import { debounce } from "lodash";
import { BehaviorSubject } from "rxjs";

const cache$ = new BehaviorSubject<
  {
    value: any;
    expirationTime: number;
    key: string;
  }[]
>([]);

const renewCache = debounce(() => {
  const cache = cache$.value;
  const currtime = new Date().getTime();
  cache$.next(cache.filter((v) => v.expirationTime >= currtime));
}, 1000);

const getValue = (key?: string) => {
  renewCache();
  return cache$.value.find((v) => v.key === key);
};

const setValue = (key: string, value: any, expirationTime: number = 500) => {
  renewCache();
  if (!value) {
    return;
  }
  const currtime = new Date().getTime();
  cache$.next([
    { key, value, expirationTime: expirationTime + currtime },
    ...cache$.value,
  ]);
};

export const useCache = () => {
  return {
    getValue,
    setValue,
  };
};
