// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ScreenRotation } from '@mui/icons-material';
import { Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Colors } from '../../../../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../../../../Configuration/Styles/Fonts';
import LOCALIZATION from '../../../../../../Localization';
import { formattedMetricValue } from '../../../../../../Tools/Tools';
import cameraBody from './camera.svg';
import { useCameraTilt } from './Hooks';
import CameraTiltStorageService from './Storage';
import { TiltSource } from './Types';


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    width: 200,
    height: 200,
    position: 'relative'
  },
  body: {
    height: '100%',
    width: '100%'
  },
  textNegative: {
    fontFamily: ({ fontFamily }) => fontFamily,
    position: 'absolute',
    bottom: 75,
    right: 10
  },
  textPositive: {
    fontFamily: ({ fontFamily }) => fontFamily,
    position: 'absolute',
    top: 75,
    right: 10
  },
  text: {
    fontFamily: ({ fontFamily }) => fontFamily,
  },
  button: {
    position: 'absolute',
    right: 0,
    top: 0,
    paddingLeft: 4,
    paddingBottom: 4,
    minWidth: 32,
    minHeight: 32,
    display: 'none'
  }
}));

const CameraTilt: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const storage = new CameraTiltStorageService()
  const [source, setSource] = useState<TiltSource>(storage.tiltSource)

  const angle = useCameraTilt(source)
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })
  const Camera = () => {
    if (angle !== undefined) {
      return (
        <>
          <img className={ classes.body } src={ cameraBody }
               style={ { transform: angle > 0 ? 'scale(1, -1)' : 'none' } } alt="arr"/>
          <div
            className={ angle > 0 ? classes.textPositive : classes.textNegative }>{ formattedMetricValue(Math.abs(angle), '˚') }</div>
        </>
      )
    } else {
      return (
        <div className={ classes.text }>{ props.intl.formatMessage({ id: LOCALIZATION.tilt_angle }) + ': ' + props.intl.formatMessage({ id: LOCALIZATION.updating }) }</div>
      )
    }
  }

  const sourceButtonTransform: string = (() => {
    switch (source) {
      case 'pitch':
        return 'rotate(45deg)'
      case 'roll':
        return 'rotate(-45deg)'
    }
  })()

  const handleChangeSource = () => {
    switch (source) {
      case 'pitch':
        setSource('roll')
        break
      case 'roll':
        setSource('pitch')
        break
    }
  }

  return (
    <div className={ classes.root }>
      <Camera/>
      <Button className={ classes.button } onClick={ handleChangeSource }>
        <ScreenRotation style={ { color: Colors.mainGrey, transform: sourceButtonTransform } }/>
      </Button>
    </div>
  )
};


export default injectIntl(CameraTilt);
