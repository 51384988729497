import { createContext, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from "react";
import { map, merge, Subject } from "rxjs";
import { useAsync } from "../../../hooks/useAsync";
export enum PanoramaAction {
    zoomIn,
    zoomOut,
    fit,
    fullSize,
}

type PanoramaState = {
    zoomIn$: Subject<PanoramaAction.zoomIn>,
    zoomOut$: Subject<PanoramaAction.zoomOut>,
    fit$: Subject<PanoramaAction.fit>,
    fullSize$: Subject<PanoramaAction.fullSize>,
};

const initialValue: PanoramaState = {
    zoomIn$: new Subject(),
    zoomOut$: new Subject(),
    fit$: new Subject(),
    fullSize$: new Subject(),
}


const ParnoramaContext = createContext<{ state: PanoramaState, setState: React.Dispatch<React.SetStateAction<PanoramaState>> }>(
    {
        state: initialValue,
        setState: function(value: SetStateAction<PanoramaState>): void {
            throw new Error("Function not implemented.");
        }
    });


export const PanoramaStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<PanoramaState>(initialValue);


    return (
        <ParnoramaContext.Provider value={{ state, setState }}>
            {children}


        </ParnoramaContext.Provider>
    );
};




export const usePanoramaActions = () => {
    const { state } = useContext(ParnoramaContext);
    const { zoomIn$, zoomOut$, fit$, fullSize$ } = state;
    const zoomIn = useCallback(() => {
        zoomIn$.next(PanoramaAction.zoomIn)
    }, [zoomIn$])

    const zoomOut = useCallback(() => {
        zoomOut$.next(PanoramaAction.zoomOut)
    }, [zoomOut$])

    const fit = useCallback(() => {
        fit$.next(PanoramaAction.fit)
    }, [fit$])

    const fullSize = useCallback(() => {
        fullSize$.next(PanoramaAction.fullSize)
    }, [fullSize$])

    const action$ = useMemo(() => merge(zoomIn$, zoomOut$, fit$, fullSize$).pipe(map(v => ({ action: v, timestamp: (new Date()).getTime() }))), [])
    const lastAction = useAsync({ observable: action$ })

    return { zoomIn, zoomOut, fit, fullSize, lastAction };
};