import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader/index";
import Popup from "../../../../../Components/_Layout/Popup/index";
import { useGetZonePoints } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useGetZonePoints";
import { useCameraShot } from "../../../../../hooks/useCameraShot";
import { useFirstDefinedValue } from "../../../../../hooks/useFirstDefined";
import LOCALIZATION from "../../../../../Localization/index";
import { PanoramaTool } from "../../../../Panorama/PanoramaTool/PanoramaTool";
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        '& .MuiCardContent-root': {
            [theme.breakpoints.down('md')]: {
                padding: '40px 10px',
            },
            padding: '40px',
            paddingTop: '20px',
            paddingBottom: '20px',
        },
        '& .MuiPaper-root': {
            boxShadow: 'none',
            overflowY: 'initial',
        },
    },
    contentRoot: {
        overflowY: "initial",
        background: 'white',
        padding: '0px',
    }
}))

interface Props {
    onClose?: () => any,
    onApply?: () => any,
    camerasToStitch: [number, number]
    uuid: string,
}
export const PerspectiveStitching = ({ onClose, onApply, camerasToStitch, uuid }: Props) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const [camera1, camera2] = camerasToStitch;

    const { data: requestedZonePoints, isLoading: loadingPoints } = useGetZonePoints({ indexes: [camera1, camera2], uuid })
    const firstrequestedZonePoints = useFirstDefinedValue(requestedZonePoints)
    const [points, setPoints] = useState<[Array<[number, number]>, Array<[number, number]>]>();
    useEffect(() => {
        if (!firstrequestedZonePoints) {
            return;
        }
        setPoints(firstrequestedZonePoints)
    }, [firstrequestedZonePoints])
    const [offset, setOffset] = useState(100);
    const { base64: src1 } = useCameraShot({ camera: camera1 })
    const { base64: src2 } = useCameraShot({ camera: camera2 })

    return <LoadingComponent isLoading={!src1 || !src2 || loadingPoints}><Popup
        closeTitle={`${formatMessage({ id: LOCALIZATION.close_btn })}`}
        isFullScreen
        headerTitle={`${formatMessage({ id: LOCALIZATION.zone })}#${camera2}`}
        // isApplyDisabled={loadingList}
        onApply={onApply}
        onClose={onClose}
        classes={classes}
        applyTitle={`${formatMessage({ id: LOCALIZATION.save })}`}

    >   {src1 && src2 && points && <PanoramaTool
        style={{ width: "100%", height: `100%` }}
        points={points}
        leftImageSource={src1}
        rightImageSource={src2}
        offset={offset}
        setPoints={setPoints}
        setOffset={setOffset}
        onOK={() => { }}
        onCancel={() => { }}
        onRefresh={() => { }}
        leftPanelId={camera1.toString()}
        rightPanelId={camera2.toString()}
        zoneId={"2"}
    />}</Popup></LoadingComponent>
}