// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Grid, SxProps, Theme } from '@mui/material';
import React from 'react';

import { createStyles, makeStyles } from '@mui/styles';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import MainCard from '../../../Components/_Layout/Cards/Main/Card';
import MainCardContent from '../../../Components/_Layout/Cards/Main/Content';
import VertGrid from '../../../Components/_Layout/VertGrid';
import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import LOCALIZATION from '../../../Localization';

import { Colors } from '../../../Configuration/Styles/Colors';
import { Sizes } from '../../../Configuration/Styles/Sizes';

import AnalyticsHorizBarWithPartsView from '../../../Components/_ChartsAndBars/AnalyticsHorizBarWithPartsView';
import { createBarChartWithPartsConfigForPhysicalReportDetails } from '../../../Components/_ChartsAndBars/AnalyticsHorizBarWithPartsView/helper';
import AnaliticsTeamScoreInfoLineView from '../../../Components/_Layout/AnaliticsTeamScoreInfoLineView';
import AnalyticsTeamsValueRow from '../../../Components/_Layout/AnalyticsTeamsValueRow';
import HorizGrid from '../../../Components/_Layout/HorizGrid';
import IconWithDescriptionRow from '../../../Components/_Layout/IconWithDescriptionRow';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { analyticsAnalyticsConvertedDataTypeName, analyticsConvertAllData, AnalyticsConvertedDataType, analyticsConvertedValueFormated, analyticsConvertedValueFormatedFromParts } from '../../../Data/Analytics/AnalyticsConverters';
import { useAnalyticsStatDistance } from '../../../Data/Analytics/AnalyticsStatDistance/hook';
import { useAnalyticsStatSpeed } from '../../../Data/Analytics/AnalyticsStatSpeed/hook';
import { GetTeamRefById } from '../../../Data/Analytics/AnalyticsTeamRef/hook';
import { AnalyticsStatAction, AnalyticsStatGranularity } from '../../../Data/Analytics/AnalyticsTypes';
import { IconFigureRun, IconFigureSprint, IconFigureWalk } from "../../../Icons/Icons";
import { AnalyticsConvertedDataUnit } from '../../../Tools/analyticsUnitsConvert';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  titleText: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.caption,
    fontWeight: Sizes.boldWeight,
    color: Colors.mainTitle,
  },
  icon: {
    display: 'flex',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    border: `1px solid ${Colors.analyticsCircleBorder}`,
    // backgroundColor: '#FFFFFF',
    marginRight: '12pt',
  },
  valueText: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontWeight: Sizes.regularWeight,
    textAlign: 'center'
  },
}));


type Props = Readonly<{
  gameId: string;
  gameResultInfo?: AnalyticsGameArchive
  granularity: AnalyticsStatGranularity;
  action: AnalyticsStatAction | undefined;
  getTeamRefByExternalId: GetTeamRefById | undefined;
  title: string | undefined;
  sx?: SxProps<Theme>;
} & WrappedComponentProps>;

const GameStatBartLineChartCard: React.FC<Props> = (props: Props) => {
  const {gameId, granularity, gameResultInfo, action} = props;

  const { data: distanceStat } = useAnalyticsStatDistance({ gameId, granularity, action });
  const { data: speedStat } = useAnalyticsStatSpeed({ gameId, granularity, action });

  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const teamName = (teamId: string | undefined) => {
    return props.getTeamRefByExternalId?.(teamId)?.name || teamId || ""
  }
  const teamShortName = (teamId: string | undefined) => {
    return props.getTeamRefByExternalId?.(teamId)?.shortName || teamId || ""
  }

  const team1Name = teamName(gameResultInfo?.team1);
  const team1ShortName = teamShortName(gameResultInfo?.team1);
  const team2Name = teamName(gameResultInfo?.team2);
  const team2ShortName = teamShortName(gameResultInfo?.team2);

  const {data} = createBarChartWithPartsConfigForPhysicalReportDetails(
    team1ShortName,
    team2ShortName,
    granularity,
    undefined,
    distanceStat?.time_intervals,
    speedStat?.time_intervals,
  )

  const allConvertedData = analyticsConvertAllData(
    team1ShortName,
    team2ShortName,
    undefined,
    undefined,
    distanceStat?.game_stat,
    speedStat?.game_stat,
    undefined,
    undefined,
    undefined,
    {
      avgSpeed: AnalyticsConvertedDataUnit.KmPerHour
    }
  )


  const team1ValueStr = analyticsConvertedValueFormated(allConvertedData.distanceCovered?.team1);
  const team2ValueStr = analyticsConvertedValueFormated(allConvertedData.distanceCovered?.team2);
  const mainDataIndexName = analyticsAnalyticsConvertedDataTypeName(AnalyticsConvertedDataType.distanceCovered, allConvertedData.distanceCovered);

  return (
    <MainCard>
      <Grid
        container direction="row"
          alignItems='flex-start'
          justifyContent="space-between"
          spacing={1}
          sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 2 }}
        >
          <Grid item xs={ 12 } lg = { 6 } xl = { 6 }>
            <Box className={ classes.titleText }>
              { props.title }
            </Box>
          </Grid>
          <Grid item xs={ 12 } lg = { 6 } xl = { 4 } >
            <VertGrid spacing = { 0 }>
            <AnaliticsTeamScoreInfoLineView
                valueAlign='right'
                title={ team1Name  }
                value={ team1ValueStr }
                iconCircleMarkerColor={{
                  teamIndex: 1,
                  team1ShortName: team1ShortName,
                  team2ShortName: team2ShortName,
                }}
                valueFontSize={ Sizes.analyticsScore }
              />
            <AnaliticsTeamScoreInfoLineView
                valueAlign='right'
                title={ team2Name  }
                value={ team2ValueStr }
                iconCircleMarkerColor={{
                  teamIndex: 2,
                  team1ShortName: team1ShortName,
                  team2ShortName: team2ShortName,
                }}
                valueFontSize={ Sizes.analyticsScore }
              />
            </VertGrid>
          </Grid>
        </Grid>

      <MainCardContent>

        <AnalyticsHorizBarWithPartsView
          granularity={props.granularity}
          // visualiseMode={ visualiseMode }
          value1Name={ team1ShortName  }
          value2Name={ team2ShortName  }
          value1Color={ AnalyticsGameShared.team1Color }
          value2Color={ AnalyticsGameShared.team2Color }
          data= { data }
          mainDataIndex={ AnalyticsConvertedDataType.distanceCovered }
          mainDataIndexName={ mainDataIndexName }
          secondDataIndex={ AnalyticsConvertedDataType.avgSpeed }
          thirdDataIndex={ AnalyticsConvertedDataType.intensity }
        />

        <HorizGrid
          spacing={2}
          sx={{
            marginLeft: 4,
            marginRight: 4
          }}
        >
          <IconWithDescriptionRow icon={ IconFigureWalk } title={ props.intl.formatMessage({ id: LOCALIZATION.analytics_help_walking }) } />
          <IconWithDescriptionRow icon={ IconFigureRun } title={ props.intl.formatMessage({ id: LOCALIZATION.analytics_help_running }) } />
          <IconWithDescriptionRow icon={ IconFigureSprint } title={ props.intl.formatMessage({ id: LOCALIZATION.analytics_help_sprinting }) } />
        </HorizGrid>

        <VertGrid
          sx={{
            marginTop: 3,
            marginLeft: 4,
            marginRight: 4
          }}
        >
          <Box
            className={classes.titleText}
            sx={{
              paddingBottom: 2
            }}
          >
            { props.intl.formatMessage({ id: LOCALIZATION.statistics }) }
          </Box>

          <VertGrid>
            <AnalyticsTeamsValueRow title='' value1={ team1Name } value2={ team2Name } />
            <AnalyticsTeamsValueRow
              title={ props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_team_distance }) }
              value1={ analyticsConvertedValueFormated(allConvertedData.distanceCovered?.team1) }
              value2={ analyticsConvertedValueFormated(allConvertedData.distanceCovered?.team2) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_avg_players_speed })}
              value1={ analyticsConvertedValueFormated(allConvertedData.avgSpeed?.team1) }
              value2={ analyticsConvertedValueFormated(allConvertedData.avgSpeed?.team2) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_distance_walked_by_players })}
              value1={ analyticsConvertedValueFormatedFromParts(allConvertedData.distanceCovered?.team1, AnalyticsConvertedDataType.partWalk) }
              value2={ analyticsConvertedValueFormatedFromParts(allConvertedData.distanceCovered?.team2, AnalyticsConvertedDataType.partWalk) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_avg_walking_speed })}
              value1={ analyticsConvertedValueFormatedFromParts(allConvertedData.avgSpeed?.team1, AnalyticsConvertedDataType.partWalk) }
              value2={ analyticsConvertedValueFormatedFromParts(allConvertedData.avgSpeed?.team2, AnalyticsConvertedDataType.partWalk) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_distance_run_by_players })}
              value1={ analyticsConvertedValueFormatedFromParts(allConvertedData.distanceCovered?.team1, AnalyticsConvertedDataType.partRun) }
              value2={ analyticsConvertedValueFormatedFromParts(allConvertedData.distanceCovered?.team2, AnalyticsConvertedDataType.partRun) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_avg_running_speed })}
              value1={ analyticsConvertedValueFormatedFromParts(allConvertedData.avgSpeed?.team1, AnalyticsConvertedDataType.partRun) }
              value2={ analyticsConvertedValueFormatedFromParts(allConvertedData.avgSpeed?.team2, AnalyticsConvertedDataType.partRun) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_distance_sprinted_by_players })}
              value1={ analyticsConvertedValueFormatedFromParts(allConvertedData.distanceCovered?.team1, AnalyticsConvertedDataType.partSprint) }
              value2={ analyticsConvertedValueFormatedFromParts(allConvertedData.distanceCovered?.team2, AnalyticsConvertedDataType.partSprint) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_avg_sprinting_speed })}
              value1={ analyticsConvertedValueFormatedFromParts(allConvertedData.avgSpeed?.team1, AnalyticsConvertedDataType.partSprint) }
              value2={ analyticsConvertedValueFormatedFromParts(allConvertedData.avgSpeed?.team2, AnalyticsConvertedDataType.partSprint) }
              />
            <AnalyticsTeamsValueRow
              title={props.intl.formatMessage({ id: LOCALIZATION.analytics_stat_avg_intensity })}
              value1={ analyticsConvertedValueFormated(allConvertedData.intensity?.team1) }
              value2={ analyticsConvertedValueFormated(allConvertedData.intensity?.team2) }
              />
          </VertGrid>
        </VertGrid>
      </MainCardContent>
    </MainCard>
  )
};

export default injectIntl(GameStatBartLineChartCard);
