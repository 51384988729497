// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { makeStyles } from '@mui/styles';
import React from 'react';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';

import { Sizes } from '../../../Configuration/Styles/Sizes';
import AnyChildren from '../../../Tools/AnyChildren';


type Props = Readonly<{
  textAnchor: string;
  x: number;
  y: number;
  fill: string;
  children: AnyChildren;
}>;


const useStyles = makeStyles<any, { fontFamily: FontName }>(() => ({
  label: {
    fontSize: Sizes.title,
    fontFamily: ({ fontFamily }) => fontFamily,
  },
}));


const YAxisLabel: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  return (
    <text
      className={ classes.label }
      textAnchor={ props.textAnchor }
    >
      { props.children }
    </text>
  );
};


export default YAxisLabel;
