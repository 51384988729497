// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Colors } from '../../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';

export type TabItemInterface = Readonly<{
  id: number;
  title: string;
  Content: React.FC<any>,
}>;

type Props = Readonly<{
  items: TabItemInterface[];
  selectedTabID: number;
  onChangeTab: (id: number) => void;
  itemClass?: string;
}>;

export const AntTabsHolder = styled(Tabs)({
  borderBottom: 'none',
  maxWidth: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: Colors.mainGreen,
    height: '3px',
  },
});

export const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: Sizes.mediumWeight,
    fontSize: Sizes.heading2,
    marginRight: theme.spacing(1),
    color: Colors.mainTitle,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: Colors.mainTitle,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

interface StyledTabProps {
  label: string;
  id: string;
  className?: string;
}

const TabsComponent: React.FC<Props> = (props: Props) => {
  const { itemClass } = props
  const handleChange = (event: any, newValue: any) => {
    props.onChangeTab(newValue as number);
  };
  const { font } = useCurrentFont()
  const renderTabs = () => (
    props.items.map((item) => (
      <AntTab
        className={itemClass}
        key={ item.id }
        label={ item.title }
        id={`tab-${item.id}`}
        sx={{ fontFamily: font }
        }
        // aria-controls={ `simple-tabpanel-${item.id}` }
      />
    ))
  );

  return (
    <Box>
      <AntTabsHolder
        // aria-label="basic tabs example"
        value={ props.selectedTabID }
        onChange={ handleChange }
      >
        { renderTabs() }
      </AntTabsHolder>
    </Box>
  );
};


export default TabsComponent;
