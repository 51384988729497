import { debounce } from "lodash";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { JAVA_BACKEND_URL } from "../../constants";
import fetchJSONData from "../_Networking/ReactQuery/fetch";
interface Component {
  size: string;
  tag: string;
  version: string;
}
type RECORDJSON = {
  VERSION: string;
  device: string;
} & { [key in string]: Component };

interface MetricJson {
  whenCreated: number;
  id: string;
  name: string;
  sensorPlace: string;
  sensorName: string;
  errorMeasurement: string;
  json: RECORDJSON;
  sampleTime: number;
}

type Response = MetricJson[];
interface IUseMetrics<TData> {
  isServer: boolean;
  /**
   * @description You can override default settings, such as staleTime or cacheTime.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}

export const useSoftwareVersionsCacheKey = (isServer: boolean) =>
  `useSoftwareVersionsCacheKey${isServer}`;

/**
 * @description Hook to fetch the last metrics data from the API.
 * @param props
 * @returns
 */
export const useSoftwareVersions = (
  props: IUseMetrics<RECORDJSON | undefined>
) => {
  const { options, isServer } = props ?? {};
  const queryClient = useQueryClient();
  const key = [useSoftwareVersionsCacheKey(isServer)] as QueryKey;

  const fetchMetrics = async () => {
    const path = new URL(`${JAVA_BACKEND_URL}/metrics/json/last`);
    path.searchParams.set("max_age", `${600000}`);
    path.searchParams.set("filter_name", "static_data");
    const response = await fetchJSONData<Response>(path.toString(), {
      headers: {
        "X-ALLOW-PROXY-REQUEST": `${!isServer}`,
      },
    });
    return response?.[0].json;
  };

  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };

  const query = useQuery(key, fetchMetrics, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
  });

  const fetchData = debounce(async () => {
    return queryClient.fetchQuery(key, fetchMetrics);
  }, 100);

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
  };
};
