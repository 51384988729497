// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { css } from "@emotion/css";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
import {
  applyToPoint,
  compose,
  inverse,
  Matrix,
  scale
} from "transformation-matrix";
import {
  ActualSizeSvg,
  FitSvg,
  ZoomInSvg,
  ZoomOutSvg
} from "../shared/SvgIcons";
import { fit } from "./fit";
import { Point } from "./Pin";
import { PanoramaAction, usePanoramaActions } from "./usePanormaActions";

type Props = {
  position: "left" | "right";
  matrix: Matrix;
  setMatrix: (m: Matrix) => void;
  image: HTMLImageElement | null;
  bbox: DOMRect | null;
  visible?: boolean
};

export const Footer = (props: Props) => {
  const { position, matrix, setMatrix, bbox, image, visible = true } = props;

  const { lastAction } = usePanoramaActions()

  const zoomStage = (p: Point, by: number) => {
    //event.preventDefault();
    const inv = inverse(matrix);
    const pp = applyToPoint(inv, p);
    const newMatrix = compose(matrix, scale(by, by, pp[0], pp[1]));
    return newMatrix;
  }

  const zoomInCallback = () => bbox && setMatrix(zoomStage([bbox.width / 2, bbox.height / 2], 1.1))

  const zoomOutCallback = () => bbox && setMatrix(
    fit(image as HTMLImageElement, bbox.width, bbox.height)
  )
  const fitCallback = () => bbox && setMatrix(
    fit(image as HTMLImageElement, bbox.width, bbox.height)
  )

  const fullSizeCallback = () => bbox && setMatrix(
    zoomStage([bbox.width / 2, bbox.height / 2], 1 / matrix.a)
  )

  useEffect(() => {
    if (!lastAction) {
      return;
    }
    const action = lastAction.action;
    switch (action) {
      case PanoramaAction.fit:
        fitCallback()
        break;
      case PanoramaAction.fullSize:
        fullSizeCallback()
        break;
      case PanoramaAction.zoomIn:
        zoomInCallback()
        break;
      case PanoramaAction.zoomOut:
        zoomOutCallback()
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAction])

  return (
    <div className={`footer footer-${position} ${css`visibility: ${!visible ? 'hidden' : 'initial'};`}`}>
      {bbox && (
        <>
          <IconButton
            size="small"
            onClick={zoomInCallback}
          >
            <ZoomInSvg />
          </IconButton>
          <IconButton
            size="small"
            onClick={zoomOutCallback}
          >
            <ZoomOutSvg />
          </IconButton>
          <IconButton
            size="small"
            onClick={fitCallback}
          >
            <FitSvg />
          </IconButton>
          <IconButton
            size="small"
            onClick={fullSizeCallback}
          >
            <ActualSizeSvg />
          </IconButton>
        </>
      )}
    </div>
  );
};
