// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { TableBody, TableCell, TableRow } from '@mui/material';
import { findIndex } from 'lodash';

import { EMPTY_ARRAY } from '../../constants';
import Order from '../../Data/_Networking/ReactQuery/Order';
import ResponseMany from '../../Data/_Networking/ReactQuery/ResponseMany';
import { AddDropdownProps } from '../_BaseUI/AddDropdownButton';
import { MenuDropdownAction } from '../_BaseUI/MenuDropdownButton';
import { getComparator, stableSort } from './Body.helpers';
import { TypeID } from './index';
import SortedTableBodyRow, { RenderCellContent, RowClassName } from './SortedTableBodyRow';
import { HeadCell } from './SortedTableHead';


type Props<T> = Readonly<{
  headCells: HeadCell<T>[];
  manyItems: ResponseMany<T[]>;
  order: Order;
  orderBy: keyof T;
  page: number;
  rowsPerPage: number;
  selected?: T[];
  renderCellContent?: RenderCellContent<T>;
  rowClassName?: (row: T, allItems: T[]) => RowClassName | null;
  onRowClick?: (item: T) => void;
  onSelect?: (selected: T[]) => void;
  showRemoveIconButton?: (item: T) => boolean;
  onRemoveIconButtonClicked?: (selected: T) => void;
  showMenuIconButton?: (item: T) => boolean;
  onMenuEdit?: (selected: T) => void;
  onMenuCopy?: (selected: T) => void;
  onMenuDelete?: (selected: T) => void;
  customActions?: MenuDropdownAction<T>[]
  showAddIconButton?: (item: T) => boolean;
} & AddDropdownProps<T>>;


const SortedTableBody = <T extends { id: TypeID },>(props: Props<T>): JSX.Element => {
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = (props.page > 0) ?
    Math.max(0, (1 + props.page) * props.rowsPerPage - (props.manyItems.meta.pagination?.total || 0))
    :
    0;

  const handleClick = (clickedItem: T): void => {
    if (props?.selected && props?.onSelect) {
      const selectedIndex = findIndex<T>(props.selected, (item) => (item.id === clickedItem.id));
      // const selectedIndex = props.selected.indexOf((item) => (item.id === clickedItem.id));

      let newSelected: T[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat((props.selected || EMPTY_ARRAY), clickedItem);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(props.selected.slice(1));
      } else if (selectedIndex === props.selected.length - 1) {
        newSelected = newSelected.concat(props.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          props.selected.slice(0, selectedIndex),
          props.selected.slice(selectedIndex + 1),
        );
      }

      props.onSelect(newSelected);
    }
  };
  const preparedItems: T[] = stableSort<T>(
    (props?.manyItems?.data || EMPTY_ARRAY),
    getComparator<T>(props.order, props.orderBy),
  );
  // .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage);

  const renderRow = (row: T) => (
    <SortedTableBodyRow
      key={row.id}
      headCells={props.headCells}
      row={row}
      allItems={preparedItems}
      selected={props.selected}
      renderCellContent={props.renderCellContent}
      rowClassName={props.rowClassName}
      onClick={props.onRowClick}
      onSelect={props?.onSelect ? handleClick : undefined}
      showRemoveButton={(!props?.showRemoveIconButton) || props.showRemoveIconButton(row)}
      showMenuButton={(!props?.showMenuIconButton) || props.showMenuIconButton(row)}
      showAddButton={(!props?.showAddIconButton) || props.showAddIconButton(row)}
      onRemoveIconButtonClicked={props.onRemoveIconButtonClicked}
      onMenuCopy={props.onMenuCopy}
      onMenuDelete={props.onMenuDelete}
      onMenuEdit={props.onMenuEdit}
      customActions={props.customActions}
      getAddMenuItems={props.getAddMenuItems}
      onAddMenuItemSelect={props.onAddMenuItemSelect}
    />
  );
  const renderRows = preparedItems.map(renderRow);

  return (
    <TableBody>
      {renderRows}
      {
        (emptyRows > 0) &&
        (
          <TableRow
            style={{
              height: (53 * emptyRows),
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )
      }
    </TableBody>
  );
};


export default SortedTableBody;
