import React, { ComponentType, forwardRef } from 'react';

export const higherOrderComponent = <P extends object>(WrappedComponent: keyof HTMLElementTagNameMap) => {
    return forwardRef((props: P, ref) => {
        const element = React.createElement(WrappedComponent as unknown as ComponentType<P>, { ...props, ref });

        return element
    })

};

