// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import React from 'react';

import { theme$, ThemeType } from '../../../hooks/useTheme';
import { AppActionType, AppLocale, useAppConfig, useAppConfigAction } from '../../../Localization/AppContextProvider/helpers';
import enumToObject from '../../../Tools/enumToObject';
import LanguageItem from './Item';


type Props = Readonly<{}>;

const LOCALIZATIONS = enumToObject(AppLocale);

const LanguageSelector: React.FC<Props> = () => {
  const { localization: { locale } } = useAppConfig();
  const appConfigAction = useAppConfigAction();
  const supportedLocalizations = Object.keys(LOCALIZATIONS);

  const updateLocalization = (localization: AppLocale) => {
    appConfigAction({
      type: AppActionType.ChangeLanguage,
      locale: localization,
    });
    if (localization === AppLocale.Cn) {
      theme$.next(ThemeType.CHINA)
    } else {
      theme$.next(ThemeType.COMMON)

    }
  };

  const renderItem = (localization: string, index: number) => {
    const itemLocale = LOCALIZATIONS[localization] as AppLocale;
    const handleChangeLocale = () => updateLocalization(itemLocale);

    return (
      <LanguageItem
        key={ localization }
        title={ itemLocale }
        divider={ index < (supportedLocalizations.length - 1) }
        selected={ locale === itemLocale }
        onClicked={ handleChangeLocale }
      />
    );
  };

  return (
    <Grid
      container
      alignItems="center"
    >
      { supportedLocalizations.map(renderItem) }
    </Grid>
  )
};


export default LanguageSelector;
