import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CameraIntervalImage } from "../../../../Components/CameraIntervalImage";
import LoadingComponent from "../../../../Components/Loader";
import MainButton from "../../../../Components/_BaseUI/MainButton";
import Card from "../../../../Components/_Layout/Card";
import Popup from "../../../../Components/_Layout/Popup";
import { useCurrentFont } from "../../../../Configuration/Styles/Fonts";
import { usePingCalibrationMode } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePingCalibrationMode";
import { useSelectUndistordConfig } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useSetUndistordConfig";
import { useCurrentUndistordConfigs } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCurrentUndistordConfigs";
import { useUndistordConfigsList } from "../../../../Data/NapiCameraHandlerV2/hooks/useUndistordConfigsList";
import { CameraStateValue } from "../../../../Data/NapiCameraState";
import { useCameraState } from "../../../../Data/NapiCameraState/hook";
import { useAwaitedInterval } from "../../../../hooks/useAwaitedInterval";
import { useCalibrationMode } from "../../../../hooks/useCalibrationMode";
import useConfirm from "../../../../hooks/useConfirm";
import { usePromise } from "../../../../hooks/usePromise";
import LOCALIZATION from "../../../../Localization";
import { ImageAdjust } from "../ImageAdjust/ImageAdjust";
import { CreateUdistortionConfigModalPreload } from "./EditUndistortionConfigModal/EditUdistortionConfigModal";
import { UndistordConfigItem } from "./UndistordConfigItem";


type Props = Readonly<{
    onClose: () => void
    handleSubmit?: () => Promise<void>,
    camera: number,
}>;


const StyledCameraIntervalImage = (styled(CameraIntervalImage)`
    height: 100%;
    max-width: calc(100%);
    background-color: #E5E5E5;
    border: solid 1px;
    border-color:  rgba(0, 0, 0, 0.30);
    border-radius: 10px;
    width: 100%;
    min-height: 60px;
`)

const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))
const EditModal = ((props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote, camera } = props;
    const { mutateAsync: pingCalibrationMode, pendingKick, isLoading: enteringCalibratyionMode, isError: errorEnteringCalibrationMode, data: currentCalibrationMode, previousData } = usePingCalibrationMode({
        options: {
            keepPreviousData: true,
        }
    })
    const { formatMessage } = useIntl();
    const { confirm, confirmDialog } = useConfirm();
    const intl = useIntl();
    const { font } = useCurrentFont()

    const Header = styled.span`
        color: var(--C_Dark_Text, #334D5E);
        margin-bottom:10px;
        /* c_h4 (18) */
        font-family: ${font};
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.27px;
        margin-bottom:10px;`



    const [kickIgnore, setKickIgnore] = useState<boolean>(false);

    useEffect(() => {
        if (!errorEnteringCalibrationMode) {
            return;
        }
        alert(intl.formatMessage({ id: LOCALIZATION.error_while_changing_camera_handler_conf }))
    }, [errorEnteringCalibrationMode, intl])

    useEffect(() => {
        if (!pendingKick || kickIgnore || confirmDialog?.isOpen) {
            return;
        }
        confirm({
            bodyText: intl.formatMessage({ id: LOCALIZATION.pending_kick_alert }),
            timeoutAction: "confirm",
            timeout: 150,
        }).then((v) => {
            if (v) {
                onClose();
                return
            }
            setKickIgnore(true)
        })

    }, [confirm, confirmDialog?.isOpen, intl, kickIgnore, onClose, pendingKick])

    const { setMode: set, setRunActiveUndistord, setUndistordDisabled } = useCalibrationMode();

    useAwaitedInterval(() => pingCalibrationMode(), 15_000)
    useEffect(() => {
        set(true);
        setRunActiveUndistord(true);
        pingCalibrationMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCloseCallback = useCallback(() => {
        set(false);
        setUndistordDisabled(false)

        onClose();
    }, [onClose, set, setUndistordDisabled])
    const { data: configListResponse, isLoading: loadingList } = useUndistordConfigsList({
        options: {
            keepPreviousData: true,
        }
    });
    const { data: currentConfigsResponse } = useCurrentUndistordConfigs();
    const activeSetting = useMemo(() => currentConfigsResponse?.getCurrentUndistordConfig.filter(v => v.camera === camera)?.[0], [camera, currentConfigsResponse?.getCurrentUndistordConfig])
    const isUndistortionDisabled = useMemo(() => {
        return !Boolean(activeSetting)
    }, [activeSetting])
    const [createConfigModal, setCreateConfigModal] = useState<boolean>(false)
    const [adjustImageModal, setAdjustImageModal] = useState<boolean>(false)
    const settingsList = useMemo(() => {
        return configListResponse?.getUndistordConfigs ?? []
    }, [configListResponse])
    const classes = useStyles();
    const { mutateAsync: selectConfig, isLoading: selectingConfig, } = useSelectUndistordConfig({
        options: {
            keepPreviousData: true,
        }
    });
    const { state } = useCameraState()
    const onSelectCallback = useCallback<(params: Parameters<typeof selectConfig>) => void>(([{ uuid, camera }]) => {
        confirm({
            bodyText: (`${intl.formatMessage({ id: LOCALIZATION.confirm_actions })}`),
            timeoutAction: "cancel",
            timeout: 150,
        }).then((v) => {
            if (v) {
                setUndistordDisabled(!uuid)
                return selectConfig({ uuid, camera })
            }
        }).then(() => pingCalibrationMode()).catch(e => {
            alert(formatMessage({ id: LOCALIZATION.error_while_changing_camera_handler_conf }))
        })
    }, [confirm, formatMessage, intl, pingCalibrationMode, selectConfig, setUndistordDisabled])




    const loadingMessage = useMemo(() => {

        if ((!currentCalibrationMode?.state) && enteringCalibratyionMode) {
            return formatMessage({ id: LOCALIZATION.entering_calibration_mode })
        }
        if (loadingList) {
            return formatMessage({ id: LOCALIZATION.loading_list })
        }
        if (selectingConfig) {
            return formatMessage({ id: LOCALIZATION.saving_changes })
        }
        if (state === CameraStateValue.ConfigApplying) {
            return formatMessage({ id: LOCALIZATION.applying_configuration })

        }


    }, [currentCalibrationMode?.state, enteringCalibratyionMode, loadingList, selectingConfig, state, formatMessage])

    const [modalEditOpen, setModalEditOpen] = useState<{ uuid: string, name: string } | undefined>()

    const imageInterval = useMemo(() => createConfigModal || adjustImageModal || modalEditOpen ? Infinity : 5_000, [adjustImageModal, createConfigModal, modalEditOpen])





    return <>
        <Popup
            closeTitle={`${intl.formatMessage({ id: LOCALIZATION.close_btn })}`}
            isFullScreen
            headerTitle={`${intl.formatMessage({ id: LOCALIZATION.lens })} #${camera} ${intl.formatMessage({ id: LOCALIZATION.calibration })}`}
            isApplyDisabled={loadingList}
            onApply={handleSubmitRemote}
            onClose={onCloseCallback}
            classes={classes}
        >
            <LoadingComponent ugly isLoading={(loadingList || selectingConfig || (!previousData?.state)) && !createConfigModal} label={loadingMessage}>

                <Grid sx={{ minHeight: '523px', }} container className={css`
                        display: 'flex';
                         align-items: 'flex-start';
                `} gap={1}>
                    <Grid md={5} sm={6} xs={12} xl={5} item>
                        <Card sx={{
                            height: "100%",
                        }}
                            contentSx={{
                                height: "100%",
                                padding: '20px 20px 30px 20px !important',
                                boxSizing: "border-box",
                                display: "flex",
                                flexDirection: "column",

                            }}>



                            <Header>{formatMessage({ id: LOCALIZATION.lens_settings })}</Header>
                            <UndistordConfigItem active={isUndistortionDisabled} onSelect={(uuid) => onSelectCallback([{ uuid, camera }])} name={formatMessage({ id: LOCALIZATION.undistord_disabled })} />
                            {settingsList.map(v => {
                                return <UndistordConfigItem onEditRequest={() => setModalEditOpen(v)} active={activeSetting?.uuid?.includes(v.uuid)} onSelect={(uuid) => onSelectCallback([{ uuid, camera }])} key={v.uuid} uuid={v.uuid} name={v.name} />
                            })}
                            <Grid display={"flex"} marginTop={"auto"}>
                                <MainButton sx={{ width: "fit-content", marginLeft: 'auto' }} onClicked={() => setCreateConfigModal(true)} title={formatMessage({ id: LOCALIZATION.add })} />
                            </Grid>

                        </Card>
                    </Grid>
                    <Grid xs xl style={{ height: "max-content" }} item>
                        <Card sx={{
                            height: "100%",
                            width: "100%",
                        }}
                            contentSx={{
                                padding: '20px !important',
                                paddingBottom: '30px !important',
                                display: "flex", flexDirection: "column"

                            }}>
                            <Header>{formatMessage({ id: LOCALIZATION.active })}: {activeSetting?.name ?? formatMessage({ id: LOCALIZATION.undistord_disabled })}</Header>
                            <div className={css`
                                aspect-ratio: 16/9;
                                width: -webkit-fill-available;
                                max-height: 80vh;
                                max-width: 100%;
                            `}>
                                <LoadingComponent isLoading={!previousData?.runActiveUndistord} >
                                    <StyledCameraIntervalImage camera={camera} interval={imageInterval} width={1200} /></LoadingComponent>
                            </div>
                            <Grid display={"flex"} marginTop={2}>
                                <MainButton sx={{ width: "fit-content", marginLeft: 'auto' }} onClicked={() => setAdjustImageModal(true)} title={formatMessage({ id: LOCALIZATION.adjust_image })} />
                            </Grid>
                        </Card>
                    </Grid>

                </Grid>
            </LoadingComponent>

        </Popup>
        {modalEditOpen && <CreateUdistortionConfigModalPreload {...modalEditOpen} camera={camera} onClose={() => { setModalEditOpen(undefined); }} />}
        {createConfigModal && <CreateUdistortionConfigModalPreload {...props} onClose={() => { setCreateConfigModal(false); }} />}
        {adjustImageModal && <ImageAdjust handleSubmit={() => { setAdjustImageModal(false); }} camera={camera} onClose={() => { setAdjustImageModal(false); }} />}
    </>
})

export const UndistortionListModal = ((props: Props) => {
    const { fetchData, resetCache, data: configList } = useUndistordConfigsList();
    const loaded = usePromise(async () => {
        await resetCache()
        await fetchData?.();
        return true;
    }, [])
    if (!loaded || !configList) {
        return <></>
    }
    return <EditModal {...props}></EditModal>
})
