// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { SvgInHtml } from "../../../Icons/Icons";
import HorizGrid from '../HorizGrid';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
    titleText: {
    fontFamily: ({ fontFamily }) => fontFamily,
        fontSize: Sizes.title,
        fontWeight: Sizes.regularWeight,
        color: Colors.mainTitle,
    },
  }));

type Props = Readonly<{
    icon: SvgInHtml;
    title?: string;
}>;

const IconWithDescriptionRow: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const Icon = props.icon

  return (
    <HorizGrid spacing={ 1 } alignItems='top'>
        <Box minWidth={ 20} minHeight={ 20 }>
          <Icon/>
        </Box>
        <Box className={ classes.titleText }>
            { props.title }
        </Box>
    </HorizGrid>
  )
};


export default IconWithDescriptionRow;
