// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved


import { FormControl, MenuItem, Select } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import { EMPTY_ARRAY } from '../../../../constants';
import { SelectorOption } from '../../Types/SelectorOption.type';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    flexGrow: 1
  },
  formControl: {
    minWidth: 90,
    width: '100%',
    minHeight: 40,
    background: Colors.mainBackground,
    fontFamily: ({ fontFamily }) => fontFamily,
    borderRadius: 6
  },
  select: {
    borderRadius: 6
  },
  text: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.subtitle,
    color: Colors.mainTitle
  }
}));


export default function CameraApiRequestSelector(props: { sources: (SelectorOption)[], currentSource?: (SelectorOption), onChanged: (source: SelectorOption) => void }) {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })
  // eslint-disable-next-line no-unused-vars
  const sources = props.sources || EMPTY_ARRAY;

  const firstAvailableApiRequest = () => {
    if (props.currentSource) {
      return props.currentSource
    } else if (sources.length > 0) {
      return sources[0]
    } else {
      return undefined
    }
  }
  const currentValue = firstAvailableApiRequest()
  const handleCurrentValueChange = (source: SelectorOption) => {
    props.onChanged(source)
  }

  function createDropDownItems() {
    return sources.map(source => {
      return (
        <MenuItem
          key={ source.name }
          value={ source.name }
          onClick={ () => {
            handleCurrentValueChange(source)
          } }>
          <div className={ classes.text }>{ source.name }</div>
        </MenuItem>
      )
    })
  }

  return (
    <div className={ classes.root }>
      <FormControl variant="outlined" className={ classes.formControl } size="small">
        <Select
          className={ classes.select }
          displayEmpty
          value={currentValue?.name ?? ''}
        >
          { createDropDownItems() }
        </Select>
      </FormControl>
    </div>
  )
}
