import { gql } from "graphql-request";
import { debounce } from "lodash";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useCameraHandler } from "../../../../hooks/useCameraHandler";
import { globalConfigCacheKey } from "../../types";

interface IResponse {
  getMainCameraScheduleConfig:
    | null
    | [{ timestamp: number; camera: number }]
    | [
        { timestamp: number; camera: number },
        { timestamp: number; camera: number }
      ];
}

const graphql = gql`
  query MainCameraScheduleConfig {
    getMainCameraScheduleConfig {
      timestamp
      camera
    }
  }
`;
interface IUseCameraCommonSettingsProps<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}
const cacheKey = "useMainCameraSchedule";

/**
 * @description It request the data only when fetchData has been fired
 * @param props
 * @returns
 */
export const useMainCameraSchedule = (
  props?: IUseCameraCommonSettingsProps<IResponse | undefined>
) => {
  const { options } = props ?? {};
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const key = [globalConfigCacheKey, cacheKey] as QueryKey;
  const request = () => client?.request<IResponse>(graphql);
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };
  const query = useQuery(key, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
  });
  const fetchData = debounce(async () => {
    return queryClient.fetchQuery(key, request); // Prefetch the query
  }, 100);

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
  };
};
