import { useEffect, useState } from "react";

enum DeviceType {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
}

/**
 * Custom hook to determine the device type (mobile, tablet, desktop) based on the user agent.
 *
 * @returns {DeviceType} The current device type.
 */
const useDevice = (): DeviceType => {
  const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType());

  useEffect(() => {
    setDeviceType(getDeviceType());
  }, []);

  return deviceType;
};

/**
 * Helper function to determine the device type based on the user agent string.
 *
 * @returns {DeviceType} The detected device type.
 */
const getDeviceType = (): DeviceType => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/mobile/i.test(userAgent)) {
    return DeviceType.Mobile;
  } else if (/tablet|ipad|playbook|silk|android(?!.*mobi)/i.test(userAgent)) {
    return DeviceType.Tablet;
  } else {
    return DeviceType.Desktop;
  }
};

export { useDevice, DeviceType };
