import { Grid, } from "@mui/material";
import { useIntl } from "react-intl";
import Card from "../../../../Components/_Layout/Card";
import Header from "../../../../Components/_Layout/Header";
import LOCALIZATION from "../../../../Localization";
import { UndistordSettingsTable } from "./UndistordSettingsTable";

export const UndistordSettings = (() => {
    const intl = useIntl();

    return <><Grid item xs={12} sm={10}>
        <Header
            text={intl.formatMessage({ id: LOCALIZATION.page_camera_settings_udistord_settings_header })}
        />
    </Grid>
        <Grid item style={{
            "display": "flex",
            "padding": "0px",
            "marginLeft": "auto",
            "alignItems": "flex-end",
        }}>
        </Grid>

        <Grid item xs={12}>
            <Card>
                <UndistordSettingsTable ></UndistordSettingsTable>
            </Card>
        </Grid>
    </>
})