/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export function useDelay<T>(property: T, delay: number): T {
    const [delayed, setDelayed] = useState<T>(property)
    const delayedChange = useCallback(debounce((value: T) => {
        setDelayed(value);
    }, delay), [delay]);

    useEffect(() => {
        delayedChange(property);
        return () => {
            delayedChange.cancel();
        };
    }, [property, delayedChange]);

    return delayed;
}
