// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { RefObject, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Matrix } from "transformation-matrix";
import { useCurrentFont } from "../../../Configuration/Styles/Fonts";
import LOCALIZATION from "../../../Localization/index";
import { fit } from "./fit";
import { Footer } from "./Footer";
import { Point } from "./Pin";
import { PreviewCanvas } from "./PreviewCanvas";

type Props = {
  position: "left" | "right";
  matrix: Matrix;
  panelId: string;
  setMatrix: (m: Matrix) => void;
  imageSource: string;
  points: [Array<Point>, Array<Point>];
  setPoints(points: [Array<Point>, Array<Point>]): void;
  selectedPinIndex: number;
  setSelectedPinIndex: (n: number) => void;
  refCanvas: RefObject<HTMLDivElement>;
  bboxCanvas: DOMRect | null;
  footer?: boolean;
};

export const PreviewPanel = (props: Props) => {
  const {
    position,
    matrix,
    setMatrix,
    imageSource,
    points,
    setPoints,
    selectedPinIndex,
    setSelectedPinIndex,
    refCanvas,
    bboxCanvas: bbox,
    panelId,
    footer = false,
  } = props;
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const bboxHeight = bbox ? bbox.height : 0;
  const { font } = useCurrentFont()

  const { formatMessage } = useIntl()

  useEffect(() => {
    if (bbox && bbox.width > 0) {
      const image = new Image();
      image.src = imageSource;
      image.onload = () => {
        const m = fit(image, bbox.width, bbox.height);
        setImage(image);
        setMatrix(m);
      };
    }
  }, [imageSource, bboxHeight, bbox, setMatrix]);

  return (
    <>
      <PreviewCanvas
        imageSource={imageSource}
        points={points}
        setPoints={setPoints}
        position={position}
        matrix={matrix}
        setMatrix={setMatrix}
        selectedPinIndex={selectedPinIndex}
        setSelectedPinIndex={setSelectedPinIndex}
        elementRef={refCanvas}
        bbox={bbox}
        image={image}
        panelId={panelId}
      />
      {bbox && (
        <div
          style={{
            backgroundColor: "transparent",
            border: "0px solid red",
            position: "absolute",
            left: bbox.left,
            width: bbox.right - bbox.left,
            top: bbox.bottom - 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: font,
            fontWeight: 'bold',
            fontSize: 20,
            lineHeight: 24,
            color: "white",
            textShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
            userSelect: 'none'
          }}
        >
          {`${formatMessage({ id: LOCALIZATION.lens })}`}#{panelId}
        </div>
      )}
      <Footer
        visible={footer}
        position={position}
        matrix={matrix}
        setMatrix={setMatrix}
        bbox={bbox}
        image={image}
      />
    </>
  );
};
