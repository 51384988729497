// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { useCurrentFont } from '../../../../../Configuration/Styles/Fonts';
import LOCALIZATION from '../../../../../Localization';
import MainButton from '../../../../_BaseUI/MainButton';
import useStyles from './useStyles';


type Props = Readonly<{
  onApply: () => void;
  onCancel: () => void;
} & WrappedComponentProps>;


const CardHeader: React.FC<Props> = (props: Props) => {
  const theme = useTheme()
  const { font } = useCurrentFont()
  const classes = useStyles({ ...theme, fontFamily: font });

  return (
    <div className={ classes.toolbar }>
      <Grid container direction="row" justifyContent="space-between" spacing={ 1 } alignItems="center">
        <Grid item>
          <div className={ classes.header }>{ props.intl.formatMessage({ id: LOCALIZATION.configure_chart }) }</div>
        </Grid>
        <Grid item xs>
          <div className={ classes.topButtons }></div>
          <Grid container direction="row" spacing={ 1 } justifyContent="flex-end" wrap="nowrap">
            <Grid item>
              <MainButton title={ props.intl.formatMessage({ id: LOCALIZATION.apply }) } onClicked={ props.onApply }/>
            </Grid>
            <Grid item>
              <MainButton title={ props.intl.formatMessage({ id: LOCALIZATION.cancel }) } onClicked={ props.onCancel }/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
};


export default injectIntl(CardHeader);
