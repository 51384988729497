// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { getAnalyticsBackend, getJavaBackend, getNodeJSBackend } from './Configuration/ENV';


export const PAGE_LIMIT = 100;
export const JAVA_BACKEND_URL = getJavaBackend();
export const NODE_JS_BACKEND_URL = getNodeJSBackend();
export const ANALYTICS_BACKEND_URL = getAnalyticsBackend();
export const MAIN_BACKEND_HOST_URL = JAVA_BACKEND_URL.replace('/api', '');

export const PATHS = {
  login: "/login",
  oAuth: "/oauth",
  base64: "/base64",
};
export const EMPTY_ARRAY: Array<any> = [];
export const MAX_EVENT_DURATION = 6
