// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { PlatformConfiguration } from "../../../Configuration/PlatformConfiguration";
import { EMPTY_ARRAY } from "../../../constants";
import { getAccessToken } from "../../../Tools/token";
import ApiErrorProcessor from "../AxiosFetch/Errors/Prosessors";
import ApiError from "../AxiosFetch/Errors/Types";
import BaseCommandExecutor from "../AxiosFetch/Executor";
import { BaseCommand, BaseCommandScope } from '../AxiosFetch/Types';
import ResponseMany from "./ResponseMany";
import ResponseManyMeta from "./ResponseMeta";


type FetchOptions = Readonly<{
  limit: number;
  offset: number;
}>;

enum ErrorResponseType {
  InvalidToken = 'InvalidToken',
}

export type ErrorResponse = Readonly<{
  errorType: ErrorResponseType;
  httpStatus: number;
  message: string;
}>;


const refreshToken = async <T>(errorResponse: ErrorResponse, success: () => Promise<T>): Promise<T> => new Promise((resolve, reject) => {
  const apiErrorProcessor = new ApiErrorProcessor();
  const apiError: ApiError = {
    error: new Error(errorResponse.message),
    statusCode: errorResponse.httpStatus,
    scope: 'signin',
  };

  const successResponse = () => {
    success().then((data: T) => {
      resolve(data);
    }).catch(reject);
  };

  apiErrorProcessor.process(
    apiError,
    successResponse,
    reject
    // () => {
    //   throw new Error(errorResponse.message);
    // }
  );
});

// const isErrorResponse = <T>(object: T | ErrorResponse): object is ErrorResponse => ('errorType' in object);

const fetchJavaManyJSONData = async <T>(input: string, name: BaseCommandScope, options: FetchOptions, init?: RequestInit): Promise<ResponseMany<T[]>> => new Promise((resolve, reject) => {
  try {
    const command = new BaseCommand(input, name, 'GET');
    const executor = new BaseCommandExecutor(command);

    const succeeded = (data: any, headers: any) => {
      const count = (data || EMPTY_ARRAY).length;
      const totalCount = headers?.['x-total-count'];
      const total: number = (totalCount && !isNaN(parseInt(totalCount, 10))) ?
        parseInt(totalCount, 10)
        :
        0;
      const meta: ResponseManyMeta = {
        status: 200,
        pagination: {
          count,
          limit: options.limit,
          offset: options.offset,
          total,
        },
      }
      const result = {
        data,
        meta,
      } as ResponseMany<T[]>;

      resolve(result);
    };

    executor.execute(succeeded, reject);
  } catch (error) {
    throw error;
  }
})
/*
const fetchJavaManyJSONData = async <T>(input: RequestInfo, options: FetchOptions, init?: RequestInit): Promise<ResponseMany<T[]>> => {
  try {
    const accessToken = getAccessToken();
    const response = await fetch(
      input,
      {
        ...init,
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
          ['Authorization', accessToken || ''],
          ['X-API-VERSION', PlatformConfiguration.apiVersion],
        ],
      },
    );

    const data = await response.json() as T[];
    // console.log({ response, data });

    if (isErrorResponse(data)) {
      const callback = async () => {
        // console.log('callback');
        return await fetchJavaManyJSONData<T>(input, options, init);
      };

      return await refreshToken<ResponseMany<T[]>>(data, callback);
    }

    const count = (data || EMPTY_ARRAY).length;
    const totalCount = response.headers.get('X-TOTAL-COUNT');
    const total: number = (totalCount && !isNaN(parseInt(totalCount, 10))) ?
      parseInt(totalCount, 10)
      :
      0;
    const meta: ResponseManyMeta = {
      status: 200,
      pagination: {
        count,
        limit: options.limit,
        offset: options.offset,
        total,
      },
    }
    const result = {
      data,
      meta,
    } as ResponseMany<T[]>;

    return result;
  } catch (error) {
    throw error;
  }
}
*/

const fetchJSONData = async <T>(input: RequestInfo, init?: RequestInit, onError?: (error: ErrorResponse) => void): Promise<T> => {
  try {
    const accessToken = getAccessToken();
    const response = await fetch(input, {
      ...init,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken || "",
        "X-API-VERSION": PlatformConfiguration.apiVersion,
        ...init?.headers,
      },
    });

    const result = await response.json();

    if (response.status === 403) {
      const callback = async () => {
        return await fetchJSONData<T>(input, init, onError);
      };

      return await refreshToken<T>({
        errorType: ErrorResponseType.InvalidToken,
        httpStatus: 403,
        message: response.statusText,
      }, callback);
    }

    if ((result as ErrorResponse)?.errorType) {
      if (onError) {
        onError(result);
      } else {
        throw new Error(result?.message || result?.errorType || 'Error');
      }
    }

    return result as T;
  } catch (error) {
    throw error;
  }
}


const fetchImage = async (input: RequestInfo, init?: RequestInit): Promise<string> => {
  try {
    const accessToken = getAccessToken();
    const response = await fetch(
      input,
      {
        // ...init,
        headers: [
          ['Accept', 'image/jpeg'],
          // ['Content-Type', 'image/jpeg'],
          ['Authorization', accessToken || ''],
          ['X-API-VERSION', PlatformConfiguration.apiVersion],
        ],
      },
    );

    const blob = await response.blob();

    return URL.createObjectURL(blob);
  } catch (error) {
    throw error;
  }
}


export {
  fetchImage,
  fetchJavaManyJSONData,
};

export default fetchJSONData;
