// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import AnalyticsTeamsColorPalettePicker from '../../../Components/_BaseUI/AnalyticsTeamsColorPalettePicker';
import Button from '../../../Components/_BaseUI/Button';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import HorizGrid from '../../../Components/_Layout/HorizGrid';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { ANALYTICS_BACKEND_URL } from '../../../constants';
import AnalyticsGameArchive from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import TeamRef from '../../../Data/Analytics/AnalyticsTeamRef';
import useFetchAllPagesTeamRefs from '../../../Data/Analytics/AnalyticsTeamRef/hook';
import { fetchImage } from '../../../Data/_Networking/ReactQuery/fetch';
import { GamesTeamImagesSwapBtn } from '../../../Icons/Icons';
import LOCALIZATION from '../../../Localization';

const useElementsStyles = makeStyles<any, { fontFamily: FontName }>(() => ({
  teamImageSelectTitle: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.heading,
    fontWeight: Sizes.regularWeight,
    color: Colors.mainTitle,
    paddingBottom: "20pt",
  },
  teamImage: {
    overflow: 'hidden',
    minWidth: "100px",
    width: "97%",
    aspectRatio: "16/12",
    objectFit: "cover",
    backgroundColor: Colors.backgroundGrey,
    borderRadius: '8px',
    border: 'solid 1px #0000004C',
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "1%",
    marginBottom: "-1%",
  },
  teamImageSelectTeamName: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.heading,
    fontWeight: Sizes.boldWeight,
    color: Colors.mainTitle,
    textAlign: 'center',
  },
}));

type Props = Readonly<{
  game?: AnalyticsGameArchive;
  onTeamImageChange: (team1ImageIndex: number, team2ImageIndex: number) => void;
  onTeam1ColorChange: (team1Color: string | undefined) => void;
  onTeam2ColorChange: (team1Color: string | undefined) => void;
} & WrappedComponentProps>;

const TeamImageSelectView: React.FC<Props> = (props: Props) => {
  const [team1Img, setTeam1Img] = React.useState<string | null>(null);
  const [team2Img, setTeam2Img] = React.useState<string | null>(null);

  const [team1UrlImgRef, setTeam1UrlImgRef] = React.useState<HTMLImageElement | null>(null);
  const [team2UrlImgRef, setTeam2UrlImgRef] = React.useState<HTMLImageElement | null>(null);
  const { font } = useCurrentFont()
  const elementsClasses = useElementsStyles({ fontFamily: font });

  const { allCached: teams, } = useFetchAllPagesTeamRefs();

  const team1ImageIndex = props.game?.team1_markup_id

  const getTeamByExternalId = (externalTeamId: string | undefined): TeamRef | undefined => {
    if (externalTeamId) {
      const foundTeam = teams?.data.find((team: TeamRef) => (team.externalTeamId === externalTeamId))
      return foundTeam;
    }
  };

  const getTeamNameByExternalId = (externalTeamId: string | undefined, teamIndex: 1 | 2): string => {
    const name = getTeamByExternalId(externalTeamId)?.name;
    if (name) {
      return name;
    }
    return props.intl.formatMessage({ id: (teamIndex === 1) ? LOCALIZATION.event_home_team : LOCALIZATION.event_guest_team })
  };

  const team1Name = getTeamNameByExternalId(props.game?.team1, 1);
  const team1ShortName = getTeamByExternalId(props.game?.team1)?.shortName || team1Name;
  const team1Color=props.game?.team1_color || getTeamByExternalId(props.game?.team1)?.color

  const team2Name = getTeamNameByExternalId(props.game?.team2, 2);
  const team2ShortName = getTeamByExternalId(props.game?.team2)?.shortName || team2Name;
  const team2Color=props.game?.team2_color || getTeamByExternalId(props.game?.team2)?.color

  const teamImageUrl = (teamIndex: number) => (
    `${ANALYTICS_BACKEND_URL}/games/${props.game?.id || "3fa85f64-5717-4562-b3fc-2c963f66afa6"}/team_split_images/${teamIndex}`
  )

  const placeholaderImgUrl = "/placeholder-operator.jpg"

  const downloadTeam1Image = async () => {
    try {
      if (team1UrlImgRef) {
        const img = team1Img || await fetchImage(teamImageUrl(0));
        team1UrlImgRef.setAttribute('src', img);
        setTeam1Img(img);

        team1UrlImgRef.onerror = (error) => {
          team1UrlImgRef?.setAttribute('src', placeholaderImgUrl);
        }
      }
    } catch (ignore) {
    }
  };
  const downloadTeam2Image = async () => {
    try {
      if (team2UrlImgRef) {
        const img = team2Img || await fetchImage(teamImageUrl(1));
        team2UrlImgRef.setAttribute('src', img);
        setTeam2Img(img);

        team2UrlImgRef.onerror = (error) => {
          team2UrlImgRef?.setAttribute('src', placeholaderImgUrl);
        }
      }
    } catch (ignore) {
    }
  };

  React.useEffect(() => {
    downloadTeam1Image();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team1UrlImgRef]);

  React.useEffect(() => {
    downloadTeam2Image();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team2UrlImgRef]);

  return (
    <Box>
        <Box className={elementsClasses.teamImageSelectTitle} >
        { props.intl.formatMessage({ id: LOCALIZATION.analytics_select_player_match_team }) }
        </Box>

        <Grid container spacing={ 1 } alignItems="center" >

        {/* Titles */}
        <Grid item xs={ 5.5 }>
          <HorizGrid spacing={ 1 }>
            <AnalyticsTeamsColorPalettePicker
              mainTeamName={ team1ShortName }
              mainTeamColor={ team1Color }
              otherTeamName={ team2ShortName }
              otherTeamColor={ team2Color }
              onMainTeamColorChange={(color) => {
                props.onTeam1ColorChange(color);
              }}
            />
            <Box className={elementsClasses.teamImageSelectTeamName} >
            { team1Name }
            </Box>
          </HorizGrid>
        </Grid>
        <Grid item xs={ 1 }>
        </Grid>
        <Grid item xs={ 5.5 }>
          <HorizGrid spacing={ 1 }>
            <AnalyticsTeamsColorPalettePicker
              mainTeamName={ team2ShortName }
              mainTeamColor={ team2Color }
              otherTeamName={ team1ShortName }
              otherTeamColor={ team1Color }
              onMainTeamColorChange={(color) => {
                props.onTeam2ColorChange(color);
              }}
            />
            <Box className={elementsClasses.teamImageSelectTeamName} >
            { team2Name }
            </Box>
          </HorizGrid>
        </Grid>

        {/* Images */}
        <Grid item xs={ 5.5 }
          sx={{
            position:"relative"
          }}
        >
          <img
            ref={ (!team1ImageIndex) ? setTeam1UrlImgRef : setTeam2UrlImgRef }
            className={ elementsClasses.teamImage }
            alt={ team1Name }
            src={ placeholaderImgUrl }
          />
          {(!team1Img) &&
            <Box sx={{
              position: 'absolute',
              zIndex: 10,
              top: '20px',
              left: '20px',
              bottom: '20px',
              right: '20px',
            }}>
              <Spinner />
            </Box>
          }
        </Grid>
        <Grid item xs={ 1 }>
            <Button
            sx={{
                minWidth: '10px',
                paddingLeft: '5px',
                paddingRight: '5px',
            }}
            onClick={ () => {
                const team1Index = (!team1ImageIndex) ? 1 : 0;
                const team2Index = (!team1Index) ? 1 : 0;
                props.onTeamImageChange(team1Index, team2Index);
            }}
            >
            <GamesTeamImagesSwapBtn />
            </Button>
        </Grid>
        <Grid item xs={ 5.5 }
          sx={{
            position:"relative"
          }}
        >
          <img
            ref={ (!team1ImageIndex) ? setTeam2UrlImgRef : setTeam1UrlImgRef }
            className={ elementsClasses.teamImage }
            alt={ team2Name }
            src={ placeholaderImgUrl }
          />
          {(!team2Img) &&
            <Box sx={{
              position: 'absolute',
              zIndex: 10,
              top: '20px',
              left: '20px',
              bottom: '20px',
              right: '20px',
            }}>
              <Spinner />
            </Box>
          }
        </Grid>
        </Grid>
    </Box>
  );
};


export default injectIntl(TeamImageSelectView);
