import { isNil } from "lodash";
import { useEffect, useState } from "react";

export const useFirstDefinedValue = <T>(nextValue: T | undefined) => {
  const [value, setValue] = useState<T | undefined>(nextValue);

  useEffect(() => {
    if (isNil(value) && !isNil(nextValue)) {
      setValue(nextValue);
    }
  }, [value, nextValue]);

  return value;
};
