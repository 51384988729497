export const GreenLine = ({ className }: { className: string }) => <svg className={className} width="25" height="9" viewBox="0 0 25 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6657_6786)">
        <circle cx="13" cy="4.5" r="3" stroke="#5DCA8A" stroke-width="2" />
        <path d="M10 4.5H0.5M16 4.5H25" stroke="#5DCA8A" stroke-width="3" stroke-dasharray="2 2" />
    </g>
    <defs>
        <clipPath id="clip0_6657_6786">
            <rect width="24.5" height="8" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
    </defs>

</svg>