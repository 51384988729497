import { useEffect, useState } from "react";
import { catchError, from, lastValueFrom, retry, take } from "rxjs";

const convertImageToBase64 = async (src: string) => {
  const imageElement = document.createElement("img");
  try {
    imageElement.src = src;
    imageElement.crossOrigin = "anonymous";

    // Wait for the image to load or fail
    await new Promise((resolve, reject) => {
      imageElement.onload = resolve;
      imageElement.onerror = () => reject("Ошибка загрузки изображения");
      imageElement.onabort = () => reject("Загрузка изображения была прервана");
    });

    // Convert image to base64 data URL
    const canvas = document.createElement("canvas");
    canvas.width = imageElement.width;
    canvas.height = imageElement.height;
    const context = canvas.getContext("2d");
    context?.drawImage(imageElement, 0, 0);
    return canvas.toDataURL("image/jpeg", 1.0);
  } catch (e) {
    throw e;
  } finally {
    // Cleanup
    imageElement.remove();
  }
};

const useImageToJpeg = (src?: string, dependencies: any[] = []) => {
  const [base64, setBase64] = useState<string | undefined>();

  useEffect(() => {
    if (!src) {
      return;
    }

    // Retry mechanism with proper handling for failed images
    lastValueFrom(
      from(convertImageToBase64(src)).pipe(
        retry({ delay: 1_000 }),
        take(1),
        catchError((error) => {
          console.error(
            "Ошибка при конвертации изображения после всех попыток:",
            error
          );
          return from(Promise.resolve(undefined)); // Handle as undefined when failing
        })
      )
    )
      .then((v) => {
        setBase64(v);
      })
      .catch((error) => {
        console.error("Ошибка при конвертации изображения:", error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, ...dependencies]);

  return { base64, convertImageToBase64 };
};

export default useImageToJpeg;
