import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../../Components/Loader/index";
import Popup from "../../../../../../Components/_Layout/Popup";
import { useCameraShot } from "../../../../../../hooks/useCameraShot";
import useImageDimensions from "../../../../../../hooks/useGetImageDimansions";
import LOCALIZATION from "../../../../../../Localization";
import { RegionForm } from "../RegionForm";
import { Region } from "./Region.interface";
import { withRegion2 } from "./withRegion2";



type Props = Readonly<{
    onClose: () => void
    handleSubmit?: (value: Region) => Promise<void> | void,
    camera: number,
    region: Region,
}>;
const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: 'white',
        padding: '0px',
    }
}))

const Canvas = withRegion2(styled.canvas())

export const ImagePointer = (props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote, camera, region: initialRegion } = props;
    const classes = useStyles();
    const intl = useIntl();
    const ref = useRef<HTMLCanvasElement>(null);
    const { base64, update } = useCameraShot({ camera, interval: Infinity, });
    // const { base64Url: base64, loadingPercents, fetch } = useRandomImg(5000, 5000);
    useEffect(() => { update() }, [update])

    const { height, width } = useImageDimensions(base64) ?? {};
    const imageLoaded = useMemo(() => base64 && width && height, [base64, height, width])

    const [regionToSave, setRegionToSave] = useState<Region>(initialRegion)





    const submitCallback = useCallback(() => {
        handleSubmitRemote?.(regionToSave);
        onClose();
    }, [handleSubmitRemote, onClose, regionToSave])

    const [pixiConf, setPixiConf] = useState<{ resizeTo: HTMLDivElement } | undefined>(undefined);

    const rootGridRef = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            setPixiConf({ resizeTo: node });
        }
    }, []);

    return <Popup
        closeTitle={`${intl.formatMessage({ id: LOCALIZATION.close_btn })}`}
        applyTitle={`${intl.formatMessage({ id: LOCALIZATION.apply })}`}
        isFullScreen
        headerTitle={`${intl.formatMessage({ id: LOCALIZATION.lens })} ${intl.formatMessage({ id: LOCALIZATION.image_enchantments })}`}
        onApply={submitCallback}
        onClose={onClose}
        classes={classes}

    >
        <Grid ref={rootGridRef} height={"100%"} overflow={"hidden"}>

            <Grid paddingLeft={2} container xs={6} xl={6} sm={12}>
                <RegionForm value={regionToSave} onChange={setRegionToSave}></RegionForm>

            </Grid>
            <LoadingComponent isLoading={!imageLoaded || !pixiConf}>

                <Grid container height={"100%"} width={"100%"}>

                    {imageLoaded && pixiConf && <Canvas base64={base64} ref={ref} region={regionToSave} onRegionChanged={setRegionToSave} config={pixiConf} />}
            </Grid>
            </LoadingComponent>
        </Grid>

    </Popup>
}