// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { AppBar, CircularProgress, Dialog, DialogContent, DialogContentText, IconButton, Toolbar, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { Close } from '@mui/icons-material';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';


type Props = Readonly<{
  isVisible: boolean;
  minTimeToShowingLoaderMS?: number;
  closeShowingTimeoutMS?: number;
  header: string;
  text: string;
}>;


const MIN_TIME_TO_SHOWING_LOADER_MS = 3000;
const CLOSE_SHOWING_TIMEOUT_MS = 10000;

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
    '& .MuiCardContent-root': {
      padding: '40px',
      paddingBottom: 0,
    },
  },
  contentRoot: {
    width: '500px',
    padding: '10px 0 70px 0',
    textAlign: 'center',
  },
  contentText: {
    padding: '20px',
  },
  header: {
    backgroundColor: 'lightgray',
  },
  headerText: {
    flex: 1,
    textAlign: 'center',
  },
  mainPlain: {
    margin: '10px 0',
    color: Colors.accentBlue,
  },
}));

const LoaderPopup: React.FC<Props> = (props: Props) => {
  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const closeBtnShowTimeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [startedShowingLoader, setStartedShowingLoader] = React.useState<number>(0);
  const [showCloseBtn, setShowCloseBtn] = React.useState<boolean>(false);
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const {
    minTimeToShowingLoaderMS = MIN_TIME_TO_SHOWING_LOADER_MS,
    closeShowingTimeoutMS = CLOSE_SHOWING_TIMEOUT_MS,
   } = props;

  React.useEffect(() => {
    if (props.isVisible) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      setStartedShowingLoader((new Date()).getMilliseconds());

      // show close btn timer
      if (closeBtnShowTimeout.current) {
        clearTimeout(closeBtnShowTimeout.current);
      }
      closeBtnShowTimeout.current = setTimeout(() => {
        setShowCloseBtn(true);
      }, closeShowingTimeoutMS);
    } else {
      const loaderShowedMS = (((new Date()).getMilliseconds()) - startedShowingLoader);

      if (loaderShowedMS < minTimeToShowingLoaderMS) {
        timeout.current = setTimeout(() => {
          setStartedShowingLoader(0);
        }, (minTimeToShowingLoaderMS - loaderShowedMS));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  React.useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (closeBtnShowTimeout.current) {
      clearTimeout(closeBtnShowTimeout.current);
    }
  }, []);

  if (startedShowingLoader === 0) {
    return null;
  }

  return (
    <Dialog
      open
      className={ classes.root }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar
        style={ { position: 'relative' } }
      >
        <Toolbar>
          <Typography
            className={ classes.headerText }
            variant="h6"
            component="div"
          >
            { props.header }
          </Typography>

          { (showCloseBtn) &&
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                setStartedShowingLoader(0);
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
          }
        </Toolbar>
      </AppBar>
      <DialogContent
        className={ classes.contentRoot }
      >
        <DialogContentText
          id="alert-dialog-description"
          component={ 'div' }
        >
          <div
            className={ classes.contentText }
          >
            { props.text }
          </div>
          <CircularProgress
            className={ classes.mainPlain }
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};


export default LoaderPopup;
