// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Checkbox, Grid } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import StreamAutocompleteSelect from '../../../Components/_AutocompleteSelect/StreamAutocompleteSelect';
import TeamAutocompleteSelect from '../../../Components/_AutocompleteSelect/TeamAutocompleteSelect';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import MainButton, { ButtonType } from '../../../Components/_BaseUI/MainButton';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import Card from '../../../Components/_Layout/Card';
import HorizGrid from '../../../Components/_Layout/HorizGrid';
import Popup from '../../../Components/_Layout/Popup';
import { Colors } from '../../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { MAX_EVENT_DURATION } from '../../../constants';
import { isUserHaveServerAnalyticsAccess } from '../../../Data/AccountUsers/User';
import AuthorisationManager from '../../../Data/Auth/AuthorisationManager';
import Event from '../../../Data/EPG/EpgSchedule/Event';
import EventWithoutID, { EventDestStreamOverride, EventDestStreamOverrideItem, EventPriority, GameRepeatType, GameType, WeekDay } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import Team from '../../../Data/EPG/Team/Team';
import { DestType } from '../../../Data/NapiCameraConfig/Dest';
import { getStreamingProfilesCached } from '../../../Data/NapiStreamingProfile/fetch';
import { STREAM_TYPES_SUPPORTING_OVERRIDE_ARR } from '../../../Data/NapiStreamingProfileOptions';
import LOCALIZATION from '../../../Localization';
import WeekDaysSelector from '../WeekDaysSelector';
import EventPopupErrorComponent from './Error';
import {
  dayjsFrom,
  EventPopupErrors,
  EVENT_PRIORITY_ID_ARR,
  GAME_TYPE_ID_ARR,
  initialEventState,
  // instanceOfEvent,
  isItemValidToSend,
  // isStartDateTimeInPast,
  localizedGameTypeById,
  localizedPriorityById,
  localizedSportNameById,
  SPORT_ID_ARR
} from './helpers';

type Props = Readonly<{
  archiveEventEditMode?: boolean;
  item?: Event | EventWithoutID;
  // page: number;
  onSubmit: (item: Event | EventWithoutID) => void;
  onClose: () => void;
} & WrappedComponentProps>;

const EventsListPopup: React.FC<Props> = (props: Props) => {
  const [errorPopup, setErrorPopup] = React.useState<EventPopupErrors | undefined>(undefined);
  const [item, setItem] = React.useState<Event | EventWithoutID>(props.item || initialEventState());
  const [isItemDefault, setIsItemDefault] = React.useState<boolean>(!props.item);
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  const [dateHelperText, setDateHelperText] = React.useState<string | undefined>(undefined);
  const [warningEventState, setWarningEventState] = React.useState<string | undefined>(undefined);

  const isArchiveEventEditMode = !!props.archiveEventEditMode
  const isStreamsOverride = !!item?.streamOverride?.overrideItems

  const userHaveAnalyticsAccess = isUserHaveServerAnalyticsAccess(AuthorisationManager.shared?.user);

  const isApplyDisabled = isItemValidToSend(item);
  const handleSubmit = () => {
    if (!isApplyDisabled) {
      // const isModOldEventDisabled = (props.item && instanceOfEvent(props.item) && props.item.disabled && !item.disabled);
      // const isJustCreatedEvent = (!props.item);

      // only one time events cant start in pas. ir recurent than can start in past
      // const startInPast = (item.repeatType === GameRepeatType.NoRepeat) && isStartDateTimeInPast(item.startDate, item.startTime)

      // if ((isModOldEventDisabled || isJustCreatedEvent) && startInPast) {
      //   setErrorPopup(EventPopupErrors.EventInvalidStartDate);

      //   return;
      // }

      setIsLoaderShowing(true);
      props.onSubmit(item);
    }
  };

  const handleCloseErrorPopup = () => {
    setErrorPopup(undefined);
  };

  const currentDurationString = () => {
    try {
      const start = dayjsFrom("start", item.startDate, item.startTime, item.endDate, item.endTime)
      const end = dayjsFrom("end", item.startDate, item.startTime, item.endDate, item.endTime)

      // event durattion > 6 hours
      const eventDurationInHours = end?.diff(start, 'hours', true) ?? 0
      const currHours = Math.trunc(eventDurationInHours)
      const currMinutes = Math.trunc((eventDurationInHours - currHours) * 60);

      return props.intl.formatMessage({ id: LOCALIZATION.event_curr_duration },
        {
          currHours: currHours,
          currMinutes: currMinutes.pad(2) })

    } catch (ignore) {
    }
  }

  const dateErrorCheckPattern = (startDate?: string | null, startTime?: string | null, endDate?: string | null, endTime?: string | null): boolean => {
    try {
      const start = dayjsFrom("start", startDate, startTime, endDate, endTime)
      const end = dayjsFrom("end", startDate, startTime, endDate, endTime)

      // event durattion > 6 hours
      const eventDurationInHours = end?.diff(start, 'hours', true) ?? 0
      if (eventDurationInHours > MAX_EVENT_DURATION) {
        setDateHelperText(props.intl.formatMessage({ id: LOCALIZATION.event_invalid_max_duration },
          { max: MAX_EVENT_DURATION }));

        return true;
      }

    } catch (ignore) {
    }
    return false;
  };

  const streamsToOverrideArr = (streamProfileID: string | undefined | null): EventDestStreamOverrideItem[] | undefined => {
    if (!streamProfileID) {
      return undefined
    }

    // check prev profile already match
    if (item?.streamOverride?.profileId === streamProfileID && item?.streamOverride?.overrideItems?.length) {
      return item?.streamOverride?.overrideItems;
    }

    const streamingProfiles = getStreamingProfilesCached();
    const streamingProfile = streamingProfiles?.data.find(({ id }) => (id === streamProfileID));

    if (streamingProfile?.custom?.destStreamBlock?.length) {
      const destStreamBlocks = streamingProfile?.custom?.destStreamBlock?.filter((block) => (block.type === DestType.RTMP))

      const overrideItems: EventDestStreamOverrideItem[] = destStreamBlocks?.map((block, index) => (
                            {
                              orderNumber: index,
                              id: block.id,
                              key: block.key,
                              type: block.type
                            }
                          ))
                          ?.filter((block) => (STREAM_TYPES_SUPPORTING_OVERRIDE_ARR.includes(block.id) ))


      const dublicatedIdArr = overrideItems?.map((block) => (block.id))
          .filter((e, i, a) => a.indexOf(e) !== i) // [2, 4]
      if (dublicatedIdArr.length) { // have duplicates in stream names need to show indexes to separate in UI
        for (const item of overrideItems) {
          item.uiOrderNumber = item.orderNumber + 1
        }
      }

      return overrideItems;
    }

    return [];
  };
  const streamsToOverrideData = (streamProfileID: string | undefined | null): EventDestStreamOverride | null => {
    if (!streamProfileID) {
      return null
    }
    const overrideItems = streamsToOverrideArr(streamProfileID);
    if (overrideItems) {
      return {
        profileId: streamProfileID,
        overrideItems
      }
    }
    return null
  }

  React.useEffect(() => {
    try {

      if (item.deleted) { // deleted event
        setWarningEventState(props.intl.formatMessage({ id: LOCALIZATION.deleted }));
        return;
      }

      if (item.disabled) { // disabled event
        setWarningEventState(props.intl.formatMessage({ id: LOCALIZATION.event_status_disabled_event }));
        return;
      }

      const start = dayjsFrom("start", item.startDate, item.startTime, item.endDate, item.endTime)
      const end = dayjsFrom("end", item.startDate, item.startTime, item.endDate, item.endTime)

      // end date if erlier then start
      const endD = dayjsFrom("endDate", item.startDate, item.startTime, item.endDate, item.endTime)
      if (endD && start?.isAfter(endD)) {
        setWarningEventState(props.intl.formatMessage({ id: LOCALIZATION.event_end_before_start_date }));
        return;
      }

      const now = dayjs()
      if (item.repeatType === GameRepeatType.NoRepeat) { // non repeat event

        // start erlier then now and not recurent
        if (start?.isBefore(now)) {
          if (end?.isBefore(now)) {// event ended
            setWarningEventState(props.intl.formatMessage({ id: LOCALIZATION.event_status_ended_event }));
          }
          else {
            setWarningEventState(props.intl.formatMessage({ id: LOCALIZATION.event_status_start_in_past }));
          }
          return;
        }
      }
      else {// repeat event

        // repeat event with end in past
        if (endD?.isBefore(now)) {
          setWarningEventState(props.intl.formatMessage({ id: LOCALIZATION.event_status_recurring_end_in_past }));
          return;
        }
      }

      setWarningEventState(undefined);
    } catch (ignore) {
    }
    // eslint-disable-next-line
  }, [item]);
  const { font } = useCurrentFont()

  return (
    <>
      <Popup
        isWide
        headerTitle={ props.intl.formatMessage({ id: (isArchiveEventEditMode) ? LOCALIZATION.archive : LOCALIZATION.event }) }
        applyTitle={ props.intl.formatMessage({ id: LOCALIZATION.save }) }
        isApplyDisabled={ (isApplyDisabled || isLoaderShowing || errorPopup !== undefined) }
        isCloseDisabled={ isLoaderShowing }
        onApply={ handleSubmit }
        onClose={ props.onClose }
      >
        <Grid
          item
          xs={ 12 }
        >
          <Card>
            {
              (isLoaderShowing) ?
              <>
              <br /><br /><Spinner /><br /><br />
              </>
                :
                <form
                  action={ window.location.href }
                  onSubmit={ handleSubmit }
                >
                  {(warningEventState && !isArchiveEventEditMode) &&
                    <FieldSet
                      key={ 'warning' }
                      label={ props.intl.formatMessage({ id: LOCALIZATION.event_warning }) }
                      // helperText={ 'Team name' }
                      input={ {
                        type: FieldSetInputType.StaticLabel,
                        valueType: 'warning',
                        value: warningEventState,
                      } }
                    />
                  }

                  <FieldSet
                    key={ 'Name' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_name }) }
                    // helperText={ 'Team name' }
                    tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_name_help }) }
                    input={ {
                      type: FieldSetInputType.Text,
                      value: item.name,
                      onChange: (name: string) => ( setItem({ ...item, name, }) ),
                    } }
                  />
                  <FieldSet
                    key={ 'subTitle' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_sub_title }) }
                    tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_sub_title_help }) }
                    // helperText={ 'Team name' }
                    input={ {
                      type: FieldSetInputType.Text,
                      value: item.subTitle || "",
                      onChange: (subTitle: string) => ( setItem({ ...item, subTitle, }) ),
                    } }
                  />
                  <FieldSet
                    key={ 'Game Type' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_game_type }) }
                    // helperText={ 'Short team name' }
                    tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_game_type_help }) }
                    input={ {
                      type: FieldSetInputType.Select,
                      value: item.gameType,
                      options: GAME_TYPE_ID_ARR.map((gameTypeId) => ( {id: gameTypeId, name: localizedGameTypeById(gameTypeId) } )),
                      onChange: (gameType: string) => ( setItem({ ...item, gameType: (gameType as GameType) })),
                    } }
                  />
                  <FieldSet
                    key={ 'Sport Type' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_sport_type }) }
                    // helperText={ 'Short team name' }
                    input={ {
                      type: FieldSetInputType.Select,
                      value: item.sportType,
                      options: SPORT_ID_ARR.map((sportId) => ( {id: sportId, name: localizedSportNameById(sportId) } )),
                      onChange: (sportType: string) => ( setItem({ ...item, sportType }) ),
                    } }
                  />
                  <FieldSet
                    key={ 'Priority' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_priority }) }
                    // helperText={ 'Short team name' }
                    tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_priority_help }) }
                    input={ {
                      type: FieldSetInputType.Select,
                      value: item.priority,
                      options: EVENT_PRIORITY_ID_ARR.map((priority) => ( {id: priority, name: localizedPriorityById(priority) } )),
                      onChange: (priority: string) => ( setItem({ ...item, priority: (priority as EventPriority) })),
                    } }
                  />
                  {(!isArchiveEventEditMode) && // do not change date&time in archive edit because this will break mp4 files connect by name with this event
                  <FieldSet
                    key={ 'Start date' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_start_date }) }
                    hideSeparator={true}
                    input={ {
                      type: FieldSetInputType.Date,
                      fullWidth: true,
                      value: {
                        date: item.startDate,
                      },
                      onChangeDate: (startDate: string | null) => ( (startDate) && setItem({ ...item, startDate, }) ),
                      errorCheckPattern: (date) => ( dateErrorCheckPattern(date, item.startTime, item.endDate, item.endTime) ),
                    } }
                    onErrorTooltipText={ dateHelperText }
                  />
                  }

                  {(!isArchiveEventEditMode) && // do not change date&time in archive edit because this will break mp4 files connect by name with this event
                  <FieldSet
                    key={ 'Time period' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_time_period }) }
                    tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_invalid_max_duration },  { max: MAX_EVENT_DURATION }) }
                    input={ {
                      type: FieldSetInputType.TimeTillTime,
                      value: {
                        time1: item.startTime,
                        time2: item.endTime
                      },
                      onChangeTime1: (startTime: string) => ( setItem({ ...item, startTime}) ),
                      onChangeTime2: (endTime: string) => ( setItem({ ...item, endTime}) ),
                      errorCheckPattern1: (date) => ( dateErrorCheckPattern(item.startDate, date, item.endDate, item.endTime) ),
                      errorCheckPattern2: (date) => ( dateErrorCheckPattern(item.startDate, item.startTime, item.endDate, date) ),
                      helpText: currentDurationString(),
                    }
                    }
                    onErrorTooltipText={ dateHelperText }
                  />
                  }

                  {(!isArchiveEventEditMode) && // do not change date&time in archive edit because this will break mp4 files connect by name with this event
                  <FieldSet
                    key={ 'Repeat' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_repeat }) }
                    hideSeparator={item.repeatType !== GameRepeatType.NoRepeat}
                    input={ {
                      type: FieldSetInputType.Select,
                      value: item.repeatType,
                      options: [
                        {
                          id: 'noRepeat',
                          name: props.intl.formatMessage({ id: LOCALIZATION.event_repeat_no }),
                        },
                        {
                          id: 'everyWeek',
                          name: props.intl.formatMessage({ id: LOCALIZATION.event_repeat_every_week }),
                        },
                      ],
                      children: (
                        <WeekDaysSelector
                          days={ (item.repeatType === GameRepeatType.NoRepeat) ? [] : item.repeatDays }
                          isDisabled={ (item.repeatType === GameRepeatType.NoRepeat) }
                          onChange={ (repeatDays: WeekDay[]) => ( setItem({ ...item, repeatDays }) ) }
                        />
                      ),
                      onChange: (repeatType: string) => ( setItem({ ...item, repeatType: (repeatType as GameRepeatType)}) ),
                    } }
                  />
                  }

                  {(item.repeatType !== GameRepeatType.NoRepeat) &&
                    <FieldSet
                      key={ 'End date' }
                      label={ props.intl.formatMessage({ id: LOCALIZATION.event_end_date }) }
                      input={ {
                        type: FieldSetInputType.Date,
                        allowRemoveDate: true,
                        fullWidth: true,
                        noValueText: props.intl.formatMessage({ id: LOCALIZATION.event_no_event_date }),
                        value: {
                          date: item.endDate || null,
                        },
                        onChangeDate: (endDate: string | null) => {
                          if (endDate === 'null') {
                            setItem({ ...item, endDate: null });
                          } else {
                            setItem({...item, endDate});
                          }
                        },
                        errorCheckPattern: (date) => ( dateErrorCheckPattern(item.startDate, item.startTime, date, item.endTime) ),
                      } }
                      onErrorTooltipText={ dateHelperText }
                    />
                  }

                  <FieldSet
                    key={ 'Home Team' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_home_team }) }
                    tooltipQuestionText={props.intl.formatMessage({ id: LOCALIZATION.event_teams_help })}
                    hideSeparator={true}
                    input={ {
                      type: FieldSetInputType.Other,
                      children: (
                        <TeamAutocompleteSelect
                          selectedTeamID={ item.homeTeamID || undefined }
                          onSelect={ (homeTeamID: Team['id'] | null) => ( setItem({ ...item, homeTeamID }) ) }
                        />
                      ),
                    } }
                  />
                  <FieldSet
                    key={ 'Guest Team' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_guest_team }) }
                    tooltipQuestionText={props.intl.formatMessage({ id: LOCALIZATION.event_teams_help })}
                    input={ {
                      type: FieldSetInputType.Other,
                      children: (
                        <TeamAutocompleteSelect
                          selectedTeamID={ item.guestTeamID || undefined }
                          onSelect={ (guestTeamID: Team['id'] | null) => ( setItem({ ...item, guestTeamID }) ) }
                        />
                      ),
                    } }
                  />
                  {(!isArchiveEventEditMode) &&
                  <FieldSet
                    key={ 'Stream' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_stream_type }) }
                    tooltipQuestionText={props.intl.formatMessage({ id: LOCALIZATION.event_stream_type_help })}
                    hideSeparator={isStreamsOverride && !!item?.streamOverride?.overrideItems?.length}
                    forceShowonErrorTooltipText={isStreamsOverride && !item?.streamOverride?.overrideItems?.length}
                    onErrorTooltipText={ props.intl.formatMessage({ id: LOCALIZATION.event_profile_nothing_override_error }) }
                    input={ {
                      type: FieldSetInputType.Other,
                      children: (
                        <HorizGrid>
                          <Box sx={{flexGrow: 1}}>
                            <StreamAutocompleteSelect
                              selectedStreamProfileID={ item.streamProfileID || undefined }
                              autoselectFirstIfNoId={ isItemDefault }
                              onSelect={(streamProfileID) => {
                                const streamOverride: EventDestStreamOverride | null = (isStreamsOverride) ? streamsToOverrideData(streamProfileID) : null
                                setItem({ ...item, streamProfileID, streamOverride });
                                setIsItemDefault(false);
                              } }
                            />
                          </Box>
                          <MainButton
                            buttonType={ ButtonType.Icon }
                            iconButton={(isStreamsOverride) ? <EditOffIcon /> : <EditIcon />}
                            sx={{
                              marginLeft: 1
                            }}
                            onClicked={()=>{
                              const streamOverride: EventDestStreamOverride | null = (isStreamsOverride) ? null : streamsToOverrideData(item.streamProfileID)
                              setItem({ ...item, streamOverride })
                            }}
                          />
                        </HorizGrid>
                      ),
                    } }
                  />
                  }
                  {
                    (isStreamsOverride && !!item?.streamOverride?.overrideItems?.length) &&
                      <Box sx={{
                        fontFamily: font,
                        fontSize: Sizes.titleFiled,
                        fontWeight: Sizes.regularWeight,
                        color: Colors.mainTitle,
                        textAlign: "center"
                      }}>
                        { props.intl.formatMessage({ id: LOCALIZATION.event_key_override_header }) }
                      </Box>
                  }
                  {
                    (isStreamsOverride && !!item?.streamOverride?.overrideItems?.length) &&
                      item.streamOverride.overrideItems.map((overrideItem, index, array) => (
                        <FieldSet
                        key={ overrideItem.key ?? overrideItem.id }
                          label={props.intl.formatMessage({ id: LOCALIZATION.event_key_override_item },
                                {"name": overrideItem.id, "number": ((overrideItem.uiOrderNumber !== undefined) ? ` #${overrideItem.uiOrderNumber}` : "")})
                              }
                        labelSx={{
                          justifyContent: "end"
                        }}
                        hideSeparator={index < array.length - 1}
                        input={ {
                          type: FieldSetInputType.Text,
                          value: overrideItem.urlKey ?? "",
                          onChange: (urlKey: string) => {
                            overrideItem.urlKey = urlKey;
                            setItem({ ...item, streamOverride: item.streamOverride })
                          },
                        } }
                        />
                      ))
                  }
                  <FieldSet
                    key={ 'description' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_description }) }
                    tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_description_help }) }
                    hideSeparator={true}
                    input={ {
                      type: FieldSetInputType.Text,
                      rows: 4,
                      value: item.description || "",
                      onChange: (description: string) => ( setItem({ ...item, description, }) ),
                    } }
                  />
                  { (userHaveAnalyticsAccess) &&
                    <FieldSet
                      key={ 'AnalyticsPromocode' }
                      label={ props.intl.formatMessage({ id: LOCALIZATION.event_analytics_promocode }) }
                      // helperText={ 'Team name' }
                      tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_analytics_promocode_hint }) }
                      hideSeparator={true}
                      input={ {
                        type: FieldSetInputType.Text,
                        value: item.immediateMarkupPromocode || "",
                        onChange: (promocode: string) => ( setItem({ ...item, immediateMarkupPromocode: promocode, }) ),
                      } }
                    />
                  }
                  {(!isArchiveEventEditMode) &&
                  <FieldSet
                    key={ 'Disabled' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_disabled }) }
                    input={ {
                      type: FieldSetInputType.Other,
                      children: (
                        <Checkbox
                          defaultChecked={ !!item.disabled }
                          value={ !!item.disabled }
                          color="primary"
                          onChange={ (event: any, disabled: boolean) => ( setItem({ ...item, disabled }) ) }
                        />
                      ),
                    } }
                  />
                  }
                  { (props.item?.deleted) &&
                    <FieldSet
                      key={ 'Deleted' }
                      label={ props.intl.formatMessage({ id: LOCALIZATION.event_deleted }) }
                      input={ {
                        type: FieldSetInputType.Other,
                        children: (
                          <Checkbox
                            defaultChecked={ !!item.deleted }
                            value={ !!item.deleted }
                            color="primary"
                            onChange={ (event: any, deleted: boolean) => ( setItem({ ...item, deleted: ((deleted) ? props.item?.deleted : null)  }) ) }
                          />
                        ),
                      } }
                    />
                  }
                </form>
            }
          </Card>
        </Grid>
      </Popup>
      {
        (errorPopup !== undefined) &&
          <EventPopupErrorComponent
            errorType={ errorPopup }
            onClose={ handleCloseErrorPopup }
          />
      }
    </>
  );
};


export default injectIntl(EventsListPopup);
