import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { NODE_JS_BACKEND_URL } from "../../../../constants";
import { getAccessToken } from "../../../../Tools/token";

const downloadPto = (props: { uuid: string }): Promise<Blob | undefined> => {
  const { uuid } = props;

  return fetch(`${NODE_JS_BACKEND_URL}/raw-mapping-pto-archiever/${uuid}`, {
    method: "GET",
    headers: {
      Authorization: getAccessToken() ?? "",
      "X-API-VERSION": "1",
    },
  }).then((res) => {
    if (res.status !== 200) {
      return;
    }
    return res.blob();
  });
};

export const usePtoDownloadCacheKey = "usePtoDownloadCacheKey";

export const useDownloadPto = ({
  options,
  uuid,
}: {
  uuid: string;
  options?: Omit<
    UseQueryOptions<Blob | undefined, unknown, Blob | undefined, QueryKey>,
    "queryKey" | "queryFn"
  >;
}) => {
  const queryClient = useQueryClient();
  const query = useQuery<Blob | undefined>(
    [usePtoDownloadCacheKey, uuid],
    () => downloadPto({ uuid }),
    {
      ...options,
    }
  );
  const fetchData = async () => {
    return queryClient.fetchQuery([usePtoDownloadCacheKey, uuid], () =>
      downloadPto({ uuid })
    ); // Prefetch the query
  };
  const abort = async () => {
    await queryClient.cancelQueries({
      predicate: (queries) => {
        return (
          queries.queryKey.includes(uuid) &&
          queries.queryKey.includes(usePtoDownloadCacheKey)
        );
      },
    });
  };

  return {
    ...query,
    fetchData,
    abort,
  };
};
