import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { useIntl } from "react-intl"
import { CameraIntervalImage } from "../../../../../Components/CameraIntervalImage"
import LoadingComponent from "../../../../../Components/Loader"
import MainButton, { ButtonType } from "../../../../../Components/_BaseUI/MainButton"
import { useUploadPto } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useUploadPto"
import { useDownloadPto } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useDownloadPto"
import { useZonePointCounts } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useZonePointCounts"
import { useCameraCommonSettings } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"
import { useAllCameraShots } from "../../../../../hooks/useAllCameraShots"
import { useCameraShot } from "../../../../../hooks/useCameraShot"
import { useDownloadBlob } from "../../../../../hooks/useDownloadBlob"
import { useFilesSelector } from "../../../../../hooks/useFilesSelector"
import { BreakpointEnum, useWidth } from "../../../../../hooks/useWidth"
import { useZip } from "../../../../../hooks/useZip"
import { AdjustButtonIcon, RefreshIcon2 } from "../../../../../Icons/Icons"
import LOCALIZATION from "../../../../../Localization/index"
import { ImageAdjust } from "../../ImageAdjust/ImageAdjust"
import { useCameraImageContext } from "../CameraImageProvider"
import { PanoramaStitching } from "./PanoramaStitching"
import { SensitiveStitching } from "./SensitiveStiching/SensitiveStiching"
import { useSaveRawCoefficent } from "./useSaveCylinderCoefficent"

const MainContainer = styled(Grid)`
        width: 100%;
                height: 100%;

`

const ImagesContainer = styled(Grid)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
     padding-top: 120px;
    padding-bottom: 20px;
    flex-wrap: nowrap;
`

const ZoneGrid = styled(Grid)`
    position: relative;
    width: 100%;
    display: flex;
    width: min-content;
`

const ZoneVertical1 = ((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const Component = styled.div`
    position: absolute;
    border-right: 3px solid black;
    transform: translate(50%,0%);
    height: calc(300% );
    top: 0;
    border-color:#A0A0A0;
    z-index: -1;
    right: 50%;
    `;
    return <Component {...props} />
});

const ZoneVertical2 = ((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const Component = styled.div`
    position: absolute;
    border-right: 3px solid black;
    transform: translate(50%,0%);
    height: calc(100% );
    right: 0;
    top: 0;
    border-color:#A0A0A0;
    z-index: 1;
    `;
    return <Component {...props} />
});

const ZoneButton = styled.div`
    right: 0;

    position: absolute;
    width:auto;
    transform: translate(50%,-200%);
    z-index: 2;
`

const ZoneSettingButton = styled(MainButton)`
    width:auto;
    min-width: fit-content;
    margin-left: auto;
    transform: translate(50%,0%);
    bottom: 0;
    z-index: 2;
`



const AdjustingColor = ((props: Parameters<typeof MainButton>[0]) => {
    const size = useWidth();
    const Component = styled(MainButton)`
    width: auto;
    min-width: fit-content;
    padding-left: ${BreakpointEnum.sm >= size ? '20px' : '40px'};
    padding-right:${BreakpointEnum.sm >= size ? '20px' : '40px'};`
    return <Component {...props} />
})

const AdjustingColorRefresh = styled(MainButton)`
    width: auto;
    min-width: fit-content;
`



export const CalculateForm = ({ uuid }: { uuid: string }) => {
    const { data: commonSettings } = useCameraCommonSettings()
    const [panoramaModal, setPanoramaModal] = useState<boolean>(false)
    const [panoramaSensitiveModal, setSensitivePanoramaModal] = useState<boolean>(false)
    const { formatMessage } = useIntl()

    const cameras = useMemo(() => (new Array(commonSettings?.configState.config.camera["num-cameras"])).fill(0).map((_, i) => i), [commonSettings?.configState.config.camera["num-cameras"]])

    const [camerasToStitch, setCamerasToStitch] = useState<[number, number]>([0, 1]);

    const { isLoading: countinZonePoints, data: zonePointsInfo } = useZonePointCounts({ uuid });

    const { download } = useDownloadBlob()

    const [generatingZip, setGeneratingZip] = useState<boolean>(false)
    const { fetch: fetchShots } = useAllCameraShots({})
    const { fetchData, } = useDownloadPto({
        uuid, options: {
            enabled: false,
        }
    })
    const { zip: runZip } = useZip()

    const { openModal: choosePto } = useFilesSelector({ accept: '.pto', multiply: false })

    const downloadZipWithImages = useCallback(async () => {

        try {
            setGeneratingZip(true)
            const shots = await fetchShots()

            const pto = await fetchData()
            const files = shots.filter(v => Boolean(v.blob)).map(({ camera, blob }) => ({ name: `${camera}.jpeg`, content: blob as unknown as Blob }));
            pto && files.push({ content: pto, name: "stiching.pto" })
            const blob = await runZip(files);
            download(blob)
        }
        catch {
            alert(`error`)
        }
        finally {
            setGeneratingZip(false)

        }
    }, [download, fetchData, fetchShots, runZip])
    const { mutateAsync: uploadPTO, isLoading: uploadingPTO } = useUploadPto()
    const { set, patch, state, } = useSaveRawCoefficent()

    const uploadPto = useCallback(async () => {
        const pto = await choosePto();
        const base64 = pto?.[0].split('base64,')?.[1];
        if (!base64) {
            return;
        }
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
        }

        const byteArray = new Uint8Array(byteArrays);
        const file = new Blob([byteArray], { type: "application/pto" });
        await uploadPTO({ file, uuid }).then(({ config }) => {
            set(prevconfig => {
                return { ...prevconfig, "raw-mapping": config?.["raw-mapping"] }
            })
        })
    }, [choosePto, uploadPTO, uuid])
    const [adjustImageModal, setAdjustImageModal] = useState<number | null>(null)

    const { getShot, isLoading: gettingCameraShot } = useCameraShot({})

    const { setImage, images } = useCameraImageContext()
    const refreshImage = useCallback(async (camera: number) => {
        const src = await getShot(camera)
        setImage(camera, src)
    }, [getShot, setImage])


    return <>

        <MainContainer className={css`
            `} container>
            <LoadingComponent className={css`width: 100%;`} ugly isLoading={countinZonePoints || !cameras.length || generatingZip || uploadingPTO || gettingCameraShot}>

                <ImagesContainer item container>
                    {cameras.map((v) => <>
                        <ZoneGrid className={css`width: inherit;`} item key={v}>
                            <Grid className={css`width: 100%;`} container>
                                <Grid className={css`width: 100%;`} marginBottom={2} xs={12}>
                                    <div className={css`
                                aspect-ratio: 16/9;
                                /* max-height: 320px; */
                                width: 100%;
                            `} >
                                        <CameraIntervalImage overrideSrc={images?.find?.(c => c.camera === v)?.src} className={css`width: 100%;`} height={320} />

                                    </div>
                                </Grid>
                                <Grid className={css`
                            align-items: center;
                            justify-content: center;
                        `} container gap={1} xs={12}>
                                    <Grid className={css`
                               margin-left: ${v !== cameras.length - 1 ? "auto" : "initial"};
                            `} item>
                                        <AdjustingColor buttonType={ButtonType.Outline} onClicked={() => setAdjustImageModal(v)} title={`${formatMessage({ id: LOCALIZATION.adjust_colors })}`} />
                                        {adjustImageModal === v && createPortal(<ImageAdjust handleSubmit={() => { setAdjustImageModal(null); }} camera={v} onClose={() => { setAdjustImageModal(null); }} />, document.body)}

                                    </Grid>
                                    <Grid item>

                                        <AdjustingColorRefresh onClicked={() => refreshImage(v)} buttonType={ButtonType.Outline} title={<RefreshIcon2 />} />
                                    </Grid>
                                    {v !== cameras.length - 1 && <ZoneSettingButton onClicked={() => {
                                        setCamerasToStitch([v, v + 1])
                                        setSensitivePanoramaModal(true);
                                        // alert(`${formatMessage({ id: LOCALIZATION.under_development })}`)
                                    }} buttonType={ButtonType.Outline} title={<AdjustButtonIcon stroke="rgb(24, 160, 251)" />} />}

                                </Grid>
                            </Grid>
                            {v !== cameras.length - 1 && zonePointsInfo && (<><ZoneButton><MainButton isDisabled={true} onClicked={() => {
                                setCamerasToStitch([v, v + 1])
                                setPanoramaModal(true);
                            }} buttonType={ButtonType.Outline} title={<>

                                {` ${formatMessage({ id: LOCALIZATION.zone }).capitalizeFirstLetter()} #${v + 1}`}
                            </>} />
                                <ZoneVertical1 />
                            </ZoneButton>
                                <ZoneVertical2 /></>)}
                        </ZoneGrid>

                    </>)}


                </ImagesContainer>
                <Grid item className={css`
                display: flex;
                    align-items: center;
                            justify-content: center;
                `} xs={12}
                    paddingLeft={2} paddingRight={2}
                >
                    <MainButton onClicked={downloadZipWithImages} className={css`
                        width: auto;
                        padding-left: 20px;
                        padding-right: 20px;

                    `}
                        buttonType={ButtonType.Outline}
                        title={`${formatMessage({ id: LOCALIZATION.download_images_for_external_tool })}`} />

                </Grid>
                <Grid marginTop={2} item className={css`
                display: flex;
                    align-items: center;
                            justify-content: center;
                `} xs={12} paddingLeft={2} paddingRight={2}>
                    <MainButton onClicked={uploadPto} className={css`
                        width: auto;
                        padding-left: 20px;
                        padding-right: 20px;
                    `} title={`${formatMessage({ id: LOCALIZATION.upload_pto_stitching_file })}`} />
                </Grid>
            </LoadingComponent>

        </MainContainer>
        {
            panoramaModal && <PanoramaStitching uuid={uuid} camerasToStitch={camerasToStitch} onClose={() => setPanoramaModal(false)} onApply={() => setPanoramaModal(false)} />
        }

        {
            panoramaSensitiveModal && <SensitiveStitching onClose={() => setSensitivePanoramaModal(false)} onApply={() => setSensitivePanoramaModal(false)} />
        }



    </>
}