import { cloneDeep } from "lodash"
import { useIntl } from "react-intl"
import LoadingComponent from "../../../../../../Components/Loader/index"
import FieldSet, { FieldSetInputType } from "../../../../../../Components/_BaseUI/FieldSet/index"
import { useCameraCommonSettings } from "../../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"
import LOCALIZATION from "../../../../../../Localization/index"
import { useStoreEditorState } from "../useStoreEditorState"

export const ImageFilesStoreSettings = () => {
    const { formatMessage } = useIntl()
    const { data: commonSettings, isLoading: gettinCommon } = useCameraCommonSettings()
    const { state: storeState, patch, set } = useStoreEditorState()

    // Определяем варианты для селекта
    const panoramaImageStoreIntervalOptions = [
        { id: "-1", name: formatMessage({ id: LOCALIZATION.off }) },
        { id: "1", name: `1 ${formatMessage({ id: LOCALIZATION.sec })}` },
        { id: "2", name: `2 ${formatMessage({ id: LOCALIZATION.sec })}` },
        { id: "5", name: `5 ${formatMessage({ id: LOCALIZATION.sec })}` },
        { id: "10", name: `10 ${formatMessage({ id: LOCALIZATION.sec })}` },
        { id: "30", name: `30 ${formatMessage({ id: LOCALIZATION.sec })}` },
    ]

    return (
        <>
            <LoadingComponent isLoading={gettinCommon}>
                <FieldSet labelSize={2} inputSize={true} input={{
                    type: FieldSetInputType.Text,
                    value: storeState.config.dest?.["jpeg-dir"] ?? "/var/run/camera-handler",
                    onChange: (v) => {
                        const storeStateC = cloneDeep(storeState);
                        if (storeStateC.config.dest?.["jpeg-dir"]) {
                            storeStateC.config.dest["jpeg-dir"] = v;
                        }
                        set(storeStateC);
                    },
                }} label={formatMessage({ id: LOCALIZATION.images_dir_path })}></FieldSet>

                <FieldSet labelSize={2} inputSize={true} input={{
                    type: FieldSetInputType.Text,
                    value: storeState.config.operator?.external ?? "/var/run/camera-handler/camera",
                    onChange: (v) => {
                        const storeStateC = cloneDeep(storeState);
                        if (storeStateC.config.operator?.external) {
                            storeStateC.config.operator.external = v;
                        }
                        set(storeStateC);
                    },
                }} label={formatMessage({ id: LOCALIZATION.operator_image_store_file_path })}></FieldSet>

                {/* Обновленный компонент для выбора интервала обновления изображения панорамы */}
                <FieldSet labelSize={2} inputSize={true} input={{
                    type: FieldSetInputType.Select,
                    options: panoramaImageStoreIntervalOptions,
                    value: storeState.config.original?.["full-frame-jpeg"]?.toString() ?? '-1',
                    onChange: (v) => {
                        const storeStateC = cloneDeep(storeState);
                        if (storeStateC.config.original?.["full-frame-jpeg"] !== undefined) {
                            storeStateC.config.original["full-frame-jpeg"] = Number(v);
                        }
                        set(storeStateC);
                    },
                }} label={formatMessage({ id: LOCALIZATION.panorama_image_store_interval })}></FieldSet>
            </LoadingComponent>
        </>
    )
}
