// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage'
import PICard from './Cards/PI/PICard'
import PowerCard from './Cards/Power/Card'
import { JetsonIdAll } from '../../Data/Camera/Jetson/Types'
import JetsonCard from './Cards/Jetson/Card'
import HardwareStatisticsCard from './Cards/Statistics/Card'
import CaseHeaterCard from './Cards/CaseHeater/Card'
import CameraDirectionCard from './Cards/Directions/Card'
import PageHeader from '../../Components/_Layout/PageHeader';
import { Grid, Box } from '@mui/material';
import LOCALIZATION from '../../Localization';
import { useActiveHeatersIds, useCameraData } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import { isCameraSettingsOptionActive } from '../../Data/Camera/HWCameraSettings/CameraSettingsActiveOptions';

const HardwarePage: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {

  const camera = useCameraData()
  const activeHeaterIdArr = useActiveHeatersIds()
  const isLoaderShowing = (!camera)
  const isPiCardVisible = (camera?.settings && isCameraSettingsOptionActive(camera?.settings?.activeOptions?.havePi))
  const isGyroscopeVisible = (camera?.settings && isCameraSettingsOptionActive(camera?.settings?.activeOptions?.haveGyroscope))

  if (isLoaderShowing) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  return (
    // maggic -20px margin to fix internal margin of grid
    <Box sx={{ marginRight: '-20px' }}> 
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <PageHeader heading={ props.intl.formatMessage({ id: LOCALIZATION.page_hardware_heading }) }/>
          <ErrorMessage/>
        </Grid>

        {
          // if PICard not visible then move DC PowerCard to bottom. because else it a hollow in UI
          Boolean(isPiCardVisible) &&
            <>
              <Grid item xs={ 12 } md={ 6 }>
                <PICard settings = {camera?.settings}/>
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <PowerCard settings = {camera?.settings}/>
              </Grid>
            </>
        }

        { 
          JetsonIdAll.map(jetsonId => {
            return (
              <Grid item xs={ 12 } key={ jetsonId }>
                <JetsonCard id={ jetsonId } settings = {camera?.settings}/>
              </Grid>
            )
          }) 
        }
        <Grid item xs={ 12 }>
          <HardwareStatisticsCard/>
        </Grid>

        {
          Boolean(isGyroscopeVisible) &&
            <Grid item xs={ 12 } md={ 6 }>
              <CameraDirectionCard settings = {camera?.settings}/>
            </Grid>
        }

        {
          Boolean(!isPiCardVisible) &&
            <Grid item xs={ 12 } md={ 6 }>
              <PowerCard settings = {camera?.settings}/>
            </Grid>
        }

        { 
          activeHeaterIdArr?.map(heaterId => {
            return (
              <Grid item xs={ 12 } md={ 6 } key={ heaterId }>
                <CaseHeaterCard id={ heaterId }/>
              </Grid>
            )
          }) 
        }     
        </Grid>
    </Box>
  )
};

export default injectIntl(HardwarePage);
