// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ButtonsInRowElement, { ButtonsInRowArray } from '../../Components/_BaseUI/ButtonsInRowElement';
import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage';
import MainButton from '../../Components/_BaseUI/MainButton';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import { Colors } from '../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../Configuration/Styles/Fonts';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { useCameraSettings } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import { ExposedConfigurationID } from '../../Data/NapiExposedConfigurations/ExposedConfigurationID.enum';
import { useConfigByName } from '../../Data/NapiExposedConfigurations/hooks/useConfigByName';
import LOCALIZATION from '../../Localization';
import CameraApiRequestSelector from './Components/CameraApiRequestSelector/View';
import JsonEditor from './Components/JsonEditor/View';
import CameraApiRequestsRepository from './Data/Repository';
import { ApiRequestConfig, ApiRequestConfigInterface, ApiRequestMethod } from './Types';
import { SelectorAPIOption, SelectorOption } from './Types/SelectorOption.type';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  container: {
    background: Colors.secondaryBackground
  },
  page_heading: {
    fontWeight: Sizes.boldWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.caption,
    color: Colors.headingMain
  },
  root: {
    flexGrow: 1
  }
}));
/** @deprecated Overengeneering */
const repository = new CameraApiRequestsRepository()

const napirequests: SelectorAPIOption[] =
  [{
    name: ExposedConfigurationID.useConfig,
    methods: [
      ApiRequestMethod.GET,

    ]
  },
    {
      name: ExposedConfigurationID.useIndividualCamera,
      methods: [
        ApiRequestMethod.GET,
        ApiRequestMethod.POST,

      ]
    },
    {
      name: ExposedConfigurationID.useOverlays,
      methods: [
        ApiRequestMethod.GET,
        ApiRequestMethod.POST,

      ]
    },
    {
      name: ExposedConfigurationID.useUndistortion,
      methods: [
        ApiRequestMethod.GET,
        ApiRequestMethod.POST,

      ]
    },
    {
      name: ExposedConfigurationID.useBaseConfig,
      methods: [
        ApiRequestMethod.GET,
        ApiRequestMethod.POST,

      ]
    },
    {
      name: ExposedConfigurationID.useCameraConfig,
      methods: [
        ApiRequestMethod.GET,
        ApiRequestMethod.POST,

      ]
    },
    {
      name: ExposedConfigurationID.useStreamingLegacy,
      methods: [
        ApiRequestMethod.GET,
        ApiRequestMethod.POST,
      ]
    }
  ].map<SelectorAPIOption>(item => new SelectorAPIOption(item))


const CameraAPIPage: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const [apiFetchRequests, setApiFetchRequests] = useState<SelectorOption[]>([...repository.apiRequests, ...napirequests])

  const [currentApiFetchRequest, setCurrentApiFetchRequest] = useState<SelectorOption>(apiFetchRequests[0])
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })
  const [input, setInput] = useState<any | undefined>(undefined)
  const [output, setOutput] = useState<any | undefined>(undefined)

  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const cameraSettings = useCameraSettings(true);

  const isOutputOk: boolean = (output && input)

  function handleCurrentApiRequestConfigChanged(apiRequestConfig: SelectorOption) {
    setCurrentApiFetchRequest(apiRequestConfig)
    updateInput(undefined)
  }

  function processApiFetchConfigs(apiApiRequests: ApiRequestConfigInterface[]) {
    setApiFetchRequests([...apiApiRequests, ...napirequests])
    if (!currentApiFetchRequest && apiApiRequests.length > 0) {
      handleCurrentApiRequestConfigChanged(apiApiRequests[0])
    }
  }

  function update(): void {
    setIsLoading(true)
    repository.update().then(() => {
      setIsLoading(false)
      setError(undefined)
      processApiFetchConfigs(repository.apiRequests)
      // console.log('CamerasPageDetails > updated')
    }).catch(error => {
      setIsLoading(false)
      setError(error)
      // console.log('CamerasPageDetails > failed to update: ' + error?.message)
    })
  }

  const updateInput = (value: any) => {
    setIsLoading(false)
    setError(undefined)
    setInput(value)
    setOutput(undefined)
  }

  const updateError = (error: Error, hideJson: boolean) => {
    if (hideJson) {
      setInput(undefined)
    }
    setOutput(undefined)
    setIsLoading(false)
    setError(error)
  }

  const name = useMemo(() => {
    console.log(currentApiFetchRequest)
    return currentApiFetchRequest.name
  }, [currentApiFetchRequest])

  const { data, fetch, set } = useConfigByName(name);


  const handleApiRequestMethod = (method: ApiRequestMethod) => {
    const apiRequestConfig = currentApiFetchRequest
    if (apiRequestConfig instanceof ApiRequestConfig) {
      switch (method) {
        case 'GET':
          setIsLoading(true)
          repository.getRequest(apiRequestConfig.name, (value) => {
            updateInput(value)
          }, (error) => {
            updateError(error, true)
          })
          break
        case 'POST':
          if (!output) {
            return
          }
          if (!window.confirm(props.intl.formatMessage({ id: LOCALIZATION.post_camera_api_confirm }))) {
            return
          }
          setInput(output)
          setIsLoading(true)

          repository.postRequest(apiRequestConfig.name, output, (value, message) => {
            updateInput(value)
            setError(Error(message)) // this is temp solution to show message as error
          }, (error) => {
            updateError(error, false)
          })
          break
      }
      return;
    }
    if (apiRequestConfig instanceof SelectorAPIOption) {
      switch (method) {
        case 'GET':
          setIsLoading(true)
          if (!data) {
            fetch?.().then((value) => {
              updateInput(JSON.stringify(value))

            }).catch((error) => {
              updateError(error, true)
            })
          } else {
            updateInput(JSON.stringify(data))
          }
          break
        case 'POST':
          if (!output) {
            return
          }
          if (!window.confirm(props.intl.formatMessage({ id: LOCALIZATION.post_camera_api_confirm }))) {
            return
          }
          setInput(output)
          setIsLoading(true)

          set?.(output).then((value) => {
            updateInput(JSON.stringify(value))
          }).catch((error) => {
            updateError(error, false)
          })

          break
      }
      return;
    }


  }

  const handleJsonChange = (json: any) => {
    setOutput(json)
  }

  const handleJsonWrong = () => {
    setOutput(undefined)
  }

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const requestMethods = currentApiFetchRequest?.methods ?? []

  const urlForDebug = (baseUrl?: string): string | undefined => (baseUrl?.replace("{static_dir}", "ccdebug"))

  const buttons: ButtonsInRowArray = []

  const linkPi = urlForDebug(cameraSettings?.urls?.staticPiTemplate);
  if (linkPi) {
    buttons.push( {
      text: `${props.intl.formatMessage({ id: LOCALIZATION.page_camera_api_title })} PI`,
      bordered: true,
      onClick: () => ( window.open(linkPi) ),
    })
  }

  const linkJ1 = urlForDebug(cameraSettings?.urls?.staticJ1Template);
  if (linkJ1) {
    if (buttons.length !== 0) {
      buttons.push( {
        space : 15 ,
      })
    }
    buttons.push( {
      text: `${props.intl.formatMessage({ id: LOCALIZATION.page_camera_api_title })} J#1`,
      bordered: true,
      onClick: () => ( window.open(linkJ1) ),
    })
  }

  const linkJ2 = urlForDebug(cameraSettings?.urls?.staticJ2Template);
  if (linkJ2) {
    if (buttons.length !== 0) {
      buttons.push( {
        space : 15 ,
      })
    }
    buttons.push( {
      text: `${props.intl.formatMessage({ id: LOCALIZATION.page_camera_api_title })} J#2`,
      bordered: true,
      onClick: () => ( window.open(linkJ2) ),
    })
  }
  buttons.push( {
    space : -1 ,
  })

  return (
    <div className={ classes.root }>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } style={{ paddingBottom: '10pt'}}>
          <ButtonsInRowElement
            id = "buttonsRow1"
            buttons={ buttons }
          />
        </Grid>

        <Grid item xs={ 12 }>
          <Grid container spacing={ 2 } justifyContent="space-between">
            {/* Camera API Header */}
            <Grid item>
              <Grid container spacing={ 2 }>
                <Grid item>
                  <div className={ classes.page_heading }> { props.intl.formatMessage({ id: LOCALIZATION.page_camera_api_heading }) } </div>
                </Grid>
                <Grid item>
                  <CameraApiRequestSelector sources={ apiFetchRequests } currentSource={ currentApiFetchRequest }
                    onChanged={handleCurrentApiRequestConfigChanged} />
                </Grid>
              </Grid>
            </Grid>

            {/* GET,POST buttons */}
            <Grid item>
              <Grid container spacing={ 1 }>
                { requestMethods.map(method => {
                  let isEnabled = true
                  switch (method) {
                    case 'POST':
                      isEnabled = isOutputOk
                      break
                    case 'GET':
                      break
                  }

                  return (
                    <Grid item key={ method }>
                      <MainButton title={ method } onClicked={ () => {
                        handleApiRequestMethod(method)
                      } } isDisabled={ !isEnabled }/>
                    </Grid>
                  )
                }) }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={ 12 }>
          <ErrorMessage error={ error } local/>
          <Spinner isHidden={ !isLoading }/>
        </Grid>
        {(input) &&
          <Grid item xs={ 12 } key="editor">
              <JsonEditor
                value={ input }
                onChanged={ handleJsonChange }
                onWrong={ handleJsonWrong }
              />
            </Grid>
        }
      </Grid>
    </div>
  )
};

export default injectIntl(CameraAPIPage);
