import { gql } from "graphql-request";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useNAPIGraphQLClient } from "../../../../../hooks/useNAPIGraphQLClient";
import { PanoramaStoreConfig } from "../PanoramaStoreConfig.interface";

const graphql = gql`
  query GetPanoramaStoreList {
    getPanoramaStoreList
  }
`;
interface IResponse {
  getPanoramaStoreList: PanoramaStoreConfig[];
}

interface IUseCurrentStitchingConfigs<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}
export const usePanoramaStoreListCacheKey = "usePanoramaStoreList";

/**
 * @param props
 * @returns
 */
export const usePanoramaStoreList = (
  props?: IUseCurrentStitchingConfigs<IResponse | undefined>
) => {
  const { options } = props ?? {};
  const queryClient = useQueryClient();
  const client = useNAPIGraphQLClient();
  const key = [usePanoramaStoreListCacheKey] as QueryKey;
  const request = () => client?.request<IResponse>(graphql);
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };
  const query = useQuery(key, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });
  const fetchData = async () => {
    return queryClient.fetchQuery(key, request); // Prefetch the query
  };

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
  };
};
