// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ReactNode } from 'react';
import { Fonts, useCurrentFont } from '../../Configuration/Styles/Fonts';

import { Sizes } from "../../Configuration/Styles/Sizes";

export const PlaceholderStyled = ({ children }: { children: ReactNode }) => {

  const { font } = useCurrentFont()
  return <div style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    padding: 20,
    fontSize: Sizes.title,
    fontFamily: font,
    fontWeight: Sizes.mediumWeight,
  }}>{children}</div>
}
export const ChartLabelStyle = {
  fontSize: Sizes.title,
  fontFamily: Fonts.main,
};

export const ChartLegendStyle = {
  fontSize: Sizes.title,
  fontFamily: Fonts.main,
  marginLeft: 30,
};

export const ChartTooltipLabelStyle = {
  ...ChartLabelStyle,
  fontSize: Sizes.heading,
  paddingLeft: 5,
  paddingBottom: 10,
  fontWeight: Sizes.mediumWeight,
};

export const ChartTooltipItemStyle = {
  ...ChartLabelStyle,
  fontSize: Sizes.heading,
  padding: 5,
};

export default PlaceholderStyled;
