// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';
// import utc from 'dayjs/plugin/utc';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import { FieldSetInputType } from '.';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import { useStylesInputBox } from './FieldSetText';
import { getLocale } from './helpers';
import { ReactComponent as DateCalcendarIcon } from './Icons/DateCalcendarIcon.svg';

dayjs.extend(customParseFormat)
// dayjs.extend(utc)

export type FieldSetDate = Readonly<{
  /** if defined then constrol state is stored in browser session and recovered on control present.  */
  sessionStoreKey?: string;
  type: FieldSetInputType.Date;
  noWrapper?: boolean;
  allowRemoveDate?: boolean;
  fullWidth?: boolean;
  noValueText?: string;
  dateFormat?: string;
  value: {
    date: string | null;
  };
  errorCheckPattern?: (value?: string | null) => boolean;
  onChangeDate: (date: string | null) => void;
}>;

type Props = Readonly<{
  input: FieldSetDate;
  labelText?: string;
  isError?: boolean;
  onIsError?: (isError: boolean) => void;
}>;

export function FieldSetDateComponent_StoredValue(sessionStoreKey: string){
  try {
    if (sessionStoreKey?.length) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
      const storedValue = window.sessionStorage.getItem("FieldSetDateComponent-"+sessionStoreKey);
      if (storedValue !== null) {
        return storedValue
      }
    }
  } catch(ignore) {}
  return undefined
}

const FieldSetDateComponent: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStylesInputBox({ fontFamily: font });
  const { localization: { locale } } = useAppConfig();
  const { dateFormat = 'YYYY.MM.DD'} = props.input;

  const date = (): Dayjs | null => {
    if (props.input.value?.date && props.input.value.date.length) {
      return dayjs(props.input.value.date, dateFormat)
    }
    else {
      return (props.input.value?.date === null) ? null : dayjs()
    }
  }

  const [value, setValue] = React.useState<Dayjs | null>(date());

  const userAcceptValue = (newValue: Dayjs | null) => {
    try {
      newValue = (newValue) ? dayjs(newValue) : null
      const newDate = (newValue) ? newValue?.format(dateFormat) : null
      saveNewValue(newDate);
    } catch (ignore) {
      props.input.onChangeDate(dayjs().format(dateFormat));
    }
  }

  const saveNewValue = (newDate: string | null) => {
    try {
      if ((newDate) || (props.input.allowRemoveDate)) {
        if (props.input.sessionStoreKey?.length) {// if enabled then recover stored value from session storage
          window.sessionStorage.setItem("FieldSetDateComponent-"+props.input.sessionStoreKey, newDate || "");
        }

        props.input.onChangeDate(newDate);

        if (props.input.errorCheckPattern && props.onIsError) {
          props.onIsError(props.input.errorCheckPattern(newDate))
        }
      }
    } catch (ignore) {
      props.input.onChangeDate(dayjs().format(dateFormat));
    }
  }

  const renderInput = (params: MuiTextFieldPropsType): React.ReactElement => {

    const showRemoveDate = ((props.input.allowRemoveDate) && (props.input.value.date))

    const handleClick = (event: React.MouseEvent<HTMLLabelElement>) => {
      setValue(null)
      userAcceptValue(null);
      event.preventDefault();
      event.stopPropagation();
    };

    if ((props.input.noValueText) && (params.inputProps) && (!params.inputProps.value)) {
      params.inputProps.value = props.input.noValueText
    }

    return (<TextField
      fullWidth = { props.input?.fullWidth }
      { ...params }
      InputLabelProps={{
        shrink: true
      }}
      InputProps={ {
        className: cn(classes.input, classes.singleLine, {
          [classes.error]: props.isError,
        }),
        endAdornment: (
          <InputAdornment
          position="end"
          disablePointerEvents={ !showRemoveDate }
          >
            {
              (showRemoveDate) ?
                <IconButton
                edge="end"
                  color="warning"
                  aria-label="reset"
                component="label"
                onClick= { handleClick }
                >
                  <HighlightOffIcon />
                </IconButton>
              :
                <DateCalcendarIcon />
            }
          </InputAdornment>
        ),
      } }
      // sx={ { width: '110px' } }
    />)
  };

  if (props.input?.noWrapper === true) {
    return (
      <LocalizationProvider
        locale={ getLocale(locale) }
        dateAdapter={ AdapterDateFns }
      >
        <MobileDatePicker
          label={ props.labelText }
          // inputFormat={ dateFormat }
          value={ value }
          onChange={ (newValue) => {
            setValue(dayjs(newValue));
          } }
          renderInput={ renderInput }
          onAccept={ userAcceptValue }
        />
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider
      locale={ getLocale(locale) }
      dateAdapter={ AdapterDateFns }
    >
      <MobileDatePicker
        label={ props.labelText }
        // inputFormat={ dateFormat }
        value={ value }
        onChange={ (newValue) => {
          setValue(dayjs(newValue));
        } }
        renderInput={ renderInput }
        onAccept={ userAcceptValue }
      />
    </LocalizationProvider>
  );
};


export default FieldSetDateComponent;
