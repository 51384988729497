// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import cn from 'classnames';
import React from 'react';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';

import { MenuElement, MenuID } from '../../Data/MenuElement';
import useStyles from './useStyles';


type Props = Readonly<{
  parentID?: MenuID;
  item?: MenuElement;
  isSelected: boolean;
  iconColor: string;
}>;


const MenuUIElementContent: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const renderIcon = () => {
    if (!props.item?.icon) { return null; }

    const Icon = props.item?.icon;

    return (
      <Icon
        className={ (props.item?.isMenuSection() || props.item?.isMenuMainHeader()) ? classes.sectionIcon :  classes.icon }
        stroke={ props.iconColor }
      />
    );
  };

  const title = (props.item?.title ?? props.item?.id ?? "");

  const renderContent = (
    <>
      { renderIcon() }
      <div
        className={ (props.item?.isMenuMainHeader()) ? classes.mainHeaderText : classes.text }
        title={ title }
      >
        { title }
      </div>
    </>
  );

  const rootClasses = cn(classes.sectionContainer, {
    [classes.sectionContainerSelected]: props.isSelected,
    [classes.sectionContainerRegular]: !props.isSelected,
  });

  return (
    <div
      className={ rootClasses }
    >
      { renderContent }
    </div>
  );
};


export default MenuUIElementContent;
