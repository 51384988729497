import { gql, GraphQLClient } from "graphql-request";
import { useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useCalibrationMode } from "../../../../../hooks/useCalibrationMode";
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";
import { useKickCalibrationMode } from "./useKickCalibrationMode";

const mutationQl = gql`
  mutation CalibrationMode(
    $set: Boolean
    $undistordDisabled: Boolean
    $runActiveUndistord: Boolean
  ) {
    calibrationMode(
      set: $set
      undistordDisabled: $undistordDisabled
      runActiveUndistord: $runActiveUndistord
    ) {
      state
      sessionUUID
      undistordDisabled
      runActiveUndistord
    }
  }
`;

export interface IResponse {
  calibrationMode: {
    state: boolean;
    sessionUUID: string;
    runActiveUndistord: boolean;
  };
}

export interface IPingCalibrationModeProps {}
export interface Request {
  /**
   * Set's the calibration mode if defined
   */
  set: boolean | null;
  undistordDisabled: boolean;
  runActiveUndistord?: boolean;
}

const requestInternal = (req: Request, client: GraphQLClient) =>
  client
    .request<IResponse>(mutationQl, { ...req })
    .then((v) => v.calibrationMode);

export const usePingCalibrationMode = (props?: IPingCalibrationModeProps) => {
  const client = useCameraHandler() ?? new GraphQLClient("");
  const {
    mode: calibrationMode,
    undistordDisabled,
    runActiveUndistord,
  } = useCalibrationMode();
  const { sessionUUID } = useKickCalibrationMode();

  // Состояние для хранения предыдущих данных
  const [previousData, setPreviousData] = useState<
    IResponse["calibrationMode"] | null
  >(null);

  const request = (req?: Partial<Request> | void) => {
    const { set, undistordDisabled: forceUndistordDisabled } = req ?? {};
    return requestInternal(
      {
        set: set ?? calibrationMode,
        undistordDisabled: forceUndistordDisabled ?? undistordDisabled,
        runActiveUndistord: runActiveUndistord,
      },
      client
    );
  };

  const mutation = useMutation(request, {
    retry: 2,
    retryDelay: 1_00,
    onMutate: () => {
      // Сохраняем предыдущие данные перед выполнением мутации
      if (mutation.data) {
        setPreviousData(mutation.data);
      }
    },
    onSuccess: (data) => {
      // Обновляем данные после успешной мутации
      setPreviousData(data);
    },
    onError: () => {
      // В случае ошибки можно оставить предыдущие данные
      // ничего не обновляем, чтобы оставить старое значение
    },
  });

  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    return mutation.mutateAsync(props);
  };

  const pendingToKick = useMemo(() => {
    const outerUUID = mutation.data?.sessionUUID ?? previousData?.sessionUUID;
    if (!outerUUID) {
      return false;
    }
    return sessionUUID !== outerUUID;
  }, [mutation.data?.sessionUUID, previousData?.sessionUUID, sessionUUID]);

  return {
    ...mutation,
    mutate,
    mutateAsync,
    pendingKick: pendingToKick,
    previousData, // Добавляем previousData, если нужно где-то использовать
  };
};
