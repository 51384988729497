import { css } from '@emotion/css';
import { Grid } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import SortedTable, { Props as TableProps } from '../../Components/SortedTable/index';
import Order from '../../Data/_Networking/ReactQuery/Order';
import LOCALIZATION from '../../Localization/index';

// Типизация строки данных
interface Row {
    time: string;
    id: string;
    event?: string;
    min: number;
    average: number;
    max: number;
}

// Типизация заголовков таблицы
interface HeadCell {
    id: keyof Row;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
}

// Пропсы для таблицы
interface Props {
    data?: Row[];
}

export const Table: React.FC<Props> = ({ data }) => {
    const [order, setOrder] = useState<Order>(Order.ASC);
    const [orderBy, setOrderBy] = useState<keyof Row>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { formatMessage, locale } = useIntl();

    // Определяем заголовки столбцов
    const headCells: HeadCell[] = [
        { id: 'id', numeric: false, disablePadding: true, label: '#' },
        { id: 'time', numeric: false, disablePadding: true, label: formatMessage({ id: LOCALIZATION.time }).capitalizeFirstLetter() },
        { id: 'event', numeric: false, disablePadding: true, label: formatMessage({ id: LOCALIZATION.event }).capitalizeFirstLetter() },
        { id: 'min', numeric: false, disablePadding: true, label: formatMessage({ id: LOCALIZATION.min }).capitalizeFirstLetter() },
        { id: 'average', numeric: false, disablePadding: true, label: formatMessage({ id: LOCALIZATION.average }).capitalizeFirstLetter() },
        { id: 'max', numeric: false, disablePadding: true, label: formatMessage({ id: LOCALIZATION.max }).capitalizeFirstLetter() },
    ];

    const handleRequestSort: TableProps<Row>['onRequestSort'] = (property) => {
        const isAsc = orderBy === property && order === Order.ASC;
        setOrder(isAsc ? Order.DESC : Order.ASC);
        setOrderBy(property);
    };

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (limit: number) => {
        setRowsPerPage(limit);
    };

    // Функция для форматирования даты
    const formatDate = (date: string): string => {
        const now = moment();
        const selected = moment(date);

        if (selected.isSame(now, 'day')) {
            return formatMessage({ id: LOCALIZATION.today });
        } else if (selected.isSame(now.clone().add(1, 'days'), 'day')) {
            return formatMessage({ id: LOCALIZATION.tomorrow });
        } else if (selected.isSame(now.clone().subtract(1, 'days'), 'day')) {
            return formatMessage({ id: LOCALIZATION.yesterday });
        } else {

            return selected.locale(locale).format('LL'); // Формат по локали
        }
    };

    const formatDateTime = (date: string): JSX.Element => {
        const time = moment(date).format('LT'); // Формат времени по локали
        return (
            <>
                <div><b>{formatDate(date)}</b></div>
                <div>{time}</div>
            </>
        );
    };

    // Модифицируем строки данных с форматированием времени
    const formattedRows = data?.map(row => ({
        ...row,
        time: formatDateTime(row.time)
    })) ?? [];

    return (
        <Grid className={css`padding: 20px;`} container>
            <SortedTable<typeof formattedRows[0]>
                page={page}
                rowsPerPage={rowsPerPage}
                // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
                order={order}
                orderBy={orderBy}
                manyItems={{
                    data: formattedRows.slice(page * rowsPerPage, rowsPerPage * (page + 1)), meta: {
                        status: 200, pagination: {
                            total: formattedRows.length
                        }
                    }
                }}
                headCells={headCells}
                onRequestSort={handleRequestSort}
                onPageChange={handlePageChange}
                onPeerPageChange={handleRowsPerPageChange}
            />
        </Grid>
    );
};
