// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { clone } from 'lodash';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import FieldSetText from '../../../../Components/_BaseUI/FieldSet/FieldSetText';
import Popup from '../../../../Components/_Layout/Popup';
import { FontName, useCurrentFont } from '../../../../Configuration/Styles/Fonts';
import { useCameraData } from '../../../../Data/Camera/HWCameraSettings/hook/Hooks';
import CameraConfig from '../../../../Data/NapiCameraConfig';
import Audio from '../../../../Data/NapiCameraConfig/Audio';
import Dest from '../../../../Data/NapiCameraConfig/Dest';
import Encoder from '../../../../Data/NapiCameraConfig/Encoder';
import Operator from '../../../../Data/NapiCameraConfig/Operator';
import Original from '../../../../Data/NapiCameraConfig/Original';
import StreamingProfile, { StreamingProfileCustomConfig } from '../../../../Data/NapiStreamingProfile';
import StreamingProfileOptions from '../../../../Data/NapiStreamingProfileOptions';
import LOCALIZATION from '../../../../Localization';
import AudioForm from './Audio';
import { isAllowSubmit, updateProfileItemWithDefaults } from './helpers';
import MainStream from './MainStream';
import SecondaryScoreboardStream from './SecondaryScoreboardStream/index';
import SecondaryStream from './SecondaryStream';


type Props = Readonly<{
  streamingSettingsProfileOptions: StreamingProfileOptions;
  defaultStreamingProfileConfig: CameraConfig;
  item?: StreamingProfile;
  newItemName?: string;
  onSubmit: (item: StreamingProfile) => void;
  onClose: () => void;
} & WrappedComponentProps>;

export type ItemStreamProps = Readonly<{
  custom?: StreamingProfileCustomConfig;
  dest?: Dest;
  encoder?: Encoder;
  operator?: Operator;
  original?: Original;
}>;

const useStyles = makeStyles((theme: Theme) => createStyles<any, { fontFamily: FontName }>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    backgroundColor: '#F5F6F8',

    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    },
  },
}));

const StreamingSettingsProfilePopup: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const [item, setItem] = React.useState<StreamingProfile>(updateProfileItemWithDefaults(props.newItemName, props.item, props.defaultStreamingProfileConfig));
  const isApplyDisabled = !isAllowSubmit(item, props.item);

  const camera = useCameraData(true)
  const isNotProxyMode = (camera?.settings?.activeOptions?.isProxyMode !== "true")

  const handleNameChanged = (name: string) => {
    setItem({
      ...item,
      name,
    });
  };
  const handleChangeAudio = (audio: Audio): void => {
    setItem({
      ...item,
      config: {
        ...item.config,
        audio,
      },
    });
  };
  const handleChangeMainStream = ({ custom, dest, encoder, operator, original }: ItemStreamProps) => {
    const config: CameraConfig = clone(item.config) || {};
    config.dest = dest;
    config.encoder = encoder;
    config.operator = operator;
    config.original = original;

    setItem({
      ...item,
      custom,
      config,
    });
  };
  const handleSubmit = () => {
    // console.log('handleSubmit', item);
    props.onSubmit(item);
  };

  return (
    <Popup
      isWide
      headerTitle={ props.intl.formatMessage({ id: LOCALIZATION.streaming }) }
      isApplyDisabled={ isApplyDisabled }
      onApply={ handleSubmit }
      onClose={ props.onClose }
    >
      <Grid
        className={ classes.root }
      >
        <Grid
          container
          spacing={ 2 }
        >
          <Grid
          item
          xs = { 12 }
          sx = { { marginTop: -1 }}
          >
          <FieldSetText
            input={ {
              value: item.name,
              onChange: handleNameChanged,
            } }
          />
          </Grid>
          {(isNotProxyMode) &&
            <AudioForm
              audio={ item.config?.audio }
              onChange={ handleChangeAudio }
            />
          }
          <MainStream
            item={ {
              custom: item.custom,
              dest: item.config?.dest,
              encoder: item.config?.encoder,
              operator: item.config?.operator,
              original: item.config?.original,
            } }
            defaultStreamingProfileConfig={ props.defaultStreamingProfileConfig }
            videoResolution={ props.streamingSettingsProfileOptions.options.videoResolution }
            streamBlock={ props.streamingSettingsProfileOptions.options.streamBlock }
            isNotProxyMode={ isNotProxyMode }
            onChange={ handleChangeMainStream }
          />
          {(isNotProxyMode) &&
            <SecondaryStream
              item={ {
                custom: item.custom,
                dest: item.config?.dest,
                encoder: item.config?.encoder,
                operator: item.config?.operator,
                original: item.config?.original,
              } }
              onChange={ handleChangeMainStream }
            />
          }
          {(isNotProxyMode) &&
            <SecondaryScoreboardStream
              item={{
                custom: item.custom,
                dest: item.config?.dest,
                encoder: item.config?.encoder,
                operator: item.config?.operator,
                original: item.config?.original,
              }}
              onChange={handleChangeMainStream}
            />
          }
        </Grid>
      </Grid>
    </Popup>
  );
};


export default injectIntl(StreamingSettingsProfilePopup);
