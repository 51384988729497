// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import { Button, Divider } from '@mui/material';
import { Colors } from '../../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';


type Props = Readonly<{
  title: string;
  divider: boolean;
  selected: boolean;
  onClicked: () => void;
}>;



const LanguageItem: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const SX = {
    language_value: {
      minWidth: 30,
      fontFamily: font,
      fontSize: Sizes.small,
      fontWeight: Sizes.mediumWeight,
    },
    divider: {
      background: Colors.darkBlue,
      position: 'relative',
      top: 3,
      height: Sizes.subtitle,
    },
    none: {
      display: 'none',
    },
    follow: {
      display: 'inline-block',
    },
    selected: {
      color: Colors.accentBlue,
    },
    regular: {
      color: Colors.mainTitle,
    },
  };

  let buttonSX = {
    ...SX.language_value,
  };

  if (props.selected) {
    buttonSX = { ...buttonSX, ...SX.selected };
  } else {
    buttonSX = { ...buttonSX, ...SX.regular };
  }

  let splitterSX = {
    ...SX.divider,
  };

  if (props.divider) {
    splitterSX = { ...splitterSX, ...SX.follow };
  } else {
    splitterSX = { ...splitterSX, ...SX.none };
  }

  return (
    <div>
      <Button
        sx={ buttonSX }
        onClick={ props.onClicked }
      >
        { props.title }
      </Button>
      <Divider
        flexItem
        sx={ splitterSX }
        orientation="vertical"
      />
    </div>
  );
};

export default LanguageItem;
