import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { NODE_JS_BACKEND_URL } from "../../../constants";
import { Rule } from "../../../Pages/EventsPage/ImportEpgPopup/types";
import fetchJSONData from "../../_Networking/ReactQuery/fetch";

/**
 * Функция для получения правил из API
 * @returns {Promise<Rule[]>} - Промис с массивом правил
 */
async function fetchRules(): Promise<Rule[]> {
  const response = await fetchJSONData<Rule[]>(
    `${NODE_JS_BACKEND_URL}/epg-rules`
  );
  return response;
}

const CACHE_KEY = ["epgrules"];
/**
 * Кастомный хук для получения правил с возможностью очистки кэша
 * @param options - Опции для useQuery
 * @returns - Объект с данными правил, состояниями и функцией очистки кэша
 */
export function useEpgRules(
  options?: Omit<
    UseQueryOptions<Rule[], Error, Rule[], QueryKey>,
    "queryKey" | "queryFn"
  >
) {
  const queryClient = useQueryClient();

  const query = useQuery<Rule[], Error>(CACHE_KEY, fetchRules, {
    staleTime: Infinity, // Данные считаются устаревшими через 10 минут
    cacheTime: 60 * 60 * 1000, // Кэш хранится в течение 1 часа
    refetchOnWindowFocus: false, // Не перезапрашивать данные при фокусе на окне
    ...options,
  });

  // Функция для очистки кэша
  const clearCache = () => {
    queryClient.invalidateQueries(CACHE_KEY);
  };

  return {
    ...query,
    clearCache,
  };
}
