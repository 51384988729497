// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { Attributes } from 'react';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Team from '../../../Data/Analytics/AnalyticsTeamRef';
import { useCamerasList } from '../../../Data/Camera/ActiveCamera/hook';
import Camera, { CameraType } from '../../../Data/Camera/ActiveCamera/Camera';
import { ResultStatus } from '../../../Data/_Networking/ReactQuery/template';
import AuthorisationManager from '../../../Data/Auth/AuthorisationManager';
import isSuperAdmin from '../../../Data/AccountUsers/tools/isSuperAdmin';
import { UserType } from '../../../Data/AccountUsers/UserWithoutID';
import { getCameraName } from '../../../Pages/CamerasPage/CameraCardBlock/helper';
import { useEffectAfterMount } from '../../../hooks/useEffectAfterMount';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type Props = Readonly<{
  /** if defined then constrol state is stored in browser session and recovered on control present.  */
  sessionStoreKey?: string;
  label?: string;
  selectedCameraID?: string | null;
  noneValueText?: string;
  showRequiredMark?: boolean;
  onSelect: (selectedCCamID: Team['id'] | undefined | null) => void;
}& Attributes>;

const useLabelStyles = makeStyles(() => ({
  outlined: {
    "&:not(.MuiInputLabel-shrink)": {
      marginTop: '-8px',
    }
  }
}));

export function CameraIdSelect_StoredValue(sessionStoreKey: string) {
  try {
    if (sessionStoreKey?.length) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
      const storedValue = window.sessionStorage.getItem("CameraIdSelect-"+sessionStoreKey);
      if (storedValue !== null) {
        return storedValue;
      }
    }
  } catch(ignore) {}
  return undefined;
}

const CameraIdSelect: React.FC<Props> = (props: Props) => {
  const user = AuthorisationManager.shared.user
  const [selectedCameraID, setSelectedCameraID] = React.useState<string>(props.selectedCameraID || ((props.noneValueText?.length) ? "none" : ''));

  const labelClasses = useLabelStyles();

  useEffectAfterMount(() => { // use AfterMount to prevent race when view mounted
    try {
      if (props.sessionStoreKey?.length) {// if enabled then recover stored value from session storage
        window.sessionStorage.setItem("CameraIdSelect-"+props.sessionStoreKey, selectedCameraID);
      }
    } catch(ignore) {}
  }, [selectedCameraID, props.sessionStoreKey]);


  React.useEffect(() => {
    try {
      if (props.sessionStoreKey?.length && !props.selectedCameraID) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
        const storedValue = window.sessionStorage.getItem("CameraIdSelect-"+props.sessionStoreKey);
        if (storedValue !== null && storedValue !== selectedCameraID) {
          setNewCameraId(storedValue);
        }
      }
    } catch(ignore) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const camerasListQuery = useCamerasList({
    offset: 0,
    limit: 100,
    options: {
      refetchInterval: false
    }
  })
  
  const camerasArr = () => {
    let arr: Camera[] = []
    if (camerasListQuery.status === ResultStatus.Success && camerasListQuery?.data?.length) {
      arr = camerasListQuery.data.filter((camera: Camera) => (camera.type === CameraType.Remote))
    }
    if (props.noneValueText?.length) {
      arr.splice(0,0, {
        cameraId: "none", 
        name: props.noneValueText,
      } as Camera);
    }
    return arr
  };

  const renderMenuOptions = () => (
    camerasArr()
    .map((camera: Camera) => (      
      <MenuItem
        key={ camera.cameraId }
        value={ camera.cameraId }
      >
        { `${ (camera.cameraId === "none") ? camera.name : getCameraName (camera, isSuperAdmin(user) , user?.type === UserType.Server) }` }
      </MenuItem>
    ))
  );

  const setNewCameraId = (newCameraId: string) => {
    setSelectedCameraID(newCameraId);
    props.onSelect((newCameraId === "none") ? undefined : newCameraId)
  };
  const handleChange = (event: SelectChangeEvent<typeof selectedCameraID>) => {
    setNewCameraId(event.target.value);
  };
  const showResetValue = (props.noneValueText?.length && selectedCameraID && selectedCameraID !== "none");

  const handleResetValueClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    setNewCameraId("none")
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box sx={{
      maxWidth: 250
    }}>
      <Box key={ props.key || props.label } sx = {{ minWidth: 130 }}>
        <FormControl fullWidth>
          <InputLabel 
            id="select-sort-label" 
            classes={labelClasses}
            required={ props.showRequiredMark }
          >{ props.label }</InputLabel>
          <Select
            IconComponent={ (showResetValue) ? Box : ArrowDropDownIcon }
            labelId="select-sort-label"
            id="select-sort-select"
            value={ selectedCameraID }
            label={ props.label }
            onChange={handleChange}
            sx = {{ height: '38px' }}
            endAdornment={(
              <InputAdornment 
              position="end"
              disablePointerEvents={ !showResetValue }
              >
                {
                  (showResetValue) ?
                    <IconButton 
                    edge="end"
                    color="warning" 
                    aria-label="reset" 
                    component="label"
                    onClick= { handleResetValueClick }
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  :
                    null
                }
              </InputAdornment>
            )}
          >
          { renderMenuOptions() }
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};


export default CameraIdSelect;
