// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage';
import TextEllipsis from '../../Components/_BaseUI/TextEllipsis';
import H2 from '../../Components/_Layout/H2';
import PageContainer from '../../Components/_Layout/PageContainer';
import PageHeader from '../../Components/_Layout/PageHeader';
import { FontName, useCurrentFont } from '../../Configuration/Styles/Fonts';
import { flushStreamingSettingsDefaultProfileConfigCache } from '../../Data/NapiCameraConfig/fetch/defaultProfileConfig';
import StreamingProfile from '../../Data/NapiStreamingProfile';
import { NO_STREAM } from '../../Data/NapiStreamingProfile/constants';
import { flushStreamingProfilesCache, getStreamingProfilesCached } from '../../Data/NapiStreamingProfile/fetch';
import { useFetchStreamingProfiles } from '../../Data/NapiStreamingProfile/hook';
import { flushStreamingSettingsProfileOptionsCache } from '../../Data/NapiStreamingProfileOptions/fetch';
import { StreamingSettings } from '../../Data/NapiStreamingSettings';
import { flushNapiStreamingSettingsCache } from '../../Data/NapiStreamingSettings/fetch';
import { useNapiStreamingSettingsFetch } from '../../Data/NapiStreamingSettings/hook';
import LOCALIZATION from '../../Localization';
import BoxCol2 from './BoxCol2';
import FetchErrorAlert from './FetchErrorAlert';
import { getSelectedProfileID } from './helpers';
import StreamSettingsProfiles from './Profiles';
import StreamingInfo from './StreamingInfo';


type Props = Readonly<{} & WrappedComponentProps>;


const useStyles = makeStyles((theme: Theme) => createStyles<any, { fontFamily: FontName }>({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

const StreamingPage: React.FC<Props> = (props: Props) => {
  const [isErrorFetchProfiles, setIsErrorFetchProfiles] = React.useState<boolean>(false);
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  // streaming/settings.json load every 5 sec
  const streamingSettingsFetchState = useNapiStreamingSettingsFetch();
  const [streamingSettings, setStreamingSettings] = React.useState<StreamingSettings | undefined>();
  const streamingSettingsUpdateTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  let stopStreamingHandler: ()=>void| undefined

  React.useEffect(() => {
    if (streamingSettingsFetchState.status === 'success') {
      if (streamingSettingsFetchState.data?.data) {
        setStreamingSettings(streamingSettingsFetchState.data.data);
      }

      if (streamingSettingsUpdateTimer.current) {
        clearTimeout(streamingSettingsUpdateTimer.current);
      }
      streamingSettingsUpdateTimer.current = setTimeout(() => {
        flushNapiStreamingSettingsCache();
      }, 5000);
    }
  }, [streamingSettingsFetchState.status, streamingSettingsFetchState.data?.data]);

  // const camera = useCameraData();
  // const isMaintenanceModeOn = camera?.settings?.mode === "maintenance"
  const isMaintenanceModeOn = !!streamingSettings && !!streamingSettings.maintenance_mode;

  // streaming/profiles.json
  const streamingProfilesFetchState = useFetchStreamingProfiles();
  const streamingProfilesArrData = getStreamingProfilesCached();
  const isProfilesFetchSuccess = (streamingProfilesFetchState.status === 'success')

  React.useEffect(() => {
    setIsErrorFetchProfiles(streamingProfilesFetchState.status === 'error');
  }, [streamingProfilesFetchState.status]);

  React.useEffect(() => {
    // flushNapiStreamingSettingsCache();
    // flushStreamingSettingsProfileOptionsCache();
    // flushStreamingSettingsDefaultProfileConfigCache();

    return (
      () => {
        if (streamingSettingsUpdateTimer.current) {
          clearTimeout(streamingSettingsUpdateTimer.current);
        }
        flushNapiStreamingSettingsCache();
        flushStreamingSettingsProfileOptionsCache();
        flushStreamingSettingsDefaultProfileConfigCache();
        flushStreamingProfilesCache();
      }
    );
  }, []);

  const handleStopStreaming = () => {
    if (stopStreamingHandler) {
      stopStreamingHandler()
    }
  };
  const storeStopStreamingCallback = (stopHandler:()=>void) => {
    stopStreamingHandler = stopHandler;
  }

  const selectedProfileID = getSelectedProfileID(streamingSettings, streamingProfilesArrData?.data);
  const activeProfile = streamingProfilesArrData?.data.find(( profile: StreamingProfile ) => (profile.id === selectedProfileID)) || NO_STREAM()

  return (
    <PageContainer>

      <Grid
        item
        xs={ 12 }
      >
        <PageHeader
          heading={ props.intl.formatMessage({ id: LOCALIZATION.streaming }) }
        />
        <ErrorMessage/>
      </Grid>

      <BoxCol2
        // contentHeight={ '70vh' }
        title={
        <H2
          className={ classes.nowrap }
        >
          <TextEllipsis>
            { props.intl.formatMessage({ id: LOCALIZATION.select_streaming_settings }) }
          </TextEllipsis>
        </H2>
      }
      >
        {
          (isMaintenanceModeOn) &&
          <TextEllipsis>
            { props.intl.formatMessage({ id: LOCALIZATION.maintenance_mode }) }
          </TextEllipsis>
        }
        {
          (isErrorFetchProfiles) ?
            <FetchErrorAlert />
            :
            <StreamSettingsProfiles
              isMaintenanceModeOn={ isMaintenanceModeOn }
              isSuccessLoadedData={ isProfilesFetchSuccess }
              selectedProfileID={ selectedProfileID }
              profiles={ streamingProfilesArrData?.data }
              stopStreamingCallback={ // receive stop handler and store it for future use
                storeStopStreamingCallback
              }
            />
        }
      </BoxCol2>

      <BoxCol2
        // contentHeight={ '70vh' }
        title={
          <H2
            className={ classes.nowrap }
          >
            <TextEllipsis>
              {
                activeProfile.name
              }
            </TextEllipsis>
          </H2>
        }
      >
        <StreamingInfo
          streamingProfile={ activeProfile }
          handleStopStreaming = { handleStopStreaming }
        />
      </BoxCol2>
    </PageContainer>
  );
};

export default injectIntl(StreamingPage);