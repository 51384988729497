import { javascript } from "@codemirror/lang-javascript";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import CodeMirror from '@uiw/react-codemirror';
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader/index";
import FieldSet, { FieldSetInputType } from "../../../../../Components/_BaseUI/FieldSet/index";
import Card from "../../../../../Components/_Layout/Card/index";
import Popup from "../../../../../Components/_Layout/Popup/index";
import { StitchingCalculations, useCalculateStitching } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useCalibrateStichingPanorama";
import { useCameras } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameras";
import LOCALIZATION from "../../../../../Localization/index";



interface IProps {
    uuid: string,
    onClose: () => void,
    onApply: (value: StitchingCalculations['calculateStitching']['data']) => void,
}


const Header = styled.header`
/* c_h4 (18) */
color: rgb(51, 77, 94);
font-family: Montserrat;
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: -1.5%;
text-align: left;
`
const ZonePart = ({ id, uuid, err, rotation_diff }: { id: number, uuid: string, err?: string, rotation_diff?: string }) => {
    const { formatMessage } = useIntl()

    return <>

        <Grid marginBottom={2}> <Header>
            {`${formatMessage({ id: LOCALIZATION.zone })} #${id + 1}`}
        </Header></Grid>

        <Card contentSx={{
            padding: '20px !important',
            paddingBottom: '30px !important',
        }}>
            <FieldSet label={formatMessage({ id: LOCALIZATION.error })} input={{ type: FieldSetInputType.StaticLabel, value: err }} />
            <FieldSet hideSeparator label={formatMessage({ id: LOCALIZATION.rotation_diff })} input={{ type: FieldSetInputType.StaticLabel, value: rotation_diff }} />
        </Card>
    </>
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))

export const CalculatedCoefficents = (({ uuid, onClose, onApply }: IProps) => {
    const { formatMessage } = useIntl()

    const { data: cameras, isLoading } = useCameras();
    const { data: calibration, mutateAsync: calculateStitching, isLoading: calculating, isError } = useCalculateStitching();
    useEffect(() => {
        if (isError) {
            alert(formatMessage({ id: LOCALIZATION.error_wrong_parameters }))
            onClose()
        }
    }, [formatMessage, isError, onClose])
    const classes = useStyles();
    useEffect(() => {
        calculateStitching({ uuid })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { angle, output, error } = useMemo(() => {
        if (!calibration) {
            return {}
        }
        const error = calibration.calculateStitching.info.averageError;
        const angle = calibration.calculateStitching.info.commonAngle;
        const output = JSON.stringify(calibration.calculateStitching.data, undefined, 2);
        return { error, angle, output }
    }, [calibration])

    const onApplyCallback = useCallback(() => {
        calibration?.calculateStitching.data && onApply(calibration?.calculateStitching.data);
    }, [calibration?.calculateStitching.data, onApply])

    return <>
        <LoadingComponent ugly pretendChildrenInitializing isLoading={isLoading || calculating || !calibration}>
            {calibration && <Popup
                closeTitle={`${formatMessage({ id: LOCALIZATION.close_btn })}`}
                isFullScreen
                headerTitle={`${formatMessage({ id: LOCALIZATION.panorama_image_stitching_cylindrical })}`}
                // isApplyDisabled={loadingList}
                onApply={onApplyCallback}
                onClose={onClose}
                classes={classes}
                applyTitle={`${formatMessage({ id: LOCALIZATION.save })}`}

            >
                <Grid marginBottom={2}>

                    <FieldSet hideSeparator input={{ type: FieldSetInputType.StaticLabel, value: String(error) }} label={formatMessage({ id: LOCALIZATION.average_error_stitching })} />
                    <FieldSet input={{ type: FieldSetInputType.StaticLabel, value: String(angle) }} label={formatMessage({ id: LOCALIZATION.common_angle })} />
                    <div></div>
                </Grid>
                {cameras.map(v => <>
                    {v !== cameras.length - 1 &&

                        <Grid key={v} marginBottom={3}>
                            <ZonePart err={calibration?.calculateStitching.info.pairError[v].toString()} rotation_diff={calibration?.calculateStitching.info.pairRotateDiff[v].toString()} id={v} uuid={uuid} />
                        </Grid>
                    }
            </>)}
                <Card contentSx={{
                    padding: '20px !important',
                    paddingBottom: '30px !important',
                }}> <CodeMirror
                className={css`
            width: 100%;
          `}
                value={String(output)}
                // height="200px"
                extensions={[javascript({ jsx: true })]}
                /></Card>


            </Popup>}
        </LoadingComponent>


    </>
})
