// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import cn from 'classnames';
import React, { ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Theme
} from '@mui/material';
import { ClassNameMap, createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import PopupHeader from './PopupHeader';


type Props = Readonly<{
  align?: 'left' | 'right' | 'center';
  isWide?: boolean;
  isMoreWide?: boolean;
  isFullScreen?: boolean;
  headerTitle: string;
  applyTitle?: string | ReactNode
  closeTitle?: string
  children: any;
  isApplyDisabled?: boolean;
  isCloseDisabled?: boolean;
  disableScroll?: boolean;
  onApply?: () => void;
  onClose?: () => void;
  classes?: ClassNameMap;
  headerChildren?: ReactNode;
  width?: number;
  onEditTitleClick?: () => void;
} & WrappedComponentProps>;


const useStyles = makeStyles((theme: Theme) => createStyles<any, { fontFamily: FontName }>({
  root: {
    '& .MuiCardContent-root': {
      [theme.breakpoints.down('md')]: {
        padding: '40px 10px',
      },
      padding: '40px',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  rootDialog: {
    paddingTop: 0,
    paddingRight: '40px',
    paddingBottom: '30px',
  },
  contentRoot: {
    padding: 0,
    height: "100%",
  },
  alignCenter: {
    textAlign: 'center',
  },
  paperWide: {
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      maxWidth: '100vw',
      minWidth: '100vw',
      height: '100%',
      maxHeight: '100vh',
      minHeight: '100vh',
    },
    minWidth: '699px',
  },
  paperMoreWide: {
    [theme.breakpoints.down('lg')]: {
      width: '100vw',
      maxWidth: '100vw',
      minWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      minHeight: '100vh',
    },
    minWidth: '1200px',
  },
  header: {
    backgroundColor: 'lightgray',
  },
  headerText: {
    flex: 1,
  },
}));

const Popup: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const { headerChildren, } = props;
  const localclasses = useStyles({ fontFamily: font });
  const classes = { ...localclasses, ...props.classes };

  return (
    <Dialog
      open
      fullScreen={ props.isFullScreen }
      onClose={ props.onClose }
      classes={
        (props.isMoreWide) ?
          { paper: classes.paperMoreWide}
        :
        (props.isWide) ?
          { paper: classes.paperWide}
          :
          {}
      }
      className={ cn(classes.root, {
        [classes.alignCenter]: (props.align === 'center'),
      }) }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={ {
        style: {
          borderRadius: '23px',
          background: Colors.mainBackground,
          boxShadow: '0px 4px 16px #00000014',
        }
      }}
    >
      <AppBar
        style={{
          position: 'relative' ,
          borderTopLeftRadius: '23px',
          borderTopRightRadius: '23px',
          overflow: 'hidden',
        }}
      >
        <PopupHeader
          headerTitle={ props.headerTitle }
          applyTitle={ props.applyTitle }
          isApplyDisabled={ props.isApplyDisabled }
          onApply={ props.onApply }
          cancelTitle={ props.closeTitle }
          isCancelDisabled={ props.isCloseDisabled }
          onCancel={props.onClose}
          onEditTitleClick={props.onEditTitleClick}

        >
          {headerChildren}
       </PopupHeader>
        {/* <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={ props.onClose }
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography className={ classes.headerText } variant="h6" component="div">
            { props.header }
          </Typography>
          {
            (props.onApply) &&
              <Grid
                item
              >
                <Index
                  isDisabled={ (props.isApplyDisabled === true) }
                  type="submit"
                  title={ props.intl.formatMessage({ id: LOCALIZATION.button_accept }) }
                  onClicked={ props.onApply }
                />
              </Grid>
          }
        </Toolbar> */}
      </AppBar>
      <DialogContent
        className={ classes.contentRoot }
      >
        {(props.disableScroll) ?
          <Box
            sx={{
              position: "relative",
              height: "100%"
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 3,
                height: "100%",
              }}
            >
              { props.children }
            </Box>
          </Box>
        :
        <DialogContentText
          id="alert-dialog-description"
            component={'div'}
            style={{
              height: "100%"
            }}
        >
          { props.children }
        </DialogContentText>
        }
      </DialogContent>
    </Dialog>
  );
};


export default injectIntl(Popup);
