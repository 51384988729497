// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { enUS, ru, zhCN } from "date-fns/locale";
import { AppLocale } from '../../../Localization/AppContextProvider/helpers';

const dateLocale: { [key in AppLocale]: any } = {
  [AppLocale.En]: enUS,
  [AppLocale.Ru]: ru,
  [AppLocale.Cn]: zhCN,
};

const getLocale = (lang: AppLocale) => {
  let locale: any = dateLocale[AppLocale.En];
  try {
    locale = dateLocale[lang] as any;
  } catch (ignore) {
    // ignore
  }

  return locale;
};


export {
  getLocale,
};
