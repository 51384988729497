import { PropsWithChildren, useEffect, useState } from "react";
import { BehaviorSubject, isObservable, Observable, Subscription } from "rxjs";

type AsyncProps<T> = PropsWithChildren<{
  observable?: Observable<T> | BehaviorSubject<T>;
}>;

export function useAsync<T = any>({
  observable,
}: AsyncProps<T>): T | undefined {
  const [value, setValue] = useState<T | undefined>(undefined);
  useEffect(() => {
    if (observable instanceof BehaviorSubject) {
      setValue(observable.value);
    }
    let subscription: Subscription;
    if (!isObservable(observable)) {
      console.warn("is not observable");
      return undefined;
    }
    subscription = observable.subscribe(setValue);
    return () => subscription.unsubscribe();
  }, [observable]);
  return value;
}
