// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import clsx from 'clsx';

import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';


type Props = Readonly<{
  className?: string;
  children: string | JSX.Element;
}>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  page_heading: {
    fontWeight: Sizes.boldWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.heading2,
    color: Colors.headingMain,
  }
}));

const H2 = (props: Props) => (
  <h2
    className={clsx(useStyles({ fontFamily: useCurrentFont().font }).page_heading, props.className)}
  >
    { props.children }
  </h2>
);


export default H2;
