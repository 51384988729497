// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Grid, SxProps, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import HorizGrid from '../HorizGrid';
import AnalyticsTeamsColorPalettePicker from '../../_BaseUI/AnalyticsTeamsColorPalettePicker';
import { AnalyticsGameShared, updateAnalyticsGameSharedColors } from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import { removeAllQueryCache } from '../../../AppProviders';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  titleText: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.heading,
    fontWeight: Sizes.mediumWeight,
    color: Colors.mainTitle,
  },
  icon: {
    marginRight: '12pt',
  },
  valueText: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontWeight: Sizes.regularWeight,
  },
}));

type IconCircleMarker = Readonly<{
  teamIndex: 1 | 2
  team1ShortName?: string
  team2ShortName?: string
}>;

type Props = Readonly<{
  title?: string
  iconCircleMarkerColor?: IconCircleMarker
  titleToValueWidthRatio?: number // default 0.4
  value?: string
  valueAlign: "center" | "right"
  valueFontSize?: number
  sx?: SxProps<Theme>;
}>;

const AnaliticsTeamScoreInfoLineView: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const defaultTitleToValueWidthRatio = (props.valueAlign === "center") ? 0.4 : 0.5
  const titleToValueWidthRatio = (props.titleToValueWidthRatio === undefined) ? defaultTitleToValueWidthRatio : props.titleToValueWidthRatio
  const valueFontSize = (props.valueFontSize === undefined) ? Sizes.title : props.valueFontSize

  const titleEl = (
    <HorizGrid>
      { (!!props.iconCircleMarkerColor) &&
        <AnalyticsTeamsColorPalettePicker
          className={ classes.icon }
          mainTeamName={ ((props.iconCircleMarkerColor?.teamIndex === 1) ? props.iconCircleMarkerColor.team1ShortName : props.iconCircleMarkerColor.team2ShortName) ?? props.title }
          mainTeamColor={ (props.iconCircleMarkerColor?.teamIndex === 1) ? AnalyticsGameShared.team1Color : AnalyticsGameShared.team2Color }
          otherTeamName={ ((props.iconCircleMarkerColor?.teamIndex !== 1) ? props.iconCircleMarkerColor.team1ShortName : props.iconCircleMarkerColor.team2ShortName) ?? props.title }
          otherTeamColor={ (props.iconCircleMarkerColor?.teamIndex !== 1) ? AnalyticsGameShared.team1Color : AnalyticsGameShared.team2Color }
          onMainTeamColorChange={(color) => {
            if (props.iconCircleMarkerColor?.teamIndex === 1) {
              if (updateAnalyticsGameSharedColors(color, undefined, undefined, true)) {
                // make reload all UI
                removeAllQueryCache();
              }
            }
            else {
              if (updateAnalyticsGameSharedColors(undefined, color, undefined, true)) {
                // make reload all UI
                removeAllQueryCache();
              }
            }
          }}
        />
      }
      <Box className={ classes.titleText }>
        { props.title }
      </Box>
    </HorizGrid>
  )

  const valueEl = (
    <Box
      className={ classes.valueText }
      sx={ {
        fontSize: valueFontSize,
        textAlign: props.valueAlign,
      } }
    >
      { props.value }
    </Box>
  )

  if (props.valueAlign === "right") {
    return (
      <HorizGrid
        alignItems='center'
        justifyContent='space-between'
        sx={{...props.sx}}
      >
        { titleEl }
        { valueEl }
      </HorizGrid>
    )
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{...props.sx}}
    >
      <Grid item  xs={ 12 * titleToValueWidthRatio } >
        <HorizGrid>
          { titleEl }
        </HorizGrid>
      </Grid>

      <Grid item xs={ 12 * (1.0-titleToValueWidthRatio) } >
        { valueEl }
      </Grid>
    </Grid>
  )
};


export default AnaliticsTeamScoreInfoLineView;