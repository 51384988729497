// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import React from 'react';

import { Colors } from '../../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';


type Props = Readonly<{
  name: string;
  form: JSX.Element;
}>;


const InputAnyForm: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const SX = {
    name: {
      fontSize: Sizes.subtitle,
      color: Colors.black,
      fontFamily: font,
    },
    valueContainer: {
      minWidth: { xs: 68, sm: 120 }
    },
  };

  return (
  <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
    <Grid item>
      <Grid sx={ SX.name }>{ props.name }</Grid>
    </Grid>
    <Grid item>
      <Grid sx={ SX.valueContainer }>
        { props.form }
      </Grid>
    </Grid>
  </Grid>
  )
};


export default InputAnyForm;
