import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

// Тип данных для изображения с камеры
interface CameraImage {
    camera: number;
    src: string;
}

// Интерфейс для методов и состояния провайдера
interface CameraImageContextProps {
    images: CameraImage[];
    pushImage: (image: CameraImage) => void;
    getImage: (camera: number) => CameraImage | undefined;
    findImage: (camera: number) => boolean;
    setImage: (camera: number, src: string) => void;
}

// Создаем контекст
const CameraImageContext = createContext<CameraImageContextProps | undefined>(undefined);

// Провайдер контекста
export const CameraImageProvider = ({ children }: { children: ReactNode }) => {
    const [images, setImages] = useState<CameraImage[]>([]);

    // Мемоизируем функции с помощью useCallback
    const pushImage = useCallback((image: CameraImage) => {
        setImages((prevImages) => {
            const index = prevImages.findIndex((img) => img.camera === image.camera);
            if (index === -1) {
                return [...prevImages, image]; // добавляем, если камеры нет
            } else {
                const updatedImages = [...prevImages];
                updatedImages[index] = image; // обновляем, если камера уже существует
                return updatedImages;
            }
        });
    }, []);

    const getImage = useCallback((camera: number): CameraImage | undefined => {
        return images.find((img) => img.camera === camera);
    }, [images]);

    const findImage = useCallback((camera: number): boolean => {
        return images.some((img) => img.camera === camera);
    }, [images]);

    const setImage = useCallback((camera: number, src: string) => {
        setImages((prevImages) => {
            const index = prevImages.findIndex((img) => img.camera === camera);
            if (index === -1) {
                return [...prevImages, { camera, src }]; // добавляем новое изображение
            } else {
                const updatedImages = [...prevImages];
                updatedImages[index] = { camera, src }; // обновляем существующее изображение
                return updatedImages;
            }
        });
    }, []);

    // Мемоизируем значение контекста с помощью useMemo
    const contextValue = useMemo(() => ({
        images,
        pushImage,
        getImage,
        findImage,
        setImage,
    }), [images, pushImage, getImage, findImage, setImage]);

    return (
        <CameraImageContext.Provider value={contextValue}>
            {children}
        </CameraImageContext.Provider>
    );
};

// Хук для использования контекста
export const useCameraImageContext = (): CameraImageContextProps => {
    const context = useContext(CameraImageContext);
    if (!context) {
        throw new Error('useCameraImageContext must be used within a CameraImageProvider');
    }
    return context;
};
