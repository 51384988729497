// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@emotion/css';
import CodeMirror from '@uiw/react-codemirror';
import Hjson from 'hjson';

export default function JsonEditor(props: { value: any, onChanged: (value: any) => void, onWrong?: () => void }) {
    const stringify = (json: any): string => {
        let obj = json;
        if (typeof json === 'string' || json instanceof String) {
            obj = Hjson.parse(json.toString());
        }
        return Hjson.stringify(obj, { quotes: 'all', separator: true });
    }

    const input = stringify(props.value);

    const handleJsonChange = (value: string) => {
        try {
            const parsedJson = Hjson.parse(value);
            props.onChanged(parsedJson);
        } catch (error) {
            if (props.onWrong) {
                props.onWrong();
            }
        }
    }

    return (
        <CodeMirror
            className={css`
                width: 100%;
            `}
            value={input}
            extensions={[javascript({ jsx: true })]}
            onChange={(value, viewUpdate) => {
                handleJsonChange(value);
            }}
        />
    )
}
