
import PageContainer from "../../Components/_Layout/PageContainer"
import { CommonSettingsMain } from "./Components/CommonSettings"
import { ParnoramaSettings } from "./Components/PanoramaSettings"
import { UndistordSettings } from "./Components/UndistordSettings"



export const CameraSettingsPage = (() => {



    return <PageContainer>
        <CommonSettingsMain></CommonSettingsMain>
        <UndistordSettings></UndistordSettings>
        <ParnoramaSettings />
    </PageContainer>
})