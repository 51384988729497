import { isNil } from "lodash";
import { unix } from "moment";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import FieldSet, { FieldSetInputType } from "../../../../Components/_BaseUI/FieldSet";
import Spinner from "../../../../Components/_BaseUI/Spinner/Spinner";
import { useMainCameraSchedule } from "../../../../Data/NapiCameraHandlerV2/hooks/common-settings/useMainCameraSchedule";
import { useCameraCommonSettings } from "../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import LOCALIZATION from "../../../../Localization";

export const CommonSettingsTable = (() => {
    const { data, isLoading } = useCameraCommonSettings()
    const intl = useIntl();
    const commonSettings = useMemo(() => {
        return data?.configState.config.camera
    }, [data])
    const awb_modeLabel = useMemo(() => {
        const key = commonSettings?.["awb-mode"]?.toString?.()

        let label = key
        try {
            //@ts-expect-error
            if (key) label = intl.formatMessage({ id: LOCALIZATION[key] })
        } catch (e) { }
        return label;
    }, [commonSettings?.["awb-mode"], intl]);
    const antibanding_modeLabel = useMemo(() => {
        const key = commonSettings?.["ae-antibanding"]?.toString?.()

        let label = key
        try {
            //@ts-expect-error
            if (key) label = intl.formatMessage({ id: LOCALIZATION[key] })
        } catch (e) { }
        return label;
    }, [commonSettings?.["ae-antibanding"], intl]);

    const { isLoading: gettingMainCameraSchedule, data: mainCameraSchedule } = useMainCameraSchedule()

    const scheduleLabel = useMemo(() => {
        return (mainCameraSchedule?.getMainCameraScheduleConfig?.length && mainCameraSchedule?.getMainCameraScheduleConfig?.length !== 1 ? `(${mainCameraSchedule?.getMainCameraScheduleConfig?.filter(v => !isNil(v.timestamp)).map(v => `${v.camera} ${intl.formatMessage({ id: LOCALIZATION.time_start_from })} ${unix(v.timestamp / 1000).format('HH:mm')}`).join(' ,')})` : '') ?? ''
    }, [intl, mainCameraSchedule?.getMainCameraScheduleConfig])

    return <>
        <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_lens })}
            input={{ type: FieldSetInputType.StaticLabel, value: commonSettings?.["num-cameras"]?.toString?.() }}
        ></FieldSet>
        <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_angle })}
            input={{ type: FieldSetInputType.StaticLabel, value: commonSettings?.angle?.toString?.() }}
        ></FieldSet>
        <FieldSet
            label={`${intl.formatMessage({ id: LOCALIZATION.camera_settings_common_main_lens })}`}
            input={{ type: FieldSetInputType.StaticLabel, value: `${commonSettings?.["main-camera"] === -1 ? intl.formatMessage({ id: LOCALIZATION.off }) : commonSettings?.["main-camera"]?.toString?.()}  ${scheduleLabel}` }}
        ></FieldSet>
        <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_fps })}
            input={{ type: FieldSetInputType.StaticLabel, value: commonSettings?.["frame-rate"]?.toString?.() }}
        ></FieldSet>
        <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_denoise })}
            input={{ type: FieldSetInputType.StaticLabel, value: commonSettings?.["denoise-strength"]?.toString?.() }}
        ></FieldSet>
        <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_edge })}
            input={{ type: FieldSetInputType.StaticLabel, value: commonSettings?.["edge-enhance-strength"]?.toString?.() }}
        ></FieldSet>
        <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_auto_white })}
            input={{ type: FieldSetInputType.StaticLabel, value: awb_modeLabel }}
        ></FieldSet>
        <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_antibanding })}
            input={{ type: FieldSetInputType.StaticLabel, value: antibanding_modeLabel }}
        ></FieldSet>
        {(isLoading || gettingMainCameraSchedule) && <Spinner></Spinner>}

    </>
})
