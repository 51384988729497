// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Button, ButtonGroup } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';

import { WeekDay } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import enumToObject from '../../../Tools/enumToObject';
import weekDaysShortNameLocalizationKey from './weekDaysShortNames';


type Props = Readonly<{
  days: WeekDay[];
  isDisabled?: boolean;
  onChange: (days: WeekDay[]) => void;
} & WrappedComponentProps>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    marginTop: '6px',
    marginBottom: '7px',
  },
  rootButton: {
    '&.MuiButtonGroup-grouped': {
      minWidth: '20px',
    },
    height: '38px',
    padding: '0px',
    color: '#192A2E',
    borderColor: 'rgba(0, 0, 0, 0.23)',

    '&.MuiButton-containedPrimary': {
      color: '#fff',
      backgroundColor: '#18A0FB',
    },
  }
}));

const WeekDaysSelector: React.FC<Props> = (props: Props) => {
  const weekDays = enumToObject(WeekDay);
  const { font } = useCurrentFont()
  const classes = useStyles({ ...weekDays, fontFamily: font });
  const {
    days = [],
    isDisabled = false,
  } = props;
  const isSelected = (key: WeekDay): boolean => (
    days.includes(key)
  );

  const handleButtonClicked = (day: WeekDay) => {
    if (isSelected(day)) {
      props.onChange(days.filter((d: WeekDay) => (d !== day)));
    } else {
      props.onChange([
        ...days,
        day,
      ])
    }
  };
  const renderButtons = () => (
    Object.keys(weekDays).map((key) => {
      const value = weekDays[key] as WeekDay;

      return (
        (
          <Button
            key={ key.toString() }
            color="primary"
            disabled={ isDisabled }
            className={ classes.rootButton }
            variant={ isSelected(value) ? 'contained' : 'outlined' }
            onClick={ () => handleButtonClicked(value) }
          >
            {props.intl.formatMessage({ id:  weekDaysShortNameLocalizationKey[value] }) }
          </Button>
        )
      );
    })
  );

  return (
    <ButtonGroup
      fullWidth
      size="large"
      variant={ 'outlined' }
      aria-label="outlined button group"
      className={ classes.root }
    >
      { renderButtons() }
    </ButtonGroup>
  );
};


export default injectIntl(WeekDaysSelector);
