import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { InputAdornment, TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import { FieldSetInputType } from '.';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';


export type ChangeNumber = (value: number | null) => void;

export type FieldSetInputNumber = Readonly<{
  type: FieldSetInputType.Number | FieldSetInputType.Float;
  value: number;
  shownValue?: number;
  min?: number;
  max?: number;
  step?: number;
  postfix?: string;
  /**
   * !IMPORTANT the callback link will be memoized
   */
  onChange?: ChangeNumber;
}>;

type Props = FieldSetInputNumber;



export const useStylesInputBox = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  input: {
    height: '38px',
    paddingTop: '0px',
    paddingBottom: '0px',
    lineHeight: '38px',
    textAlign: 'left',
  },
}));

export const FieldSetNumber: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStylesInputBox({ fontFamily: font });
  const { type, max, min, step, onChange, value: _outsideValue, shownValue, postfix } = props;
  const outsideValue = _outsideValue;
  const [internalValue, setInternalValue] = useState(outsideValue);


  const adjustValue = useCallback((value: number) => {

    if (isNaN(value)) return min ?? 0;
    if ((min !== undefined) && (value < min)) return min;
    if ((max !== undefined) && (value > max)) return max;
    return value;
  }, [max, min])

  const setValue = useCallback((v: number) => {
    setInternalValue(adjustValue(v))
  }, [adjustValue])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce((nextValue) => onChange?.(nextValue), 300),
    [onChange]
  );
  const handleTextValueChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    const nextValue = Number(event.target.value);
    setValue(nextValue);
    debouncedChangeHandler(nextValue);
  };
  useEffect(() => {
    setValue(_outsideValue);
  }, [_outsideValue, setValue]);

  return (
    <TextField
      fullWidth
      type={FieldSetInputType.Number}
      variant="outlined"
      value={internalValue}
      inputProps={{
        step, min, max, className: classes.input,

      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" disablePointerEvents>
            <span style={{ whiteSpace: 'nowrap', marginLeft: '-4px' }}>{postfix}</span>
          </InputAdornment>
        ),
      }}
      onChange={handleTextValueChanged}
    />
  );
};

