// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Colors } from "../../../../../Configuration/Styles/Colors";
import { FontName } from "../../../../../Configuration/Styles/Fonts";
import { Sizes } from '../../../../../Configuration/Styles/Sizes';



const useStyles = makeStyles((theme: Theme) =>
  createStyles<any, { fontFamily: FontName }>({
    root: {
      position: "absolute",
      width: "calc(100vw - 100vw/14)",
      maxWidth: 690,
    },
    toolbar: {
      paddingBottom: theme.spacing(1.6),
      paddingTop: theme.spacing(1.6),
      background: Colors.mainGrey,
      [theme.breakpoints.up("xs")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    header: {
      fontFamily: ({ fontFamily }) => fontFamily,
      fontSize: Sizes.caption,
      fontWeight: Sizes.boldWeight,
      color: Colors.headingMain,
    },
    row: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      alignItems: "center",
    },
    rowSelect: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      alignItems: "center",
    },
    topButtons: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    bordered: {
      borderBottom: `1px solid ${Colors.lightGrey}`,
    },
  })
);


export default useStyles;
