import { useMemo } from "react";
import { QueryKey, UseQueryOptions } from "react-query";
import { useStitchingConfig } from "./useStichingConfig";

interface IResponse {
  getStitchingCoefficents: object;
}

interface IUseActiveStitchingCoefficentsConfigs<TData> {
  uuid: string;
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}
export const useActiveStitchingCoefficentsConfigsCacheKey =
  "useActiveStitchingCoefficentsConfigs";

/**
 * @description It request the data only when fetchData has been fired
 * @param props
 * @returns
 */
export const useActiveStitchingCoefficents = (
  props: IUseActiveStitchingCoefficentsConfigs<IResponse | undefined>
) => {
  const { uuid } = props ?? {};
  const { data: stitchingConfig, isLoading } = useStitchingConfig({
    uuid,
  });

  const data = useMemo(() => {
    return stitchingConfig?.config;
  }, [stitchingConfig?.config]);
  return {
    data,
    isLoading,
  };
};
