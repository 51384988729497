import * as PIXI from "pixi.js-legacy";
import { CanvasHTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import { debounceTime } from "rxjs";
import useImageDimensions from "../../../../../../hooks/useGetImageDimansions";
import { useObjectChanged } from "../../../../../../hooks/useObjectChanged";
import { PanningContainer } from "./PanningContainer";
import { Rectangle } from "./Rectangle";
import { Region } from "./Region.interface";
import { Vertex } from "./Vertex";


export const withRegion2 = <P extends CanvasHTMLAttributes<HTMLCanvasElement>>(
  Component: React.ComponentType<P>,

) => {
  return (props: P & {
    /**
     * it's tooks only once
     */
    base64?: string,
    config?: Partial<PIXI.IApplicationOptions>,
    region: Region,
    onRegionChanged?: (region: Region) => void
  }) => {
    const { base64, config, region, onRegionChanged, } = props;
    const ref = useRef<HTMLCanvasElement>(null);
    const { width: imageWidth, height: imageHeight } = useImageDimensions(base64)
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    useEffect(() => {
      setCanvas(ref.current);
    }, []);

    useEffect(() => {
      const parent = canvas?.parentElement;
      if (!canvas || !parent) {
        return;
      }
      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    }, [canvas])



    const app = useMemo(() => {
      if (!canvas) {
        return;
      }
      const app = new PIXI.Application({
        width: Number(canvas.width),
        height: Number(canvas.height),
        antialias: true,
        resolution: 1,
        view: canvas,
        forceCanvas: true,
        ...config,
      });


      return app
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas])

    const panningContainer = useMemo(() => {
      if (!app) {
        return;
      }
      const panning = new PanningContainer()
      app.stage.addChild(panning)
      return panning
    }, [app])

    const polygon = useMemo(() => {
      if (!panningContainer) {
        return
      }
      const { left, right, top, bottom } = region;
      const box = {
        points: [
          [Number(left), Number(top)],
          [Number(right), Number(bottom)],
        ] as [number, number][]
      }
      const vertices = box.points.map(p => { return new Vertex(new PIXI.Point(p[0], p[1])) })
      const poly = new Rectangle(vertices as [Vertex, Vertex]);

      poly.zIndex = 2;

      poly.update$.pipe(debounceTime(300)).subscribe((object) => {
        const points = object.vertices.map(v => [v.x, v.y] as [number, number]);
        const maxX = Math.round(Math.max(...points.map(v => v[0])))
        const minX = Math.round(Math.min(...points.map(v => v[0])))
        const maxY = Math.round(Math.max(...points.map(v => v[1])))
        const minY = Math.round(Math.min(...points.map(v => v[1])))
        onRegionChanged?.({
          left: minX,
          top: minY,
          right: maxX,
          bottom: maxY,
        });
      })
      panningContainer.addChild(poly);
      return poly;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panningContainer])

    const { value: changedRegion } = useObjectChanged(region)

    useEffect(() => {
      if (!polygon || polygon.isDraging) {
        return;
      }
      const points = polygon.vertices.map(v => [v.x, v.y] as [number, number]);
      const maxX = Math.round(Math.max(...points.map(v => v[0])))
      const minX = Math.round(Math.min(...points.map(v => v[0])))
      const maxY = Math.round(Math.max(...points.map(v => v[1])))
      const minY = Math.round(Math.min(...points.map(v => v[1])))
      const { left, right, top, bottom } = changedRegion;

      if (left === minX &&
        top === minY &&
        right === maxX &&
        bottom === maxY) {
        return;
      }

      polygon.rb.position.set(right, bottom)
      polygon.lt.position.set(left, top)

      polygon.rt.position.set(right, top)
      polygon.lb.position.set(left, bottom);

      polygon.redrawFigure();
    }, [polygon, changedRegion])

    useEffect(() => {
      if (!imageHeight || !imageWidth || !base64 || !panningContainer) {
        return
      }
      const img = PIXI.Sprite.from(base64);
      img.anchor.set(0, 0);
      img.position.set(0, 0);
      img.width = imageWidth;
      img.height = imageHeight;
      panningContainer.addChild(img);
      return () => {
        img.destroy(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panningContainer, imageWidth, imageHeight])


    useEffect(() => {
      return () => app?.destroy()
    }, [app])


    return <Component ref={ref}  {...props as P}></Component>
  }
}