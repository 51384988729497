// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import LOCALIZATION from '../../../Localization';
import { debounce } from '../../../Tools/Tools';


export enum InputFieldType {
  Email = 'email',
  Password = 'password',
  Temperature = 'temperature',
}
type Props = Readonly<{
  inputType: InputFieldType;
  defaultValue?: string;
  autoFocus?: boolean;
  isCorrect?: boolean;
  isDebounced?: boolean;
  onTextChanged: (text: string) => void;
  onEnterPress?: (text: string) => void;
  id?: string;
} & WrappedComponentProps>;


const useStyles = makeStyles((theme: Theme) => createStyles<any, { fontFamily: FontName }>({
  textField: {
    width: '100%',
    '& label': {
      background: Colors.clear,
    },
  },
  input: {
    borderRadius: 6,
    height: 38,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.input,
    background: Colors.mainBackground,
    [theme.breakpoints.down('xs')]: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    }
  },
  correct: {
    color: Colors.mainTitle
  },
  wrong: {
    color: Colors.mainRed
  }
}));

// Калбек с текстом вызывается через дебаунсер
const InputField: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })

  let label: string | undefined
  const isDebounced = props.isDebounced ?? false

  let autocomplete: string | undefined
  let required: boolean
  let type: string
  let inputProps: any | undefined

  const isCorrect = props.isCorrect ?? true

  // это те температуры, которые могут охватить более-менее приличные датчики
  const minTemp = -50
  const maxTemp = 120

  const [failbackText, setFailbackText] = useState<string | undefined>(undefined)

  function sendTextChanged(text: string): void {
    props.onTextChanged(text)
  }

  function sendEnterPressed(text: string): void {
    props.onEnterPress?.(text)
  }

  const debouncedSendTextChanged = debounce(1000, sendTextChanged)
  const debouncedEnterPressed = debounce(1000, sendEnterPressed)

  const handleTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let text = event.target.value

    let temperature: number | undefined

    switch (props.inputType) {
      case 'email':
      case 'password':
        break
      case 'temperature':
        temperature = parseFloat(text)
    }

    if (temperature !== undefined) {
      if (temperature < minTemp) {
        text = minTemp.toFixed(1)
        setFailbackText(text)
      } else if (temperature > maxTemp) {
        text = maxTemp.toFixed(1)
        setFailbackText(text)
      } else {
        setFailbackText(undefined)
      }
    } else {
      setFailbackText(undefined)
    }
    if (isDebounced) {
      debouncedSendTextChanged(text)
    } else {
      sendTextChanged(text)
    }
  }


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.toLowerCase() === 'enter' && props.onEnterPress !== undefined) {
      let element = event.target as HTMLInputElement
      let text = element?.value !== undefined ? element.value : ''

      setFailbackText(undefined)

      if (isDebounced) {
        debouncedEnterPressed(text)
      } else {
        sendEnterPressed(text)
      }
      event.preventDefault();
    }
  }

  switch (props.inputType) {
    case InputFieldType.Email:
      label = props.intl.formatMessage({ id: LOCALIZATION.user_login })
      autocomplete = 'email'
      type = 'email'
      required = false
      break
    case InputFieldType.Password:
      label = props.intl.formatMessage({ id: LOCALIZATION.password })
      autocomplete = 'current-password'
      type = 'password'
      required = false
      break
    case InputFieldType.Temperature:
      type = 'number'
      required = true
      inputProps = { min: minTemp, max: maxTemp }
      break
  }

  return (
    <TextField
      className={ classes.textField }
      id={ props.id }
      required={ required }
      label={ label }
      name={ label }
      type={ type }
      autoComplete={ autocomplete }
      autoFocus={ props.autoFocus }
      variant="outlined"
      size="small"
      value={ failbackText }
      defaultValue={ props.defaultValue }
      onChange={ handleTextChanged }
      onKeyDown={ handleKeyDown }
      InputProps={ {
        className: clsx(classes.input, isCorrect ? classes.correct : classes.wrong),
        inputProps: inputProps
      } }
      InputLabelProps={ { className: classes.input } }
    />
  )
};


export default injectIntl(InputField);
