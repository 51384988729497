// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Colors } from "../../Configuration/Styles/Colors";
import { FontName } from "../../Configuration/Styles/Fonts";
import { Sizes } from "../../Configuration/Styles/Sizes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles<any, { fontFamily: FontName }>({
    container: {
      padding: 20,
      paddingTop: 48,
      [theme.breakpoints.up("md")]: {
        paddingTop: 200,
      },
    },
    none: {
      display: "none",
    },
    error: {
      color: Colors.mainTitle,
      textAlign: "center",
    },
    errorText: {
      fontSize: Sizes.subtitle,
      fontFamily: ({ fontFamily }) => fontFamily,
    },
  })
);


export default useStyles;
