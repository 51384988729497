import styled from "@emotion/styled";
import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader/index";
import Card from "../../../../../Components/_Layout/Card";
import Popup from "../../../../../Components/_Layout/Popup";
import { usePushStitchingImage } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/usePushStitchingImage";
import { useWriteStitchingItem } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useWriteStichingItem";
import { StitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/StitchinConfig.interface";
import { useStitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useStichingConfig";
import { useStichingImages } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useStichingImages";
import { useCameraCommonSettings } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { useCameraShot } from "../../../../../hooks/useCameraShot";
import { ThemeVariables } from "../../../../../hooks/useTheme";
import LOCALIZATION from "../../../../../Localization";
import { useCameraImageContext } from "../CameraImageProvider";
import { ActiveCoefficentsForm } from "./ActiveCoefficentsForm";
import { CalculateForm } from "./CalculateForm";
import { PanoramaStitchingByActiveCoefficents } from "./PanoramaStitchingByActiveCoefficents";
import { useSaveRawCoefficent } from "./useSaveCylinderCoefficent";
import { ZipArchivator } from "./ZipArchivator";

const Header = styled.span`
color: var(--C_Dark_Text, #334D5E);
margin-bottom:10px;
/* c_h4 (18) */
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.27px;
margin-bottom:10px;`




const Refresh = styled.button`
   background: none;
    border: 2px solid#18A0FB;
    border-radius: 6px;
    padding: 0;
    padding: 4px 5px 1px 4px;

    align-content: center;
    & :hover {
        cursor: pointer;
    };
`


const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))

interface Props {
    onClose?: () => void,
    onApply?: () => void,
    uuid: string,

}

const Input = styled.input`
    color: var(--AdBlack, #000);
    /* c_body_P */
    font-family: var(${ThemeVariables.font});
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    border: 1px solid var(--AdGray1, #B5BBC5);
    background: #FFF;
    height: 38px;
    width: 100%;
    padding-left: 12px;
`
export const RawForm = ({ onClose, onApply, uuid }: Props) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { mutateAsync: pushStichingImage, isLoading: pushingImage } = usePushStitchingImage()

    const { patch, state: config } = useSaveRawCoefficent()
    const name = useMemo(() => config && config?.name, [config])
    const setName = useCallback((name: string) => {
        patch({ name })
    }, [patch])
    const { data: stichingConf } = useStitchingConfig({ uuid })
    const { mutateAsync: saveConfig, isLoading: savingConfig } = useWriteStitchingItem()
    const { setImage, images } = useCameraImageContext()



    const { data: prevoiusImages, isLoading: loadingStichingImages, clearCache } = useStichingImages({
        uuid, options: {
            keepPreviousData: true,
        }
    })
    const onApplyCallback = useCallback(async () => {
        if (!config) {
            return
        }
        await Promise.all(images.map(async ({ camera, src }) => {
            return pushStichingImage({
                uuid,
                name: `${camera}`,
                file: await (await fetch(src)).blob(),
            })
        }))

        await saveConfig({ config: { ...config, calibrationInput: stichingConf?.calibrationInput } as StitchingConfig, });
        onApply?.()
        clearCache()
    }, [clearCache, config, images, onApply, pushStichingImage, saveConfig, stichingConf?.calibrationInput, uuid])

    const { data: commonSettings } = useCameraCommonSettings()
    const cameras = useMemo(() => (new Array(commonSettings?.configState.config.camera["num-cameras"])).fill(0).map((_, i) => i), [commonSettings?.configState.config.camera["num-cameras"]])
    const { getShot, isLoading: gettingCameraShot } = useCameraShot({})
    useEffect(() => {
        if (!prevoiusImages) {
            return
        }
        for (const camera of cameras) {
            const src = prevoiusImages?.find(v => v?.camera === camera)?.src
            if (src) {
                setImage(camera, src);
                continue;
            }
            getShot(camera).then(async src => {
                setImage(camera, src)
                pushStichingImage({
                    uuid,
                    name: `${camera}`,
                    file: await (await fetch(src)).blob(),
                })
            }
            )
        }



    }, [cameras, getShot, prevoiusImages, setImage])

    return <>
        <LoadingComponent ugly isLoading={savingConfig || gettingCameraShot || loadingStichingImages || pushingImage}>
            <Popup
                closeTitle={`${formatMessage({ id: LOCALIZATION.cancel })}`}
                isFullScreen
                headerTitle={`${formatMessage({ id: LOCALIZATION.panorama_image_stitching_raw })}`}
                // isApplyDisabled={loadingList}
                onApply={onApplyCallback}
                onClose={onClose}
                classes={classes}
                applyTitle={`${formatMessage({ id: LOCALIZATION.save })}`}

            >
                <Grid container >
                    <Grid marginBottom={3} item xs={12}>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item marginBottom={3} xs={12}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <Grid marginBottom={2}>
                                <Header>{formatMessage({ id: LOCALIZATION.calculate_raw_projection_coefficients_automatically }).capitalizeFirstLetter()}</Header>
                            </Grid>
                            <Grid>
                                <ZipArchivator>
                                    <CalculateForm uuid={uuid} />
                                </ZipArchivator>

                            </Grid>
                        </Card>

                    </Grid>
                    <Grid item marginBottom={3} xs={12}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <Grid marginBottom={2}>

                            </Grid>
                            <Header>{formatMessage({ id: LOCALIZATION.active_coeff }).capitalizeFirstLetter()}</Header>
                            <Grid>

                                <ActiveCoefficentsForm uuid={uuid} />
                            </Grid>
                        </Card>

                    </Grid>

                    <Grid item marginBottom={3} xs={12}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <Grid marginBottom={2}><Header >{formatMessage({ id: LOCALIZATION.panorama_stitched_by_active_coefficients }).capitalizeFirstLetter()}</Header></Grid>

                            <Grid>  <PanoramaStitchingByActiveCoefficents /></Grid>
                        </Card>

                    </Grid>

                </Grid>

            </Popup>
        </LoadingComponent>

    </>
}