// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';


export enum AppLocale {
  Ru = "ru",
  En = "en",
  Cn = "cn",
}
type AppLocalization = Readonly<{
  locale: AppLocale;
}>;
export type AppConfig = Readonly<{
  localization: AppLocalization;
}>;

export enum AppActionType {
  ChangeLanguage = 'changeLanguage',
}
export interface Action {
  type: AppActionType;
  locale?: AppLocale;
}
type Dispatch = (action: Action) => void;



export const AppConfigContext = React.createContext<AppConfig>({} as AppConfig);
export const AppConfigDispatchContext = React.createContext<Dispatch | undefined>(undefined);

export const useAppConfig = () => {
  const context = React.useContext(AppConfigContext);

  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context;
};
export const useAppConfigAction = () => {
  const context = React.useContext(AppConfigDispatchContext);

  if (context === undefined) {
    throw new Error('useConfigAction must be used within a ConfigProvider')
  }
  return context;
};

export const STORAGE_LOCALE_KEY = 'locale';
const DEFAULT_LOCALE = AppLocale.En;

export const getLocalStorageLocale = (): AppLocale => {
  try {
    var valueFromLocalStorage = localStorage.getItem(STORAGE_LOCALE_KEY);
    if (!valueFromLocalStorage) { // detect default language by host DNS name.
      if (window.location.hostname && window.location.hostname.endsWith(".ru")) {
        valueFromLocalStorage = AppLocale.Ru;
      }
      else {
        valueFromLocalStorage = DEFAULT_LOCALE;
      }
    }



    return (
      Object.values(AppLocale).find((v) => v === valueFromLocalStorage) ??
      DEFAULT_LOCALE
    );
  } catch (ignore) {
    return DEFAULT_LOCALE;
  }
};

export const INITIAL_STATE: AppConfig = {
  localization: {
    locale: getLocalStorageLocale(),
  },
};


export const reducer = (state: AppConfig, action: Action): AppConfig => {
  switch (action.type) {
    case AppActionType.ChangeLanguage: {
      if (action.locale) {
        localStorage.setItem(STORAGE_LOCALE_KEY, action.locale);

        return {
          ...state,
          localization: {
            ...state.localization,
            locale: action.locale,
          },
        };
      }

      return state;
    }
    default:
      return state;
  }
};