import { useEffect, useState } from "react";
import { exhaustMap, from, merge, of, timer } from "rxjs";

export const useAwaitedInterval = <T>(
  async: () => Promise<T>,
  interval = 10_000
) => {
  const [value, setValue] = useState<T>();
  useEffect(() => {
    const observable = merge(of(0), timer(0, interval)).pipe(
      exhaustMap((v) => from(async()))
    );

    const sub = observable.subscribe((value) => {
      setValue(value);
    });
    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { value };
};