import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid, Popover, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CameraIntervalImage } from "../../../../../Components/CameraIntervalImage";
import LoadingComponent from "../../../../../Components/Loader";
import MainButton from "../../../../../Components/_BaseUI/MainButton";
import Card from "../../../../../Components/_Layout/Card";
import Popup from "../../../../../Components/_Layout/Popup";
import { useDeleteStitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useDeleteStichingConfig";
import { useDublicateStitching } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useDublicateStiching";
import { useSetStitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useSetStichingConfig";
import { useWriteStitchingItem } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useWriteStichingItem";
import { useStitchingList } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useStitchingList";
import { usePingCalibrationMode } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePingCalibrationMode";
import { useCameras } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameras";
import { useAwaitedInterval } from "../../../../../hooks/useAwaitedInterval";
import { useCalibrationMode } from "../../../../../hooks/useCalibrationMode";
import useConfirm from "../../../../../hooks/useConfirm";
import { CylindralStitchingIcon, PerspectiveStitchingIcon } from "../../../../../Icons/Icons";
import LOCALIZATION from "../../../../../Localization";
import { CameraImageProvider } from "../CameraImageProvider";
import { CylindralForm } from "../CylindralForm";
import { PerspectiveForm } from "../PespectiveForm/index";
import { RawForm } from "../RawForm/index";
import { RawSaveItemsStateProvider } from "../RawForm/useSaveCylinderCoefficent";
import { StitichingConfigItem } from "./StitichingConfigItem";


type Props = Readonly<{
    onClose: () => void
    handleSubmit?: () => Promise<void> | void,
}>;

const Header = styled.span`
color: var(--C_Dark_Text, #334D5E);
margin-bottom:10px;
/* c_h4 (18) */
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.27px;
margin-bottom:10px;`



const StyledCameraIntervalImage = (styled(CameraIntervalImage)`
       height: 100%;
       max-width: calc(100%);
    background-color: #E5E5E5;
    border: solid 1px;
    border-color:  rgba(0, 0, 0, 0.30);
    border-radius: 10px;
    width: 100%;
    min-height: 60px;
`)

const AddMenuText = styled.button`
background: white;
border: none;
display:flex;
font-family: Montserrat;
font-weight: 500;
line-height: 15.85px;
text-align: left;
padding: 10px;

width: 100%;

    font-size: 14px;
    font-style: normal;
    line-height: normal;
    :hover {
        cursor: pointer;
        background-color: #c2cfe069;
    }

`

const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))
export const PanoramaImageList = ((props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote, } = props;
    const { formatMessage } = useIntl();
    const intl = useIntl();
    const { mutateAsync: pingCalibrationMode, pendingKick, previousData, isLoading: enteringCalibratyionMode, isError: errorEnteringCalibrationMode } = usePingCalibrationMode()
    const { confirm, confirmDialog } = useConfirm();

    const [kickIgnore, setKickIgnore] = useState<boolean>(false);

    useEffect(() => {
        if (!errorEnteringCalibrationMode) {
            return;
        }
        alert(intl.formatMessage({ id: LOCALIZATION.error_while_changing_camera_handler_conf }))
    }, [errorEnteringCalibrationMode, intl])

    useEffect(() => {
        if (!pendingKick || kickIgnore || confirmDialog?.isOpen) {
            return;
        }
        confirm({
            bodyText: intl.formatMessage({ id: LOCALIZATION.pending_kick_alert }),
            timeoutAction: "confirm",
            timeout: 150,
        }).then((v) => {
            if (v) {
                onClose();
                return
            }
            setKickIgnore(true)
        })

    }, [confirm, confirmDialog?.isOpen, intl, kickIgnore, onClose, pendingKick])
    useAwaitedInterval(() => pingCalibrationMode(), 15_000)
    const { setMode: set, setUndistordDisabled } = useCalibrationMode();
    useEffect(() => {
        set(true);
        pingCalibrationMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [addModal, setAddModal] = useState(false)
    const [editCylinderModal, setEditCyliderModal] = useState<string | void>()
    const [editPerspectiveModal, setEditPerspectivModal] = useState<string | void>()
    const [editRawModal, setEditRawModal] = useState<string | void>()

    const editCallback = useCallback((uuid: string, type: "cylindral" | "perspective" | "raw") => {
        switch (type) {
            case "cylindral":
                setEditCyliderModal(uuid)
                break;
            case "perspective":
                setEditPerspectivModal(uuid)
                break;
            case "raw":
                setEditRawModal(uuid)
                break;

        }
    }, [])


    // const onSelectCallback = useCallback<(params: Parameters<typeof selectConfig>) => void>(([{ uuid, camera }]) => {
    //     window.confirm(`${intl.formatMessage({ id: LOCALIZATION.confirm_actions })}`) && selectConfig({ uuid, camera }).catch(e => {
    //         alert(formatMessage({ id: LOCALIZATION.error_while_changing_camera_handler_conf }))
    //     })
    // }, [])
    const { data: stitchingConfigs } = useStitchingList()



    const loadingMessage = useMemo(() => {

        return undefined

    }, [])

    const { mutateAsync: disableCalibrationMode, isLoading: disablingCalibration, isError: disablingError } = usePingCalibrationMode();
    useEffect(() => {
        if (!disablingError) {
            return;
        }
        alert(LOCALIZATION.error_while_changing_camera_handler_conf)
    }, [disablingError])
    const onCloseCallback = useCallback(async () => {
        set(false);
        setUndistordDisabled(false)
        await disableCalibrationMode({ set: false, undistordDisabled: false });

        onClose?.();
    }, [disableCalibrationMode, onClose, set, setUndistordDisabled])

    const isLoading = useMemo(() => {
        return false;
    }, [])
    const classes = useStyles();

    const imageInterval = useMemo(() => editCylinderModal ? Infinity : 5_000, [editCylinderModal])

    const isPanoramaDisabled = useMemo(() => {
        return !stitchingConfigs?.getStitchingList.find(v => v.active);
    }, [stitchingConfigs?.getStitchingList])

    const activeItem = useMemo(() => {
        return stitchingConfigs?.getStitchingList.find(v => v.active);

    }, [stitchingConfigs?.getStitchingList])

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const { mutateAsync: addStitching, isLoading: addingStitchingConfig } = useWriteStitchingItem()

    const { isLoading: isDeleting, mutateAsync: deleteConfig } = useDeleteStitchingConfig()

    const { data: cameras, isLoading: gettingCameras } = useCameras()

    const { mutateAsync: setConfig, isLoading: isSettingConfig } = useSetStitchingConfig()
    const { mutateAsync: dublicateConfig, isLoading: isDublicatingConfig } = useDublicateStitching()

    const setConfigCallback = useCallback(async (uuid?: string) => {
        (await confirm({})) && setConfig(uuid)
    }, [confirm, setConfig])

    const dublicateConfigCallback = useCallback((uuid: string) => {
        dublicateConfig({ uuid })
    }, [dublicateConfig])

    const addCylindralCallbaclk = useCallback(async () => {
        const res = await addStitching({ config: { type: 'cylindral', name: `Settings ${(new Date()).toLocaleString()}` } });
        setAnchorEl(null);
        editCallback(res.writeStitchingConfig, 'cylindral')
    }, [addStitching, editCallback])

    const addRawStitchingCallback = useCallback(async () => {
        const res = await addStitching({ config: { type: 'raw', name: `Settings ${(new Date()).toLocaleString()}` } });
        setAnchorEl(null);
        editCallback(res.writeStitchingConfig, 'raw')

    }, [addStitching, editCallback])

    return <>
        <Popup
            closeTitle={`${intl.formatMessage({ id: LOCALIZATION.close_btn })}`}
            isFullScreen
            headerTitle={`${intl.formatMessage({ id: LOCALIZATION.page_camera_settings_panorama_settings_header })}`}
            // isApplyDisabled={loadingList}
            onApply={handleSubmitRemote}
            onClose={onCloseCallback}
            classes={classes}
        >
            <LoadingComponent ugly label={loadingMessage} isLoading={!previousData?.state || isDublicatingConfig || disablingCalibration || isLoading || gettingCameras || addingStitchingConfig || isDeleting || isSettingConfig}>

                <Grid sx={{ minHeight: '523px', }} container className={css`
                        display: 'flex';
                         align-items: 'flex-start';
                `} gap={1}>
                    <Grid xs item>
                        <Card sx={{
                            height: "100%",
                        }}
                            contentSx={{
                                height: "100%",
                                padding: '20px 20px 30px 20px !important',
                                boxSizing: "border-box",
                                display: "flex",
                                flexDirection: "column",

                            }}>



                            <Header>{formatMessage({ id: LOCALIZATION.select_panorama_settings })}</Header>

                            <StitichingConfigItem active={isPanoramaDisabled} onSelect={setConfigCallback} name={formatMessage({ id: LOCALIZATION.stitching_disabled })} />
                            {stitchingConfigs?.getStitchingList.map(({ name, uuid, type, active }) => uuid && <StitichingConfigItem active={active} key={uuid} onRemove={() => deleteConfig({ uuid })} type={type} onDublicate={() => dublicateConfigCallback(uuid)} onEdit={() => editCallback(uuid, type)} onSelect={setConfigCallback} name={name} uuid={uuid} />)}
                            <Grid display={"flex"} marginTop={"auto"}>
                                <MainButton sx={{ width: "fit-content", marginLeft: 'auto' }} onClicked={(event) => {
                                    event && setAnchorEl(event.currentTarget);
                                    setAddModal(true)
                                }} title={formatMessage({ id: LOCALIZATION.add })} />
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Grid >
                                        <Grid >
                                            <AddMenuText onClick={addCylindralCallbaclk}>
                                                <CylindralStitchingIcon className={css`
                                                    margin-right: 5px;
                                                `} />{formatMessage({ id: LOCALIZATION.cylindrical_stitching })}
                                            </AddMenuText>
                                        </Grid>
                                        <Grid>
                                            <AddMenuText onClick={addRawStitchingCallback}>
                                                <PerspectiveStitchingIcon className={css`
                                                    margin-right: 5px;
                                                `} />
                                                {formatMessage({ id: LOCALIZATION.raw_stiching })}
                                            </AddMenuText>

                                            {/* <AddMenuText onClick={() => { addStitching({ type: 'perspective', name: "Default" }); setAnchorEl(null); }}>
                                                <PerspectiveStitchingIcon className={css`
                                                    margin-right: 5px;
                                                `}
                                                />Cylindrical Stitching
                                            </AddMenuText> */}

                                        </Grid>
                                    </Grid>
                                </Popover>
                            </Grid>

                        </Card>
                    </Grid>
                    <Grid md={7} sm={6} xs={12} xl={8}
                        style={{ height: "max-content" }}
                        item>
                        <Card
                            sx={{
                            height: "100%",
                            width: "100%",
                        }}
                            contentSx={{
                                padding: '20px !important',
                                paddingBottom: '30px !important',
                                display: "flex", flexDirection: "column"

                            }}>
                            <Header>{formatMessage({ id: LOCALIZATION.active })}: {activeItem?.name ?? formatMessage({ id: LOCALIZATION.panorama_disabled })}</Header>
                            <div className={css`
    aspect-ratio: ${cameras.length * 16}/9;
max-width: 100%;
                            `}>
                                <StyledCameraIntervalImage camera={"panorama_cropped"} interval={imageInterval} width={1200} />
                            </div>

                        </Card>
                    </Grid>

                </Grid>
            </LoadingComponent>

        </Popup>
        {editCylinderModal && <CameraImageProvider><RawSaveItemsStateProvider uuid={editCylinderModal}><CylindralForm uuid={editCylinderModal} onClose={() => setEditCyliderModal()} onApply={() => setEditCyliderModal()} /></RawSaveItemsStateProvider></CameraImageProvider>}
        {editPerspectiveModal && <CameraImageProvider><PerspectiveForm uuid={editPerspectiveModal} onClose={() => setEditPerspectivModal()} onApply={() => setEditPerspectivModal()} /></CameraImageProvider>}
        {editRawModal && <CameraImageProvider><RawSaveItemsStateProvider uuid={editRawModal}><RawForm uuid={editRawModal} onClose={() => setEditRawModal()} onApply={() => setEditRawModal()} /></RawSaveItemsStateProvider></CameraImageProvider>}

    </>
})

