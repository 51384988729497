// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { PlatformConfiguration } from '../Configuration/PlatformConfiguration';
import { FontName, useCurrentFont } from '../Configuration/Styles/Fonts';
import useCurrentUser from '../Data/AccountUsers/hook/useCurrentUser';
import { useFetchActiveCamera } from '../Data/Camera/ActiveCamera/hook';
import { intl } from '../Localization/LocalizationProvider';
import AppToolbar from './AppBar/AppToolbar';
import useBreadCrumbs from './breadCrumbs';
import { MenuElement, MenuID } from './Data/MenuElement';
import { getCamerasTitle } from './Data/MenuStructure';
import MenuContainer from './MenuContainer';
import MenuUIElement from './MenuUIElement';


type Props = Readonly<{
  isMenuOpened: boolean;
  items: MenuElement[];
  onMenuOpenedToggle: () => void;
  onLogout: () => void;
}>;


const menuWidth = PlatformConfiguration.menuWidth;

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  drawerPaper: {
    width: menuWidth,
    border: 0,
    boxShadow: '0px 4px 16px #00000014',
  }
}));

const Menu: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const isExpandableMenu = !useMediaQuery(theme.breakpoints.up('md'));
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const breadCrumbs = useBreadCrumbs();
  const user = useCurrentUser();
  const fetchActiveCamera = useFetchActiveCamera();

  if (props.items && user) {// update user name in menu item
    const menuItem = MenuElement.findMenuWithId(props.items, MenuID.Administration)
    if (menuItem) {
      menuItem.title = user.name ?? user.login
    }
  }

  if (props.items && fetchActiveCamera && !fetchActiveCamera.isLoading) {// update user name in menu item
    const menuItem = MenuElement.findMenuWithId(props.items, MenuID.Cameras)
    if (menuItem) {
      menuItem.title = getCamerasTitle(intl().formatMessage)
    }
  }

  if (breadCrumbs.length === 0 && props.items?.[0]?.id) {
    breadCrumbs.push(props.items[0].id);
  }

  const renderItem = (item: MenuElement) => {
    const handleClicked = (): void => {
      if (isExpandableMenu) {
        props.onMenuOpenedToggle();
      }
    }
    const closeMenuIfNeed = (): void => {
      if (isExpandableMenu) {
        props.onMenuOpenedToggle();
      }
    }

    return (
      <MenuUIElement
        key={ item.id }
        item={ item }
        breadCrumbs={ breadCrumbs }
        closeMenuIfNeed={ closeMenuIfNeed }
        onPageSelected={ handleClicked }
        onLogout={ props.onLogout }
      />
    )
  };

  return (
    <Drawer
      variant={ isExpandableMenu ? 'persistent' : 'permanent' }
      style={ {
        width: menuWidth,
        flexShrink: 0
      } }
      classes={ {
        paper: classes.drawerPaper,
      } }
      anchor="left"
      open={ props.isMenuOpened }
      PaperProps={ { elevation: 3 } }
    >
      <AppToolbar />
      <MenuContainer>
        { props.items.map(renderItem) }
      </MenuContainer>
    </Drawer>
  );
};


export default Menu;
