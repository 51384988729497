import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Loader from "../../../../Components/Loader";
import FieldSet, { FieldSetInputType } from "../../../../Components/_BaseUI/FieldSet";
import { useActivePanoramaStore } from "../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/query/useActivePanoramaStore";
import { usePanoramaStoreList } from "../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/query/usePanoramaStoreList";
import { useStitchingList } from "../../../../Data/NapiCameraHandlerV2/hooks/stiching/useStitchingList";
import { useKickCalibrationMode } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useKickCalibrationMode";
import { usePingCalibrationMode } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePingCalibrationMode";
import { NO_STREAM_ID } from "../../../../Data/NapiStreamingProfile/constants";
import { useNapiStreamingSettingsFetch } from "../../../../Data/NapiStreamingSettings/hook/index";
import { StreamingSettingsKey } from "../../../../Data/NapiStreamingSettings/index";
import { useCalibrationMode } from "../../../../hooks/useCalibrationMode";
import useConfirm from "../../../../hooks/useConfirm";
import LOCALIZATION from "../../../../Localization";
import { PanoramaImageList } from "./PanoramaImageList";
import { PanoramaStoreList } from "./PanoramaStoreList/index";

const useStyles = makeStyles((theme: Theme) => createStyles({
    editButton: {
        [theme.breakpoints.down(500)]: {
            display: "none !important"
        },
    },
    editIcon: {
        [theme.breakpoints.up(500)]: {
            display: "none  !important"
        },
    },
    buttonSx: {
        [theme.breakpoints.down(500)]: {
            minWidth: "20px !important",
        },
        [theme.breakpoints.up(500)]: {
            minWidth: "120px  !important"
        },
    }
}))

export const PanoramaSettingsTable = (() => {
    const { editButton, editIcon, buttonSx } = useStyles()

    const { formatMessage } = useIntl()
    const [imageSettingsModal, setImageSettingsModal] = useState(false);
    const [openStoreListModal, setOpenStoreListModal] = useState(false);
    const { mutateAsync: kickOperator } = useKickCalibrationMode()
    const { confirm } = useConfirm();
    const { mutateAsync: checkCalibrationMode, isLoading: checkingCalibrationMode } = usePingCalibrationMode();
    const { setMode: set, setUndistordDisabled } = useCalibrationMode();
    const { data: streamingSettings } = useNapiStreamingSettingsFetch()

    const onClickEditImageSettings = useCallback(async () => {
        set(null);
        if (streamingSettings?.data && streamingSettings?.data?.[StreamingSettingsKey.StreamingSettingsSelectedProfileID] !== NO_STREAM_ID && !(await confirm({
            bodyText: formatMessage({ id: LOCALIZATION.confirm_streamoff }),
            timeoutAction: "cancel",
            timeout: 15,
        }))) {
            return;
        }
        checkCalibrationMode().then(async ({ state }) => {
            if (state && (! await confirm({ bodyText: formatMessage({ id: LOCALIZATION.calibration_mode_is_occupy }), timeout: 15 }))) {
                return;
            }
            kickOperator();
        })
        setImageSettingsModal(true);
    }, [checkCalibrationMode, confirm, formatMessage, kickOperator, set, streamingSettings?.data])
    const onClickEditStoreSettings = useCallback(() => {
        setOpenStoreListModal(true);
    }, [])
    const { data: stitchingConfigs } = useStitchingList()
    const activeStitchingConfig = useMemo(() => {
        return stitchingConfigs?.getStitchingList.find(v => v.active)?.name ?? formatMessage({ id: LOCALIZATION.stitching_disabled })
    }, [formatMessage, stitchingConfigs?.getStitchingList])
    const { isLoading: gettingActive, data: activeSettingUuid } = useActivePanoramaStore()
    const { isLoading: gettingList, data: panoramaStoreList } = usePanoramaStoreList()
    const activePanoramaStore = useMemo(() => {
        return panoramaStoreList?.getPanoramaStoreList.find(v => v.uuid === activeSettingUuid?.getActivePanoramaStore)?.name ?? formatMessage({ id: LOCALIZATION.panorama_store_disabled })
    }, [activeSettingUuid?.getActivePanoramaStore, formatMessage, panoramaStoreList?.getPanoramaStoreList])
    return <>
        <Loader ugly isLoading={checkingCalibrationMode || gettingActive || gettingList} >
            <FieldSet
                button={{ label: formatMessage({ id: LOCALIZATION.edit }), onClick: onClickEditImageSettings }}
                label={`${formatMessage({ id: LOCALIZATION.page_camera_settings_panorama_image_settings })}`}
                input={{ type: FieldSetInputType.StaticLabel, value: `${activeStitchingConfig}` }}
                inputSx={{ display: "flex" }}
                labelSize={4}
                inputSize={8}
                buttonSx={buttonSx}
            ></FieldSet>
            <FieldSet
                button={{ label: formatMessage({ id: LOCALIZATION.edit }), onClick: onClickEditStoreSettings }}
                label={`${formatMessage({ id: LOCALIZATION.panorama_store_settings })}`}
                input={{ type: FieldSetInputType.StaticLabel, value: `${activePanoramaStore}` }}
                inputSx={{ display: "flex" }}
                labelSize={4}
                inputSize={8}
                buttonSx={buttonSx}
            ></FieldSet>
        </Loader>
        {imageSettingsModal && <PanoramaImageList onClose={() => setImageSettingsModal(false)} />}
        {openStoreListModal && <PanoramaStoreList onClose={() => setOpenStoreListModal(false)} />}


    </>
})
