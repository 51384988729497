// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Alert, AlertTitle } from '@mui/lab';
import { Grid } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import FieldSet, { FieldSetInputType } from '../../Components/_BaseUI/FieldSet';
import ErrorMessage from '../../Components/_BaseUI/LastErrorMessageLabel/ErrorMessage';
import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import Card from '../../Components/_Layout/Card';
import Header from '../../Components/_Layout/Header';
import PageContainer from '../../Components/_Layout/PageContainer';
import { ActionType, bitrateToMbit, CameraSettingsSyncConfig, mbitToBitrate, useMutationSyncConfig } from '../../Data/Camera/HWCameraSettings/CameraSettingsSyncConfig';
import { useCameraSettingsState } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import StorageInfo from '../../Data/NapiStorageInfo';
import { clearSyncServerQueue, flushCacheStorageInfo } from '../../Data/NapiStorageInfo/fetch';
import { useFetchStorageInfo } from '../../Data/NapiStorageInfo/hook';
import LOCALIZATION from '../../Localization';
import { formatBitrate, formatBytes } from '../../Tools/b2Mb';
import Storage from './Storage';

// export const useStylesInputBox = makeStyles(() =>  createStyles<any, { fontFamily: FontName }>({
//   input: {
//     height: '38px',
//     paddingTop: '0px',
//     paddingBottom: '0px',
//     lineHeight: '38px',
//   },
// }));


const DEFAULT_VALUE: StorageInfo = {
  removeOldVideoWhenStorageLessThenGB: 0,
  sizeInGB: 0,
  freeInGB: 0,
  syncInfo: {
    fileCount: 0,
    sizeInGB: 0,
  },
};

const StoragePage: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const storageInfoUpdateTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [storageInfo, setStorageInfo] = React.useState<StorageInfo>(DEFAULT_VALUE);
  const storageInfoFetchStat = useFetchStorageInfo();

  const [cameraSettings, setCameraSettings] = useCameraSettingsState();

  React.useEffect(() => {
    if (storageInfoFetchStat.status === 'success') {
      if (storageInfoFetchStat.data?.data) {
        setStorageInfo(storageInfoFetchStat.data.data);
      }

      setSyncConfigPostError(''); // reset error every 5 sec

      if (storageInfoUpdateTimer.current) {
        clearTimeout(storageInfoUpdateTimer.current);
      }
      storageInfoUpdateTimer.current = setTimeout(() => {
        flushCacheStorageInfo();
      }, 5000);
    }
  }, [storageInfoFetchStat.status, storageInfoFetchStat.data?.data]);

  React.useEffect(() => {
    return () => {
      if (storageInfoUpdateTimer.current) {
        clearTimeout(storageInfoUpdateTimer.current);
      }

      flushCacheStorageInfo();
    };
  }, []);

  const { mutateAsync: mutateSyncConfig } = useMutationSyncConfig();

  const [syncConfigPostError, setSyncConfigPostError] = React.useState('');
  const updateSyncConfig = async (config?: Partial<CameraSettingsSyncConfig> ) => {
    try {

      try {
        if (cameraSettings !== undefined) {
          let newSettings = {...cameraSettings}
          if (newSettings.syncConfig !== undefined) {
            newSettings.syncConfig = {...newSettings.syncConfig, ...config}
          }
          setCameraSettings(newSettings)
        }

        setSyncConfigPostError('');
        await mutateSyncConfig({
          type: ActionType.Patch,
          config: config,
        });
      } catch (error: any) {
        setSyncConfigPostError(error.toString());
      }
    } catch (error: any) {
      setSyncConfigPostError(error.toString());
    }
  };

  const handleClearSyncQueue = async () => {
    if (window && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_actions }))) {
      try {
        setSyncConfigPostError('');
        const newInfoData = await clearSyncServerQueue();
        setStorageInfo(newInfoData?.data);
      } catch (error: any) {
        setSyncConfigPostError(error.toString());
      }
    }
  };

  return (
    <PageContainer>
      <Header
        text={ props.intl.formatMessage({ id: LOCALIZATION.storage }) }
      />
      <Grid
        item
        xs={ 12 }
      >
        <Card>
          {
            (storageInfoFetchStat.data?.error) ?
              <ErrorMessage
                local
                error={ new Error(storageInfoFetchStat.data.error) }
              />
            : (!storageInfo) ?
              <>
              <br /><br /><Spinner /><br /><br />
              </>
            :
              <Storage
                sizeInGB={ storageInfo.sizeInGB }
                freeInGB={ storageInfo.freeInGB }
                removeOldVideoWhenStorageLessThenGB={ storageInfo.removeOldVideoWhenStorageLessThenGB }
              />
          }
        </Card>
      </Grid>


      <Header
        text={ props.intl.formatMessage({ id: LOCALIZATION.storage_sync }) }
        sx = { { paddingTop: '14px' } }
      />
      <Grid item xs={ 12 }>
        <Card>
          { !cameraSettings ? (
            <>
            <br /><br /><Spinner /><br /><br />
            </>
          ) : (
              <>

                { syncConfigPostError && (
                  <Alert severity="error">
                    <AlertTitle>{ syncConfigPostError }</AlertTitle>
                  </Alert>
                ) }

                <FieldSet
                  label={ props.intl.formatMessage({ id: LOCALIZATION.current_sync_speed_in_b_sec }) }
                  hideSeparator= { true }
                  input={ {
                    type: FieldSetInputType.StaticLabel,
                    value: (cameraSettings?.syncState?.currentSyncBitrate !== undefined) ? formatBitrate(cameraSettings?.syncState?.currentSyncBitrate) : props.intl.formatMessage({ id: LOCALIZATION.wait })
                  } }
                />

                <FieldSet
                  label={ props.intl.formatMessage({ id: LOCALIZATION.files_in_sync_queue_in_gb }) }
                  input={ {
                    type: FieldSetInputType.StaticLabel,
                    value: `${storageInfo?.syncInfo?.fileCount || 0} (${formatBytes(storageInfo?.syncInfo?.sizeInGB * 1073741824)})` ,
                    actionButtonLabel: (storageInfo?.syncInfo?.fileCount) ? props.intl.formatMessage({ id: LOCALIZATION.clear_sync_queue }) : undefined,
                    onActionButtonPress: handleClearSyncQueue
                  } }
                />

                <FieldSet
                    label={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_sync_to_server }) }
                    input={ {
                      type: FieldSetInputType.Boolean,
                      value: !!cameraSettings?.syncConfig?.syncEnabled,
                      onChange: (val: boolean) => {
                        updateSyncConfig({syncEnabled: val})
                      },
                    } }
                />

                { (!!cameraSettings?.syncConfig?.syncEnabled) && (
                  <>
                  <FieldSet
                    label={ props.intl.formatMessage({ id: LOCALIZATION.default_sync_speed_mbit }) }
                    hideSeparator= { true }
                    input={ {
                      type: FieldSetInputType.Float,
                      value: bitrateToMbit(cameraSettings?.syncConfig?.defaultSyncBitrate),
                      min: 0,
                      max: 500,
                      onChange: (val: number | null) => {
                        if (val !== null) {
                          updateSyncConfig({defaultSyncBitrate: mbitToBitrate(val)})
                        }
                      },
                    } }
                  />

                  <FieldSet
                    label={ props.intl.formatMessage({ id: LOCALIZATION.sync_speed_when_streaming_active_mbit }) }
                    hideSeparator= { true }
                    input={ {
                      type: FieldSetInputType.Float,
                      value: bitrateToMbit(cameraSettings?.syncConfig?.syncWhileStreamingBitrate),
                      min: 0,
                      max: 500,
                      onChange: (val: number | null) => {
                        if (val !== null) {
                          updateSyncConfig({syncWhileStreamingBitrate: mbitToBitrate(val)})
                        }
                      },
                    } }
                  />

                  <FieldSet
                    label={ props.intl.formatMessage({ id: LOCALIZATION.sync_speed_when_low_speed_network_mbit }) }
                    input={ {
                      type: FieldSetInputType.Float,
                      value: bitrateToMbit(cameraSettings?.syncConfig?.syncWhileLowNetworkSpeedBitrate),
                      min: 0,
                      max: 500,
                      onChange: (val: number | null) => {
                        if (val !== null) {
                          updateSyncConfig({syncWhileLowNetworkSpeedBitrate: mbitToBitrate(val)})
                        }
                      },
                    } }
                  />

                  <FieldSet
                    label={ props.intl.formatMessage({ id: LOCALIZATION.maximum_sync_speed_in }) }
                    hideSeparator = { true }
                    input={ {
                      type: FieldSetInputType.TimeTillTime,
                      value: {
                        time1: cameraSettings?.syncConfig?.maximumSyncStartTime,
                        time2: cameraSettings?.syncConfig?.maximumSyncEndTime
                      },
                      onChangeTime1: (val: string) => {
                        updateSyncConfig({maximumSyncStartTime: val})
                      },
                      onChangeTime2: (val: string) => {
                        updateSyncConfig({maximumSyncEndTime: val})
                      },
                    }
                    }
                  />

                  <FieldSet
                    label={ props.intl.formatMessage({ id: LOCALIZATION.maximum_sync_speed_mbit }) }
                    hideSeparator = { true }
                    input={ {
                      type: FieldSetInputType.Float,
                      value: bitrateToMbit(cameraSettings?.syncConfig?.maximumSyncBitrate),
                      min: 0,
                      max: 500,
                      onChange: (val: number | null) => {
                        if (val !== null) {
                          updateSyncConfig({maximumSyncBitrate: mbitToBitrate(val)})
                        }
                      },
                    } }
                  />
                </>
                )}
              </>
          ) }
        </Card>
      </Grid>
    </PageContainer>
  );
};


export default injectIntl(StoragePage);
