import { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

const useSortKeys = <T extends any>(o: T): T => {
  const sorted = useMemo(() => {
    if (typeof o !== "object") return o;
    return (
      o &&
      //@ts-ignore
      // eslint-disable-next-line
      (Object.keys(o)
        .sort()
        //@ts-ignore
        // eslint-disable-next-line
        .reduce((r, k) => ((r[k] = o[k]), r), {} as T) as T)
    );
  }, [o]);
  return sorted;
};

/**
 * Custom hook that returns an object and updates it when the initialValue changes.
 * @template T
 * @param {T} value - The initial value of the object.
 * @returns {T} The object that is updated when the initialValue changes.
 */
export function useObjectChanged<T extends any>(
  inputValue: T
): { value: T; version: string } {
  const value = useSortKeys(inputValue);

  const [object, setObject] = useState<{ value: T; version: string }>({
    value,
    version: v4(),
  });

  useEffect(() => {
    // Check if the object has changed
    if (JSON.stringify(object.value) !== JSON.stringify(value)) {
      // If it has changed, update the object
      setObject({ value, version: v4() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return { ...object };
}
