export enum ConditionOperator {
  Contains = "Contains",
  ContainsCS = "Contains CS",
  Equal = "Equal",
  EqualCS = "Equal CS",
  Starts = "Starts",
  StartsCS = "Starts CS",
  Ends = "Ends",
  EndsCS = "Ends CS",
  Any = "Any",
}

export enum LogicalOperator {
  IF = "IF",
  AND = "AND",
  OR = "OR",
}

export enum FieldName {
  Title = "Title",
  Subtitle = "Subtitle",
  Description = "Description",
}

export enum ActionType {
  ThenSet = "Then Set",
  SetEventType = "Event Type",
  SetStreamProfile = "Stream Profile",
  SetSportType = "Sport Type",
}

export enum EndAction {
  End = "STOP NEXT RULES",
  Continue = "CONTINUE NEXT RULES",
}

export interface Condition {
  logicalOperator: LogicalOperator; // Логический оператор, указывающий начало условия и его связь с другими условиями
  field: FieldName;
  operator: ConditionOperator;
  value: string;
}

export interface IfBlock {
  conditions: Condition[];
}

export interface Action {
  type: ActionType;
  value: string;
}

export interface Rule {
  id: number; // Уникальный идентификатор правила
  ifBlock: IfBlock;
  actions: Action[];
  successEndAction: EndAction; // Действие, если все условия выполнены корректно
  failureEndAction: EndAction; // Действие, если условия не выполнены
}

export interface ScheduleImportRules {
  rules: Rule[];
}

