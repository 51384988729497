// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';

import { Colors } from '../../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import useStyles from './useStyles';

export enum LabelType {
  Red = 'red',
  Green = 'green',
  Yellow = 'yellow',
  Olive = 'olive',
  DarkGreen = 'darkGreen',
  Grey = 'grey',
}

type Props = Readonly<{
  labelType?: LabelType;
  title: ReactNode;
  stateText?: ReactNode;
  isReversed?: boolean;
  sx?: SxProps<Theme>;
}>;

const COLOR: { [key in LabelType]: string } = {
  [LabelType.Red]: Colors.cicrcleRed,
  [LabelType.Green]: Colors.cicrcleGreen,
  [LabelType.Yellow]: Colors.cicrcleYellow,
  [LabelType.Olive]: Colors.cicrcleOlive,
  [LabelType.DarkGreen]: Colors.cicrcleDarkGreen,
  [LabelType.Grey]: Colors.lightGrey,
};

const CircledLabel: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont();
  const classes = useStyles({ fontFamily: font });
  const {
    isReversed = false,
    labelType = LabelType.Green,
  } = props;

  const items = [
    <Grid item key="icon">
      <div className={classes.iconContainer}>
        <div className={classes.icon} style={{ background: COLOR[labelType] }} />
      </div>
    </Grid>,
    <Grid item key="text">
      <div className={classes.regularText}>
        {props.title}
        {props.stateText && <>: {props.stateText}</>}
      </div>
    </Grid>,
  ];

  if (isReversed) {
    items.reverse();
  }

  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        ...props.sx
      }}
      spacing={1}
      direction="row"
      wrap="nowrap"
    >
      {items.map(item => item)}
    </Grid>
  );
};

export default CircledLabel;
