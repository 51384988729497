import { ExposedConfigurationID } from "../../../Data/NapiExposedConfigurations/ExposedConfigurationID.enum";
import { ApiRequestConfig, ApiRequestMethod } from "../Types";

export class SelectorAPIOption {
  type = "napi";
  /**
   * for indiffirate the type
   */
  name!: ExposedConfigurationID;
  methods!: ApiRequestMethod[];
  constructor(partial: Partial<SelectorAPIOption>) {
    Object.assign(this, partial);
  }
}

export type SelectorOption = ApiRequestConfig | SelectorAPIOption;
