// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { TextField } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import cn from 'classnames';
import { format } from 'date-fns';
import React from 'react';

import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import { useStylesInputBox } from './FieldSetText';
import { getLocale } from './helpers';
import { FieldSetInputType } from './index';
import styles from './styles.module.css';


export type FieldSetDateAndTime = Readonly<{
  type: FieldSetInputType.DateAndTime;
  maxDate?: Date;
  minDate?: Date;
  errorTime?: string;
  value: {
    date: string | null;
    time: string;
  };
  errorCheckPattern?: (date: string, time: string) => boolean;
  onChangeDate: (date: string) => void;
  onChangeTime: (time: string, date?: string) => void;
}>;

type Props = Readonly<{
  input: FieldSetDateAndTime;
  isError?: boolean;
  onIsError?: (isError: boolean) => void;
}>;


const dateFormat = 'yyyy.MM.dd';

const FieldSetDateAndTimeComponent: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStylesInputBox({ fontFamily: font });
  const now = new Date();
  const { localization: { locale } } = useAppConfig();
  const date = (props.input.value?.date && props.input.value.date.length) ?
    props.input.value.date.replaceAll('.', '-')
    :
    (props.input.value?.date === null) ? null : format(now, 'yyyy-MM-dd');
  const dateTime = (props.input.value?.time && props.input.value.time.length) ?
    new Date(`${date?.replaceAll('.', '-') || format(now, 'yyyy-MM-dd')}T${props.input.value.time}`)
    :
    now;

  const handleDateValueChanged = (date: any | null) => {
    try {
      const newDate = format(date, dateFormat);

      props.input.onChangeDate(newDate);

      if (props.input.errorCheckPattern && props.onIsError) {
        props.onIsError(props.input.errorCheckPattern(newDate, props.input.value.time));
      }

    } catch (ignore) {
      props.input.onChangeDate(format(new Date(), dateFormat));
    }
  };
  const handleTimeValueChanged = (time: any) => {
    try {
      const newTime = format(time || new Date(), 'HH:mm');

      if (props.input.errorCheckPattern && props.onIsError) {
        props.onIsError(props.input.errorCheckPattern(format((date as any | null) || now, dateFormat), newTime));
      }

      props.input.onChangeTime(newTime);
    } catch (ignore) {
      // ignore
    }
  };

  const renderInput = (params: MuiTextFieldPropsType): React.ReactElement => (
    <TextField
      { ...params }
      InputProps={ {
        className: cn(classes.input, {
          [classes.error]: props.isError,
        }),
      } }
      helperText={ props.input.errorTime }
      error={ (props.input.errorTime !== undefined) }
    />
  );

  return (
    <LocalizationProvider
      locale={ getLocale(locale) }
      dateAdapter={ AdapterDateFns }
    >
      <>
        <div
          className={ styles.date }
        >
          <MobileDatePicker
            inputFormat={ dateFormat }
            value={ date }
            onChange={ handleDateValueChanged }
            renderInput={ renderInput }
          />
        </div>
        <div
          className={ styles.time }
        >
          <MobileTimePicker
            value={ dateTime }
            onChange={ handleTimeValueChanged }
            renderInput={ renderInput }
          />
        </div>
      </>
    </LocalizationProvider>
  );
};


export default FieldSetDateAndTimeComponent;
