// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useCurrentFont } from '../../../../../Configuration/Styles/Fonts';

import { MetricOutputTimeAll, MetricOutputTimeInterface } from '../../../../../Data/Camera/Metrics/Types';
import useStyles from './useStyles';


type Props = Readonly<{
  time: MetricOutputTimeInterface;
  onChanged: (time: MetricOutputTimeInterface) => void;
}>;


const MetricsChartOutputTimeSelector: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const currentValue = props.time;


  const handleCurrentValueChange = (time: MetricOutputTimeInterface) => {
    props.onChanged(time)
  }

  function createDropDownItems() {
      return MetricOutputTimeAll.map(time => {
          return (
              <MenuItem
                  key = { time.timeInterval }
                  value = { time.timeInterval }
                  onClick = { () => { handleCurrentValueChange(time) } }>
                  <div className = {classes.text}>{ time.displayName }</div>
              </MenuItem>
          )
      })
  }

  return (
    <div className={ classes.root }>
      <FormControl
        variant="outlined"
        className={ classes.formControl }
        size="small"
      >
        <Select
          className={ classes.select }
          displayEmpty
          value={ currentValue.timeInterval }
        >
          { createDropDownItems() }
        </Select>
      </FormControl>
    </div>
  )
};


export default MetricsChartOutputTimeSelector
