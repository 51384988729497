// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { useIntl } from 'react-intl';
import { Colors } from '../../../../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../../../../Configuration/Styles/Sizes';
import LOCALIZATION from '../../../../../../Localization';


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    flexGrow: 1
  },
  formControl: {
    minWidth: 90,
    width: '100%',
    minHeight: 40,
    background: Colors.mainBackground,
    fontFamily: ({ fontFamily }) => fontFamily,
  },
  select: {
    height: 38,
    borderRadius: 6
  },
  text: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.subtitle
  },
  correct: {
    color: Colors.mainTitle
  },
  wrong: {
    color: Colors.mainRed
  }
}));


export default function JetsonHardwareButtonDurationSelector(props: { durations?: number[], currentDuration?: number, isCorrect?: boolean, onChanged: (duration: number) => void }) {
  const intl = useIntl();
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })
  const durations = props.durations ?? [1000, 2000, 3000, 4000, 5000, 10000, 12000, 15000]
  const isCorrect = (props.isCorrect !== undefined) ? props.isCorrect : true

  const currentValue = props.currentDuration

  const handleCurrentValueChange = (duration: number) => {
    props.onChanged(duration)
  }

  const durationName = (duration: number) => {
    return duration.toFixed(0) + ' ' + intl.formatMessage({ id: LOCALIZATION.abr_milliseconds })
  }

  function createDropDownItems() {
    return durations.map(duration => {
      return (
        <MenuItem
          key={ duration }
          value={ duration }
          onClick={ () => {
            handleCurrentValueChange(duration)
          } }>
          <div
            className={ clsx(classes.text, isCorrect ? classes.correct : classes.wrong) }>{ durationName(duration) }</div>
        </MenuItem>
      )
    })
  }

  return (
    <div className={ classes.root }>
      <FormControl variant="outlined" fullWidth className={ classes.formControl } size="small">
        <InputLabel>{ intl.formatMessage({ id: LOCALIZATION.duration }) }</InputLabel>
        <Select
          className={ classes.select }
          displayEmpty
          label={ intl.formatMessage({ id: LOCALIZATION.duration }) }
          value={ currentValue }
        >
          { createDropDownItems() }
        </Select>
      </FormControl>
    </div>
  )
}
