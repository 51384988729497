import { gql } from 'graphql-request';
import { debounce } from 'lodash';
import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useCameraHandler } from "../../../../hooks/useCameraHandler";
import { undistordConfigCacheKey } from "../useUndistordConfig";

interface IResponse {
  getCalibrationState: {
    status: "pending" | "resolved" | "rejected" | "not_spawned";
    config?: {
      type: "fisheye" | "regular";
      kdata: string;
      ddata: string;
      angle: number;
      balance: string;
    };
    info?: {
      reported_reprojection_error: number;
      avg_reprojection_error: number;
    };
  };
}

const graphql = gql`
  query GetCalibrationState($uuid: ID!) {
    getCalibrationState(uuid: $uuid) {
      status
      config {
        type
        kdata
        ddata
        angle
        balance
      }
      info {
        reported_reprojection_error
        avg_reprojection_error
      }
    }
  }
`;

interface IUseCalibrationResult<TData> {
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
  pollingDelay?: number; //  параметр для задания интервала между запросами
  maxRetries?: number; //  параметр для задания количества попыток
  uuid: string;
}

export const calibrationStateCacheKey = (uuid: string) =>
  `useCalibrationStateCacheKey${uuid}`;

export const usePollCalibrationState = (
  props: IUseCalibrationResult<IResponse | undefined>
) => {
  const { options, pollingDelay = 0, maxRetries = 0, uuid } = props ?? {};
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const key = [calibrationStateCacheKey(uuid)] as QueryKey;
  const request = () => client?.request<IResponse>(graphql, { uuid });
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };

  const polledRequest = async () => {
    let retries = 0;
    let response = await request();
    while (
      response?.getCalibrationState.status === "pending" &&
      retries < maxRetries
    ) {
      response = await request();
      retries++;
      await new Promise((resolve) => setTimeout(resolve, pollingDelay)); // Ожидаем заданный интервал перед следующим запросом
    }
    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(undistordConfigCacheKey(uuid));
      },
    });
    return response;
  };

  const query = useQuery(key, () => polledRequest(), {
    staleTime: 0,
    cacheTime: 0,
    enabled: false, // Отключаем начальный запрос
    keepPreviousData: true,
    ...options,
  });

  const fetchData = debounce(async () => {
    return queryClient.fetchQuery(key, polledRequest); // Prefetch the query
  }, 100);
  const abort = async () => {
    await queryClient.cancelQueries(calibrationStateCacheKey(uuid));
  };

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
    abort,
  };
};
