import { debounce } from "lodash";
import { useEffect, useMemo } from "react";
import { BehaviorSubject } from "rxjs";
import { getJavaBackend } from "../Configuration/ENV/index";
import { useCameraSettings } from "../Data/Camera/HWCameraSettings/hook/Hooks";
import { useAsync } from "./useAsync";
import { useCache } from "./useCache";
import { useDocumentLoader } from "./useDocumentLoader";
import useImageSize from "./useImagesSize";
import { useIsProd } from "./useIsProd";
const cachedPanoramaOriginalSizes$ = new BehaviorSubject<
  { width: number; height: number } | undefined
>(undefined);

export const useCachedPanoramaOriginalSizes = () => {
  const value = useAsync({ observable: cachedPanoramaOriginalSizes$ });
  const cameraSettings = useCameraSettings(true);
  const isProd = useIsProd();
  const set = (v: { width: number; height: number }) =>
    cachedPanoramaOriginalSizes$.next(v);
  const src = useMemo(() => {
    const { protocol, host, port } = new URL(getJavaBackend());

    return cameraSettings?.urls?.liveSrcPanoramaImage
      ? `${`${protocol}//${host}:${port}`}${
          cameraSettings?.urls?.liveSrcPanoramaImage
        }`
      : undefined;
  }, [cameraSettings?.urls?.liveSrcPanoramaImage, isProd]);
  const { getValue } = useCache();

  const { base64Url, fetch } = useDocumentLoader(
    getValue("full_frame_panorama")?.value ?? src
  );
  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 1_000);
  }, [fetch]);
  const meta = useImageSize(base64Url);

  useEffect(() => {
    meta && set(meta);
  }, [meta]);

  return { value, set, fetch: debouncedFetch };
};
