import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { exhaustMap, from, lastValueFrom, Subject, take } from "rxjs";
import { v4 } from "uuid";
import { useAsync } from "../../../../../hooks/useAsync";
import { useZip } from "../../../../../hooks/useZip";





/**
 *  Context for keeping/changing the state of page filtering state
 */
export const ZipArchivatorContext = createContext<{
    requestZip: () => Promise<undefined | Blob>, outputFile?: Blob,
    inputFiles: {
        name: string;
        content: string | number[] | Uint8Array | ArrayBuffer | Blob;
    }[],
    setInputFiles: (value: {
        name: string;
        content: string | number[] | Uint8Array | ArrayBuffer | Blob;
    }[]) => void
}>({
    requestZip: () => {
        throw new Error("Function not implemented.");
    },
    setInputFiles: () => {
        throw new Error("Function not implemented.");
    },
    outputFile: undefined,
    inputFiles: []
});

export const useZipArchivatorState = () => {
    const context = useContext(ZipArchivatorContext)
    return { ...context }
}


export const ZipArchivator = (props: {
    children: ReactNode;
}) => {

    const [inputFiles, setInputFiles] = useState<{
        name: string;
        content: string | number[] | Uint8Array | ArrayBuffer | Blob;
    }[]>([])
    const { zip: runZip } = useZip(inputFiles)
    const zip$ = useMemo(() => new Subject<string>(), [])

    const zipOutput$ = useMemo(() => {
        return zip$.pipe(exhaustMap(v => from(runZip(inputFiles))))
    }, [inputFiles, runZip, zip$])
    const setZip = useCallback(() => {
        const nextValue = lastValueFrom((zipOutput$.pipe(take(1))))
        zip$.next(v4())
        return nextValue;
    }, [zip$, zipOutput$]);
    const outputZip = useAsync({ observable: zipOutput$ })

    const { children, } = props;
    return <>
        <ZipArchivatorContext.Provider value={{
            requestZip: setZip,
            outputFile: outputZip,
            inputFiles,
            setInputFiles
        }}>
            {children}
        </ZipArchivatorContext.Provider>
    </>
}
