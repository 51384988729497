import styled from "@emotion/styled";
import { createStyles, makeStyles } from '@mui/styles';

import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { filter, fromEvent, map, Subject } from "rxjs";
import useSound from "use-sound";
import { v4 } from "uuid";
import screenSfx from "../../../../../../../Audio/screenshot.mp3";
import { CameraIntervalImage } from "../../../../../../../Components/CameraIntervalImage";
import LoadingComponent from "../../../../../../../Components/Loader/index";
import MainButton, { ButtonType } from "../../../../../../../Components/_BaseUI/MainButton";
import Popup from "../../../../../../../Components/_Layout/Popup";
import { usePingCalibrationMode } from "../../../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePingCalibrationMode";
import { useAsync } from "../../../../../../../hooks/useAsync";
import { FIcon, RefreshIcon } from "../../../../../../../Icons/Icons";
import LOCALIZATION from "../../../../../../../Localization";
interface IProps {
    camera?: number,
    onClose: () => void,
    handleSubmit?: (base64img: [string]) => void,
}


const useStyles = makeStyles((theme: Theme) => createStyles({

    contentRoot: {
        padding: 0,
        height: "100%",
        background: '#E5E5E5',
        overflowY: "hidden",
    },

}));

const StyledCameraIntervalImage = (styled(CameraIntervalImage)`
        object-fit: contain;
    max-width: 100%;

    max-height: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    width: fit-content;
    width: -webkit-fill-available;
`)

const AlignDiv = styled.div`
display: flex;
    align-items: center;
`
const ApplyTitle = () => {
    const { formatMessage } = useIntl();
    return <AlignDiv>{formatMessage({ id: LOCALIZATION.add_image })}<FIcon width={24} height={24} /></AlignDiv>
}

export const AddImageModal = memo((props: IProps) => {
    const { camera = 0, onClose, handleSubmit } = props;

    const popUpStyles = useStyles()

    const [renewInterval, setRenewInterval] = useState(5_000);



    const base64$ = useMemo(() => new Subject<string | undefined>(), []);

    const base64 = useAsync({ observable: base64$ });


    const handleChange = (event: SelectChangeEvent) => {
        setRenewInterval(Number(event.target.value));
    };

    const [imageKey, setImageKey] = useState<string>(v4())

    const [screenShot] = useSound(screenSfx)


    const applyCallback = useCallback(() => {
        if (!base64) return;
        handleSubmit?.([base64]);
        screenShot()
        onClose();
    }, [base64, handleSubmit, onClose, screenShot])

    const { formatMessage } = useIntl();

    const pressedF$ = useMemo(() => fromEvent(document, 'keypress').pipe(filter((e) => (e as KeyboardEvent).key.toLowerCase() === "f"), map(() => v4())), [])

    const { mutateAsync: pingCalibrationMode, } = usePingCalibrationMode({
        options: {
            keepPreviousData: true,
        }
    })
    const [isLoading, setIsloading] = useState<boolean>(true)
    useEffect(() => {
        (async () => {
            setIsloading(true);
            await pingCalibrationMode()
            setIsloading(false);
        })()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pressedF = useAsync({
        observable: pressedF$
    })

    useEffect(() => {
        if (!pressedF) {
            return;
        }
        applyCallback()
    }, [applyCallback, pressedF])

    return <Popup
        isFullScreen
        headerTitle={formatMessage({ id: LOCALIZATION.add_image })}
        applyTitle={<ApplyTitle />}
        isApplyDisabled={!base64}
        onApply={applyCallback}
        onClose={onClose}
        classes={
            popUpStyles
        }
        headerChildren={<Grid container maxHeight={36} display={'flex'} alignContent={"center"} gap={1} margin={1}>
            <Grid item marginLeft={'auto'} xs={2}>
                <FormControl fullWidth>
                    <InputLabel id="2f257c6d-c3ad-4972-9790-16c30e483f0b">{formatMessage({ id: LOCALIZATION.auto_refresh })}</InputLabel>
                    <Select
                        sx={{ height: 38 }}
                        variant={"outlined"}
                        labelId="2f257c6d-c3ad-4972-9790-16c30e483f0b"
                        id="14d4316a-de0c-42d9-b517-0cb50b29d23a"
                        value={renewInterval.toString()}
                        label={formatMessage({ id: LOCALIZATION.auto_refresh })}
                        onChange={handleChange}
                    >
                        <MenuItem value={5_000}>5 {formatMessage({ id: LOCALIZATION.sec })}</MenuItem>
                        <MenuItem value={Infinity}>{formatMessage({ id: LOCALIZATION.off })}</MenuItem>
                    </Select>
                </FormControl>

            </Grid>
            <Grid>
                <MainButton buttonType={ButtonType.Icon} onClicked={() => setImageKey(v4())}><RefreshIcon /></MainButton>
            </Grid>

        </Grid>}
    // classes={classes}
    >
        <LoadingComponent isLoading={isLoading}>
        <Grid sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center", position: "relative" }}>
            <StyledCameraIntervalImage undistordTemplate key={imageKey} image_id={imageKey} camera={camera} interval={renewInterval} overlapCheckDisable onLoad={(v) => base64$.next(v)} />
        </Grid>
        </LoadingComponent>
    </Popup >


})