import { useMemo } from "react";
import { useCameraCommonSettings } from "./useCameraCommonSettings";

export const useCameras = () => {
  const { data: commonSettings, isLoading } = useCameraCommonSettings();

  const num_cameras = useMemo(
    () =>
      new Array(commonSettings?.configState.config.camera["num-cameras"])
        .fill(0)
        .map((_, i) => i),
    [commonSettings?.configState.config.camera["num-cameras"]]
  );

  return { isLoading, data: num_cameras };
};
