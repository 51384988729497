import styled from '@emotion/styled';
import React, { forwardRef, ImgHTMLAttributes, useCallback } from 'react';

interface ImageProps {
    src?: string;
    overrideSrc?: string;
    tabOpened?: (v: Window) => void;
}

export const withOpenBlank = <P extends ImgHTMLAttributes<HTMLImageElement>>(Component: React.ComponentType<P>) => {
    return forwardRef((props: P & ImageProps, ref) => {
        const { src, overrideSrc, tabOpened } = props;

        const handleClick = useCallback(() => {
            const imageSrc = overrideSrc ?? src;

            if (!imageSrc) {
                return;
            }

            const isBase64 = imageSrc.startsWith('data:image/') && imageSrc.includes('base64,');
            if (isBase64) {
                // Извлекаем часть base64 данных
                const base64Data = imageSrc.split(',')[1];
                // Преобразуем base64 в двоичный массив
                const binaryData = atob(base64Data);
                const len = binaryData.length;
                const buffer = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    buffer[i] = binaryData.charCodeAt(i);
                }

                // Создаем Blob из двоичного массива
                const blob = new Blob([buffer], { type: 'image/png' });
                const blobUrl = URL.createObjectURL(blob);

                // Открываем Blob URL в новой вкладке
                const newTab = window.open(blobUrl, '_blank');
                if (newTab && tabOpened) {
                    tabOpened(newTab);
                }

                // Очищаем URL после открытия
                setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
            } else {
                const newTab = window.open(imageSrc, '_blank');
                if (newTab && tabOpened) {
                    tabOpened(newTab);
                }
            }
        }, [overrideSrc, src, tabOpened]);

        const ComponentWithHover = styled(Component)`
            :hover {
                cursor: zoom-in;
            }
        `;

        return <ComponentWithHover onClick={handleClick} {...(props as P)} ref={ref} />;
    });
};
