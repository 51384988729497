import { useMemo } from "react";
import { useStitchingList } from "./useStitchingList";

type RequestParams = {
  uuid: string;
};

export const useStitchingConfig = ({ uuid }: RequestParams) => {
  const { isLoading, data: list } = useStitchingList();
  const config = useMemo(() => {
    return list?.getStitchingList.find((v) => v.uuid === uuid);
  }, [list?.getStitchingList, uuid]);
  return {
    isLoading,
    data: config,
  };
};
