// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Colors } from "../../../../../Configuration/Styles/Colors";
import { FontName } from "../../../../../Configuration/Styles/Fonts";
import { Sizes } from '../../../../../Configuration/Styles/Sizes';


const useStyles = makeStyles((theme: Theme) =>
  createStyles<any, { fontFamily: FontName }>({
    root: {
      flexGrow: 1,
    },
    formControl: {
      minWidth: 90,
      width: "100%",
      minHeight: 40,
      background: Colors.mainBackground,
      fontFamily: ({ fontFamily }) => fontFamily,
    },
    select: {
      height: 38,
      borderRadius: 6,
    },
    text: {
      fontFamily: ({ fontFamily }) => fontFamily,
      fontSize: Sizes.subtitle,
    },
    correct: {
      color: Colors.mainTitle,
    },
    wrong: {
      color: Colors.mainRed,
    },
  })
);


export default useStyles;
