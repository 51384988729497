// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export enum ApiRequestMethod {
  GET = "GET",
  POST = "POST",
}

/**
 * @deprecated overeengenering
 */
export interface ApiRequestConfigInterface {
  type: "API";
  name: string;
  methods: ApiRequestMethod[];
  url: string;
  timeout: number;
}

/**
 * @deprecated overeengenering
 */
export class ApiRequestConfig implements ApiRequestConfigInterface {
  type: "API" = "API";
  name!: string;
  methods!: ApiRequestMethod[];
  url!: string;
  timeout!: number;
  constructor(partial: Partial<ApiRequestConfig>) {
    Object.assign(this, partial);
  }
}

export type NetworkRequestState = "pending" | "completed";
export type NetworkResponseStatus = "error" | "validated";

export interface ResponseBody {
  status: NetworkResponseStatus;
  message?: string;
}

export interface NetworkRequestInterface {
  readonly id: string;
  readonly state: NetworkRequestState;
  readonly attemptNumber: number;
  readonly timeout: number;
  readonly url?: string;
  readonly status: number;
  readonly responseBody?: string;
}
