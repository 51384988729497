import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import {
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useIntl } from "react-intl";
import { getLocale } from "../Components/_BaseUI/FieldSet/helpers";
import LOCALIZATION from "../Localization";
import { useAppConfig } from "../Localization/AppContextProvider/helpers";

type TimePickerDialogState = {
  unixtimestamp: number | undefined,
  isOpen: boolean;
  resolve: (value: number | undefined) => void,
};

const initialValue: TimePickerDialogState = {
  isOpen: false,
  unixtimestamp: undefined,
  resolve: function(value: number | undefined): void {
    throw new Error("Function not implemented.");
  }
};

const TimePickerDialogContext = createContext<{
  state: TimePickerDialogState;
  setState: React.Dispatch<React.SetStateAction<TimePickerDialogState>>;
}>({
  state: {
    isOpen: false,
    unixtimestamp: undefined,
    resolve: function(value: number | undefined): void {
      throw new Error("Function not implemented.");
    },
  },
  setState: function(value: SetStateAction<TimePickerDialogState>): void {
    throw new Error("Function not implemented.");
  },
});

export const TimePickProvider: React.FC<{ children: ReactNode[] }> = ({
  children,
}) => {
  const { formatMessage } = useIntl();
  const [state, setState] = useState<TimePickerDialogState>(initialValue);
  const { isOpen, unixtimestamp, resolve } = state;

  const [time, setTime] = useState<Date | undefined>(new Date(0))

  const handleClose = useCallback(() => {
    if (state) {
      resolve(undefined)
      setState(initialValue);
    }
  }, [resolve, state]);

  const handleAccept = useCallback(() => {
    if (state && time) {
      const milliseconds = time.getUTCHours() * 60 * 60 * 1000 +
        time.getUTCMinutes() * 60 * 1000 +
        time.getUTCSeconds() * 1000;
      resolve(milliseconds)
      setState({ ...initialValue, unixtimestamp: milliseconds, isOpen: false });
    }
  }, [resolve, state, time]);

  useEffect(() => {
    if (!unixtimestamp) {
      return;
    }
    setTime(new Date(unixtimestamp))
  }, [unixtimestamp])



  const { localization: { locale } } = useAppConfig();

  return (
    <TimePickerDialogContext.Provider value={{ state, setState }}>
      {children}
      <Dialog open={isOpen} onClose={handleClose}>

        <DialogContent>
          <DialogContentText>
            <LocalizationProvider
              locale={getLocale(locale)}
              dateAdapter={AdapterDateFns}
            >
              <StaticTimePicker
                displayStaticWrapperAs="mobile"
                value={time}
                onChange={(v) => v && setTime(new Date(v))}
                onAccept={handleAccept}
                renderInput={(params) => <TextField {...params} />}
                components={{
                  ActionBar: () => <>
                    <DialogActions>
                      <Button onClick={handleClose}>
                        {formatMessage({ id: LOCALIZATION.cancel })}
                      </Button>
                      <Button onClick={handleAccept} autoFocus>
                        {`${formatMessage({ id: LOCALIZATION.confirm })}`}
                      </Button>
                    </DialogActions>
                  </>
                }
                }
              />
            </LocalizationProvider>

          </DialogContentText>
        </DialogContent>

      </Dialog>
    </TimePickerDialogContext.Provider>
  );
};



const useTimePicker = () => {
  const state = useContext(TimePickerDialogContext);

  if (!state) {
    throw new Error("useConfirm must be used within a ConfirmDialogProvider");
  }

  const pick = (): Promise<number | undefined> => {
    return new Promise<number | undefined>((resolve) => {
      state.setState({
        ...state.state,
        isOpen: true,
        resolve,
      });
    });
  };

  return { pick, ...state.state };
};

export default useTimePicker;
