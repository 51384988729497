import { Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../Components/Loader/index";
import SortedTable from "../../Components/SortedTable/index";
import { HeadCell } from "../../Components/SortedTable/SortedTableHead";
import PageContainer from "../../Components/_Layout/PageContainer/index";
import { AntTab, AntTabsHolder } from "../../Components/_Layout/Tabs/index";
import { useFetchActualMetrics } from "../../Data/Software/useActualSoftware";
import { useSoftwareVersions } from "../../Data/Software/useSoftwareVersions";
import Order from "../../Data/_Networking/ReactQuery/Order";
import { useIsServer } from "../../hooks/useIsServer";
import { useVersionTable } from "../../hooks/useVersionTable";
import LOCALIZATION from "../../Localization/index";

export const SoftwarePage: React.FC = () => {
  const isServerEnabled = useIsServer();
  const [currentTab, setCurrentTab] = useState(0);
  const { formatMessage } = useIntl();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>(Order.DESC);
  const [orderBy, setOrderBy] = useState<keyof any>("device");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (limit: number) => {
    setRowsPerPage(limit);
  };

  const handleRequestSort = (property: keyof any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? Order.DESC : Order.ASC);
    setOrderBy(property);
  };

  const isServer = isServerEnabled ? currentTab === 1 : false;
  const { data: currentSoftware, isLoading: currentSoftwareisLoading } = useSoftwareVersions({ isServer });
  const { data: actualSoftware, isLoading: actualSoftwareisLoading } = useFetchActualMetrics();
  const rows = useVersionTable(currentSoftware, actualSoftware);

  //@ts-ignore
  const headCells: HeadCell<any>[] = [
    { id: "device", label: formatMessage({ id: LOCALIZATION.device }), numeric: false, disablePadding: true },
    { id: "componentName", label: formatMessage({ id: LOCALIZATION.component }), numeric: false, disablePadding: true },
    { id: "version", label: formatMessage({ id: LOCALIZATION.version }), numeric: false, disablePadding: true },
    { id: "tag", label: formatMessage({ id: LOCALIZATION.tag }), numeric: false, disablePadding: true },
    { id: "size", label: formatMessage({ id: LOCALIZATION.size }), numeric: false, disablePadding: true },
    isServerEnabled ? { id: "actualVersion", label: formatMessage({ id: LOCALIZATION.actual_version }), numeric: false, disablePadding: true } : null,
  ].filter(Boolean);

  return (
      <PageContainer
        header={
          !isServerEnabled ? formatMessage({
            id: LOCALIZATION.camera_software
          }) : ''
        }
    >
      <LoadingComponent ugly isLoading={actualSoftwareisLoading || currentSoftwareisLoading}>

        <Grid container
          sx={{ marginLeft: '20px', }}
          gap={2}
        >
          <Grid xs={12} item>
            {isServerEnabled && (
              <AntTabsHolder value={currentTab} onChange={handleChange} aria-label="software version tabs">
                <AntTab label={formatMessage({ id: LOCALIZATION.camera_software })} id="tab-0" />

                <AntTab label={formatMessage({ id: LOCALIZATION.server_software })} id="tab-1" />
              </AntTabsHolder>
            )}
        </Grid>
          <Grid xs={12} item>
            <Paper sx={{ borderRadius: 3, boxShadow: 3, paddingLeft: '20px', paddingRight: '20px' }}>
              <SortedTable<any>
                page={page}
                rowsPerPage={rowsPerPage}
                order={order}
                orderBy={orderBy}
                manyItems={{ data: rows, meta: { status: 1 } }} 
                headCells={headCells}
                onPageChange={handlePageChange}
                onPeerPageChange={handleRowsPerPageChange}
                onRequestSort={handleRequestSort}
              />
            </Paper>
          </Grid>
        </Grid>
      </LoadingComponent>

    </PageContainer>
  );
};
