import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { merge } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader";
import MainButton from "../../../../../Components/_BaseUI/MainButton";
import { useCurrentFont } from "../../../../../Configuration/Styles/Fonts";
import { useIndividualCamera } from "../../../../../Data/NapiCameraHandlerV2/hooks/individual-camera/useIndividualCamera";
import { useCameraCommonSettings } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { RefreshIcon2 } from "../../../../../Icons/Icons";
import LOCALIZATION from "../../../../../Localization";
import { ImagePointer } from "./ImagePointer";
import { Region } from "./ImagePointer/Region.interface";
import { RegionForm } from "./RegionForm";



const Refresh = styled.button`
   background: none;
    border: 2px solid#18A0FB;
    border-radius: 6px;
    padding: 0;
    padding: 4px 5px 1px 4px;

    align-content: center;
    & :hover {
        cursor: pointer;
    };
`

type Props = Readonly<{
    camera: number;
    onChange?: (value: { left: number, top: number, right: number, bottom: number }) => void;
    initialValue?: {
        left: number;
        top: number;
        right: number;
        bottom: number;
    } | null;
}>;

export const AutoExposureRegion = (props: Props) => {
    const { onChange, camera, initialValue } = props;
    const { isLoading: loadinIndividualConfig, data: individualConfig } = useIndividualCamera({ camera });
    const { formatMessage } = useIntl();
    const [openImageRegionEditor, setOpenImageRegionEditor] = useState<boolean>(false)
    const [ae_region] = useState(merge({ top: 0, bottom: 100, left: 0, right: 100 }, initialValue))
    const { data: commonSettings, isLoading: gettingCommonSettings } = useCameraCommonSettings()

    const { font } = useCurrentFont()

    const Header = styled.span`
        color: var(--C_Dark_Text, #334D5E);
        margin-bottom:10px;
        /* c_h4 (18) */
        font-family: ${font};
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.27px;
        margin-bottom:10px;`


    const [regionToSave, setRegionToSave] = useState<Region>()
    const [editorRegion, setEditorRegion] = useState<Region>()
    const [formRegion, setFormRegion] = useState<Region>()

    useEffect(() => {
        if (!ae_region) {
            return;
        }
        setEditorRegion(ae_region)
        setFormRegion(ae_region)
    }, [ae_region, individualConfig])

    useEffect(() => {
        if (editorRegion)
            onChange?.(editorRegion);
    }, [editorRegion, onChange])


    useEffect(() => {
        if (regionToSave)
            onChange?.(regionToSave);
    }, [regionToSave, onChange])

    useEffect(() => {
        setRegionToSave(editorRegion)
    }, [editorRegion,])
    useEffect(() => {
        setRegionToSave(formRegion)
    }, [formRegion,])

    const { width, height } = useMemo(() => {
        return commonSettings?.configState.config.camera ?? { width: 100, height: 100 }
    }, [commonSettings?.configState.config.camera])

    const initialRegion = useMemo(() => merge({ top: 0, bottom: height, left: 0, right: width }), [height, width])


    return <>
                    <LoadingComponent ugly pretendChildrenInitializing isLoading={loadinIndividualConfig}>

                        <Header>{formatMessage({ id: LOCALIZATION.auto_exposure_region_header_hint }).capitalizeFirstLetter()}</Header>
                        <Grid container gap={4}>
                            {formRegion && <RegionForm value={formRegion} onChange={setEditorRegion}></RegionForm>}
                <Grid xs item container gap={1} display={"flex"} alignItems={"center"} >
                    <Grid xs={false} display={"flex"} alignItems={"center"} >
                        <Refresh onClick={() => setFormRegion(initialRegion)}>
                            <RefreshIcon2 />
                        </Refresh>

                    </Grid>
                    <Grid xs display={"flex"} alignItems={"center"}>

                                <MainButton
                                    onClicked={() => setOpenImageRegionEditor(true)}
                                    title={formatMessage({ id: LOCALIZATION.adjust_AE_region }).capitalizeFirstLetter()}
                                />
                    </Grid>

                            </Grid>
                        </Grid>
            {openImageRegionEditor && ae_region && editorRegion && <ImagePointer region={editorRegion} camera={camera} onClose={() => setOpenImageRegionEditor(false)} handleSubmit={setFormRegion} />}
        </LoadingComponent>


    </>
}