// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { CSSProperties, memo, useState } from "react";
import { applyToPoint, identity, inverse, Matrix } from "transformation-matrix";
import { useDiffLogger } from "../../../hooks/useDiffLogger";
import "./PanoramaTool.css";
import { Point } from "./Pin";
import { PreviewPanel } from "./PreviewPanel";
import { SecondaryToolbar } from "./SecondaryToolbar";
import { useBbox } from "./useBbox";
import { PanoramaStateProvider } from "./usePanormaActions";

type Props = {
  leftPanelId: string,
  rightPanelId: string,
  zoneId: string,
  leftImageSource: string;
  rightImageSource: string;
  points: [Array<Point>, Array<Point>];
  style: CSSProperties;
  setPoints(points: [Array<Point>, Array<Point>]): void;
  offset: number;
  setOffset: (v: number) => void;
  onOK: () => void;
  onCancel: () => void;
  onRefresh: () => void;
};

export const PanoramaTool = memo((props: Props) => {
  const {
    style,
    points,
    leftImageSource,
    rightImageSource,
    offset,
    setOffset,
    setPoints,
    onCancel,
    onOK,
    onRefresh,
    leftPanelId,
    rightPanelId,
    zoneId,
  } = props;
  useDiffLogger(props);
  const [leftMatrix, setLeftMatrix] = useState(identity());
  const [rightMatrix, setRightMatrix] = useState(identity());
  const [leftBBox, refLeftCanvas] = useBbox();
  const [rightBBox, refRightCanvas] = useBbox();
  const [selectedPinIndex, setSelectedPinIndex] = useState<number>(-1);
  const calcPoint = (bbox: DOMRect, matrix: Matrix) => {
    const inv = inverse(matrix);
    const point = applyToPoint(inv, [bbox.width / 2, bbox.height / 2]);
    return point;
  };
  const addPoint = () => {
    if (leftBBox && rightBBox) {
      const leftPoint = calcPoint(leftBBox, leftMatrix);
      const rightPoint = calcPoint(rightBBox, rightMatrix);
      setPoints([
        [...points[0], leftPoint],
        [...points[1], rightPoint],
      ]);
      setSelectedPinIndex(points[0].length);
    }
  };
  return (
    <PanoramaStateProvider>
    <div className="panorama-tool-container" style={{ ...style }}>
      {/* <PrimaryToolbar onCancel={onCancel} onOK={onOK} zoneId={zoneId}/> */}
      <SecondaryToolbar
        points={points}
        offset={offset}
        setSelectedPinIndex={setSelectedPinIndex}
        selectedPinIndex={selectedPinIndex}
        onRefresh={onRefresh}
        addPoint={addPoint}
        setOffset={setOffset}
        setPoints={setPoints}
      />
      <PreviewPanel
        position="left"
        points={points}
        selectedPinIndex={selectedPinIndex}
        setSelectedPinIndex={setSelectedPinIndex}
        setPoints={setPoints}
        imageSource={leftImageSource}
        matrix={leftMatrix}
        setMatrix={setLeftMatrix}
        refCanvas={refLeftCanvas}
        panelId={leftPanelId}
        bboxCanvas={leftBBox}
      />
      <PreviewPanel
        imageSource={rightImageSource}
        points={points}
        setPoints={setPoints}
        position="right"
        setSelectedPinIndex={setSelectedPinIndex}
        selectedPinIndex={selectedPinIndex}
        matrix={rightMatrix}
        setMatrix={setRightMatrix}
        refCanvas={refRightCanvas}
        panelId={rightPanelId}
        bboxCanvas={rightBBox}
      />
      </div>
    </PanoramaStateProvider>
  );
});
