import styled from "@emotion/styled";
import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useState } from "react";
import { useIntl } from "react-intl";
import Card from "../../../../../Components/_Layout/Card";
import Popup from "../../../../../Components/_Layout/Popup";
import LOCALIZATION from "../../../../../Localization";
import { ActiveCoefficentsForm } from "./ActiveCoefficentsForm";
import { CalculateForm } from "./CalculateForm";
import { PerspectiveStitchingByActiveCoefficents } from "./PerspectiveStitchingByActiveCoefficents";

const Header = styled.span`
color: var(--C_Dark_Text, #334D5E);
margin-bottom:10px;
/* c_h4 (18) */
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.27px;
margin-bottom:10px;`




const Refresh = styled.button`
   background: none;
    border: 2px solid#18A0FB;
    border-radius: 6px;
    padding: 0;
    padding: 4px 5px 1px 4px;

    align-content: center;
    & :hover {
        cursor: pointer;
    };
`


const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))

interface Props {
    onClose?: () => void,
    onApply?: () => void,
    uuid: string,

}

const Input = styled.input`
    color: var(--AdBlack, #000);
    /* c_body_P */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    border: 1px solid var(--AdGray1, #B5BBC5);
    background: #FFF;
    height: 38px;
    width: 100%;
    padding-left: 12px;
`
export const PerspectiveForm = ({ onClose, onApply, uuid }: Props) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();

    const [name, setName] = useState<string>();

    return <>
        <Popup
            closeTitle={`${formatMessage({ id: LOCALIZATION.close_btn })}`}
            isFullScreen
            headerTitle={`${formatMessage({ id: LOCALIZATION.panorama_image_stitching_cylindrical })}`}
            // isApplyDisabled={loadingList}
            onApply={onApply}
            onClose={onClose}
            classes={classes}
            applyTitle={`${formatMessage({ id: LOCALIZATION.save })}`}

        >
            <Grid container >
                <Grid marginBottom={3} item xs={12}>
                    <Input value={name} onChange={(e) => setName(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <Card contentSx={{
                        padding: '20px !important',
                        paddingBottom: '30px !important',
                    }}>
                        <Header>{formatMessage({ id: LOCALIZATION.calculate_perspective_projection_coefficients_automatically }).capitalizeFirstLetter()}</Header>

                        <CalculateForm uuid={uuid} />
                    </Card>

                </Grid>
                <Grid item xs={12}>
                    <Card contentSx={{
                        padding: '20px !important',
                        paddingBottom: '30px !important',
                    }}>
                        <Header>{formatMessage({ id: LOCALIZATION.active_coeff }).capitalizeFirstLetter()}</Header>

                        <ActiveCoefficentsForm uuid={uuid} />
                    </Card>

                </Grid>

                <Grid item xs={12}>
                    <Card contentSx={{
                        padding: '20px !important',
                        paddingBottom: '30px !important',
                    }}>
                        <Header>{formatMessage({ id: LOCALIZATION.panorama_stitched_by_active_coefficients }).capitalizeFirstLetter()}</Header>

                        <PerspectiveStitchingByActiveCoefficents />
                    </Card>

                </Grid>

            </Grid>

        </Popup>
    </>
}