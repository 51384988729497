// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import * as React from "react";
import {
  UserRole,
  userRoleAccessLevel,
} from "../../Data/AccountUsers/UserWithoutID";
import { SvgInHtml } from "../../Icons/Icons";
import { StatusIcon } from "./MenuIcons";

export enum MenuID {
  Configuration = "Configuration",
  Analytics = "Analytics",
  Administration = "Administration",

  Cameras = "Cameras",
  AnalyticsTeamsList = "AnalyticsTeamsList",
  AnalyticsVideoArchive = "AnalyticsVideoArchive",
  AnalyticsVideoArchiveDetails = "AnalyticsVideoArchiveDetails",
  AnalyticsOverall = "AnalyticsOverall",
  AnalyticsGamesArchive = "AnalyticsGamesArchive",
  AnalyticsGameReport = "AnalyticsGameReport",
  AnalyticsGameReportDetails = "AnalyticsGameReportDetails",
  AnalyticsGameReportTactical = "AnalyticsGameReportTactical",
  AnalyticsGameReportPhysical = "AnalyticsGameReportPhysical",
  Status = "Status",
  Events = "Events",
  Panorama = "Panorama",
  VideoArchive = "VideoArchive",
  Hardware = "Hardware",
  CameraAPI = "CameraAPI",
  Storage = "Storage",
  Streaming = "Streaming",
  AdministrationUsers = "AdministrationUsers",

  Logout = "Logout",

  CameraSettings = "CameraSettings",
  Software = "Software",
  Attendance = "Attendance",
}

export const MenuIDArr: string[] = Object.values(MenuID);

export enum MenuElType {
  Page = "Page",
  Special = "Special",
  Section = "Section",
}

export type MenuElEnv = {
  userHaveActiveCamera?: boolean;
  userHaveCamerasAccess?: boolean;

  userHaveAvailableTeamRefList?: boolean;
  userHaveAnalyticsAccess?: boolean;

  cameraIsProxy?: boolean; // true if camera is not a CC but Proxy

  curUserRole?: UserRole;
};

export type MenuEl = {
  type?: MenuElType;
  id: MenuID;
  title?: string;
  icon?: SvgInHtml;
  Component?: React.FC;
  minUserRole?: UserRole;
  items?: Array<MenuEl>;

  isDynamicPathId?: boolean; // if true then then create path user /parent/:AnalyticsTeamsList to allow get some id. AnalyticsTeamsList is defined value from MenuID enum.
  isUseActiveCamera?: boolean;
  isUseCameraAccess?: boolean;
  isUseTeamRefList?: boolean;
  isUseAnalytics?: boolean;
  isUseOnlyAnalytics?: boolean; // override for isUseActiveCamera=false; isUseCameraAccess=false; isUseTeamRefList=false; isUseAnalytics=true;
  isHiddenForProxy?: boolean;

  dynamicPathIdCache?: string; // cached dynamic id. exist only after use getMenuArrayFromUrlPath() and used by getMenuLinkFromMenuArray() to create correct link
};

export class MenuElement implements MenuEl {
  type = MenuElType.Page;
  id: MenuID;
  title: string;
  icon: SvgInHtml;
  Component?: React.FC;
  minUserRole?: UserRole;
  items: Array<MenuElement> = [];

  dynamicPathIdCache?: string = undefined;

  isDynamicPathId?: boolean = false; // if true then then create path user /parent/:AnalyticsTeamsList to allow get some id. AnalyticsTeamsList is defined value from MenuID enum.
  isHiddenForProxy?: boolean = false;

  isUseActiveCamera: boolean = true;
  isUseCameraAccess: boolean = true;
  isUseTeamRefList: boolean = false;
  isUseAnalytics: boolean = false;

  constructor(el: MenuEl) {
    this.type = el.type ?? MenuElType.Page;
    this.id = el.id;
    this.title = el.title || el.id;
    this.Component = el.Component;
    this.icon = el.icon || StatusIcon;
    this.minUserRole = el.minUserRole;
    this.isDynamicPathId = el.isDynamicPathId;
    this.isHiddenForProxy = el.isHiddenForProxy;

    if (el.isUseOnlyAnalytics) {
      this.isUseActiveCamera = false;
      this.isUseCameraAccess = false;
      this.isUseTeamRefList = false;
      this.isUseAnalytics = true;
    } else {
      this.isUseActiveCamera =
        el.isUseActiveCamera ?? this.type !== MenuElType.Section;
      this.isUseCameraAccess =
        el.isUseCameraAccess ?? this.type !== MenuElType.Section;
      this.isUseTeamRefList = el.isUseTeamRefList ?? false;
      this.isUseAnalytics = el.isUseAnalytics ?? false;
    }
  }

  static createFromMenuEl(el: MenuEl, env: MenuElEnv): MenuElement | undefined {
    let element = new MenuElement(el);

    if (element.isUseActiveCamera && !env?.userHaveActiveCamera) {
      return undefined;
    }
    if (element.isUseCameraAccess && !env?.userHaveCamerasAccess) {
      return undefined;
    }

    if (element.isUseTeamRefList && !env?.userHaveAvailableTeamRefList) {
      return undefined;
    }
    if (element.isUseAnalytics && !env?.userHaveAnalyticsAccess) {
      return undefined;
    }
    if (element.isHiddenForProxy && env?.cameraIsProxy) {
      return undefined;
    }

    if (element.minUserRole) {
      const elAccess = userRoleAccessLevel(element.minUserRole) || 1000;
      const userAccess = userRoleAccessLevel(env?.curUserRole) || 200;
      if (userAccess > elAccess) {
        return undefined;
      }
    }

    if (!!el.items?.length) {
      for (var i = 0; i < el.items.length; i++) {
        const itemEl = el.items[i];
        const itemElement = MenuElement.createFromMenuEl(itemEl, env);
        if (itemElement) {
          element.items.push(itemElement);
        }
      }
    }
    return element.isEmptyElemnt() ? undefined : element;
  }

  isMenuSection(): boolean {
    return this.type === MenuElType.Section;
  }

  isEmptyElemnt(): boolean {
    if (this.isMenuItem() && !this.Component)
      // empty page
      return true;
    // empty section
    if (!this.isMenuSection()) return false;
    if (this.items && this.items.length > 0) return false;
    return true;
  }

  isId(menuId: MenuID): boolean {
    return this.id === menuId;
  }

  isMenuItem(): boolean {
    return this.type === MenuElType.Page;
  }

  isMenuSpecial(): boolean {
    return this.type === MenuElType.Special;
  }

  isMenuMainHeader(): boolean {
    return this.isMenuItem() && this.id === MenuID.Cameras;
  }

  isMenuLogout(): boolean {
    return this.isMenuSpecial() && this.id === MenuID.Logout;
  }

  static findMenuWithId(
    menu: MenuElement[],
    menuId: MenuID
  ): MenuElement | undefined {
    for (var m of menu) {
      if (m.isId(menuId)) return m;
      if (m.isMenuSection() && m.items && m.items.length > 0) {
        const foundM = MenuElement.findMenuWithId(m.items, menuId);
        if (foundM) return foundM;
      }
    }
    return undefined;
  }
}

export type { SvgInHtml };
