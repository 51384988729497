import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { useIntl } from "react-intl"
import { CameraIntervalImage } from "../../../../../Components/CameraIntervalImage"
import LoadingComponent from "../../../../../Components/Loader"
import MainButton, { ButtonType } from "../../../../../Components/_BaseUI/MainButton"
import { StitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/StitchinConfig.interface"
import { useStitchingCaltulcationAble } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useStichingCaltulcationAble"
import { useZonePointCounts } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useZonePointCounts"
import { useCameraCommonSettings } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"
import { useCameraShot } from "../../../../../hooks/useCameraShot"
import { BreakpointEnum, useWidth } from "../../../../../hooks/useWidth"
import { AdjustButtonIcon, RefreshIcon2 } from "../../../../../Icons/Icons"
import LOCALIZATION from "../../../../../Localization/index"
import { ImageAdjust } from "../../ImageAdjust/ImageAdjust"
import { useCameraImageContext } from "../CameraImageProvider"
import { CalculatedCoefficents } from "./CalculatedCoefficents"
import { PanoramaStitching } from "./PanoramaStitching"
// import { SensitiveStitching } from "../../../SensitiveStiching/SensitiveStiching"
import { SensitiveStitching } from "../RawForm/SensitiveStiching"
import { useSaveRawCoefficent } from "../RawForm/useSaveCylinderCoefficent"

const MainContainer = styled(Grid)`
        width: 100%;
                height: 100%;

`

const ImagesContainer = styled(Grid)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
     padding-top: 120px;
    padding-bottom: 20px;
    flex-wrap: nowrap;
`

const ZoneGrid = styled(Grid)`
    position: relative;
    width: 100%;
    display: flex;
    width: min-content;
`

const ZoneVertical1 = ((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const Component = styled.div`
    position: absolute;
    border-right: 3px solid black;
    transform: translate(50%,0%);
    height: calc(300% );
    top: 0;
    border-color:#A0A0A0;
    z-index: -1;
    right: 50%;
    `;
    return <Component {...props} />
});

const ZoneVertical2 = ((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const Component = styled.div`
    position: absolute;
    border-right: 3px solid black;
    transform: translate(50%,0%);
    height: calc(100% );
    right: 0;
    top: 0;
    border-color:#A0A0A0;
    z-index: 1;
    `;
    return <Component {...props} />
});

const ZoneButton = styled.div`
    right: 0;

    position: absolute;
    width:auto;
    transform: translate(50%,-200%);
    z-index: 2;
`

const ZoneSettingButton = styled(MainButton)`
    width:auto;
    min-width: fit-content;
    margin-left: auto;
    transform: translate(50%,0%);
    bottom: 0;
    z-index: 2;
`

const AdjustingColor = ((props: Parameters<typeof MainButton>[0]) => {
    const size = useWidth();
    const Component = styled(MainButton)`
    width: auto;
    min-width: fit-content;
    padding-left: ${BreakpointEnum.sm >= size ? '20px' : '40px'};
    padding-right:${BreakpointEnum.sm >= size ? '20px' : '40px'};`
    return <Component {...props} />
})

const AdjustingColorRefresh = styled(MainButton)`
    width: auto;
    min-width: fit-content;
`

/**
 * Converts Blob to a Base64 string.
 * @param blob - Blob object to be converted.
 * @returns A promise that resolves to a Base64 encoded string.
 */
const blobToBase64 = async (blob: Blob): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const CalculateForm = ({ uuid }: { uuid: string }) => {
    const { data: commonSettings } = useCameraCommonSettings()
    const [panoramaModal, setPanoramaModal] = useState<boolean>(false)
    const [panoramaSensitiveModal, setSensitivePanoramaModal] = useState<boolean>(false)
    const { formatMessage } = useIntl()

    const cameras = useMemo(() => (new Array(commonSettings?.configState.config.camera["num-cameras"])).fill(0).map((_, i) => i), [commonSettings?.configState.config.camera["num-cameras"]])


    const [camerasToStitch, setCamerasToStitch] = useState<[number, number]>([0, 1]);

    const { isLoading: countinZonePoints, data: zonePointsInfo } = useZonePointCounts({ uuid });

    const isCalcAble = useStitchingCaltulcationAble({ uuid })

    const [isCalculationModal, setIsCalculationModal] = useState<boolean>(false);
    const runCalculationsCallback = useCallback(() => {
        setIsCalculationModal(true);
        return;
    }, [])
    const [adjustImageModal, setAdjustImageModal] = useState<number | null>(null)

    const { patch } = useSaveRawCoefficent();

    const useApplyCalcutationCallback = useCallback((value: Partial<StitchingConfig['config'] & any>) => {
        patch({ config: value })
        setIsCalculationModal(false)
    }, [patch])


    const { getShot, isLoading: gettingCameraShot } = useCameraShot({})

    const { setImage, images } = useCameraImageContext()
    const refreshImage = useCallback(async (camera: number) => {
        const src = await getShot(camera)
        setImage(camera, src)
    }, [getShot, setImage])



    return <>
        <MainContainer className={css`
            `} container>
            <LoadingComponent className={css`width: 100%;`} ugly isLoading={countinZonePoints || gettingCameraShot}>

                <ImagesContainer item container>
                {cameras.map((v) => <>
                    <ZoneGrid className={css`width: inherit;`} item key={v}>
                        <Grid className={css`width: 100%;`} container>
                            <Grid className={css`width: 100%;`} marginBottom={2} xs={12}>
                                <div className={css`
                                aspect-ratio: 16/9;
                                width: 100%;
                            `} >
                                    <CameraIntervalImage overrideSrc={images?.find?.(c => c.camera === v)?.src} className={css`width: 100%;`} height={320} />
                                </div>
                            </Grid>
                            <Grid className={css`
                            align-items: center;
                            justify-content: center;
                        `} container gap={1} xs={12}>
                                <Grid className={css`
                               margin-left: ${v !== cameras.length - 1 ? "auto" : "initial"};
                            `} item>
                                    <AdjustingColor buttonType={ButtonType.Outline} onClicked={() => setAdjustImageModal(v)} title={`${formatMessage({ id: LOCALIZATION.adjust_colors })}`} />
                                    {adjustImageModal === v && createPortal(<ImageAdjust handleSubmit={() => { setAdjustImageModal(null); }} camera={v} onClose={() => { setAdjustImageModal(null); }} />, document.body)}

                                </Grid>
                                <Grid item>

                                    <AdjustingColorRefresh onClicked={() => refreshImage(v)} buttonType={ButtonType.Outline} title={<RefreshIcon2 />} />
                                </Grid>
                                {v !== cameras.length - 1 && <ZoneSettingButton onClicked={() => {
                                    setCamerasToStitch([v, v + 1])
                                    setSensitivePanoramaModal(true);
                                    // alert(`${formatMessage({ id: LOCALIZATION.under_development })}`)
                                }} buttonType={ButtonType.Outline} title={<AdjustButtonIcon stroke="rgb(24, 160, 251)" />} />}

                            </Grid>
                        </Grid>
                        {v !== cameras.length - 1 && zonePointsInfo && (<><ZoneButton><MainButton onClicked={() => {
                            setCamerasToStitch([v, v + 1])
                            setPanoramaModal(true);
                        }} buttonType={ButtonType.Outline} title={<>

                            {`${zonePointsInfo[v] >= 10 ? '✅' : '❗'}  ${formatMessage({ id: LOCALIZATION.zone }).capitalizeFirstLetter()} #${v + 1}`}
                        </>} />
                            <ZoneVertical1 />
                        </ZoneButton>
                            <ZoneVertical2 /></>)}
                    </ZoneGrid>

                </>)}


            </ImagesContainer>
            <Grid item className={css`
                display: flex;
                    align-items: center;
                            justify-content: center;
                `} xs={12}>
                    <MainButton onClicked={runCalculationsCallback} isDisabled={!isCalcAble} className={css`
                        width: auto;
                    `} title={`${formatMessage({ id: LOCALIZATION.calculate_coefficients })}`} />
                </Grid>
            </LoadingComponent>

        </MainContainer>
        {
            panoramaModal && <PanoramaStitching uuid={uuid} camerasToStitch={camerasToStitch} onClose={() => setPanoramaModal(false)} onApply={() => setPanoramaModal(false)} />
        }

        {
            panoramaSensitiveModal && <SensitiveStitching onClose={() => setSensitivePanoramaModal(false)} onApply={() => setSensitivePanoramaModal(false)} />
        }
        {
            isCalculationModal && <CalculatedCoefficents onClose={() => setIsCalculationModal(false)} onApply={useApplyCalcutationCallback} uuid={uuid} />
        }

    </>
}
