import * as PIXI from "pixi.js-legacy";
import { CanvasHTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import LoadingComponent from "../../../../../../Components/Loader/index";
import { useFirstDefinedValue } from "../../../../../../hooks/useFirstDefined";
import { PanningContainer } from "../../../ImageAdjust/AutoAdjustmentForm/ImagePointer/PanningContainer";
import { PanelAction, usePanelActions } from "./usePanelActions";


export const withStitchingEditor = <P extends CanvasHTMLAttributes<HTMLCanvasElement>>(
  Component: React.ComponentType<P>,

) => {
  return (props: P & {
    /**
     * it's tooks only once
     */
    img: string,
    config?: Partial<PIXI.IApplicationOptions>,
  }) => {
    const { img, config, } = props;
    const ref = useRef<HTMLCanvasElement>(null);
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    useEffect(() => {
      setCanvas(ref.current);
    }, []);
    const [isLoading, setIsloading] = useState<boolean>(false)
    useEffect(() => {
      const parent = canvas?.parentElement;
      if (!canvas || !parent) {
        return;
      }
      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    }, [canvas])


    const { lastAction } = usePanelActions();

    const app = useMemo(() => {
      if (!canvas) {
        return;
      }
      const app = new PIXI.Application({
        width: Number(canvas.width),
        height: Number(canvas.height),
        antialias: true,
        resolution: 1,
        view: canvas,
        forceCanvas: true,
        ...config,
      });


      return app
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas])

    const panningContainer = useMemo(() => {
      if (!app || !canvas) {
        return;
      }
      const panning = new PanningContainer()
      app.stage.addChild(panning)
      panning.width = canvas.width;
      panning.height = canvas.height;
      return panning
    }, [app])



    const firstImg2 = useFirstDefinedValue(img);

    // initialize all objects
    const { sprite2, } = useMemo(() => {
      if (!panningContainer || !firstImg2) {
        return {}
      }
      const sprite2 = PIXI.Sprite.from(firstImg2);
      panningContainer.addChild(sprite2);


      return { sprite2, }
    }, [panningContainer, firstImg2,])
    // on src images update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (sprite2) {
        setIsloading(true)
        PIXI.Texture.fromURL(img).then((texture) => sprite2.texture = texture).finally(() => {
          setIsloading(false)

        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [img])

    useEffect(() => {
      return () => app?.destroy()
    }, [app])

    useEffect(() => {
      if (!sprite2 || !panningContainer || !canvas) {
        return
      }
      if (lastAction?.action === PanelAction.fit) {
        panningContainer.position.set(0, 0)
        const w = panningContainer.width;
        const nh = (canvas.width / w) * panningContainer.height;
        panningContainer.width = canvas.width;
        panningContainer.height = nh;
      }
      if (lastAction?.action === PanelAction.fullSize) {

        panningContainer.position.set(0, 0)
        const w = panningContainer.height;
        const nh = (canvas.height / w) * panningContainer.width;
        panningContainer.height = canvas.height;
        panningContainer.width = nh;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastAction])


    return <LoadingComponent ugly isLoading={isLoading}>
      <Component ref={ref}  {...props as P}></Component>
    </LoadingComponent>
  }

};
