import { SportType } from "../../../Data/EPG/EpgSchedule/EventWithoutID";
import {
  ActionType,
  ConditionOperator,
  EndAction,
  FieldName,
  LogicalOperator,
  Rule,
} from "./types";

export const defaultRule: Rule = {
  id: 1,
  ifBlock: {
    conditions: [
      {
        logicalOperator: LogicalOperator.IF,
        field: FieldName.Title,
        operator: ConditionOperator.Contains,
        value: "Football",
      },
    ],
  },
  actions: [
    {
      type: ActionType.SetSportType,
      value: SportType.Basketball,
    },
  ],
  successEndAction: EndAction.End,
  failureEndAction: EndAction.Continue,
};
