import styled from "@emotion/styled";
import { Fragment, ReactNode, useCallback, useContext, useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { FontName, useCurrentFont } from "../../../Configuration/Styles/Fonts";
import { useCamerasStatistic } from "../../../Data/Camera/AvaibleCamerasStatistic/hook";
import { RemoteCameraStateValue } from "../../../Data/Camera/RemoteCameraState/index";
import LOCALIZATION from "../../../Localization";
import { RemoteCamerasFilter } from "../RemoteCamerasFilter.context";

type Props = Readonly<{} & WrappedComponentProps>;

const StatusTextContainer = styled.span`
  white-space: nowrap;
  * {
    white-space: nowrap;
  }
`;

const BoldText = styled.strong<{ font: FontName }>`
  color: #334d5e;
  font-family: ${({ font }) => font};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Critical = styled.a<{ font: FontName }>`
  color: #eb5757;
  font-family: ${({ font }) => font};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
`;

const Warning = styled.a<{ font: FontName }>`
  color: #f2994a;
  font-family: ${({ font }) => font};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
`;

const CameraListStatusMessage: React.FC<Props> = (props: Props) => {
  const camerasStatisticQuery = useCamerasStatistic();
  const { font } = useCurrentFont();
  const camerasOverroundInfo = camerasStatisticQuery.data;
  const { setState, } = useContext(RemoteCamerasFilter)

  const handleClick = useCallback((status: RemoteCameraStateValue) => {
    setState?.(status, false)
  }, [setState]);

  const statusMessage = useMemo(() => {
    const message: ReactNode[] = [];
    const totalText = `${props.intl.formatMessage({ id: LOCALIZATION.total })}: ${camerasOverroundInfo?.totalCameras}`;
    message.push(totalText);

    const OffText = camerasOverroundInfo?.offlineCameras ? (
      <Critical font={font} onClick={() => handleClick(RemoteCameraStateValue.Offline)}>
        {`${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_off })}: ${camerasOverroundInfo?.offlineCameras}`}
      </Critical>
    ) : undefined;

    const ErrorText = camerasOverroundInfo?.errorCameras ? (
      <Warning font={font} onClick={() => handleClick(RemoteCameraStateValue.Error)}>
        {`${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_error })}: ${camerasOverroundInfo?.errorCameras}`}
      </Warning>
    ) : undefined;

    const WarningText = camerasOverroundInfo?.onlineWarningCameras ? (
      <Warning font={font} onClick={() => handleClick(RemoteCameraStateValue.OnlineWarning)}>
        {`${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_warning })}: ${camerasOverroundInfo?.onlineWarningCameras}`}
      </Warning>
    ) : undefined;

    const CriticalText = camerasOverroundInfo?.onlineCriticalCameras ? (
      <Critical font={font} onClick={() => handleClick(RemoteCameraStateValue.OnlineCritical)}>
        {`${props.intl.formatMessage({ id: LOCALIZATION.cameras_list_statuses_critical })}: ${camerasOverroundInfo?.onlineCriticalCameras}`}
      </Critical>
    ) : undefined;

    const arrOfAditinalMesgs = [OffText, WarningText, ErrorText, CriticalText].filter(Boolean);
    if (arrOfAditinalMesgs.length) {
      message.push(" ( ");
      message.push(
        arrOfAditinalMesgs.map((element, index, array) => (
          <Fragment key={index}>
            {element}
            {index !== array.length - 1 && ", "}
          </Fragment>
        ))
      );
      message.push(" )");
    }
    return message;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    camerasOverroundInfo?.errorCameras,
    camerasOverroundInfo?.offlineCameras,
    camerasOverroundInfo?.onlineCriticalCameras,
    camerasOverroundInfo?.onlineWarningCameras,
    camerasOverroundInfo?.totalCameras,
    props.intl,
    handleClick,
  ]);

  return (
    <StatusTextContainer>
      <BoldText font={font}>{statusMessage}</BoldText>
    </StatusTextContainer>
  );
};

export default injectIntl(CameraListStatusMessage);
