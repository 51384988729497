// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { memo, useContext, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ViewType } from '../../Components/_BaseUI/ViewSwitcher/ViewType.enum';
import Camera from '../../Data/Camera/ActiveCamera/Camera';
import { CameraListOrderByVariant } from '../../Data/Camera/ActiveCamera/fetch';
import { useCamerasList } from '../../Data/Camera/ActiveCamera/hook';
import { CameraHardwareListOrderByVariant, useCamerasHardwareStatusesList } from '../../Data/Camera/RemoteCameraState/hook';
import { DeviceType, useDevice } from '../../hooks/useDevice';
import { BreakpointEnum, useWidth } from '../../hooks/useWidth';
import CameraCardBlock from './CameraCardBlock';
import CamerasPageHeaderV2 from './CamerasPageHeaderV2';
import { PaginationContext } from './Pagination.context';
import { RemoteCamerasFilter } from './RemoteCamerasFilter.context';
import { TableViewContext } from './TableView.context';


type Props = Readonly<{
  isServer: boolean;
  activeCamera: Camera | undefined;
  // TODO
  onSetActiveCamera: (camera: Camera) => void;
} & WrappedComponentProps>


const ListOfCameras: React.FC<Props> = (props: Props) => {
  const { offset, limit, orderByDate } = useContext(PaginationContext);
  const currentPage = useMemo(() => {
    return offset / limit
  }, [offset, limit]);
  const { state: stateFilter } = useContext(RemoteCamerasFilter)

  /**
   * Sorting by newest/oldest cams
   */
  const sortCameraList = useMemo(() => {
    if (!orderByDate) {
      return;
    }
    return ({
      order: orderByDate,
      by: CameraListOrderByVariant.whenCreated
    })
  }, [
    orderByDate
  ])


  /**
   * sorting by newest/oldest hardware info about cams
   */
  const sortCameraHardwareList = useMemo(() => {
    if (!orderByDate) {
      return;
    }
    return ({
      order: orderByDate,
      by: CameraHardwareListOrderByVariant.whenCreated
    })
  }, [
    orderByDate
  ])

  const camerasListQuery = useCamerasList({
    offset,
    limit,
    stateFilter,
    sort: sortCameraList,
  })
  const camerasArr = camerasListQuery.data;
  const camerasStates = useCamerasHardwareStatusesList({
    enableToFetch: true,
    page: currentPage,
    limit: limit,
    stateFilter,
    sort: sortCameraHardwareList,
    debouceTime: 500,
  })
  const { currentView, } = useContext(TableViewContext);

  useEffect(() => {
    return (
      () => {
        if (!camerasArr || camerasArr.length === 0) { // clear cache if no cameras in list. possible load error
          camerasListQuery.resetCache();
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * With of column for camera card
   */
  const sm = useMemo(() => {
    switch (currentView) {
      case ViewType.LIST:
        return false;
      case ViewType.GRID:
        return 6
    }
  }, [currentView])
  const deviceType = useDevice()

  const width = useWidth()

  /**
   * Compact mode for all cards
   */
  const areCompactCards = useMemo(() => {
    return (currentView === ViewType.GRID) || (deviceType === DeviceType.Mobile) || (width <= BreakpointEnum.sm)
  }, [currentView, deviceType, width])



  return (
    <>
      <Grid
        item
        xs={12}
      >
        <CamerasPageHeaderV2
          camerasStatesArr={(camerasStates.data?.data || [])}
        />
      </Grid>
      { (camerasArr || []).map((camera: Camera) => {
        if (camera.localCamera && props.isServer) { return null; }
        const cameraHardware = camerasStates.data?.data.find(({ cameraId }) => (cameraId === camera.cameraId)) || camera.state;

        if (!cameraHardware) { return null; }

        return (
          <Grid
            key={ camera.id }
            item
            xs={ 12 }
            sm={ sm }
          >
            <CameraCardBlock
              isServer={ props.isServer }
              compact={areCompactCards}
              cameraHardware={ cameraHardware }
              camera={ camera }
              activeCamera={ props.activeCamera }
              onSetActiveCamera={ props.onSetActiveCamera }
            />
          </Grid>
        );
      }) }
    </>
  )
};

export default injectIntl(memo(ListOfCameras));
