// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cn from 'classnames';
import { format } from 'date-fns';
import React from 'react';

import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../Configuration/Styles/Colors';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import { getLocale } from '../FieldSet/helpers';

import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { ScissorsCircleGrayBtn } from '../../../Icons/Icons';
import LOCALIZATION from '../../../Localization';
import { intl } from '../../../Localization/LocalizationProvider';

export function timeToSeconds(timeHHmmssStr: string | undefined) : number | undefined {
  if (!timeHHmmssStr?.length) {
    return undefined;
  }
  const [hours, minutes, seconds] = timeHHmmssStr.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

export function secondsToHHmmss(timeSec: number | undefined, removeLeadingZerros?: boolean) : string | undefined {
  if (timeSec === undefined) {
    return undefined;
  }
  const time = new Date(timeSec * 1000).toISOString().slice(11, 19);
  if (removeLeadingZerros) {
    return time?.replace(/^(00:0|00:|0)/, "");
  }
  return time;
}

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  label: {
    fontWeight: Sizes.regularWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.subtitle,
    color: Colors.mainTitle,
  },
  input: {
    paddingTop: '0px',
    paddingBottom: '0px',
    lineHeight: '38px',
    backgroundColor: '#fff',
  },
  singleLine: {
    height: '38px',
  },
  error: {
    border: 'solid 1px #EB5757',
    backgroundColor: '#EB575720',
  },
  errorLight: {
    border: 'solid 1px #EB5757',
  },
}));

type Props = Readonly<{
    errorTime?: string;
    helpText?: string;
    isError?: boolean;
    showScissorsCutBtn?: boolean;

    timeValue: string;
    onChangeTime: (time: string) => void;
    onScissorsCutPress?: () => void;
}>;

const AnalyticsTimeField: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  let timeout = React.useRef<NodeJS.Timeout>(null).current;

  const now = new Date();
  const { localization: { locale } } = useAppConfig();
  const dateTime = (): Date => (
   (props.timeValue?.length) ?
    new Date(`${format(now, 'yyyy-MM-dd')}T${props.timeValue}`)
    :
    now
  )
  const [errorCount, setErrorCount] = React.useState<number>(0);

  const filedWidth = (props.showScissorsCutBtn) ? '85pt' : '70pt'

  const handleClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    props.onScissorsCutPress?.();
  };

  const renderInput = (params: MuiTextFieldPropsType): React.ReactElement => (
    <TextField
      fullWidth
      { ...params }
      InputProps={ {
        className: cn(classes.input, classes.singleLine, {
          [classes.error]: props.isError || errorCount > 0,
        }),
        endAdornment: (props.showScissorsCutBtn) ? (
          <InputAdornment
          position="end"
          sx={{
            marginLeft: '-10pt',
            marginRight: '-5pt',
          }}
          // disablePointerEvents={ !showRemoveDate }
          >
            {
              <IconButton
                edge="end"
                aria-label="cut"
                component="label"
                onClick= { handleClick }
                >
                  <ScissorsCircleGrayBtn />
              </IconButton>
            }
          </InputAdornment>
        ) : undefined,
      } }
      helperText={ params.helperText || props.errorTime }
      error={ params.error || (props.errorTime !== undefined) }
    />
  );

  return (
    <LocalizationProvider
      locale={ getLocale(locale) }
      dateAdapter={ AdapterDateFns }
    >
      <Box display="flex" alignItems="center" flexDirection="column"
    sx={{
        minWidth: '70pt',
        maxWidth: filedWidth,
    }}
    >
      <Tooltip
        arrow
        open={ errorCount > 0 }
        title={ intl().formatMessage({ id: LOCALIZATION.analytics_game_edit_time_invalid }) }
        PopperProps={ {
          sx: {
            zIndex: 1300,
            '& .MuiTooltip-tooltip': {
              fontSize: '14px',
              color: '#fff',
              backgroundColor: '#EB5757',
            },
            '& .MuiTooltip-arrow:before': {
              backgroundColor: '#EB5757',
            },
          }
        } }
      >
        <Box display="flex" alignItems="center" flexDirection="row" width="100%" >
          <TimePicker
              ampm={false}
              openTo="hours"
              views={['hours', 'minutes', 'seconds']}
              inputFormat="HH:mm:ss"
              mask="__:__:__"
              value={ dateTime() }
              onChange={ (newValue) => {
                  if (newValue && !isNaN(newValue.getTime())) {
                      const newTime = format(newValue, 'HH:mm:ss');
                      props.onChangeTime(newTime);
                      if (timeout) {
                        clearTimeout(timeout);
                      }
                      setErrorCount(0);
                    }
                  else {
                    setErrorCount(errorCount + 1);

                    if (timeout) {
                      clearTimeout(timeout);
                    }
                    timeout = setTimeout(() => {
                      setErrorCount(0);
                    }, 2000);
                  }
              } }
              renderInput={ renderInput }
              />
        </Box>
      </Tooltip>

      {(props.helpText) &&
      <div
      className={ classes.label }
      >
        { props.helpText }
          </div>
      }
    </Box>
    </LocalizationProvider>
  );
};


export default AnalyticsTimeField;
