// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { ApiRequestConfigInterface } from '../Types'

interface CameraApiRequestsStorageServiceInterface {
    apiRequests: ApiRequestConfigInterface[]
}

/** @deprecated Overengeneering */
export default class CameraApiRequestsStorageService implements CameraApiRequestsStorageServiceInterface {
    private static _apiRequests: ApiRequestConfigInterface[]

    get apiRequests(): ApiRequestConfigInterface[] {
        return CameraApiRequestsStorageService._apiRequests
    }

    set apiRequests(newApiRequests: ApiRequestConfigInterface[]) {
        CameraApiRequestsStorageService._apiRequests = newApiRequests
    }

    static reset() {
        CameraApiRequestsStorageService._apiRequests = []
    }
}
