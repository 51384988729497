import { useEffect, useRef } from "react";

export const useDiffLogger = <T>(obj: T) => {
  const prevObjRef = useRef(obj);

  useEffect(() => {
    const prevObj = prevObjRef.current;

    // Compare the keys and values of the previous and current objects
    for (const key in obj) {
      if (obj[key] !== prevObj[key]) {
        console.log(
          `Key: ${key}, Previous Value: ${prevObj[key]}, Current Value: ${obj[key]}`
        );
      }
    }

    prevObjRef.current = obj;
  }, [obj]);
};
