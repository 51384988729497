import { javascript } from "@codemirror/lang-javascript";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import CodeMirror from '@uiw/react-codemirror';
import { cloneDeep, merge } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader";
import FieldSet, { FieldSetInputType } from "../../../../../Components/_BaseUI/FieldSet";
import MainButton from "../../../../../Components/_BaseUI/MainButton";
import { useTemporaryApplyStitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useTemporaryApplyStichingConfig";
import { useFirstDefinedValue } from "../../../../../hooks/useFirstDefined";
import LOCALIZATION from "../../../../../Localization";
import { useSaveRawCoefficent } from "./useSaveCylinderCoefficent";

const MainContainer = styled(Grid)`

`

const loadingt = 'Loading...'

export const ActiveCoefficentsForm = ({ uuid }: { uuid: string }) => {
    const { formatMessage } = useIntl();
    const { set, patch, state, } = useSaveRawCoefficent()
    const jsonConfig = useMemo(() => {
        try {
            const striginfied = JSON.stringify(state?.config, undefined, 2)
            return striginfied

        }
        catch {

        }
        return loadingt
    }, [state?.config])
    const setConf = useCallback((value: string) => {
        try {
            const parsed = JSON.parse(value)
            set({ ...state, config: parsed })
        } catch { }
    }, [state, set])

    const realblending = useMemo(() => state?.config?.stitching?.['blend-width'] ?? 16, [state?.config?.stitching])
    const initialBlending = useFirstDefinedValue(realblending)
    const [blending, setBlending] = useState<number>(initialBlending ?? 16)
    useEffect(() => {
        if (!initialBlending) {
            return
        }
        setBlending(initialBlending)
    }, [initialBlending])
    const setrealBlending = useCallback((value: number) => {

        patch({
            config: merge(
                cloneDeep(state?.config),
                { stitching: { 'blend-width': value } }
            )
        })
    }, [patch, state])

    const changeBlending = useCallback((v: number | null) => {
        setBlending(Math.round(Number((v ?? 0))))
    }, [])
    useEffect(() => {
        if (!initialBlending) {
            return
        }
        setBlending(initialBlending)
    }, [initialBlending])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { setrealBlending(blending) }, [blending])
    useEffect(() => { setBlending(state.config?.stitching?.["blend-width"] ?? 16) }, [state])

    const { mutateAsync: applyConfig, isLoading: applingConfig } = useTemporaryApplyStitchingConfig()

    const runTmpConfigCallback = useCallback(() => {
        applyConfig(state?.config)
    }, [applyConfig, state?.config]);

    useEffect(() => {
        return () => {
            applyConfig(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <>
        <LoadingComponent ugly isLoading={applingConfig}>

            <MainContainer xs={12} container>
                <CodeMirror
                    className={css`
            width: 100%;
          `}
                    value={jsonConfig}
                    // height="200px"
                    extensions={[javascript({ jsx: true })]}
                    onChange={(value, viewUpdate) => {
                        if (value === loadingt) {
                            return;
                        }
                        setConf(value)
                    }}

                />
                <FieldSet hideSeparator label={`${formatMessage({ id: LOCALIZATION.blending_width })} , ${formatMessage({ id: LOCALIZATION.px })}`} input={{ min: 0, max: 128, type: FieldSetInputType.Number, step: 1, value: blending, onChange: changeBlending }} inputSize={"auto"} labelSize={"auto"} />
                <MainButton className={css`
                width: auto;
                margin: auto;
                `} title={formatMessage({ id: LOCALIZATION.apply })} onClicked={runTmpConfigCallback} />
            </MainContainer>
        </LoadingComponent>

    </>
}