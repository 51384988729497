import { gql, GraphQLClient } from "graphql-request";
import { useMutation } from "react-query";
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";
import { IResponse } from "../../undistord/mutation/useKickCalibrationMode";

const mutationQl = gql`
  mutation TemporaryAdjustmentMode(
    $camera: Int!
    $state: Boolean!
    $config: IndividualCameraConfigInput
  ) {
    temporaryAdjustmentMode(camera: $camera, config: $config, state: $state)
  }
`;

export interface TemporaryImageAdjustmentProps {
  camera: number;
  state: boolean;
  config?: {
    exposure_compensation: number | null;
    ae_region: {
      left: number | null;
      top: number | null;
      right: number | null;
      bottom: number | null;
    } | null;
    awb_region: {
      left: number | null;
      top: number | null;
      right: number | null;
      bottom: number | null;
    } | null;
    color_saturation: number | null;
    color_saturation_bias: number | null;
    brightness: number | null;
    contrast: number | null;
    adjust_color: {
      r: number;
      b: number;
      g: number;
    } | null;
  };
}

interface Props {}

export const useSwitchTemporaryImageAdjustment = (props?: Props) => {
  const client = useCameraHandler() ?? new GraphQLClient("");
  const request = (props: TemporaryImageAdjustmentProps) =>
    client.request<IResponse>(mutationQl, { ...props });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const req: TemporaryImageAdjustmentProps = JSON.parse(
      JSON.stringify(props)
    );
    if (req) {
      const { adjust_color } = req.config ?? {};
      const { r, g, b } = adjust_color ?? {};
      if (req.config) {
        req.config.adjust_color = {
          r: Number(r) / 255,
          g: Number(g) / 255,
          b: Number(b) / 255,
        };
      }
    }
    const res = await mutation.mutateAsync(req);
    return res;
  };
  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
