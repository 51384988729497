import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import Color from "color"
import { useEffect, useMemo, useRef } from "react"
import LoadingComponent from "../../../../../../Components/Loader/index"
import { useCameraCommonSettings } from "../../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"
import { useCameraShot } from "../../../../../../hooks/useCameraShot"
import { BreakpointEnum, useWidth } from "../../../../../../hooks/useWidth"
import { PanelAction, usePanelActions } from "./usePanelActions"
import { withStitchingEditor } from "./withStichingEditor"
const MainContainer = styled(Grid)`
`
const Canvas = withStitchingEditor(styled.canvas())
export const ResultImage = () => {
    const { data: commonSettings } = useCameraCommonSettings()
    const camerasCount = useMemo(() => commonSettings?.configState.config.camera["num-cameras"], [commonSettings?.configState.config.camera["num-cameras"]])

    const { lastAction } = usePanelActions();

    const { base64, update } = useCameraShot({
        camera: "full_frame_panorama", interval: Infinity, width: 1200, useCached: false
    });

    useEffect(() => {
        if (lastAction?.action === PanelAction.update) {
            update();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastAction])
    const containerRef = useRef(null)
    const currentBreakPoint = useWidth()
    return <>
        <LoadingComponent isLoading={!camerasCount}>

            {camerasCount && <MainContainer xs={12} ref={containerRef} className={css`
            aspect-ratio: ${camerasCount * 16}/9;
            height: ${currentBreakPoint <= BreakpointEnum.md ? '50%' : '100%'};;
            min-height: ${currentBreakPoint <= BreakpointEnum.md ? '40vh' : '80vh'};
            background: black;
        `} container>
                {base64 && containerRef.current && <Canvas config={{ backgroundAlpha: 1, backgroundColor: new Color('black').hex(), resizeTo: containerRef.current }} img={base64} ></Canvas>}

            </MainContainer>}
        </LoadingComponent>

    </>
}