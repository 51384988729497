// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, IconButton, Theme, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import LOCALIZATION from '../../../Localization';
import MainButton from '../../_BaseUI/MainButton';


type Props = Readonly<{
    headerTitle: string;
  applyTitle?: string | ReactNode;
    isApplyDisabled?: boolean;
    onApply?: () => void;
    cancelTitle?: string
    isCancelDisabled?: boolean;
    onCancel?: () => void;
  children?: ReactNode;
    onEditTitleClick?: () => void;
} & WrappedComponentProps>;


const useStyles = makeStyles((theme: Theme) => createStyles<any, { fontFamily: FontName }>({
    toolbar: {
      paddingBottom: theme.spacing(1.6),
      paddingTop: theme.spacing(1.6),
      background: Colors.mainGrey,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    },
    header: {
      fontFamily: ({ fontFamily }) => fontFamily,
      fontSize: Sizes.caption,
      fontWeight: Sizes.boldWeight,
      color: Colors.headingMain
    },
    topButtons: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    titleEditButton: {
      color: Colors.buttonBlue,
      marginLeft: 1,
    },
  }));


const PopupHeader: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const theme = useTheme()
  const { font } = useCurrentFont()
  const classes = useStyles({ ...theme, fontFamily: font });

  return (
    <div className={ classes.toolbar }>
      <Grid container direction="row" justifyContent="space-between" spacing={ 1 } alignItems="center">
        <Grid item>
          <div className={ classes.header }>{ props.headerTitle }
            {(props.onEditTitleClick) &&
              <IconButton
                  className={ classes.titleEditButton }
                  onClick={ props.onEditTitleClick }
                  aria-label="edit"
                >
                  <DriveFileRenameOutlineIcon />
              </IconButton>
            }
          </div>
        </Grid>
        <Grid item xs>
          <Grid container direction="row" spacing={1} justifyContent="flex-end" wrap="nowrap">
            {children}
            {
                (!props.onApply) ||
                <Grid item>
                  <MainButton title={(props.applyTitle) ?? props.intl.formatMessage({ id: LOCALIZATION.apply })}
                    type="submit"
                    isDisabled={ (props.isApplyDisabled === true) }
                    onClicked={ props.onApply }/>
                </Grid>
            }
            {
                (props.isCancelDisabled === true) ||
                <Grid item>
                  <MainButton title={(props.cancelTitle) ?? props.intl.formatMessage({ id: LOCALIZATION.cancel })}
                    onClicked={ props.onCancel }/>
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
};


export default injectIntl(PopupHeader);
