export const RedLine = ({ className }: { className: string }) => <svg className={className} width="25" height="9" viewBox="0 0 25 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6657_6780)">
        <circle cx="12.5" cy="4.5" r="3" stroke="#EB5757" stroke-width="2" />
        <path d="M9.5 4.5H0M15.5 4.5H24.5" stroke="#EB5757" stroke-width="3" />
    </g>
    <defs>
        <clipPath id="clip0_6657_6780">
            <rect width="24.5" height="8" fill="white" transform="translate(0 0.5)" />
        </clipPath>
    </defs>
</svg>
