import { gql } from "graphql-request";
import { debounce } from "lodash";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useCameraHandler } from "../../../hooks/useCameraHandler";
import { AeAntibanding, AWB_MODE, globalConfigCacheKey } from "../types";

interface IResponse {
  configState: {
    config: {
      version: string;
      id: string;
      mode: string;
      custom: string;
      camera: {
        "num-cameras": number;
        width: number;
        height: number;
        "frame-rate": number;
        "main-camera": number;
        "denoise-strength": number;
        "edge-enhance-strength": number;
        "awb-mode": AWB_MODE;
        "ae-antibanding": AeAntibanding;
        angle: number;
      };
    };
  };
}

const graphql = gql`
  query ConfigStateCamera {
    configState {
      config
    }
  }
`;
interface IUseCameraCommonSettingsProps<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}
const cacheKey = "useCameraCommonSettings";

/**
 * @description It request the data only when fetchData has been fired
 * @param props
 * @returns
 */
export const useCameraCommonSettings = (
  props?: IUseCameraCommonSettingsProps<IResponse | undefined>
) => {
  const { options } = props ?? {};
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const key = [globalConfigCacheKey, cacheKey] as QueryKey;
  const request = () => client?.request<IResponse>(graphql);
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };
  const query = useQuery(key, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
  });
  const fetchData = debounce(async () => {
    return queryClient.fetchQuery(key, request); // Prefetch the query
  }, 100);

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
  };
};
