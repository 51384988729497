import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";

interface Component {
  size?: string;
  tag?: string;
  version?: string;
}

interface DeviceData {
  JP_VER?: string;
  L4T_VER?: string;
  VERSION?: string;
  device?: string;
  [key: string]: any; // Allows any other properties
}

interface StaticData {
  VERSION?: string;
  device?: string;
  other?: {
    [key: string]: DeviceData;
  };
  [key: string]: any; // Allows any other properties
}

interface VersionRow {
  device: ReactNode;
  component: string;
  componentName: string,
  version?: React.ReactNode;
  tag?: string;
  size?: string;
  actualVersion?: React.ReactNode;
}

interface ActualVersion {
  value: {
    app?: string;
    releaseTag?: string;
    version?: string;
  };
  kvId: string;
  key: string;
  title: string;
  group: string;
  flagShared: boolean;
  whenMarkedDefault: number;
  flags: string;
}

// Styled component for red text
const VersionMismatch = styled.span`
  color: red;
`;

const goodSvg = <svg width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_6634_5117)">
    <path d="M12.105 24.21C18.7369 24.21 24.2121 18.7273 24.2121 12.105C24.2121 5.47312 18.7273 0 12.0954 0C5.47523 0 0 5.47312 0 12.105C0 18.7273 5.48484 24.21 12.105 24.21Z" fill="#5DCA8A" />
    <path d="M10.7988 17.8898C10.3582 17.8898 9.99631 17.6983 9.66843 17.2734L6.79663 13.78C6.5871 13.5119 6.47952 13.2414 6.47952 12.9445C6.47952 12.3475 6.95295 11.8633 7.54779 11.8633C7.91928 11.8633 8.20029 11.9878 8.5017 12.3745L10.7604 15.2423L15.6061 7.48383C15.8604 7.08375 16.1925 6.87305 16.552 6.87305C17.1373 6.87305 17.6747 7.28367 17.6747 7.89773C17.6747 8.1682 17.5329 8.45461 17.3691 8.7239L11.8866 17.265C11.6173 17.6749 11.2373 17.8898 10.7988 17.8898Z" fill="white" />
  </g>
  <defs>
    <clipPath id="clip0_6634_5117">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>

const bad = <svg width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.7336 23.8081C11.9401 23.8081 12.2457 23.7272 12.5429 23.5749C19.2573 20.1165 21.4672 18.3008 21.4672 14.0958V5.37593C21.4672 4.01281 20.9431 3.52273 19.7942 3.04086C18.5075 2.51211 14.1964 0.979531 12.9404 0.555077C12.5565 0.432733 12.125 0.359375 11.7336 0.359375C11.3443 0.359375 10.9128 0.442343 10.5364 0.555077C9.27078 0.960313 4.96179 2.52172 3.67507 3.04086C2.53578 3.51101 2 4.01281 2 5.37593V14.0958C2 18.3008 4.31023 19.9489 10.9243 23.5749C11.2311 23.7369 11.5292 23.8081 11.7336 23.8081Z" fill="#F2994A" />
  <path d="M11.7378 13.5998C11.0504 13.5998 10.6695 13.2199 10.6473 12.5229L10.4727 6.6107C10.4504 5.89469 10.9686 5.39453 11.7261 5.39453C12.4719 5.39453 13.0114 5.90641 12.9891 6.62031L12.8145 12.5186C12.7923 13.2295 12.4039 13.5998 11.7378 13.5998ZM11.7378 17.6374C10.9524 17.6374 10.2988 17.0643 10.2988 16.3012C10.2988 15.5381 10.9428 14.9554 11.7378 14.9554C12.5328 14.9554 13.1705 15.5327 13.1705 16.3012C13.1705 17.074 12.5232 17.6374 11.7378 17.6374Z" fill="white" />
</svg>

const nameMap: Record<string, string> = {
  cameraHandler: "camera_handler",
  mainBackend: "Main Backend",
  napi: "Napi",
  nginxCC: "Nginx",
  JP_VER: "JetPack",
  OS: "OS",
  operator: "Operator",
  scoreboard: "Scoreboard",
  front: "Frontend",
};

export function useVersionTable(
  staticData?: StaticData,
  actualVersionsData?: ActualVersion[]
): VersionRow[] {
  if (!staticData || !actualVersionsData) {
    return [];
  }
  const versionRows: VersionRow[] = [];

  // Process the root device
  const rootDeviceName = staticData.device || "device0";
  versionRows.push(...getDeviceComponents(rootDeviceName, staticData));

  // Process other devices
  if (staticData.other) {
    for (const [deviceName, deviceData] of Object.entries(staticData.other)) {
      versionRows.push(...getDeviceComponents(deviceName, deviceData));
    }
  }

  // Create a map of actual versions for quick lookup
  const actualVersionMap = createActualVersionMap(actualVersionsData);

  // Fill in the actualVersion field in versionRows
  for (const row of versionRows) {
    const appName = row.component;
    const actualVersion = actualVersionMap.get(appName);
    row.actualVersion = actualVersion;

    if (row.version && actualVersion && row.version !== actualVersion) {
      // If versions don't match, wrap the version in red color
      row.version = <VersionMismatch>{row.version}</VersionMismatch>;
      row.actualVersion = <VersionMismatch>{actualVersion}</VersionMismatch>;
      row.device = <div className={css`display:flex; align-items:center; `}>{bad} {row.device}</div>

    } else {
      row.device = <div className={css`display:flex; align-items:center; `}>{goodSvg} {row.device}</div>

    }


  }

  return versionRows;
}

function getDeviceComponents(
  deviceName: string,
  deviceData: DeviceData
): VersionRow[] {
  const rows: VersionRow[] = [];

  for (const [key, value] of Object.entries(deviceData)) {
    // Skip known non-component properties
    if (
      key === "device" ||
      key === "VERSION" ||
      key === "JP_VER" ||
      key === "L4T_VER" ||
      key === "serialNumber"
    ) {
      continue;
    }

    if (value && typeof value === "object" && !Array.isArray(value)) {
      // Check if the object has any of the component properties
      const component = value as Component;
      if (component.version || component.tag || component.size) {
        const version = component.version || component.tag;
        const tag = component.tag;
        const size = component.size;

        rows.push({
          device: deviceName,
          component: key,
          componentName: nameMap?.[key] ?? key,
          version: version,
          tag: tag,
          size: size,
          actualVersion: undefined, // To be filled later
        });
      }
    }
  }

  // Handle special properties like JP_VER
  if (deviceData.JP_VER) {
    rows.push({
      device: deviceName,
      component: "JP_VER",
      componentName: nameMap["JP_VER"],
      version: deviceData.JP_VER,
      tag: deviceData.L4T_VER,
      size: undefined,
      actualVersion: undefined,
    });
  }

  // Handle special properties like JP_VER
  if (deviceData.VERSION) {
    rows.push({
      device: deviceName,
      component: "OS",
      componentName: nameMap["OS"],
      version: deviceData.VERSION,
      tag: undefined,
      size: undefined,
      actualVersion: undefined,
    });
  }

  return rows;
}

function createActualVersionMap(
  actualVersionsData: ActualVersion[]
): Map<string, string> {
  const actualVersionMap = new Map<string, string>();
  for (const actualVersion of actualVersionsData) {
    const app = actualVersion.value.app;
    const version = actualVersion.value.version;
    if (app && version) {
      actualVersionMap.set(app, version);
    }
  }
  return actualVersionMap;
}
