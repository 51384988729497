import { RequestMiddleware } from "graphql-request"
import { getAccessToken } from "../../Tools/token"

export const authMiddleware: RequestMiddleware = async (request) => {
    return {
        ...request,
        headers: {
            ...request.headers,
            'Authorization': getAccessToken() ?? '',
            'X-API-VERSION': '1',
        },
    }
}
