// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Colors } from "../../../Configuration/Styles/Colors";
import { useCurrentFont } from "../../../Configuration/Styles/Fonts";
import { Sizes } from "../../../Configuration/Styles/Sizes";
import { IconFigureRun, IconFigureSprint, IconFigureWalk, SvgInHtml } from "../../../Icons/Icons";

export type BarChartItemValue = Readonly<{
    value: number;
    ratio?: number;
    icon?: JSX.Element;
    label?: string;
    name?: string;
    parts?: BarChartItemValue[];
}>;

export type BarChartItem = Readonly<{
    label: string;
    value1: BarChartItemValue;
    value2: BarChartItemValue;
    normBase?: number;
    units?: string;
}>;


export type BarChartDataArrayItemVal = Readonly<{
    valueLabel?: string | JSX.Element;
    valueName?: string | JSX.Element;
    value: number | undefined;
    ratio: number | undefined;
}>;


export type BarChartData = {
    label: string | undefined;
    units?: string;
    val1_data0?: BarChartDataArrayItemVal,// contain main data for val1
    val1_data1?: BarChartDataArrayItemVal,// contain part 1 for val1
    val1_data2?: BarChartDataArrayItemVal,// contain part 2 for val1
    val1_data3?: BarChartDataArrayItemVal,// contain part 3 for val1
    val2_data0?: BarChartDataArrayItemVal,// contain main data for val1
    val2_data1?: BarChartDataArrayItemVal,// contain part 1 for val2
    val2_data2?: BarChartDataArrayItemVal,// contain part 2 for val2
    val2_data3?: BarChartDataArrayItemVal,// contain part 3 for val2
};

export function XAxisTickLeftAligntLabel({ x, y, payload }: any) {
  const { font } = useCurrentFont()
    return (
      <g transform={`translate(${0},${y})`}>
        <text x={0} y={0} textAnchor="start" fill={Colors.black} fontSize={Sizes.title} fontFamily={font} fontWeight={Sizes.regularWeight} >
          {payload.value}
        </text>
      </g>
    );
}

function valueWithParts(
  normBase: number,
    ratioNormFactor: number,
  part: BarChartItemValue,
  valueLabel?: string | JSX.Element,
  parentName?: string,
    ratio?: number,
): BarChartDataArrayItemVal {
    return {
        valueLabel,
        valueName: (parentName && part.name) ? parentName + " - " + part.name : part.name,
        value: part.value,
        ratio: (ratio !== undefined) ? ratio : (part.ratio === undefined ? (part.value / normBase) * 100 : part.ratio * ratioNormFactor)
    }
}

function updateDataWithValue( outData: BarChartData, normBase: number, keyPrefix: string, item: BarChartItem, value?: BarChartItemValue) {
    if (value) {// add all existing parts
        type BarChartDataVal = {
            [key: string|number]: any
        }
        const dataMap = outData as BarChartDataVal;

        const valueLabel = (item.units) ? value.value + item.units : value.value + "";
        const ratioNormFactor = (value.value / normBase);// use to normalize ratio get from server. because server ratio is always 100% for each team so is is eqal in terms of ratio

        if (!!value.parts?.length) {
            for (let i = 0; (i < value.parts.length) && (i < 3); i++) {
                dataMap[keyPrefix+(i+1)] = valueWithParts(normBase, ratioNormFactor, value.parts[i], undefined, value.name);
            }
            // if have parts then add main value with 0 ratio to make it 0 width
            dataMap[keyPrefix+0] = valueWithParts(normBase, 1.0, value, valueLabel, undefined, 0);
        }
        else {
            dataMap[keyPrefix+0] = valueWithParts(normBase, ratioNormFactor, value, valueLabel);
        }

    }
}

function processsBarChartItemValue( item: BarChartItem, normBase: number): BarChartData {
    const outData: BarChartData = {
        label: item.label,
    }

    updateDataWithValue(outData, normBase, "val1_data", item, item.value1);
    updateDataWithValue(outData, normBase, "val2_data", item, item.value2);

    return outData;
}

function normlizeValues( values: number[], normBase: number | undefined ): number {
    return normBase || Math.max(...values) || 1;
}

export function createVertBarChartSkinDataArr(data: BarChartItem[]): BarChartData[] {
    // create out arr
    let outArr: BarChartData[] = [];

    // process input data
    for (let index = 0; index < data.length; index++) {
        const item = data[index];

        const normBase = normlizeValues( [item.value1.value, item.value2.value], item.normBase );
        const valueData = processsBarChartItemValue(item, normBase);
        outArr.push(valueData);
    }

    return outArr;
}

export type VertBarChartTeamConfig = Readonly<{
  dataKeyPrefix: string;// key to get value from data
  fillColor?: string;
  fillOpacity?: number;
  stackId?: string;// used to group bars with same label in one row (stack)
  icon?: SvgInHtml;
}>;

// skin config to ver chart bar
export const vertBarChartTeamConfig = (team1Color: string | undefined, team2Color: string | undefined): VertBarChartTeamConfig[] => ([
    {
      fillColor: team1Color,
      fillOpacity: 0.6,
      dataKeyPrefix: "val1_data1",
      stackId: "val1",
      icon: IconFigureWalk,
    },
    {
      fillColor: team1Color,
      fillOpacity: 0.8,
      dataKeyPrefix: "val1_data2",
      stackId: "val1",
      icon: IconFigureRun,
    },
    {
      fillColor: team1Color,
      fillOpacity: 1,
      dataKeyPrefix: "val1_data3",
      stackId: "val1",
      icon: IconFigureSprint,
    },
    {
      fillColor: team1Color,
      fillOpacity: 1,
      dataKeyPrefix: "val1_data0",
      stackId: "val1",
    },


    {
      fillColor: team2Color,
      fillOpacity: 0.6,
      dataKeyPrefix: "val2_data1",
      stackId: "val2",
      icon: IconFigureWalk,
    },
    {
      fillColor: team2Color,
      fillOpacity: 0.8,
      dataKeyPrefix: "val2_data2",
      stackId: "val2",
      icon: IconFigureRun,
    },
    {
      fillColor: team2Color,
      fillOpacity: 1,
      dataKeyPrefix: "val2_data3",
      stackId: "val2",
      icon: IconFigureSprint,
    },
    {
      fillColor: team2Color,
      fillOpacity: 1,
      dataKeyPrefix: "val2_data0",
      stackId: "val2"
    },
  ])


type LabelListCoontentIconProps = Readonly<{
  Icon: SvgInHtml | undefined
  padding?: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  style?: any;
  value?: number | string;
}>;

export const LabelListContentIconWraper: React.FC<LabelListCoontentIconProps> = (props: LabelListCoontentIconProps) => {
  const { x = 0, y = 0, width = 0, height = 0, style, Icon, padding = 0 } = props;
  if (!width || !height || !Icon || width < height) {
    return null;
  }

  return (
    <Icon
      x={x + padding}
      y={y + padding}
      width={width - padding * 2}
      height={height - padding * 2}
      style={style}
      />
  );
};