import { useState, useEffect, useCallback, } from 'react';


export const useDocumentLoader = (
    documentUrl: string | undefined,
    init?: RequestInit | undefined
) => {
    const [loadingPercents, setLoadingPercents] = useState(0);
    const [base64Url, setBase64Url] = useState<string | undefined>(undefined);

    const loadDocument = useCallback(() => {
        if (!documentUrl) {
            return;
        }
        const xhr = new XMLHttpRequest();
        xhr.open('GET', documentUrl, true);
        xhr.responseType = 'arraybuffer';
        const headers = init?.headers;
        if (headers) {
            for (const key in headers) {
                if (Object.prototype.hasOwnProperty.call(headers, key)) {
                    //@ts-ignore
                    xhr.setRequestHeader(key, headers[key]);
                }
            }
        }
        xhr.onprogress = (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setLoadingPercents(percent);
        };

        xhr.onload = () => {
            const loadedFile = new Blob([xhr.response]);
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64 = reader.result as string;
                setBase64Url(base64);
            };

            reader.readAsDataURL(loadedFile);
        };

        xhr.send();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const fetch = useCallback(() => {
        setLoadingPercents(0);
        setBase64Url(undefined);
        loadDocument();
    }, [loadDocument]);

    return { loadingPercents, base64Url, fetch };
};
