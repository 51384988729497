import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { v4 } from "uuid";
import { CameraIntervalImage } from "../../../../../Components/CameraIntervalImage";
import LoadingComponent from "../../../../../Components/Loader/index";
import MainButton from "../../../../../Components/_BaseUI/MainButton";
import Card from "../../../../../Components/_Layout/Card";
import Popup from "../../../../../Components/_Layout/Popup";
import { useDeletePanoramaStore } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/mutations/useDeletePanoramaStore";
import { useDublicatePanoramaStore } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/mutations/useDublicatePanoramaStore";
import { useSetPanoramaStore } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/mutations/useSetPanoramaStore";
import { useWritePanoramaStore } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/mutations/useWritePanoramaStore";
import { useActivePanoramaStore } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/query/useActivePanoramaStore";
import { usePanoramaStoreList } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/query/usePanoramaStoreList";
import { usePingCalibrationMode } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePingCalibrationMode";
import { useCameras } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameras";
import { useAwaitedInterval } from "../../../../../hooks/useAwaitedInterval";
import useConfirm from "../../../../../hooks/useConfirm";
import LOCALIZATION from "../../../../../Localization";
import { Form } from "./Form/Form";
import { StitichingConfigItem } from "./StitichingConfigItem";
import { PanoramaStoreEditorStateProvider } from "./useStoreEditorState";


type Props = Readonly<{
    onClose: () => void
    handleSubmit?: () => Promise<void> | void,
}>;

const Header = styled.span`
color: var(--C_Dark_Text, #334D5E);
margin-bottom:10px;
/* c_h4 (18) */
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.27px;
margin-bottom:10px;`



const StyledCameraIntervalImage = (styled(CameraIntervalImage)`
       height: 100%;
       max-width: calc(100%);
    background-color: #E5E5E5;
    border: solid 1px;
    border-color:  rgba(0, 0, 0, 0.30);
    border-radius: 10px;
    width: 100%;
    min-height: 60px;
`)

const AddMenuText = styled.button`
background: white;
border: none;
display:flex;
font-family: Montserrat;
font-weight: 500;
line-height: 15.85px;
text-align: left;
padding: 10px;


    font-size: 14px;
    font-style: normal;
    line-height: normal;
    :hover {
        cursor: pointer;
        background-color: #c2cfe069;
    }

`

const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))
export const PanoramaStoreList = ((props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote, } = props;
    const { formatMessage } = useIntl();
    const intl = useIntl();
    const { mutateAsync: pingCalibrationMode, pendingKick, isLoading: enteringCalibratyionMode, isError: errorEnteringCalibrationMode } = usePingCalibrationMode()
    const { confirm, confirmDialog } = useConfirm();

    const [kickIgnore, setKickIgnore] = useState<boolean>(false);

    useEffect(() => {
        if (!errorEnteringCalibrationMode) {
            return;
        }
        alert(intl.formatMessage({ id: LOCALIZATION.error_while_changing_camera_handler_conf }))
    }, [errorEnteringCalibrationMode, intl])

    useEffect(() => {
        if (!pendingKick || kickIgnore || confirmDialog?.isOpen) {
            return;
        }
        confirm({
            bodyText: intl.formatMessage({ id: LOCALIZATION.pending_kick_alert }),
            timeoutAction: "confirm",
            timeout: 150,
        }).then((v) => {
            if (v) {
                onClose();
                return
            }
            setKickIgnore(true)
        })

    }, [confirm, confirmDialog?.isOpen, intl, kickIgnore, onClose, pendingKick])
    useAwaitedInterval(() => pingCalibrationMode(), 15_000)


    const [editCylinderModal, setEditCyliderModal] = useState<string | void>()

    const editCallback = useCallback((uuid: string,) => {

        setEditCyliderModal(uuid)


    }, [])



    const { data: stitchingConfigs } = usePanoramaStoreList()



    const loadingMessage = useMemo(() => {

        return undefined

    }, [])


    const onCloseCallback = useCallback(() => {

        onClose?.();
    }, [onClose,])

    const isLoading = useMemo(() => {
        return false;
    }, [])
    const classes = useStyles();

    const imageInterval = useMemo(() => editCylinderModal ? Infinity : 5_000, [editCylinderModal])
    const { data: activeConfigUuid, isLoading: isLoadingActiveConfigUuid } = useActivePanoramaStore()


    const activeItem = useMemo(() => {
        return stitchingConfigs?.getPanoramaStoreList.find(v => v.uuid === activeConfigUuid?.getActivePanoramaStore);

    }, [activeConfigUuid?.getActivePanoramaStore, stitchingConfigs?.getPanoramaStoreList])
    const isPanoramaDisabled = useMemo(() => {
        return !activeItem;
    }, [activeItem])

    const { mutateAsync: addStitching, isLoading: addingStitchingConfig } = useWritePanoramaStore()

    const { isLoading: isDeleting, mutateAsync: deleteConfig } = useDeletePanoramaStore()

    const { data: cameras, isLoading: gettingCameras } = useCameras()

    const { mutateAsync: setConfig, isLoading: isSettingConfig } = useSetPanoramaStore()
    const { mutateAsync: dublicateConfig, isLoading: isDublicatingConfig } = useDublicatePanoramaStore()

    const setConfigCallback = useCallback(async (uuid?: string) => {
        (await confirm({})) && setConfig({ uuid })
    }, [confirm, setConfig])

    const dublicateConfigCallback = useCallback((uuid: string) => {
        dublicateConfig({ uuid })
    }, [dublicateConfig])

    const addCylindralCallbaclk = useCallback(async () => {
        const res = await addStitching({
            uuid: v4(), config: {
                original: {
                    bitrate: 30 * 1000000,
                    "max-parts": 3,
                    h264: false,
                    "full-frame-jpeg": 1,
                },
                operator: {
                    external: "/var/run/camera-handler/camera",
                },
                dest: {
                    "rename-interval": 600,
                    original: [
                        { type: "mp4", file: "/mnt/ssd/panorama/panorama_{timestamp}_part.mp4" }
                    ],
                    operator: [
                        {
                            type: "mp4",
                            file: "/mnt/ssd/operator/operator_{timestamp}.mp4"
                        }
                    ]
                }
            }, name: `Settings ${(new Date()).toLocaleString()}`, disabled: false,
        });
        editCallback(res.writePanoramaStoreConfig)
    }, [addStitching, editCallback])

    return <>
        <Popup
            closeTitle={`${intl.formatMessage({ id: LOCALIZATION.close_btn })}`}
            isFullScreen
            headerTitle={`${intl.formatMessage({ id: LOCALIZATION.page_camera_settings_panorama_settings_header })}`}
            // isApplyDisabled={loadingList}
            onApply={handleSubmitRemote}
            onClose={onCloseCallback}
            classes={classes}
        >
            {/* {`${String(isLoadingActiveConfigUuid)} , ${String(isDublicatingConfig)} , ${String(isLoading)} , ${String(gettingCameras)} , ${String(addingStitchingConfig)} , ${String(enteringCalibratyionMode)} , ${String(isDeleting)} , ${String(isSettingConfig)}`} */}
            <LoadingComponent ugly label={loadingMessage} isLoading={isLoadingActiveConfigUuid || isDublicatingConfig || isLoading || gettingCameras || addingStitchingConfig || enteringCalibratyionMode || isDeleting || isSettingConfig}>

                <Grid sx={{ minHeight: '523px', }} container className={css`
                        display: 'flex';
                         align-items: 'flex-start';
                `} gap={1}>
                    <Grid xs item>
                        <Card sx={{
                            height: "100%",
                        }}
                            contentSx={{
                                height: "100%",
                                padding: '20px 20px 30px 20px !important',
                                boxSizing: "border-box",
                                display: "flex",
                                flexDirection: "column",

                            }}>



                            <Header>{formatMessage({ id: LOCALIZATION.select_panorama_settings })}</Header>

                            <StitichingConfigItem active={isPanoramaDisabled} onSelect={setConfigCallback} name={formatMessage({ id: LOCALIZATION.stitching_disabled })} />
                            {stitchingConfigs?.getPanoramaStoreList.map(({ name, uuid }) => uuid && <StitichingConfigItem active={uuid === activeConfigUuid?.getActivePanoramaStore} key={uuid} onRemove={() => deleteConfig({ uuid })} onDublicate={() => dublicateConfigCallback(uuid)} onEdit={() => editCallback(uuid,)} onSelect={setConfigCallback} name={name} uuid={uuid} />)}
                            <Grid display={"flex"} marginTop={"auto"}>
                                <MainButton sx={{ width: "fit-content", marginLeft: 'auto' }} onClicked={(event) => {
                                    addCylindralCallbaclk()
                                }} title={formatMessage({ id: LOCALIZATION.add })} />

                            </Grid>

                        </Card>
                    </Grid>
                    <Grid md={7} sm={6} xs={12} xl={8}
                        style={{ height: "max-content" }}
                        item>
                        <Card
                            sx={{
                                height: "100%",
                                width: "100%",
                            }}
                            contentSx={{
                                padding: '20px !important',
                                paddingBottom: '30px !important',
                                display: "flex", flexDirection: "column"

                            }}>
                            <Header>{formatMessage({ id: LOCALIZATION.active })}: {activeItem?.name ?? formatMessage({ id: LOCALIZATION.panorama_disabled })}</Header>
                            <div className={css`
    aspect-ratio: ${cameras.length * 16}/9;
max-width: 100%;
                            `}>
                                <StyledCameraIntervalImage camera={"panorama_cropped"} interval={imageInterval} width={1200} />
                            </div>

                        </Card>
                    </Grid>

                </Grid>
            </LoadingComponent>

        </Popup>
        {editCylinderModal && <PanoramaStoreEditorStateProvider uuid={editCylinderModal}><Form uuid={editCylinderModal} onClose={() => setEditCyliderModal()} onApply={() => setEditCyliderModal()} /></PanoramaStoreEditorStateProvider>}
    </>
})

