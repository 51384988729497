import { createContext } from "react";
import { RemoteCameraStateValue } from "../../Data/Camera/RemoteCameraState";

export interface IRemoteCamerasFilter {
  state?: RemoteCameraStateValue;
  setState?: (
    v: RemoteCameraStateValue | undefined,
    /**
     * @default true
     */
    save?: boolean
  ) => void;
}
/**
 *  Context for keeping/changing the state of page filtering state
 */
export const RemoteCamerasFilter = createContext<IRemoteCamerasFilter>({
    state: undefined
});