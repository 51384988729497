import { useMutation, UseMutationResult } from "react-query";
import { NODE_JS_BACKEND_URL } from "../../constants";
import fetchJSONData from "../_Networking/ReactQuery/fetch";
// Определяет структуру данных, возвращаемых API после успешного перезагрузки стрима
interface ReloadResponse {
  message: string;
}

// Определяет интерфейс для возможных ошибок при перезагрузке стрима
interface ReloadError {
  message: string;
}

/**
 * Отправляет POST-запрос к API для перезагрузки стрим-профиля.
 */
async function reloadStream(): Promise<ReloadResponse> {
  const response = await fetchJSONData<ReloadResponse>(
    `${NODE_JS_BACKEND_URL}/streaming/v2/reload`,
    {
      method: "POST",
    }
  );

  return response;
}

export function useReloadStream(): UseMutationResult<
  ReloadResponse,
  ReloadError,
  void
> {
  return useMutation<ReloadResponse, ReloadError, void>(reloadStream, {
    onError: (error) => {
      console.error("Ошибка при перезагрузке стрима:", error.message);
    },
  });
}
