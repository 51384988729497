// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ViewType } from '../../Components/_BaseUI/ViewSwitcher/ViewType.enum';
import useCurrentUser from '../../Data/AccountUsers/hook/useCurrentUser';
import isServer from '../../Data/AccountUsers/tools/isServer';
import { clearCamerasCache } from '../../Data/Camera/ActiveCamera/fetch';
import { RemoteCameraStateValue } from '../../Data/Camera/RemoteCameraState';
import Order from '../../Data/_Networking/ReactQuery/Order';
import CameraCardLocal from './CameraCardLocal';
import CameraCardServer from './CameraCardServer';
import { PaginationContext } from './Pagination.context';
import { RemoteCamerasFilter } from './RemoteCamerasFilter.context';
import { TableViewContext } from './TableView.context';

const CamerasPage: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const user = useCurrentUser();
  const isServerUser = isServer(user);
  const [currentView, setCurrentView] = useState<ViewType>(ViewType.LIST);

  /**
   * Pagination state
   */
  const [offset, setOffset] = useState<number>(0);
  const [state, setState] = useState<RemoteCameraStateValue | undefined>();
  const [order, setOrder] = useState<Order | undefined>(Order.ASC);
  /**
   * Count of elements on the page
   */
  const [limit] = useState<number>(14);

  React.useEffect(() => {
    clearCamerasCache();
  }, []);

  const setOrderCallback = useCallback((order: Order) => {
    setOrder(order);
    localStorage.setItem("filter-order-d81a1717-3cf2-40dd-8171-2c71eff15b65", order)
  }, [])

  const setStateCallback = useCallback((remoteState?: RemoteCameraStateValue, save = true) => {
    setState(remoteState);
    if (!save) {
      return
    }
    remoteState ?
      localStorage.setItem("filter-state-d81a1717-3cf2-40dd-8171-2c71eff15b65", remoteState)
      : localStorage.getItem("filter-state-d81a1717-3cf2-40dd-8171-2c71eff15b65") && localStorage.removeItem("filter-state-d81a1717-3cf2-40dd-8171-2c71eff15b65")
  }, [])
  const setPage = useCallback((value: number) => {
    setOffset(value * limit)
  }, [limit])
  const setPageCallback = useCallback((offset: number) => {
    setPage(offset);
    localStorage.setItem("filter-offset-d81a1717-3cf2-40dd-8171-2c71eff15b65", offset.toString())
  }, [setPage])

  const setTableViewCallback = useCallback((type: ViewType) => {
    setCurrentView(type);
    localStorage.setItem("table-view-d81a1717-3cf2-40dd-8171-2c71eff15b65", type.toString())
  }, [])


  useEffect(() => {
    const tableView = localStorage.getItem("table-view-d81a1717-3cf2-40dd-8171-2c71eff15b65")

    const order = localStorage.getItem("filter-order-d81a1717-3cf2-40dd-8171-2c71eff15b65")
    const state = localStorage.getItem("filter-state-d81a1717-3cf2-40dd-8171-2c71eff15b65")
    const offset = Number(localStorage.getItem("filter-offset-d81a1717-3cf2-40dd-8171-2c71eff15b65"))
    tableView && setCurrentView(Number(tableView) as ViewType)
    offset && setPageCallback(offset);
    state && setStateCallback(state as RemoteCameraStateValue);
    order && setOrderCallback(order as Order)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isServerUser) {
    return (
      <TableViewContext.Provider value={{ currentView, setCurrentView: setTableViewCallback }}>
        <PaginationContext.Provider value={{ offset, limit, setPage: setPageCallback, setOrder: setOrderCallback, orderByDate: order }}>
          <RemoteCamerasFilter.Provider value={{ state, setState: setStateCallback }}>
            <CameraCardServer />
          </RemoteCamerasFilter.Provider>
        </PaginationContext.Provider>
      </TableViewContext.Provider>

    )
  }

  return (
      <CameraCardLocal />
  )
};

export default injectIntl(CamerasPage);
