import * as PIXI from "pixi.js-legacy";
import { Subject } from "rxjs";
import { Vertex } from "./Vertex";

export class Polygon extends PIXI.Graphics {
  private _isDraging = false;
  public lastDrag: number = 0;

  set isDraging(value: boolean) {
    this.lastDrag = new Date().getTime();
    this._isDraging = value;
  }

  get isDraging() {
    return this._isDraging;
  }

  private _update$ = new Subject<Polygon>();

  public get update$() {
    return this._update$.asObservable();
  }

  constructor(
    public readonly vertices: Vertex[],
    public fillColor: number = 0x18a0fb,
    public strokeColor = 0x18a0fb0d
  ) {
    super();
    for (const vertex of vertices) {
      vertex.position$.subscribe((v) => this.onMovedVertices(vertex));
    }
    this.cursor = "grab";

    this.on("pointerdown", this.onDragStart);
    this.on("pointerup", this.onDragEnd);
    this.on("pointerupoutside", this.onDragEnd);
    this.on("globalpointermove", this.onDragMove);
    this.redrawFigure();
    this.interactive = true;

    this.on("added", (container) => {
      for (const vertex of this.vertices) {
        if (vertex.parent) {
          continue;
        }
        container.addChild(vertex);
        vertex.zIndex = this.zIndex + 1;
      }
    });
  }

  protected onMovedVertices(vertice: Vertex) {
    console.log(`Vertex moved: x=${vertice.x}, y=${vertice.y}`);
    this.redrawFigure();
    this._update$.next(this);
  }

  public redrawFigure() {
    this.clear();
    this.pivot.set(0, 0);

    const polygon = new PIXI.Polygon(
      ...this.sortPointsClockwise(
        this.vertices.map((p) => new PIXI.Point(p.x, p.y))
      )
    );
    this.beginFill(this.fillColor);
    this.alpha = 0.5;
    this.drawPolygon(polygon);
    this.endFill();
    for (const vertex of this.vertices) {
      vertex.zIndex = this.zIndex + 1;
    }
  }

  protected onDragStart(event: PIXI.FederatedPointerEvent) {
    this.isDraging = true;
    this.cursor = "grabbing";
  }

  protected onDragMove(event: PIXI.FederatedPointerEvent) {
    if (!this.isDraging) {
      return;
    }
    this.cursor = "grabbing";

    for (const vertex of this.vertices) {
      const newPos = new PIXI.Point(
        vertex.position.x + event.movementX,
        vertex.position.y + event.movementY
      );
      vertex.position.x = newPos.x;
      vertex.position.y = newPos.y;
      console.log(`Vertex dragged to: x=${newPos.x}, y=${newPos.y}`);
    }
    this.redrawFigure();
    this._update$.next(this);
  }

  protected onDragEnd(event: PIXI.FederatedPointerEvent) {
    this.cursor = "grab";
    this.isDraging = false;
  }

  private sortPointsClockwise(points: PIXI.Point[]) {
    // Calculate the center point
    const center = new PIXI.Point(0, 0);
    for (const point of points) {
      center.x += point.x;
      center.y += point.y;
    }
    center.x /= points.length;
    center.y /= points.length;

    // Sort the points based on the angle
    points.sort((a, b) => {
      const angleA = Math.atan2(a.y - center.y, a.x - center.x);
      const angleB = Math.atan2(b.y - center.y, b.x - center.x);
      return angleA - angleB;
    });

    return points;
  }
}
