import styled from "@emotion/styled";
import { Alert, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { v4 } from "uuid";
import LoadingComponent from "../../../../../Components/Loader";
import Card from "../../../../../Components/_Layout/Card";
import Popup from "../../../../../Components/_Layout/Popup";
import { useCurrentFont } from "../../../../../Configuration/Styles/Fonts";
import { useEditUndistordConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useEditUndistordConfig";
import { useRequestGenerateUndistordConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useGenerateUndistordConfig";
import { usePushUndistordImage } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePushUndistordImage";
import { useRemoveCalibrationJob } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useRemoveCalibrationJob";
import { useRemoveUndistordImage } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useRemoveUndistordImage";
import { useCalibrationConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCalibrationConfig";
import { useCurrentUndistordConfigs } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCurrentUndistordConfigs";
import { usePollCalibrationState } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/usePollCalibrationState";
import { useCameraCommonSettings } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { useUndistordConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/useUndistordConfig";
import { useUndistordConfigsList } from "../../../../../Data/NapiCameraHandlerV2/hooks/useUndistordConfigsList";
import { useCalibrationMode } from "../../../../../hooks/useCalibrationMode";
import { useObjectChanged } from "../../../../../hooks/useObjectChanged";
import LOCALIZATION from "../../../../../Localization";
import { EditAdditianonalParams } from "./EditAdditianonalParams";
import { UndisotrionConfigEdit } from "./UndisotrionConfigEdit";
import { UdistortionImageInput } from "./UndistionImageInput";
type Props = Readonly<{
    onClose: () => void
    handleSubmit?: () => Promise<void>,
    camera: number,
    name?: string,
    uuid?: string,
}>;

const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))



export const CreateUdistortionConfigModalPreload = ((props: Props) => {
    const { uuid } = props;
    const { data, fetchData } = useUndistordConfigsList({
        options: {
            keepPreviousData: true,
        }
    });

    useEffect(() => {
        fetchData()
    }, [fetchData])
    const settingsList = useMemo(() => {
        return data?.getUndistordConfigs ?? []
    }, [data])
    const prevSettings = useMemo(() => {
        return settingsList.find(v => v.uuid === uuid)
    }, [settingsList, uuid]);

    return <>{<LoadingComponent isLoading={!data}><EditUdistortionConfigModal {...{ ...props, ...prevSettings }} /></LoadingComponent>}</>
})
export const EditUdistortionConfigModal = ((props: Props) => {
    const { camera: cameraNumber } = props;
    const { onClose, handleSubmit: handleSubmitRemote, name: initialName = `Lens#${cameraNumber} Settings ${(new Date()).toLocaleString()}`, uuid: existUUID } = props;
    const { isLoading: loadingCurrentConfigs } = useCurrentUndistordConfigs();
    const intl = useIntl();
    const [name, setName] = useState<string>(initialName)

    const classes = useStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const req = useMemo(() => (existUUID ? { req: { uuid: existUUID } } : undefined), [existUUID])
    const { data: undistordConfig, } = useUndistordConfig(req)
    const { setRunActiveUndistord } = useCalibrationMode();

    useEffect(() => {
        setRunActiveUndistord(false)
        return () => {
            setRunActiveUndistord(true);
        }
    }, [setRunActiveUndistord])

    const [configToSave, setConfigToSave] = useState<{
        type?: "fisheye" | "regular",
        kdata?: number[],
        ddata?: number[],
        angle?: number,
        balance?: number;
        scale?: number;
        stretch?: [number, number, number];
    } | undefined>(undefined)
    const { value: lastConfigToSave, } = useObjectChanged(configToSave)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const uuid = useMemo(() => existUUID ?? v4(), [])

    const { abort: abortCalibration } = usePollCalibrationState({ uuid: uuid ?? '' })

    const onCloseCallback = () => {
        abortCalibration();
        onClose();
    }

    const { mutateAsync: saveConfigParams, isLoading: savingParams } = useEditUndistordConfig({ uuid })
    const [imagesToSave, setImagesToSave] = useState<{ uuid: string, file: string, name: string }[]>([]);
    const { mutateAsync: removeImage, isLoading: removingImage } = useRemoveUndistordImage()

    const [imagesToDelete, setImagesToDelete] = useState<{ uuid: string, filename: string }[]>([]);
    const [imageInputKey, resetImageInputKey] = useState(v4())
    const { mutateAsync: saveImage, isLoading: imageSaving } = usePushUndistordImage();
    const [isSaving, setIsSaving] = useState<boolean>();
    const saveConfig = (async (close = true) => {
        setIsSaving(true);
        try {

            const configUploading: Promise<any>[] = []
            configUploading.push(saveConfigParams({
                uuid, patch: configToSave, name
            }));
            for (const image of imagesToSave) {
                configUploading.push(saveImage({ ...image, file: await (await fetch(image.file)).blob() }).catch(e => {
                    alert(`${intl.formatMessage({ id: LOCALIZATION.error_image_uploading })}`)
                }));
            }
            for (const image of imagesToDelete) {
                configUploading.push(removeImage({ ...image }))
            }
            await Promise.all(configUploading)
            await handleSubmitRemote?.()
            setImagesToSave([]);
            setImagesToDelete([]);
            resetImageInputKey(v4());
            if (close) {
                onCloseCallback()
            }
        } catch (e) {
            alert(`${intl.formatMessage({ id: LOCALIZATION.error_save })}`)

        }
        setIsSaving(false);
    })
    const { mutateAsync: generateConfig, isLoading: requestingConfigGeneration, isError } = useRequestGenerateUndistordConfig({ uuid })

    const { data: calibratedConfig, isLoading: waitingForCalibratedConfig, refetch: refetchCalibrationState, } = useCalibrationConfig({ uuid, options: { refetchInterval: 5_000 } })

    const calculatingStatus = useMemo(() => calibratedConfig?.getCalibrationState?.status, [calibratedConfig?.getCalibrationState?.status])

    const { mutateAsync: removeCalibrationJob, isLoading: removingJob, isError: isErrorOnRemoving } = useRemoveCalibrationJob()

    useEffect(() => {
        if (!isErrorOnRemoving) {
            return
        }
        alert(intl.formatMessage({ id: LOCALIZATION.removing_err }))
    }, [intl, isErrorOnRemoving])

    const onRequestingCalibration = (async () => {
        await saveConfig(false)
        await generateConfig({ uuid });
        await refetchCalibrationState()
    });

    const onRequestingSaveFiles = useCallback(async () => {
        if (!imagesToSave.length) {
            return;
        }
        setIsSaving(true);
        const configUploading: Promise<any>[] = []

        try {
            for (const image of imagesToSave) {
                configUploading.push(saveImage({ ...image, file: await (await fetch(image.file)).blob() }).catch(e => {
                    alert(`${intl.formatMessage({ id: LOCALIZATION.error_image_uploading })}`)
                }));
            }
        } catch {

        }
        await Promise.all(configUploading)

        setImagesToSave([]);
        // resetImageInputKey(v4());
        setIsSaving(false);
    }, [imagesToSave, intl, saveImage]);

    useEffect(() => { onRequestingSaveFiles() }, [onRequestingSaveFiles])


    useEffect(() => {
        if (!isError) {
            return;
        }
        alert(intl.formatMessage({ id: LOCALIZATION.calculating_undistord_error }))
    }, [intl, isError])


    const loadingLabel = useMemo(() => {
        if (loadingCurrentConfigs) {
        }
        if (imageSaving) {
            return intl.formatMessage({ id: LOCALIZATION.image_saving })
        }
        if (savingParams || removingImage) {
            return intl.formatMessage({ id: LOCALIZATION.saving_changes })
        }
        if (calculatingStatus === "pending") {
            return intl.formatMessage({ id: LOCALIZATION.calculating_undistord })
        }
    }, [calculatingStatus, imageSaving, intl, loadingCurrentConfigs, removingImage, savingParams])


    const cancelJob = useCallback(() => {
        removeCalibrationJob({ uuid })
    }, [removeCalibrationJob, uuid])

    const onCancelCallback = useMemo(() => {
        if (loadingLabel === intl.formatMessage({ id: LOCALIZATION.calculating_undistord })) {
            return cancelJob
        }
    }, [cancelJob, intl, loadingLabel])

    const initialEditValues = useMemo(() => undistordConfig?.getUndistordConfig.config ?? {}, [undistordConfig?.getUndistordConfig.config])
    const { value: lastInitialValues, version } = useObjectChanged(initialEditValues)
    const onChangeCallback = useCallback((v: SetStateAction<{ type?: "fisheye" | "regular" | undefined; kdata?: number[] | undefined; ddata?: number[] | undefined; angle?: number | undefined; balance?: number | undefined; scale?: number | undefined; stretch?: [number, number, number] | undefined; } | undefined>) => {
        setConfigToSave({ ...lastInitialValues, ...lastConfigToSave, ...v });
    }, [lastConfigToSave, lastInitialValues])

    const { data: commonSettingsData, } = useCameraCommonSettings()
    const { font } = useCurrentFont()

    const mainCamera = useMemo(() => {
        return commonSettingsData?.configState.config.camera["main-camera"]
    }, [commonSettingsData])

    const nameChangeCallback: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => setName(e.target.value), [])
    const Input = useMemo(() => styled.input`
    color: var(--AdBlack, #000);
    /* c_body_P */
    font-family: ${font};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    border: 1px solid var(--AdGray1, #B5BBC5);
    background: #FFF;
    height: 38px;
    width: 100%;
    padding-left: 12px;
`, [font])
    return <>
        <Popup
            isFullScreen
            headerTitle={`${intl.formatMessage({ id: LOCALIZATION.lens })}  #${cameraNumber}${mainCamera === cameraNumber ? `(${intl.formatMessage({ id: LOCALIZATION.main_lens })})` : ''} ${intl.formatMessage({ id: LOCALIZATION.calibration })}`}

            isApplyDisabled={isSaving || loadingCurrentConfigs || imageSaving || savingParams || removingImage}
            // isCloseDisabled={patchLoading}
            applyTitle={`${intl.formatMessage({ id: LOCALIZATION.save })}`}
            onApply={saveConfig}
            onClose={onCloseCallback}
            classes={classes}
        >
            <LoadingComponent switchDelay={0} ugly isLoading={isSaving || loadingCurrentConfigs || imageSaving || savingParams || removingImage || removingJob || requestingConfigGeneration || waitingForCalibratedConfig || calculatingStatus === "pending"} onCancel={onCancelCallback} cancelDisabled={removingJob} label={loadingLabel}>
                <Grid container >
                    <Grid marginBottom={3} item xs={12}>
                        <Input value={name} onChange={nameChangeCallback} />
                    </Grid>

                    <Grid item xs={12} marginBottom={2}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <UdistortionImageInput initialImagesToRemove={imagesToDelete} onRequestingCalibration={onRequestingCalibration} requestSaveFiles={onRequestingSaveFiles} key={imageInputKey} onChange={v => { setImagesToSave(v.toSave); setImagesToDelete(v.toRemove) }} camera={cameraNumber} uuid={uuid}></UdistortionImageInput>
                        </Card>
                    </Grid>

                    <Grid item xs={12} marginBottom={2} >
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            {calculatingStatus === 'rejected' && <Grid marginBottom={2}>
                                <Alert severity="warning"> {intl.formatMessage({ id: LOCALIZATION.calculating_undistord_error })}</Alert>
                            </Grid>}

                            {lastInitialValues && <UndisotrionConfigEdit header hasUndistordApplyModal key={version} uuid={uuid} onChange={onChangeCallback} initialValues={lastInitialValues} />}

                        </Card>
                    </Grid>

                    <Grid item xs={12} marginBottom={2} >
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            {lastInitialValues && <EditAdditianonalParams initialValues={lastInitialValues} onChange={onChangeCallback} />}

                        </Card>
                    </Grid>



                </Grid>
            </LoadingComponent>

        </Popup>
    </>
})
