import { gql } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { useCameraHandler } from "../../../hooks/useCameraHandler";
import { CommonSettings, globalConfigCacheKey } from "../types";

const mutationQl = gql`
  mutation ChangeCommonCameraSettings($commonSettingsInput: Object!) {
    changeCommonCameraSettings(commonSettingsInput: $commonSettingsInput) {
      state
    }
  }
`;

interface IResponse {
  state: number;
}

interface IPatchCommonSettingProps {}

export const usePatchCommonSetting = (props?: IPatchCommonSettingProps) => {
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const request = (patch: CommonSettings) =>
    client.request<IResponse>(mutationQl, { ...patch });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
    queryClient.invalidateQueries(globalConfigCacheKey);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    queryClient.invalidateQueries(globalConfigCacheKey);
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
