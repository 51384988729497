import * as PIXI from "pixi.js-legacy";
import { CanvasHTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import { debounceTime } from "rxjs";
import { useFirstDefinedValue } from "../../../../../../hooks/useFirstDefined";
import useImageDimensions from "../../../../../../hooks/useGetImageDimansions";
import { useObjectChanged } from "../../../../../../hooks/useObjectChanged";
import { useWhyDidYouUpdate } from "../../../../../../hooks/useWhyDidYouUpdate";
import { PanningContainer } from "./PanningContainer";
import { Rectangle } from "./Rectangle";
import { Region } from "./Region.interface";
import { Vertex } from "./Vertex";


export const withRegion = <P extends CanvasHTMLAttributes<HTMLCanvasElement>>(
  Component: React.ComponentType<P>,

) => {
  return (props: P & {
    /**
     * it's tooks only once
     */
    base64?: string,
    config?: Partial<PIXI.IApplicationOptions>,
    region: Region,
    onRegionChanged?: (region: Region) => void
  }) => {
    const { base64, config, region, onRegionChanged, } = props;
    useWhyDidYouUpdate('sad', props)
    const ref = useRef<HTMLCanvasElement>(null);
    const firstbase64 = useFirstDefinedValue(base64)
    const { width: imageWidth, height: imageHeight } = useImageDimensions(firstbase64)
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    useEffect(() => {
      setCanvas(ref.current);
    }, []);

    useEffect(() => {
      const parent = canvas?.parentElement;
      if (!canvas || !parent) {
        return;
      }
      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    }, [canvas])



    const app = useMemo(() => {
      if (!canvas) {
        return;
      }
      const app = new PIXI.Application({
        width: Number(canvas.width),
        height: Number(canvas.height),
        antialias: true,
        resolution: 1,
        view: canvas,
        forceCanvas: true,
        ...config,
      });
      //@ts-ignore
      window.pixiapp = app

      return app
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas])

    const panningContainer = useMemo(() => {
      if (!app) {
        return;
      }
      const panning = new PanningContainer()
      panning.sortableChildren = true;
      app.stage.addChild(panning)

      return panning
    }, [app])
    const [polygon, setpolygon] = useState<Rectangle>()

    useEffect(() => {
      if (!panningContainer || !imageWidth || !imageHeight || polygon) {
        return
      }
      const { left, right, top, bottom } = region;
      const box = {
        points: [
          [Number(left), Number(top)],
          [Number(imageWidth - right), Number(imageHeight - bottom)],
        ] as [number, number][]
      }
      const vertices = box.points.map(p => { return new Vertex(new PIXI.Point(p[0], p[1])) })
      const poly = new Rectangle(vertices as [Vertex, Vertex]);

      poly.zIndex = 2;

      poly.update$.pipe(debounceTime(300)).subscribe((object) => {
        const points = object.vertices.map(v => [v.x, v.y] as [number, number]);

        const minX = Math.round(Math.min(...points.map(v => v[0])));
        const maxX = Math.round(Math.max(...points.map(v => v[0])));
        const minY = Math.round(Math.min(...points.map(v => v[1])));
        const maxY = Math.round(Math.max(...points.map(v => v[1])));


        // Теперь корректно пересчитываем границы региона
        onRegionChanged?.({
          left: minX,
          top: minY,
          right: imageWidth - maxX,  // Смещение от правого края
          bottom: imageHeight - maxY // Смещение от нижнего края
        });
      });
      panningContainer.addChild(poly);
       setpolygon(poly);
      // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [panningContainer, imageWidth, imageHeight])

    const { value: changedRegion } = useObjectChanged(region)


    const [imageSprite, setImageSprite] = useState<PIXI.Sprite>()

    useEffect(() => {
      if (!imageHeight || !imageWidth || !base64 || !panningContainer) {
        return
      }
      const img = PIXI.Sprite.from(base64);
      img.anchor.set(0, 0);
      img.position.set(0, 0);
      img.width = imageWidth;
      img.height = imageHeight;
      img.zIndex = 1;
      panningContainer.addChild(img);
      setImageSprite(img)
      return () => {
        img.destroy(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panningContainer, imageWidth, imageHeight])
    useEffect(() => {
      if (!polygon || polygon.isDraging || !imageWidth || !imageHeight || !imageSprite) {
        return;
      }
      const points = polygon.vertices.map(v => [v.x, v.y] as [number, number]);
      const maxX = Math.round(Math.max(...points.map(v => v[0])));
      const minX = Math.round(Math.min(...points.map(v => v[0])));
      const maxY = Math.round(Math.max(...points.map(v => v[1])));
      const minY = Math.round(Math.min(...points.map(v => v[1])));

      const { left, right, top, bottom } = changedRegion;

      // Исправляем расчет, чтобы учитывать смещения правильно
      const calculatedRight = imageWidth - right;
      const calculatedBottom = imageHeight - bottom;

      if (left === minX &&
        top === minY &&
        calculatedRight === maxX &&
        calculatedBottom === maxY) {
        return;
      }

      // Обновляем позиции вершин с учетом новых расчетов
      polygon.rb.position.set(calculatedRight, calculatedBottom);
      polygon.lt.position.set(left, top);
      polygon.rt.position.set(calculatedRight, top);
      polygon.lb.position.set(left, calculatedBottom);

      polygon.redrawFigure();
    }, [polygon, changedRegion, imageWidth, imageHeight, imageSprite]);

    useEffect(() => {
      return () => app?.destroy(true, { children: true })
    }, [app])


    return <Component ref={ref}  {...props as P}></Component>
  }

};
