// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { ReactNode, useMemo } from 'react';

import { GridSize } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import FieldSetBoolean, { FieldSetInputBoolean } from './FieldSetBoolean';
import FieldSetColor, { FieldSetInputColor } from './FieldSetColor';
import FieldSetDateAndTimeComponent, { FieldSetDateAndTime } from './FieldSetDateAndTimeComponent';
import FieldSetDateComponent, { FieldSetDate } from './FieldSetDateComponent';
import FieldSetFileComponent, { FieldSetFile } from './FieldSetFileComponent';
import { FieldSetInputNumber, FieldSetNumber } from './FieldSetNumber';
import FieldSetOtherComponent, { FieldSetOther } from './FieldSetOtherComponent';
import FieldSetSelectComponent, { FieldSetSelect } from './FieldSetSelectComponent';
import FieldSetStaticLabel, { FieldSetInputStaticLabel } from './FieldSetStaticLabel';
import FieldSetText, { FieldSetInputText } from './FieldSetText';
import FieldSetTimeComponent, { FieldSetTime } from './FieldSetTimeComponent';
import FieldSetTimeTillTimeComponent, { FieldSetTimeTillTime } from './FieldSetTimeTillTimeComponent';
import FieldSetWrapper from "./FieldSetWrapper";


export enum FieldSetInputType {
  Number = 'number',
  Float = 'float',
  Text = 'text',
  TextArea = 'textarea',
  Password = 'password',
  Color = 'color',
  Select = 'select',
  Date = 'date',
  Time = 'time',
  DateAndTime = 'dateAndTime',
  TimeTillTime = 'timeTillTime',
  Other = 'other',
  File = 'file',
  Radio = 'radio',
  Boolean = 'boolean',
  StaticLabel = 'staticLabel',
}

type ChangeRadio = (value: boolean) => void;

type FieldSetInputRadio = Readonly<{
  type: FieldSetInputType.Radio;
  value: boolean;
  onChange: ChangeRadio;
}>;

type Input = Readonly<
  FieldSetInputText | FieldSetInputNumber | FieldSetInputBoolean |
  FieldSetInputStaticLabel | FieldSetInputColor | FieldSetSelect | FieldSetInputRadio |
  FieldSetDate | FieldSetTime | FieldSetDateAndTime | FieldSetTimeTillTime |
  FieldSetFile | FieldSetOther
>;

type Props = Readonly<{
  label?: ReactNode;
  labelSx?: SxProps<Theme>;
  tooltipQuestionText?: string;
  onHintTooltipText?: string;
  onErrorTooltipText?: string;// show if input.errorCheckPattern return true or if forceShowonErrorTooltipText==true
  forceShowonErrorTooltipText?: boolean;
  input?: Input;
  hideSeparator?: boolean
  disableAutocomplete?: boolean
  isError?: boolean;
  isHint?: boolean;
  buttonSx?: string;
  button?: { onClick?: () => void, label: ReactNode };
  labelSize?: boolean | GridSize;
  inputSize?: boolean | GridSize;
  inputSx?: SxProps<Theme>;
  mask?: string;
}>;


const FieldSet: React.FC<Props> = (props: Props) => {
  const { labelSize, inputSize, forceShowonErrorTooltipText, onHintTooltipText, onErrorTooltipText } = props;

  const [isError, setIsError] = React.useState<boolean>(false);
  const [isHint, setIsHint] = React.useState<boolean>(false);
  const helperText = useMemo(() => {
    if (isError || forceShowonErrorTooltipText) {
      return onErrorTooltipText
    }
    if (isHint) {
      return onHintTooltipText
    }
  }, [isError, forceShowonErrorTooltipText, isHint, onErrorTooltipText, onHintTooltipText])
  const control = useMemo(() => {
    switch (props.input?.type) {

      case FieldSetInputType.Password:
      case FieldSetInputType.Text: {
        return (
          <FieldSetText
            input={props.input}
            isError={props.isError}
            isHint={props.isHint}
            onIsError={setIsError}
            onIsHint={setIsHint}
            disableAutocomplete={true}
          />
        );


      }
      case FieldSetInputType.StaticLabel: {
        return (
          <FieldSetStaticLabel
            input={props.input}
          />
        );

      }
      case FieldSetInputType.Boolean: {
        return (
          <FieldSetBoolean
            input={props.input}
          />
        );

      }
      case FieldSetInputType.Float:
      case FieldSetInputType.Number: {
        return (
          <FieldSetNumber
            {...props.input}
          />
        );


      }
      case FieldSetInputType.Color: {
        return (
          <FieldSetColor
            input={props.input}
          />
        );


      }
      case FieldSetInputType.Select: {
        return (
          <FieldSetSelectComponent
            input={props.input}
          />
        );


      }
      case FieldSetInputType.Date: {
        return (
          <FieldSetDateComponent
            input={props.input}
            isError={isError}
            onIsError={setIsError}
          />
        );


      }
      case FieldSetInputType.Time: {
        return (
          <FieldSetTimeComponent
            input={props.input}
            isError={isError}
            onIsError={setIsError}
          />
        );


      }
      case FieldSetInputType.DateAndTime: {
        return (
          <FieldSetDateAndTimeComponent
            input={props.input}
            isError={isError}
            onIsError={setIsError}
          />
        );


      }
      case FieldSetInputType.TimeTillTime: {
        return (
          <FieldSetTimeTillTimeComponent
            input={props.input}
            isError={isError}
            onIsError={setIsError}
          />
        );


      }
      case FieldSetInputType.Other: {
        return (
          <FieldSetOtherComponent
            input={props.input}
          />
        );


      }
      case FieldSetInputType.File: {
        return (
          <FieldSetFileComponent
            input={props.input}
          />
        );


      }
      default: return null;
    }

  }, [isError, props.input, props.isError, props.isHint])

  if (control) {
    return (
      <FieldSetWrapper
        error={isError}
        hint={isHint}
        buttonSx={props.buttonSx}
        button={props.button}
        labelSize={labelSize}
        inputSize={inputSize}
        label={ props.label }
        labelSx={props.labelSx}
        inputSx={props.inputSx}
        tooltipQuestionText={ props.tooltipQuestionText }
        multiLine={ (props.input?.type === FieldSetInputType.Text && (props.input.rows || 1) > 1) }
        helperText={helperText}
        hideSeparator = {props.hideSeparator }
      >
        { control }
      </FieldSetWrapper>
    );
  }
  else {
    return (
      <FieldSetWrapper
        label={ props.label }
        labelSx={ props.labelSx }
        tooltipQuestionText={ props.tooltipQuestionText }
        helperText={helperText}
        hideSeparator = {props.hideSeparator }
      />
    );
  }
};


export default FieldSet;
