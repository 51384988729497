
import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient, } from 'react-query';
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";
import { undistordConfigCacheKey } from "../../useUndistordConfig";
import { useUndistordConfigsListCacheKey } from "../../useUndistordConfigsList";

const mutationQl = gql`
  mutation WriteUndistordConfig(
    $uuid: ID!
    $name: String!
    $patch: UndistordConfigInput
  ) {
    writeUndistordConfig(uuid: $uuid, patch: $patch, name: $name)
  }
`;

type Response = { writeUndistordConfig: boolean };

interface IPatchCommonSettingProps {}

interface IRequest {
  uuid: string;
  patch?: Partial<{
    type: "fisheye" | "regular";
    kdata: number[];
    ddata: number[];
    angle: number;
    balance: number;
  }>;
  name: string;
}

export const useEditUndistordConfig = (props: IPatchCommonSettingProps) => {
  const queryClient = useQueryClient();
  const client = useCameraHandler() ?? new GraphQLClient("");
  const request = (req: IRequest) =>
    client.request<Response>(mutationQl, { ...req });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
    if (props.uuid) {
      queryClient.invalidateQueries({
        predicate: (queries) => {
          return queries.queryKey.includes(undistordConfigCacheKey(props.uuid));
        },
      });
    }
    queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useUndistordConfigsListCacheKey);
      },
    });
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);

    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(undistordConfigCacheKey(props.uuid));
      },
    });
    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useUndistordConfigsListCacheKey);
      },
    });
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};