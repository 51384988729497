import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, CircularProgress, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDelay } from '../../hooks/useDelay';
import { UglyLoaderIcon } from '../../Icons/Icons';
import LOCALIZATION from '../../Localization';
import MainButton from '../_BaseUI/MainButton';
import Popup from '../_Layout/Popup';

// Определяем интерфейс для пропсов стилей
interface StyleProps {
    blur: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
            width: '100%',
            minHeight: "60px",
            minWidth: "40px",
        },
        loader: {
            position: 'absolute',
            right: '0',
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        mask: (props: StyleProps) => ({
            position: 'absolute',
            top: '-2.5%',
            left: '-2.5%',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            backdropFilter: `blur(${props.blur}px)`,
            boxShadow: '1px 0px 20px 14px rgb(0 0 0 / 0%);',
            height: '105%',
            width: '105%',
            zIndex: 9999,
        }),
    })
);

interface Props {
    className?: string;
    isLoading?: boolean;
    children?: ReactNode;
    /**
     * Используется для предотвращения появления дочерних элементов в DOM
     */
    pretendChildrenInitializing?: boolean;
    label?: string;
    ugly?: boolean;
    onCancel?: () => void;
    switchDelay?: number;
    cancelDisabled?: boolean;
    /**
     * Степень размытия (в пикселях). По умолчанию 5.
     */
    blur?: number;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinningIcon = styled(UglyLoaderIcon)`
    margin-top: 45px;
  animation: ${spin} 1s steps(12) infinite;
`;

const useUglyStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: "hidden",

            '& .MuiCardContent-root': {
                overflow: "hidden",
            }
        },
        rootDialog: {
            overflow: "hidden",
            width: '100px',
            paddingTop: 0,
            paddingRight: '40px',
            paddingBottom: '30px',
            overflowY: "hidden",
        },
        contentRoot: {
            overflow: "hidden",
            padding: 0,
            height: "auto",
        },
    })
);

const LoadingComponent = ({
    isLoading,
    children,
    pretendChildrenInitializing,
    label,
    ugly,
    onCancel,
    switchDelay = 0,
    cancelDisabled,
    className,
    blur = 5, // Значение по умолчанию
}: Props) => {
    const classes = useStyles({ blur });

    const UglyLabel = styled.p`
        color: #000;
        text-align: center;

        /* c_body_P */
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 30px;
    `;

    const uglyClasses = useUglyStyles();
    const delayedLoading = useDelay(isLoading, switchDelay);
    const pretendChildrenLogic = useMemo(() => {
        if (delayedLoading && pretendChildrenInitializing) {
            return (<></>)
        }
        return <>{children}</>
    }, [children, delayedLoading, pretendChildrenInitializing]);
    const intl = useIntl();

    if (!delayedLoading) {
        return <>{children}</>;
    }

    return (
        <div className={`${classes.root} ${className}`}>
            {pretendChildrenLogic}
            {!ugly && <div className={classes.mask} />}
            {!ugly && (
                <div className={classes.loader}>
                    <CircularProgress />
                    {label}
                    {onCancel && (
                        <MainButton
                            onClicked={onCancel}
                            isDisabled={cancelDisabled}
                            title={`${intl.formatMessage({ id: LOCALIZATION.cancel })}`}
                        />
                    )}
                </div>
            )}
            {ugly && (
                <Popup
                    isCloseDisabled
                    headerTitle={intl.formatMessage({ id: LOCALIZATION.stream_profile_apply_progress_header })}
                    width={660}
                    classes={uglyClasses}
                    isWide
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        padding={2}
                        height={320}
                        maxWidth={660}
                        alignItems={"center"}
                        overflow={"hidden"}
                    >
                        <UglyLabel>
                            {label ?? intl.formatMessage({ id: LOCALIZATION.loading_data })}
                        </UglyLabel>

                        <SpinningIcon width={90} height={90} />
                        <Box marginTop={4}>
                            {onCancel && (
                                <MainButton
                                    onClicked={onCancel}
                                    title={`${intl.formatMessage({ id: LOCALIZATION.cancel })}`}
                                />
                            )}
                        </Box>
                    </Box>
                </Popup>
            )}
        </div>
    );
};

export default LoadingComponent;
