// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import ButtonsInRowElement, { ButtonsInRowArray } from '../../../Components/_BaseUI/ButtonsInRowElement';
import LoaderPopup from '../../../Components/_PopupControls/LoaderPopup';
import { getStreamingSettingsDefaultProfileConfigCached } from '../../../Data/NapiCameraConfig/fetch/defaultProfileConfig';
import { useFetchStreamingSettingsDefaultProfileConfig } from '../../../Data/NapiCameraConfig/hook/useDefaultProfileConfig';
import { usePingCalibrationMode } from '../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePingCalibrationMode';
import StreamingProfile from '../../../Data/NapiStreamingProfile';
import { NoStream, NO_STREAM } from '../../../Data/NapiStreamingProfile/constants';

import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import useMutationStreamingSettings, {
  ActionType as ActionTypeStreamingSettings
} from '../../../Data/NapiStreamingProfile/hook/useMutation';
import { getStreamingSettingsProfileOptionsCached } from '../../../Data/NapiStreamingProfileOptions/fetch';
import { useFetchStreamingSettingsProfileOptions } from '../../../Data/NapiStreamingProfileOptions/hook';
import { StreamingSettings, StreamingSettingsKey } from '../../../Data/NapiStreamingSettings';
import useMutationNapiSettings, { ActionType } from '../../../Data/NapiStreamingSettings/hook/useMutation';
import LOCALIZATION from '../../../Localization';
import uuid from '../../../Tools/uuid';
import StreamingConfigurationCol from '../StreamingConfigurationCol';
import StreamSettingsProfilesList from './List';
import StreamingSettingsProfilePopup from './StreamingSettingsProfilePopup';


type Props = Readonly<{
  isMaintenanceModeOn: boolean;
  isSuccessLoadedData: boolean;
  selectedProfileID: string;
  profiles?: StreamingProfile[];
  stopStreamingCallback?: (stopStreamingHandler: ()=>void) => void;
} & WrappedComponentProps>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  scrollableList: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    // overflowY: 'scroll',
    minHeight: '300pt',
  },
}));

const StreamSettingsProfiles: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const isLowWidth = useMediaQuery(theme.breakpoints.down('lg'));
  const [editItem, setEditItem] = React.useState<StreamingProfile | undefined>(undefined);
  const [isChangeConfigInProgress, setIsChangeConfigInProgress] = React.useState<boolean>(false);
  const [isChangeStreamingProfileInProgress, setIsChangeStreamingProfileInProgress] = React.useState<boolean>(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const { mutateAsync: mutateSettings } = useMutationNapiSettings();
  const { mutateAsync: mutateStreamingSettings } = useMutationStreamingSettings();
  useFetchStreamingSettingsProfileOptions();
  const streamingSettingsProfileOptions = getStreamingSettingsProfileOptionsCached();
  useFetchStreamingSettingsDefaultProfileConfig();
  const streamingSettingsDefaultProfileConfig = getStreamingSettingsDefaultProfileConfigCached();
  const { mutateAsync: pingMode } = usePingCalibrationMode()
  const handleSettingsChange = async (settings: Partial<StreamingSettings>) => {
    try {
      const state = await pingMode().catch(() => ({ state: false }))
      if (state.state) {
        alert(props.intl.formatMessage({ id: LOCALIZATION.cannot_be_switched_cos_of_calibration_mode }))
        return;
      }
      setIsChangeConfigInProgress(true);
      await mutateSettings({
        type: ActionType.Post,
        settings,
      }, {
        onSuccess: () => {
          setIsChangeConfigInProgress(false);
        },
      });
    } catch (error) {
      setIsChangeConfigInProgress(false);
      setIsChangeStreamingProfileInProgress(false);
      alert(error);
    }
  };
  const handleStreamingProfileEdit = async (streamingSettings: StreamingProfile) => {
    try {
      setIsChangeStreamingProfileInProgress(true);
      setIsEditPopupOpened(false);
      await mutateStreamingSettings({
        type: ActionTypeStreamingSettings.Post,
        streamingSettings,
      }, {
        onSuccess: () => {
          setIsChangeStreamingProfileInProgress(false);
          setEditItem(undefined);
        },
      });
    } catch (error) {
      setIsChangeStreamingProfileInProgress(false);
      alert(error);
    }
  };
  const handleStreamingProfileDelete = async (IDs: string[]) => {
    try {
      setIsChangeConfigInProgress(true);
      setEditItem(undefined);
      await mutateStreamingSettings({
        type: ActionTypeStreamingSettings.Delete,
        IDs,
      }, {
        onSuccess: () => {
          setIsChangeConfigInProgress(false);
        },
      });
    } catch (error) {
      setIsChangeConfigInProgress(false);
      alert(error);
    }
  };
  const handleSelectProfile = async (item: StreamingProfile | NoStream) => {
    if (window && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_actions }))) {
      await handleSettingsChange({ [StreamingSettingsKey.StreamingSettingsSelectedProfileID]: item.id });
    }
  };

  const stopStreamingHandler = async () => {
    handleSelectProfile(NO_STREAM());
  };
  if (props.stopStreamingCallback) {
    props.stopStreamingCallback(stopStreamingHandler)
  }

  const handleSubmitPopup = async (item: StreamingProfile) => {
    // if (item.config.dest) {
    //   item.config.dest.operator = [];
    //   item.config.dest.original = [];
    // }
    await handleStreamingProfileEdit(item);
  };
  const handleClosePopup = () => {
    setEditItem(undefined);
    setIsEditPopupOpened(false);
  };
  const handleAdd = (): void => {
    setIsEditPopupOpened(true);
  };
  const handleCopy = (item: StreamingProfile): void => {
    const newProfile = {
      ...item,
      id: uuid(),
      name: `${ item.name } <${props.intl.formatMessage({ id: LOCALIZATION.copy_item })}>`
    }
    if (newProfile?.custom?.destStreamBlock) {
      for( const block of newProfile?.custom?.destStreamBlock) {
        block.oAuthToken = undefined; // remove all oAuth markers when copy
      }
    }
    setEditItem(newProfile);
  };
  const handleDelete = async (item: StreamingProfile) => {
    await handleStreamingProfileDelete([item.id]);
  };

  const selectBestDefaultProfileName = (baseName: string): string => {
    if (props.profiles) {
      for (let i = 1; i < 100; i++) {
        const testName = `${baseName} ${i}`
        if (!props.profiles?.find(({ name }) => name === testName)) {
          return testName
        }
      }
    }
    return baseName
  }

  const buttons: ButtonsInRowArray = [
    {
      space :-1 ,
    },
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.add }),
      onClick: handleAdd,
    },
  ];

  return (
    <StreamingConfigurationCol
      onOptionBar={
        <ButtonsInRowElement
          id = "buttonsRow1"
          buttons={ buttons }
        />
      }
    >
      <Box
        className={ (isLowWidth) ? undefined : classes.scrollableList }
      >
        <StreamSettingsProfilesList
          profiles={ props.profiles }
          isMaintenanceModeOn={ props.isMaintenanceModeOn }
          isSuccessLoadedData={ props.isSuccessLoadedData }
          isChangeConfigInProgress={ isChangeConfigInProgress }
          selectedProfileID={ props.selectedProfileID }
          onSelect={ handleSelectProfile }
          onCopy={ handleCopy }
          onDelete={ handleDelete }
          onEdit={ setEditItem }
        />
        <LoaderPopup
          isVisible={ isChangeStreamingProfileInProgress }
          minTimeToShowingLoaderMS={ 1000 }
          header={ props.intl.formatMessage({ id: LOCALIZATION.stream_profile_apply_progress_header }) }
          text={ props.intl.formatMessage({ id: LOCALIZATION.stream_profile_apply_progress_text }) }
        />
      </Box>
      {
        (!isChangeStreamingProfileInProgress && (isEditPopupOpened || editItem) && streamingSettingsProfileOptions?.data && streamingSettingsDefaultProfileConfig?.data) &&
          <StreamingSettingsProfilePopup
              streamingSettingsProfileOptions={ streamingSettingsProfileOptions.data }
              defaultStreamingProfileConfig={ streamingSettingsDefaultProfileConfig.data }
              item={ editItem }
              newItemName={ selectBestDefaultProfileName(props.intl.formatMessage({ id: LOCALIZATION.stream_profile })) }
              onSubmit={ handleSubmitPopup }
              onClose={ handleClosePopup }
          />
      }
    </StreamingConfigurationCol>
  );
};


export default injectIntl(StreamSettingsProfiles);
