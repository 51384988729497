import { useMemo } from "react";
import { useZonePointCounts } from "./useZonePointCounts";

export const useStitchingCaltulcationAble = ({ uuid }: { uuid: string }) => {
  const { isLoading: countinZonePoints, data: zonePointsInfo } =
    useZonePointCounts({ uuid });
  const res = useMemo(() => {
    if (countinZonePoints || !zonePointsInfo?.length) return false;
    for (const zone of zonePointsInfo) {
      if (Number(zone ?? 0) < 10) {
        return false;
      }
    }
    return true;
  }, [countinZonePoints, zonePointsInfo]);
  return res;
};
