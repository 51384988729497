// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl, useIntl, WrappedComponentProps } from 'react-intl';

import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { cloneDeep, isNaN } from 'lodash';
import moment from 'moment';
import LoadingComponent from '../../../Components/Loader/index';
import StreamAutocompleteSelect from '../../../Components/_AutocompleteSelect/StreamAutocompleteSelect';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import { StaticLabelTypes } from '../../../Components/_BaseUI/FieldSet/FieldSetStaticLabel';
import MainButton from '../../../Components/_BaseUI/MainButton/index';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import MainCard from '../../../Components/_Layout/Cards/Main/Card';
import Popup from '../../../Components/_Layout/Popup';
import { GameType, SportType } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import { EPGFormatType, EPGFormatTypeArr, localizedEPGFormatType } from '../../../Data/EPG/EpgSchedule/fetch';
import useMutationEvent, { ActionType } from '../../../Data/EPG/EpgSchedule/fetch/useMutation';
import { useEpgRules } from '../../../Data/EPG/Rules/useEpgRules';
import { useFetchStreamingProfiles } from '../../../Data/NapiStreamingProfile/hook/index';
import { StreamingSettings } from '../../../Data/NapiStreamingSettings';
import { flushNapiStreamingSettingsCache } from '../../../Data/NapiStreamingSettings/fetch';
import { useNapiStreamingSettingsFetch } from '../../../Data/NapiStreamingSettings/hook';
import { ThemeVariables } from '../../../hooks/useTheme';
import LOCALIZATION from '../../../Localization';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import { GAME_TYPE_ID_ARR, localizedGameTypeById, localizedSportNameById, SPORT_ID_ARR } from '../AddEvent/helpers';
import { defaultRule } from './defaultRule';
import RuleComponent from './RuleComponent';
import { Rule } from './types';

const MainDesc = styled(Grid)`
    //styleName: c_body_P;
font-family: var(${ThemeVariables.font});
font-size: 14px;
font-weight: 400;
line-height: 17.07px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
`
type Props = Readonly<{
  streamProfileID?: string;
  // page: number;
  onSubmit: () => void;
  onClose: () => void;
} & WrappedComponentProps>;

const RulesHeader = styled(Grid)`
font-family: var(${ThemeVariables.font});
font-size: 18px;
font-weight: 700;
line-height: 21.94px;
letter-spacing: -0.015em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin: 0;
`

const useStyles = makeStyles((theme: Theme) => createStyles({
  contentRoot: {
    background: '#F5F6F8',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    },
  }
}))

const ImportEpgPopup: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();
  const classes = useStyles();
  const { mutateAsync: mutateConfig } = useMutationEvent();
  const { formatMessage } = useIntl()
  const [rules, setRules] = useState<Rule[]>([])
  const { data: rulesFeched, isLoading: loadingRules, clearCache: clearRulesCache } = useEpgRules()
  useEffect(() => {
    rulesFeched && setRules(rulesFeched)
  }, [rulesFeched])
  const updateRule = useCallback((rule: Rule) => {
    setRules((rules) => {
      const oldRuleIndex = rules.findIndex(v => v.id === rule.id);
      rules[oldRuleIndex] = rule;
      return cloneDeep(rules)
    })
  }, [setRules])

  const moveRuleUp = useCallback((index: number) => {
      setRules((rules) => {
        const updatedRules = [...rules];
        [updatedRules[index - 1], updatedRules[index]] = [updatedRules[index], updatedRules[index - 1]];
        return cloneDeep(updatedRules.map((v, id) => ({ ...v, id: id + 1 })));
      });
  }, []);

  const addRule = useCallback(() => {
    setRules(rules => {
      const updatedRules = [...rules];
      updatedRules.unshift({ ...defaultRule, id: -1 })

      return cloneDeep(updatedRules.sort((a, b) => a.id - b.id).map((v, id) => ({ ...v, id: id + 1 })))
    })
  }, [])

  const moveRuleDown = useCallback((index: number) => {
    setRules((rules) => {
      const updatedRules = [...rules];
        [updatedRules[index], updatedRules[index + 1]] = [updatedRules[index + 1], updatedRules[index]];
      return cloneDeep(updatedRules.map((v, id) => ({ ...v, id: id + 1 })));
    });
  }, []);

  const deleteRule = useCallback((index: number) => {
    setRules((rules) => rules.filter((_, i) => i !== index).sort((a, b) => a.id - b.id).map((v, id) => ({ ...v, id: id + 1 })));
  }, []);

  const [streamProfileID, setStreamProfileID] = React.useState<string | null | undefined>(undefined);
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);

  const [autoImportUrl, setAutoImportUrl] = React.useState<string | null>(null);
  const [autoImportPeriodHours, setAutoImportPeriodHours] = React.useState<number | null | undefined>(undefined);
  const [streamingSettings, setStreamingSettings] = React.useState<StreamingSettings>();
  const [epgFormatType, setEpgFormatType] = React.useState<EPGFormatType | null>(null);
  const [epgDefaultGameType, setEpgDefaultGameType] = React.useState<GameType | null>(null);
  const [epgDefaultSportType, setEpgDefaultSportType] = React.useState<SportType | null>(null);
  const streamingProfilesData = useFetchStreamingProfiles();

  const streamProfileOptions = (streamingProfilesData?.data?.data || [])
    .map(({ id, name }) => ({
      id,
      name: name,
    }));

  // streaming/settings.json load
  const streamingSettingsFetchState = useNapiStreamingSettingsFetch();
  React.useEffect(() => {
    if (streamingSettingsFetchState.status === 'success') {
      const settings = streamingSettingsFetchState.data?.data;
      if (settings && !streamingSettings) {
        setStreamingSettings(settings);

        // always set value. because else autoset from checkbox will do this
        setStreamProfileID(settings.epg_auto_import_streaming_profile_id);

        setEpgFormatType(settings.epg_auto_import_format_type as EPGFormatType);
        setEpgDefaultGameType(settings.epg_auto_import_default_game_type as GameType);
        setEpgDefaultSportType(settings.epg_auto_import_default_sport_type as SportType);

        if (!autoImportUrl && settings.epg_auto_import_url) {
          setAutoImportUrl(settings.epg_auto_import_url);
        }
        if (autoImportPeriodHours === undefined) {
          const perioadSec = parseInt(settings.epg_auto_import_period_sec || "0", 10);
          if (!isNaN(perioadSec)) {
            const periodInHours = Math.round((perioadSec / 60.0 / 60.0) * 10) / 10.0;
            setAutoImportPeriodHours(periodInHours);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [streamingSettingsFetchState.status, streamingSettingsFetchState.data?.data]);

  // clear cache
  React.useEffect(() => {
    return (
      () => {
        flushNapiStreamingSettingsCache();
        clearRulesCache()
      }
    );
  }, []);

  const isApplyDisabled = false;
  const handleSubmit = async () => {
    setIsLoaderShowing(true);
    try {
      await mutateConfig(
        {
          type: ActionType.ImportEpg,
          streamingProfileId: streamProfileID,
          // import by URL
          epgFileDataUrl: autoImportUrl || null,
          epgFileUpdatePeriodInSec: (autoImportPeriodHours || 0) * 60 * 60, // hours to sec
          epgFormatType: epgFormatType,
          epgDefaultGameType: epgDefaultGameType,
          epgDefaultSportType: epgDefaultSportType,
          rules,
        }, {
          onSuccess: () => {
            clearRulesCache()
            props.onSubmit();

          },
        },
      );

    } catch (error) {
      alert(error);
      props.onClose();
    }
  };
  const handleSetEpgInBase64 = async (epgInBase64?: string) => {
    setIsLoaderShowing(true);
    try {
      await mutateConfig(
        {
          type: ActionType.ImportEpg,
          streamingProfileId: streamProfileID,
          // import by file in base64
          epgFileDataInBase64: epgInBase64,
          epgFormatType: epgFormatType,
          epgDefaultGameType: epgDefaultGameType,
          epgDefaultSportType: epgDefaultSportType,
          rules,
        }, {
          onSuccess: () => {
            props.onSubmit();
          },
        },
      );
    } catch (error) {
      alert(error);
      props.onClose();
    }
  };

  const lastImportResultStr: string | undefined = (() => {
    if (streamingSettings) {
      var successAt = (streamingSettings.epg_auto_import_by_url_last_success_date_at) ? moment(streamingSettings.epg_auto_import_by_url_last_success_date_at) : undefined;
      var errorAt = (streamingSettings.epg_auto_import_by_url_last_error_date_at) ? moment(streamingSettings.epg_auto_import_by_url_last_error_date_at) : undefined;
      if (successAt && errorAt) {
        if (successAt.isBefore(errorAt)) {
          successAt = undefined;
        }
        else {
          errorAt = undefined;
        }
      }

      if (successAt) {
        successAt.locale(locale);
        const diff = successAt.diff(moment());
        const duration = moment.duration(diff).locale(locale);
        return `${props.intl.formatMessage({ id: LOCALIZATION.import_epg_last_import_success })} ${duration.humanize(true).capitalizeFirstLetter()} (${successAt.format('llll').capitalizeFirstLetter()})`;
      }
      else if (errorAt) {
        errorAt.locale(locale);
        const diff = errorAt.diff(moment());
        const duration = moment.duration(diff).locale(locale);
        return `${props.intl.formatMessage({ id: LOCALIZATION.import_epg_last_import_error })} ${duration.humanize(true).capitalizeFirstLetter()} (${errorAt.format('llll').capitalizeFirstLetter()})`;
      }
    }
    return undefined;
  })()

  var activeImportStateText = props.intl.formatMessage({ id: LOCALIZATION.import_epg_auto_import_disabled });
  var activeImportState: StaticLabelTypes = "error";
  if (streamingSettings && streamingSettings.epg_auto_import_url && streamingSettings.epg_auto_import_period_sec) {
    if (streamingSettings.epg_auto_import_url.length !== 0) {
      const perioadSec = parseInt(streamingSettings.epg_auto_import_period_sec, 10);
      if (!isNaN(perioadSec) && perioadSec > 60) {
        activeImportStateText = props.intl.formatMessage({ id: LOCALIZATION.import_epg_auto_import_active });
        activeImportState = "warning";
      }
    }
  }


  return (
    <Popup
      isMoreWide
      headerTitle={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_btn }) }
      isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
      isCloseDisabled={ isLoaderShowing }
      closeTitle={ props.intl.formatMessage({ id: LOCALIZATION.close_btn }) }
      onApply={ !isLoaderShowing ? handleSubmit : undefined }
      onClose={props.onClose}
      classes={classes}
    >
      <LoadingComponent isLoading={loadingRules || isLoaderShowing} ugly>

      <Grid
        item
        xs={ 12 }
      >
        <MainDesc className={css`
          margin: 20px;
        `} item xs={12}>
          <Grid item xs="auto">

            {props.intl.formatMessage({ id: LOCALIZATION.import_epg_notes })}
          </Grid>

        </MainDesc>

        <MainCard className={css`
          margin: 20px;
        `}>

          <Grid container padding={2}>
          {
            (isLoaderShowing) ?
            <>
            <br /><br /><Spinner /><br /><br />
            </>
              :
                <form
                  className={css`
                    width: 100%;
                  `}
                action={ window.location.href }
                encType={ 'multipart/form-Data' }
                onSubmit={ handleSubmit }
              >

                <FieldSet
                  key={ 'Stream' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_def_stream_type }) }
                  tooltipQuestionText={props.intl.formatMessage({ id: LOCALIZATION.import_epg_def_stream_type_help })}
                  input={ {
                    type: FieldSetInputType.Other,
                    children: (
                      <StreamAutocompleteSelect
                        selectedStreamProfileID={ streamProfileID }
                        autoselectFirstIfNoId={ (!props.streamProfileID) }
                        onSelect={ (streamProfileID) => ( setStreamProfileID(streamProfileID) ) }
                      />
                    ),
                  } }
                />
                <FieldSet
                  key={ 'Default Game Type' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_game_type }) }
                  // helperText={ 'Short team name' }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_game_type_help }) }
                  input={ {
                    type: FieldSetInputType.Select,
                    value: (epgDefaultGameType) ? epgDefaultGameType as string : GameType.FreeTime,
                    options: GAME_TYPE_ID_ARR.map((gameTypeId) => ( {id: gameTypeId, name: localizedGameTypeById(gameTypeId) } )),
                    onChange: (gameType: string) => ( setEpgDefaultGameType(gameType as GameType)),
                  } }
                />
                <FieldSet
                  key={ 'Default Sport Type' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_sport_type }) }
                  // helperText={ 'Short team name' }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_default_sport_type_help }) }
                  input={ {
                    type: FieldSetInputType.Select,
                    value: (epgDefaultSportType) ? epgDefaultSportType as string : SportType.Unknown,
                    options: SPORT_ID_ARR.map((sportId) => ( {id: sportId, name: localizedSportNameById(sportId) } )),
                    onChange: (sportType: string) => ( setEpgDefaultSportType(sportType as SportType) ),
                  } }
                />
                <FieldSet
                    key={ 'Import File Type' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_file_type }) }
                    // helperText={ 'Short team name' }
                    input={ {
                      type: FieldSetInputType.Select,
                      value: (epgFormatType) ? epgFormatType as string : EPGFormatType.Xmltv,
                      options: EPGFormatTypeArr.map((epgType) => ( {id: epgType, name: localizedEPGFormatType(epgType) } )),
                      onChange: (type: string) => ( setEpgFormatType(type as EPGFormatType) ),
                    } }
                />
                <FieldSet
                  key={ 'Import File' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file_help })}
                  input={ {
                    type: FieldSetInputType.File,
                    fileFormatCheckType: ["text/xml", "application/json"],
                    invalidFileFormatMsg: props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file_btn }),
                    buttonText: props.intl.formatMessage({ id: LOCALIZATION.import_epg_upload_file_btn }),
                    onChange: handleSetEpgInBase64,
                  } }
                />
                <FieldSet
                  key={ 'AutoImportState' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_auto_import }) }
                  // helperText={ 'Team name' }
                  input={ {
                    type: FieldSetInputType.StaticLabel,
                    valueType: activeImportState,
                    value: activeImportStateText,
                  } }
                />
                <FieldSet
                  key={ 'AutoImportUrl' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_url }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_url_help }) }
                  input={ {
                    type: FieldSetInputType.Text,
                    value: autoImportUrl || "",
                    onChange: (url: string) => ( setAutoImportUrl(url) ),
                  } }
                />
                <FieldSet
                  key={ 'AutoImportPeriod' }
                  label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_update_period }) }
                  tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_update_period_help }) }
                  input={ {
                    type: FieldSetInputType.Float,
                    value: (autoImportPeriodHours === undefined) ? 24 : (autoImportPeriodHours || 0),
                    min: 0,
                    max: 168, // 7 days
                    onChange: (period: number | null) => ( setAutoImportPeriodHours(period) ),
                  } }
                />
                { (lastImportResultStr) &&
                  <FieldSet
                    key={ 'LastDownloadResult' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.import_epg_last_import_result }) }
                    input={ {
                      type: FieldSetInputType.StaticLabel,
                      value: lastImportResultStr,
                    } }
                  />
                }
              </form>
          }
        </Grid>
        </MainCard>
        <MainCard className={css`
          margin: 20px;
        `}>
          <Grid gap={2} padding={2} container>
            <Grid container >
              <RulesHeader item className={css`display: flex;    align-items: center;`} xs>
                {formatMessage({ id: LOCALIZATION.import_epg_rules_rules })}

              </RulesHeader>
              <Grid xs={'auto'}>
                <MainButton onClicked={addRule}>
                  {formatMessage({ id: LOCALIZATION.import_epg_rules_add_rule })}
                </MainButton>
              </Grid>
            </Grid>


            {rules.sort((a, b) => a.id - b.id).map((rule, i, { length }) =>
              <Grid item xs={12} gap={2} key={rule.id}><RuleComponent
                isLast={i === length - 1}
                isFirst={i === 0}
                onChange={updateRule}
                onMoveUp={() => moveRuleUp(i)}
                onMoveDown={() => moveRuleDown(i)}
                onDelete={() => deleteRule(i)}
                rule={rule}
                streamProfileOptions={streamProfileOptions}
                eventTypeOptions={GAME_TYPE_ID_ARR.map((gameTypeId) => ({ id: gameTypeId, name: localizedGameTypeById(gameTypeId) }))}
              /></Grid>
          )}
        </Grid>
        </MainCard>
        </Grid>
      </LoadingComponent>

    </Popup>
  );
};


export default injectIntl(ImportEpgPopup);
