// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createStyles, makeStyles } from '@mui/styles';
import { Key } from 'react';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Link, SxProps, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { MenuEl, MenuID } from '../../../Menu/Data/MenuElement';
import { getMenuLinkFromMenuArray, MenuElPathArr } from '../../../Menu/Data/MenuStructure';
import MenuDropdownButton, { MenuDropdownAction } from '../../_BaseUI/MenuDropdownButton';
import HorizGrid from '../HorizGrid';
import VertGrid from '../VertGrid';

type Props<T> = Readonly<{
  id?: Key | null | undefined;
  pathMenuArr: MenuElPathArr
  removeMenuIdsFromMenu?: (MenuID | undefined)[]
  sx?: SxProps<Theme>
  onEditSecondLineClick?: () => void;

  // must define secondLineMenuItem if used secondLineMenuActions
  secondLineMenuItem?: T;
  secondLineMenuActions?: MenuDropdownAction<T>[]
}>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  firstLine: {
    fontWeight: Sizes.regularWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.heading2,
    color: Colors.headingMain
  },
  separator: {
    fontWeight: Sizes.regularWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.heading2,
    paddingTop: '3pt',
    paddingLeft: '3pt',
    paddingRight: '3pt',
    color: Colors.headingMain
  },
  secondLine: {
    fontWeight: Sizes.boldWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.caption,
    color: Colors.headingMain
  },
  secondLineEditButton: {
    color: Colors.buttonBlue,
    marginLeft: 1,
  }
}));

const PageHeader2Row = <T, >(props: Props<T>) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  if (!props.pathMenuArr || props.pathMenuArr.length === 0) {
    return null;
  }

  var firstLineMenuArr = [...props.pathMenuArr];
  if (props.removeMenuIdsFromMenu) {
    firstLineMenuArr = firstLineMenuArr.filter((menuEl) => ( !props.removeMenuIdsFromMenu?.includes(menuEl?.id) ))
  }

  const secondLineMenu = firstLineMenuArr.pop();
  const secondLineEl = (
    <div className={ classes.secondLine } >
      {(props.secondLineMenuActions?.length && props.secondLineMenuItem) &&
        <MenuDropdownButton<T>
          menuButtonIcon= { MenuIcon }
          menuButtonEdge= { "start" }
          item={ props.secondLineMenuItem }
          customActions={ props.secondLineMenuActions }
        />
      }
      { secondLineMenu?.title }
      {(props.onEditSecondLineClick) &&
        <IconButton
            edge="start"
            className={ classes.secondLineEditButton }
            onClick={ props.onEditSecondLineClick }
            aria-label="close"
          >
            <DriveFileRenameOutlineIcon />
        </IconButton>
      }
    </div>
  )
  if (firstLineMenuArr.length === 0) { // only have second line. show it
    return (
      <Box
      key={ props.id }
      sx={ {
        ...props.sx
      }}>
        {secondLineEl }
      </Box>
    )
  }

  // have 2 lines
  const firstLineHandler = (menuEl: MenuEl | undefined, index: number, array:(MenuEl | undefined)[]) => {

    const menuLink = (menuEl) ? getMenuLinkFromMenuArray(props.pathMenuArr, menuEl?.id) :  "";
    var outArr = [
      <Link key={index} component={RouterLink} className={classes.firstLine} underline="hover" to={`${menuLink}`}>
        { menuEl?.title }
      </Link>
    ]

    if (index < (array.length - 1)) { // add separator > if not last element
      const separator =  ">"
      outArr.push(
        <div key={ index} className={ classes.separator } >
          { separator }
        </div>
      )
    }

    return outArr
  }

  return (
    <Box
    key={ props.id }
    sx={ {
      marginTop: -2, // make to fix title on one place
      ...props.sx
    }}>
      <VertGrid spacing="2pt" >
        <HorizGrid>
          { firstLineMenuArr.flatMap(firstLineHandler) }
        </HorizGrid>
        {secondLineEl }
      </VertGrid>
    </Box>
  )
};


export default PageHeader2Row;
