import { useMemo } from "react";
import { ExposedConfigurationID } from "../ExposedConfigurationID.enum";
import { useBaseConfig } from "./useBaseConfig";
import { useCameraConfig } from "./useCameraConfig";
import { useIndividualCameraConfig } from "./useIndividualCameraConfig";
import { useOverlaysConfig } from "./useOverlays";
import { useStreamingLegacyConfig } from "./useStreamingLegacyConfig";
import { useTotalConfig } from "./useTotalConfig";
import { useUndistortionConfig } from "./useUndistortion";

export const useConfigByName = (
  name?: ExposedConfigurationID | string | undefined
) => {
  const baseConfig = useBaseConfig({ options: { enabled: false } });
  const cameraConfig = useCameraConfig({ options: { enabled: false } });
  const individualCameraConfig = useIndividualCameraConfig({
    options: { enabled: false },
  });
  const overlaysConfig = useOverlaysConfig({ options: { enabled: false } });
  const undistortionConfig = useUndistortionConfig({
    options: { enabled: false },
  });
  const totalConfig = useTotalConfig({
    options: { enabled: false },
  });

  const streamingLegacyConfig = useStreamingLegacyConfig({
    options: { enabled: false },
  });
  const hook = useMemo(
    () =>
      [
        {
          hook: baseConfig,
          name: ExposedConfigurationID.useBaseConfig,
        },
        {
          hook: cameraConfig,
          name: ExposedConfigurationID.useCameraConfig,
        },
        {
          hook: individualCameraConfig,
          name: ExposedConfigurationID.useIndividualCamera,
        },
        {
          hook: overlaysConfig,
          name: ExposedConfigurationID.useOverlays,
        },
        {
          hook: undistortionConfig,
          name: ExposedConfigurationID.useUndistortion,
        },
        {
          hook: totalConfig,
          name: ExposedConfigurationID.useConfig,
        },
        {
          hook: streamingLegacyConfig,
          name: ExposedConfigurationID.useStreamingLegacy,
        },
      ].find((hook) => hook.name === name)?.hook,
    [
      baseConfig,
      cameraConfig,
      individualCameraConfig,
      name,
      overlaysConfig,
      undistortionConfig,
      totalConfig,
      streamingLegacyConfig,
    ]
  );
  return (hook ?? {}) as Partial<{
    set: (value: string) => Promise<any>;
    data: object;
    fetch: () => Promise<any>;
  }>;
};
