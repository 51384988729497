import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";
import { individualCameraCacheKey } from "../useIndividualCamera";

const mutationQl = gql`
  mutation UpdateIndividualCameraConfig(
    $camera: Int!
    $patch: IndividualCameraConfigInput!
  ) {
    updateIndividualCameraConfig(camera: $camera, individualCamera: $patch)
  }
`;

interface IResponse {
  uuid: string;
}

interface Props {}

export interface UpdateIndividualCameraProps {
  camera: number;
  patch: {
    exposure_compensation: number | null;
    ae_region: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    } | null;
    awb_region: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    } | null;
    color_saturation: number | null;
    color_saturation_bias: number | null;
    brightness: number | null;
    contrast: number | null;
    adjust_color: {
      r: number;
      b: number;
      g: number;
    } | null;
  };
}

export const useUpdateIndividualCamera = (props?: Props) => {
  const queryClient = useQueryClient();
  const client = useCameraHandler() ?? new GraphQLClient("");
  const request = (props: UpdateIndividualCameraProps) =>
    client.request<IResponse>(mutationQl, { ...props });
  const mutation = useMutation(request);

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const copy: UpdateIndividualCameraProps = JSON.parse(JSON.stringify(props));
    const { r, g, b } = copy.patch.adjust_color ?? {};
    copy.patch.adjust_color = {
      r: Number(r) / 255,
      g: Number(g) / 255,
      b: Number(b) / 255,
    };
    const res = await mutation.mutateAsync(copy);
    queryClient.invalidateQueries(individualCameraCacheKey(copy.camera));
    return res;
  };

  return {
    ...mutation,
    mutate: undefined,
    mutateAsync,
  };
};
