import { createContext, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";


const useCalibrationSetterMode = () => {
  const [value, set] = useState<boolean | null>(null);
  const [undistordDisabled, setUndistordDisabled] = useState<boolean>(false);
  const [runActiveUndistord, setRunActiveUndistord] = useState<boolean>(true);

  useEffect(() => {
    console.trace(value)
  }, [value])

  return { setMode: set, mode: value, setUndistordDisabled, undistordDisabled, setRunActiveUndistord, runActiveUndistord };
};

const CalibrationModeContext = createContext<ReturnType<typeof useCalibrationSetterMode>>({
  setUndistordDisabled: function(value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
  setMode: function(value: SetStateAction<boolean | null>): void {
    throw new Error("Function not implemented.");
  },
  setRunActiveUndistord: function(value: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
  mode: null,
  undistordDisabled: false,
  runActiveUndistord: true,
});


export const CalibrationModeProvider = ({ children }: { children: ReactNode }) => {
  const globalState = useCalibrationSetterMode();

  return (
    <CalibrationModeContext.Provider value={globalState}>
      {children}
    </CalibrationModeContext.Provider>
  );
};

export const useCalibrationMode = () => useContext(CalibrationModeContext);
