import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import { useMemo } from "react"
import { CameraIntervalImage } from "../../../../../Components/CameraIntervalImage"
import LoadingComponent from "../../../../../Components/Loader"
import { useCameraCommonSettings } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"

const MainContainer = styled(Grid)`
`

export const PanoramaStitchingByActiveCoefficents = () => {
    const { data: commonSettings } = useCameraCommonSettings()
    const camerasCount = useMemo(() => commonSettings?.configState.config.camera["num-cameras"], [commonSettings?.configState.config.camera["num-cameras"]])

    return <>
        <LoadingComponent isLoading={!camerasCount}>

            {camerasCount && <MainContainer xs={12} className={css`
            aspect-ratio: ${camerasCount * 16}/9;
        `} container>
                <CameraIntervalImage className={css`
                    width:100%;
                `} width={1200} interval={5_000} camera={"full_frame_panorama"} />
            </MainContainer>}
        </LoadingComponent>

    </>
}