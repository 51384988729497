// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import React, { ReactNode } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { FieldSetInputType } from '.';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { useStylesInputBox } from './FieldSetText';

type FieldSetSelectOptionValue<T extends string = string> = Readonly<T>;
export type FieldSetSelectOption<T extends string = string> = Readonly<{
  name: string;
  id: T;
}>;
type ChangeSelect<T extends string = string> = (value: FieldSetSelectOptionValue<T>) => void;

export type FieldSetSelect<T extends string = string> = Readonly<{
  /** if defined then constrol state is stored in browser session and recovered on control present.  */
  sessionStoreKey?: string;
  /** show reset (x) button when some value selected */
  showResetIfSelected?: boolean;
  type: FieldSetInputType.Select;
  label?: string;
  value: FieldSetSelectOptionValue<T>;
  children?: JSX.Element;
  options: FieldSetSelectOption[];
  variant?: 'standard' | 'outlined' | 'filled';
  sx?: SxProps<Theme>;
  onChange?: ChangeSelect<T>;
}>;

type Props<T extends string = string> = Readonly<{
  input: FieldSetSelect<T>;
  sx?: SxProps<Theme>;
}>;

export function FieldSetSelectComponent_StoredValue(sessionStoreKey: string){
  try {
    if (sessionStoreKey?.length) {// if sessionStoreKey enabled then recover stored value from session storage only if no active value
      const storedValue = window.sessionStorage.getItem("FieldSetSelectComponent-"+sessionStoreKey);
      if (storedValue !== null) {
        return storedValue
      }
    }
  } catch(ignore) {}
  return undefined
}

const FieldSetSelectComponent: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStylesInputBox({ fontFamily: font });
  const handleSelectValueChanged = (event: SelectChangeEvent<string>, child: ReactNode) => {
    const value = (event.target as HTMLInputElement).value || '';
    setNewValue(value);
  };
  const setNewValue = (value: string) => {
    if (props.input.sessionStoreKey?.length) {// if enabled then recover stored value from session storage
      window.sessionStorage.setItem("FieldSetSelectComponent-"+props.input.sessionStoreKey, value);
    }
    (props.input as FieldSetSelect).onChange?.(value);
  };

  const showResetValue = (props.input.showResetIfSelected && props.input.value && props.input.value !== "none");

  const handleResetValueClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    setNewValue("")
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box sx = {{ minWidth: 100, ...props.sx  }}>
      <FormControl
        fullWidth
        size="small"
      >
        <InputLabel id="select-combo-label">{ props.input.label }</InputLabel>
        <Select
          IconComponent={ (showResetValue) ? Box : ArrowDropDownIcon }
          fullWidth
          labelId="select-combo-label"
          id="select-combo-select"
          variant={ props.input.variant ?? "outlined"}
          label={ props.input.label }
          value={ props.input.value }
          defaultValue={ props.input.value }
          inputProps={ { className: classes.input } }
          onChange={ handleSelectValueChanged }
          sx={{
            ...props.input.sx,
            cursor: 'pointer',
            background: "white",

            '& .MuiSelect-select': {
              cursor: 'pointer',
              background: "none",
            }
          }}
          endAdornment={(
            <InputAdornment
            position="end"
            disablePointerEvents={ !showResetValue }
            >
              {
                (showResetValue) ?
                  <IconButton
                  edge="end"
                  color="warning"
                  aria-label="reset"
                  component="label"
                  onClick= { handleResetValueClick }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                :
                  null
              }
            </InputAdornment>
          )}
        >
          {
            (props.input.options || [])
              .map((option: FieldSetSelectOption) => (
                <MenuItem
                  key={ option.id }
                  value={ option.id }
                  sx={ { width: '100%' } }
                >
                  { option.name }
                </MenuItem>
              ))
          }
        </Select>
        { props.input.children }
      </FormControl>
    </Box>
  );
};


export default FieldSetSelectComponent;
