import { javascript } from "@codemirror/lang-javascript";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import CodeMirror from '@uiw/react-codemirror';
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader";
import FieldSet, { FieldSetInputType } from "../../../../../Components/_BaseUI/FieldSet";
import MainButton from "../../../../../Components/_BaseUI/MainButton";
import { useActiveStitchingCoefficents } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useActiveStichingCoefficents";
import { useFirstDefinedValue } from "../../../../../hooks/useFirstDefined";
import LOCALIZATION from "../../../../../Localization";

const MainContainer = styled(Grid)`

`




export const ActiveCoefficentsForm = ({ uuid }: { uuid: string }) => {
    const { data: activeCoefficents, isLoading } = useActiveStitchingCoefficents({ uuid })
    const { formatMessage } = useIntl();
    const firstDefined = useFirstDefinedValue(activeCoefficents)
    const [value, setValue] = useState<string>()
    const [blending, setBlending] = useState<number>(0)
    useEffect(() => {
        firstDefined && setValue(JSON.stringify(firstDefined, undefined, 2))
    }, [firstDefined])
    return <>
        <LoadingComponent isLoading={isLoading}>

            <MainContainer xs={12} container>
                <CodeMirror
                    className={css`
            width: 100%;
          `}
                    value={value}
                    // height="200px"
                    extensions={[javascript({ jsx: true })]}
                    onChange={(value, viewUpdate) => {
                        setValue(value)
                    }}

                />
                <FieldSet hideSeparator label={`Blending width , px`} input={{ type: FieldSetInputType.Number, max: 10, min: 0.1, value: blending, onChange: (v) => setBlending(Number(v ?? 0)) }} inputSize={"auto"} labelSize={"auto"} />
                <MainButton className={css`
                width: auto;
                margin: auto;
                `} title={formatMessage({ id: LOCALIZATION.apply })} />
        </MainContainer>
        </LoadingComponent>

    </>
}