// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';


type Props = Readonly<{
  heading: string;
}>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  page_heading: {
    fontWeight: Sizes.boldWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.caption,
    color: Colors.headingMain
  }
}));

const PageHeader: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  return (
    <div
      className={ classes.page_heading }
    >
      { props.heading }
    </div>
  )
};


export default PageHeader;
