// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Grid, SxProps, Theme } from '@mui/material';
import React from 'react';

import { createStyles, makeStyles } from '@mui/styles';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import MainCard from '../../../Components/_Layout/Cards/Main/Card';
import MainCardContent from '../../../Components/_Layout/Cards/Main/Content';
import VertGrid from '../../../Components/_Layout/VertGrid';
import AnalyticsGameArchive, { AnalyticsGameShared } from '../../../Data/Analytics/AnalyticsGameArchive/AnalyticsGameArchive';
import LOCALIZATION from '../../../Localization';

import { Colors } from '../../../Configuration/Styles/Colors';
import { Sizes } from '../../../Configuration/Styles/Sizes';

import { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import FieldSetSelectComponent from '../../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import AnalyticsHorizBarLineChartView from '../../../Components/_ChartsAndBars/AnalyticsHorizBarLineChartView';
import { AnalyticsHorizBarVisualiseMode, analyticsHorizBarVisualiseModeName, ANALYTICS_HOR_BAR_VISUALISE_MODE_ARR, BarLineChartItem, BarLineChartStatType } from '../../../Components/_ChartsAndBars/AnalyticsHorizBarLineChartView/helper';
import AnaliticsTeamScoreInfoLineView from '../../../Components/_Layout/AnaliticsTeamScoreInfoLineView';
import HorizGrid from '../../../Components/_Layout/HorizGrid';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { analyticsAnalyticsConvertedDataTypeName, AnalyticsConvertedData, AnalyticsConvertedDataType, analyticsConvertedValueFormated } from '../../../Data/Analytics/AnalyticsConverters';
import { GetTeamRefById } from '../../../Data/Analytics/AnalyticsTeamRef/hook';
import { AnalyticsStatGranularity } from '../../../Data/Analytics/AnalyticsTypes';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  titleText: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.caption,
    fontWeight: Sizes.boldWeight,
    color: Colors.mainTitle,
  },
  icon: {
    display: 'flex',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    border: `1px solid ${Colors.analyticsCircleBorder}`,
    // backgroundColor: '#FFFFFF',
    marginRight: '12pt',
  },
  valueText: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontWeight: Sizes.regularWeight,
    textAlign: 'center'
  },
}));


type Props = Readonly<{
  gameResultInfo?: AnalyticsGameArchive
  data: BarLineChartItem[];
  granularity: AnalyticsStatGranularity;
  allConvertedData: AnalyticsConvertedData;
  availableStats: BarLineChartStatType[];
  mainDataIndex: AnalyticsConvertedDataType;
  secondDataIndex?: AnalyticsConvertedDataType;
  getTeamRefByExternalId: GetTeamRefById | undefined;
  title?: string;
  sx?: SxProps<Theme>;
} & WrappedComponentProps>;

const GameStatBartLineChartCard: React.FC<Props> = (props: Props) => {
  const {mainDataIndex} = props;
  const [visualiseMode, setVisualiseMode] = React.useState<AnalyticsHorizBarVisualiseMode>(AnalyticsHorizBarVisualiseMode.Bar);
  const [secondDataIndex, setSecondDataIndex] = React.useState<AnalyticsConvertedDataType | undefined>(props.secondDataIndex);

  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const teamName = (teamId: string | undefined) => {
    return props.getTeamRefByExternalId?.(teamId)?.name || teamId || ""
  }
  const teamShortName = (teamId: string | undefined) => {
    return props.getTeamRefByExternalId?.(teamId)?.shortName || teamId || ""
  }

  const visualiseModeOptions = ANALYTICS_HOR_BAR_VISUALISE_MODE_ARR.map((mode) => {
    return ({
      id: mode,
      name: analyticsHorizBarVisualiseModeName(mode),
    });
  });

  const handleVisualiseModeChange = (value: string) => {
    setVisualiseMode(value as AnalyticsHorizBarVisualiseMode);
  };

  let compateStatOptions:{id: string, name: string}[] = props.availableStats.map((item) => {
    return ({
      id: item.index,
      name: item.name,
    });
  });
  compateStatOptions.unshift({
    id: "none",
    name: "-",
  })

  const handleCompateStatChange = (value: string) => {
    setSecondDataIndex((value === "none") ? undefined : value as AnalyticsConvertedDataType);
  };


  let team1ValueStr = analyticsConvertedValueFormated(props.allConvertedData[mainDataIndex]?.team1);
  let team2ValueStr = analyticsConvertedValueFormated(props.allConvertedData[mainDataIndex]?.team2);
  const mainDataIndexName = analyticsAnalyticsConvertedDataTypeName(mainDataIndex, props.allConvertedData[mainDataIndex]);
  let secondDataIndexName = undefined
  if (secondDataIndex) {
    const team1SecondValue = analyticsConvertedValueFormated(props.allConvertedData[secondDataIndex]?.team1);
    const team2SecondValue = analyticsConvertedValueFormated(props.allConvertedData[secondDataIndex]?.team2);
    team1ValueStr = `${team1ValueStr} / ${team1SecondValue}`
    team2ValueStr = `${team2ValueStr} / ${team2SecondValue}`
    secondDataIndexName = analyticsAnalyticsConvertedDataTypeName(secondDataIndex, props.allConvertedData[secondDataIndex]);
  }

  return (
    <MainCard>
      <Grid
        container direction="row"
        alignItems='flex-end'
          justifyContent="space-between"
          sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 2 }}
        >
          <Grid item xs={ 'auto' }>
            <VertGrid spacing={ 2 } >
              <Box className={ classes.titleText }>
                { props.title }
              </Box>
              <HorizGrid spacing={ 2 }>
                <FieldSetSelectComponent
                  input={ {
                    type: FieldSetInputType.Select,
                    label: props.intl.formatMessage({ id: LOCALIZATION.analytics_compare }),
                    value: secondDataIndex || "none",
                    sx: { minWidth: 150 },
                    options: compateStatOptions,
                    onChange: handleCompateStatChange,
                  } }
                />
                <FieldSetSelectComponent
                  input={ {
                    type: FieldSetInputType.Select,
                    label: props.intl.formatMessage({ id: LOCALIZATION.analytics_visualize_mode }),
                    value: visualiseMode || "bar",
                    sx: { minWidth: 150 },
                    options: visualiseModeOptions,
                    onChange: handleVisualiseModeChange,
                  } }
                />
              </HorizGrid>
            </VertGrid>
          </Grid>
          <Grid item xs={ 12 } lg = { 6 } xl = { 4 } >
            <VertGrid spacing = { 0 }>
            <AnaliticsTeamScoreInfoLineView
                valueAlign='right'
                title={ teamName(props.gameResultInfo?.team1)  }
                value={ team1ValueStr }
                iconCircleMarkerColor={{
                  teamIndex: 1,
                  team1ShortName: teamShortName(props.gameResultInfo?.team1),
                  team2ShortName: teamShortName(props.gameResultInfo?.team2),
                }}
                valueFontSize={ Sizes.analyticsScore }
              />
            <AnaliticsTeamScoreInfoLineView
                valueAlign='right'
                title={ teamName(props.gameResultInfo?.team2)  }
                value={ team2ValueStr }
                iconCircleMarkerColor={{
                  teamIndex: 2,
                  team1ShortName: teamShortName(props.gameResultInfo?.team1),
                  team2ShortName: teamShortName(props.gameResultInfo?.team2),
                }}
                valueFontSize={ Sizes.analyticsScore }
              />
            </VertGrid>
          </Grid>
        </Grid>

      <MainCardContent>

        <AnalyticsHorizBarLineChartView
          granularity={props.granularity}
          visualiseMode={ visualiseMode }
          value1Name={ teamShortName(props.gameResultInfo?.team1)  }
          value2Name={ teamShortName(props.gameResultInfo?.team2)  }
          value1Color={ AnalyticsGameShared.team1Color }
          value2Color={ AnalyticsGameShared.team2Color }
          data= {props.data}
          mainDataIndex={ mainDataIndex }
          mainDataIndexName={ mainDataIndexName }
          secondDataIndex={ secondDataIndex }
          secondDataIndexName={ secondDataIndexName }
        />
      </MainCardContent>
    </MainCard>
  )
};

export default injectIntl(GameStatBartLineChartCard);
