// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export const PIN_TYPES = [
  ["ff0000", true],
  ["ff8700", true],
  ["ffd300", false],
  ["deff0a",false],
  ["a1ff0a",false],
  ["0aff99",false],
  ["0aefff",false],
  ["147df5", true],
  ["580aff", true],
  ["be0aff", true],
].map(([color, isDark]) => ({ name: color, color: `#${color}`, isDark }));