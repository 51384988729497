// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Checkbox, Grid } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import FieldSet, { FieldSetInputType } from '../../../../../Components/_BaseUI/FieldSet';
import { ScoreboardMode } from '../../../../../Data/NapiStreamingProfile/index';
import LOCALIZATION from '../../../../../Localization';
import BoxComponent from '../../../Box';
import FieldSetWrapper from '../FieldSetWrapper';
import { ItemStreamProps } from '../index';


type Props = Readonly<{
  item: ItemStreamProps;
  onChange: (item: ItemStreamProps) => void;
}>;



const ScoreboardStream = ({ value, setValue }: {
  value: ScoreboardMode,
  setValue: (value: ScoreboardMode) => void
}) => {
  const intl = useIntl()

  return <FieldSetWrapper>
    <FieldSet
        key={'scoreboard_mode'}
        label={`${intl.formatMessage({ id: LOCALIZATION.scoreboard_mode })}:`}
      input={{
        type: FieldSetInputType.Select,
        value,
        options: [
          {
            id: ScoreboardMode.AUTO,
            name: intl.formatMessage({ id: LOCALIZATION.scoreboard_mode_auto }),
          },
          {
            id: ScoreboardMode.OFF,
            name: intl.formatMessage({ id: LOCALIZATION.scoreboard_mode_off }),
          },
          {
            id: ScoreboardMode.ONLY_SCOREBOARD,
            name: intl.formatMessage({ id: LOCALIZATION.scoreboard_mode_only_scoreboard }),
          },
          {
            id: ScoreboardMode.ONLY_DESCRIPTION,
            name: intl.formatMessage({ id: LOCALIZATION.scoreboard_mode_only_description }),
          },

        ],
        onChange: v => setValue(v as ScoreboardMode),
      }}
    />
  </FieldSetWrapper>

}


const SecondaryScoreboardStream: React.FC<Props> = (props: Props) => {

  const intl = useIntl()
  const handleChangeStoreVideoToSsd = (event: any, storeScoreboardVideoToSsd: boolean) => {
    const changed = {
      ...props.item,
      custom: {
        ...props.item?.custom,
        storeScoreboardVideoToSsd,
      },
    };

    props.onChange(changed);
  };
  const handleChangeSyncPanoramaToServer = (event: any, syncScoreboard: boolean) => {
    const changed = {
      ...props.item,
      custom: {
        ...props.item?.custom,
        syncScoreboard,
      },
    };

    props.onChange(changed);
  };



  const handleChangeScoreboardMode = (scoreboardMode: ScoreboardMode) => {
    const changed = {
      ...props.item,
      custom: {
        ...props.item?.custom,
        scoreboardMode,
      },
    };

    props.onChange(changed);
  };



  return (
    <BoxComponent
      title={intl.formatMessage({ id: LOCALIZATION.stream_video_scoreboard_title })}
    >
      <Grid
        container
        spacing={4}
      >
        <ScoreboardStream setValue={handleChangeScoreboardMode} value={props.item.custom?.scoreboardMode ?? ScoreboardMode.AUTO} />
        <FieldSetWrapper>
          <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.stream_video_store_local_file })}
            input={{
              type: FieldSetInputType.Other,
              children: (
                <Checkbox
                  sx={{ marginLeft: -1 }}
                  defaultChecked={!!props.item.custom?.storeScoreboardVideoToSsd}
                  value={!!props.item.custom?.storeScoreboardVideoToSsd}
                  color="primary"
                  onChange={handleChangeStoreVideoToSsd}
                />
              ),
            }}
          />
        </FieldSetWrapper>
        <FieldSetWrapper>
          <FieldSet
            label={intl.formatMessage({ id: LOCALIZATION.stream_video_sync_to_server })}
            input={{
              type: FieldSetInputType.Other,
              children: (
                <Checkbox
                  sx={{ marginLeft: -1 }}
                  defaultChecked={!!props.item.custom?.syncScoreboard}
                  value={!!props.item.custom?.syncScoreboard}
                  color="primary"
                  onChange={handleChangeSyncPanoramaToServer}
                />
              ),
            }}
          />
        </FieldSetWrapper>


      </Grid>
    </BoxComponent>
  );
};


export default (SecondaryScoreboardStream);
