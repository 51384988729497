// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Grid } from '@mui/material';
import React from 'react';

import { Colors } from '../../../Configuration/Styles/Colors';
import { Sizes } from '../../../Configuration/Styles/Sizes';

import Card from '../../../Components/_Layout/Card';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';

type Props = Readonly<{
  title: string;
  children: any;
}>;




const BoxComponent: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  return <Grid
    item
    xs={ 12 }
  >
      <Card>
        {
        (props.title) &&
          <Box
            sx={{
              marginTop: -1,
              paddingBottom: 4,
              fontWeight: Sizes.boldWeight,
              fontFamily: font,
              fontSize: Sizes.heading,
              color: Colors.headingMain,
            }}
          >
            { props.title }
          </Box>
        }
        { props.children }
      </Card>
  </Grid>
};

export default BoxComponent;
