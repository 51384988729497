import { gql, GraphQLClient } from "graphql-request";
import { useMutation } from "react-query";
import { useNAPIGraphQLClient } from "../../../../../hooks/useNAPIGraphQLClient";
import { CalibrationInputImageType } from "../StitchinConfig.interface";

const mutationQl = gql`
  mutation CalculateStitching($uuid: String!) {
    calculateStitching(uuid: $uuid) {
      info {
        averageError
        pairError
        detailedError
        commonAngle
        angles
        rotates
        pairRotateDiff
      }
      data {
        cylinder {
          kdata
          rotate
        }
        crop
      }
    }
  }
`;
export interface StitchingCalculations {
  calculateStitching: {
    info: {
      averageError: number;
      pairError: number[];
      detailedError: number[];
      commonAngle: number;
      angles: number[];
      rotates: number[];
      pairRotateDiff: number[];
    };
    data: {
      cylinder: {
        kdata: number[];
        rotate: number;
      }[];
      crop: {
        "dest-x": number;
        "dest-y": number;
        "src-x": number;
        "src-y": number;
        width: number;
        height: number;
      }[];
    };
  };
}

export interface CalibrationInput {
  images: {
    type: CalibrationInputImageType;
    index: number;
    width: number;
    height: number;
    points: { x: number; y: number }[];
  }[];
  offsets: { index: number; value: number }[];
}

type Request = { uuid: string };

const debouncedRequest = (patch: Request, client: GraphQLClient) =>
  client.request<StitchingCalculations>(mutationQl, patch);
export const useCalculateStitching = () => {
  const client = useNAPIGraphQLClient() ?? new GraphQLClient("");
  const request = (patch: Request) =>
    debouncedRequest(patch, client) ?? Promise.resolve(undefined);
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };
  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    return res;
  };
  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
