// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useState } from 'react';

import AppRoutes from './AppRoutes';
import Container from './Components/_Layout/Container';
import ContentWrapper from './Components/_Layout/ContentWrapper';
import { PlatformConfiguration } from './Configuration/PlatformConfiguration';
import AuthorisationManager from './Data/Auth/AuthorisationManager';
import { getCachedActiveCamera, isNoActiveCamera } from './Data/Camera/ActiveCamera/fetch';
import { useFetchActiveCamera } from './Data/Camera/ActiveCamera/hook';
import { CameraInstance } from './Data/Camera/HWCameraSettings/Camera';
import CameraContext from './Data/Camera/HWCameraSettings/fetch/Context';
import ApiSessionHandler from './Data/_Networking/AxiosFetch/Sessioning/Handlers';
import { AppLocale, useAppConfig } from './Localization/AppContextProvider/helpers';
import Menu from './Menu';
import MainAppBar from './Menu/AppBar';
import getMenuStructure from './Menu/Data/MenuStructure';
import ManagerServicePopup from './Pages/ManagerServicePopup';


type Props = Readonly<{}>;


const AppWrapper: React.FC<Props> = () => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [logoutReasonError, setLogoutReasonError] = useState<Error | undefined>(undefined);
  const [isAuthorised, setIsAuthorised] = useState<boolean>(AuthorisationManager.shared.isAuthorised());
  const { localization: { locale = AppLocale.Ru } } = useAppConfig();
  const menuStructure = getMenuStructure(locale);

  // const [activeCamera, setActiveCamera] = React.useState<Camera | undefined>(undefined);
  const fetchActiveCamera = useFetchActiveCamera();
  const isActiveCameraLoaded = !fetchActiveCamera.isLoading;
  const activeCameraData = getCachedActiveCamera();

  React.useEffect(() => {
    if (isActiveCameraLoaded) {
      const newActiveCamera = (!isActiveCameraLoaded || isNoActiveCamera(activeCameraData)) ? undefined : activeCameraData
      // setActiveCamera(newActiveCamera);

      if (newActiveCamera !== undefined) {// change active camera also reset previous camera settings
        const activeCameraInstance = CameraInstance.newCamera(newActiveCamera.name)
        CameraContext.use(activeCameraInstance)
      }
    }
  }, [isActiveCameraLoaded, activeCameraData]);

  const handleMenuOpenedToggle = (): void => {
    setIsMenuOpened(!isMenuOpened);
  };

  const onLoggedOut = (error?: Error) => {
    setLogoutReasonError(error);
    setIsAuthorised(false);
    document.location.replace('/');
  };
  const handleLogout = () => {
    // Нужно время, чтобы остановились сервисы забора метрик
    setTimeout(() => {
      AuthorisationManager.shared.logout(onLoggedOut);
    }, PlatformConfiguration.stopServicesDelay);
  };
  const handleSignedIn = () => {
    setLogoutReasonError(undefined);
    setIsAuthorised(true);
  };

  ApiSessionHandler.onHandleLogout(onLoggedOut);

  return (
    <Container>
      <ManagerServicePopup />
      {
        (isAuthorised) &&
          <MainAppBar
            isMenuOpened={ isMenuOpened }
            onMenuOpenedToggle={ handleMenuOpenedToggle }
            onLogout={ handleLogout }
          />
      }
      {
        (isAuthorised) &&
          <Menu
            isMenuOpened={ isMenuOpened }
            onMenuOpenedToggle={ handleMenuOpenedToggle }
            onLogout={ handleLogout }
            items={ menuStructure }
          />
      }
      <ContentWrapper
        isAuthorised={ isAuthorised }
      >
        <AppRoutes
          items={ menuStructure }
          isAuthorised={ isAuthorised }
          logoutReasonError={ logoutReasonError }
          onSignedIn={ handleSignedIn }
        />

      </ContentWrapper>

    </Container>
  )
};


export default AppWrapper;
