import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { useNAPIGraphQLClient } from "../../../../../hooks/useNAPIGraphQLClient";
import { useCurrentStitchingConfigsCacheKey } from "../useStitchingList";

const mutationQl = gql`
  mutation SetStitchingConfig($uuid: String) {
    setStitchingConfig(uuid: $uuid)
  }
`;

interface IResponse {
  setStitchingConfig: boolean;
}

type RequestParams = string | undefined | null;

export const useSetStitchingConfig = () => {
  const queryClient = useQueryClient();
  const client = useNAPIGraphQLClient() ?? new GraphQLClient("");
  const request = (uuid: RequestParams) =>
    client.request<IResponse>(mutationQl, { uuid });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
    queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentStitchingConfigsCacheKey);
      },
    });
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentStitchingConfigsCacheKey);
      },
    });
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
