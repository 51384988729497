import { useMemo } from "react";
import { usePanoramaStoreList } from "./query/usePanoramaStoreList";

export const usePanoramaStore = ({ uuid }: { uuid: string }) => {
  const { data, isLoading } = usePanoramaStoreList();
  const value = useMemo(() => {
    return data?.getPanoramaStoreList.find((v) => v.uuid === uuid);
  }, [data?.getPanoramaStoreList, uuid]);
  return { value, isLoading };
};
