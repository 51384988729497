import styled from "@emotion/styled";
import { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader";
import FieldSet, { FieldSetInputType } from "../../../../../Components/_BaseUI/FieldSet";
import { useCurrentFont } from "../../../../../Configuration/Styles/Fonts";
import LOCALIZATION from "../../../../../Localization";

interface IProps {
    onChange?: (value: {
        balance?: number;
        scale?: number;
        stretch?: [number, number, number];
    }) => void,
    initialValues: {
        balance?: number;
        scale?: number;
        stretch?: [number, number, number];
    }
}

export const EditAdditianonalParams = memo((props: IProps) => {
    const { onChange, initialValues, } = props;
    const [balance, setBalance] = useState(initialValues?.balance ?? 0)
    const [scale, setScale] = useState(initialValues?.scale ?? 1)
    const [stretch, setVerticalStretching] = useState(initialValues?.stretch?.join(',') ?? '')

    useEffect(() => {
        onChange?.({
            balance: balance ? balance : undefined,
            scale: scale ? scale : undefined,
            stretch: (new RegExp(/^(([-]?\d+([.]\d*)?[,])){2}([-]?\d+([.]\d*)?)$/)).test(stretch) ? stretch.split(',').map(Number) as [number, number, number] : undefined,
        })
    }, [onChange, balance, scale, stretch])


    const { font } = useCurrentFont()
    const Header = styled.span`
    color: var(--C_Dark_Text, #334D5E);
    /* c_h4 (18) */
    font-family: ${font};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.27px;
`

    const { formatMessage } = useIntl();
    return <LoadingComponent >
        <Header>
            {formatMessage({ id: LOCALIZATION.calibration_additional_adjustments })}
        </Header>        <FieldSet
            inputSize
            labelSize={3}
            hideSeparator
            label={`${formatMessage({ id: LOCALIZATION.balance })}, 0-1.0`}
            tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.balanc_desc })} `}
            input={{ type: FieldSetInputType.Float, max: 1, min: 0, value: balance, onChange: (v) => setBalance(Number(v)) }}
        ></FieldSet>

        <FieldSet
            inputSize
            labelSize={3}

            hideSeparator
            label={`${formatMessage({ id: LOCALIZATION.scale })}, 0.1-10.0`}
            tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.scale_desc })} `}

            input={{ type: FieldSetInputType.Float, max: 10, min: 0.1, value: scale ?? 0, onChange: (v) => setScale(Number(v)) }}
        ></FieldSet>
        {/* <FieldSet
            inputSize
            labelSize={3}

            hideSeparator
            label={`${formatMessage({ id: LOCALIZATION.vertical_stretching })}`}
            tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.vertical_stretching_desc })} `}
            onHintTooltipText={`${formatMessage({ id: LOCALIZATION.prop_will_not_saved_with_wrong_value })}. ${formatMessage({ id: LOCALIZATION.format }).capitalizeFirstLetter()} : 0, 0, 0`}
            input={{
                placeholder: "0, 0, 0",
                type: FieldSetInputType.Text, value: stretch, onChange: (v) => setVerticalStretching(trimByPattern(String(v).replace(/[^\d.,-]/g, ''), /^(([-]?\d+([.]\d*)?[,])){2}([-]?\d+([.]\d*)?)/)), hintCheckPattern: (value: string) => {
                    return !!value.length && !(new RegExp(/^(([-]?\d+([.]\d*)?[,])){2}([-]?\d+([.]\d*)?)/)).test(value)
                },
            }}
        ></FieldSet> */}
    </LoadingComponent>
})
