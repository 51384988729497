// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { createStyles, makeStyles } from '@mui/styles';
import { RenderCellContentValue } from "../../../../Components/SortedTable/SortedTableBodyRow";
import { HeadCell } from "../../../../Components/SortedTable/SortedTableHead";
import AnalyticsScoreNumber from "../../../../Components/_BaseUI/AnalyticsScoreNumber";
import AnalyticsTeamSideSelector from "../../../../Components/_BaseUI/AnalyticsTeamSideSelector";
import AnalyticsTimeField from "../../../../Components/_BaseUI/AnalyticsTimeField";
import HorizGrid from "../../../../Components/_Layout/HorizGrid";
import { Colors } from "../../../../Configuration/Styles/Colors";
import { FontName } from "../../../../Configuration/Styles/Fonts";
import { Sizes } from '../../../../Configuration/Styles/Sizes';
import { analyticsEventTypeHasScore, analyticsEventTypeHasTeamSide, analyticsFindEventWithScoreForEvent, AnalyticsGameEventType, AnalyticsGameEventWithId, AnalyticsTeamSide, analyticsTeamSideOpposit, localizedAnalyticsGameEventType } from "../../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent";
import { markerForAnalyticsGameEventType } from "../../../../Data/Analytics/AnalyticsGameEvent/helper";
import { AnalyticsSportType } from "../../../../Data/Analytics/AnalyticsTypes";
import { AnalyticsSmallPlayBtn } from "../../../../Icons/Icons";
import LOCALIZATION from "../../../../Localization";
import { intl } from "../../../../Localization/LocalizationProvider";

export const useRowStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
    rowText: {
        fontFamily: ({ fontFamily }) => fontFamily,
      fontSize: Sizes.title,
      fontWeight: Sizes.regularWeight,
      color: Colors.mainTitle,
    },
  }));

export const headCells = (team1Name?: string, team2Name?:string): HeadCell<AnalyticsGameEventWithId>[] => (
[
    // {
    //   id: 'id' as keyof AnalyticsGameEventWithId,
    //   numeric: false,
    //   disablePadding: true,
    //   label: intl().formatMessage({ id: LOCALIZATION.analytics_events_number }),
    // },
    {
      id: 'event_type' as keyof AnalyticsGameEventWithId,
      numeric: false,
      disablePadding: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_events_event }),
    },
    {
      id: 'timestamp' as keyof AnalyticsGameEventWithId,
      numeric: false,
      disablePadding: true,
      label: intl().formatMessage({ id: LOCALIZATION.analytics_events_time }),
    },
    {
      id: 'team1_side' as keyof AnalyticsGameEventWithId,
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: team1Name || "Team 1",
    },
    {
      id: 'team2_side' as keyof AnalyticsGameEventWithId,
      numeric: false,
      disablePadding: true,
      disableSort: true,
      label: team2Name || "Team 2",
    },
  ]);

type Props = Readonly<{
    sportType: AnalyticsSportType | undefined;
    rowClasses: ReturnType<typeof useRowStyles>;
    events: AnalyticsGameEventWithId[] | undefined;
    onVideoStart: (item: AnalyticsGameEventWithId) => void
    onEventUpdate: (newEvent: AnalyticsGameEventWithId) => void

    showScissorsCutBtn?: boolean;
    onScissorsCutPress: (item: AnalyticsGameEventWithId) => void

    team1Name: string  | undefined;
    team2Name: string  | undefined;
}>;

export const getRenderCellContent = (props: Props) => {
    const countEventNumberForEvent = (event: AnalyticsGameEventWithId | undefined): number => {
        if (!event || !props.events) {
            return 0;
        }
        let count = 1;
        for(const item of props.events) {
            if (item.id === event.id) {
                return count;
            }
            if (item.event_type === event.event_type) {
                count++;
            }
        }
        return count;
    }

    const detectGoalTeamValueForEvent = (event: AnalyticsGameEventWithId | undefined, team1Value: string | undefined, team2Value: string | undefined): string | undefined => {
        if (!event || !props.events || event.event_type !== AnalyticsGameEventType.Goal) {
            return undefined;
        }

        const lastEventWithScore = analyticsFindEventWithScoreForEvent(event, props.events, false);
        const team1_score = lastEventWithScore?.team1_score ?? 0
        const team2_score = lastEventWithScore?.team2_score ?? 0

        const event_team1_score = event?.team1_score ?? 0
        const event_team2_score = event?.team2_score ?? 0

        const team1_sore_distance = event_team1_score - team1_score;
        const team2_sore_distance = event_team2_score - team2_score;

        if (team1_sore_distance > team2_sore_distance) { // team1 score become bigger
            return team1Value;
        }
        if (team1_sore_distance < team2_sore_distance) { // team2 score become bigger
            return team2Value;
        }

        return undefined;
    }

    return (item: AnalyticsGameEventWithId, cellName: keyof AnalyticsGameEventWithId, index: number): RenderCellContentValue => {
        switch (cellName) {
            // case 'id': {
            //     return ({
            //         content: (
            //             <Box className={props.rowClasses.rowText}>
            //                 { item.id || "-" }
            //             </Box>
            //         ),
            //     });
            // }
            case 'event_type': {
                const count = countEventNumberForEvent(item)
                let extraInfo = ""
                if (item.event_type === AnalyticsGameEventType.Goal) {
                    const goalTeamName = detectGoalTeamValueForEvent(item, props.team1Name, props.team2Name)
                    if (goalTeamName !== undefined) {
                        extraInfo = ` (${goalTeamName})`;
                    }
                }

                return ({
                    content: (
                        <HorizGrid>
                            { markerForAnalyticsGameEventType(item.event_type, props.sportType) }
                            <Box className={props.rowClasses.rowText}>
                                { `${localizedAnalyticsGameEventType(item.event_type, count)}${extraInfo}` }
                            </Box>
                        </HorizGrid>
                    ),
                });
            }
            case 'timestamp': {
                if (!!item.timestamp) {
                    return ({
                        content: (
                            <HorizGrid>
                                <IconButton aria-label="play" sx={{ marginLeft: -1 }} onClick={(ev) => {
                                ev.stopPropagation();
                                props.onVideoStart(item);
                                } }>
                                <AnalyticsSmallPlayBtn />
                            </IconButton>
                                <AnalyticsTimeField
                                // errorTime="test1"
                                // isError={true}
                                // helpText="asd"
                                showScissorsCutBtn={ props.showScissorsCutBtn }
                                onScissorsCutPress={ () => {
                                    props.onScissorsCutPress(item);
                                }}
                                timeValue={ item.timestamp }
                                onChangeTime={(newValue) => {
                                    props.onEventUpdate({
                                        ...item,
                                        timestamp: newValue,
                                    })
                                }}
                            />
                            </HorizGrid>
                        ),
                    });
                }
                return ({
                    content: "-",
                });
            }
            case 'team1_side': {
                if (analyticsEventTypeHasTeamSide(item.event_type, props.events)) {
                    return ({
                        content: (
                            <AnalyticsTeamSideSelector
                                selectedSide={ item.team1_side || AnalyticsTeamSide.Left }
                                onChange={(newSide) => {
                                    props.onEventUpdate({
                                        ...item,
                                        team1_side: newSide,
                                        team2_side: analyticsTeamSideOpposit(newSide),
                                    })
                                }}
                            />
                        ),
                    });
                }
                if (analyticsEventTypeHasScore(item.event_type)) {
                    return ({
                        content: (
                            <AnalyticsScoreNumber
                                value={ item.team1_score }
                                onChange={(newValue) => {
                                    props.onEventUpdate({
                                        ...item,
                                        team1_score: newValue,
                                    })
                                }}
                            />
                        ),
                    });
                }

                return ({
                    content: "",
                });
            }
            case 'team2_side': {
                if (analyticsEventTypeHasTeamSide(item.event_type, props.events)) {
                    return ({
                        content: (
                            <AnalyticsTeamSideSelector
                                selectedSide={ item.team2_side || AnalyticsTeamSide.Right }
                                onChange={(newSide) => {
                                    props.onEventUpdate({
                                        ...item,
                                        team1_side: analyticsTeamSideOpposit(newSide),
                                        team2_side: newSide
                                    })
                                }}
                            />
                        ),
                    });
                }
                if (analyticsEventTypeHasScore(item.event_type)) {
                    return ({
                        content: (
                            <AnalyticsScoreNumber
                                value={ item.team2_score }
                                onChange={(newValue) => {
                                    props.onEventUpdate({
                                        ...item,
                                        team2_score: newValue,
                                    })
                                }}
                            />
                        ),
                    });
                }
                return ({
                    content: "",
                });
            }
        }

        return ({
        content: (item?.[cellName] + "" || ''),
        });
    };
}