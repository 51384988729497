import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { useCameraHandler } from "../../../../hooks/useCameraHandler";
import { globalConfigCacheKey } from "../../types";

const mutationQl = gql`
  mutation PushMainCameraScheduleConfig($config: [MainCameraScheduleInput!]) {
    pushMainCameraScheduleConfig(config: $config)
  }
`;
interface IResponse {
  mainCameraSchedule: boolean;
}

interface IPatchCommonSettingProps {}

type Request =
  | [{ camera: number }]
  | [
      { timestamp: number; camera: number },
      { timestamp: number; camera: number }
    ];

/**
 * @description It request the data only when fetchData has been fired
 * @param props
 * @returns
 */
export const usePushMainCameraSchedule = (props?: IPatchCommonSettingProps) => {
  const queryClient = useQueryClient();
  const client = useCameraHandler() ?? new GraphQLClient("");
  const request = (patch: Request) =>
    client.request<IResponse>(mutationQl, { config: patch });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
    queryClient.invalidateQueries(globalConfigCacheKey);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    queryClient.invalidateQueries(globalConfigCacheKey);
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
