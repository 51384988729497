// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FontName } from "../../../../Configuration/Styles/Fonts";

import { Sizes } from "../../../../Configuration/Styles/Sizes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles<any, { fontFamily: FontName }>({
    root: {
      flexGrow: 1,
    },
    name: {
      fontSize: Sizes.heading,
      fontFamily: ({ fontFamily }) => fontFamily,
      fontWeight: Sizes.mediumWeight,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2),
      minHeight: 32,
      display: "flex",
      alignItems: "center",
    },
    status: {
      fontSize: Sizes.title,
      fontFamily: ({ fontFamily }) => fontFamily,
      fontWeight: Sizes.mediumWeight,
    },
    statusName: {
      fontSize: Sizes.title,
      fontFamily: ({ fontFamily }) => fontFamily,
      fontWeight: Sizes.mediumWeight,
    },
  })
);


export default useStyles;
