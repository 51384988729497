import { css } from "@emotion/css";
import { Grid } from "@mui/material";
import { unix } from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { injectIntl, useIntl, WrappedComponentProps } from "react-intl";
import Loader from "../../../../Components/Loader";
import FieldSet, { FieldSetInputType } from "../../../../Components/_BaseUI/FieldSet";
import FieldSetSelectComponent, { FieldSetSelectOption } from '../../../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import Popup from "../../../../Components/_Layout/Popup";
import { useMainCameraSchedule } from "../../../../Data/NapiCameraHandlerV2/hooks/common-settings/useMainCameraSchedule";
import { usePushMainCameraSchedule } from "../../../../Data/NapiCameraHandlerV2/hooks/common-settings/usePushCameraSchedule";
import { useCameraCommonSettings } from "../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { usePatchCommonSetting } from "../../../../Data/NapiCameraHandlerV2/hooks/usePatchCommonSettings";
import { AeAntibanding, AWB_MODE, CommonSettings } from "../../../../Data/NapiCameraHandlerV2/types";
import { useFirstDefinedValue } from "../../../../hooks/useFirstDefined";
import { usePromise } from "../../../../hooks/usePromise";
import useTimePicker from "../../../../hooks/useTimePicker";
import LOCALIZATION from "../../../../Localization";


type Props = Readonly<{
    onClose: () => void
    handleSubmit?: (props: CommonSettings) => void
} & WrappedComponentProps>;

type MainLensState = [{ camera: number }] | /** milliseconds */[{ timestamp: number, camera: number }, { timestamp: number, camera: number }]

type MainLensSelectorProps = {
    state: MainLensState;
    onChange: (value: MainLensState) => void;
}

const startFromOption = 'Start From';
const allDayOption = 'All Day';
const customOption = 'customOption';

const MainLensSelector = ({ state, onChange }: MainLensSelectorProps) => {
    const intl = useIntl();
    const { data, } = useCameraCommonSettings()
    const commonSettings = useMemo(() => {
        return data?.configState.config.camera
    }, [data]);
    const [num_cameras] = useState<number | undefined>(commonSettings?.["num-cameras"])


    const onChangeCallback = useCallback((value: MainLensState) => {
        switch (value.length) {
            case 1:
                onChange(value);
                break;
            case 2:
                break;
        }
    }, [onChange]);
    const { pick } = useTimePicker()
    const onSwichingModeCallback = useCallback(async (id: string,) => {
        if (id === allDayOption) {
            onChange([{ camera: commonSettings?.["main-camera"] ?? 0 }]);
            return;
        }
        if (id === startFromOption) {
            const time = await pick();
            if (!time) {
                return
            }
            const res: [{ timestamp: number, camera: number }, { timestamp: number, camera: number }] = [
                {
                    timestamp: time, camera: state.length === 1 ? state[0].camera : state[0].camera,
                }, {
                    timestamp: time + 1, camera: state.length === 1 ? state[0].camera : state[1].camera,
                }]
            onChange(res)
        }
    }, [commonSettings?.["main-camera"], onChange, pick, state])
    const onSelectTimeFrom = useCallback(async (id: string, index: number) => {
        if (id === allDayOption) {
            onChange([{ camera: commonSettings?.["main-camera"] ?? 0 }]);
            return;
        }
        if (state.length !== 2) {
            return;
        }
        if (id === startFromOption) {
            const time = await pick();
            if (!time) {
                return
            }
            const clone = JSON.parse(JSON.stringify(state));
            clone[index].timestamp = time + index;
            onChange(clone)
        }
    }, [commonSettings?.["main-camera"], onChange, pick, state])
    const onChangeCamera = useCallback(async (id: string, index: number) => {
        if (state.length !== 2) {
            return;
        }
        const clone = JSON.parse(JSON.stringify(state));
        clone[index].camera = Number(id);
        onChange(clone)
    }, [onChange, state])
    const mainCameraOptions: FieldSetSelectOption[] = useMemo(() => {
        const offOption = { id: "-1", name: intl.formatMessage({ id: LOCALIZATION.off }) }
        if (!num_cameras) {
            return [offOption];
        }
        const options = Array.from(Array(num_cameras).keys()).map((v) => ({
            id: v.toString(),
            name: v.toString()
        }))

        return [...options, offOption];
    }, [intl, num_cameras])

    switch (state.length) {
        case 1:
            return (
                <>
                    <FieldSet label={intl.formatMessage({ id: LOCALIZATION.camera_settings_common_main_lens })} labelSize={6} inputSize input={{
                        type: FieldSetInputType.Other, children: (
                            <Grid justifyContent={'right'} gap={1} container>
                                <Grid xs>

                                    <FieldSetSelectComponent input={{
                                        type: FieldSetInputType.Select,
                                        value: allDayOption,
                                        options: [{ id: allDayOption, name: `${intl.formatMessage({ id: LOCALIZATION.all_day })}` }, { id: startFromOption, name: `${intl.formatMessage({ id: LOCALIZATION.time_start_from })}...` },],
                                        onChange: onSwichingModeCallback,
                                    }} />
                                </Grid>
                                <Grid xs={'auto'} >

                                    <FieldSetSelectComponent input={{
                                        type: FieldSetInputType.Select,
                                        value: state[0].camera.toString(),
                                        onChange: (v) => onChangeCallback([{ camera: Number(v) }]),
                                        options: mainCameraOptions
                                    }} />
                                </Grid>
                            </Grid>
                        )
                    }} />



                </>
            )
        case 2:
            //@ts-ignore
            return <FieldSet labelSx={{ display: 'block' }} label={(
                <Grid className={css`
                   align-self: start; `} container>
                    <Grid xs={'auto'}>
                        {intl.formatMessage({ id: LOCALIZATION.camera_settings_common_main_lens })}</Grid>
                </Grid>)}
                labelSize={6} inputSize
                input={{
                    type: FieldSetInputType.Other, children: (
                        <>
                            <Grid justifyContent={'right'} gap={1} marginBottom={2} container>
                                <Grid xs>

                                    <FieldSetSelectComponent input={{
                                        type: FieldSetInputType.Select,
                                        value: customOption,
                                        options: [{ id: allDayOption, name: `${intl.formatMessage({ id: LOCALIZATION.all_day })}` }, { id: startFromOption, name: `${intl.formatMessage({ id: LOCALIZATION.time_start_from })}...` }, { id: customOption, name: unix(state[0].timestamp / 1000).format("HH:mm") }],
                                        onChange: (v) => onSelectTimeFrom(v, 0),

                                    }} />
                                </Grid>
                                <Grid xs={'auto'} >

                                    <FieldSetSelectComponent input={{
                                        type: FieldSetInputType.Select,
                                        value: state[0].camera.toString(),
                                        options: mainCameraOptions,
                                        onChange: v => onChangeCamera(v, 0)
                                    }} />
                                </Grid>
                            </Grid>
                            <Grid justifyContent={'right'} gap={1} container>
                                <Grid xs>

                                    <FieldSetSelectComponent input={{
                                        type: FieldSetInputType.Select,
                                        value: customOption,
                                        options: [{ id: startFromOption, name: `${intl.formatMessage({ id: LOCALIZATION.time_start_from })}...` }, { id: customOption, name: unix(state[1].timestamp / 1000).format("HH:mm") }],
                                        onChange: (v) => onSelectTimeFrom(v, 1),

                                    }} />
                                </Grid>
                                <Grid xs={'auto'} >

                                    <FieldSetSelectComponent input={{
                                        type: FieldSetInputType.Select,
                                        value: state[1].camera.toString(),
                                        options: mainCameraOptions,
                                        onChange: v => onChangeCamera(v, 1)
                                    }} />
                                </Grid>
                            </Grid>
                        </>
                    )
                }}>
            </FieldSet>
    }
}

const EditModal = injectIntl((props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote } = props;

    const { data, isLoading, } = useCameraCommonSettings()
    const commonSettings = useMemo(() => {
        return data?.configState.config.camera
    }, [data]);
    const [num_cameras] = useState<number | undefined>(commonSettings?.["num-cameras"])
    const { mutateAsync: patchCommonSettings, isLoading: patchLoading } = usePatchCommonSetting()
    const { mutateAsync: pushMainCameraScheldule, isLoading: pushingMainCameraSchedule } = usePushMainCameraSchedule()


    const { formatMessage } = useIntl()


    const [denoise_strength, setDenoise_strength] = useState<number | undefined>(commonSettings?.["denoise-strength"])
    const [edge_enhance_strength, setEdge_enhance_strength] = useState<number | undefined>(commonSettings?.["edge-enhance-strength"])
    const [frame_rate, setFrame_rate] = useState<number | undefined>(commonSettings?.["frame-rate"])
    const [awb_mode, setAwb_mode] = useState<AWB_MODE | undefined>(commonSettings?.["awb-mode"])

    const [ae_antibanding, setAe_antibanding] = useState<AeAntibanding | undefined>(commonSettings?.["ae-antibanding"])


    const awb_modeOptions: FieldSetSelectOption<AWB_MODE>[] = useMemo(() => {
        return [
            {
                id: AWB_MODE.auto,
                name: formatMessage({ id: LOCALIZATION.auto }),
            },
            {
                id: AWB_MODE.cloudyDaylight,
                name: formatMessage({ id: LOCALIZATION["cloudy-daylight"] }),
            },
            {
                id: AWB_MODE.daylight,
                name: formatMessage({ id: LOCALIZATION.daylight }),
            },
            {
                id: AWB_MODE.fluorescent,
                name: formatMessage({ id: LOCALIZATION.fluorescent }),
            },
            {
                id: AWB_MODE.incandescent,
                name: formatMessage({ id: LOCALIZATION.incandescent }),
            },
            {
                id: AWB_MODE.off,
                name: formatMessage({ id: LOCALIZATION.off }),
            },
            {
                id: AWB_MODE.shade,
                name: formatMessage({ id: LOCALIZATION.shade }),
            },
            {
                id: AWB_MODE.twilight,
                name: formatMessage({ id: LOCALIZATION.twilight }),
            },
            {
                id: AWB_MODE.warmFluorescent,
                name: formatMessage({ id: LOCALIZATION["warm-fluorescent"] }),
            },
        ]
    }, [formatMessage])

    const ae_modeOptions: FieldSetSelectOption<AeAntibanding>[] = useMemo(() => {
        return [
            {
                id: AeAntibanding.auto,
                name: formatMessage({ id: LOCALIZATION.auto }),
            },
            {
                id: AeAntibanding.hz50,
                name: AeAntibanding.hz50,
            }, {
                id: AeAntibanding.hz60,
                name: AeAntibanding.hz60,
            }, {
                id: AeAntibanding.off,
                name: formatMessage({ id: LOCALIZATION.off }),
            },
        ]
    }, [formatMessage])

    const { isLoading: gettingMainCameraSchedule, data: mainCameraSchedule } = useMainCameraSchedule()

    const firstMainCameraSchedule = useFirstDefinedValue(mainCameraSchedule?.getMainCameraScheduleConfig)
    const [mainLensState, setMainLensState] = useState<MainLensState>([{ camera: commonSettings?.["main-camera"] ?? 0 }])

    useEffect(() => {
        const value = firstMainCameraSchedule;
        if (!value?.length) {
            return;
        }
        setMainLensState(value)
    }, [firstMainCameraSchedule,])

    const handleSubmit = useCallback(async () => {
        const patch = {
            "num-cameras": num_cameras, "denoise-strength": denoise_strength, "edge-enhance-strength": edge_enhance_strength, "frame-rate": frame_rate, "awb-mode": awb_mode, "ae-antibanding": ae_antibanding, "main-camera": mainLensState[0].camera,
        };
        try {
            console.log(patch)
            await pushMainCameraScheldule(mainLensState)
            await patchCommonSettings(patch).then(() => {
                handleSubmitRemote?.(patch);
            })
        } catch {

        } finally {
            onClose();

        }


    }, [num_cameras, denoise_strength, edge_enhance_strength, frame_rate, awb_mode, ae_antibanding, pushMainCameraScheldule, mainLensState, patchCommonSettings, handleSubmitRemote, onClose])


    if (!commonSettings) {
        return <></>
    }
    return <Popup
        isWide
        headerTitle={props.intl.formatMessage({ id: LOCALIZATION.page_camera_settings_common_settings_header })}
        isApplyDisabled={isLoading || patchLoading || gettingMainCameraSchedule || pushingMainCameraSchedule}
        isCloseDisabled={patchLoading}
        onApply={handleSubmit}
        onClose={onClose}
    >
        <Loader isLoading={isLoading || patchLoading}>
            <Grid style={{ position: 'relative' }} padding={2}>

            <FieldSet
                label={props.intl.formatMessage({ id: LOCALIZATION.camera_settings_common_lens })}
                input={{ type: FieldSetInputType.StaticLabel, value: (num_cameras ?? 0).toString(), }}

            ></FieldSet>
            <FieldSet
                label={props.intl.formatMessage({ id: LOCALIZATION.camera_settings_common_angle })}
                input={{ type: FieldSetInputType.StaticLabel, value: commonSettings.angle.toString() }}
                ></FieldSet>
                <MainLensSelector state={mainLensState} onChange={setMainLensState} />

            <FieldSet
                label={props.intl.formatMessage({ id: LOCALIZATION.camera_settings_common_fps })}
                    input={{ type: FieldSetInputType.Float, value: frame_rate ?? 0, onChange: (v) => setFrame_rate(Number(v)) }}
            ></FieldSet>
            <FieldSet
                label={props.intl.formatMessage({ id: LOCALIZATION.camera_settings_common_denoise })}
                    input={{ type: FieldSetInputType.Float, value: denoise_strength ?? 0, onChange: (v) => setDenoise_strength(Number(v)), max: 1, min: -2 }}
            ></FieldSet>
            <FieldSet
                label={props.intl.formatMessage({ id: LOCALIZATION.camera_settings_common_edge })}
                    input={{ type: FieldSetInputType.Float, value: edge_enhance_strength ?? 0, onChange: (v) => setEdge_enhance_strength(Number(v)), max: 1, min: -2 }}
            ></FieldSet>

            <FieldSet
                label={props.intl.formatMessage({ id: LOCALIZATION.camera_settings_common_auto_white })}
                input={{ type: FieldSetInputType.Select, value: awb_mode ?? '', onChange: (v) => setAwb_mode(v as AWB_MODE), options: awb_modeOptions }}
            ></FieldSet>

            <FieldSet
                label={props.intl.formatMessage({ id: LOCALIZATION.camera_settings_common_antibanding })}
                input={{ type: FieldSetInputType.Select, value: ae_antibanding ?? '', options: ae_modeOptions, onChange: (v) => setAe_antibanding(v as AeAntibanding) }}
            ></FieldSet>

        </Grid>
        </Loader>

    </Popup>
})

export const EditModalPrefetch = ((props: {
    onClose: () => void
    handleSubmit?: (props: CommonSettings) => void
}) => {
    const { fetchData, resetCache, data } = useCameraCommonSettings();
    const loaded = usePromise(async () => {
        await resetCache()
        await fetchData?.();
        return true;
    }, [])
    if (!loaded || !data) {
        return <></>
    }
    return <EditModal {...props}></EditModal>
})
