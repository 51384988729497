// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Divider, Grid } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Button from '../../../Components/_BaseUI/Button';
import Spinner from '../../../Components/_BaseUI/Spinner/Spinner';
import Card from '../../../Components/_Layout/Card';
import Popup from '../../../Components/_Layout/Popup';
import User, { isUserHaveServerAnalyticsAccess, isUserHaveServerCameraAccess } from '../../../Data/AccountUsers/User';
import TeamRef from '../../../Data/Analytics/AnalyticsTeamRef';
import Camera from '../../../Data/Camera/ActiveCamera/Camera';
import LOCALIZATION from '../../../Localization';
import CamerasList from './CamerasList';
import UserPopupLogin from './UserPopupLogin';
import UserPopupPasswords from './UserPopupPasswords';
// import { getUserCameras } from '../../../hooks/fetch/User';
// import { getUserTeamRefs } from '../../../hooks/fetch/User';
import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import { isSuccessResponse, resetPassword } from '../../../Data/AccountUsers/fetch';
import useMutationUser, { ActionType } from '../../../Data/AccountUsers/fetch/useMutation';
import UserWithoutID, { isUser1AccessLeveleBetterUser2AccessLevele, isUserRoleMinimum, UserAccessLevel, UserCameraAccess, UserRole, UserTeamRefAccess, UserType } from '../../../Data/AccountUsers/UserWithoutID';
import AuthorisationManager from '../../../Data/Auth/AuthorisationManager';
import { DEFAULT_VALUE, isUserWithID, rolesOptions } from './helpers';
import TeamRefsList from './TeamRefsList';


type Props = Readonly<{
  cameras: Camera[];
  editorCameraIdList?: string[];
  teamRefs: TeamRef[];
  editorTeamRefIdList?: string[];
  editorAccessLevel: UserAccessLevel;
  cameraAccess: UserCameraAccess;
  teamRefAccess: UserTeamRefAccess;
  isCurrentUser: boolean;
  type: UserType;
  user?: User | UserWithoutID;
  onPostedSuccess: (user?: User) => void;
  onClose: () => void;
} & WrappedComponentProps>;
// Import statements remain the same



const UserPopup: React.FC<Props> = (props: Props) => {
  const [user, setUser] = React.useState<User>(props.user as User || DEFAULT_VALUE);
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);
  const [isPasswordSuccessReset, setIsPasswordSuccessReset] = React.useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = React.useState<string | undefined>(undefined);
  const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(true);
  const [isLoginCorrect, setIsLoginCorrect] = React.useState<boolean>(!!props.user?.login?.length);
  const [isNameCorrect, setIsNameCorrect] = React.useState<boolean>(!!props.user?.name?.length);
  const { mutateAsync: mutateUsers } = useMutationUser();
  const isApplyEnabled = isNameCorrect && isPasswordCorrect && isLoginCorrect;
  const isEditMode = isUserWithID(user);
  const isServerMode = props.type === UserType.Server;

  const { user: curUser } = AuthorisationManager.shared;
  const isAdminOrBetterUser = isUserRoleMinimum(curUser?.role, UserRole.Admin);
  const isValidRoleForEditExistingUser =
    props.isCurrentUser ||
    !isEditMode ||
    (isAdminOrBetterUser && isUser1AccessLeveleBetterUser2AccessLevele(curUser?.accessLevel, user?.accessLevel));
  const curUserHaveServerCameraAccess = isUserHaveServerCameraAccess(curUser);
  const curUserHaveServerAnalyticsAccess = isUserHaveServerAnalyticsAccess(curUser);

  const handleSuccessSubmit = React.useCallback(() => {
    props.onPostedSuccess(user as User);
  }, [props, user]);

  const handleSubmit = React.useCallback(async () => {
    try {
      if (isApplyEnabled) {
        setIsLoaderShowing(true);
        const type = isEditMode ? ActionType.Patch : ActionType.Add;
        await mutateUsers(
          {
            user,
            type,
            originalUser: { ...(props.user as User), password: currentPassword },
          },
          {
            onSuccess: handleSuccessSubmit,
          }
        );
        setIsLoaderShowing(false);
      }
    } catch (error) {
      alert(error);
      setIsLoaderShowing(false);
    }
  }, [isApplyEnabled, isEditMode, mutateUsers, user, props.user, currentPassword, handleSuccessSubmit]);

  // Memoized callback without dependencies on changing state
  const handleChangeLogin = React.useCallback(
    (login: string) => {
      setUser((prevUser) => ({
        ...prevUser,
        login,
      }));
    },
    [setUser]
  );

  const handleChangeName = React.useCallback(
    (name: string) => {
      setUser((prevUser) => ({
        ...prevUser,
        name,
      }));
    },
    [setUser]
  );

  const handleChangeRole = React.useCallback(
    (value: string) => {
      const role = value as UserRole;
      setUser((prevUser) => ({
        ...prevUser,
        role,
      }));
    },
    [setUser]
  );

  const handleChangePassword = React.useCallback(
    (isCorrectPassword: boolean, password: string, oldPassword?: string) => {
      const newPasswordValue =
        isCorrectPassword && password.length !== 0 ? password : undefined;

      setIsPasswordCorrect(isCorrectPassword);
      setCurrentPassword(oldPassword);
      setUser((prevUser) => ({
        ...prevUser,
        password: newPasswordValue,
      }));
    },
    [setIsPasswordCorrect, setCurrentPassword, setUser]
  );

  const handleCamerasChanged = React.useCallback(
    (cameraAccess: UserCameraAccess, cameraIdList: string[]) => {
      setUser((prevUser) => ({
        ...prevUser,
        cameraAccess,
        cameraIdList,
      }));
    },
    [setUser]
  );

  const handleTeamRefsChanged = React.useCallback(
    (teamRefAccess: UserTeamRefAccess, teamRefIdList: string[]) => {
      setUser((prevUser) => ({
        ...prevUser,
        teamRefAccess,
        teamRefIdList,
      }));
    },
    [setUser]
  );

  const handleResetPassword = React.useCallback(async () => {
    if (isEditMode && user.id) {
      const resetPasswordState = await resetPassword(user.id);

      if (isSuccessResponse(resetPasswordState)) {
        setIsPasswordSuccessReset(true);
      } else {
        alert(resetPasswordState.message || resetPasswordState.name || 'Error');
        setIsPasswordSuccessReset(false);
      }
    }
  }, [isEditMode, user.id]);

  const nameErrorCheckPattern = React.useCallback(
    (name: string): boolean => {
      const isCorrectValue = name.length > 0;
      setIsNameCorrect(isCorrectValue);
      return !isCorrectValue;
    },
    [setIsNameCorrect]
  );

  const renderPasswordBlock = React.useCallback(() => {
    if (!isValidRoleForEditExistingUser) {
      return null;
    }

    if (!isServerMode) {
      return (
        <UserPopupPasswords
          isCurrentUser={props.isCurrentUser}
          onChangePassword={handleChangePassword}
        />
      );
    }

    if (!isEditMode) {
      return (
        <>
          <br />
          <Grid>
            {props.intl.formatMessage({
              id: LOCALIZATION.password_will_be_generated_and_send_to_login_email_address,
            })}
          </Grid>
          <br />
        </>
      );
    }

    if (isServerMode && isEditMode) {
      if (isPasswordSuccessReset) {
        return (
          <>
            <br />
            {props.intl.formatMessage({ id: LOCALIZATION.password_reset_successful })}
            <br />
            <br />
          </>
        );
      }

      return (
        <>
          <br />
          <Button onClick={handleResetPassword}>
            {props.intl.formatMessage({ id: LOCALIZATION.password_reset })}
          </Button>
          <br />
          <br />
        </>
      );
    }

    return null;
  }, [
    handleChangePassword,
    handleResetPassword,
    isEditMode,
    isPasswordSuccessReset,
    isServerMode,
    isValidRoleForEditExistingUser,
    props.intl,
    props.isCurrentUser,
  ]);

  return (
    <Popup
      isWide
      headerTitle={props.intl.formatMessage({ id: LOCALIZATION.user_popup_title })}
      applyTitle={props.intl.formatMessage({
        id: isEditMode ? LOCALIZATION.save : LOCALIZATION.add,
      })}
      isApplyDisabled={!isApplyEnabled || isLoaderShowing}
      isCloseDisabled={isLoaderShowing}
      onApply={handleSubmit}
      onClose={props.onClose}
    >
      <Grid item xs={12}>
        <Card>
          {isLoaderShowing ? (
            <>
              <br />
              <br />
              <Spinner />
              <br />
              <br />
            </>
          ) : (
            <form
                action={window.location.href}
                encType={'multipart/form-Data'}
                onSubmit={handleSubmit}
              >
                <UserPopupLogin
                  isServerMode={isServerMode}
                  isEditMode={isEditMode}
                  login={user.login}
                  onChangeLogin={handleChangeLogin}
                  onChangeLoginCorrect={setIsLoginCorrect}
                />
                <FieldSet
                  key={'Name'}
                  label={props.intl.formatMessage({ id: LOCALIZATION.user_name })}
                  input={{
                    type: isValidRoleForEditExistingUser
                      ? FieldSetInputType.Text
                      : FieldSetInputType.StaticLabel,
                    value: user.name,
                    errorCheckPattern: nameErrorCheckPattern,
                    onChange: handleChangeName,
                  }}
                  onErrorTooltipText={props.intl.formatMessage({ id: LOCALIZATION.value_incorrect })}
                />
                {!props.isCurrentUser && isValidRoleForEditExistingUser && (
                  <FieldSet
                    key={'Role'}
                    label={props.intl.formatMessage({ id: LOCALIZATION.user_group })}
                    input={{
                      type: FieldSetInputType.Select,
                      value: user.role,
                      options: rolesOptions(props.editorAccessLevel),
                      onChange: handleChangeRole,
                    }}
                  />
                )}
                {renderPasswordBlock()}
                {props.type === UserType.Server &&
                  !props.isCurrentUser &&
                  isValidRoleForEditExistingUser &&
                  curUserHaveServerCameraAccess && (
                    <>
                      <Divider />
                      <br />
                      <CamerasList
                      isEditMode={isEditMode}
                      userCameraAccess={user.cameraAccess}
                      userRole={user.role}
                      editorCameraIdList={props.editorCameraIdList}
                      withRootAccess={props.cameraAccess === UserCameraAccess.All}
                      userCameras={user.userAvailableCameraIdList}
                      cameras={props.cameras}
                      onChange={handleCamerasChanged}
                    />
                  </>
                  )}
                {props.type === UserType.Server &&
                  !props.isCurrentUser &&
                  isValidRoleForEditExistingUser &&
                  curUserHaveServerAnalyticsAccess && (
                    <>
                      <Divider />
                      <br />
                      <TeamRefsList
                      isEditMode={isEditMode}
                      userTeamRefAccess={user.teamRefAccess}
                      userRole={user.role}
                      editorTeamRefIdList={props.editorTeamRefIdList}
                      selfTeamRefAccess={props.teamRefAccess}
                      userTeamRefIdList={user.userAvailableTeamRefIdList || []}
                      teamRefs={props.teamRefs}
                      onChange={handleTeamRefsChanged}
                    />
                  </>
                  )}
              </form>
          )}
        </Card>
      </Grid>
    </Popup>
  );
};

export default injectIntl(UserPopup);
