// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import styled from '@emotion/styled';
import { ReactComponent as AnalyticsSmallPlayBtn } from './analytics_small_play_btn.svg';
import { ReactComponent as AttendancePageIcon } from './attendancePageIcon.svg';
import { ReactComponent as CircleArrowButton } from './Button_CIRCLE.svg';
import { ReactComponent as CylindralStitchingIcon } from './cylindral_stitching.svg';
import { ReactComponent as DownloadFromCloud } from './download_from_cloud.svg';
import { ReactComponent as FIcon } from './F.svg';
import { ReactComponent as GamesTeamImagesSwapBtn } from './gamesTeamImagesSwapBtn.svg';
import { ReactComponent as IconArrowLeft } from './iconArrowLeft.svg';
import { ReactComponent as IconArrowRight } from './iconArrowRight.svg';
import { ReactComponent as IconGrid } from './iconGrid.svg';
import { ReactComponent as IconList } from './iconList.svg';
import { ReactComponent as IconFigureRun } from './icon_figure_run.svg';
import { ReactComponent as IconFigureSprint } from './icon_figure_sprint.svg';
import { ReactComponent as IconFigureWalk } from './icon_figure_walk.svg';
import { ReactComponent as AdjustButtonIcon } from './mobile_adjust_button_icon.svg';
import { ReactComponent as PerspectiveStitchingIcon } from './pespective_stitching.svg';
import { ReactComponent as RefreshIconImported } from './refreshIcon.svg';
import { ReactComponent as RefreshIcon2 } from './refreshIcon2.svg';
import { ReactComponent as CrossIcon } from './removeButton.svg';
import { ReactComponent as ScissorsCircleGrayBtn } from './scissorsCircleGrayBtn.svg';
import { ReactComponent as softwareIcon } from './softwareIcon.svg';
import { ReactComponent as UglyLoaderIcon } from './ugly_loader.svg';
const RefreshIcon = styled(RefreshIconImported)` fill:white;  `
export type SvgInHtml = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }> | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;

const CircleArrowButtonIcon = styled(CircleArrowButton)`
      transform: ${props => props.direction === 'left' ? `scaleX(-1)` : 'initial'} ;
`

export {
    AdjustButtonIcon, AnalyticsSmallPlayBtn, CrossIcon, FIcon, GamesTeamImagesSwapBtn,
    IconArrowLeft,
    IconArrowRight, IconFigureRun,
    IconFigureSprint,
    IconFigureWalk, IconGrid, IconList, RefreshIcon, RefreshIcon2, ScissorsCircleGrayBtn, UglyLoaderIcon,
    PerspectiveStitchingIcon,
    CylindralStitchingIcon,
    CircleArrowButtonIcon,
    softwareIcon, DownloadFromCloud,
    AttendancePageIcon
};

