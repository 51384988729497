import { gql } from "graphql-request";
import { useMemo } from "react";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useCameraHandler } from "../../../../hooks/useCameraHandler";

interface IResponse {
  getIndividualCameraConfig: {
    exposure_compensation: number | null;
    ae_region: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    } | null;
    awb_region: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    } | null;
    color_saturation: number | null;
    color_saturation_bias: number | null;
    brightness: number | null;
    contrast: number | null;
    adjust_color: {
      r: number;
      b: number;
      g: number;
    };
  };
}

const graphql = gql`
  query GetIndividualCameraConfig($camera: Int!) {
    getIndividualCameraConfig(camera: $camera) {
      exposure_compensation
      ae_region {
        left
        top
        right
        bottom
      }
      awb_region {
        left
        top
        right
        bottom
      }
      color_saturation
      color_saturation_bias
      brightness
      contrast
      adjust_color {
        r
        b
        g
      }
    }
  }
`;

interface Props<TData> {
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
  camera: number;
}

export const individualCameraCacheKey = (camera: number) =>
  `useIndividualCameraCacheKey${camera}`;

export const useIndividualCamera = (props: Props<IResponse | undefined>) => {
  const { options, camera } = props ?? {};
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const key = [individualCameraCacheKey(camera)] as QueryKey;
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };
  const request = async () => {
    return client
      ?.request<IResponse>(graphql, { camera })
      .then((data: IResponse) => {
        const res = data?.getIndividualCameraConfig;

        if (!res) {
          return data;
        }
        if (!res.adjust_color) {
          return data;
        }
        res.adjust_color.b = Number(res.adjust_color.b) * 255;
        res.adjust_color.g = Number(res.adjust_color.g) * 255;
        res.adjust_color.r = Number(res.adjust_color.r) * 255;
        return data;
      });
  };

  const query = useQuery(key, () => request(), {
    staleTime: 15_000,
    ...options,
  });

  const data = useMemo(() => {
    return query.data?.getIndividualCameraConfig;
  }, [query.data]);

  return {
    ...query,
    resetCache,
    queryKey: key,
    data,
  };
};
