// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import LOCALIZATION from "../../Localization";
import { intl } from "../../Localization/LocalizationProvider";
import CameraConfig from "../NapiCameraConfig";
import { DestType } from '../NapiCameraConfig/Dest';
import { StreamType } from "../NapiStreamingProfileOptions";

export enum StreamAccessPermission {
  all = 'all',
  members = 'members',
  payed = 'payed',
  admin = 'admin',
  byLink = 'by_link',
}

export const VK_STREAM_ACCESS_PERMISSIONS_ARR = [
  StreamAccessPermission.all,  StreamAccessPermission.members, StreamAccessPermission.payed, StreamAccessPermission.admin, StreamAccessPermission.byLink,
]

export const YOUTUBE_STREAM_ACCESS_PERMISSIONS_ARR = [
  StreamAccessPermission.all,  StreamAccessPermission.byLink,
]

export type DestStreamBlock = {
  key: string | undefined; // unique uuid for this block
  type: DestType;
  id: StreamType;
  enabled: boolean;
  urlKey?: string; // only for type = RTMP. key for RTMP url.  urlKey is automatically add to url if defined
  url?: string; // only for type = RTMP. rtmp URL to store stream. can have key or not. urlKey is automatically add to this URL if defined
  file?: string; //  only for type = File. path to store stream.
  oAuthToken?: { [k: string]: string } | null; // if undefined or null then no token. for seculity after save token server will return enpty object as marker that it have token = {}
  groupId?: number; //  only if oAuthToken defined. For services like VK can hold groupId of group where to post video
  groupName?: string; //  only if oAuthToken defined. when set groupId also save corresponding name fot group
  playlistName?: string; //  only if oAuthToken defined. Can define paylist name

  timezoneMinutes?: number; // zimeone use to format dates when create name, desc for video. default 180 = UTC+3
  timeLocale?: string; // time locale use to format dates when create name, desc for video. default "ru"
  videoNamePrefix?: string; // only if oAuthToken defined. prefix when create name for video when start on Youtube, VK, etc. Prefix+"%START_TIME% - %END_TIME%: $TITLE$"
  videoNameFormatOnStart?: string; // only if oAuthToken defined. format how create name for video when start on Youtube, VK, etc. "Камера 1, %START_TIME% - %END_TIME%: $TITLE$"
  videoNameFormatOnStop?: string; // only if oAuthToken defined. format how create name for video when stop on Youtube, VK, etc. "Камера 1, %START_TIME% - %END_TIME%: $TITLE$"
  videoDescFormatOnStart?: string; // only if oAuthToken defined. format how create description for video when start on Youtube, VK, etc. "Камера 1, %START_TIME% - %END_TIME%: $TITLE$\n$DESCRIPTION$"
  videoDescFormatOnStop?: string; // only if oAuthToken defined. format how create description for video when stop on Youtube, VK, etc. "Камера 1, %START_TIME% - %END_TIME%: $TITLE$\n$DESCRIPTION$"
  videoAccessPermission?: StreamAccessPermission; //only if oAuthToken defined. permission for stream view on target platform (VK, Youtube, etc)
};

export const isValidDestStreamBlock = (item?: DestStreamBlock): boolean => {
  if (!item) {
    return false;
  }
  if (item.id === StreamType.LocalFile || !item.enabled) {
    return true;
  }

  if (item.file?.length || item.url?.length || item.oAuthToken !== undefined) {
    return true;
  }

  return false;
};

export enum ScoreboardMode {
  AUTO = "AUTO",
  OFF = "OFF",
  ONLY_SCOREBOARD = "ONLY_SCOREBOARD",
  ONLY_DESCRIPTION = "ONLY_DESCRIPTION",
}

export interface StreamingProfileCustomConfig {
  storePanoramaVideoToSsd?: boolean;
  syncPanorama?: boolean;
  syncOperator?: boolean;
  destStreamBlock?: DestStreamBlock[];
  storeScoreboardVideoToSsd?: boolean;
  syncScoreboard?: boolean;
  scoreboardMode?: ScoreboardMode;
  recordPeople?: boolean;
}

type StreamingProfile = Readonly<{
  id: string;
  name: string;
  custom?: StreamingProfileCustomConfig;
  config?: CameraConfig;
}>;

export const streamAccessPermissionName = (type?: StreamAccessPermission): string => {
  switch (type) {
    case StreamAccessPermission.all:
      return intl().formatMessage({
        id: LOCALIZATION.stream_access_permision_all,
      });
    case StreamAccessPermission.members:
      return intl().formatMessage({
        id: LOCALIZATION.stream_access_permision_members,
      });
    case StreamAccessPermission.payed:
      return intl().formatMessage({
        id: LOCALIZATION.stream_access_permision_payed,
      });
    case StreamAccessPermission.admin:
      return intl().formatMessage({
        id: LOCALIZATION.stream_access_permision_admin,
      });
    case StreamAccessPermission.byLink:
      return intl().formatMessage({
        id: LOCALIZATION.stream_access_permision_by_link,
      });
  }
  return intl().formatMessage({ id: LOCALIZATION.unknown });
}

export default StreamingProfile;
