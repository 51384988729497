import { css } from "@emotion/css";
import { memo, useEffect, useMemo } from "react";
import { interval } from "rxjs";
import { higherOrderComponent } from "../../hocs/higherOrderComponent";
import { withOpenBlank } from "../../hocs/withOpenBlank";
import { useCameraShot } from "../../hooks/useCameraShot";
import useElementIsOverlaped from "../../hooks/useElementOverlaped";
import { withBoxTemplate } from "../../Pages/CameraSettingsPage/Components/UndistordSettings/BoxTemplate";
import LoadingComponent from "../Loader";
//@ts-ignore
const Image = (withOpenBlank(higherOrderComponent('img')))



export const CameraIntervalImage = (memo((props: {
    camera?: number | "full_frame_panorama" | "panorama_cropped", interval?: number, image_id?: string, onLoad?: (base64?: string) => void, className?: string, undistordTemplate?: boolean, width?: number;
    height?: number;
    overlapCheckDisable?: boolean,
    overrideSrc?: string
}) => {
    const { overrideSrc, camera, interval: intervalRemote = Infinity, image_id, onLoad, className, undistordTemplate, width, height, overlapCheckDisable } = props;
    const { ref: ovelapRef, isOverlaped, check: checkOverlaping } = useElementIsOverlaped<HTMLImageElement>()
    useEffect(() => {
        const rx$ = interval(5_000);
        const sub = rx$.subscribe(() => {
            checkOverlaping()
        })
        return () => {
            sub.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const { base64, } = useCameraShot({ camera, interval: overrideSrc || (!overlapCheckDisable && isOverlaped) ? Infinity : intervalRemote, width, height });
    // const { base64Url: base64, base64Url: src, fetch } = useRandomImg(width, height)
    // useEffect(() => {
    //     fetch();
    // }, [])
    useEffect(() => {
        base64 && onLoad?.(base64)
    }, [base64, onLoad])
    const MainRender = useMemo(() => () => <>
        {(overrideSrc || base64) && <Image ref={ovelapRef} className={`${className} ${css`
            // max-height: 100%;
        `}`} id={image_id} decoding={"sync"} loading="eager" src={overrideSrc ?? base64} alt="camera_interval_image" />}
    </>, [base64, className, image_id, ovelapRef, overrideSrc]);

    const WithUndistordTemplate = useMemo(() => undistordTemplate ? withBoxTemplate(MainRender) : MainRender, [MainRender, undistordTemplate])

    return <LoadingComponent pretendChildrenInitializing blur={0} isLoading={!base64 && !overrideSrc}>
        <WithUndistordTemplate></WithUndistordTemplate>
    </LoadingComponent>
}))
