import styled from "@emotion/styled";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import { SyntheticEvent, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../Components/Loader";
import LOCALIZATION from "../../../../../Localization";

interface IProps {
    active?: boolean,
    uuid?: string,
    name: string,
    type?: "perspective" | "cylindral",
    onSelect: (uuid?: string) => void,
    onEdit?: (uuid: string) => void,
    onRemove?: (uuid: string) => void,
    onDublicate?: (uuid: string) => void,
}
const Line = styled.hr`
 height: 1px; border: 0;
    background-color: #C2CFE0;
 `

const Led = styled.div`
    border-radius: 100%;
    width: 16px;
    height: 16px;
    background-color: ${props => (props["aria-selected"] ? '#5DCA8A' : '#C2CFE0')};
`

const Text = styled.span`
    color: var(--AdBlack, #000);
    /* c_body_P */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
`


export const StitichingConfigItem = (props: IProps) => {
    const { name, uuid, active, onSelect, onDublicate, onEdit, onRemove, } = props;

    const intl = useIntl();



    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = (event?: {}, reason?: "backdropClick" | "escapeKeyDown"): void => {
        if (event) {
            const e = event as SyntheticEvent
            if (e.stopPropagation) {
                e.stopPropagation();
            }
        }
        setAnchorEl(null);
    };
    const handleOpenMenu = (event: SyntheticEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleEditClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation()
        if (!uuid) {
            return;
        }
        onEdit?.(uuid)
        handleClose();
    }

    const handleDublicateClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation()
        if (!uuid) {
            return;
        }
        onDublicate?.(uuid)
        handleClose();
    }




    const removeItem: React.MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation()
        if (!uuid) {
            return;
        }
        if (!window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_remove }))) {
            return;
        }
        onRemove?.(uuid)
        handleClose();

    }





    return <>
        <Box>        <LoadingComponent ugly  >
            <Box sx={{
                "&:hover": {
                    backgroundColor: "#c2cfe069"
                },
                paddingLeft: `10px`,
                paddingRight: `10px`,
            }} onClick={() => onSelect(uuid)} display={'flex'} alignItems={'center'} height={56}>
                <Led aria-selected={active}></Led>
                <Text style={{ marginRight: "1rem", display: "flex" }}>  {name}  </Text>
                {Boolean(uuid) &&
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpenMenu}
                        sx={{
                            minWidth: 'auto',
                            cursor: 'pointer',
                            marginLeft: 'auto',
                        }}
                    >
                        <MoreVertIcon />
                    </Button>
                }

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={handleEditClick}
                    >
                        {intl.formatMessage({ id: LOCALIZATION.edit })}
                    </MenuItem>

                    <MenuItem
                        onClick={removeItem}
                    >
                        {intl.formatMessage({ id: LOCALIZATION.remove })}
                    </MenuItem>

                    <MenuItem
                        onClick={handleDublicateClick}
                    >
                        {intl.formatMessage({ id: LOCALIZATION.dublicate })}
                    </MenuItem>

                </Menu>

            </Box>
            <Line />

        </LoadingComponent>
        </Box>


    </>
}