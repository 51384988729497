import { Point } from "pixi.js-legacy";
import { Polygon } from "./Polygon";
import { Vertex } from "./Vertex";

export class Rectangle extends Polygon {
  public readonly lt: Vertex;
  public readonly rb: Vertex;
  public readonly lb: Vertex;
  public readonly rt: Vertex;
  constructor(
    vertices: [Vertex, Vertex],
    fillColor: number = 0x18a0fb,
    strokeColor = 0x18a0fb0d
  ) {
    const lt = vertices[0];
    const rb = vertices[1];
    const lb = new Vertex(new Point(lt.x, rb.y));
    const rt = new Vertex(new Point(rb.x, lt.y));
    super([lt, rb, lb, rt], fillColor, strokeColor);
    this.lb = lb;
    this.rb = rb;
    this.lt = lt;
    this.rt = rt;
  }

  protected onMovedVertices(vertice: Vertex) {
    if (vertice === this.lb) {
      this.lt.position.x = this.lb.x;
      this.rb.position.y = this.lb.y;
    } else if (vertice === this.lt) {
      this.lb.position.x = this.lt.x;
      this.rt.position.y = this.lt.y;
    } else if (vertice === this.rb) {
      this.rt.position.x = this.rb.x;
      this.lb.position.y = this.rb.y;
    } else if (vertice === this.rt) {
      this.rb.position.x = this.rt.x;
      this.lt.position.y = this.rt.y;
    }
    super.onMovedVertices(vertice);
  }
}
