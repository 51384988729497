import { LegacyRef, useEffect, useRef, useState } from "react";

type UseResizeObserverHook = {
  ref: LegacyRef<any>;
  width?: number;
  height?: number;
};

export const useResizeObserver = (): UseResizeObserverHook => {
  const ref = useRef<any>(undefined);
  const [size, setSize] = useState<{ width: number; height: number }>();

  useEffect(() => {
    if (!ref.current) return;

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.target.getBoundingClientRect();
        setSize({ width, height });
      }
    });

    observer.observe(ref.current);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return { ref, ...size };
};
