// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { delay } from "lodash";
import { useRef, useEffect, useState, RefObject } from "react";

export const useBbox: () => [
  DOMRect | null,
  RefObject<HTMLDivElement>
] = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [bbox, setBbox] = useState<DOMRect | null>(null);

  const set = () => {
    delay(() => {
      setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : null);
    }, 10);
  };

  useEffect(() => {
    set();
    window.addEventListener("resize", set);
    return () => window.removeEventListener("resize", set);
  }, []);

  return [bbox, ref];
};
