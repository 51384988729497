// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Rectangle, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';

import { makeStyles } from '@mui/styles';
import { Colors } from '../../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { AnalyticsSportType } from '../../../Data/Analytics/AnalyticsTypes';
import { SvgInHtml } from '../../../Icons/Icons';
import LOCALIZATION from '../../../Localization';
import AspectRatio from '../../_Layout/AspectRatio';
import PlaceholderStyled, { ChartTooltipItemStyle, ChartTooltipLabelStyle } from '../Styled';
import { HeatmapData, HeatmapReachItem, makeHeatmapGroups } from './helper';

import { gameFieldBasketballAspectRatio, GameFieldBasketballSvg, gameFieldFootballAspectRatio, GameFieldFootballSvg, gameFieldHandballAspectRatio, GameFieldHandballSvg, gameFieldHockeyAspectRatio, GameFieldHockeySvg, gameFieldMiniFootballAspectRatio, GameFieldMiniFootballSvg } from '../../../Icons/gameFieldsImages';

const useStyles = makeStyles(() => ({
  tooltipBody: {
    margin: 0,
    paddingLeft: 15,
    paddingRight: 15,
    border: "1pt solid rgb(204, 204, 204)"
  },
  tooltipTitle: {
    ...ChartTooltipLabelStyle,
    padding: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  tooltipItem: {
    ...ChartTooltipItemStyle,
    padding: 0,
    margin: 0,
    paddingBottom: 12,
  },
}));

type Props = Readonly<{
  data: HeatmapData | undefined;
  sportType: AnalyticsSportType | undefined;
  team1Name: string;
  team2Name: string;
  team1Color: string;
  team2Color: string;
  maxWidth?: number;
} & WrappedComponentProps>;

const AnalyticsHeatmapView: React.FC<Props> = (props: Props) => {
  const [viewWidth, setViewWidth] = React.useState<number>( 500 );
  const [viewHeight, setViewHeight] = React.useState<number>( 300 );
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  if (!!props.data) {
    const groups = makeHeatmapGroups(props.data, props.team1Color, props.team2Color);

    let fieldImage: SvgInHtml | undefined
    let fieldAspectRatio = gameFieldBasketballAspectRatio

    switch (props.sportType) {
      case AnalyticsSportType.Basketball: {
        fieldImage = GameFieldBasketballSvg;
        fieldAspectRatio = gameFieldBasketballAspectRatio;
        break
      }
      case AnalyticsSportType.Football: {
        fieldImage = GameFieldFootballSvg;
        fieldAspectRatio = gameFieldFootballAspectRatio;
        break
      }
      case AnalyticsSportType.Handball: {
        fieldImage = GameFieldHandballSvg;
        fieldAspectRatio = gameFieldHandballAspectRatio;
        break
      }
      case AnalyticsSportType.Hockey: {
        fieldImage = GameFieldHockeySvg;
        fieldAspectRatio = gameFieldHockeyAspectRatio;
        break
      }
      case AnalyticsSportType.MiniFootball: {
        fieldImage = GameFieldMiniFootballSvg;
        fieldAspectRatio = gameFieldMiniFootballAspectRatio;
        break
      }
    }


    const rectWidth = viewWidth / groups.width - 1;
    const rectHeight = viewHeight / groups.height - 1;
    const CustomShape = (props: any) => {
      const data = props.payload as HeatmapReachItem
      if (data.value === undefined) { // Dead ball - gray color
        return (
          <Rectangle
            {...props}
            height={rectHeight}
            width={rectWidth}
            x={props.x + 4}
            y={props.y + 4}
          />
        )
      }
      if (data.value === 0) {
        return (
          <Rectangle
            {...props}
            fillOpacity={ 0.5 }
            fill="url(#teamDrawDiagonalHatch)"
            height={rectHeight}
            width={rectWidth}
            x={props.x + 4}
            y={props.y + 4}
          />
        )
      }
      return (
        <g>
          <Rectangle
            {...props}
            height={rectHeight}
            width={rectWidth}
            x={props.x + 4}
            y={props.y + 4}
          />
          <text
            x={props.x + ((props?.node?.x === 0) ? 20 : 10)}
            y={props.y + 10}
            textAnchor="start" alignmentBaseline="hanging"
            filter = "url(#shadow)"
            fill={Colors.analyticsHeatmapText} fontSize={Sizes.title} fontFamily={font} fontWeight={Sizes.mediumWeight}
          >
            { props.name }
            {/* { `${props.name}) ${props.node.x}, ${props.node.y}` } */}
          </text>
        </g>
      );
    };

    const CustomCursor = (props: any) => {
      // const { x, y, width, height, stroke } = props;
      const { x, y } = props;
      return (
        <Rectangle
          fill="black"
          fillOpacity={1}
          stroke="black"
          x={x-1}
          y={y-1}
          width={rectWidth + 1}
          height={rectHeight + 1}
          style={{
            zIndex: 10
          }}
        />
      );
    };

    const onChartResize = (width: number, height: number) => {
      setViewWidth(width);
      setViewHeight(height);
    };

    const ImageView = () => {
      if (!fieldImage) {
        return null;
      }
      const FieldSvg = fieldImage
      return (
        <div style={{
          position: 'absolute',
          left: 10, right: 10,
          top: 0, bottom: 0,
          width:'auto',
          height:'auto',
          pointerEvents: 'none',
          userSelect: 'none',
          }}
        >
          <FieldSvg
           style={{
              position: 'absolute',
            left: 0, top: 0, width: '100%', height: '100%'
            }}
           />
        </div>
      )
    }

    return (
      <AspectRatio
        ratio={ fieldAspectRatio }
        maxWidth={ props.maxWidth }
      >
        <ResponsiveContainer width="100%" height="100%" onResize={ onChartResize } >
          <ScatterChart
          >
            <defs>
              <filter id="shadow" x="0" y="0" width="200%" height="200%">
                <feDropShadow dx="0" dy="0" stdDeviation="1" floodColor={ Colors.black } floodOpacity="1" />
              </filter>
              <pattern
                id="teamDrawDiagonalHatch"
                width="12"
                height="12"
                patternTransform="rotate(45 0 0)"
                patternUnits="userSpaceOnUse"
              >
                <rect x="0" y="0" width="20" height="20" fill={ props.team1Color } />
                <line x1="0" y1="0" x2="0" y2="12" stroke={ props.team2Color } strokeWidth="12" />
              </pattern>
            </defs>
            <XAxis
              hide
              dataKey="x"
              domain={[0, groups.width]}
              type="number"
              interval={0}
            />
            <YAxis
              hide
              dataKey="y"
              reversed
              interval={0}
              domain={[0, groups.height]}
            />

            <Tooltip
              allowEscapeViewBox={{y: true}}
              cursor={<CustomCursor />}
              wrapperStyle={ { background: Colors.mainBackground, zIndex: 2 } }
              content={({ payload }) => {
                const data = payload && payload[0] && payload[0].payload as HeatmapReachItem;

                const team1Line = <p className={ classes.tooltipItem } style={{ color: props.team1Color }}>{ props.team1Name}: {data?.team1_percent} %  ({data?.team1_time_sec.toFixed(0)} {props.intl.formatMessage({ id: LOCALIZATION.analytics_seconds_short })})</p>
                const team2Line = <p className={ classes.tooltipItem } style={{ color: props.team2Color }} >{ props.team2Name}: {data?.team2_percent} %  ({data?.team2_time_sec.toFixed(0)} {props.intl.formatMessage({ id: LOCALIZATION.analytics_seconds_short })})</p>
                const deadBallLine = <p className={ classes.tooltipItem } >{props.intl.formatMessage({ id: LOCALIZATION.analytics_no_players })}: {data?.deadBall_percent} %  ({data?.deadBall_time_sec.toFixed(0)} {props.intl.formatMessage({ id: LOCALIZATION.analytics_seconds_short })})</p>
                // const deadBallLine = <p className={ classes.tooltipItem } >{props.intl.formatMessage({ id: LOCALIZATION.analytics_no_players })}: {data?.deadBall_time_sec.toFixed(0)} {props.intl.formatMessage({ id: LOCALIZATION.analytics_seconds_short })}</p>

                return (
                  <div id="tooltip" className={ classes.tooltipBody} >
                    <p className={ classes.tooltipTitle } >{props.intl.formatMessage({ id: data?.value === 0 ? LOCALIZATION.analytics_zone_possession_draw :  LOCALIZATION.analytics_zone_possession })}</p>
                    { data?.value !== undefined && team1Line }
                    { data?.value !== undefined && team2Line }
                    { data?.value === undefined && deadBallLine }
                  </div>
                );
              }}
            />

            {groups.data.map((group) => (
              <Scatter
                name={group.label}
                data={group.data}
                fill={group.color}
                fillOpacity={group.alpha}
                stroke={group.color}
                strokeOpacity={0}
                shape={CustomShape}
              />
            ))}
          </ScatterChart>
        </ResponsiveContainer>
        <ImageView />
      </AspectRatio>
    );
  }

  return (
    <PlaceholderStyled>
      { props.intl.formatMessage({ id: LOCALIZATION.loading_metrics }) }
    </PlaceholderStyled>
  );
};

export default injectIntl(AnalyticsHeatmapView);
