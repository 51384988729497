// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { SxProps, Theme } from '@mui/material';
import { findLastIndex } from 'lodash';
import React from 'react';

import { injectIntl, WrappedComponentProps } from 'react-intl';
import LOCALIZATION from '../../../../Localization';
// import { createStyles, makeStyles } from '@mui/styles';
// import { Colors } from '../../../../Configuration/Styles/Colors';

// import { Sizes } from '../../../../Configuration/Styles/Sizes';
// import { Fonts } from '../../../../Configuration/Styles/Fonts';
import SortedTable from '../../../../Components/SortedTable';

import dayjs from 'dayjs';
import { VideoPlayerCommands } from '../../../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import { AddMenuItem } from '../../../../Components/_BaseUI/AddDropdownButton';
import { secondsToHHmmss, timeToSeconds } from '../../../../Components/_BaseUI/AnalyticsTimeField';
import { useCurrentFont } from '../../../../Configuration/Styles/Fonts';
import { EMPTY_ARRAY } from '../../../../constants';
import { analyticsCanAddEventInsideEvent, analyticsEventTypeGetEndType, analyticsEventTypeHasScore, analyticsEventTypeHasTeamSide, AnalyticsGameEvent, AnalyticsGameEventType, AnalyticsGameEventWithId, AnalyticsTeamSide, analyticsTeamSideOpposit } from '../../../../Data/Analytics/AnalyticsGameEvent/AnalyticsGameEvent';
import { AnalyticsSportType, AnalyticsType } from '../../../../Data/Analytics/AnalyticsTypes';
import AnalyticsVideoArchive from '../../../../Data/Analytics/AnalyticsVideoArchive/AnalyticsVideoArchive';
import Order from '../../../../Data/_Networking/ReactQuery/Order';
import ResponseMany from '../../../../Data/_Networking/ReactQuery/ResponseMany';
import { getRenderCellContent, headCells, useRowStyles } from './renderCellContent';

var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')

export const DEFAULT_EVENTS = (gameVideoInfo?: AnalyticsVideoArchive): AnalyticsGameEventWithId[] => {
  let endTimestamp = "00:01:00"
  if (gameVideoInfo?.start?.length && gameVideoInfo?.end?.length) { // count default game dutation from video (end - start)
    const startAt = dayjs(gameVideoInfo.start);
    const endAt = dayjs(gameVideoInfo.end);
    const durationSec = endAt.diff(startAt, "seconds")
    endTimestamp = secondsToHHmmss( durationSec) ?? endTimestamp;
  }
  return [{
    id: 1,
    type: AnalyticsType.GameEvent,
    event_type: AnalyticsGameEventType.GameStart,
    timestamp: "00:00:00",
    title: "Game Start",
    team1_side: AnalyticsTeamSide.Left,
    team2_side: AnalyticsTeamSide.Right,
  },
  {
    id: 2,
    type: AnalyticsType.GameEvent,
    event_type: AnalyticsGameEventType.GameEnd,
    timestamp: endTimestamp,
    title: "Game End",
    team1_score: 0,
    team2_score: 0,
  }]
};

/*
const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  titleText: {
    fontFamily: Fonts.main,
    fontSize: Sizes.caption,
    fontWeight: Sizes.boldWeight,
    color: Colors.mainTitle,
  },
  icon: {
    display: 'flex',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    border: `1px solid ${Colors.analyticsCircleBorder}`,
    // backgroundColor: '#FFFFFF',
    marginRight: '12pt',
  },
  valueText: {
    fontFamily: Fonts.main,
    fontWeight: Sizes.regularWeight,
    textAlign: 'center'
  },
}));
*/

type Props = Readonly<{
  sx?: SxProps<Theme>;
  sportType?: AnalyticsSportType;
  team1Name?: string;
  team2Name?: string;
  team1ShortName?: string;
  team2ShortName?: string;
  events: AnalyticsGameEvent[] | undefined;
  stickyHeader?: boolean;
  onEventsUpdate: (events: AnalyticsGameEvent[] | undefined) => void
  videoPlayerCommands: VideoPlayerCommands | undefined
} & WrappedComponentProps>;

const GameResultCard: React.FC<Props> = (props: Props) => {
  // const classes = useStyles();
  const { font } = useCurrentFont()
  const rowClasses = useRowStyles({ fontFamily: font });

  const [page] = React.useState(0);
  const [limit, setLimit] = React.useState(1000);
  const [orderBy, setOrderBy] = React.useState<keyof AnalyticsGameEventWithId>('timestamp');
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [selected, setSelected] = React.useState<AnalyticsGameEventWithId[]>(EMPTY_ARRAY);

  // console.log('status:', status);

  const handlePeerPageChange = (newLimit: number) => {
    setSelected(EMPTY_ARRAY);
    setLimit(newLimit);
  };

  const handleRequestSort = (newOrderField: keyof AnalyticsGameEventWithId) => {
    if (orderBy === newOrderField) {
      setOrder((order === Order.ASC) ? Order.DESC : Order.ASC);
    }

    setOrderBy(newOrderField);
  };
  const handleRowItemClicked = (item: AnalyticsGameEventWithId): void => {
  };


  const reindexEventsWithId = (events?: (AnalyticsGameEvent | AnalyticsGameEventWithId)[]): AnalyticsGameEventWithId[] => {
    return events?.map( (event , index) => {
      return {
        ...event,
        id: index + 1,
      }
    }) || []
  };

  const eventsWithIndexes: ResponseMany<AnalyticsGameEventWithId[]> = {
    data: reindexEventsWithId(props.events),
    meta: {
      status: 200
    }
  }

  const updateEvent = (event?: AnalyticsGameEventWithId | undefined): AnalyticsGameEventWithId[] => {
    let events = eventsWithIndexes.data
    let updateIndex = event?.id;
    if (!event || !updateIndex) {// reindexEventsWithId not use #0
      return events;
    }
    updateIndex--;
    if (updateIndex >= events.length) {
      return events;
    }
    events[updateIndex] =  event;

    return events;
  };

  const renderCellContent = getRenderCellContent({
    sportType: props.sportType,
    rowClasses: rowClasses,
    events: eventsWithIndexes.data,
    team1Name: props.team1ShortName,
    team2Name: props.team2ShortName,
    onVideoStart(item: AnalyticsGameEventWithId) {
      let timeSec = timeToSeconds(item.timestamp)
      if (timeSec !== undefined) {
        timeSec -= 2 // small gap before event to make user see the event
      }
      props.videoPlayerCommands?.play(timeSec)
    },
    onEventUpdate(newEvent: AnalyticsGameEventWithId) {
      const newEventsList = updateEvent(newEvent)
      props.onEventsUpdate(newEventsList);
    },

    showScissorsCutBtn: props.videoPlayerCommands?.isPlayerActive(),
    onScissorsCutPress(item: AnalyticsGameEventWithId) {
      const timeSec = props.videoPlayerCommands?.currentTimeSec();
      if (timeSec !== undefined) {
        const timestamp = secondsToHHmmss(timeSec);
        if (timestamp) {
          const newEvent = {
            ...item,
            timestamp
          }
          const newEventsList = updateEvent(newEvent)
          props.onEventsUpdate(newEventsList);
        }
      }
    },
  })
/*
  const countEventsWithType = (type: AnalyticsGameEventType): number => {
    return props.events?.filter((event) => (event.event_type === type))?.length || 0
  }

  const getAddMenuItems2 = (event: AnalyticsGameEventWithId): AddMenuItem[] | undefined =>  {
    const outArr:AddMenuItem[] = []

    // add Time
    const timeStartCount = countEventsWithType(AnalyticsGameEventType.TimeStart);
    const timeEndCount = countEventsWithType(AnalyticsGameEventType.TimeEnd);
    const timesCount = Math.max(timeStartCount, timeEndCount);
    outArr.push({
      id: AnalyticsGameEventType.TimeStart,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_time_start }, {number: timesCount || "" })
    });
    outArr.push({
      id: AnalyticsGameEventType.TimeEnd,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_time_end }, {number: timesCount || ""})
    });

    // add Overtime
    const overtimeStartCount = countEventsWithType(AnalyticsGameEventType.OvertimeStart);
    const overtimeEndCount = countEventsWithType(AnalyticsGameEventType.OvertimeEnd);
    const overtimeCount = Math.max(overtimeStartCount, overtimeEndCount);
    outArr.push({
      id: AnalyticsGameEventType.OvertimeStart,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_overtime_start }, {number: overtimeCount || "" })
    });
    outArr.push({
      id: AnalyticsGameEventType.OvertimeEnd,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_overtime_end }, {number: overtimeCount || ""})
    });

    // add Penalty
    const penaltyStartCount = countEventsWithType(AnalyticsGameEventType.PenaltyStart);
    const penaltyEndCount = countEventsWithType(AnalyticsGameEventType.PenaltyEnd);
    const penaltyPeriodCount = Math.max(penaltyStartCount, penaltyEndCount);
    outArr.push({
      id: AnalyticsGameEventType.PenaltyStart,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_penalty_start }, {number: penaltyPeriodCount || "" })
    });
    outArr.push({
      id: AnalyticsGameEventType.PenaltyEnd,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_penalty_end }, {number: penaltyPeriodCount || ""})
    });

    // add Goal
    const goalCount = countEventsWithType(AnalyticsGameEventType.Goal);
    outArr.push({
      id: AnalyticsGameEventType.Goal,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_goal }, {number: goalCount || "" })
    });

    return outArr;
  }
  */

  const getAddMenuItems = (parentEvent: AnalyticsGameEventWithId): AddMenuItem[] | undefined =>  {
    const outArr:AddMenuItem[] = []

    // add Time
    outArr.push({
      id: AnalyticsGameEventType.TimeStart,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_time })
    });

    // add Overtime
    outArr.push({
      id: AnalyticsGameEventType.OvertimeStart,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_overtime })
    });

    // add Penalty series
    outArr.push({
      id: AnalyticsGameEventType.PenaltyStart,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_penalty_series })
    });

    // add Time-out
    outArr.push({
      id: AnalyticsGameEventType.TimeOutStart,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_timeout })
    });

    // add Goal
    outArr.push({
      id: AnalyticsGameEventType.Goal,
      title: props.intl.formatMessage({ id: LOCALIZATION.game_event_goal })
    });

    return outArr;
  }

  const onAddMenuItemSelect = (parentEvent: AnalyticsGameEventWithId, selectedMenuItem?: AddMenuItem) => {
    const addType = selectedMenuItem?.id as AnalyticsGameEventType;
    let secondAddType: AnalyticsGameEventType | undefined = analyticsEventTypeGetEndType(addType);

    const curEvents: AnalyticsGameEventWithId[] = eventsWithIndexes.data || [];
    // sort evnets array by time
    curEvents.sort((a: AnalyticsGameEventWithId, b: AnalyticsGameEventWithId) => (a.timestamp?.localeCompare(b.timestamp ?? "") ?? 0))

    let parentIndex = curEvents.findIndex((item: AnalyticsGameEventWithId) => (item.id === parentEvent.id));

    // prevent add time, overtime, penaly inside other time, overtime, penalty
    if (secondAddType && !analyticsCanAddEventInsideEvent(parentEvent.event_type, secondAddType)) { // timeout can add inside time, overtime, penalty
      const nextFindType = analyticsEventTypeGetEndType(parentEvent.event_type);
      if (nextFindType && (parentEvent.event_type !== AnalyticsGameEventType.GameStart)) {
        for( let index = parentIndex + 1; index < curEvents.length; index++) {
          const textEvent = curEvents[index];
          if (textEvent.event_type === nextFindType) {
            parentEvent = textEvent;
            parentIndex = index;
            break;
          }
        }
      }
    }

    // find event after parent
    let nextAfterParentEvent: AnalyticsGameEventWithId | undefined
    if (parentIndex >= 0 && (parentIndex+1) < curEvents.length) {
      nextAfterParentEvent = curEvents[parentIndex+1]
    }

    let team1_side: AnalyticsTeamSide | undefined = undefined;
    let team2_side: AnalyticsTeamSide | undefined = undefined;
    if (analyticsEventTypeHasTeamSide(addType, undefined)) {
      // find event that we can copy team side
      for(let index = parentIndex; index >=0 ; index --) {
        const testEvent = curEvents[index];
        if (analyticsEventTypeHasTeamSide(testEvent.event_type, undefined)) {
          if (testEvent.event_type === AnalyticsGameEventType.GameStart) {// do not chnage side when init from game
            team1_side = testEvent.team1_side;
            team2_side = testEvent.team2_side;
          }
          else { // swap side when init from time, overtime, penalty
            team1_side = analyticsTeamSideOpposit(testEvent.team1_side);
            team2_side = analyticsTeamSideOpposit(testEvent.team2_side);
          }
          break;
        }
      }
    }

    let team1_score: number | undefined = undefined;
    let team2_score: number | undefined = undefined;
    if (addType ===  AnalyticsGameEventType.Goal) {
      team1_score = 0;
      team2_score = 0;

      // find event that we can copy score
      for(let index = parentIndex; index >=0 ; index --) {
        const testEvent = curEvents[index];
        if (analyticsEventTypeHasScore(testEvent.event_type)) {
          team1_score = testEvent.team1_score;
          team2_score = testEvent.team2_score;
          break;
        }
      }
    }

    const addEvents: AnalyticsGameEventWithId[] = []
    // add first event
    addEvents.push({
      id: -1,
      event_type: addType,
      timestamp: parentEvent.timestamp,
      team1_side,
      team2_side,
      team1_score,
      team2_score
    })

    // add second event if need

    if (secondAddType) {

      team1_score = undefined;
      team2_score = undefined;
      if (analyticsEventTypeHasScore(secondAddType)) {
        team1_score = 0;
        team2_score = 0;

        // find event that we can copy score
        for(let index = parentIndex; index >=0 ; index --) {
          const testEvent = curEvents[index];
          if (analyticsEventTypeHasScore(testEvent.event_type)) {
            team1_score = testEvent.team1_score;
            team2_score = testEvent.team2_score;
            break;
          }
        }
      }


      // try count end timestamp as 1/2 time from parent and next events
      let parentTimestampSec = timeToSeconds(parentEvent.timestamp);
      let endTimestamp: string | undefined
      if (parentTimestampSec !== undefined && nextAfterParentEvent?.timestamp?.length) {
        let endTimestampSec = timeToSeconds(nextAfterParentEvent.timestamp)
        if (endTimestampSec !== undefined) {
          if (endTimestampSec > parentTimestampSec) {
            endTimestampSec = parentTimestampSec + (endTimestampSec - parentTimestampSec) / 2;
            endTimestamp = secondsToHHmmss( endTimestampSec);
          }
          else {// dif is  0 careate empty event
            endTimestamp = parentEvent.timestamp;
          }
        }
      }
      if (!endTimestamp) {// for add event add + 60 sec to split int from start
        endTimestamp = (parentTimestampSec !== undefined) ? secondsToHHmmss( parentTimestampSec + 60) : parentEvent.timestamp;
      }

      addEvents.push({
        id: -1,
        event_type: secondAddType,
        timestamp: endTimestamp,
        team1_score,
        team2_score
      })
    }

    // add all events we need
    curEvents.splice((parentIndex<0) ? curEvents.length : parentIndex+1 , 0, ...addEvents);

    props.onEventsUpdate(curEvents);
  }


  const handleDelete = (event?: AnalyticsGameEventWithId) => {
    if (event && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_remove }))) {
      const curEvents = eventsWithIndexes.data || [];
      // sort evnets array by time
      curEvents.sort((a: AnalyticsGameEventWithId, b: AnalyticsGameEventWithId) => (a.timestamp?.localeCompare(b.timestamp ?? "") ?? 0))

      const index = curEvents.findIndex((item: AnalyticsGameEventWithId) => (item.id === event.id));

      let somethingRemoved = false;
      if (index >= 0) {
        if (event.event_type === AnalyticsGameEventType.TimeOutStart) {// remove timeout start -> also remove timeout end
          const nextIndex = curEvents.findIndex((item: AnalyticsGameEventWithId, itemIndex) => (itemIndex > index && item.event_type === AnalyticsGameEventType.TimeOutEnd));
          if (nextIndex >= 0) { // first remove next to not break index order
            curEvents.splice(nextIndex , 1);
          }
          curEvents.splice(index , 1);
          somethingRemoved = true;
        }
        else if (event.event_type === AnalyticsGameEventType.TimeOutEnd) {// remove timeout -> also remove timeout start
          const prevIndex = findLastIndex(curEvents, (item: AnalyticsGameEventWithId, itemIndex) => (item.event_type === AnalyticsGameEventType.TimeOutStart), index);

          curEvents.splice(index , 1);
          if (prevIndex >= 0) {
            curEvents.splice(prevIndex , 1);
          }
          somethingRemoved = true;
        }
        else { // remove other element as single element
          curEvents.splice(index , 1);
          somethingRemoved = true;
        }
      }

      if (somethingRemoved) {
        props.onEventsUpdate(curEvents);
      }
    }
  };

  return (
    <SortedTable<AnalyticsGameEventWithId>
      sx={{
        ...props.sx,
        // maxHeight: 220,
        // height: 220,
        // border: `solid 1px ${ Colors.mainGrey }`
      }}
      stickyHeader={ props.stickyHeader }
      page={ page }
      rowsPerPage={ limit }
      // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
      orderBy={ orderBy }
      order={ order }
      headCells={ headCells(props.team1ShortName, props.team2ShortName) }
      selected={ selected }
      manyItems={ eventsWithIndexes }
      renderCellContent={ renderCellContent }
      onPageChange={ () => {} }
      onPeerPageChange={ handlePeerPageChange }
      onRowClick={ handleRowItemClicked }
      // onSelect={  (!mobileView) ? handleSelectIDs : undefined }
      // onSelect={  handleSelectIDs }
      onRequestSort={ handleRequestSort }
      // onMenuEdit={ handleRowItemClicked }
      // onMenuCopy={ handleMenuCopy }
      // onMenuDelete={ handleMenuDelete }
      onRemoveIconButtonClicked={ handleDelete }
      showRemoveIconButton={(event) => (
        event.event_type !== AnalyticsGameEventType.GameStart && event.event_type !== AnalyticsGameEventType.GameEnd
        // event.event_type !== AnalyticsGameEventType.TimeEnd  && event.event_type !== AnalyticsGameEventType.OvertimeEnd &&
        // event.event_type !== AnalyticsGameEventType.PenaltyEnd
      )}
      showAddIconButton={ (event) => (
        event.event_type !== AnalyticsGameEventType.GameEnd
      ) }
      getAddMenuItems={ getAddMenuItems }
      onAddMenuItemSelect={ onAddMenuItemSelect }
    />
  )
};

export default injectIntl(GameResultCard);
