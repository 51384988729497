import { css } from "@emotion/css";
import { ReactNode } from "react";
import { useIntl } from "react-intl";
import {
  fetchActualVersions,
  useFetchActualMetrics
} from "../Data/Software/useActualSoftware";
import LOCALIZATION from "../Localization/index";
import { useIsServer } from "./useIsServer";

interface Component {
  size?: string;
  tag?: string;
  version?: string;
}

interface DeviceData {
  JP_VER?: string;
  L4T_VER?: string;
  VERSION?: string;
  device?: string;
  cameraHandler?: Component;
  mainBackend?: Component;
  napi?: Component;
  nginxCC?: Component;
  operator?: Component;
  scoreboard?: Component;
  [key: string]: any;
}

interface StaticData {
  VERSION?: string;
  device?: string;
  front?: Component;
  mainBackend?: Component;
  napi?: Component;
  operator?: Component;
  cameraHandler?: Component;
  scoreboard?: Component;
  nginxCC?: Component;
  other?: {
    [key: string]: DeviceData;
  };
  L4T_VER?: string,
  JP_VER?: string;
  serialNumber?: string;
}

interface VersionInfo {
  device: string;
  version: string;
  index: number;
}

interface Versions {
  mainBackend: VersionInfo[];
  nginxCC: VersionInfo[];
  front: VersionInfo[];
  cameraHandler: VersionInfo[];
  napi: VersionInfo[];
  operator: VersionInfo[];
  scoreboard: VersionInfo[];
  JP_VER: VersionInfo[];
  L4T_VER: VersionInfo[],
}

const red = css`color: red;`;
const green = css``;

/**
 * @deprecated exist only cos of the critical amout of legacy that has to be support
 */
export const versionSummaryFunction = (data: {
  staticData?: StaticData;
  formatMessage: ReturnType<typeof useIntl>["formatMessage"];
  actualVersions: Awaited<ReturnType<typeof fetchActualVersions>> | undefined;
  isServer: boolean;
}) => {
  const { formatMessage, staticData, actualVersions, isServer } = data;
  const actualVersionsLocal = actualVersions?.map(v => ({ app: v.value.app, version: v.value.version }));
  if (!staticData) {
    return;
  }
  const isActual = (app: string, version: string) => {
    const act = actualVersionsLocal?.find(v => v.app === app)?.version;

    return !act?.length ? true : actualVersionsLocal?.find(v => v.app === app)?.version === version;
  };
  const versions: Versions = {
    mainBackend: [],
    nginxCC: [],
    JP_VER: [],
    napi: [],
    front: [],
    operator: [],
    scoreboard: [],
    cameraHandler: [],
    L4T_VER: []
  };

  const deviceOrder = [
    staticData.device || "device0",
    ...Object.keys(staticData.other || {}),
  ];
  const deviceIndices: { [device: string]: number } = {};
  deviceOrder.forEach((device, index) => {
    deviceIndices[device] = index;
  });

  const deviceName = staticData.device || "device0";
  const extractIndex = (input: string): number => {
    const match = input.match(/\d+$/);
    return match ? parseInt(match[0], 10) : 0;
  };
  const deviceIndex = extractIndex(deviceName);

  // Collect versions from staticData
  if (staticData.mainBackend?.version) {
    versions.mainBackend.push({
      device: deviceName,
      version: staticData.mainBackend.version,
      index: deviceIndex,
    });
  }

  if (staticData.nginxCC?.version) {
    versions.nginxCC.push({
      device: deviceName,
      version: staticData.nginxCC.version,
      index: deviceIndex,
    });
  }


  if (staticData.JP_VER) {
    versions.JP_VER.push({
      device: deviceName,
      version: staticData.JP_VER,
      index: deviceIndex,
    });
  }

  if (staticData.front?.version) {
    versions.front.push({
      device: deviceName,
      version: staticData.front.version,
      index: deviceIndex,
    });
  }

  if (staticData.napi?.version) {
    versions.napi.push({
      device: deviceName,
      version: staticData.napi.version,
      index: deviceIndex,
    });
  }

  if (staticData.operator?.version) {
    versions.operator.push({
      device: deviceName,
      version: staticData.operator.version,
      index: deviceIndex,
    });
  }

  if (staticData.scoreboard?.version) {
    versions.scoreboard.push({
      device: deviceName,
      version: staticData.scoreboard.version,
      index: deviceIndex,
    });
  }

  if (staticData.cameraHandler?.version) {
    versions.cameraHandler.push({
      device: deviceName,
      version: staticData.cameraHandler?.version,
      index: deviceIndex,
    });
  }

  if (staticData.L4T_VER) {
    versions.L4T_VER.push({
      device: deviceName,
      version: staticData.L4T_VER,
      index: deviceIndex,
    });
  }
  // Collect


  // Collect versions from other devices
  for (const [otherDeviceName, deviceData] of Object.entries(
    staticData.other || {}
  )) {

    const otherDeviceIndex = extractIndex(otherDeviceName);

    if (deviceData.mainBackend?.version) {
      versions.mainBackend.push({
        device: otherDeviceName,
        version: deviceData.mainBackend.version,
        index: otherDeviceIndex ?? 0,
      });
    }

    if (deviceData.cameraHandler?.version) {
      versions.cameraHandler.push({
        device: otherDeviceName,
        version: deviceData.cameraHandler.version,
        index: otherDeviceIndex ?? 0,
      });
    }

    if (deviceData.front?.version) {
      versions.front.push({
        device: otherDeviceName,
        version: deviceData.front.version,
        index: otherDeviceIndex ?? 0,
      });
    }


    if (deviceData.scoreboard?.version) {
      versions.scoreboard.push({
        device: otherDeviceName,
        version: deviceData.scoreboard.version,
        index: otherDeviceIndex ?? 0,
      });
    }


    if (deviceData.operator?.version) {
      versions.operator.push({
        device: otherDeviceName,
        version: deviceData.operator.version,
        index: otherDeviceIndex ?? 0,
      });
    }
    if (deviceData.nginxCC?.version) {
      versions.nginxCC.push({
        device: otherDeviceName,
        version: deviceData.nginxCC.version,
        index: otherDeviceIndex ?? 0,
      });
    }

    if (deviceData.napi?.version) {
      versions.napi.push({
        device: otherDeviceName,
        version: deviceData.napi.version,
        index: otherDeviceIndex ?? 0,
      });
    }


    if (deviceData.JP_VER) {
      versions.JP_VER.push({
        device: otherDeviceName,
        version: deviceData.JP_VER,
        index: otherDeviceIndex ?? 0,
      });
    }

    if (deviceData.L4T_VER) {
      versions.L4T_VER.push({
        device: otherDeviceName,
        version: deviceData.L4T_VER,
        index: otherDeviceIndex ?? 0,
      });
    }
  }

  // Build output
  const output: { actual: boolean, node: ReactNode }[] = [];

  // MB
  if (versions.mainBackend.length > 0) {
    const allVersions = versions.mainBackend.map((v) => v.version);
    const firstVersion = allVersions[0];
    const allSame = allVersions.every((v) => v === firstVersion);
    if (allSame) {
      const actual = isActual("mainBackend", firstVersion);
      output.push({ node: <>MB <span className={isServer ? actual ? green : red : ''}>v{firstVersion}</span></>, actual });
    } else {
      versions.mainBackend.sort((a, b) => a.index - b.index);
      versions.mainBackend.forEach((v) => {
        const actual = isActual("mainBackend", v.version);
        output.push({ node: <>MB{Boolean(v.index) && v.index} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
      });
    }
  }

  // Nginx
  if (versions.nginxCC.length > 0) {
    const allVersions = versions.nginxCC.map((v) => v.version);
    const firstVersion = allVersions[0];
    const allSame = allVersions.every((v) => v === firstVersion);
    if (allSame) {
      const actual = isActual("nginxCC", firstVersion);
      output.push({ node: <>Nginx <span className={isServer ? actual ? green : red : ''}>v{firstVersion}</span></>, actual });
    } else {
      versions.nginxCC.sort((a, b) => a.index - b.index);
      versions.nginxCC.forEach((v) => {
        const actual = isActual("nginxCC", v.version);
        output.push({ node: <>Nginx{Boolean(v.index) && v.index} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
      });
    }
  }

  // CH
  if (versions.cameraHandler.length > 0) {
    const allVersions = versions.cameraHandler.map((v) => v.version);
    const firstVersion = allVersions[0];
    const allSame = allVersions.every((v) => v === firstVersion);
    if (allSame) {
      const actual = isActual("cameraHandler", firstVersion);
      output.push({ node: <>CH <span className={isServer ? actual ? green : red : ''}>v{firstVersion}</span></>, actual });
    } else {
      versions.cameraHandler.sort((a, b) => a.index - b.index);
      versions.cameraHandler.forEach((v) => {
        const actual = isActual("cameraHandler", v.version);
        output.push({ node: <>CH{Boolean(v.index) && v.index} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
      });
    }
  }



  // Front
  if (versions.front.length > 0) {
    const allVersions = versions.front.map((v) => v.version);
    const firstVersion = allVersions[0];
    const allSame = allVersions.every((v) => v === firstVersion);
    if (allSame) {
      const actual = isActual("front", firstVersion);
      output.push({ node: <>Front <span className={isServer ? actual ? green : red : ''}>v{firstVersion}</span></>, actual });
    } else {
      versions.front.sort((a, b) => a.index - b.index);
      versions.front.forEach((v) => {
        const actual = isActual("front", v.version);
        output.push({ node: <>Front{Boolean(v.index) && v.index} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
      });
    }
  }


  // NAPI
  if (versions.napi.length > 0) {
    const allVersions = versions.napi.map((v) => v.version);
    const firstVersion = allVersions[0];
    const allSame = allVersions.every((v) => v === firstVersion);
    if (allSame) {
      const actual = isActual("napi", firstVersion);
      output.push({ node: <>NAPI <span className={isServer ? actual ? green : red : ''}>v{firstVersion}</span></>, actual });
    } else {
      versions.napi.sort((a, b) => a.index - b.index);
      versions.napi.forEach((v) => {
        const actual = isActual("napi", v.version);
        output.push({ node: <>NAPI{Boolean(v.index) && v.index} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
      });
    }
  }


  // Operator
  if (versions.operator.length > 0) {
    const allVersions = versions.operator.map((v) => v.version);
    const firstVersion = allVersions[0];
    const allSame = allVersions.every((v) => v === firstVersion);
    if (allSame) {
      const actual = isActual("operator", firstVersion);
      output.push({ node: <>Operator <span className={isServer ? actual ? green : red : ''}>v{firstVersion}</span></>, actual });
    } else {
      versions.operator.sort((a, b) => a.index - b.index);
      versions.operator.forEach((v) => {
        const actual = isActual("operator", v.version);
        output.push({ node: <>Operator{Boolean(v.index) && v.index} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
      });
    }
  }


  // Scoreboard
  if (versions.scoreboard.length > 0) {
    const allVersions = versions.scoreboard.map((v) => v.version);
    const firstVersion = allVersions[0];
    const allSame = allVersions.every((v) => v === firstVersion);
    if (allSame) {
      const actual = isActual("scoreboard", firstVersion);
      output.push({ node: <>Scoreboard <span className={isServer ? actual ? green : red : ''}>v{firstVersion}</span></>, actual });
    } else {
      versions.scoreboard.sort((a, b) => a.index - b.index);
      versions.scoreboard.forEach((v) => {
        const actual = isActual("scoreboard", v.version);
        output.push({ node: <>Scoreboard{Boolean(v.index) && v.index} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
      });
    }
  }


  // JP_VER
  if (versions.JP_VER.length > 0) {
    versions.JP_VER.sort((a, b) => a.index - b.index);
    versions.JP_VER.forEach((v) => {
      const actual = isActual("JP_VER", v.version);
      output.push({ node: <>JP{Boolean(v.index) && String(v.index)} <span className={isServer ? actual ? green : red : ''}>v{v.version}</span></>, actual });
    });
  }

  return {
    node: <>{formatMessage({ id: LOCALIZATION.software })}: {Boolean(output.length) ? output.map((v, i) => <span key={i}>{v.node}{i < output.length - 1 ? ','
      : ''} </span>) : <>{formatMessage({ id: LOCALIZATION.unknown })}</>}</>, isOutdated: Boolean(output.length) ? !output.map(v => v.actual).reduce((acc, curr) => acc && curr) : true
  };
};

export const useVersionSummary = (data: { staticData?: StaticData }) => {

  const { formatMessage } = useIntl();
  const { data: actualVersions } = useFetchActualMetrics();
  const isServer = useIsServer()

  return versionSummaryFunction({ ...data, formatMessage, actualVersions, isServer });
};
