import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import FieldSet, { FieldSetInputType } from "../../../../../../Components/_BaseUI/FieldSet/index";
import { useCameraCommonSettings } from "../../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { useObjectChanged } from "../../../../../../hooks/useObjectChanged";
import LOCALIZATION from "../../../../../../Localization/index";
import { Region } from "../../../ImageAdjust/AutoAdjustmentForm/ImagePointer/Region.interface";


interface IProps {
    value: Region,
    onChange?: (region: Region) => void,
    width: number, height: number,
}



export const RegionForm = (props: IProps) => {
    const { value, onChange, width, height } = props;
    const { data: commonSettings } = useCameraCommonSettings()
    const { formatMessage } = useIntl();
    const { value: outsideValue } = useObjectChanged(value)

    const [left, setLeft] = useState<number>(outsideValue.left);
    const [top, setTop] = useState<number>(outsideValue.top);
    const [right, setRight] = useState<number>(outsideValue.right);
    const [bottom, setBottom] = useState<number>(outsideValue.bottom);

    const [region, setRegion] = useState<Region>({ left, top, right, bottom })


    useEffect(() => {
        setBottom(outsideValue.bottom);
        setLeft(outsideValue.left);
        setTop(outsideValue.top);
        setRight(outsideValue.right);
    }, [
        outsideValue,
    ])
    useEffect(() => {
        setRegion({ left, top, right, bottom })
    }, [left, right, top, bottom,])

    useEffect(() => {
        onChange?.(region);
    }, [onChange, region])



    return <>
        <Grid xs={12} md={5} sm={5} xl lg item>
            <FieldSet
                inputSize={5}
                labelSize={false}
                hideSeparator
                label={`${formatMessage({ id: LOCALIZATION.left }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}
                input={{ type: FieldSetInputType.Number, max: width, min: 0, step: 1, value: left, onChange: (v) => setLeft(Number(v)) }}
            />
        </Grid>
        <Grid xs={12} md={5} sm={5} xl lg item>
            <FieldSet
                inputSize={5}
                labelSize={false}
                hideSeparator
                label={`${formatMessage({ id: LOCALIZATION.top }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}
                input={{ type: FieldSetInputType.Number, max: height, min: 0, step: 1, value: top, onChange: (v) => setTop(Number(v)) }}
            ></FieldSet>
        </Grid>
        <Grid xs={12} md={5} sm={5} xl lg item>
            <FieldSet
                inputSize={5}
                labelSize={false}
                hideSeparator
                label={`${formatMessage({ id: LOCALIZATION.right }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}
                input={{ type: FieldSetInputType.Number, max: width, min: 0, step: 1, value: right, onChange: (v) => setRight(Number(v)) }}
            ></FieldSet>
        </Grid>
        <Grid xs={12} md={5} sm={5} xl lg item>
            <FieldSet
                inputSize={5}
                labelSize={false}
                hideSeparator
                label={`${formatMessage({ id: LOCALIZATION.bottom }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}
                input={{ type: FieldSetInputType.Number, max: height, min: 0, step: 1, value: bottom, onChange: (v) => setBottom(Number(v)) }}
            ></FieldSet>
        </Grid>
    </>
}