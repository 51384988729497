// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, TextField } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import cn from 'classnames';
import { format } from 'date-fns';
import React from 'react';

import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import { useStylesInputBox } from './FieldSetText';
import { getLocale } from './helpers';
import { FieldSetInputType } from './index';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  label: {
    fontWeight: Sizes.regularWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.subtitle,
    color: Colors.mainTitle,
  },
}));

export type FieldSetTimeTillTime = Readonly<{
  type: FieldSetInputType.TimeTillTime;
  errorTime?: string;
  helpText?: string;
  value: {
    time1: string;
    time2: string;
  };
  errorCheckPattern1?: (time: string) => boolean;
  errorCheckPattern2?: (time: string) => boolean;
  onChangeTime1: (time: string) => void;
  onChangeTime2: (time: string) => void;
}>;

type Props = Readonly<{
  input: FieldSetTimeTillTime;
  isError?: boolean;
  onIsError?: (isError: boolean) => void;
}>;

const FieldSetTimeTillTimeComponent: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()

  const labelClasses = useStyles({ fontFamily: font });

  const classes = useStylesInputBox({ fontFamily: font });
  const now = new Date();
  const { localization: { locale } } = useAppConfig();
  const dateTime1 = (): Date => (
   (props.input.value?.time1 && props.input.value.time1.length) ?
    new Date(`${format(now, 'yyyy-MM-dd')}T${props.input.value.time1}`)
    :
    now
  )
  const dateTime2 = (): Date => (
    (props.input.value?.time2 && props.input.value.time2.length) ?
    new Date(`${format(now, 'yyyy-MM-dd')}T${props.input.value.time2}`)
    :
    now
  )

  const [time1, setTime1] = React.useState<Date | null>(dateTime1());
  const [time2, setTime2] = React.useState<Date | null>(dateTime2());

  const userAcceptTime1 = (time: Date | null) => {
    try {
      const newTime = format(time || new Date(), 'HH:mm');

      props.input.onChangeTime1(newTime);

      if (props.input.errorCheckPattern1 && props.onIsError) {
        props.onIsError(props.input.errorCheckPattern1(newTime));
      }
    } catch (ignore) {
      // ignore
    }
  };
  const userAcceptTime2 = (time: Date | null) => {
    try {
      const newTime = format(time || new Date(), 'HH:mm');

      props.input.onChangeTime2(newTime);

      if (props.input.errorCheckPattern2 && props.onIsError) {
        props.onIsError(props.input.errorCheckPattern2(newTime));
      }
    } catch (ignore) {
      // ignore
    }
  };

  const renderInput = (params: MuiTextFieldPropsType): React.ReactElement => (
    <TextField
      fullWidth
      { ...params }
      InputProps={ {
        className: cn(classes.input, classes.singleLine, {
          [classes.error]: props.isError,
        }),
      } }
      helperText={ props.input.errorTime }
      error={ (props.input.errorTime !== undefined) }
    />
  );

  return (
    <LocalizationProvider
      locale={ getLocale(locale) }
      dateAdapter={ AdapterDateFns }
    >
    <Box display="flex" alignItems="center" flexDirection="column" >
      <Box display="flex" alignItems="center" flexDirection="row" width="100%" >
          <MobileTimePicker
              value={ time1 }
              onChange={ (newValue) => {
                setTime1(newValue);
              } }
              onAccept={ userAcceptTime1 }
              renderInput={ renderInput }
          />
          <div style={ { margin: 5 } }>-</div>
          <MobileTimePicker
              value={ time2 }
              onChange={ (newValue) => {
                setTime2(newValue);
              } }
              onAccept={ userAcceptTime2 }
              renderInput={ renderInput }
          />
      </Box>

      {(props.input.helpText) &&
      <div
      className={ labelClasses.label }
      >
        { props.input.helpText }
          </div>
      }
    </Box>
    </LocalizationProvider>
  );
};


export default FieldSetTimeTillTimeComponent;
