// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { z } from 'zod';


export enum StreamingSettingsKey {
  MaintenanceMode = "maintenance_mode",
  StreamingSettingsSelectedProfileID = "streaming_settings_selected_profile_id",
  OperatorVideoFilePath = "operator_video_file_path",
  PanoramaVideoFilePath = "panorama_video_file_path",
  EpgAutoImportFormatType = "epg_auto_import_format_type",
  EpgAutoImportURL = "epg_auto_import_url",
  EpgAutoImportPeriodSec = "epg_auto_import_period_sec", // as string bacause DB field is string
  EpgAutoImportStreamingProfileId = "epg_auto_import_streaming_profile_id",
  EpgAutoImportByUrlLastSuccessDateAt = "epg_auto_import_by_url_last_success_date_at", // ISO date with timezone
  EpgAutoImportByUrlLastErrorDateAt = "epg_auto_import_by_url_last_error_date_at", // ISO date with timezone
  EpgAutoImportDefaultGameType = "epg_auto_import_default_game_type",
  EpgAutoImportDefaultSportType = "epg_auto_import_default_sport_type",
}


export type StreamingSettingsKeyValue = {
  [key in StreamingSettingsKey]?: string;
};


export type StreamingSettingsFromDB = Readonly<{
  key: StreamingSettingsKey;
  value: string;
}>;


export const StreamingSettingsModel = z.object({
  [StreamingSettingsKey.StreamingSettingsSelectedProfileID]: z.string().optional(),
  [StreamingSettingsKey.MaintenanceMode]: z.string().transform((value: string) => (value === 'true')),
  [StreamingSettingsKey.OperatorVideoFilePath]: z.string().optional().nullable(),
  [StreamingSettingsKey.PanoramaVideoFilePath]: z.string().optional().nullable(),
  [StreamingSettingsKey.EpgAutoImportFormatType]: z.string().optional().nullable(),
  [StreamingSettingsKey.EpgAutoImportURL]: z.string().optional().nullable(),
  [StreamingSettingsKey.EpgAutoImportPeriodSec]: z.string().optional().nullable(),// as string bacause DB field is string
  [StreamingSettingsKey.EpgAutoImportStreamingProfileId]: z.string().optional().nullable(),
  [StreamingSettingsKey.EpgAutoImportByUrlLastSuccessDateAt]: z.string().optional().nullable(),
  [StreamingSettingsKey.EpgAutoImportByUrlLastErrorDateAt]: z.string().optional().nullable(),
  [StreamingSettingsKey.EpgAutoImportDefaultGameType]: z.string().optional().nullable(),
  [StreamingSettingsKey.EpgAutoImportDefaultSportType]: z.string().optional().nullable(),
});


export type StreamingSettings = z.infer<typeof StreamingSettingsModel>;
