import { useEffect, useState } from "react";

type ImageSize =
  | {
      width: number;
      height: number;
    }
  | undefined;

const useImageSize = (imageUrl?: string) => {
  const [imageSize, setImageSize] = useState<ImageSize>();

  useEffect(() => {
    if (!imageUrl) {
      return;
    }
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      setImageSize({
        width: image.width,
        height: image.height,
      });
    };

    return () => {
      image.onload = null;
    };
  }, [imageUrl]);

  return imageSize;
};

export default useImageSize;
