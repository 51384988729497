// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved

import { BehaviorSubject } from "rxjs";
import { ThemeVariables } from "../../hooks/useTheme";

// Author: Vitaliy Alekseev <villy@cybercamera.ru>

export type FontName = string

export class Fonts {
    private static _main$ = new BehaviorSubject(`var(${ThemeVariables.font})`);
    static get main() {
        return this._main$.value;
    }
    static get main$() {
        return this._main$.asObservable()
    }
    static set main(v: FontName) {
        this._main$.next(v);
    }
}

export const useCurrentFont = () => {
    return { font: `var(${ThemeVariables.font})` };
}