import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { isNil } from "lodash";

interface CellProps {
    top?: number;
    left?: number;
    horizontalCentering?: boolean;
    verticalCentering?: boolean;
}

const verticalCentering = css`
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const horizontalCentering = css`
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

const absoluteCentering = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Cell = styled.div<CellProps>`
  width: 20%;
  height: 20%;
  border: 1px solid #a7a7a7;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  ${(props) => (!isNil(props.top) && { top: `${props.top}%` })};
  ${(props) => (!isNil(props.left) && { left: `${props.left}%` })};
  ${(props) => props.horizontalCentering && props.verticalCentering && absoluteCentering};
  ${(props) => !props.verticalCentering && props.horizontalCentering && horizontalCentering};
  ${(props) => !props.horizontalCentering && props.verticalCentering && verticalCentering};
`;
