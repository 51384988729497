import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FieldSetDateComponent from '../../Components/_BaseUI/FieldSet/FieldSetDateComponent';
import FieldSetSelectComponent from '../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { FieldSetInputType } from '../../Components/_BaseUI/FieldSet/index';
import { DownloadFromCloud, RefreshIcon2 } from '../../Icons/Icons';
import LOCALIZATION from '../../Localization/index';

// Определяем возможные типы для statistics и attendance
export type StatisticType = 'total_number_of_people' | 'people_on_field' | 'people_out_field';
export type AttendanceType = 'by_hour' | 'by_event' | 'by_10_minutes';

// Типизируем комбинации строк с использованием шаблонных строк
export type StatisticAttendanceCombination = `op_${StatisticType}_${AttendanceType}`;

// Определяем интерфейс для данных формы
interface FormData {
    statistics: StatisticType;
    attendance: AttendanceType;
    selectedDate: string;
    period: string;
    periodTimestamps: {
        startTimestamp: number;
        endTimestamp: number;
    };
    combination: StatisticAttendanceCombination; // Новое поле для комбинации
}

// Пропсы компонента с коллбэком и начальными значениями
export interface StatisticFormProps {
    onFormChange: (formData: FormData) => void;
    onRefreshClick?: () => void;
    onDownloadClick?: () => void;
    initialStatistics?: StatisticType;
    initialAttendance?: AttendanceType;
    initialSelectedDate?: string;
    initialPeriod?: string;
}


const IconButton = styled.button`
   background: none;
   border: none;

    align-content: center;
    & :hover {
        cursor: pointer;
    };
`

export const StatisticForm: React.FC<StatisticFormProps> = ({
    onFormChange,
    onRefreshClick,
    onDownloadClick,
    initialStatistics = 'total_number_of_people', // Значение по умолчанию
    initialAttendance = 'by_hour', // Значение по умолчанию
    initialSelectedDate = moment().format('YYYY-MM-DD'), // Дата по умолчанию сегодня
    initialPeriod = '1_day', // Значение по умолчанию
}) => {
    const intl = useIntl();

    // Состояния с типизацией, начальные значения из пропсов
    const [statistics, setStatistics] = useState<StatisticType>(initialStatistics);
    const [attendance, setAttendance] = useState<AttendanceType>(initialAttendance);
    const [selectedDate, setSelectedDate] = useState<string>(initialSelectedDate);
    const [period, setPeriod] = useState<string>(initialPeriod);

    // Функция для получения таймстемпов начала и конца периода
    const getPeriodTimestamps = (periodValue: string, selectedDateValue: string): { startTimestamp: number; endTimestamp: number } => {
        const startDate = moment(selectedDateValue, 'YYYY-MM-DD');
        let endDate: moment.Moment;

        switch (periodValue) {
            case '1_day':
                endDate = startDate.clone().add(1, 'days');
                break;
            case '3_days':
                endDate = startDate.clone().add(3, 'days');
                break;
            case 'week':
                endDate = startDate.clone().add(7, 'days');
                break;
            default:
                endDate = startDate.clone().add(1, 'days'); // По умолчанию 1 день
        }

        return {
            startTimestamp: startDate.valueOf(),
            endTimestamp: endDate.valueOf(),
        };
    };

    // Обновление значений формы
    const handleFormChange = (updatedField: 'statistics' | 'attendance' | 'selectedDate' | 'period', value: string): void => {
        let newStatistics = statistics;
        let newAttendance = attendance;
        let newSelectedDate = selectedDate;
        let newPeriod = period;

        // Обновляем соответствующее значение
        switch (updatedField) {
            case 'statistics':
                newStatistics = value as StatisticType;
                setStatistics(newStatistics);
                break;
            case 'attendance':
                newAttendance = value as AttendanceType;
                setAttendance(newAttendance);
                break;
            case 'selectedDate':
                newSelectedDate = value;
                setSelectedDate(newSelectedDate);
                break;
            case 'period':
                newPeriod = value;
                setPeriod(newPeriod);
                break;
            default:
                break;
        }

    };

    useEffect(() => {

        // Генерация комбинации строки statistics и attendance
        const combination: StatisticAttendanceCombination = `op_${statistics}_${attendance}`;

        // Вызываем коллбэк с обновленными данными
        const periodTimestamps = getPeriodTimestamps(period, selectedDate);
        onFormChange({
            statistics,
            attendance,
            selectedDate,
            period,
            periodTimestamps,
            combination, // Добавляем комбинацию в данные формы
        });
    }, [attendance, onFormChange, period, selectedDate, statistics])

    return (
        <Grid gap={2} className={css`padding: 20px;`} container>
            {/* Компонент для выбора статистики */}
            <Grid item>
                <FieldSetSelectComponent
                    input={{
                        type: FieldSetInputType.Select,
                        label: intl.formatMessage({ id: LOCALIZATION.statistics }),
                        options: [
                            { id: 'total_number_of_people', name: intl.formatMessage({ id: LOCALIZATION.total_number_of_people }) },
                            { id: 'people_on_field', name: intl.formatMessage({ id: LOCALIZATION.people_on_field }) },
                            { id: 'people_out_field', name: intl.formatMessage({ id: LOCALIZATION.people_out_field }) },
                        ],
                        value: statistics,
                        onChange: (e) => handleFormChange('statistics', e), // Обработка изменения
                    }}
                />
            </Grid>

            {/* Компонент для выбора посещаемости */}
            <Grid item>
                <FieldSetSelectComponent
                    input={{
                        label: intl.formatMessage({ id: LOCALIZATION.attendance }),
                        type: FieldSetInputType.Select,
                        options: [
                            { id: 'by_hour', name: intl.formatMessage({ id: LOCALIZATION.by_hour }) },
                            { id: 'by_event', name: intl.formatMessage({ id: LOCALIZATION.by_event }) },
                            { id: 'by_10_minutes', name: intl.formatMessage({ id: LOCALIZATION.by_10_minutes }) },
                        ],
                        value: attendance,
                        onChange: (e) => handleFormChange('attendance', e), // Обработка изменения
                    }}
                />
            </Grid>

            {/* Компонент для выбора даты */}
            <Grid item>
                <FieldSetDateComponent
                    labelText={intl.formatMessage({ id: LOCALIZATION.date })}
                    input={{
                        dateFormat: 'YYYY-MM-DD',
                        type: FieldSetInputType.Date,
                        value: { date: selectedDate },
                        onChangeDate: (e) => e && handleFormChange('selectedDate', e), // Обработка изменения даты
                    }}
                />
            </Grid>

            {/* Компонент для выбора периода */}
            <Grid item>
                <FieldSetSelectComponent
                    input={{
                        label: intl.formatMessage({ id: LOCALIZATION.period }),
                        type: FieldSetInputType.Select,
                        options: [
                            { id: '1_day', name: intl.formatMessage({ id: LOCALIZATION.one_day }) },
                            { id: '3_days', name: intl.formatMessage({ id: LOCALIZATION.three_days }) },
                            { id: 'week', name: intl.formatMessage({ id: LOCALIZATION.week }) },
                        ],
                        value: period,
                        onChange: (e) => handleFormChange('period', e), // Обработка изменения периода
                    }}
                />
            </Grid>
            <Grid marginLeft={"auto"} item>

                <Grid gap={2} display={"flex"} alignItems={"center"} justifyContent={"center"} container>
                    <Grid xs={"auto"} display={"flex"} alignItems={"center"} justifyContent={"center"} item onClick={onDownloadClick}>
                        <IconButton><DownloadFromCloud /></IconButton>
                    </Grid>
                    <Grid xs={"auto"} display={"flex"} alignItems={"center"} justifyContent={"center"} item onClick={onRefreshClick}>
                        <IconButton><RefreshIcon2 /></IconButton>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};