// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>
import { EditorView } from '@codemirror/view';
import { Dialog, Grid } from '@mui/material';
import { clone, cloneDeep, merge } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CodeMirrorMerge from 'react-codemirror-merge';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useIsProd } from '../../hooks/useIsProd';
//@ts-ignore
import { EditorState } from '@codemirror/state';
import { css } from '@emotion/css';
import { createPortal } from 'react-dom';
import AnyChildren from '../../Tools/AnyChildren';
import { AppLocale, getLocalStorageLocale, useAppConfig } from '../AppContextProvider/helpers';
import cn from '../cn.json';
import en from '../en.json';
import ru from '../ru.json';

const Original = CodeMirrorMerge.Original;
const Modified = CodeMirrorMerge.Modified;
type Props = Readonly<{
  children: AnyChildren;
}>;

type Localization = {
  [key: string]: string;
};

const TRANSLATIONS_DEV: { [key in AppLocale]: Localization } = {
  en,
  ru,
  [AppLocale.Cn]: cn,
};
const TRANSLATIONS_PROD: { [key in AppLocale]: Localization } = {
  en,
  ru: merge(clone(en), clone(ru)),
  [AppLocale.Cn]: merge(clone(en), clone(cn)),
};
const TRANSLATIONS = process.env.NODE_ENV !== "development" ? TRANSLATIONS_PROD : TRANSLATIONS_DEV;
// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

export const intl = (locale?: AppLocale) => {
  const lang = locale || getLocalStorageLocale();

  return (
    createIntl({
      locale: lang,
      messages: TRANSLATIONS[lang],
    }, cache)
  );
};


const getUndefinedKeys = (obj1: object, obj2: object) => {
  const clone1: any = cloneDeep(obj1)
  const clone2 = cloneDeep(obj2)


  const keys = Object.keys(clone1).filter(key => !clone2.hasOwnProperty(key));
  return keys
}


const DiffCode = ({ obj1, obj2 }: {
  obj1: string,
  obj2: string
}) => {
  return <>
    <Grid container>
      <CodeMirrorMerge className={css`width:100%;`} orientation="b-a">
        <Original value={obj2} />
        <Modified
          value={obj1}
          extensions={[EditorView.editable.of(true), EditorState.readOnly.of(false)]}
        />
      </CodeMirrorMerge>

    </Grid>
  </>
}



const LocalizationProvider: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();
  const isProd = useIsProd();
  const Base = TRANSLATIONS.ru;
  const required = useMemo(() => {
    if (isProd) {
      return;
    }
    return Object.entries(TRANSLATIONS).map(([locale, v]) => {
      const keys = getUndefinedKeys(Base, v);
      if (!keys.length) {
        return undefined;
      }
      const clone1: any = {}
      const clone2: any = {}
      for (const key of keys) {
        clone1[key] = Base[key];
        clone2[key] = ''
      }
      return { a: clone1, b: clone2, locale }
    }).filter(v => !!v)
  }, [Base, isProd])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  useEffect(() => {
    required?.length && setIsOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <RawIntlProvider
      value={ intl(locale) }
    >
      {createPortal(<Dialog fullWidth maxWidth={false} open={isOpen} onClose={handleClose}>
        {!!required?.length && <>
          {required.map((v, i) =>
            <>
              <>В словаре {v?.locale} не хватает переводов</>
              <DiffCode key={i} obj1={JSON.stringify(v?.a, undefined, 2)} obj2={JSON.stringify(v?.b, undefined, 2)} />
            </>
          )}
        </>}
      </Dialog>, document.body)}
      {props.children}

    </RawIntlProvider>
  );
};


export default LocalizationProvider;
