import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { memo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../../Components/Loader";
import Popup from "../../../../../../Components/_Layout/Popup";
import { useCalibrationConfig } from "../../../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCalibrationConfig";
import { useCurrentUndistordConfigs } from "../../../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCurrentUndistordConfigs";
import LOCALIZATION from "../../../../../../Localization";
import { useObjectChanged } from "../../../../../../hooks/useObjectChanged";
import { UndistordCalcutationForm } from "./UndistordCalcutationForm";

type Props = Readonly<{
    onClose: () => void
    handleSubmit?: (v?: {
        type?: "fisheye" | "regular",
        kdata?: number[],
        ddata?: number[],
        angle?: number,
    }) => Promise<void> | void,
    uuid: string,
}>;


const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: 'white',
        padding: '40px',
    }
}))




export const UndistordModalResults = memo((props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote, uuid } = props;
    const { isLoading: loadingCurrentConfigs } = useCurrentUndistordConfigs();
    const intl = useIntl();

    const classes = useStyles();
    const { data: calibratedConfig, isLoading: isLoadingCalibrationConfig } = useCalibrationConfig({ uuid })
    const { value: initialEditValues } = useObjectChanged(calibratedConfig?.getCalibrationState.config)

    const [configToSave, setConfigToSave] = useState<{
        type?: "fisheye" | "regular",
        kdata?: number[],
        ddata?: number[],
        angle?: number,
    }>()

    useEffect(() => {
        initialEditValues && setConfigToSave(initialEditValues)
    }, [initialEditValues])


    const onCloseCallback = () => {
        onClose();
    }


    const saveConfig = (async () => {
        handleSubmitRemote?.(configToSave);
        onClose();
    })




    return <>
        <LoadingComponent isLoading={loadingCurrentConfigs || isLoadingCalibrationConfig}>
        <Popup
            headerTitle={`${intl.formatMessage({ id: LOCALIZATION.undistord_coefficients })}`}
            isApplyDisabled={loadingCurrentConfigs}
            // isCloseDisabled={patchLoading}
            applyTitle={`${intl.formatMessage({ id: LOCALIZATION.save })}`}
            onApply={saveConfig}
            onClose={onCloseCallback}
            classes={classes}
            isMoreWide
        >
            <Grid container >

                    {initialEditValues && <UndistordCalcutationForm initialValues={initialEditValues} uuid={uuid} onChange={(v) => setConfigToSave(v)} />
                    }

            </Grid>

            </Popup>
        </LoadingComponent>
    </>
})
