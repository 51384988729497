import { css } from "@emotion/css";
import { Grid, IconButton, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../../Components/Loader/index";
import Popup from "../../../../../../Components/_Layout/Popup/index";
import { useTemporaryApplyStitchingConfig } from "../../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useTemporaryApplyStichingConfig";
import { StitchingConfig } from "../../../../../../Data/NapiCameraHandlerV2/hooks/stiching/StitchinConfig.interface";
import { BreakpointEnum, useWidth } from "../../../../../../hooks/useWidth";
import LOCALIZATION from "../../../../../../Localization/index";
import { FitSvg, RefreshSvg } from "../../../../../Panorama/shared/SvgIcons";
import { RawSaveItemsStateProvider, useSaveRawCoefficent } from "../useSaveCylinderCoefficent";
import { Form } from "./Form";
import { ResultImage } from "./ResultImage";
import { PanelStateProvider, usePanelActions } from "./usePanelActions";




const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: 'white',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))


interface Props {
    onClose?: () => void,
    onApply?: (state: Partial<StitchingConfig>) => void,

}



const SensitiveStitchingInternal = ({ onClose, onApply: onApplyRemote }: Props) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { fit, fullSize, update, test, lastAction } = usePanelActions()
    const { mutateAsync: tempApply, isLoading: applingConfig } = useTemporaryApplyStitchingConfig()
    const { state, } = useSaveRawCoefficent()

    useEffect(() => {
        return () => {
            tempApply(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onApply = useCallback(() => {
        onApplyRemote?.(state)
    }, [onApplyRemote, state])
    const currentBreakPoint = useWidth()
    return <>
        <PanelStateProvider>

            <Popup
                closeTitle={`${formatMessage({ id: LOCALIZATION.close_btn })}`}
                isFullScreen
                headerTitle={`${formatMessage({ id: LOCALIZATION.panorama_image_stitching_raw })}`}
                // isApplyDisabled={loadingList}
                onApply={onApply}
                onClose={onClose}
                classes={classes}
                applyTitle={`${formatMessage({ id: LOCALIZATION.save })}`}

            >
                <LoadingComponent ugly >

                    <Grid container >
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} className={css`
                display:flex;
    align-items: center;
    justify-content: center;
            `}>
                            <IconButton
                                onClick={fit}
                                size="small"
                                style={{ margin: 5, width: 38 }}
                            >
                                <FitSvg />
                            </IconButton>



                            <IconButton
                                onClick={fullSize}
                                size="small"
                                className={css`
                            margin: 5px;
                            width: 65px;
                            border-radius: 5px;
                            background: rgb(144, 160, 181);
                            height: fit-content;
                            color: white;
          `}

                            >
                                100%
                            </IconButton>
                            <IconButton
                                onClick={update}
                                size="small"
                                style={{ margin: 5, width: 38 }}
                            >
                                <RefreshSvg />
                            </IconButton>

                            <IconButton
                                onClick={test}
                                size="small"
                                className={css`
                            margin: 5px;
                            width: 65px;
                            border-radius: 5px;
                            background: rgb(144, 160, 181);
                            height: fit-content;
                            color: white;
          `}

                            >
                                {formatMessage({ id: LOCALIZATION.test })}

                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid gap={1} className={css`max-height: 100%;`} container>
                        <Grid xs={12} md={5} className={css`height: ${currentBreakPoint <= BreakpointEnum.md ? '50%' : '100%'};`} item>
                            <ResultImage />

                        </Grid>
                        <Grid xs item>
                            <Form />

                        </Grid>
                    </Grid>
                </LoadingComponent>

            </Popup>
        </PanelStateProvider>
    </>
}

export const SensitiveStitching = (props: Props) => {
    const { state, set } = useSaveRawCoefficent()
    const { onApply: onApplyRemote } = props
    const onApply = useCallback((state: Partial<StitchingConfig>) => {
        set(state)
        onApplyRemote?.(state)
    }, [onApplyRemote, set])
    return <RawSaveItemsStateProvider initialValue={state} uuid={(state!.uuid) as string}>
        <SensitiveStitchingInternal {...props} onApply={onApply} />
    </RawSaveItemsStateProvider>

}