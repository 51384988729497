import { GraphQLClient } from "graphql-request";
import { createContext } from "react";
import { NODE_JS_BACKEND_URL } from "../constants";
import { authMiddleware } from "../GraphQL/middlewares/auth.middleware";

interface IProps {
  cameraHandlerGraphQL: GraphQLClient;
  exposedConfigurationsClient: GraphQLClient;
  napiGraphQLScoerboardClient: GraphQLClient;
}
export const napiGraphQLCameraHandlerClient = new GraphQLClient(
  `${NODE_JS_BACKEND_URL}/camera-handler`,
  {
    /** @todo extend when it'll be reason */
    requestMiddleware: authMiddleware,
  }
);

export const napiGraphQLExposedConfigurationsClient = new GraphQLClient(
  `${NODE_JS_BACKEND_URL}/exposed-configurations`,
  {
    /** @todo extend when it'll be reason */
    requestMiddleware: authMiddleware,
  }
);

export const napiGraphQLScoerboardClient = new GraphQLClient(
  `${NODE_JS_BACKEND_URL}/scoreboard`,
  {
    /** @todo extend when it'll be reason */
    requestMiddleware: authMiddleware,
  }
);
/**
 *  Context for keeping/changing the state of page filtering state
 */
export const NAPICameraHandlerGraphQLClient = createContext<IProps>({
  cameraHandlerGraphQL: napiGraphQLCameraHandlerClient,
  exposedConfigurationsClient: napiGraphQLExposedConfigurationsClient,
  napiGraphQLScoerboardClient,
});