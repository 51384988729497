// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';

import { Sizes } from '../../../Configuration/Styles/Sizes';


const ConfigurableMetricsChartViewRootStyled = styled('div')(() => ({
  margin: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  position: 'relative',
}));


const ConfigurableMetricsChartViewRPlaceholderStyled = ({ children }: { children: ReactNode }) => {
  const { font } = useCurrentFont()
  return <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    fontSize: Sizes.title,
    fontWeight: Sizes.mediumWeight,
    fontFamily: font
  }}>{children}</div>
}

export {
  ConfigurableMetricsChartViewRootStyled,
  ConfigurableMetricsChartViewRPlaceholderStyled,
};

