import { merge } from "lodash";
import { createContext, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import LoadingComponent from "../../../../../Components/Loader/index";
import { PanoramaStoreConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/PanoramaStoreConfig.interface";
import { usePanoramaStore } from "../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/usePanoramaStore";
import { useObjectChanged } from "../../../../../hooks/useObjectChanged";



const initialValue: PanoramaStoreConfig = {
    uuid: '',
    name: "",
    disabled: true,
    config: {
        original: {
            bitrate: 30 * 1000000,
            "max-parts": 3,
            h264: false,
            "full-frame-jpeg": 1,
        },
        operator: {
            external: "/var/run/camera-handler/camera",
        },
        dest: {
            "rename-interval": 600,
            original: [
                { type: "mp4", file: "/mnt/ssd/panorama/panorama_{timestamp}_part.mp4" }
            ],
            operator: [
                {
                    type: "mp4",
                    file: "/mnt/ssd/operator/operator_{timestamp}.mp4"
                }
            ]
        }
    }
}


const PanoramaStoreEditorContext = createContext<{ state: PanoramaStoreConfig, setState: React.Dispatch<React.SetStateAction<PanoramaStoreConfig>> }>(
    {
        state: initialValue,
        setState: function(value: SetStateAction<PanoramaStoreConfig>): void {
            throw new Error("Function not implemented.");
        }
    });


export const PanoramaStoreEditorStateProvider: React.FC<{ children: ReactNode, uuid: string }> = ({ children, uuid }) => {
    const [state, setState] = useState<PanoramaStoreConfig>(initialValue);
    const { value: remoteConfig } = usePanoramaStore({ uuid })
    const { value: baseConfig } = useObjectChanged(remoteConfig)

    useEffect(() => {
        const st = ({ ...initialValue, ...baseConfig })
        setState(st)
    }, [baseConfig,]);


    return (
        <LoadingComponent ugly pretendChildrenInitializing isLoading={!state?.uuid}>
            <PanoramaStoreEditorContext.Provider value={{ state, setState }}>

                {children}

            </PanoramaStoreEditorContext.Provider>
        </LoadingComponent>
    );
};




export const useStoreEditorState = () => {
    const { state, setState } = useContext(PanoramaStoreEditorContext);
    const setStateCallback = useCallback((newPatch: Partial<PanoramaStoreConfig>) => {
        setState(merge(JSON.parse(JSON.stringify(state)), JSON.parse(JSON.stringify(newPatch))))
    }, [setState, state]);

    return { state, patch: setStateCallback, set: setState };
};