import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { useNAPIGraphQLClient } from "../../../../../hooks/useNAPIGraphQLClient";
import { useCurrentStitchingConfigsCacheKey } from "../useStitchingList";

const mutationQl = gql`
  mutation RemoveStitchingConfig($uuid: String!) {
    removeStitchingConfig(uuid: $uuid)
  }
`;
interface IResponse {
  removeStitchingConfig: boolean;
}

type Request = { uuid: string };

const debouncedRequest = (patch: Request, client: GraphQLClient) =>
  client.request<IResponse>(mutationQl, patch);

export const useDeleteStitchingConfig = () => {
  const queryClient = useQueryClient();
  const client = useNAPIGraphQLClient() ?? new GraphQLClient("");
  const request = (patch: Request) =>
    debouncedRequest(patch, client) ?? Promise.resolve(undefined);
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
    queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentStitchingConfigsCacheKey);
      },
    });
  };
  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentStitchingConfigsCacheKey);
      },
    });
    return res;
  };
  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
