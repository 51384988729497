// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import cn from 'classnames';
import React from 'react';

import { PlatformConfiguration } from '../../../Configuration/PlatformConfiguration';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { MainAppBarHeight } from '../../../Menu/AppBar';


type Props = Readonly<{
  isAuthorised: boolean;
  children: JSX.Element | JSX.Element[];
}>;


const menuWidth = PlatformConfiguration.menuWidth;
const useStyles = makeStyles((theme: Theme) => createStyles<any, { fontFamily: FontName }>({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(4),
    marginTop: MainAppBarHeight,
    marginLeft: -menuWidth,
    [theme.breakpoints.up('md')]: {
      marginTop: MainAppBarHeight,
      marginLeft: 0,
      padding: theme.spacing(4)
    },
    width: '100%'
  },
  rootUnaUth: {
    height: '100vh',
    position: 'relative',
    minHeight: '400px',
    margin: 0,
    padding: 0,
  },
}));

const ContentWrapper: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  return (
    <Grid
      className={ cn(classes.root, {
        [classes.rootUnaUth]: !props.isAuthorised,
      }) }
    >
      { props.children }
    </Grid>
  );
};


export default ContentWrapper;
