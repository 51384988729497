

import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient, } from 'react-query';
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";
import { useUndistordConfigsListCacheKey } from "../../useUndistordConfigsList";

const mutationQl = gql`
  mutation RemoveConfig($uuid: ID!) {
    removeConfig(uuid: $uuid)
  }
`;

interface IResponse {}

interface IPatchCommonSettingProps {}

interface IBody {
  uuid: string;
}

export const useRemoveUndistordConfig = (props?: IPatchCommonSettingProps) => {
  const queryClient = useQueryClient();
  const client = useCameraHandler() ?? new GraphQLClient("");
  const request = (patch: IBody) =>
    client.request<IResponse>(mutationQl, { ...patch });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
    queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useUndistordConfigsListCacheKey);
      },
    });
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useUndistordConfigsListCacheKey);
      },
    });
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};