import { useMutation, useQueryClient } from "react-query";
import { NODE_JS_BACKEND_URL } from "../../../../../constants";
import { getAccessToken } from "../../../../../Tools/token";
export const useGetStichingImagesMetaCacheKey =
  "useGetStichingImagesMetaCacheKey";

const uploadImage = (props: {
  file: Blob;
  uuid: string;
  name: string;
}): Promise<any> => {
  const { file, uuid, name } = props;
  const formData = new FormData();
  formData.append("image", file);

  return fetch(`${NODE_JS_BACKEND_URL}/stitching-images/${uuid}/${name}`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: getAccessToken() ?? "",
      "X-API-VERSION": "1",
    },
  });
};

export const usePushStitchingImage = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(uploadImage);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    queryClient.invalidateQueries(useGetStichingImagesMetaCacheKey);
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
