// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import Spinner from '../../Components/_BaseUI/Spinner/Spinner';
import { getCachedActiveCamera, isNoActiveCamera } from '../../Data/Camera/ActiveCamera/fetch';
import { useFetchActiveCamera } from '../../Data/Camera/ActiveCamera/hook';
import { CameraSystemStateValue } from '../../Data/Camera/HWCameraSettings/CameraSettingsStreamingState';
import { useCameraSettings } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import RemoteCameraState, { RemoteCameraStateValue } from '../../Data/Camera/RemoteCameraState';
import { DeviceType, useDevice } from '../../hooks/useDevice';
import { BreakpointEnum, useWidth } from '../../hooks/useWidth';
import CameraCardBlock from './CameraCardBlock';


const CameraCardLocal: React.FC<WrappedComponentProps> = (props: WrappedComponentProps) => {
  const fetchActiveCamera = useFetchActiveCamera();
  const isActiveCameraLoaded = !fetchActiveCamera.isLoading;
  const camera = getCachedActiveCamera();
  const cameraSettings = useCameraSettings();
  const deviceType = useDevice()

  const width = useWidth()

  /**
   * Compact mode for all cards
   */
  const areCompactCards = useMemo(() => {
    return (deviceType === DeviceType.Mobile) || (width <= BreakpointEnum.sm)
  }, [deviceType, width])
  if (!isActiveCameraLoaded || isNoActiveCamera(camera)) {
    return (
      <>
      <br /><br /><Spinner /><br /><br />
      </>
    )
  }

  if (!cameraSettings || !camera) {
    return null;
  }

  // count current camera.state.state from cameraSettings because for local camera server return old values
  let newState = RemoteCameraStateValue.Unknown
  if (cameraSettings.systemStates) {
    var statesCountMap: { [id: string] : number; } = {};
    for (const [, value] of Object.entries(cameraSettings.systemStates)) {
      statesCountMap[value] = ((statesCountMap[value]) ?? 0)  + 1;
    }
    if (statesCountMap[CameraSystemStateValue.Critial]) {
      newState = RemoteCameraStateValue.OnlineCritical
    }
    else if (statesCountMap[CameraSystemStateValue.Warning]) {
      newState = RemoteCameraStateValue.OnlineWarning
    }
    else {
      newState = RemoteCameraStateValue.OnlineOK
    }
  }

  const cameraHardware: RemoteCameraState = {
    id: camera.id,
    cameraId: camera.cameraId,
    lastOnlineTime: camera.state.lastOnlineTime,
    lastSettingsHttpCode: camera.state.lastSettingsHttpCode,
    lastSettingsResponseTimestamp: camera.state.lastSettingsResponseTimestamp,
    lastSettingsResponse: cameraSettings,
    state: newState,
  };

  return (
    <CameraCardBlock
      isServer={false}
      cameraHardware={ cameraHardware }
      compact={areCompactCards}
      camera={camera}
      activeCamera={ camera }
      // onSetActiveCamera={ props.onSetActiveCamera }
    />
  );
};

export default injectIntl(CameraCardLocal);
