import { gql } from "graphql-request";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useNAPIGraphQLClient } from "../../../../hooks/useNAPIGraphQLClient";
import { StitchingConfig } from "./StitchinConfig.interface";

const graphql = gql`
  query GetStitchingList {
    getStitchingList {
      calibrationInput {
        images {
          type
          index
          width
          height
          points {
            x
            y
          }
        }
        offsets {
          index
          value
        }
      }
      config
      name
      uuid
      type
      active
    }
  }
`;
interface IResponse {
  getStitchingList: StitchingConfig[];
}

interface IUseCurrentStitchingConfigs<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}
export const useCurrentStitchingConfigsCacheKey = "useCurrentStitchingConfigs";

/**
 * @description It request the data only when fetchData has been fired
 * @param props
 * @returns
 */
export const useStitchingList = (
  props?: IUseCurrentStitchingConfigs<IResponse | undefined>
) => {
  const { options } = props ?? {};
  const queryClient = useQueryClient();
  const client = useNAPIGraphQLClient();
  const key = [useCurrentStitchingConfigsCacheKey] as QueryKey;
  const request = () => client?.request<IResponse>(graphql);
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };
  const query = useQuery(key, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });
  const fetchData = async () => {
    return queryClient.fetchQuery(key, request); // Prefetch the query
  };

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
  };
};
