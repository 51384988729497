// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { range } from "lodash";
import { useState } from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/virtual';
import { Point } from "transformation-matrix";
import { Carousel } from "../../../Components/Carusel";
import LoadingComponent from "../../../Components/Loader/index";
import { Colors } from "../../../Configuration/Styles/Colors";
import { BreakpointEnum, useWidth } from "../../../hooks/useWidth";
import { CircleArrowButtonIcon } from "../../../Icons/Icons";
import { PIN_TYPES } from "./settings";
type Props = {
  points: [Array<Point>, Array<Point>];
  setPoints(points: [Array<Point>, Array<Point>]): void;
  selectedPinIndex: number;
  setSelectedPinIndex: (n: number) => void;
  addPoint: () => void;
};

const ButtonArrow = styled.button`
  box-sizing: border-box;
border: none;
margin: 0;
padding: 0;
background: none;
text-align: center;
display: flex; align-items: center;
`


export const PointsToolbar = (props: Props) => {
  const { addPoint, points, selectedPinIndex, setSelectedPinIndex } = props;
  const count = points[0].length;
  const shouldAllowAdd = count < 10;
  const size = 38;
  const itemStyle = {
    height: size,
    width: size,
    borderRadius: size / 2,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
  };

  const toggleSelected = (n: number) => {
    if (selectedPinIndex === n) {
      setSelectedPinIndex(-1);
    } else {
      setSelectedPinIndex(n);
    }
  };

  const [activeIndex, setActiveIndex] = useState<number>(0)

  const width = useWidth();

  const caruselSize = css`
    width: ${width >= BreakpointEnum.lg ? '140px' : '40px'};
  `

  const isAllPinsinView = width >= BreakpointEnum.lg ? count === 3 : count === 1;


  return (
    <LoadingComponent ugly pretendChildrenInitializing >

      <div>
        <Grid gap={1} className={css`display: flex; align-items: center;`} container xs>
          {!isAllPinsinView && <Grid xs className={css`display: flex; align-items: center;`} item>
            <ButtonArrow onClick={() => setActiveIndex(activeIndex - 1)}>
              <CircleArrowButtonIcon className={css`
                height: 20px;
              `} direction={"left"} />
            </ButtonArrow>
          </Grid>}
          <Grid item xs>
            <Carousel
              slidesPerView={width >= BreakpointEnum.lg ? 3 : 1}
              spaceBetween={width >= BreakpointEnum.lg ? '10px' : '20px'}
              activeIndex={activeIndex}
              className={css`
                                     ${caruselSize}

          padding: 5px;
`}
            >
        {range(0, count).map((n) => (

          <div

            className={css`
                       width: 40px;
                 text-decoration: "none";
              cursor: 'pointer';
            `}
            onClick={(evt) => {
              evt.preventDefault();
              toggleSelected(n);
            }}
          >
            <div
              style={{
                ...itemStyle,
                outline: `${n === selectedPinIndex ? 3 : 0}px solid ${
                  Colors.accentBlue
                }`,
                outlineOffset: 1,
                backgroundColor: PIN_TYPES[n].color,
                color: PIN_TYPES[n].isDark ? "white" : "black",
              }}
            >
              {n + 1}
            </div>
          </div>
        ))}

            </Carousel>
          </Grid>
        {shouldAllowAdd && (
            <Grid
              item
              xs
            style={{
              textDecoration: "none",
              cursor: 'pointer',
              width: 'fitContent',
            }}
            onClick={(evt) => {
              evt.preventDefault();
              addPoint();
            }}
          >
            <div
              style={{
                ...itemStyle,
                  backgroundColor: "#90A0B5",
                  border: "1px solid white",
                  color: "white",
              }}
            >
              +
            </div>
            </Grid>
          )}

          {!isAllPinsinView && <Grid xs className={css`display: flex; align-items: center;`} item>

            <ButtonArrow onClick={() => setActiveIndex(activeIndex + 1)}>
              <CircleArrowButtonIcon className={css`
                height: 20px;
              `} />
            </ButtonArrow>
          </Grid>}
        </Grid>
      </div>
    </LoadingComponent>
  );
};
