// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Card } from '@mui/material';
import cn from 'classnames';
import React from 'react';

import { createStyles, makeStyles } from '@mui/styles';
import { FontName, useCurrentFont } from '../../../../Configuration/Styles/Fonts';
import AnyChildren from '../../../../Tools/AnyChildren';


type Props = Readonly<{
    elevation?: number;
    className?: string;
    children: AnyChildren;
}>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
    card: {
        flexGrow: 1,
        borderRadius: 23,
        boxShadow: '0px 4px 16px #00000014',
    },
}));


const ModalCard: React.FC<Props> = React.forwardRef((props: Props, ref: any) => {
    const { font } = useCurrentFont()
    const classes = useStyles({ fontFamily: font });

    return (
      <Card
        ref={ ref }
        elevation={ props.elevation }
        className={ cn(classes.card, props.className) }
      >
          { props.children }
      </Card>
    );
});


export default ModalCard;
