// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import CameraState from "..";
import { fetchCameraState } from "../fetch";


export const useCameraState = () => {
  const timeout = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [cameraState, setCameraState] = React.useState<CameraState | null>(null);

  const handleFetch = async () => {
    try {
      const data = await fetchCameraState();

      setCameraState(data);
    } catch (ignore) {
      timeout.current = setTimeout(() => {
        handleFetch();
      }, 1000);
    }
  };

  React.useEffect(() => {
    handleFetch();

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...cameraState };
};
