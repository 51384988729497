// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Checkbox, FormControlLabel, FormControlLabelProps, Grid } from '@mui/material'
import React from 'react'
import { Colors } from '../../../Configuration/Styles/Colors'
import { useCurrentFont } from '../../../Configuration/Styles/Fonts'
import { Sizes } from '../../../Configuration/Styles/Sizes'
import iconCheckboxOff from '../../../Icons/iconCheckboxOff.svg'
import iconCheckboxOn from '../../../Icons/iconCheckboxOn.svg'


export enum MainCheckboxType {
  MainPlain = 'mainPlain',
  CircleShaped = 'circleShaped',
}

export default function MainCheckbox(props: { buttonType?: MainCheckboxType, isChecked: boolean, isDisabled?: boolean, labelPlacementStart?: boolean, title?: string, onClicked: () => void }) {
  const { buttonType = MainCheckboxType.MainPlain } = props;
  const { font } = useCurrentFont()
  const SX = {
    mainPlain: {
      color: Colors.mainTitle,
      fontSize: Sizes.subtitle,
      fontFamily: font,
    },
    icon: {
      display: 'flex',
      width: 16,
      height: 16,
      borderRadius: '50%'
    },
    iconContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };

  function getLabelPlacement(): FormControlLabelProps['labelPlacement'] {
    const placement = props.labelPlacementStart
    switch (placement) {
      case undefined:
        return 'end';
      default:
        return 'start';
    }
  }

  const iconOff: () => React.ReactNode = () => {
    switch (buttonType) {
      case MainCheckboxType.MainPlain:
        return (<img src={ iconCheckboxOff } alt="iconCheckboxOff"/>)
      case MainCheckboxType.CircleShaped:
        return (
          <Grid sx={ SX.iconContainer }>
            <Grid sx={ SX.icon } style={ { background: Colors.mainGrey } } />
          </Grid>
        );
    }
  }

  const iconOn: () => React.ReactNode = () => {
    switch (buttonType) {
      case MainCheckboxType.MainPlain:
        return (<img src={ iconCheckboxOn } alt="iconCheckboxOn"/>)
      case MainCheckboxType.CircleShaped:
        return (
          <Grid sx={ SX.iconContainer }>
            <Grid sx={ SX.icon } style={ { background: Colors.mainGreen } } />
          </Grid>
        );
    }
  }

  return (
    <div>
      <FormControlLabel
        disabled={ props.isDisabled }
        control={ <Checkbox checked={ props.isChecked } icon={ iconOff() } checkedIcon={ iconOn() }/> }
        label={
          <Grid
            sx={ SX.mainPlain }
          >
            { props.title }
          </Grid>
        }
        labelPlacement={ getLabelPlacement() }
        onClick={ props.onClicked }
      />
    </div>
  )
}
