// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { HighlightOff } from '@mui/icons-material';
import { clone } from 'lodash';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { createStyles, makeStyles } from '@mui/styles';
import LoginOAuth2 from '../../../../../../Components/LoginOAuth2';
import VKUserGroupAutocompleteSelect from '../../../../../../Components/_AutocompleteSelect/VKUserGroupAutocompleteSelect';
import { FieldSetInputType } from '../../../../../../Components/_BaseUI/FieldSet';
import FieldSetSelectComponent from '../../../../../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import FieldSetStaticLabel from '../../../../../../Components/_BaseUI/FieldSet/FieldSetStaticLabel';
import FieldSetText from '../../../../../../Components/_BaseUI/FieldSet/FieldSetText';
import MainButton, { ButtonType } from '../../../../../../Components/_BaseUI/MainButton';
import MainCheckbox, { MainCheckboxType } from '../../../../../../Components/_BaseUI/MainCheckbox/MainCheckbox';
import QuestionTooltip from '../../../../../../Components/_BaseUI/QuestionTooltip';
import GridContainer from '../../../../../../Components/_Layout/Grid';
import HorizGrid from '../../../../../../Components/_Layout/HorizGrid';
import { FontName, useCurrentFont } from '../../../../../../Configuration/Styles/Fonts';
import { PATHS } from '../../../../../../constants';
import { DestType } from '../../../../../../Data/NapiCameraConfig/Dest';
import { StreamAccessPermission, streamAccessPermissionName, VK_STREAM_ACCESS_PERMISSIONS_ARR, YOUTUBE_STREAM_ACCESS_PERMISSIONS_ARR } from '../../../../../../Data/NapiStreamingProfile';
import { StreamBlock, StreamType } from '../../../../../../Data/NapiStreamingProfileOptions';
import LOCALIZATION from '../../../../../../Localization';
import { Colors } from './../../../../../../Configuration/Styles/Colors';
import { Sizes } from './../../../../../../Configuration/Styles/Sizes';
import { LocalDestStreamBlock } from './index';

type Props = Readonly<{
  index: number;
  streamBlock: StreamBlock[];
  allowedStreamBlocks: StreamBlock[];
  item: LocalDestStreamBlock;
  onChange: (item: LocalDestStreamBlock) => void;
  onDelete: (key: string | undefined) => void;
} & WrappedComponentProps>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  hintTitle: {
    fontWeight: Sizes.boldWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.small,
    color: Colors.headingMain,
  },
  hintText: {
    fontWeight: Sizes.regularWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.extraSmall,
    color: Colors.headingMain,
  },
  streamIndexText: {
    fontWeight: Sizes.boldWeight,
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.heading,
    color: Colors.mainTitle,
  },
}));

const DestTypeBlockItem: React.FC<Props> = (props: Props) => {

  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const handleToggleEnabled = () => {
    const item = clone(props.item);

    item.enabled = !item.enabled;

    props.onChange(item);
  };
  const handleDelete = () => {
    if (window && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.confirm_actions }))) {
      props.onDelete(props.item.key);
    }
  };
  const handleChangeType = (value: string) => {
    const selectedStreamBlock = props.allowedStreamBlocks.find(({ id }) => (id === value));

    if (selectedStreamBlock) {
      props.onChange({
        key: props.item.key,
        id: selectedStreamBlock.id,
        type: selectedStreamBlock.type,
        enabled: props.item.enabled,
        srcStreamInfo: selectedStreamBlock,
      });
    }
  };
  const handleChangeRtmpURL = (url: string) => {
    props.onChange({
      ...props.item,
      url,
    });
  };
  const handleChangeRtmpKey = (urlKey: string) => {
    props.onChange({
      ...props.item,
      urlKey,
    });
  };
  const handleChangePlaylistName = (playlistName: string) => {
    props.onChange({
      ...props.item,
      playlistName,
    });
  };
  const handleChangeVideoNamePrefix = (videoNamePrefix: string) => {
    props.onChange({
      ...props.item,
      videoNamePrefix,
    });
  };
  const urlErrorCheckPattern = (path: string): boolean => {
    const isCorrectValue = (path.length !== 0) || (!props.item.enabled);
    return !isCorrectValue;
  };

  const vkStreamPermissionOptions = VK_STREAM_ACCESS_PERMISSIONS_ARR.map((permision) => {
    return ({
      id: permision,
      name: streamAccessPermissionName(permision),
    });
  });
  const youtubeStreamPermissionOptions = YOUTUBE_STREAM_ACCESS_PERMISSIONS_ARR.map((permision) => {
    return ({
      id: permision,
      name: streamAccessPermissionName(permision),
    });
  });


  const handleStreamPermissionChange = (videoAccessPermission: string | undefined) => {
    props.onChange({
      ...props.item,
      videoAccessPermission : videoAccessPermission as StreamAccessPermission,
    });
  };

  const redirectUrl = (): string => {
    try {
      let redirectUri = props.item.srcStreamInfo?.oAuthParams?.redirectUri
      if (!redirectUri?.length) {
        const href = window.location.href;
        const url = new URL( href );
        const pathEndIndex = href.indexOf(url.pathname)
        const hostName = href.substring(0, pathEndIndex);
        redirectUri = hostName + PATHS.oAuth;
      }

      const redirectUriScheme = props.item.srcStreamInfo?.oAuthParams?.redirectUriScheme
      if (redirectUri?.length && redirectUriScheme?.length) {
        redirectUri = redirectUri.replace(/^https?/, redirectUriScheme)
      }

      return redirectUri;
    }
    catch (ignore) {

    }
    return ""
  }

  const getHintText = () => {
    let hintText = undefined
    switch (props.item.id) {
      case StreamType.Cybercamera:
        hintText = props.intl.formatMessage({ id: LOCALIZATION.stream_video_hint_cybercamera });
        break;
      case StreamType.Youtube:
      case StreamType.YoutubeRTMP:
          hintText = props.intl.formatMessage({ id: LOCALIZATION.stream_video_hint_youtube });
        break
      case StreamType.VKVideo:
      case StreamType.VKVideoRTMP:
          hintText = props.intl.formatMessage({ id: LOCALIZATION.stream_video_hint_vkvideo });
        break
      case StreamType.RUTUBE:
        hintText = props.intl.formatMessage({ id: LOCALIZATION.stream_video_hint_rutube });
        break
      case StreamType.Twitch:
        hintText = props.intl.formatMessage({ id: LOCALIZATION.stream_video_hint_twitch });
        break
    }
    return hintText;
  }

  const renderRtmpUrlKey = () => {
    if (props.item.srcStreamInfo?.oAuthParams) {
      return null;
    }
    const hintText = getHintText();

    return <>
      { (hintText) &&
        <GridContainer
        style={ {
          padding: '0 0 10px 0',
          margin: '0',
        } }
        gridTemplateColumns="1fr"
        >
        <div className={ classes.hintText }>
          { hintText }
        </div>
        </GridContainer>
      }

      {(props.item.srcStreamInfo?.separatedRtmpKey) &&
        <GridContainer
          style={ {
            padding: '0 0 10px 0',
            margin: '0',
          } }
          gridTemplateColumns="110px 1fr"
        >
          <div>
            { props.intl.formatMessage({ id: LOCALIZATION.stream_video_rtmp_key }) }&nbsp;
            <QuestionTooltip
              toolTip={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_rtmp_help }) }
            />
          </div>
          <div>
            <FieldSetText
              input={ {
                type: FieldSetInputType.Text,
                value: props.item.urlKey || '',
                onChange: handleChangeRtmpKey,
                // errorCheckPattern: urlErrorCheckPattern, // no need to check key because if need url can have all need to work. so key may be empty
              } }
            />
          </div>
        </GridContainer>
      }

        <GridContainer
          style={ {
            padding: '0 0 10px 0',
            margin: '0',
          } }
          gridTemplateColumns="110px 1fr"
        >
          <div>
            { props.intl.formatMessage({ id: LOCALIZATION.stream_video_rtmp_url }) }&nbsp;
            <QuestionTooltip
              toolTip={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_rtmp_help }) }
            />
          </div>
          <div>
            <FieldSetText
              input={ {
                type: FieldSetInputType.Text,
                value: props.item.url || '',
                onChange: handleChangeRtmpURL,
                errorCheckPattern: urlErrorCheckPattern,
              } }
            />
          </div>
        </GridContainer>
    </>
  }

  const renderOauthLogin = () => {
    if (!props.item.srcStreamInfo?.oAuthParams) {
      return null;
    }
    const hintTitle = props.intl.formatMessage({ id: LOCALIZATION.stream_video_title_hint });
    const hintText = getHintText();

    const loginBtn = () => {
      let text = `${props.intl.formatMessage({ id: LOCALIZATION.login })} ${props.item.id}`
      switch (props.item.id) {
        case StreamType.Youtube:
          text = props.intl.formatMessage({ id: LOCALIZATION.stream_video_youtube_login }); break
        case StreamType.VKVideo:
          text = props.intl.formatMessage({ id: LOCALIZATION.stream_video_vkvideo_login }); break
        case StreamType.Cybercamera:
          text = props.intl.formatMessage({ id: LOCALIZATION.stream_video_cybercamera_login }); break
        }
      return text;
    }

    const logoutBtn = () => {
      let text = `${props.intl.formatMessage({ id: LOCALIZATION.menu_logout })} ${props.item.id}`
      switch (props.item.id) {
        case StreamType.Youtube:
          text = props.intl.formatMessage({ id: LOCALIZATION.stream_video_youtube_logout }); break
        case StreamType.VKVideo:
          text = props.intl.formatMessage({ id: LOCALIZATION.stream_video_vkvideo_logout }); break
        case StreamType.Cybercamera:
          text = props.intl.formatMessage({ id: LOCALIZATION.stream_video_cybercamera_logout }); break
        }
      return text
    }

    return <>
      { (hintText) &&
        <GridContainer
        style={ {
          padding: '0 0 10px 0',
          margin: '0',
        } }
        gridTemplateColumns="1fr"
        >
        <div className={ classes.hintText }>
          { hintText }
        </div>
        <div className={ classes.hintTitle }>
          { hintTitle }
        </div>
        </GridContainer>
      }
      {
        (props.item.oAuthToken) ?
        <>
          {
            (props.item.id === StreamType.VKVideo || props.item.id === StreamType.Cybercamera) &&
            <GridContainer
              style={ {
                padding: '0 0 10px 0',
                margin: '0',
              } }
              gridTemplateColumns="120pt 1fr"
            >
              <div>
                { props.intl.formatMessage({ id: LOCALIZATION.stream_video_stream_target }) }&nbsp;
                <QuestionTooltip
                  toolTip={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_stream_target_hint }) }
                />
              </div>
              <div>
                { (props.item.oAuthToken["access_token"]) ?
                  <VKUserGroupAutocompleteSelect  accessToken={ props.item.oAuthToken["access_token"] } selectedGroupId={ props.item.groupId }
                    onSelect={function (groupId, groupName): void {
                      props.onChange({
                        ...props.item,
                        groupId: groupId || undefined,
                        groupName,
                      });
                  }}/>
                  :
                  <FieldSetStaticLabel
                    input={ {
                      type: FieldSetInputType.StaticLabel,
                      value: (props.item.groupId) ? props.item.groupName : props.intl.formatMessage({ id: LOCALIZATION.stream_video_stream_target_user_page }),
                    } }
                  />
                }
              </div>
            </GridContainer>
          }
          <GridContainer
            style={ {
              padding: '0 0 10px 0',
              margin: '0',
            } }
            gridTemplateColumns="120pt 1fr"
          >
            <div>
              { props.intl.formatMessage({ id: LOCALIZATION.stream_video_playlist_name }) }&nbsp;
              <QuestionTooltip
                toolTip={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_playlist_name_hint }) }
              />
            </div>
            <div>
              <FieldSetText
                input={ {
                  type: FieldSetInputType.Text,
                  value: props.item.playlistName || '',
                  onChange: handleChangePlaylistName,
                  // errorCheckPattern: urlErrorCheckPattern, // no need to check key because if need url can have all need to work. so key may be empty
                } }
              />
            </div>
          </GridContainer>
          <GridContainer
            style={ {
              padding: '0 0 10px 0',
              margin: '0',
            } }
            gridTemplateColumns="120pt 1fr"
          >
            <div>
              { props.intl.formatMessage({ id: LOCALIZATION.stream_video_name_prefix }) }&nbsp;
              <QuestionTooltip
                toolTip={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_name_prefix_hint }) }
              />
            </div>
            <div>
              <FieldSetText
                input={ {
                  type: FieldSetInputType.Text,
                  value: props.item.videoNamePrefix || '',
                  onChange: handleChangeVideoNamePrefix,
                  // errorCheckPattern: urlErrorCheckPattern, // no need to check key because if need url can have all need to work. so key may be empty
                } }
              />
            </div>
          </GridContainer>
          {
            (props.item.id === StreamType.VKVideo || props.item.id === StreamType.Cybercamera || props.item.id === StreamType.Youtube) &&
            <GridContainer
              style={ {
                padding: '0 0 10px 0',
                margin: '0',
              } }
              gridTemplateColumns="120pt 1fr"
            >
              <div>
                { props.intl.formatMessage({ id: LOCALIZATION.stream_video_access_permision }) }&nbsp;
                <QuestionTooltip
                  toolTip={ props.intl.formatMessage({ id: LOCALIZATION.stream_video_access_permision_hint }) }
                />
              </div>
              <div>
                <FieldSetSelectComponent
                  input={ {
                    type: FieldSetInputType.Select,
                    value: props.item.videoAccessPermission || ((props.item.groupId !== undefined) ? StreamAccessPermission.members : StreamAccessPermission.all),
                    options: (props.item.id === StreamType.Youtube) ? youtubeStreamPermissionOptions : vkStreamPermissionOptions,
                    onChange: handleStreamPermissionChange,
                    // errorCheckPattern: urlErrorCheckPattern, // no need to check key because if need url can have all need to work. so key may be empty
                  } }
                />
              </div>
            </GridContainer>
          }
          <HorizGrid justifyContent='flex-end'
            sx={ {
              padding: '0 0 10px 0',
              margin: '0',
            } }
          >
              <MainButton
              onClicked={ () => {
                props.onChange({
                  ...props.item,
                  oAuthToken: null,
                });
                }}
                title={logoutBtn()}
              buttonType={ ButtonType.Outline }
              sx={{ maxWidth: '200pt' }}
            />
          </HorizGrid>
        </>
        :
        <HorizGrid justifyContent='center'
          sx={ {
            padding: '0 0 10px 0',
            margin: '0',
          } }
        >
          <LoginOAuth2
            authorizeUri={ props.item.srcStreamInfo?.oAuthParams.authorizeUri }
            clientId={ props.item.srcStreamInfo?.oAuthParams.clientId }

            redirectUri={ redirectUrl() }
            scope={ props.item.srcStreamInfo?.oAuthParams.scope }
            responseType={ props.item.srcStreamInfo?.oAuthParams.responseType }
            resposeSuccessKeyName={ props.item.srcStreamInfo?.oAuthParams.resposeSuccessKeyName }
            onSuccess={(response) => {
              // console.log(response);
              props.onChange({
                ...props.item,
                oAuthToken: response,
              });
            }}
            onFailure={(response: { message: string; }) => {
              console.error(response);
              props.onChange({
                ...props.item,
                oAuthToken: undefined,
              });
            } }
            className="login"
              params={props.item.srcStreamInfo?.oAuthParams.params}
            button={
              <MainButton title={ loginBtn() } sx={{ maxWidth: '200pt' }} />
            }
          />
        </HorizGrid>
      }
    </>
  }

  const renderRtmpDest = () => {
    return <div>
      { renderRtmpUrlKey() }
      { renderOauthLogin() }
    </div>
  };
  const renderFileDest = () => (
    <GridContainer
      style={ {
        padding: '0 0 10px 0',
        margin: '0',
      } }
      gridTemplateColumns="110px 1fr"
    >
      <div>
        renderFile
      </div>
      <div>
        Field
      </div>
    </GridContainer>
  );
  const renderAdditionalField = () => {
    switch (props.item.type) {
      case DestType.RTMP: {
        return renderRtmpDest();
      }
      case DestType.File: {
        return renderFileDest();
      }
      default:
        return null;
    }
  };

  return (
    <div
      style={ {
        padding: '4px 0',
        margin: '0',
      } }
    >
      <GridContainer
        gridTemplateColumns="20px 16px 1fr 20px"
        style={ {
          padding: '10pt 0 5pt 0',
          margin: '0',
        } }
      >
        <div className={ classes.streamIndexText }>
          { `#${ props.index }` }
        </div>
        <MainCheckbox
          buttonType={ MainCheckboxType.MainPlain }
          isChecked={ props.item.enabled }
          onClicked={ handleToggleEnabled }
        />
        <FieldSetSelectComponent
          input={ {
            type: FieldSetInputType.Select,
            value: props.item.id,
            options: (props.item.id === StreamType.LocalFile) ?
              props.streamBlock.map((item) => ({ id: item.id, name: item.id }))
              :
              props.allowedStreamBlocks.map((item) => ({ id: item.id, name: item.id })),
            onChange: handleChangeType,
          } }
        />
        <HighlightOff
          style={ {
            cursor: 'pointer',
            fill: '#EB5757',
          } }
          onClick={ handleDelete }
        />
      </GridContainer>
      { renderAdditionalField() }
    </div>
  );
};


export default injectIntl(DestTypeBlockItem);
