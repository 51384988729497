// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Button, SxProps, Theme, Typography } from '@mui/material';
import React, { ReactNode, useCallback } from 'react';

import { Colors } from '../../../Configuration/Styles/Colors';
import { Fonts } from '../../../Configuration/Styles/Fonts';

import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';


export enum ButtonType {
  Plain = 'plain',
  Outline = 'outline',
  Icon = 'icon',
}

type Props = Readonly<{
  type?: 'button' | 'submit';
  buttonType?: ButtonType;
  isDisabled?: boolean;
  title?: string | ReactNode;
  iconButton?: JSX.Element
  anyButtonContentEl?: JSX.Element
  sx?: SxProps<Theme>;
  className?: string;
  fitContent?: boolean;
  children?: ReactNode;
  onClicked?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}>;


export const SX = (fontFamily: FontName) => ({
  main: {
    minWidth: 120,
    minHeight: 38,
    width: '100%',
    fontSize: Sizes.subtitle,
    fontFamily: fontFamily,
    fontWeight: Sizes.boldWeight,
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '6px',
  },
  buttonTitle: {
    fontSize: Sizes.subtitle,
    fontFamily: Fonts.main,
    fontWeight: Sizes.boldWeight,
    textAlign: 'center',
    textTransform: 'none',
  },
  multilineButton: {
    display: "block"
  },
  iconButton: {
    width: 'auto',
    minWidth: 'auto',
    background: Colors.buttonBlue,
    color: Colors.white,
    '&:hover': {
      backgroundColor: Colors.buttonBlue
    },
    '&:disabled': {
      backgroundColor: Colors.buttonDisabled,
      color: Colors.white
    }
  },
  plain: {
    background: Colors.buttonBlue,
    color: Colors.white,
    '&:hover': {
      backgroundColor: Colors.buttonBlue
    },
    '&:disabled': {
      backgroundColor: Colors.buttonDisabled,
      color: Colors.white
    }
  },
  outline: {
    background: Colors.mainBackground,
    border: '2px solid',
    color: Colors.buttonBlue,
    '&:hover': {
      backgroundColor: Colors.mainBackground
    },
    '&:disabled': {
      // backgroundColor: Colors.buttonDisabled,
      backgroundColor: Colors.mainBackground,
      color: Colors.buttonOutlinedDisabled
    }
  },
  icon: {
    background: Colors.clear,
    backgroundColor: Colors.clear,
    border: 0,
    color: Colors.buttonBlue,
    borderRadius: 0,
    '&:hover': {
      border: 0,
      backgroundColor: Colors.clear,
      color: Colors.buttonBlue,
      borderRadius: 0,
    },
    '&:disabled': {
      border: 0,
      backgroundColor: Colors.clear,
      color: Colors.buttonOutlinedDisabled,
      borderRadius: 0,
    }
  },
  fitContent: {
    width: "fit-content",
  }
});

export const MainButton: React.FC<Props> = (props: Props) => {
  const { buttonType = ButtonType.Plain, onClicked: handleClick, fitContent, className } = props;
  const { font } = useCurrentFont()
  let sx: SxProps<Theme> = { ...SX(font).main, ...SX(font).buttonTitle };

  if (buttonType === ButtonType.Plain) {
    sx = { ...sx, ...SX(font).plain };
  }
  else if (buttonType === ButtonType.Outline) {
    sx = { ...sx, ...SX(font).outline };
  }
  else if (buttonType === ButtonType.Icon) {
    sx = { ...sx, ...SX(font).iconButton };
  }
  if (fitContent) {
    sx = { ...sx, ...SX(font).fitContent };
  }

  sx = { ...sx, ...props.sx };
  const onClicked = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleClick?.(e);
  }, [handleClick])


  let anyButtonContentEl = props.anyButtonContentEl;

  if (!anyButtonContentEl && typeof props.title === "string" && props.title?.length && props.title.indexOf("\n") >= 0) {
    const lines = props.title.split("\n")
    sx = { ...sx, ...SX(font).multilineButton};
    anyButtonContentEl = <>
      {lines.map((item) => (<Typography sx={SX(font).buttonTitle }>{item}</Typography> )) }
    </>;
  }

  return (
    <Button
      sx={sx}
      className={className}
      disabled={ props.isDisabled }
      type={ props.type }
      onClick={onClicked}
    >
      {props.children || anyButtonContentEl  || props.iconButton || props.title}
    </Button>
  )
};


export default MainButton;
