// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import LoadingComponent from '../../../../Components/Loader/index';

import CircledLabel, { LabelType } from '../../../../Components/_BaseUI/CircledLabel/CircledLabel';
import VertGrid from '../../../../Components/_Layout/VertGrid';
import { useUserRole } from '../../../../Data/AccountUsers/hook/useUserRole';
import { UserRole } from '../../../../Data/AccountUsers/UserWithoutID';
import { CameraSettingsInterface } from '../../../../Data/Camera/HWCameraSettings/CameraSettings';
import { CameraSystemStates } from '../../../../Data/Camera/HWCameraSettings/CameraSettingsStuff';
import { useFetchActualMetrics } from '../../../../Data/Software/useActualSoftware';
import { useIsServer } from '../../../../hooks/useIsServer';
import { useAppConfig } from '../../../../Localization/AppContextProvider/helpers';
import { stateByKey } from './helpers';


type Props = Readonly<{
  compact?: boolean;
  // camera: Camera;
  lastSettingsResponse?: CameraSettingsInterface
  criticalErrors?: Partial<CameraSystemStates>;
} & WrappedComponentProps>;

export enum InfoField {
  Critical = 'critical',
  Streaming = 'streaming',
  StreamingProfile = 'streamingProfile',
  ActiveEpgEvent = 'activeEpgEvent',

  SaveToStorage = 'saveToStorage',
  StorageLeft = 'storageLeft',
  StorageSync = 'storageSync',

  Outdoor = 'outdoor',
  Internal = 'internal',
  FAN = 'FAN',
  DC = 'DC',
  Software = 'Software',
}

const CameraPreviewInfo: React.FC<Props> = (props: Props) => {
  const { localization: { locale } } = useAppConfig();
  const { compact } = props;
  const isServer = useIsServer()
  const { data: actualVersions, isLoading: loadingActuaVersions } = useFetchActualMetrics()
  const role = useUserRole()
  const renderStateItemExt = (key: keyof typeof InfoField, hideIfNoError?: boolean) => {
    if (!actualVersions) {
      return;
    }
    const state = stateByKey({ isServer, key, lastSettingsResponse: props.lastSettingsResponse, intl: props.intl, criticalErrors: props.criticalErrors, locale, actualVersions });

    if ((!state) || (hideIfNoError && (state.label === LabelType.Green)))  { return null; }

    return (
      <CircledLabel
        labelType={ state.label }
        title={ state.title }
      />
    );
  };

  const renderLabelItem = (key: keyof typeof InfoField) => (renderStateItemExt(key, false))
  const renderLabelIfErrorItem = (key: keyof typeof InfoField) => (renderStateItemExt(key, true))

  const renderStateItem = (key: keyof typeof InfoField) => {
    if (!actualVersions) {
      return;
    }
    const state = stateByKey({ isServer, key, lastSettingsResponse: props.lastSettingsResponse, intl: props.intl, criticalErrors: props.criticalErrors, locale, actualVersions });

    if (loadingActuaVersions)
      return <LoadingComponent ugly />


    if (!state) { return null; }

    return (
      <Grid
        item
        key={ key }
        xs={ 12 }
      >
        <CircledLabel
          labelType={ state.label }
          title={ state.title }
        />
      </Grid>
    );
  };

  if (compact) {
    return (
      <VertGrid>
        { (['Critical', 'StreamingProfile', 'Streaming', 'ActiveEpgEvent', 'DC'] as Array<keyof typeof InfoField>).map(renderLabelItem) }
        {(['SaveToStorage', 'StorageLeft', 'StorageSync', 'Outdoor', 'Internal', 'FAN',] as Array<keyof typeof InfoField>).map(renderLabelIfErrorItem)}
      </VertGrid>
    )
  }

  return (
    <Grid
      container
      spacing={ 1 }
    >
      <Grid
        item
        xs={ 4 }
      >
        { (['Critical', 'StreamingProfile', 'Streaming', 'ActiveEpgEvent', 'DC'] as Array<keyof typeof InfoField>).map(renderStateItem) }
      </Grid>
      <Grid
        item
        xs={ 4 }
      >
        { (['SaveToStorage', 'StorageLeft', 'StorageSync'] as Array<keyof typeof InfoField>).map(renderStateItem) }
      </Grid>
      <Grid
        item
        xs={ 4 }
      >
        {(['Outdoor', 'Internal', 'FAN', ((role === UserRole.Admin) || (role === UserRole.SuperAdmin)) && 'Software'] as Array<keyof typeof InfoField>).filter(Boolean).map(renderStateItem)}
      </Grid>
    </Grid>
  )
};

export default injectIntl(CameraPreviewInfo);
