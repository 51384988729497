import { gql } from "graphql-request";
import { useCallback } from "react";
import { QueryKey, UseQueryOptions, useQuery } from "react-query";
import { useExposedConfigurations } from "../../../hooks/useExposedConfigurations";

interface IResponse {
  config: object;
}
const queryQL = gql`
  query Config {
    config
  }
`;

interface IUseGetTotalConfiguration<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}

const getTotalConfigurationKey = "getTotalConfiguration";

export const useGetTotalConfiguration = (
  props?: IUseGetTotalConfiguration<IResponse | undefined>
) => {
  const { options } = props ?? {};
  const client = useExposedConfigurations();

  const request = () => client.request<IResponse>(queryQL);

  const query = useQuery([getTotalConfigurationKey] as QueryKey, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: false,
  });

  return {
    ...query,
  };
};

export const useTotalConfig = (
  props?: IUseGetTotalConfiguration<IResponse | undefined>
) => {
  const { data, refetch } = useGetTotalConfiguration(props);

  const fetch = useCallback(
    () => refetch().then((v) => v.data?.config),
    [refetch]
  );
  return {
    data,
    fetch,
  };
};
