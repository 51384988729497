import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { merge } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CameraIntervalImage } from "../../../../Components/CameraIntervalImage";
import LoadingComponent from "../../../../Components/Loader";
import MainButton from "../../../../Components/_BaseUI/MainButton";
import Card from "../../../../Components/_Layout/Card";
import Popup from "../../../../Components/_Layout/Popup";
import { useCurrentFont } from "../../../../Configuration/Styles/Fonts";
import { useSwitchTemporaryImageAdjustment } from "../../../../Data/NapiCameraHandlerV2/hooks/individual-camera/mutation/useSwitchTemporaryImageAdjustment";
import { UpdateIndividualCameraProps, useUpdateIndividualCamera } from "../../../../Data/NapiCameraHandlerV2/hooks/individual-camera/mutation/useUpdateIndividualCamera";
import { useIndividualCamera } from "../../../../Data/NapiCameraHandlerV2/hooks/individual-camera/useIndividualCamera";
import { useCameraCommonSettings } from "../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { useFirstDefinedValue } from "../../../../hooks/useFirstDefined";
import LOCALIZATION from "../../../../Localization";
import { AutoExposureRegion } from "./AutoAdjustmentForm/AutoExposureRegion";
import { AutoWhiteBalanceRegion } from "./AutoAdjustmentForm/AutoWhiteBalanceRegion";
import { Region } from "./AutoAdjustmentForm/ImagePointer/Region.interface";
import { AutoExposureCompensationForm } from "./AutoExposureCompensationForm";



type Props = Readonly<{
    onClose: () => void
    handleSubmit?: () => Promise<void> | void,
    camera: number,
}>;

const Image = styled(CameraIntervalImage)`
    max-width: 640px;
    align-self: center;

    background-color: #E5E5E5;
    border: solid 1px;
    border-color:  rgba(0, 0, 0, 0.30);
    border-radius: 10px;
    min-height: 60px;
    margin: auto;
    max-height: 360px; width:100%;
`;

const Line = styled.hr`
 height: 1px; border: 0;
    background-color: var(--C_Light_Gray, #C2CFE0);
 `


const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))
export const ImageAdjust = (props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote, camera } = props;
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const intl = useIntl();
    const [ae_region, setAe_region] = useState<Region>();
    const [awb_region, setAwb_region] = useState<Region>();

    const { font } = useCurrentFont()
    const Header = styled.span`
color: var(--C_Dark_Text, #334D5E);
margin-bottom:10px;
/* c_h4 (18) */
font-family:  ${font};
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.27px;
margin-bottom:10px;`


    const { isLoading: loadinIndividualConfig, data: individualConfig } = useIndividualCamera({ camera });
    const firstDefinedIndividualConfig = useFirstDefinedValue(individualConfig)
    const [propsToSave, setPropsToSave] = useState<UpdateIndividualCameraProps["patch"] | undefined>(firstDefinedIndividualConfig)
    useEffect(() => {
        setPropsToSave(firstDefinedIndividualConfig);
    }, [firstDefinedIndividualConfig])

    const { data: commonSettings, isLoading: gettingCommonSettings } = useCameraCommonSettings()

    const isAutoExposureAdjustmentAvaible = useMemo(() => {
        console.log(commonSettings?.configState.config)
        return camera === commonSettings?.configState.config.camera["main-camera"] || camera !== -1
    }, [camera, commonSettings?.configState.config.camera["main-camera"]])

    const { width, height } = useMemo(() => {
        return commonSettings?.configState.config.camera ?? { width: 100, height: 100 }
    }, [commonSettings?.configState.config.camera])

    const initialConf = useMemo(() => {
        if (!firstDefinedIndividualConfig) {
            return
        }
        return propsToSave ?? firstDefinedIndividualConfig
    }, [firstDefinedIndividualConfig, propsToSave])


    const onCompnesationFormChangeCallback = useCallback((patch: Partial<UpdateIndividualCameraProps["patch"]>) => {
        setPropsToSave(merge(propsToSave, patch))
    }, [propsToSave])

    useEffect(() => {
        setPropsToSave(merge(propsToSave, { ae_region }))
    }, [ae_region, propsToSave])

    useEffect(() => {
        setPropsToSave(merge(propsToSave, { awb_region }))
    }, [awb_region, propsToSave])

    const { mutateAsync: updateIndividualCamera, isLoading: updatingIndvidualCamera } = useUpdateIndividualCamera()

    const { mutateAsync: runTemporaryAdjustmentMode, isLoading: switchingTemporaryImage } = useSwitchTemporaryImageAdjustment()

    const runTemporaryAdjustmentModeCallback = useCallback(async () => {
        propsToSave && runTemporaryAdjustmentMode({
            camera,
            config: propsToSave,
            state: true,

        })
    }, [camera, propsToSave, runTemporaryAdjustmentMode])
    const onSubmitRemote = useCallback(async () => {
        propsToSave && await runTemporaryAdjustmentMode({
            camera,
            config: propsToSave,
            state: true,

        })
        propsToSave && await updateIndividualCamera({ camera, patch: propsToSave })
        await handleSubmitRemote?.()
    }, [camera, handleSubmitRemote, propsToSave, runTemporaryAdjustmentMode, updateIndividualCamera])




    const onCloseCallback = useCallback(async () => {
        await runTemporaryAdjustmentMode({
            camera,
            state: false,
        })
        onClose()
    }, [camera, onClose, runTemporaryAdjustmentMode])


    return <Popup
        closeTitle={`${intl.formatMessage({ id: LOCALIZATION.close_btn })}`}
        isFullScreen
        headerTitle={`${intl.formatMessage({ id: LOCALIZATION.lens })} #${camera} ${intl.formatMessage({ id: LOCALIZATION.image_enchantments })}`}
        // isApplyDisabled={loadingList}
        onApply={onSubmitRemote}
        onClose={onCloseCallback}
        classes={classes}
        applyTitle={`${intl.formatMessage({ id: LOCALIZATION.save })}`}

    >
        <LoadingComponent isLoading={loadinIndividualConfig || updatingIndvidualCamera || switchingTemporaryImage || gettingCommonSettings || !firstDefinedIndividualConfig} ugly pretendChildrenInitializing>

            {firstDefinedIndividualConfig && <Grid container>
                <Grid item marginBottom={4} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignContent={"center"} width={`100%`} >
                    <div className={css`    aspect-ratio: 16/9;
    width: fit-content;
    display: flex;
    width: -webkit-fill-available; max-height: 360px; width:100%; margin: auto;`}>
                        <Image camera={camera} interval={5_000} height={360} />
                    </div>
            </Grid>
            <Grid item marginBottom={4} xs={12}>
                <Line />
                </Grid>
                {!isAutoExposureAdjustmentAvaible && <Grid container gap={4} marginBottom={3}><Card><Header>{formatMessage({ id: LOCALIZATION.auto_adjustments_are_only_available_for_the_main_lens }).capitalizeFirstLetter()}</Header>  </Card>
                </Grid>}
                {isAutoExposureAdjustmentAvaible && (<><Grid item marginBottom={4} xs={12}>
                    <Card>
                        <AutoExposureRegion initialValue={merge({ left: 0, top: 0, right: width, bottom: height }, firstDefinedIndividualConfig?.ae_region,)} onChange={setAe_region} camera={camera} />
                </Card>

            </Grid>

            <Grid item marginBottom={4} xs={12}>
                        <Card>
                            <AutoWhiteBalanceRegion initialValue={merge({ left: 0, top: 0, right: width, bottom: height }, firstDefinedIndividualConfig?.awb_region,)} onChange={setAwb_region} camera={camera} />
                </Card>

                    </Grid></>)}

                {firstDefinedIndividualConfig && <Grid item marginBottom={4} xs={12}>
                <Card>
                        <AutoExposureCompensationForm initialValue={firstDefinedIndividualConfig} onChange={onCompnesationFormChangeCallback} />

                        <Grid xs={12} display={"flex"} justifyContent={"center"}>
                            <MainButton
                                onClicked={runTemporaryAdjustmentModeCallback}
                                fitContent
                                title={formatMessage({ id: LOCALIZATION.apply }).capitalizeFirstLetter()}
                            />
                        </Grid>
                </Card>
                </Grid>}



            </Grid>}
        </LoadingComponent>

    </Popup>
}