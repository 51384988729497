import { css } from "@emotion/css";
import { Grid } from "@mui/material";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../../Components/Loader/index";
import FieldSet, { FieldSetInputType } from "../../../../../../Components/_BaseUI/FieldSet/index";
import MainButton from "../../../../../../Components/_BaseUI/MainButton/index";
import { useCachedPanoramaOriginalSizes } from "../../../../../../hooks/useCachedPanoramaOriginalSizes";
import { useCameraShot } from "../../../../../../hooks/useCameraShot";
import LOCALIZATION from "../../../../../../Localization/index";
import { useStoreEditorState } from "../useStoreEditorState";
import { CropModal } from "./CropModal";

export const PanoramaCropStoreRegion = () => {
    const { formatMessage } = useIntl()
    const { value: imageSizes } = useCachedPanoramaOriginalSizes()
    const { state: storeState, patch, set } = useStoreEditorState()
    const [cropModal, setCropModal] = useState<boolean>(false)
    const { base64 } = useCameraShot({ camera: "full_frame_panorama" })
    const ouputSizes = useMemo(() => {
        if (!imageSizes) {
            return 0;
        }
        return { width: Number((Number(imageSizes.width))) - Number(storeState.config?.original?.crop?.left ?? 0) - Number(storeState.config?.original?.crop?.right ?? 0), height: Number((Number(imageSizes.height)) - Number(storeState.config?.original?.crop?.top ?? 0) - Number(storeState.config?.original?.crop?.bottom ?? 0)) }
    }, [imageSizes, storeState.config?.original?.crop?.bottom, storeState.config?.original?.crop?.left, storeState.config?.original?.crop?.right, storeState.config?.original?.crop?.top])
    return <>
        <LoadingComponent ugly isLoading={!imageSizes?.width || (cropModal && !base64)}>
            {imageSizes && <> <Grid gap={5} className={css`
            align-items: center;
            width: 100%;
            `} container>
                <Grid xs={12} md={true} item>
                    {imageSizes && <FieldSet labelSize={4} inputSize={true} input={{
                        type: FieldSetInputType.Number,
                        value: (storeState.config.original?.crop?.left ?? 0),
                        max: (imageSizes.width),
                        min: 0,
                        onChange: (v) => {
                            const storeStateC = cloneDeep(storeState);
                            if (
                                storeStateC.config.original?.crop?.left
                            ) {
                                storeStateC.config.original.crop.left = Number(v);

                            }
                            set(storeStateC);
                        },
                    }} label={`${formatMessage({ id: LOCALIZATION.left }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}></FieldSet>}
                </Grid>
                <Grid xs={12} md={true} item>

                    {imageSizes && <FieldSet labelSize={4} inputSize={true} input={{
                        type: FieldSetInputType.Number,
                        value: (storeState.config.original?.crop?.top ?? 0),
                        min: 0,
                        max: imageSizes.height,
                        onChange: (v) => {
                            const storeStateC = cloneDeep(storeState);
                            if (
                                storeStateC.config.original?.crop?.top
                            ) {
                                storeStateC.config.original.crop.top = Number(v);

                            }
                            set(storeStateC);
                        },
                    }} label={`${formatMessage({ id: LOCALIZATION.top }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}></FieldSet>}
                </Grid>
                <Grid xs={12} md={true} item>

                    <FieldSet labelSize={4} inputSize={true} input={{
                        type: FieldSetInputType.Number,
                        value: (storeState.config.original?.crop?.right) ?? (Number(imageSizes.width)),
                        min: 0,
                        max: Number(imageSizes.width),
                        onChange: (v) => {
                            const storeStateC = cloneDeep(storeState);
                            if (
                                storeStateC.config.original?.crop?.right
                            ) {
                                storeStateC.config.original.crop.right = Number(v);

                            }
                            set(storeStateC);
                        },
                    }} label={`${formatMessage({ id: LOCALIZATION.right }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}></FieldSet>
                </Grid>
                <Grid xs={12} md={true} item>
                    <FieldSet labelSize={4} inputSize={true} input={{
                        type: FieldSetInputType.Number,
                        value: (storeState.config.original?.crop?.bottom ?? Number(imageSizes.height)),
                        min: 0,
                        max: imageSizes.height,
                        onChange: (v) => {
                            const storeStateC = cloneDeep(storeState);
                            if (
                                storeStateC.config.original?.crop?.bottom
                            ) {
                                storeStateC.config.original.crop.bottom = Number(v);

                            }
                            set(storeStateC);
                        },
                    }} label={`${formatMessage({ id: LOCALIZATION.bottom }).capitalizeFirstLetter()}, ${formatMessage({ id: LOCALIZATION.px })}`}></FieldSet>
                </Grid>
                <Grid xs={'auto'} item>
                    <MainButton className={css`
                        width: auto;
                    `} onClicked={() => setCropModal(true)} title={formatMessage({ id: LOCALIZATION.crop_panorama })}></MainButton>
                </Grid>

            </Grid>
                {ouputSizes && <FieldSet labelSize={'auto'} inputSize={'auto'} input={{
                    type: FieldSetInputType.StaticLabel,
                    value: `${Number(ouputSizes.width)}x${ouputSizes.height}`,
                }} label={formatMessage({ id: LOCALIZATION.storage_panorama_size })}></FieldSet>}
                {cropModal && imageSizes && base64 && <CropModal handleSubmit={(v) => {
                    const storeStateC = cloneDeep(storeState);
                    if (!storeStateC.config.original) {
                        storeStateC.config.original = {};

                    }
                    if (!storeStateC.config.original.crop) {
                        storeStateC.config.original.crop = {};
                    }
                if (
                    storeStateC.config.original?.crop
                ) {
                    storeStateC.config.original.crop.right = Number(v.right);

                }
                if (
                    storeStateC.config.original?.crop
                ) {
                    storeStateC.config.original.crop.top = Number(v.top);

                }
                if (
                    storeStateC.config.original?.crop
                ) {
                    storeStateC.config.original.crop.bottom = Number(v.bottom);

                }
                if (
                    storeStateC.config.original?.crop
                ) {
                    storeStateC.config.original.crop.left = Number(v.left);

                }
                set(storeStateC);
                }} image={base64} region={{ left: (storeState.config.original?.crop?.left ?? 0), bottom: (storeState.config.original?.crop?.bottom ?? Number(imageSizes.height)), right: (storeState.config.original?.crop?.right ?? (Number(imageSizes.width))), top: (storeState.config.original?.crop?.top ?? 0) }} onClose={() => setCropModal(false)} />}</>}
        </LoadingComponent>
    </>
}