// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, Grid } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import cn from 'classnames';
import React from 'react';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
    fieldSet: {
        padding: '14px 0',
        minHeight: '54px',
    },
    fieldSetWithBorder: {
        padding: '14px 0',
        minHeight: '54px',
        borderBottom: 'solid #C2CFE0 1px',
        '&:last-child': {
            borderBottom: 'none',
        }
    },
    titleText: {
        fontFamily: ({ fontFamily }) => fontFamily,
        fontSize: Sizes.heading,
        fontWeight: Sizes.mediumWeight,
        color: Colors.mainTitle,
    },
    valueText: {
        fontFamily: ({ fontFamily }) => fontFamily,
        fontWeight: Sizes.regularWeight,
        textAlign: 'center'
    },
  }));

type Props = Readonly<{
    title?: string;
    value1?: string;
    value2?: string;
    hideSeparator?: boolean
}>;

const AnalyticsTeamsValueRow: React.FC<Props> = (props: Props) => {
    const { font } = useCurrentFont()
    const classes = useStyles({ fontFamily: font });

  return (
      <Grid
          container direction="row"
        alignItems='flex-start'
        justifyContent="space-between"
        className={
            cn( (props.hideSeparator) ? classes.fieldSet : classes.fieldSetWithBorder)
        }
    >
        <Grid item xs={ 5 } >
            <Box className={ classes.titleText }>
                { props.title }
            </Box>
        </Grid>

        <Grid item xs={ 3 } >
            <Box className={ classes.valueText }>
                { props.value1 }
            </Box>
        </Grid>
        <Grid item xs={ 3 } >
            <Box className={ classes.valueText }>
                { props.value2 }
            </Box>
        </Grid>
    </Grid>
  )
};


export default AnalyticsTeamsValueRow;
