import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient, } from 'react-query';
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";
import { useCurrentUndistordConfigsCacheKey } from "../useCurrentUndistordConfigs";
const mutationQl = gql`
  mutation SetUndistortionConfig($uuid: ID, $camera: Int!) {
    setUndistortionConfig(uuid: $uuid, camera: $camera)
  }
`;

interface IResponse {
  uuid?: string;
  camera: number;
}

interface IPatchCommonSettingProps {}

export const useSelectUndistordConfig = (props?: IPatchCommonSettingProps) => {
  const queryClient = useQueryClient();

  const client = useCameraHandler() ?? new GraphQLClient("");
  const request = (patch: IResponse) =>
    client.request<IResponse>(mutationQl, { ...patch });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);

    queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentUndistordConfigsCacheKey);
      },
    });
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = mutation.mutateAsync(props);

    return res.finally(() => {
      queryClient.invalidateQueries({
        predicate: (queries) => {
          return queries.queryKey.includes(useCurrentUndistordConfigsCacheKey);
        },
      });
    });
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};