// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import FieldSet, { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import LOCALIZATION from '../../../Localization';


type Props = Readonly<{
  isServerMode: boolean;
  isEditMode: boolean;
  login: string;
  onChangeLogin: (login: string) => void;
  onChangeLoginCorrect: (isCorrectLogIn: boolean) => void;
} & WrappedComponentProps>;


const UserPopupLogin: React.FC<Props> = (props: Props) => {
  if (props.isEditMode) {
    return (
      <FieldSet
        key={'Login'}
        label={props.intl.formatMessage({ id: LOCALIZATION.user_login })}
        input={{
          type: FieldSetInputType.Other,
          children: (
            <Grid>{props.login}</Grid>
          ),
        }}
      />
    );
  }

  const errorCheckPattern = (value: string): boolean => {
    let check = !!value.length;
    if (props.isServerMode) {
      check = check && (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
    }

    props.onChangeLoginCorrect(check);

    return !check;
  };

  return (
    <FieldSet
      key={'Login'}
      label={(props.isServerMode) ? props.intl.formatMessage({ id: LOCALIZATION.user_server_login }) : props.intl.formatMessage({ id: LOCALIZATION.user_login })}
      onErrorTooltipText={props.isServerMode ? props.intl.formatMessage({ id: LOCALIZATION.invalid_server_login }) : undefined}
      input={{
        errorCheckPattern,
        type: FieldSetInputType.Text,
        value: props.login,
        onChange: props.onChangeLogin,
      }}
    />
  );
};


export default injectIntl(UserPopupLogin);
