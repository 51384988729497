// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Build } from '@mui/icons-material';
import React from 'react';

import { Box, Grid, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { Fonts } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import LOCALIZATION from '../../../Localization';
import { useCalibrationMode } from '../../../hooks/useCalibrationMode';

type Props = Readonly<{}>;


const SX = {
  wrapper: {
    verticalAlign: 'middle',
    marginRight: '15px',
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontFamily: Fonts.main,
    fontSize: Sizes.small,
    fontWeight: Sizes.mediumWeight
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '15px',
    marginRight: '10px',
    '& path': {
      fill: '#5DCA8A',
    },
  },
};

const UndistortionCalibrationModeStatus: React.FC<Props> = () => {
  const intl = useIntl();


  const { mode: calibrationMode } = useCalibrationMode()

  if (!calibrationMode) {
    return <></>;
  }

  return (
    <Grid
      sx={SX.wrapper}
    >
      <Build
        sx={SX.icon}
      />
      <Grid
        sx={SX.text}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Tooltip title={intl.formatMessage({ id: LOCALIZATION.undistord_calibration_mode })}>
            <div>
              {intl.formatMessage({ id: LOCALIZATION.undistord_calibration_mode })}
            </div>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};


export default UndistortionCalibrationModeStatus;
