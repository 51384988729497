// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useCurrentFont } from '../../../../../Configuration/Styles/Fonts';

import { MetricOutputPeriodAll, MetricOutputPeriodInterface } from '../../../../../Data/Camera/Metrics/Types';
import useStyles from './useStyles';


type Props = Readonly<{
  period: MetricOutputPeriodInterface;
  onChanged: (period: MetricOutputPeriodInterface) => void;
}>;


const MetricsChartOutputPeriodSelector: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const currentValue = props.period;

  const handleCurrentValueChange = (period: MetricOutputPeriodInterface) => {
    props.onChanged(period)
  }

  function createDropDownItems() {
      return MetricOutputPeriodAll.map(period => {
          return (
              <MenuItem
                  key = { period.timeInterval }
                  value = { period.timeInterval }
                  onClick = { () => { handleCurrentValueChange(period) } }>
                  <div className = {classes.text}>{ period.displayName }</div>
              </MenuItem>
          )
      })
  }

  return (
    <div className={ classes.root }>
      <FormControl
        variant="outlined"
        className={ classes.formControl }
        size="small"
      >
        <Select
          className={ classes.select }
          displayEmpty
          value={ currentValue.timeInterval }
        >
          { createDropDownItems() }
        </Select>
      </FormControl>
    </div>
  )
};


export default MetricsChartOutputPeriodSelector;
