import JSZip from "jszip";
import { useCallback, useState } from "react";

type FileData = {
  name: string;
  content: string | number[] | Uint8Array | ArrayBuffer | Blob;
};

export const useZip = (defaultfiles?: FileData[] | undefined) => {
  const [zipData, setZipData] = useState<Blob>();

  const zip = useCallback(
    (files: FileData[] | undefined = defaultfiles) => {
      if (!files?.length) {
        return Promise.resolve(undefined);
      }
      const createZipArchive = async () => {
        const zip = new JSZip();

        // Добавление файлов в zip-архив
        files.forEach((file) => {
          zip.file(file.name, file.content);
        });

        // Генерация zip-файла
        const content = await zip
          .generateAsync({ type: "blob", compressionOptions: { level: 0 } })
          .then((v) => {
            return v;
          });

        setZipData(content);
        return content;
      };

      return createZipArchive();
    },
    [defaultfiles]
  );

  return { zip, output: zipData };
};
