import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { useNAPIGraphQLClient } from "../../../../../hooks/useNAPIGraphQLClient";
import { CalibrationInputImageType } from "../StitchinConfig.interface";
import { useCurrentStitchingConfigsCacheKey } from "../useStitchingList";

const mutationQl = gql`
  mutation mutationStitchingPoints(
    $calibrationInput: StitchingCalibrationInput!
    $uuid: String!
  ) {
    mutationStitchingPoints(
      stitchingCalibrationInput: $calibrationInput
      uuid: $uuid
    )
  }
`;
interface IResponse {
  calculateStitching: boolean;
}

export interface CalibrationInput {
  images: {
    type: CalibrationInputImageType;
    index: number;
    width: number;
    height: number;
    points: { x: number; y: number }[];
  }[];
  offsets: { index: number; value: number }[];
}

type Request = { calibrationInput: CalibrationInput; uuid: string };

const debouncedRequest = (patch: Request, client: GraphQLClient) =>
  client.request<IResponse>(mutationQl, patch);
export const useSaveStitchingPoints = () => {
  const queryClient = useQueryClient();
  const client = useNAPIGraphQLClient() ?? new GraphQLClient("");
  const request = (patch: Request) =>
    debouncedRequest(patch, client) ?? Promise.resolve(undefined);
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
    queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentStitchingConfigsCacheKey);
      },
    });
  };
  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentStitchingConfigsCacheKey);
      },
    });
    return res;
  };
  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
