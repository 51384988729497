// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { QueryKey } from 'react-query';

import { queryCache, queryClient } from '../../../../AppProviders';
import { NODE_JS_BACKEND_URL, PAGE_LIMIT } from '../../../../constants';
import { Rule } from '../../../../Pages/EventsPage/ImportEpgPopup/types';
import { getTimezoneInMinutes } from '../../../../Tools/timezone';
import fetchJSONData from '../../../_Networking/ReactQuery/fetch';
import Order from '../../../_Networking/ReactQuery/Order';
import ResponseMany from '../../../_Networking/ReactQuery/ResponseMany';
import ResponseSingle from '../../../_Networking/ReactQuery/ResponseSingle';
import { getCachedDataAllPagedItems, Result, usePagedItems } from '../../../_Networking/ReactQuery/template';
import Event from '../Event';
import { EventPriority, GameType, SportType } from '../EventWithoutID';
import { Action, ActionType } from './useMutation';

type FetchProps = Readonly<{
  page: number;
  limit: number;
  order?: Order;
  orderBy?: keyof Event;
  deleted?: number; // -1 - show all; 0 - show non deleted (default); 1 - show deleted
  priority?: EventPriority; // if undefined then no filter. else filter priority
}>;


const PREFIX_OF_A_COMPOSITE_KEY = 'events';

const getKeyWithoutPage = (): QueryKey => (PREFIX_OF_A_COMPOSITE_KEY);

const getKey = (props: FetchProps): QueryKey => ([
  getKeyWithoutPage(),
  props.page,
  props.limit,
  props.orderBy,
  props.order,
  props.deleted,
  props.priority,
]);

const getAllCached = () => (
  getCachedDataAllPagedItems<Event>(getKeyWithoutPage())
);

const getCached = (props: FetchProps) => (
  queryClient.getQueryData<ResponseMany<Event[]>>(getKey(props))
);

const flushCache = () => {
  const queries = queryCache.findAll([getKeyWithoutPage()]);

  if (queries && queries.length) {
    queries.forEach(({ queryKey }) => {
      queryClient.resetQueries(queryKey);
    })
  }
};


const postEvent = async (event: Event): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<Event>>(
      `${NODE_JS_BACKEND_URL}/event`,
      {
        method: 'POST',
        body: JSON.stringify({
          event,
          timezone: getTimezoneInMinutes(),
        }),
      }
    );

    if (data.error) {
      throw new Error(data.error);
    } else {
      return ({
        type: ActionType.Post,
        event: data.data,
      });
    }
  } catch (error) {
    throw error;
  }
};

const deleteEvent = async (IDs: number[]): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<Event>>(
      `${NODE_JS_BACKEND_URL}/event`,
      {
        method: 'DELETE',
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
        ],
        body: JSON.stringify({ IDs }),
      }
    );

    if (!data?.error) {
      return ({
        IDs,
        type: ActionType.Delete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Delete Event');
    }
  } catch (error) {
    throw error;
  }
};

const recoverDeleteEvent = async (IDs: number[]): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<Event>>(
      `${NODE_JS_BACKEND_URL}/event/recover`,
      {
        method: 'POST',
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
        ],
        body: JSON.stringify({ IDs }),
      }
    );

    if (!data?.error) {
      return ({
        IDs,
        type: ActionType.RecoverDelete,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Recover Event');
    }
  } catch (error) {
    throw error;
  }
};

const disableEvent = async (IDs: number[], disable: boolean): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<Event>>(
      `${NODE_JS_BACKEND_URL}/event/disable`,
      {
        method: 'POST',
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
        ],
        body: JSON.stringify({ IDs, disable }),
      }
    );

    if (!data?.error) {
      return ({
        IDs,
        type: (disable) ? ActionType.Disable : ActionType.Enable,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying Disable Event');
    }
  } catch (error) {
    throw error;
  }
};

export enum EPGFormatType {
  Xmltv = 'xmltv',
  MosComSportJson = 'MosComSport',
}

export const EPGFormatTypeArr = Object.values(EPGFormatType)

export const localizedEPGFormatType = (type: string): string => {
  // switch (type) {
  //   case EPGFormatType.Xmltv:
  //     return intl().formatMessage({ id: LOCALIZATION.event_game_type_game }) || '';
  //   case EPGFormatType.MosComSportJson:
  //     return intl().formatMessage({ id: LOCALIZATION.event_game_type_train }) || '';
  // }
  return type || 'Unknown'
}

const importEpgEvent = async (streamingProfileId?: string | null, epgFileDataInBase64?: string | null, epgFileDataUrl?: string | null, epgFileUpdatePeriodInSec?: number | null, epgFormatType?: EPGFormatType | null, epgDefaultGameType?: GameType | null, epgDefaultSportType?: SportType | null, rules?: Rule[]): Promise<Action | Error> => {
  try {
    const data = await fetchJSONData<ResponseSingle<Event>>(
      `${NODE_JS_BACKEND_URL}/events/import`,
      {
        method: 'POST',
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
        ],
        body: JSON.stringify({ streamingProfileId, epgFileDataInBase64, epgFileDataUrl, epgFileUpdatePeriodInSec, epgFormatType, epgDefaultGameType, epgDefaultSportType, rules }),
      }
    );

    if (!data?.error) {
      return ({
        success: true,
        type: ActionType.ImportEpg,
      });
    } else {
      throw new Error(data?.error || 'Error When Trying to Import EPG');
    }
  } catch (error) {
    throw error;
  }
};

const fetchData = async (props: FetchProps): Promise<ResponseMany<Event[]>> => {
  const cacheData = getCached(props);

  if (cacheData) { return cacheData; }

  const offset = (props.page * props.limit);
  let params = `?timezone=${getTimezoneInMinutes()}&limit=${ props.limit }&offset=${ offset }`;
  if (props.orderBy) {
    params += `&order=${ props.order }&orderBy=${ props.orderBy }`;
  }
  if (props.deleted) {
    params += `&deleted=${ props.deleted }`;
  }
  if (props.priority) {
    params += `&priority=${ props.priority }`;
  }
  return await fetchJSONData<ResponseMany<Event[]>>(
    `${NODE_JS_BACKEND_URL}/events${ params }`,
    {
      method: 'GET',
    }
  );
};


type UseFetchProps = Readonly<{
  enableToFetch: boolean;
  page: number;
  limit?: number;
  order?: Order;
  orderBy?: keyof Event;
  deleted?: number; // -1 - show all; 0 - show non deleted (default); 1 - show deleted
  priority?: EventPriority; // if undefined then no filter. else filter priority
}>;


const useFetch = (props: UseFetchProps): Result<Event> => {
  const { enableToFetch = true, page: pg = 0, limit = PAGE_LIMIT, deleted = 0, priority = undefined, } = props;
  const fetchTemplate = async (queryKey: QueryKey, page: number = 0) => fetchData({
    page,
    limit,
    order: props.order,
    orderBy: props.orderBy,
    deleted,
    priority,
  });

  return usePagedItems<Event>({
    page: pg,
    fetch: fetchTemplate,
    queryKey: getKey({
      page: pg,
      limit,
      order: props.order,
      orderBy: props.orderBy,
      deleted,
      priority,
    }),
    config: {
      enabled: !!enableToFetch,
    },
  });
};


export {
  getKey,
  postEvent,
  deleteEvent,
  recoverDeleteEvent,
  disableEvent,
  importEpgEvent,
  useFetch,
  getCached,
  flushCache,
  getAllCached,
  getKeyWithoutPage,
  PREFIX_OF_A_COMPOSITE_KEY,
};
