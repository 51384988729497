import { Checkbox } from "@mui/material"
import { cloneDeep } from "lodash"
import { useIntl } from "react-intl"
import LoadingComponent from "../../../../../../Components/Loader/index"
import FieldSet, { FieldSetInputType } from "../../../../../../Components/_BaseUI/FieldSet/index"
import { useCachedPanoramaOriginalSizes } from "../../../../../../hooks/useCachedPanoramaOriginalSizes"
import LOCALIZATION from "../../../../../../Localization/index"
import { useStoreEditorState } from "../useStoreEditorState"

export const PanoramaStoreSettings = () => {
    const { formatMessage } = useIntl()
    const { value: imageSizes } = useCachedPanoramaOriginalSizes()
    const { state: storeState, patch, set } = useStoreEditorState()

    return <>
        <LoadingComponent ugly >
            <FieldSet hideSeparator labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.StaticLabel,
                value: imageSizes ? `${Number(imageSizes.width)}x${imageSizes.height}` : "",
            }} label={formatMessage({ id: LOCALIZATION.original_panorama_size })}></FieldSet>
            <FieldSet hideSeparator labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.Other,
                children: (<Checkbox
                    checked={!storeState.disabled}
                    onChange={(v) => {
                        patch({ disabled: !v.target.checked })
                    }} />)
            }}
                label={formatMessage({ id: LOCALIZATION.store_panorama })} />

            <FieldSet hideSeparator labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.StaticLabel,
                value: 'h265',

            }}
                label={formatMessage({ id: LOCALIZATION.encoder })}
            ></FieldSet>
            <FieldSet hideSeparator labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.Select,
                value: storeState.config.original?.["max-parts"] ? String(storeState.config.original?.["max-parts"]) : '3',
                options: (new Array(16)).fill(0).map((v, i) => ({ id: String(i), name: String(i) })),
                onChange: (v) => {
                    const storeStateC = cloneDeep(storeState);
                    if (
                        storeStateC.config.original
                    ) {
                        storeStateC.config.original["max-parts"] = Number(v);

                    }
                    set(() => storeStateC);
                },
            }} label={formatMessage({ id: LOCALIZATION.file_parts_count })}></FieldSet>
            <FieldSet hideSeparator labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.Float,
                value: Number(storeState.config.original?.bitrate ?? 30000000) / 1000000,
                min: 1000 / 1000000,
                max: 1000000000 / 1000000,
                onChange: (v) => {
                    const storeStateC = cloneDeep(storeState);
                    if (
                        storeStateC.config.original
                    ) {
                        storeStateC.config.original.bitrate = Number(v) * 1000000;

                    }
                    set(() => storeStateC);
                },
            }} label={formatMessage({ id: LOCALIZATION.video_bitrate_per_file })}></FieldSet>
        </LoadingComponent>
    </>
}