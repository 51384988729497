// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { generateUserError } from "../../../Data/_Networking/AxiosFetch/Errors/Tools";
import ApiError from "../../../Data/_Networking/AxiosFetch/Errors/Types";
import BaseCommandExecutor from "../../../Data/_Networking/AxiosFetch/Executor";
import { PiRestApi } from "../../../Data/_Networking/AxiosFetch/RestApi";
import LOCALIZATION from "../../../Localization";
import { intl } from "../../../Localization/LocalizationProvider";
import {
  ApiRequestConfig,
  ApiRequestConfigInterface,
  NetworkRequestInterface,
} from "../Types";

interface CameraApiRequestsApiServiceInterface {
  fetchApiRequests(
    succeeded: (configs: ApiRequestConfig[]) => void,
    failed: (error: ApiError) => void
  ): void;
  getRequest(
    name: string,
    succeeded: (value: any) => void,
    failed: (error: ApiError) => void
  ): void;
  fetchNetworkRequest(
    id: string,
    succeeded: (networkRequest: NetworkRequestInterface) => void,
    failed: (error: Error) => void
  ): void;
}
/** @deprecated Overengeneering */
export default class CameraApiRequestsApiService
  implements CameraApiRequestsApiServiceInterface
{
  fetchApiRequests(
    succeeded: (configs: ApiRequestConfig[]) => void,
    failed: (error: ApiError) => void
  ): void {
    const command = PiRestApi.fetchApiRequestsCommand();
    const executor = new BaseCommandExecutor<
      Array<Omit<ApiRequestConfigInterface, "type">>
    >(command);

    executor.execute(
      (v) => succeeded(v.map((v) => new ApiRequestConfig(v))),
      failed
    );
  }

  getRequest(
    name: string,
    succeeded: (value: any) => void,
    failed: (error: ApiError) => void
  ): void {
    const command = PiRestApi.getApiRequestCommand(name);
    const executor = new BaseCommandExecutor(command);

    executor.execute(succeeded, failed);
  }

  postRequest(
    name: string,
    json: any,
    succeeded: (networkRequest: NetworkRequestInterface) => void,
    failed: (error: ApiError) => void
  ): void {
    const command = PiRestApi.postApiRequestCommand(name, json);
    const executor = new BaseCommandExecutor<NetworkRequestInterface>(command);

    executor.execute(succeeded, failed);
  }

  fetchNetworkRequest(
    id: string,
    succeeded: (networkRequest: NetworkRequestInterface) => void,
    failed: (error: Error) => void
  ): void {
    const filter = {
      filter_id: id,
    };
    const command = PiRestApi.getNetworkRequestsCommand(filter);
    const executor = new BaseCommandExecutor<NetworkRequestInterface[]>(
      command
    );

    executor.execute(
      (requests) => {
        if (requests.length === 1) {
          succeeded(requests[0]);
        } else {
          failed(
            Error(
              intl().formatMessage({
                id: LOCALIZATION.network_request_not_found,
              })
            )
          );
        }
      },
      (error) => failed(generateUserError(error))
    );
  }
}
