// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import styled from '@emotion/styled';
import { Grid, Slider } from '@mui/material';
import { debounce, isArray } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FieldSet, { FieldSetInputType } from '../../../../Components/_BaseUI/FieldSet';
import { RefreshIcon2 } from '../../../../Icons/Icons';
import { useFirstDefinedValue } from '../../../../hooks/useFirstDefined';



type Props = Readonly<{
    label: string;
    value: number;
    min: number;
    max: number;
    step?: number;
    defaultValue?: number;
    onChange: (value: number) => void;
}>;


const Refresh = styled.button`
   background: none;
    border: 2px solid#18A0FB;
    border-radius: 6px;
    padding: 0;
    padding: 4px 5px 1px 4px;

    align-content: center;
    & :hover {
        cursor: pointer;
    };
`

/**
 * Copied {@link src/Pages/StreamingPage/Profiles/StreamingSettingsProfilePopup/Audio/SliderWrapper.tsx} and renewed
 * @param props
 * @returns
 */
export const SliderWrapper = (({ max, min, onChange, label, value: externalValue, step, defaultValue }: Props) => {
    const defaultV = useFirstDefinedValue(defaultValue ?? externalValue)
    const debounceOutput = useMemo(() => debounce(onChange, 300), [onChange])
    const [value, setValue] = useState<number>(externalValue);
    useEffect(() => {
        setValue(externalValue)
    }, [externalValue])

    useEffect(() => {
        debounceOutput?.(value);

    }, [debounceOutput, value])
    const handleChangeInInput = useCallback((value: number | null) => {
        if (value !== null) {
            setValue(value);
        }
    }, []);
    const handleChangeInSlider: (event: Event, value: number | number[], activeThumb: number) => void = useCallback((event, value, activeThumb) => {
        if (!isArray(value)) {
            setValue(value);

        }
    }, []);


    return (
        <Grid
            container
            gap={5}
        >
            <Grid
                item
                xs={12}
                sm={4} xl={2}
            >
                <FieldSet
                    key={label}
                    label={label}
                    input={{
                        type: FieldSetInputType.Float,
                        value: value,
                        min: min,
                        max: max,
                        step: step,
                        onChange: handleChangeInInput,
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12} sm xl
                display={"flex"}
                alignItems={"center"}

            >
                <Slider
                    valueLabelDisplay="auto"
                    defaultValue={defaultV}
                    value={value}
                    min={min}
                    max={max}
                    step={step}
                    onChange={handleChangeInSlider}
                />
            </Grid>

            <Grid>
                <Refresh onClick={() => setValue(defaultV ?? 0)}>
                    <RefreshIcon2 />
                </Refresh>

            </Grid>
        </Grid>
    );
});



