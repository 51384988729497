export enum AWB_MODE {
    off = "off",
    auto = "auto",
    incandescent = "incandescent",
    fluorescent = "fluorescent",
    warmFluorescent = "warm-fluorescent",
    daylight = "daylight",
    cloudyDaylight = "cloudy-daylight",
    twilight = "twilight",
    shade = "shade"
}

export enum AeAntibanding {
    off = "off",
    auto = "auto",
    hz50 = "50hz",
    hz60 = "60hz",
}

export interface CommonSettings {
  ["main-camera"]?: number;
  ["denoise-strength"]?: number;
  ["edge-enhance-strength"]?: number;
  ["frame-rate"]?: number;
  ["awb-mode"]?: AWB_MODE;
  ["ae-antibanding"]?: AeAntibanding;
}

export const globalConfigCacheKey = "globalConfigCacheKey"