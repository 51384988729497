// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useCurrentFont } from '../../../../../Configuration/Styles/Fonts';

import { MetricDataKey, MetricTypeInterface } from '../../../../../Data/Camera/Metrics/Types';
import { formattedMetricUnit } from '../../../../../Tools/Tools';
import MainCheckbox, { MainCheckboxType } from '../../../../_BaseUI/MainCheckbox/MainCheckbox';
import useStyles from './useStyles';


type Props = Readonly<{
  metrics: MetricTypeInterface[];
  selected: MetricDataKey[];
  onChangeMetricTypeEnabled: (metric: MetricTypeInterface, isEnabled: boolean) => void;
}>;


const MetricsCollection: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const extraItems: number[] = []
  // Нужно добавить пустых элементов до полного ряда
  const itemsInLine = 3
  const itemsMissInLine = props.metrics.length % itemsInLine
  if (itemsMissInLine !== 0) {
    for (let i = 0; i < itemsInLine - itemsMissInLine; i++) {
      extraItems.push(i)
    }
  }

  return (
    <Grid container>
      { props.metrics.map(metric => {
        const isChecked = props.selected.includes(metric.dataKey)

        return (
          <Grid item key={ metric.dataKey } xs={ 4 } className={ clsx(classes.row, classes.bordered) }>
            <MainCheckbox
              buttonType={ MainCheckboxType.CircleShaped }
              title={ `${ metric.shortName }, ${ formattedMetricUnit(metric.unit) }` }
              isChecked={ isChecked }
              onClicked={ () => props.onChangeMetricTypeEnabled(metric, !isChecked) }/>
          </Grid>
        )
      }) }
      {
        extraItems.map(index => {
          return (
            <Grid item key={ `extra_${ index }` } xs={ 4 } className={ clsx(classes.row, classes.bordered) }>
            </Grid>
          )
        })
      }
    </Grid>
  )
};


export default MetricsCollection;
