// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid, GridSize, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { createStyles, makeStyles } from '@mui/styles';
import cn from 'classnames';
import { isUndefined } from 'lodash';
import React, { ReactNode, useMemo } from 'react';
import { Colors } from '../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import {MainButton} from '../MainButton';
import QuestionTooltip from '../QuestionTooltip';


type Props = Readonly<{
  label?: ReactNode;
  labelSx?: SxProps<Theme>;
  buttonSx?: string;
  tooltipQuestionText?: string;
  helperText?: string;
  multiLine?: boolean;
  children?: JSX.Element;
  hideSeparator?: boolean;
  button?: { onClick?: () => void, label: ReactNode };
  labelSize?: boolean | GridSize;
  inputSize?: boolean | GridSize;
  inputSx?: SxProps<Theme>;
  error?: boolean;
  hint?: boolean;
}>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  fieldSet: {
    padding: '14px 0',
    minHeight: '54px',
  },
  fieldSetWithBorder: {
    padding: '14px 0',
    minHeight: '54px',
    borderBottom: 'solid #C2CFE0 1px',
    '&:last-child': {
      borderBottom: 'none',
    }
  },
  fieldSetMultiLine: {
    display: 'block !important',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.titleFiled,
    fontWeight: Sizes.regularWeight,
    color: Colors.mainTitle,
  },
}));

const FieldSetWrapper: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { button = false, labelSize, inputSize, inputSx, error, hint, buttonSx } = props;
  const tooltip = useMemo(() => {
    if (hint) {
      return {
        zIndex: 1300,
        '& .MuiTooltip-tooltip': {
          fontSize: '14px',
          color: '#fff',
          backgroundColor: ' rgb(97, 192, 255)',
        },
        '& .MuiTooltip-arrow:before': {
          backgroundColor: ' rgb(97, 192, 255)',
        },
      }
    }
    if (error) {
      return {
        zIndex: 1300,
        '& .MuiTooltip-tooltip': {
          fontSize: '14px',
          color: '#fff',
          backgroundColor: '#EB5757',
        },
        '& .MuiTooltip-arrow:before': {
          backgroundColor: '#EB5757',
        },
      }
    }

  }, [error, hint])
  return (
    <Grid
      container
      className={
        cn( (props.hideSeparator) ? classes.fieldSet : classes.fieldSetWithBorder, {
          [classes.fieldSetMultiLine]: (props.multiLine === true),
        })
      }
    >
      <Grid
        item
        xs={!isUndefined(labelSize) ? labelSize : (props.multiLine) ? 'auto' : (props.children) ? ((largeScreen) ? 6 : 6) : 'auto'}
        className={ cn({ [classes.label]: (props.multiLine !== true) }) }
        sx={{...props.labelSx}}
      >
        {props.label || ''}
        { (props.tooltipQuestionText) &&
          <QuestionTooltip
            toolTip={ props.tooltipQuestionText }
          />
        }
      </Grid>
      <Tooltip
        arrow
        open={ !!props.helperText }
        title={ props.helperText || '' }
        PopperProps={ {
          sx: tooltip
        } }
      >
        <Grid
          item
          xs={!isUndefined(inputSize) ? inputSize : (props.multiLine !== true) ? ((largeScreen) ? 6 : 6) : 12}
          sx={ {
            position: 'relative',
            ...inputSx
          }}
          alignItems={'center'}
        >
          <Grid item>

            {props.children}
          </Grid>
          {button &&
            <Grid marginLeft={'auto'} xs={false} item >
              <MainButton className={buttonSx} onClicked={button.onClick} title={button.label} />
            </Grid>}
        </Grid>
      </Tooltip>

    </Grid>
  );
};


export default FieldSetWrapper;
