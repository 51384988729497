// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Divider, Grid } from '@mui/material';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useCurrentFont } from '../../../../Configuration/Styles/Fonts';

import { RemoteCameraStateValue } from '../../../../Data/Camera/RemoteCameraState';
import LOCALIZATION from '../../../../Localization';
import { getBarHeaderColor } from './helpers';
import useStyles from './useStyles';


type Props = Readonly<{
  name: string;
  cameraHardwareState: RemoteCameraStateValue;
} & WrappedComponentProps>;


const CAMERA_STATUS = {
  [RemoteCameraStateValue.OnlineOK]: LOCALIZATION.online,
  [RemoteCameraStateValue.OnlineWarning]: LOCALIZATION.cameraOnlineWarning,
  [RemoteCameraStateValue.OnlineCritical]: LOCALIZATION.cameraOnlineCritical,
  [RemoteCameraStateValue.Offline]: LOCALIZATION.offline,
  [RemoteCameraStateValue.Unknown]: LOCALIZATION.unknown,
  [RemoteCameraStateValue.Error]: LOCALIZATION.cameraError,
};

const CameraHeader: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });
  const barColor = getBarHeaderColor(props.cameraHardwareState);
  const textStatusID = CAMERA_STATUS[props.cameraHardwareState];

  return (
    <div className={ classes.root }>
      <div className={ classes.toolbar } style={ { background: barColor } }>
        <Grid container spacing={ 1 } direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <div
              className={ classes.name }
            >
              { `${ props.name }: ${ props.intl.formatMessage({ id: textStatusID }) }` }
            </div>
          </Grid>
          {/*<Grid item>*/}
          {/*  <Grid container spacing={ 0 } direction="row" alignItems="center">*/}
          {/*    { 'renderStatusesName' }*/}
          {/*    { 'renderStatuses' }*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </Grid>
      </div>
      <Divider light />
    </div>
  );
};


export default injectIntl(CameraHeader);
