// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { isNumber } from "lodash";
import React, { ReactNode } from "react";
import { Point } from "transformation-matrix";

import { css } from "@emotion/css";
import { Grid, IconButton, TextField } from '@mui/material';
import { useIntl } from "react-intl";
import { BreakpointEnum, useWidth } from "../../../hooks/useWidth";
import LOCALIZATION from "../../../Localization/index";
import { FitSvg, RefreshSvg } from "../shared/SvgIcons";
import { PointsToolbar } from "./PointsToolbar";
import { usePanoramaActions } from "./usePanormaActions";

type Props = {
  points: [Array<Point>, Array<Point>];
  offset: number;
  setOffset: (v: number) => void;
  setPoints(points: [Array<Point>, Array<Point>]): void;
  selectedPinIndex: number;
  addPoint: () => void;
  setSelectedPinIndex: (n: number) => void;
  onRefresh: () => void;
};


export const SecondaryToolbar: React.FC<Props> = (props: Props) => {
  const {
    points,
    offset,
    setOffset,
    setPoints,
    selectedPinIndex,
    setSelectedPinIndex,
    addPoint,
    onRefresh,
  } = props;
  const [offsetValue, setOffsetValue] = React.useState("");
  const [offsetError, setOffsetError] = React.useState("");
  const { formatMessage } = useIntl();
  const { fit, fullSize, } = usePanoramaActions()
  React.useEffect(() => {
    setOffsetValue("" + offset);
  }, [offset]);
  // const theme = useTheme();
  const size = useWidth();
  const mobileView = size < BreakpointEnum.md;
  const columns: ReactNode[] = [
    <Grid item style={{ margin: 5, fontSize: `14px` }}>
      {formatMessage({ id: LOCALIZATION.cylinder_stitching_editor_hint })}
    </Grid >,
    <Grid item marginLeft={mobileView ? '' : 'auto'} style={{ justifySelf: "center", display: "flex" }}>
      <PointsToolbar
        selectedPinIndex={selectedPinIndex}
        addPoint={addPoint}
        setSelectedPinIndex={setSelectedPinIndex}
        points={points}
        setPoints={setPoints}
      />
    </Grid >,
    <Grid item marginLeft={mobileView ? '' : 'auto'} >
      <Grid container> <Grid item xs={2} className={css`display: flex; justify-content: center; `}><IconButton
        onClick={fit}
        size="small"
        style={{ margin: 5, width: 38 }}
      >
        <FitSvg />
      </IconButton>
      </Grid>
        <Grid item xs={2} className={css`display: flex; justify-content: center; align-items: center; `}><IconButton
          onClick={fullSize}
          size="small"
          className={css`
                margin: 5px;
    width: 65px;
    border-radius: 5px;
    background: rgb(144, 160, 181);
    height: fit-content;
    color: white;
          `}

        >
          100%
        </IconButton></Grid>
        <Grid item xs={2} className={css`display: flex; justify-content: center; `}><IconButton
          onClick={onRefresh}
          size="small"
          style={{ margin: 5, width: 38 }}
        >
          <RefreshSvg />
        </IconButton></Grid>

        <Grid item xs
        style={{
          alignSelf: "center",
          display: "flex",
          alignItems: "center",
        }}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label={formatMessage({ id: LOCALIZATION.offset })}
            value={offsetValue}
            onChange={(evt) => {
              setOffsetValue(evt.target.value);
              const num = parseFloat(evt.target.value);
              if (isNumber(num) && num >= 0 && num <= 100) {
                setOffset(num);
                setOffsetError("");
              } else {
                setOffsetError("err");
              }
            }}
            error={!!offsetError}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*", style: { height: "38px", padding: '0 0 0 5px' } }}
          />


        </Grid>
      </Grid>
    </Grid>,


  ]
  const columnsSorted = mobileView ? [0, 2, 1].map((index) => columns?.[index]) : [0, 1, 2].map((index) => columns?.[index])

  return (
    <Grid
      container className="secondary-header"
      style={{
        display: "flex",
        background: "white",
        borderBottomWidth: 1,
        alignItems: "center",
        width: '100%',
        padding: '0 40px',
        flexDirection: mobileView ? 'column' : 'row',
      }}
    >
      {columnsSorted}
    </Grid>
  );
};
