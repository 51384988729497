import * as PIXI from "pixi.js-legacy";
import { BehaviorSubject } from "rxjs";

export class Vertex extends PIXI.Sprite {
  get position$() {
    return this._position$.asObservable();
  }

  setPosition(x?: number | undefined, y?: number | undefined) {
    this.position.set(x, y);
    this._position$.next(this.position.clone());
  }

  private readonly _position$: BehaviorSubject<
    PIXI.ObservablePoint<PIXI.Point>
  >;
  private isDraging = false;
  private graphics = new PIXI.Graphics();
  private baseSize = { width: 15, height: 15 }; // Базовый размер точки
  private hoverSizeIncrease = { width: 50, height: 50 }; // Увеличение при ховере
  private hoverDistance = 100; // Расстояние, на котором начинается увеличение

  constructor(
    public point: PIXI.Point,
    public vertexFillColor: number = PIXI.utils.rgb2hex([1, 1, 1]),
    public vertexStrokeColor: number = 0x18a0fb
  ) {
    super();
    this._position$ = new BehaviorSubject(super.position.clone());
    this.texture = PIXI.Texture.WHITE;
    this.tint = PIXI.utils.rgb2hex([0, 0, 1]);
    this.width = this.baseSize.width;
    this.height = this.baseSize.height;
    this.anchor.set(0.5);
    this.interactive = true;
    this.position.x = point.x;
    this.position.y = point.y;
    this.on("pointerdown", this.onDragStart);
    this.on("pointerup", this.onDragEnd);
    this.on("pointerupoutside", this.onDragEnd);
    this.on("globalpointermove", this.onPointerMove);
    this.on("added", () => {
      this.addChild(this.graphics);
      this.drawFigure();
    });
    this.position$.subscribe(() => {
      this.drawFigure();
    });
    this.cursor = "grab";
  }

  private drawFigure() {
    this.graphics.clear();
    this.graphics.beginFill(this.vertexFillColor);
    this.graphics.lineStyle(1, this.vertexStrokeColor);
    this.graphics.drawRect(
      -this.width / 2,
      -this.height / 2,
      this.width,
      this.height
    );
    this.graphics.endFill();
  }

  private onPointerMove(event: PIXI.FederatedPointerEvent) {
    const mousePosition = this.parent.toLocal(event.global.clone());
    const distance = Math.sqrt(
      Math.pow(this.position.x - mousePosition.x, 2) +
        Math.pow(this.position.y - mousePosition.y, 2)
    );

    if (distance <= this.hoverDistance) {
      this.width = this.baseSize.width + this.hoverSizeIncrease.width;
      this.height = this.baseSize.height + this.hoverSizeIncrease.height;
      this.cursor = "pointer";
    } else {
      this.width = this.baseSize.width;
      this.height = this.baseSize.height;
      this.cursor = "grab";
    }
    this.onDragMove(event);

    this.drawFigure();
  }

  private onDragStart(event: PIXI.FederatedPointerEvent) {
    this.isDraging = true;
    this.cursor = "grabbing";
  }

  private onDragMove(event: PIXI.FederatedPointerEvent) {
    if (!this.isDraging) {
      return;
    }
    this.cursor = "grabbing";
    const npoint = this.parent.toLocal(event.global.clone());
    this.setPosition(npoint.x, npoint.y);
    return true;
  }

  private onDragEnd(event: PIXI.FederatedPointerEvent) {
    this.isDraging = false;
    this.cursor = "grab";
  }
}
