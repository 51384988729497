// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { PATHS } from './constants';
import { MenuElement } from './Menu/Data/MenuElement';
import NotFound from './Pages/404';
import { Base64View } from './Pages/Base64View/index';
import PageCameras from './Pages/CamerasPage';
import EmptyPage from './Pages/EmptyPage';
import LoginPage from './Pages/LoginPage';


type Props = Readonly<{
  items: MenuElement[];
  isAuthorised: boolean;
  logoutReasonError?: Error;
  onSignedIn: () => void;
}>;


const AppRoutes: React.FC<Props> = (props: Props) => {

  const renderRoute = (path: string, element: JSX.Element): JSX.Element => (<Route key={ path } path={ path } element={ element } />);

  // create array of <Route for item and all its bus items.
  // items with isDynamicPathId=true have in path /:id to get some id from path
  const renderRoutes = (item: MenuElement): JSX.Element[] => (renderItemRoutes2(item))
  const renderItemRoutes2 = (item: MenuElement, parentPath?: string): JSX.Element[] => {
    var outArr: JSX.Element[] = []

    var itemPath = (parentPath || "") + ((item.isDynamicPathId) ? `/:${item.id}` : `/${item.id}`);

    if (!item.isMenuSection()) { // menu section did not have route
      const Component = (item?.Component) ? item.Component : NotFound;
      const route = renderRoute(itemPath, <RequireAuth isAuthorised={ props.isAuthorised }><Component /></RequireAuth>);
      if (route) {
        outArr.push(route)
      }
    }

    if (!!item.items?.length) {
      for (const subItem of item.items) {
        const subRoutesArr = renderItemRoutes2(subItem, itemPath);
        if (subRoutesArr.length > 0) {
          outArr.push( ...subRoutesArr);
        }
      }
    }

    return outArr;
  };

  const RootPage = PageCameras;

  const routesArr = props.items.flatMap(renderRoutes);
  // routesArr.forEach((route) => {
  //   console.log(`<Route path:${route.props.path}>`)
  // });

  return (
    <Routes>
      {
        renderRoute(
          '/',
          <RequireAuth isAuthorised={ props.isAuthorised }>
            <RootPage />
          </RequireAuth>
        )
      }
      { renderRoute(PATHS.login, <LoginPage error={ props.logoutReasonError } onSignedIn = { props.onSignedIn } />) }
      { renderRoute(PATHS.oAuth, <EmptyPage />) }
      {renderRoute(PATHS.base64, <Base64View />)}

      {// route if not autorized always open login page
        (!props.isAuthorised) &&
        renderRoute(
          '/*',
          <RequireAuth isAuthorised={ props.isAuthorised }>
            <></>
          </RequireAuth>
        )
      }
      { routesArr }
      {/*<Route path="*" element={ <NotFound /> } />*/}
    </Routes>
  );
};



type RequireProps = Readonly<{
  isAuthorised: boolean;
  children: JSX.Element;
}>;

const RequireAuth: React.FC<RequireProps> = (props: RequireProps) => {
  const location = useLocation();

  if (!props.isAuthorised) {
    return (
      <Navigate
        replace
        to={ PATHS.login }
        state={ { from: location } }
      />
    );
  }

  return props.children;
};

export default AppRoutes
