import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient } from "react-query";
import { useNAPIGraphQLClient } from "../../../../../hooks/useNAPIGraphQLClient";
import { StitchingConfig } from "../StitchinConfig.interface";
import { useCurrentStitchingConfigsCacheKey } from "../useStitchingList";

const mutationQl = gql`
  mutation AddStitchingConfig($config: StoredStitchingConfigurationInput!) {
    writeStitchingConfig(storedStitchingConfigurationInput: $config)
  }
`;

interface IResponse {
  writeStitchingConfig: string;
}

type RequestParams = StitchingConfig;

export const useWriteStitchingItem = () => {
  const queryClient = useQueryClient();
  const client = useNAPIGraphQLClient() ?? new GraphQLClient("");
  const request = ({ config }: { config: RequestParams }) => {
    return client.request<IResponse>(mutationQl, { config });
  };
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(useCurrentStitchingConfigsCacheKey);
      },
    });
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
