import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from "react";
import { useIntl } from "react-intl";
import { BehaviorSubject } from "rxjs";
import LOCALIZATION from "../Localization";

type ConfirmDialogState = {
  isOpen: boolean;
  timeout?: number;
  timeoutAction?: "cancel" | "confirm";
  bodyText?: string;
  resolve?: (value: boolean) => void;
};

const ConfirmDialogContext = createContext<{
  confirm: (options: Omit<ConfirmDialogState, "isOpen">) => Promise<boolean>;
  confirmDialog: ConfirmDialogState | null;
}>({
  confirm: () => Promise.resolve(false),
  confirmDialog: null,
});

export const ConfirmDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { formatMessage } = useIntl();
  const [currentDialog, setCurrentDialog] = useState<ConfirmDialogState | null>(
    null
  );

  const queue$ = new BehaviorSubject<ConfirmDialogState[]>([]);

  const processQueue = useCallback(() => {
    if (currentDialog || queue$.value.length === 0) return;
    const [nextDialog, ...rest] = queue$.value;
    queue$.next(rest);
    setCurrentDialog(nextDialog);
  }, [currentDialog]);

  const handleClose = useCallback(() => {
    if (currentDialog?.resolve) {
      currentDialog.resolve(false);
    }
    setCurrentDialog(null);
  }, [currentDialog]);

  const handleConfirm = useCallback(() => {
    if (currentDialog?.resolve) {
      currentDialog.resolve(true);
    }
    setCurrentDialog(null);
  }, [currentDialog]);

  const confirm = useCallback(
    (options: Omit<ConfirmDialogState, "isOpen">) => {
      return new Promise<boolean>((resolve) => {
        queue$.next([...queue$.value, { ...options, isOpen: true, resolve }]);
        processQueue();
      });
    },
    [processQueue]
  );

  return (
    <ConfirmDialogContext.Provider value={{ confirm, confirmDialog: currentDialog }}>
      {children}
      {currentDialog && (
        <Dialog open={currentDialog.isOpen} onClose={handleClose}>
          <DialogTitle>{formatMessage({ id: LOCALIZATION.confirmation })}</DialogTitle>
          <DialogContent>
            <DialogContentText>{currentDialog.bodyText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {formatMessage({ id: LOCALIZATION.cancel })}
            </Button>
            <Button onClick={handleConfirm} autoFocus>
              {formatMessage({ id: LOCALIZATION.confirm })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmDialogContext.Provider>
  );
};

const useConfirm = () => {
  const context = useContext(ConfirmDialogContext);

  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmDialogProvider");
  }

  return context;
};

export default useConfirm;
