// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { SettingsRounded } from '@mui/icons-material';
import { Button, Modal } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { Colors } from '../../../../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../../../../Configuration/Styles/Fonts';
import { CalibrationDataProvider } from '../../../../../../Data/Camera/Metrics/Calibration/DataProvider';
import Calibration from '../Calibration/View';
import arrow from './arrow.svg';
import compassBody from './compassBody.svg';
import { useCameraAzymuth } from './Hooks';


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    width: 176,
    height: 176,
    position: 'relative'
  },
  body: {
    zIndex: 1,
    position: 'absolute'
  },
  arrow: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 57
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 3,
    paddingLeft: 4,
    paddingBottom: 4,
    minWidth: 32,
    minHeight: 32,
    display: 'none'
  }
}));


type Props = Readonly<{}>;


const Compass: React.FC<Props> = React.forwardRef((props: Props, ref: any) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })
  const angle = useCameraAzymuth()

  const [isCalibrating, setIsCalibrating] = useState(false)
  const handleSettings = () => {
    if (isCalibrating) {
      CalibrationDataProvider.shared.stopCalibrating()
    }
    setIsCalibrating(!isCalibrating)
  }

  return (
    <div
      ref={ ref }
      className={ classes.root }
    >
      {
        (angle !== undefined) ?
          <>
            <img src={ arrow } className={ classes.arrow } style={ { transform: `rotate(${ angle }deg)` } } alt="arr" />
            <img src={ compassBody } height="176" width="176" className={ classes.body } alt="arr" />
            <Button className={ classes.button } onClick={ handleSettings }>
              <SettingsRounded style={ { color: Colors.mainGrey } } />
            </Button>
            <Modal
              className={ classes.modal }
              open={ isCalibrating }
              onClose={ handleSettings }
            >
              <Calibration />
            </Modal>
          </>
          :
          <img src={ compassBody } height="176" width="176" className={ classes.body } alt="compassBody" />
      }
    </div>
  );
});


export default Compass;
