import { Container } from "pixi.js-legacy";
import { BehaviorSubject } from "rxjs";

export class PanningContainer extends Container {
    private isDragging: boolean = false;
    private isChildDragging: boolean = false; // Add a flag to track child dragging
    public zoom$ = new BehaviorSubject(0)
    constructor() {
        super()
        this.interactive = true;
        this.sortableChildren = true;
        this.on('pointerdown', (event) => {
            this.isDragging = true;
        });
        this.on('pointermove', (event) => {
            if (this.isDragging && !this.isChildDragging) {
                this.cursor = "grabbing";
                this.x += event.movementX;
                this.y += event.movementY;
            }
        });

        this.on('pointerup', () => {
            this.cursor = "grab";
            this.isDragging = false;
        });

        this.on('pointerupoutside', () => {
            this.cursor = "grab";
            this.isDragging = false;
        });

        this.on('wheel', (event) => {
            const delta = event.deltaY;

            // Zoom based on the wheel delta
            const zoom = delta < 0 ? 1.01 : 0.99;
            this.zoom$.next(zoom);

        });
        this.zoom$.subscribe(zoom => {
            this.scale.x *= zoom;
            this.scale.y *= zoom;

            // Apply limits to the zoom
            const minScale = 0.1;
            const maxScale = 3;
            this.scale.x = Math.max(minScale, Math.min(maxScale, this.scale.x));
            this.scale.y = Math.max(minScale, Math.min(maxScale, this.scale.y));
        })
        this.cursor = "grab";
        this.zoom$.next(1);
        // this.on("added", () => {
        //     const parent = this.parent;
        //     parent.interactive = true;
        //     parent.cursor = "grab";
        //     let isDragging = false;
        //     parent.on('pointerdown', (event) => {
        //         isDragging = true;
        //     });
        //     parent.on('pointermove', (event) => {
        //         if (isDragging && !this.isChildDragging) {
        //             parent.cursor = "grabbing";
        //             this.x += event.movementX;
        //             this.y += event.movementY;
        //         }
        //     });

        //     parent.on('pointerup', () => {
        //         parent.cursor = "grab";
        //         isDragging = false;
        //     });

        //     parent.on('pointerupoutside', () => {
        //         parent.cursor = "grab";
        //         isDragging = false;
        //     });



        // });
    }
    private onChildPointerDown() {
        this.isChildDragging = true;
    }
    private onChildPointerUp() {

        this.isChildDragging = false;

    }
    private onChildPointerOutside() {

        this.isChildDragging = false;

    }
    protected onChildrenChange() {
        for (const child of this.children) {
            child.off('pointerdown', this.onChildPointerDown, this);
            child.off('pointerup', this.onChildPointerUp, this);
            child.off('pointerupoutside', this.onChildPointerOutside, this);
            child.on('pointerdown', this.onChildPointerDown, this);
            child.on('pointerup', this.onChildPointerUp, this);
            child.on('pointerupoutside', this.onChildPointerOutside, this);
        }
        super.onChildrenChange()
    }

}