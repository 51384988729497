import { debounce } from "lodash";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { JAVA_BACKEND_URL } from "../../constants";
import fetchJSONData from "../_Networking/ReactQuery/fetch";

interface ReleaseInfo {
  kvId: string; // Идентификатор ключа версии
  key: string; // Ключ
  title: string; // Заголовок информации о релизе
  group: string; // Группа релизов компонентов
  flagShared: boolean; // Флаг, указывающий на совместное использование
  whenMarkedDefault: number; // Время, когда было отмечено значение по умолчанию (timestamp)
  value: string; // Значение в формате JSON (строка)
  flags: string; // Флаги
}

interface ParsedValue {
  app?: string;
  releaseTag?: string;
  version?: string;
}

interface IResponseItem extends Omit<ReleaseInfo, "value"> {
  value: ParsedValue;
}

interface IUseFetchActualMetricsProps<TData> {
  /**
   * Вы можете настроить опции запроса, если это необходимо.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}

/**
 * @deprecated its used only for legacy code withou hooks comperbility and in within internal of this file
 * @returns
 */
export const fetchActualVersions = async () => {
  const path = new URL(`${JAVA_BACKEND_URL}/kvdata/last`);
  path.searchParams.set("filter_group", "componentsReleaseVersions");
  const response = await fetchJSONData<ReleaseInfo[]>(path.toString());
  const result = response.map((v) => {
    let value: {
      app?: string;
      releaseTag?: string;
      version?: string;
    } = {};
    try {
      value = JSON.parse(v.value) as {
        app?: string;
        releaseTag?: string;
        version?: string;
      };
    } catch {}
    return {
      ...v,
      value,
    };
  });
  return result;
};
const cacheKey = "useFetchActualMetrics";

export const useFetchActualMetrics = (
  props?: IUseFetchActualMetricsProps<IResponseItem[] | undefined>
) => {
  const { options } = props ?? {};
  const queryClient = useQueryClient();
  const key = [cacheKey] as QueryKey;

  const fetchActualMetrics = fetchActualVersions;

  const request = () => fetchActualMetrics();

  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };

  const query = useQuery(key, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
  });

  const fetchData = debounce(async () => {
    return queryClient.fetchQuery(key, request);
  }, 100);

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
  };
};
