// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import cn from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import { FieldSetInputType } from '.';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';

export type ChangeText = (value: string) => void;

export type FieldSetInputText = Readonly<{
  label?: string,
  readonly?: boolean;
  type?: FieldSetInputType.Text | FieldSetInputType.Password;
  rows?: number;
  maxRows?: number;
  value: string;
  errorCheckPattern?: (value: string) => boolean;
  hintCheckPattern?: (value: string) => boolean;
  onChange: ChangeText;
  onBlur?: () => void;
  inputMaskProps?: InputMaskProps;
  placeholder?: string;
}>;

type Props = Readonly<{
  input: FieldSetInputText;
  isError?: boolean;
  isHint?: boolean;
  disableAutocomplete?: boolean;
  onIsError?: (isError: boolean) => void;
  onIsHint?: (isHint: boolean) => void;
}>;

export const useStylesInputBox = makeStyles(() =>
  createStyles<any, { fontFamily: FontName }>({
    input: {
      paddingTop: '0px',
      paddingBottom: '0px',
      lineHeight: '38px',
      backgroundColor: '#fff',
    },
    singleLine: {
      height: '38px',
    },
    error: {
      border: 'solid 3px #EB5757',
    },
    hint: {
      border: 'solid 3px rgb(97, 192, 255)',
    },
    errorLight: {
      border: 'solid 1px #EB5757',
    },
  })
);

const FieldSetText: React.FC<Props> = (props: Props) => {
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [isTouched, setIsTouched] = React.useState<boolean>(false);
  const { font } = useCurrentFont();
  const classes = useStylesInputBox({ fontFamily: font });
  const {
    input: { rows = 1, maxRows, label = undefined },
  } = props;
  const multiLine = rows > 1;

  const errorCheckPatternCallback = useCallback(
    (value: string) => {
      return props.input.errorCheckPattern?.(value) ?? false;
    },
    [props.input]
  );

  const checkOnValid = useCallback(
    (value: string) => {
      const shouldShowError =
        !isFocused &&
        isTouched &&
        value !== '' &&
        (errorCheckPatternCallback?.(value) || !!props.isError);

      setIsError(shouldShowError);
    },
    [errorCheckPatternCallback, props.isError, isFocused, isTouched]
  );

  const hintCheckPatternCallback = useCallback(
    (value: string) => {
      return props.input.hintCheckPattern?.(value) ?? false;
    },
    [props.input]
  );

  const isHint = useMemo(() => {
    return hintCheckPatternCallback?.(props.input.value) || !!props.isHint;
  }, [hintCheckPatternCallback, props.input.value, props.isHint]);

  useEffect(() => {
    props.onIsError?.(isError);
  }, [isError, props]);

  useEffect(() => {
    props.onIsHint?.(isHint);
  }, [isHint, props]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    checkOnValid(props.input.value);
  }, [checkOnValid, props.input.value]);

  const handleTextValueChanged = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value || '';

    props.input.onChange(value);
    setIsTouched(true);
    checkOnValid(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    checkOnValid(props.input.value);

    if (props.input?.onBlur) {
      props.input.onBlur();
    }
  };

  const autoCompleteMode = useCallback((): string => {
    if (props.disableAutocomplete) {
      if (props.input.type === FieldSetInputType.Password) {
        return 'new-password';
      }
      return 'off';
    } else {
      if (props.input.type === FieldSetInputType.Password) {
        return 'current-password';
      }
      return 'on';
    }
  }, [props.disableAutocomplete, props.input.type]);

  return (
    <>
      {props.input.inputMaskProps && (
        <InputMask
          {...props.input.inputMaskProps}
          value={props.input.value}
          onChange={handleTextValueChanged}
          onBlur={handleBlur}
          onFocus={handleFocus}
        >
          {/*
          // @ts-ignore */}
          {() => (
            <TextField
              fullWidth
              multiline={multiLine}
              type={props.input.type}
              variant="outlined"
              rows={rows}
              maxRows={maxRows}
              InputProps={{
                className: cn(classes.input, {
                  [classes.singleLine]: !multiLine,
                  [classes.error]:
                    ((props.isError && props.input.value) || isError) &&
                    !isFocused &&
                    isTouched,
                  [classes.errorLight]: props.isError && !props.input.value,
                }),
                readOnly: props.input.readonly,
              }}
              autoComplete={autoCompleteMode()}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          )}
        </InputMask>
      )}
      {!props.input.inputMaskProps && (
        <TextField
          fullWidth
          label={label}
          multiline={multiLine}
          type={props.input.type}
          variant="outlined"
          rows={rows}
          maxRows={maxRows}
          value={props.input.value}
          onChange={handleTextValueChanged}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={props.input?.placeholder}
          InputProps={{
            className: cn(classes.input, {
              [classes.singleLine]: !multiLine,
              [classes.error]:
                ((props.isError && props.input.value) || isError) &&
                !isFocused &&
                isTouched,
              [classes.hint]: (props.isHint && props.input.value) || isHint,
              [classes.errorLight]: props.isError && !props.input.value,
            }),
            readOnly: props.input.readonly,
          }}
          autoComplete={autoCompleteMode()}
        />
      )}
    </>
  );
};

export default FieldSetText;
