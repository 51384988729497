import styled from "@emotion/styled";
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NewReleasesSharpIcon from '@mui/icons-material/NewReleasesSharp';
import { Button, CircularProgress, Menu, MenuItem, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { v4 } from "uuid";
import LoadingComponent from "../../../../Components/Loader";
import { useCurrentFont } from "../../../../Configuration/Styles/Fonts";
import { useEditUndistordConfig } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useEditUndistordConfig";
import { useRemoveCalibrationJob } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useRemoveCalibrationJob";
import { useRemoveUndistordConfig } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useRemoveUndistordConfig";
import { useCalibrationStateList } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCalibrationStateList";
import { useUndistordConfig } from "../../../../Data/NapiCameraHandlerV2/hooks/useUndistordConfig";
import LOCALIZATION from "../../../../Localization";
interface IProps {
    active?: boolean,
    uuid?: string,
    name: string,
    onSelect: (uuid?: string) => void,
    onEditRequest?: () => void,
}
const Line = styled.hr`
 height: 1px; border: 0;
    background-color: #C2CFE0;
 `

const Led = styled.div`
    border-radius: 100%;
    width: 16px;
    height: 16px;min-width: 16px;
    background-color: ${props => (props["aria-selected"] ? '#5DCA8A' : '#C2CFE0')};
`

const useDublicate = (props: { old_uuid: string, old_name: string }) => {
    const { old_uuid, old_name } = props;
    const req = useMemo(() => (old_uuid ? { uuid: old_uuid } : undefined), [old_uuid]);
    const [uuid, setUUID] = useState<string>(v4())
    const { data: config, fetchData } = useUndistordConfig({ req })
    useMemo(() => config?.getUndistordConfig.config, [config])
    const { mutateAsync: editConfig } = useEditUndistordConfig({ uuid })
    const dublicateItem = async () => {
        const data = await fetchData();
        await editConfig({ uuid, patch: data?.getUndistordConfig.config, name: `Copy ${old_name}` });
        setUUID(v4());
    }
    const mutation = useMutation(dublicateItem);
    return { ...mutation }
}

const StatusIndicator = (props: { uuid?: string }) => {
    const { uuid } = props;
    const { data: calibrationStateList } = useCalibrationStateList();
    const { formatMessage } = useIntl();
    const calculatingStatus = useMemo(() => calibrationStateList?.getCalibrationStateList.find(v => v.uuid === uuid)?.status, [calibrationStateList?.getCalibrationStateList, uuid])

    switch (calculatingStatus) {
        case 'not_spawned':
            return <></>
        case 'pending':
            return <>
                <Tooltip title={formatMessage({ id: LOCALIZATION.calculating_undistord_error })}>
                    <CircularProgress size={16} />
                </Tooltip>
            </>
        case 'rejected':
            return <Tooltip title={formatMessage({ id: LOCALIZATION.calculating_undistord })}>
                <ErrorSharpIcon style={{ color: "red" }}></ErrorSharpIcon>
            </Tooltip>

        case 'resolved':
            return <Tooltip title={formatMessage({ id: LOCALIZATION.undistord_resolved })}>
                <NewReleasesSharpIcon style={{ color: "#5DCA8A" }}></NewReleasesSharpIcon>
            </Tooltip>
        default:
            return <></>

    }
}

export const UndistordConfigItem = (props: IProps) => {
    const { name, uuid, active, onSelect, onEditRequest } = props;
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = (event?: {}, reason?: "backdropClick" | "escapeKeyDown"): void => {
        if (event) {
            const e = event as SyntheticEvent
            if (e.stopPropagation) {
                e.stopPropagation();
            }
        }
        setAnchorEl(null);
    };
    const handleOpenMenu = (event: SyntheticEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleEditClick: React.MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation()
        onEditRequest?.()
        handleClose();
    }


    const { isLoading: removingConfig, mutateAsync: removeConfig } = useRemoveUndistordConfig()

    const { mutateAsync: dublicateItem, isLoading: isDublicating } = useDublicate({ old_uuid: uuid ?? '', old_name: name })

    const removeItem: React.MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation()
        if (!uuid) {
            return;
        }
        if (!window.confirm(intl.formatMessage({ id: LOCALIZATION.confirm_remove }))) {
            return;
        }
        removeConfig({ uuid })
        handleClose();

    }
    const { data: calibrationStateList } = useCalibrationStateList();
    const { mutateAsync: removeCalibrationJob, isLoading: removingJob, isError: isErrorOnRemoving } = useRemoveCalibrationJob()

    useEffect(() => {
        if (!isErrorOnRemoving) {
            return
        }
        alert(intl.formatMessage({ id: LOCALIZATION.removing_err }))
    }, [intl, isErrorOnRemoving])

    const loadingLabel = useMemo(() => {
        if (removingConfig) {
            return intl.formatMessage({ id: LOCALIZATION.removing_data })
        }
        if (isDublicating) {
            return intl.formatMessage({ id: LOCALIZATION.saving_changes })
        }
    }, [intl, isDublicating, removingConfig])

    const calculatingStatus = useMemo(() => calibrationStateList?.getCalibrationStateList.find(v => v.uuid === uuid)?.status, [calibrationStateList?.getCalibrationStateList, uuid])

    const cancelJob = useCallback((e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        e.stopPropagation()
        uuid && removeCalibrationJob({ uuid })
    }, [removeCalibrationJob, uuid])

    const onCancelJobCallback = useMemo(() => {
        if (calculatingStatus === "pending") {
            return cancelJob
        }
    }, [calculatingStatus, cancelJob])



    const { font } = useCurrentFont()
    const Text = styled.span`
    color: var(--AdBlack, #000);
    /* c_body_P */
    font-family:  ${font};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
`


    return <>
        <Box>        <LoadingComponent ugly isLoading={removingConfig || isDublicating || removingJob} label={loadingLabel}>
            <Box sx={{
                "&:hover": {
                    backgroundColor: "#c2cfe069"
                },
                paddingLeft: `10px`,
                paddingRight: `10px`,
            }} onClick={() => onSelect(uuid)} display={'flex'} alignItems={'center'} height={56}>
                <Led aria-selected={active}></Led>
                <Text style={{ marginRight: "1rem" }}>  {name}  </Text>
                <StatusIndicator uuid={uuid} />
                {Boolean(uuid) &&
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpenMenu}
                        sx={{
                            minWidth: 'auto',
                            cursor: 'pointer',
                            marginLeft: 'auto',
                        }}
                    >
                        <MoreVertIcon />
                    </Button>
                }

                <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={handleEditClick}
                >
                        {intl.formatMessage({ id: LOCALIZATION.edit })}
                </MenuItem>

                    <MenuItem
                        onClick={removeItem}
                    >
                        {intl.formatMessage({ id: LOCALIZATION.remove })}
                    </MenuItem>


                    {onCancelJobCallback && <MenuItem
                        onClick={onCancelJobCallback}
                    >
                        {intl.formatMessage({ id: LOCALIZATION.cancel_сalculation })}
                    </MenuItem>}


                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation(); dublicateItem();
                            handleClose();
                        }}
                    >
                        {intl.formatMessage({ id: LOCALIZATION.dublicate })}
                    </MenuItem>

                </Menu>

            </Box>
            <Line />

        </LoadingComponent>
        </Box>


    </>
}