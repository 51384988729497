// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import MUIButton from '@mui/material/Button';
import React from 'react';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';

import { SX as MainButtonSX } from '../MainButton';


export type Props = Readonly<{
  sx?: any;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  onClick: () => void;
  children: any;
}>;


const SX = {
  width: 'auto',
  minWidth: '150px',
  padding: 0,
};


const Button: React.FC<Props> = (props: Props) => {
  // const cls = useStyles();
  // const classes = useStylesMainButton();
  const { sx = {} } = props;
  const { font } = useCurrentFont()
  return (
    <MUIButton
      variant= { props.variant ?? "contained" }
      sx={{ ...SX, ...MainButtonSX(font).main, ...MainButtonSX(font).plain, ...sx }}
      // className={ clsx(cls.rootButton, classes.main, classes.plain, props.className) }
      disabled={ !!props.disabled }
      onClick={(ev) => {
        ev.stopPropagation();
        props.onClick();
      }}
    >
      { props.children }
    </MUIButton>
  );
};


export default Button;
