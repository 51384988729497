import { BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

interface ICrop {
  "dest-x": number;
  "dest-y": number;
  "src-x": number;
  "src-y": number;
  width: number;
  height: number;
}

export default class CropResolver {
  private _cropLeft$: BehaviorSubject<number>;
  private _cropTop$: BehaviorSubject<number>;
  private _cropRight$: BehaviorSubject<number>;
  private _cropBottom$: BehaviorSubject<number>;
  private _outputSize$: BehaviorSubject<{ width: number; height: number }>;
  private _destX$: BehaviorSubject<number>;
  private _destY$: BehaviorSubject<number>;

  constructor(
    private originalSizes: [number, number],
    private initialCrop: ICrop
  ) {
    this._cropBottom$ = new BehaviorSubject<number>(
      this.initialCrop.width < 0 ? Math.abs(this.initialCrop.width) : 0
    );
    this._cropRight$ = new BehaviorSubject<number>(
      this.initialCrop.width < 0 ? Math.abs(this.initialCrop.width) : 0
    );
    this._cropLeft$ = new BehaviorSubject<number>(this.initialCrop["src-x"]);
    this._cropTop$ = new BehaviorSubject<number>(this.initialCrop["src-y"]);
    this._outputSize$ = new BehaviorSubject<{ width: number; height: number }>(
      this.calculateOutputSize()
    );
    this._destX$ = new BehaviorSubject<number>(this.initialCrop["dest-x"]);
    this._destY$ = new BehaviorSubject<number>(this.initialCrop["dest-y"]);

    combineLatest([
      this._cropLeft$,
      this._cropTop$,
      this._cropRight$,
      this._cropBottom$,
    ])
      .pipe(map(() => this.calculateOutputSize()))
      .subscribe((size) => this._outputSize$.next(size));
  }

  /**
   * Getters for BehaviourSubjects to allow subscriptions.
   */
  get cropLeft$() {
    return this._cropLeft$.asObservable();
  }

  get cropTop$() {
    return this._cropTop$.asObservable();
  }

  get cropRight$() {
    return this._cropRight$.asObservable();
  }

  get cropBottom$() {
    return this._cropBottom$.asObservable();
  }

  get outputSize$() {
    return this._outputSize$.asObservable();
  }

  get destX$() {
    return this._destX$.asObservable();
  }

  get destY$() {
    return this._destY$.asObservable();
  }

  /**
   * Getters for current values.
   */
  get cropLeft(): number {
    return this._cropLeft$.getValue();
  }

  get cropTop(): number {
    return this._cropTop$.getValue();
  }

  get cropRight(): number {
    return this._cropRight$.getValue();
  }

  get cropBottom(): number {
    return this._cropBottom$.getValue();
  }

  get outputSize(): { width: number; height: number } {
    return this._outputSize$.getValue();
  }

  get destX(): number {
    return this._destX$.getValue();
  }

  get destY(): number {
    return this._destY$.getValue();
  }

  /**
   * Methods to update the crop values.
   */
  setCropLeft(value: number) {
    this._cropLeft$.next(value);
  }

  setCropTop(value: number) {
    this._cropTop$.next(value);
  }

  setCropRight(value: number) {
    this._cropRight$.next(value);
  }

  setCropBottom(value: number) {
    this._cropBottom$.next(value);
  }

  setDestX(value: number) {
    this._destX$.next(value);
  }

  setDestY(value: number) {
    this._destY$.next(value);
  }

  /**
   * Method to calculate the output size based on crop values.
   */
  private calculateOutputSize(): { width: number; height: number } {
    const cropLeft = this._cropLeft$.getValue();
    const cropTop = this._cropTop$.getValue();
    const cropRight = this._cropRight$.getValue();
    const cropBottom = this._cropBottom$.getValue();

    const width = this.originalSizes[0] - cropLeft - cropRight;
    const height = this.originalSizes[1] - cropTop - cropBottom;

    return { width, height };
  }
}
