// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCurrentFont } from '../../../../../Configuration/Styles/Fonts';

import { Sizes } from '../../../../../Configuration/Styles/Sizes';
import { MetricsChartSourceInterface } from '../../../../../Data/Camera/Metrics/Types';
import LOCALIZATION from '../../../../../Localization';
import ModalCard from '../../../../_Layout/Cards/Modal/Card';
import ModalCardContent from '../../../../_Layout/Cards/Modal/Content';
import InputOutputPeriodForm from '../../InputOutputPeriodForm';
import InputOutputTimeForm from '../../InputOutputTimeForm';
import { useMetricsChartConfigurationForm } from '../Hooks';
import CardHeader from './CardHeader';
import MetricsCollection from './MetrixCollection';
import useStyles from './useStyles';


type Props = Readonly<{
  source: MetricsChartSourceInterface;
  onChanged: (source: MetricsChartSourceInterface) => void;
  onClose: () => void;
}>;


const MetricsChartConfigurator: React.FC<Props> = React.forwardRef((props: Props, ref: any) => {
  const intl = useIntl();
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const handleSubmit = (source: MetricsChartSourceInterface) => {
    props.onChanged(source)
    props.onClose()
  }

  const {
    onChangeMetricTypeEnabled,
    onChangeMetricOutputPeriod,
    onChangeMetricOutputTime,
    onSubmit,
    form
  } = useMetricsChartConfigurationForm(handleSubmit, props.source)

  const wrappedForm = (form: JSX.Element, bordered: boolean) => (
    <Grid container spacing={ 0 }>
      <Grid item xs={ 12 } className={ bordered ? clsx(classes.rowSelect, classes.bordered) : classes.rowSelect }>
        { form }
      </Grid>
    </Grid>
  );

  return (
    <ModalCard
      className={ classes.root }
      elevation={ Sizes.elevation }
    >
      <CardHeader onApply={ onSubmit } onCancel={ props.onClose } />
      <ModalCardContent>
        <Grid container direction="column">
          <Grid item xs={ 12 }>
            <MetricsCollection
              metrics={ form.metrics } selected={ form.selected }
              onChangeMetricTypeEnabled={ onChangeMetricTypeEnabled }
            />
          </Grid>
          <Grid item xs={ 12 }>
            {
              wrappedForm(
                <InputOutputPeriodForm
                  name={ intl.formatMessage({ id: LOCALIZATION.output_period }) }
                  period={ form.outputPeriod }
                  onChanged={ onChangeMetricOutputPeriod }
                />,
                true,
              )
            }
          </Grid>
          <Grid item xs={ 12 }>
            {
              wrappedForm(
                <InputOutputTimeForm
                  name={ intl.formatMessage({ id: LOCALIZATION.output_time }) }
                  time={ form.outputTime }
                  onChanged={ onChangeMetricOutputTime }
                />,
                false,
              )
            }
          </Grid>
        </Grid>
      </ModalCardContent>
    </ModalCard>
  )
});


export default MetricsChartConfigurator;
