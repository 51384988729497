import { useCallback, useEffect, useMemo, useState } from "react";
import {
  filter,
  from,
  fromEvent,
  lastValueFrom,
  map,
  switchMap,
  take,
} from "rxjs";
import { useAsync } from "./useAsync";

/**
 * Custom hook to select files with a specific file type.
 * @param {string} accept - The file type to accept (default: "image/jpeg").
 */
export const useFilesSelector = ({
  accept = "image/jpeg",
  multiply = true,
}: {
  accept?: string;
  multiply?: boolean;
}) => {
  // Step 1: Set up state variables
  const [preocessedFiles, setFiles] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Step 2: Create file input element
  const input = useMemo(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = accept;
    input.multiple = multiply;
    return input;
  }, [accept, multiply]);

  // Step 3: Clean up file input element on unmount
  useEffect(() => {
    return () => input.remove();
  }, [input]);

  // Step 4: Create change event observable
  const change$ = useMemo(
    () =>
      fromEvent(input, "change").pipe(
        map(() => {
          return input.files;
        }),
        filter(Boolean),
        switchMap((fileList) => {
          const base63Promises: Promise<string>[] = [];
          for (const file of fileList) {
            const reader = new FileReader();
            const base64 = new Promise<string>((resolve, reject) => {
              reader.onload = () => {
                const base64String = reader.result as string;
                resolve(base64String);
              };
              reader.onerror = () => reject();
            });
            base63Promises.push(base64);
            reader.readAsDataURL(file);
          }
          return from(
            Promise.allSettled(base63Promises).then((res) => {
              //@ts-ignore
              return (
                res
                  //@ts-ignore
                  .filter((v) => !!v?.value)
                  //@ts-ignore
                  .map((v) => v.value as string)
              );
            })
          );
        })
      ),
    [input]
  );
  const change = useAsync({ observable: change$ });

  // Step 5: Get file list from change event
  const fileList = useMemo(() => {
    setIsLoading(true);
    return input.files;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  // Step 6: Process selected files
  useEffect(() => {
    if (!fileList) {
      return;
    }
    const base63Promises: Promise<string>[] = [];
    for (const file of fileList) {
      const reader = new FileReader();
      const base64 = new Promise<string>((resolve, reject) => {
        reader.onload = () => {
          const base64String = reader.result as string;
          resolve(base64String);
        };
        reader.onerror = () => reject();
      });
      base63Promises.push(base64);
      reader.readAsDataURL(file);
    }
    Promise.allSettled(base63Promises).then((res) => {
      //@ts-ignore
      setFiles(res.filter((v) => !!v?.value).map((v) => v.value));
    });
  }, [fileList]);

  // Step 7: Reset loading state when files are processed
  useEffect(() => {
    setIsLoading(false);
  }, [preocessedFiles]);

  // Step 8: Function to open file selector modal
  const openModal = useCallback(() => {
    input.click();
    return lastValueFrom(change$.pipe(take(1)));
  }, [change$, input]);

  // Step 9: Return the necessary values
  return { files: preocessedFiles, openModal, isLoading, change };
};
