import { gql, GraphQLClient } from "graphql-request";
import { useMutation, useQueryClient, } from 'react-query';
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";

const mutationQl = gql`
  mutation GenerateConfig($uuid: ID!) {
    generateConfig(uuid: $uuid)
  }
`;

interface IRequest {
  uuid?: string;
}

type Response = { writeUndistordConfig: boolean };

interface IGenerateUndistordConfigProps {}

export const useRequestGenerateUndistordConfig = (
  props: IGenerateUndistordConfigProps
) => {
  const queryClient = useQueryClient();
  const client = useCameraHandler() ?? new GraphQLClient("");
  const request = (patch: IRequest) =>
    client.request<Response>(mutationQl, { ...patch });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = ({ uuid }) => {
    mutation.mutate(props);
    queryClient.invalidateQueries(uuid);
  };

  const mutateAsync: typeof mutation.mutateAsync = async ({ uuid }) => {
    const res = await mutation.mutateAsync(props);
    queryClient.invalidateQueries(uuid);
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};