// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import moment from 'moment';
import React, { Key } from 'react';
import { WrappedComponentProps } from 'react-intl';

import Archive, { FileType } from '../../Data/VideoArchive';

import { Box, Button, Checkbox, FormControlLabel, Grid, Link } from '@mui/material';
import CameraStreamPreview from '../../Components/VideoPlayerAndImagePreview/CameraStreamPreview';
import { useCameraSettings } from '../../Data/Camera/HWCameraSettings/hook/Hooks';
import LOCALIZATION from '../../Localization';
import { useAppConfig } from '../../Localization/AppContextProvider/helpers';
import { intl } from '../../Localization/LocalizationProvider';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as DownloadVideoIcon } from './download_video_btn.svg';

import dayjs from 'dayjs';
import MenuDropdownButton from '../../Components/_BaseUI/MenuDropdownButton';
import { Colors } from '../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../Configuration/Styles/Fonts';
import { Sizes } from '../../Configuration/Styles/Sizes';
import { MAIN_BACKEND_HOST_URL } from '../../constants';
import { formatBytes } from '../../Tools/b2Mb';
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)
require('dayjs/locale/ru')

export type Props = Readonly<{
  fileType: FileType;
  id: Key;
  checked: boolean;
  hideDate: boolean;
  item: Archive;
  onSelect: (startAt: string, item?: Archive) => void;
  onEdit: (startAt: string, item?: Archive) => void;
  onSendToServer: (startAt: string, item: Archive) => void;
  onDelete: (startAt: string, item?: Archive) => void;
} & WrappedComponentProps>;


const VideoArchiveItem: React.FC<Props> = (props: Props) => {
  const cameraSettings = useCameraSettings(true);
  const dateStart = moment(props.item.meta.event.startAt);
  const dateEnd = moment(props.item.meta.event.endAt);
  const { localization: { locale } } = useAppConfig();
  const { font } = useCurrentFont()
  dateStart.locale(locale);
  dateEnd.locale(locale);
  dayjs.locale(locale);
  const handleSelect = () => {
    props.onSelect(props.item.meta.event.startAt, props.item);
  };
  const handleEdit = () => {
    props.onEdit(props.item.meta.event.startAt, props.item);
  };
  const handleSendToServer = () => {
    props.onSendToServer(props.item.meta.event.startAt, props.item);
  };
  const handleDelete = () => {
    props.onDelete(props.item.meta.event.startAt, props.item);
  };
  const imageUrl = () => (
    cameraSettings?.urls?.archiveImageTemplate?.replace("{archive_image_path}", props.item.screenshot)
  )

  const videoUrl = () => (
    cameraSettings?.urls?.archiveVideoPlayerTemplate?.replace("{video_dir}", props.item.video)
  )

  const downloadVideoSaveFileName: string = (() => {
    const start = moment(props.item.meta.event.startAt);
    let name = `${ props.item.type }_${ start.format('YYYY-MM-DD_hh-mm') }_${ props.item.meta.event.name }`
    let name2 = name.replace(/[^\p{L}0-9.-]/giu, '_')
    return name2
  })()

  const downloadVideoUrl = () => (
    `${ MAIN_BACKEND_HOST_URL }${ cameraSettings?.urls?.zipArchiveFileTemplate?.replace("{archive_file_path}", props.item.video) }?zip_file_name=${ downloadVideoSaveFileName+'.zip' }&target_folder=${ downloadVideoSaveFileName }`
  )

  const startAt = dayjs(props.item.meta.event.startAt);
  const endAt = dayjs(props.item.meta.event.endAt);
  const now = dayjs()

  const videoDuration: string = (() => {
    const diffMinutes = endAt.diff(startAt, 'minutes');
    return `${diffMinutes} ${intl().formatMessage({ id: LOCALIZATION.minutes })}`
  })()

  const isLiveEvent: boolean = (() => {
    if (props.item.meta.autogenerated) {
      return now.isAfter(startAt) && (now.diff(endAt, 'minutes') < 2)
    }
    return now.isAfter(startAt) && now.isBefore(endAt)
  })()

  return (
    <Grid item key={ props.id } {...{ xs: 12, sm: 6, md: 6, lg: 4 }}>
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        } }
      >
        <MenuDropdownButton<Archive>
          item={ props.item as Archive }
          menuButtonIcon= { MenuIcon }
          onEdit={ handleEdit }
          customActions={[{
            title: props.intl.formatMessage({ id: LOCALIZATION.send_to_sync_server }),
            icon: CloudUploadIcon,
            onMenuClick: handleSendToServer
          }]}
          onDelete={ handleDelete }
        />

        <FormControlLabel
          sx={{
            ".MuiFormControlLabel-label": {
              fontFamily: font,
              fontSize: Sizes.title,
              fontWeight: Sizes.boldWeight,
              color: Colors.headingMain,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }
          }}
          control={
            <Checkbox
              color="primary"
              checked={ props.checked }
              onClick={ handleSelect }
            />
          }
          label={ (props.item.meta.event.name === 'Unknown') ? props.intl.formatMessage({ id: LOCALIZATION.unknown }) : props.item.meta.event.name }
        />

      </Box>
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        } }
      >
        <CameraStreamPreview
                      fileType={ props.fileType }
                      previewImageUrl={ imageUrl() }
                      videoUrl={ videoUrl() }
                    />
      </Box>
      <Box
        sx={ {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        } }
      >
        <Box
          key={ 1 }
          sx={{
            fontFamily: font,
            fontSize: Sizes.title,
            fontWeight: Sizes.regularWeight,
            color: Colors.black,
          }}
        >
          {/* { ((props.hideDate) ? "" : `${ dateStart.format('dd l').toUpperCase() }, `) + `${ dateStart.format('LT') } - ${ dateEnd.format('LT') } (${ videoDuration })` } */}
          { ((isLiveEvent) ? "🔴 " :  "") + ((props.hideDate) ? "" : `${ dateStart.format('dd l').toUpperCase() }, `) + `${ dateStart.format('LT') }, ${ videoDuration }` }
        </Box>
        <Box
             key={ 2 }
          sx={{
              minWidth: 5,
            flexGrow: 1
            }}/>

        <Button
            download={ downloadVideoSaveFileName }
            href={ downloadVideoUrl() }
            component={Link}
            key={ "download" }
            sx={{
              width: 'initial',
              minWidth: 'initial',
              padding: '2px',
              border: 'none',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: Colors.menuDefaultHover,
                border: 'none',
              },
            }}
          variant="outlined"
            // disabled={ !!option.disabled }
            // onClick={ handleDownloadBtn }
          >
            <Box
              key={ 3 }
              sx={{
                fontFamily: font,
                fontSize: Sizes.heading,
                fontWeight: Sizes.regularWeight,
                color: Colors.black,
                paddingRight : '12px',
                whiteSpace: 'nowrap',
              }}
            >
              { formatBytes( props.item.size ) }
            </Box>
            <DownloadVideoIcon style={{
                paddingBottom : '2px',
              }}/>
        </Button>
      </Box>

    </Grid>
  );
};


export default VideoArchiveItem;
