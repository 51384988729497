// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import { default as React, useMemo } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { merge } from 'lodash';
import LOCALIZATION from '../../../Localization';
import FieldSet, { FieldSetInputType } from '../../_BaseUI/FieldSet';
import Spinner from '../../_BaseUI/Spinner/Spinner';
import Card from '../../_Layout/Card';
import Popup from '../../_Layout/Popup';

export type EditTitleSubtitleFields = Readonly<{
  id: string;
  title?: string;
  subtitle?: string;
}>;

const DEFAULT_VALUE: EditTitleSubtitleFields = {
  id: '',
  title: '',
  subtitle: '',
};

type Props = Readonly<{
  popupTitle?: string;
  item?: EditTitleSubtitleFields;
  // page: number;
  onSubmit: (editedItem: EditTitleSubtitleFields) => void;
  onClose: () => void;
} & WrappedComponentProps>;

const EditTitleSubtitlePopup: React.FC<Props> = (props: Props) => {
  const defaultItem = useMemo(() => merge(DEFAULT_VALUE, props.item), [props.item])
  const [item, setItem] = React.useState<EditTitleSubtitleFields>(defaultItem);
  const [isLoaderShowing, setIsLoaderShowing] = React.useState<boolean>(false);

  const isApplyDisabled = useMemo(() => !item || !item!.title?.length || JSON.stringify(defaultItem) === JSON.stringify(item), [defaultItem, item]);
  const handleSubmit = () => {
    if (!isApplyDisabled) {
      setIsLoaderShowing(true);
      props.onSubmit(item);
    }
  };

  return (
    <>
      <Popup
        isWide
        headerTitle={ props.popupTitle || props.intl.formatMessage({ id: LOCALIZATION.edit }) }
        applyTitle={ props.intl.formatMessage({ id: LOCALIZATION.save }) }
        isApplyDisabled={ (isApplyDisabled || isLoaderShowing) }
        isCloseDisabled={ isLoaderShowing }
        onApply={ handleSubmit }
        onClose={ props.onClose }
      >
        <Grid
          item
          xs={ 12 }
        >
          <Card>
            {
              (isLoaderShowing) ?
              <>
              <br /><br /><Spinner /><br /><br />
              </>
                :
                <form
                  action={ window.location.href }
                  onSubmit={ handleSubmit }
                >
                  <FieldSet
                    key={ 'title' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_name }) }
                    // helperText={ 'Team name' }
                    // tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_name_help }) }
                    input={ {
                      type: FieldSetInputType.Text,
                      value: item.title || "",
                      onChange: (title: string) => ( setItem({ ...item, title, }) ),
                    } }
                  />
                  <FieldSet
                    key={ 'subtitle' }
                    label={ props.intl.formatMessage({ id: LOCALIZATION.event_sub_title }) }
                    // tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_sub_title_help }) }
                    // helperText={ 'Team name' }
                    input={ {
                      type: FieldSetInputType.Text,
                      value: item.subtitle || "",
                      onChange: (subtitle: string) => ( setItem({ ...item, subtitle, }) ),
                    } }
                  />
                </form>
            }
          </Card>
        </Grid>
      </Popup>
    </>
  );
};


export default injectIntl(EditTitleSubtitlePopup);
