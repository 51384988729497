export enum CalibrationInputImageType {
  left = "left",
  right = "right",
}

export interface StitchingConfig {
  config?: Partial<{
    cylinder: {
      "sensor-width"?: number;
      angle?: number;
      width?: number;
      height?: number;
      rotate?: number;
      stretch?: number[];
    }[];
    crop: {
      "dest-x"?: number;
      "dest-y"?: number;
      "src-x"?: number;
      "src-y"?: number;
      width?: number;
      height?: number;
    }[];
    stitching: {
      "blend-width"?: number;
    };
  }>;
  calibrationInput?: {
    images: {
      type: CalibrationInputImageType;
      index: number;
      width: number;
      height: number;
      points: { x: number; y: number }[];
    }[];
    offsets: { index: number; value: number }[];
  };
  name: string;
  uuid?: string;
  type: "cylindral" | "perspective" | "raw";
  active?: boolean;
}
