import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useState } from "react";
import { useIntl } from "react-intl";
import MainButton from "../../../../Components/_BaseUI/MainButton";
import Card from "../../../../Components/_Layout/Card";
import Header from "../../../../Components/_Layout/Header";
import { useCameraCommonSettings } from "../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { usePromise } from "../../../../hooks/usePromise";
import { AdjustButtonIcon } from "../../../../Icons/Icons";
import LOCALIZATION from "../../../../Localization";
import { CommonSettingsTable } from "./CommonSettingsTable";
import { EditModalPrefetch } from "./EditModal.modal";

const useStyles = makeStyles((theme: Theme) => createStyles({
    editButton: {
        [theme.breakpoints.down(500)]: {
            display: "none !important"
        },
    },
    editIcon: {
        [theme.breakpoints.up(500)]: {
            display: "none  !important"
        },
    },
    buttonSx: {
        [theme.breakpoints.down(500)]: {
            minWidth: "20px !important",
        },
        [theme.breakpoints.up(500)]: {
            minWidth: "120px  !important"
        },
    }
}))



export const CommonSettingsMain = (() => {
    const [openCommonSettingsModal, setOpenCommonSettingsModal] = useState<boolean>(false);
    const intl = useIntl();
    const { fetchData, resetCache } = useCameraCommonSettings()

    const [updateCount, update] = useState<number>(0)

    const { buttonSx, editIcon, editButton } = useStyles()

    usePromise(async () => {
        await resetCache()
        await fetchData?.();
        return updateCount;
    }, [updateCount])

    return <><Grid item xs={12} sm={10}>
        <Header
            text={intl.formatMessage({ id: LOCALIZATION.page_camera_settings_common_settings_header })}
        />
    </Grid>
        <Grid item style={{
            "display": "flex",
            "padding": "0px",
            "marginLeft": "auto",
            "alignItems": "flex-end",
        }}>
            <MainButton className={buttonSx} onClicked={() => { setOpenCommonSettingsModal(!openCommonSettingsModal) }} title={
                <><Grid overflow={"hidden"} className={editButton} >{intl.formatMessage({ id: LOCALIZATION.edit })}</Grid><AdjustButtonIcon className={editIcon} /></>

            } />
        </Grid>

        <Grid item xs={12}>
            <Card>
                <CommonSettingsTable ></CommonSettingsTable>
            </Card>
        </Grid>
        {openCommonSettingsModal && <EditModalPrefetch handleSubmit={() => update(updateCount + 1)} onClose={() => { setOpenCommonSettingsModal(false) }}  ></EditModalPrefetch>}
    </>
})