import { Grid } from "@mui/material";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { PlaygroundAttendance } from "../../Components/AnaliticCharts/PlaygroundAttendance";
import LoadingComponent from "../../Components/Loader/index";
import RegularCardHeader from "../../Components/_Layout/CardHeader/Regular/index";
import MainCard from "../../Components/_Layout/Cards/Main/Card";
import PageContainer from "../../Components/_Layout/PageContainer/index";
import { DataItem, useAttendance } from "../../Data/Analytics/PlaygroupAttendance/useAttendance";
import { useDownloadBlob } from "../../hooks/useDownloadBlob";
import { useIsServer } from "../../hooks/useIsServer";
import LOCALIZATION from "../../Localization/index";

import { AttendanceType, StatisticAttendanceCombination, StatisticForm, StatisticFormProps, StatisticType } from "./StatisticForm";
import { Table } from "./Table";


const saveStateToSession = (key: string, state: any) => {
    sessionStorage.setItem(key, JSON.stringify(state));
};

const getStateFromSession = (key: string, defaultValue: any) => {
    const savedState = sessionStorage.getItem(key);
    return savedState ? JSON.parse(savedState) : defaultValue;
};



// Функция для получения таймстемпов начала и конца периода
const getPeriodTimestamps = (periodValue: string, selectedDateValue: string): { startTimestamp: number; endTimestamp: number } => {
    const startDate = moment(selectedDateValue, 'YYYY-MM-DD');
    let endDate: moment.Moment;

    switch (periodValue) {
        case '1_day':
            endDate = startDate.clone().add(1, 'days');
            break;
        case '3_days':
            endDate = startDate.clone().add(3, 'days');
            break;
        case 'week':
            endDate = startDate.clone().add(7, 'days');
            break;
        default:
            endDate = startDate.clone().add(1, 'days'); // По умолчанию 1 день
    }

    return {
        startTimestamp: startDate.valueOf(),
        endTimestamp: endDate.valueOf(),
    };
};

// Преобразование DataItem[] в нужный формат
const transformDataItems = (dataItems: DataItem[], type: StatisticType): { time: string; average: number; max: number; min: number; id: string, event?: string }[] => {
    return dataItems.map((item, index) => {
        const { key, parsedValue } = item;
        const { mean, max, min } = (() => {
            switch (type) {
                case 'people_on_field':
                    return parsedValue.people_inside_field;
                case 'people_out_field':
                    return parsedValue.people_outside_field;
                case 'total_number_of_people':
                    return parsedValue.people_total;

            }
        })()
        const { event_info } = parsedValue
        const { event_name, event_id } = event_info ?? {};
        return {
            id: `${index}`,
            time: key, // Используем 'key' в качестве времени
            average: mean, // Среднее значение
            max, // Максимальное значение
            min, // Минимальное значение
            event: event_name,
            event_id
        };
    });
};



export const AttendancePage = () => {
    const { formatMessage } = useIntl()

    const [statistics, setStatistics] = useState<StatisticType>(
        () => getStateFromSession('AttendancePagestatistics', 'people_on_field')
    );
    const [attendance, setAttendance] = useState<AttendanceType>(
        () => getStateFromSession('AttendancePageattendance', 'by_event')
    );
    const [selectedDate, setSelectedDate] = useState(
        () => getStateFromSession('AttendancePageselectedDate', moment().format('YYYY-MM-DD'))
    );

    const [period, setPeriod] = useState(
        () => getStateFromSession('AttendancePageperiod', '3_days')
    );
    const combination = useMemo(() => `op_people_on_field_${attendance}` as StatisticAttendanceCombination, [attendance, statistics]);
    const onFormChange: StatisticFormProps['onFormChange'] = useCallback((form) => {
        setStatistics(form.statistics);
        setAttendance(form.attendance);
        setSelectedDate(form.selectedDate);
        setPeriod(form.period);

        saveStateToSession('AttendancePagestatistics', form.statistics);
        saveStateToSession('AttendancePageattendance', form.attendance);
        saveStateToSession('AttendancePageselectedDate', form.selectedDate);
        saveStateToSession('AttendancePageperiod', form.period);
    }, []);

    const [gt_whenMarkedDefault, le_whenMarkedDefault] = useMemo(() => {
        return Object.values(getPeriodTimestamps(period, selectedDate))
    }, [period, selectedDate])

    const isServer = useIsServer()

    const { data: attendacneData, isLoading: attendanceLoading, generateXLSX, resetCache } = useAttendance({
        params: {
            gt_whenMarkedDefault,
            le_whenMarkedDefault,
            combination,
            isServer
        }
    })


    const { download } = useDownloadBlob()
    const downloadCallback = useCallback(() => {
        const xlsxRes = generateXLSX()
        xlsxRes && download(xlsxRes)
    }, [download, generateXLSX])

    const refreshCallback = useCallback(() => {
        resetCache()
    }, [resetCache])
    const data = useMemo(() => {
        if (!attendacneData) {
            return [];
        }
        return transformDataItems(attendacneData, statistics)
    }, [attendacneData, statistics])
    const periods = useMemo(() => {
        switch (attendance) {
            case 'by_hour': { return 60; }
            case 'by_event': { return Infinity; } case 'by_10_minutes': { return 10; }
        }
    }, [attendance])
    return <PageContainer header={formatMessage({ id: LOCALIZATION.playground_attendance })}>
        <LoadingComponent ugly isLoading={attendanceLoading}>
            <Grid
                sx={{ margin: '20px', }}
                container gap={2}
            >   {/* <MainCard></MainCard> */}
                <Grid item xs={12}>
                    <MainCard>
                        <StatisticForm
                            onDownloadClick={downloadCallback}
                            onRefreshClick={refreshCallback}
                            initialStatistics={statistics}
                            initialAttendance={attendance}
                            initialSelectedDate={selectedDate}
                            initialPeriod={period}
                            onFormChange={onFormChange} />
                    </MainCard>

                </Grid>
                <Grid item xs={12}>
                    <MainCard>
                        <RegularCardHeader
                            title={`${formatMessage({ id: LOCALIZATION[statistics] })}, ${formatMessage({ id: LOCALIZATION[attendance] })}`}
                        />
                        <PlaygroundAttendance data={data} periods={periods} />
                    </MainCard>
                </Grid>

                <Grid item xs={12}>
                    <MainCard>
                        <Table data={data} />
                    </MainCard>
                </Grid>

            </Grid>
        </LoadingComponent>
    </PageContainer>
}