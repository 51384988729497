import styled from "@emotion/styled";
import { isBoolean } from "lodash";
import { memo, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import FieldSet, { FieldSetInputType } from "../../../../../Components/_BaseUI/FieldSet";
import { useCurrentFont } from "../../../../../Configuration/Styles/Fonts";
import { useRemoveCalibrationJob } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useRemoveCalibrationJob";
import { useCalibrationConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCalibrationConfig";
import { useObjectChanged } from "../../../../../hooks/useObjectChanged";
import LOCALIZATION from "../../../../../Localization";
import { trimByPattern } from "../../../../../Tools/trimByPattern";
import { UndistordModalResults } from "./UndistordModalResults/UndistordModalResults";





interface IProps {
    uuid: string,
    onChange: (value: { type?: "fisheye" | "regular", ddata?: number[], kdata?: number[], angle?: number }) => void,
    initialValues: {
        type?: "fisheye" | "regular",
        kdata?: number[],
        ddata?: number[],
        angle?: number,
        balance?: number
    },
    hasUndistordApplyModal?: boolean,
    header?: ReactNode | boolean
}


export const UndisotrionConfigEdit = memo((props: IProps) => {
    const { onChange, initialValues, uuid, hasUndistordApplyModal, header } = props;
    const [type, setType] = useState<"fisheye" | "regular">(initialValues?.type ?? 'fisheye')
    const [kdata, setKdata] = useState(initialValues?.kdata?.join(',') ?? '')
    const [ddata, setDdata] = useState(initialValues?.ddata?.join(',') ?? '')
    const [angle, setAngle] = useState(initialValues?.angle ?? 0)
    const { data: calibratedConfig, } = useCalibrationConfig({ uuid, options: { refetchInterval: 5_000 } })
    const { font } = useCurrentFont()
    const Header = styled.span`
    color: var(--C_Dark_Text, #334D5E);
    /* c_h4 (18) */
    font-family: ${font};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.27px;
`
    const { value: calculatingStatus } = useObjectChanged(calibratedConfig?.getCalibrationState?.status)

    useEffect(() => {
        onChange({
            type,
            kdata: (new RegExp(/^(([-]?\d+([.]\d*)?[,])){8}([-]?\d+([.]\d*)?)$/)).test(kdata) ? kdata.split(',').map(Number) : undefined,
            ddata: (new RegExp(/^(([-]?\d+([.]\d*)?[,])){3}([-]?\d+([.]\d*)?)$/)).test(ddata) ? ddata.split(',').map(Number) : undefined,
            angle,
        })
    }, [angle, kdata, ddata, onChange, type])

    const [openUndisrodGeneratedModal, setOpenUndisrodGeneratedModal] = useState(false);

    useEffect(() => {
        if (calculatingStatus === "resolved" && !openUndisrodGeneratedModal) {
            setOpenUndisrodGeneratedModal(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculatingStatus])
    const { mutateAsync: removeUndistordCalibration, isError: isErrorOnRemoving } = useRemoveCalibrationJob();



    const calculationReadyCallback = useCallback((v?: {
        type?: "fisheye" | "regular" | undefined;
        kdata?: number[] | undefined;
        ddata?: number[] | undefined;
        angle?: number | undefined;
    }) => {
        const { type, kdata, ddata, angle } = v ?? {};
        type && setType(type)
        kdata && setKdata(kdata?.join(','))
        ddata && setDdata(ddata?.join(','))
        setAngle(Number(angle));
        removeUndistordCalibration({ uuid });
    }, [removeUndistordCalibration, uuid])

    const closeCalculationCallback = useCallback(() => {
        removeUndistordCalibration({ uuid }); setOpenUndisrodGeneratedModal(false)
    }, [removeUndistordCalibration, uuid])


    const { formatMessage } = useIntl();
    const typeOptions = useMemo(() => [{ id: 'fisheye', name: 'fisheye' }, { id: 'regular', name: 'regular' }], [])

    useEffect(() => {
        if (!isErrorOnRemoving) {
            return
        }
        alert(formatMessage({ id: LOCALIZATION.removing_err }))
    }, [formatMessage, isErrorOnRemoving])
    return < >
        <Header>
            {isBoolean(header) && header && formatMessage({ id: LOCALIZATION.active_coefficients })}
            {!isBoolean(header) && header}
        </Header>
        <FieldSet
            inputSize
            labelSize={3}
            tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.type_desc })} `}
            hideSeparator
            label={formatMessage({ id: LOCALIZATION.type })}
            input={{ type: FieldSetInputType.Select, options: typeOptions, value: type, onChange: (v: string) => setType(v as "fisheye" | "regular",) }}
        ></FieldSet>
        <FieldSet
            inputSize
            labelSize={3}
            onHintTooltipText={`${formatMessage({ id: LOCALIZATION.prop_will_not_saved_with_wrong_value })}. ${formatMessage({ id: LOCALIZATION.format }).capitalizeFirstLetter()} : 0,0,0,0,0,0,0,0,0 `}
            tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.kdata_desc })} `}
            hideSeparator
            label={"KData"}
            input={{
                type: FieldSetInputType.Text, placeholder: " 0,0,0,0,0,0,0,0,0", value: kdata, onChange: (v: SetStateAction<string>) => setKdata(trimByPattern(String(v).replace(/[^\d.,-]/g, ''), /^(([-]?\d+([.]\d*)?[,])){8}([-]?\d+([.]\d*)?)/)), hintCheckPattern: (value: string) => {
                    return !!value.length && !(new RegExp(/^(([-]?\d+([.]\d*)?[,])){8}([-]?\d+([.]\d*)?)/)).test(value);
                },
            }}
        ></FieldSet>
        <FieldSet
            inputSize
            labelSize={3}
            onHintTooltipText={`${formatMessage({ id: LOCALIZATION.prop_will_not_saved_with_wrong_value })}. ${formatMessage({ id: LOCALIZATION.format }).capitalizeFirstLetter()} : 0,0,0,0 `}

            tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.ddata_desc })} `}
            hideSeparator
            label={"DData"}
            input={{
                type: FieldSetInputType.Text, placeholder: " 0,0,0,0", value: ddata, onChange: (v: SetStateAction<string>) => setDdata(trimByPattern((String(v).replace(/[^\d.,-]/g, '')), /^(([-]?\d+([.]\d*)?[,])){3}([-]?\d+([.]\d*)?)/)), hintCheckPattern: (value: string) => {
                    return !!value.length && !(new RegExp(/^(([-]?\d+([.]\d*)?[,])){3}([-]?\d+([.]\d*)?)/)).test(value);
                },
            }}
        ></FieldSet>
        <FieldSet
            inputSize
            labelSize={3}
            tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.angle_desc })} `}
            hideSeparator
            label={`${formatMessage({ id: LOCALIZATION.angle })}, °`}
            input={{ type: FieldSetInputType.Float, value: angle, step: 0.1, onChange: (v: any) => setAngle(Number(v)) }}
        ></FieldSet>
        {hasUndistordApplyModal && openUndisrodGeneratedModal && <UndistordModalResults uuid={uuid} onClose={closeCalculationCallback} handleSubmit={calculationReadyCallback} />}
    </>
})
