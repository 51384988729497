import { useEffect, useRef, useState } from "react";

function sortObjectKeys<T extends { [ley in string]: any }>(obj: T): T {
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj = {} as T;
  for (const key of sortedKeys) {
    // @ts-ignore
    sortedObj[key] = obj[key];
  }
  return sortedObj;
}

export function useSortedObject<T extends { [ley in string]: any }>(obj: T): T {
  const [sortedObj, setSortedObj] = useState<T>(() => sortObjectKeys(obj));
  const prevObjRef = useRef<string>(JSON.stringify(sortedObj));

  useEffect(() => {
    const newSortedObj = sortObjectKeys(obj);
    const newSortedObjString = JSON.stringify(newSortedObj);

    if (newSortedObjString !== prevObjRef.current) {
      prevObjRef.current = newSortedObjString;
      setSortedObj(newSortedObj);
    }
  }, [obj]);

  return sortedObj;
}
