import { gql } from 'graphql-request';
import { debounce, } from 'lodash';
import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useCameraHandler } from "../../../../hooks/useCameraHandler";

interface IResponse {
  getCurrentUndistordConfig: {
    name: string;
    uuid: string;
    camera: number;
  }[];
}

const graphql = gql`
  query GetCurrentUndistordConfig {
    getCurrentUndistordConfig {
      name
      uuid
      camera
    }
  }
`;
interface IUseCurrentUndistordConfigs<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}
export const useCurrentUndistordConfigsCacheKey = "useCurrentUndistordConfigs";

/**
 * @description It request the data only when fetchData has been fired
 * @param props
 * @returns
 */
export const useCurrentUndistordConfigs = (
  props?: IUseCurrentUndistordConfigs<IResponse | undefined>
) => {
  const { options } = props ?? {};
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const key = [useCurrentUndistordConfigsCacheKey] as QueryKey;
  const request = () => client?.request<IResponse>(graphql);
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };
  const query = useQuery(key, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });
  const fetchData = debounce(async () => {
    return queryClient.fetchQuery(key, request); // Prefetch the query
  }, 100);

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
  };
};