// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';

import TeamCell from './TeamCell';
import { getStreamingName } from './helpers';
import Event from '../../../Data/EPG/EpgSchedule/Event';
import { isTodayEvent, isTomorrowEvent } from '../../../Data/EPG/EpgSchedule/dates';
import { RenderCellContentValue } from '../../../Components/SortedTable/SortedTableBodyRow';
import { intl } from '../../../Localization/LocalizationProvider';
import LOCALIZATION from '../../../Localization';
import { isEventEnded } from './helpers';
import weekDaysShortNameLocalizationKey from '../WeekDaysSelector/weekDaysShortNames';
import { GameRepeatType, isWeekDaysMondayToFiday, WeekDay } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import { dayjsFrom, localizedGameTypeById, localizedSportNameById, priorityColorCharById } from '../AddEvent/helpers';

const renderEventsTabCell = (item: Event, cellName: keyof Event, index: number): RenderCellContentValue => {
  try {
    switch (cellName) {
      case 'priority': {
        return ({
          content: (
            priorityColorCharById(item.priority)
          ),
        });
      }
      case 'name': {
        if (item.subTitle) {
          return ({
            content: (
              <div>
                { item.name }<br />
                <i style={{ fontSize: '90%' }}>{ item.subTitle }</i>
              </div>
            ),
          });
        }
        return ({
          content: (
            item.name
          ),
        });
      }
      case 'gameType': {
        return ({
          content: (
            <div>
              { localizedGameTypeById(item.gameType) }<br />
              <b>{ localizedSportNameById(item.sportType) }</b>
            </div>
          ),
        });
      }
      case 'homeTeamID': {
        if (item.homeTeamID === null && item.guestTeamID === null) {
          return ({
            // cellProps: {
            //   align: ('center' as any),
            // },
            content: (
              <div>—</div>
            ),
          });
        }

        return ({
          content: (
            <div>
              {
                (item.homeTeamID === null) ?
                  '—'
                  :
                  <>
                    <TeamCell teamID={ item.homeTeamID } /><br />
                  </>
              }
              {
                (item.guestTeamID === null) ?
                  '—'
                  :
                  <TeamCell teamID={ item.guestTeamID } />
              }
            </div>
          ),
        });
      }
      case 'startDate': {
        if (item.deleted) {
          return ({ content: <b>{intl().formatMessage({ id: LOCALIZATION.deleted })}</b> });
        }

        if (item.disabled) {
          return ({ content: <b>{intl().formatMessage({ id: LOCALIZATION.disabled })}</b> });
        }

        let humanText: undefined | JSX.Element;
        const repeatMark = (item.repeatType !== GameRepeatType.NoRepeat) ? `${intl().formatMessage({ id: LOCALIZATION.event_repeat })}: ` : "";

        if (isEventEnded(item)) {
          humanText = (
            <>
              <b>{repeatMark}{intl().formatMessage({ id: LOCALIZATION.ended })}</b><br />
            </>
          );
        }
        else if (isTodayEvent(item)) {
          humanText = (
            <>
              <b>{repeatMark}{intl().formatMessage({ id: LOCALIZATION.today })}</b><br />
            </>
          );
        } 
        else if (isTomorrowEvent(item)) {
          humanText = (
            <>
              <b>{repeatMark}{intl().formatMessage({ id: LOCALIZATION.tomorrow })}</b><br />
            </>
          );
        }

        const start = dayjsFrom("start", item.startDate, item.startTime, item.endDate, item.endTime)
        if (item.endDate) {// have EndDate 
            const end = dayjsFrom("endDate", item.startDate, item.startTime, item.endDate, item.endTime)

            const startEndDiffHours = end?.diff(start, 'hours', true);
            if ((item.repeatType !== GameRepeatType.NoRepeat) || (startEndDiffHours && Math.abs(startEndDiffHours) > 24)) {// show "from - to" for date
              return ({
                content: (
                  <div>
                    { humanText }
                    { `${intl().formatMessage({ id: LOCALIZATION.date_from })} ${start?.format("dd, l")?.capitalizeFirstLetter()}` }<br />
                    { `${intl().formatMessage({ id: LOCALIZATION.date_to })} ${end?.format("dd, l")?.capitalizeFirstLetter()}` }
                  </div>
                ),
              });   
            }
        }
        else {
          const end = dayjsFrom("end", item.startDate, item.startTime, item.endDate, item.endTime)

          if (item.repeatType !== GameRepeatType.NoRepeat) {// repeat event withount EndDate show only "From - Infinity"
            return ({
              content: (
                <div>
                  { humanText }
                  { `${intl().formatMessage({ id: LOCALIZATION.date_from })} ${start?.format("dd, l")?.capitalizeFirstLetter()}` }<br />
                  { `${intl().formatMessage({ id: LOCALIZATION.date_to })} ♾️` }
                </div>
              ),
            });   
          }
        }

        return ({
          content: (
            <div>
              { humanText }
              { start?.format("dd, l")?.capitalizeFirstLetter() }
            </div>
          ),
        });
      }
      case 'startTime': {
        if (item.deleted) {
          return ({ content: "" });
        }

        if (item.disabled) {
          return ({ content: "" });
        }

        const start = dayjsFrom("start", item.startDate, item.startTime, item.endDate, item.endTime)
        const end = dayjsFrom((item.endDate) ? "endDate" : "end", item.startDate, item.startTime, item.endDate, item.endTime)

        return ({
          content: (
            <div>
              { start?.format("LT") } — { end?.format("LT") }
            </div>
          ),
        });
      }
      case 'repeatDays': {
        let repeatDays = '—';

        if (item.repeatType === GameRepeatType.EveryWeek && item.repeatDays && item.repeatDays.length !== 0) {
          if (item.repeatDays.length === 7) { // all days of week
            repeatDays = intl().formatMessage({ id: LOCALIZATION.every_day })
          }
          else {
            if (isWeekDaysMondayToFiday(item.repeatDays)) {
              repeatDays = [WeekDay.Mo,WeekDay.Fr].map((day) => ( intl().formatMessage({ id:  weekDaysShortNameLocalizationKey[day] }) )).join('—');
            }
            else {
              repeatDays = item.repeatDays.map((day) => ( intl().formatMessage({ id:  weekDaysShortNameLocalizationKey[day] }) )).join(',');
            }
          }
        }

        return ({
          content: (
            <div>
              { repeatDays }
            </div>
          ),
        });
      }
      case 'streamProfileID': {
        const streamingName = (item.streamProfileID) ? getStreamingName(item.streamProfileID, '—') : '—'
        const streamOverride = (item.streamOverride?.overrideItems?.length) ? "🔧" : ""
        return ({
          content: (
            <span>
              {
                `${streamOverride}${streamingName}`
              }
            </span>
          ),
        });
      }
      default: {
        return ({
          content: (item?.[cellName] || '') as string,
        });
      }
    }
  } catch (ignore) {
    return ({
      content: (item?.[cellName] || '') as string,
    });
  }
};


export default renderEventsTabCell;
