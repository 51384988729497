// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Attributes } from 'react';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { AnalyticsSportType, ANALYTICS_SPORT_ID_ARR, localizedAnalyticsSportNameById } from '../../../Data/Analytics/AnalyticsTypes';


type Props = Readonly<{
  label?: string;
  selectedAnalyticsSportType?: AnalyticsSportType;
  showRequiredMark?: boolean;
  onSelect: (sportType: AnalyticsSportType | null | undefined) => void;
}& Attributes>;

const useStyles = makeStyles(() => ({
  outlined: {
    "&:not(.MuiInputLabel-shrink)": {
      marginTop: '-8px',
    }
  }
}));

const AnalyticsSportTypeSelect: React.FC<Props> = (props: Props) => {
  const [value, setValue] = React.useState<string | AnalyticsSportType>(props.selectedAnalyticsSportType || '');
  const [isOpen, setIsOpen] = React.useState(false);

  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const renderMenuOptions = () => (
    ANALYTICS_SPORT_ID_ARR
      .map((sportType) => (
      <MenuItem
        value={ sportType }
      >
        { localizedAnalyticsSportNameById(sportType) }
      </MenuItem>
    ))
  );

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const newValue = event.target.value as AnalyticsSportType | undefined;
    setValue(newValue || '');
    props.onSelect(newValue);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Box key={ props.key || props.label } sx = {{ minWidth: 130 }}>
        <FormControl fullWidth>
        <InputLabel
              id="select-game-label"
              classes={ classes }
              required={ props.showRequiredMark }
            >{ props.label }</InputLabel>
            <Select
            labelId="select-game-label"
            id="select-game-select"
            value={ value }
            label={ props.label }
            onChange={handleChange}
            sx = {{ height: '38px' }}
            open={isOpen}
            onClose={handleClose}
            onOpen={ () => { setIsOpen(true) }}
            >
            { renderMenuOptions() }
            </Select>
        </FormControl>
    </Box>
  );
};


export default AnalyticsSportTypeSelect;
