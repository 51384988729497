import styled from "@emotion/styled";
import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import FieldSet, { FieldSetInputType } from "../../../../Components/_BaseUI/FieldSet";
import { useCurrentFont } from "../../../../Configuration/Styles/Fonts";
import { RefreshIcon2 } from "../../../../Icons/Icons";
import LOCALIZATION from "../../../../Localization";
import { SliderWrapper } from "./SliderWrapper";

const Refresh = styled.button`
   background: none;
    border: 2px solid#18A0FB;
    border-radius: 6px;
    padding: 0;
    padding: 4px 5px 1px 4px;

    align-content: center;
    & :hover {
        cursor: pointer;
    };
`



interface ColorAdjustments {
    exposure_compensation: number | null;
    brightness: number | null;
    contrast: number | null;
    adjust_color: {
        r: number;
        b: number;
        g: number;
    };
}

interface IProps {
    initialValue: ColorAdjustments,
    onChange?: (value: ColorAdjustments) => void
}

export const AutoExposureCompensationForm = (props: IProps) => {
    const { font } = useCurrentFont()
    const Header = styled.h1`
        color: var(--C_Dark_Text, #334D5E);

        /* c_h4 (18) */
        font-family: ${font};
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.27px;
    `

    const Label = styled.p`
        color: #000;

        /* c_body_P */
        font-family: ${font};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    `

    const { onChange, initialValue } = props;
    const { formatMessage } = useIntl();
    const [exposure_compensation, setExposureCompensation] = useState<number>(Number(initialValue?.exposure_compensation) ?? 0)
    const [brightness, setBrightness] = useState<number>(Number(initialValue?.brightness) || 0)
    const [contrast, setContrast] = useState<number>(Number(initialValue?.contrast) || 1)
    const [r, setRed] = useState<number>(Number(initialValue?.adjust_color?.r) || 0)
    const [g, setGreen] = useState<number>(Number(initialValue?.adjust_color?.g) || 0)
    const [b, setBlue] = useState<number>(Number(initialValue?.adjust_color?.b) || 0)
    useEffect(() => {
        onChange?.({
            adjust_color: { r, g, b }, contrast, brightness, exposure_compensation
        })
    }, [b, brightness, contrast, exposure_compensation, g, onChange, r])

    return <Card sx={{
        height: "100%",
        width: "100%",
    }}>
        <Header>{formatMessage({ id: LOCALIZATION.auto_exposure_compensation })}</Header>
        <Grid container>
            <Grid xs={12} gap={1} item container>
                <Grid xs={false} display={"flex"} alignItems={"center"} >

                <FieldSet
                        inputSize={2}
                        labelSize={10}
                    hideSeparator
                    label={formatMessage({ id: LOCALIZATION.auto_exposure_compensation })}
                        input={{ type: FieldSetInputType.Float, max: 1, min: 0, step: 0.1, value: exposure_compensation, onChange: (v) => setExposureCompensation(Number(v)) }}
                ></FieldSet>
                </Grid>
                <Grid xs display={"flex"} alignItems={"center"} >
                    <Refresh onClick={() => setExposureCompensation(0)}>
                        <RefreshIcon2 />
                    </Refresh>

                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Label>Image correction for lens (adjust image colors)</Label>
            </Grid>
            <Grid xs={12} item > <SliderWrapper
                label={`${formatMessage({ id: LOCALIZATION.brightness }).capitalizeFirstLetter()}:`}
                defaultValue={0}
                value={brightness}
                min={-1}
                max={1}
                step={0.1}
                onChange={setBrightness}
            /></Grid>
            <Grid xs={12} item > <SliderWrapper
                label={`${formatMessage({ id: LOCALIZATION.contrast }).capitalizeFirstLetter()}:`}
                value={contrast}
                defaultValue={1}

                min={0}
                max={2}
                step={0.1}
                onChange={setContrast}
            /></Grid>
            <Grid xs={12} item > <SliderWrapper
                label={`${formatMessage({ id: LOCALIZATION.red }).capitalizeFirstLetter()}:`}
                value={r}
                defaultValue={0}

                min={-255}
                max={255}
                step={1}
                onChange={setRed}
            /></Grid><Grid xs={12} item > <SliderWrapper
                label={`${formatMessage({ id: LOCALIZATION.green }).capitalizeFirstLetter()}:`}
                value={g}
                defaultValue={0}
                min={-255}
                max={255}
                step={1}
                onChange={setGreen}
            /></Grid><Grid xs={12} item > <SliderWrapper
                label={`${formatMessage({ id: LOCALIZATION.blue }).capitalizeFirstLetter()}:`}
                value={b}
                min={-255}
                defaultValue={0}
                max={255}
                step={1}
                onChange={setBlue}
            /></Grid>


        </Grid>

    </Card>
}