import { gql, GraphQLClient } from "graphql-request";
import { useMutation } from "react-query";
import { useNAPIGraphQLClient } from "../../../../../hooks/useNAPIGraphQLClient";
import { StitchingConfig } from "../StitchinConfig.interface";

const mutationQl = gql`
  mutation TemporalyApplyStitchingConfig(
    $config: StoredStitchingConfigurationInput
  ) {
    temporalyApplyStitchingConfig(storedStitchingConfigurationInput: $config)
  }
`;

interface IResponse {
  temporalyApplyStitchingConfig: boolean;
}

type RequestParams = StitchingConfig["config"] | null;

export const useTemporaryApplyStitchingConfig = () => {
  const client = useNAPIGraphQLClient() ?? new GraphQLClient("");
  const request = (config: RequestParams) =>
    client.request<IResponse>(mutationQl, { config: { config } });
  const mutation = useMutation(request);
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);

    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};
