import { useCallback, useMemo } from "react";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { utils, write } from "xlsx";
import { JAVA_BACKEND_URL } from "../../../constants";
import fetchJSONData from "../../_Networking/ReactQuery/fetch";
// Interface for event information
interface EventInfo {
  event_id?: string;
  event_name?: string;
  sport?: string;
  game_type?: string;
  event_start_at?: string; // ISO date string
  event_end_at?: string; // ISO date string
  extra_info?: Record<string, any>;
}

// Interface for people statistics
interface PeopleStats {
  min: number;
  mean: number;
  max: number;
}

// Interface for parsed value field
interface ParsedValue {
  date: string; // ISO date string
  duration: number; // Duration in seconds
  event_info?: EventInfo;
  people_inside_field: PeopleStats;
  people_outside_field: PeopleStats;
  people_total: PeopleStats;
}

// Interface for each item returned by the API
interface KeyValueItem {
  key: string; // Start time of the statistics, ISO date string
  group: string; // Example: 'op_people_on_field_by_10_minutes'
  flagShared: boolean;
  title: string; // Example: 'Operator People On field'
  whenMarkedDefault: number; // Timestamp in milliseconds
  value: string; // JSON-encoded string
}

// Interface for data item after parsing the 'value' field
export interface DataItem extends KeyValueItem {
  parsedValue: ParsedValue;
}

// Определяем возможные типы для statistics и attendance
type StatisticType =
  | "total_number_of_people"
  | "people_on_field"
  | "people_out_field";
type AttendanceType = "by_hour" | "by_event" | "by_10_minutes" | "by_1_minutes";

// Типизируем комбинации строк с использованием шаблонных строк
type StatisticAttendanceCombination = `op_${StatisticType}_${AttendanceType}`;

interface UseFetchStatisticsProps<TData> {
  params: {
    gt_whenMarkedDefault: number;
    le_whenMarkedDefault: number;
    combination: StatisticAttendanceCombination; // Новое поле для комбинации
    isServer: boolean;
  };
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}

/**
 * Hook to generate an XLSX file from a DataItem[] array and return a Blob file on request
 * @param data Array of DataItem[] objects to export
 * @returns Function to generate XLSX file and return a Blob
 */
export const useExportToXLSX = (data?: DataItem[]) => {
  return useCallback(() => {
    if (!data?.length) return;

    const worksheetData = data.map((item) => ({
      Time: item.key,
      "Event ID": item.parsedValue.event_info?.event_id ?? "",
      "Event Name": item.parsedValue.event_info?.event_name ?? "",
      Sport: item.parsedValue.event_info?.sport ?? "",
      "Game Type": item.parsedValue.event_info?.game_type ?? "",
      "Event Start": item.parsedValue.event_info?.event_start_at ?? "",
      "Event End": item.parsedValue.event_info?.event_end_at ?? "",
      "People Inside (Min)": item.parsedValue.people_inside_field.min,
      "People Inside (Mean)": item.parsedValue.people_inside_field.mean,
      "People Inside (Max)": item.parsedValue.people_inside_field.max,
      "People Outside (Min)": item.parsedValue.people_outside_field.min,
      "People Outside (Mean)": item.parsedValue.people_outside_field.mean,
      "People Outside (Max)": item.parsedValue.people_outside_field.max,
      "Total People (Min)": item.parsedValue.people_total.min,
      "Total People (Mean)": item.parsedValue.people_total.mean,
      "Total People (Max)": item.parsedValue.people_total.max,
    }));

    const worksheet = utils.json_to_sheet(worksheetData);

    // Adding styling to make the table more readable
    const headerStyle = {
      font: { bold: true },
      alignment: { horizontal: "center", vertical: "center" },
    };
    const headers = Object.keys(worksheetData[0] ?? {});
    headers.forEach((header, index) => {
      const cellAddress = utils.encode_cell({ r: 0, c: index });
      worksheet[cellAddress].s = headerStyle;
    });

    worksheet["!cols"] = headers.map(() => ({ wch: 20 })); // Set column width to make it more readable

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");

    const wbout = write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }, [data]);
};

// Функция для получения статистических данных
async function fetchStatistics(params: {
  gt_whenMarkedDefault: number;
  le_whenMarkedDefault: number;
  combination: StatisticAttendanceCombination; // Новое поле для комбинации
  isServer: boolean;
}): Promise<DataItem[]> {
  // Построение URL с параметрами запроса
  const url = new URL(`${JAVA_BACKEND_URL}/kvdata`);
  url.searchParams.append("filter_group", params.combination);
  if (params.gt_whenMarkedDefault !== undefined) {
    url.searchParams.append(
      "gt_whenMarkedDefault",
      params.gt_whenMarkedDefault.toString()
    );
  }
  if (params.le_whenMarkedDefault !== undefined) {
    url.searchParams.append(
      "le_whenMarkedDefault",
      params.le_whenMarkedDefault.toString()
    );
  }

  // Выполнение запроса
  const data = await fetchJSONData<DataItem[]>(url.toString(), {
    headers: {
      "X-ALLOW-PROXY-REQUEST": `${params.isServer}`,
    },
  });

  // Парсинг поля 'value' для каждого элемента
  const result: DataItem[] = data.map((item) => {
    let parsedValue: ParsedValue;
    try {
      parsedValue = JSON.parse(item.value) as ParsedValue;
    } catch (error) {
      // Обработка ошибки парсинга
      parsedValue = {} as ParsedValue;
    }
    return {
      ...item,
      parsedValue,
    };
  });
  return result;
}

const cacheKey = "useAttendance";

export const useAttendance = (
  props: UseFetchStatisticsProps<DataItem[] | undefined>
) => {
  const { params, options } = props;
  const queryClient = useQueryClient();
  const key = useMemo(() => [cacheKey, params] as QueryKey, [params]);

  const request = useCallback(() => fetchStatistics(params), [params]);

  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };

  const query = useQuery(key, request, {
    ...options,
    staleTime: 0,
    cacheTime: 0,
  });

  const fetchData = useCallback(async () => {
    return queryClient.fetchQuery(key, request);
  }, [key, queryClient, request]);

  const generateXLSX = useExportToXLSX(query.data);

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
    generateXLSX,
  };
};
