import { gql, GraphQLClient } from "graphql-request";
import { useMutation } from "react-query";
import { v4 } from "uuid";
import { useCameraHandler } from "../../../../../hooks/useCameraHandler";

const mutationQl = gql`
  mutation CalibrationMode($sessionUUID: String) {
    calibrationMode(sessionUUID: $sessionUUID) {
      sessionUUID
      state
    }
  }
`;

export interface IResponse {}

export interface Request {
  sessionUUID: string;
}
const sessionUUID = (() => {
  const key =
    window.localStorage.getItem("useKickCalibrationModeSession") ?? v4();
  window.localStorage.setItem("useKickCalibrationModeSession", key);
  return key;
})();

export const useKickCalibrationMode = () => {
  const client = useCameraHandler() ?? new GraphQLClient("");

  const request = () => client.request<IResponse>(mutationQl, { sessionUUID });
  const mutation = useMutation(request, { retry: 10, retryDelay: 1_000 });
  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };

  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    return res;
  };

  return {
    ...mutation,
    mutate,
    mutateAsync,
    sessionUUID,
  };
};
