// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import React from 'react';

import AnyChildren from '../../../../Tools/AnyChildren';
import { MAIN_CARD_ACTIVE_SX, MAIN_CARD_SX } from './styles';


type Props = Readonly<{
  isActive?: boolean;
  children: AnyChildren;
  className?: string;
  item?: boolean;
}>;


const SX = {
  ...MAIN_CARD_SX,
  flexGrow: 1,
  overflow: 'hidden',
};

const SX_ACTIVE = {
  ...MAIN_CARD_ACTIVE_SX
};

const MainCard: React.FC<Props> = (props: Props) => {
  const { className, item } = props;
  return (
  <Grid
    sx={
      (props.isActive === true) ?
        { ...SX, ...SX_ACTIVE }
        :
        SX
    }
      item={item}
      className={className}
  >
    { props.children }
  </Grid>
  )
};


export default MainCard;
