import { gql } from "graphql-request";
import { useCallback, useEffect, useMemo } from "react";
import {
  QueryKey,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useExposedConfigurations } from "../../../hooks/useExposedConfigurations";

interface IResponse {
  undistortion: object;
}
const mutationQl = gql`
  mutation Undistortion($patch: [Object]) {
    undistortion(config: $patch)
  }
`;

interface IUseGetUndistortionConfiguration<TData> {
  /**
   * Please note that the default settings are already set in the hook, including the polling time. If necessary, you can make an override.
   */
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}

const getUndistortionConfigurationKey = "getUndistortionConfiguration";

export const useGetUndistortionConfiguration = (
  props?: IUseGetUndistortionConfiguration<IResponse | undefined>
) => {
  const { options } = props ?? {};
  const client = useExposedConfigurations();

  const request = () => client.request<IResponse>(mutationQl);

  const query = useQuery(
    [getUndistortionConfigurationKey] as QueryKey,
    request,
    {
      ...options,
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: false,
    }
  );

  return {
    ...query,
  };
};

export const useMutateUndistortionConfiguration = () => {
  const queryClient = useQueryClient();
  const client = useExposedConfigurations();

  const request = (v: string) =>
    client.request<IResponse>(mutationQl, { patch: JSON.parse(v) });

  const mutation = useMutation(request);

  const mutate: typeof mutation.mutate = (props) => {
    mutation.mutate(props);
  };
  const mutateAsync: typeof mutation.mutateAsync = async (props) => {
    const res = await mutation.mutateAsync(props);
    return res;
  };

  useEffect(() => {
    if (!mutation.data) {
      return;
    }
    queryClient.setQueriesData(
      [getUndistortionConfigurationKey],
      mutation.data
    );
  }, [mutation.data, queryClient]);
  return {
    ...mutation,
    mutate,
    mutateAsync,
  };
};

export const useUndistortionConfig = (
  props?: IUseGetUndistortionConfiguration<IResponse | undefined>
) => {
  const { data: gotData, refetch } = useGetUndistortionConfiguration(props);
  const { mutateAsync, data: mutatedData } =
    useMutateUndistortionConfiguration();
  const set = useCallback(
    async (value: string) => {
      return mutateAsync(value);
    },
    [mutateAsync]
  );
  const data = useMemo(() => {
    return gotData?.undistortion ?? mutatedData?.undistortion;
  }, [gotData, mutatedData]);
  const fetch = useCallback(
    () => refetch().then((v) => v.data?.undistortion),
    [refetch]
  );
  return {
    set,
    data,
    fetch,
  };
};
