import { isNil } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  BehaviorSubject,
  exhaustMap,
  filter,
  first,
  firstValueFrom,
  from,
  interval,
  merge,
  of,
  retry,
  Subject,
  takeUntil,
  tap,
} from "rxjs";
import { v4 } from "uuid";
import { getJavaBackend } from "../Configuration/ENV/index";
import { useCameraSettings } from "../Data/Camera/HWCameraSettings/hook/Hooks";
import { useAsync } from "./useAsync";
import { useCache } from "./useCache";
import { useCachedPanoramaOriginalSizes } from "./useCachedPanoramaOriginalSizes";
import useImageSize from "./useImagesSize";
import useImageToJpeg from "./useImageToJpeg";
import { useIsProd } from "./useIsProd";

export const useCameraShot = (props: {
  camera?: number | "full_frame_panorama" | "panorama_cropped";
  interval?: number;
  width?: number;
  height?: number;
  debug?: `key-${string}`;
  pretendFirstLoad?: boolean;
  preloadMeta?: boolean;
  useCached?: boolean;
}) => {
  const {
    camera,
    interval: renewInterval = Infinity,
    height,
    width,
    debug,
    pretendFirstLoad = false,
    useCached = true,
  } = props;
  const { setValue, getValue } = useCache();
  const { set: setPanoramaCache } = useCachedPanoramaOriginalSizes();
  const cameraSettings = useCameraSettings(true);

  const destroy$ = useRef(new Subject<void>());
  const update$ = useRef(new Subject<void>());
  const pause$ = useRef(new BehaviorSubject<boolean>(false));

  const liveImageTemplate = useMemo(() => {
    switch (camera) {
      case "full_frame_panorama":
        return cameraSettings?.urls?.liveSrcPanoramaImage;
      case "panorama_cropped":
        return cameraSettings?.urls?.livePanoramaImage;
      default:
        return cameraSettings?.urls?.imageFileTemplate;
    }
  }, [
    camera,
    cameraSettings?.urls?.imageFileTemplate,
    cameraSettings?.urls?.livePanoramaImage,
    cameraSettings?.urls?.liveSrcPanoramaImage,
  ]);

  const isProd = useIsProd();
  const pause = useAsync({ observable: pause$.current });
  const setPause = useCallback((v: boolean) => pause$.current.next(v), []);

  const { convertImageToBase64 } = useImageToJpeg();
  const [isLoading, setIsLoading] = useState(false);

  const getShot = useCallback(
    async (camera: number | "full_frame_panorama" | "panorama_cropped") => {
      setIsLoading(true);
      try {
        const { protocol, host, port } = new URL(getJavaBackend());
        const urlObject = new URL(
          `${`${protocol}//${host}:${port}`}${liveImageTemplate?.replace(
            "{storage_file_path}",
            `camera${camera.toString()}.jpeg`
          )}`
        );
        width && urlObject.searchParams.append("w", width.toString());
        height && urlObject.searchParams.append("h", height.toString());
        urlObject.searchParams.append("key", v4());
        return await firstValueFrom(
          from(convertImageToBase64(urlObject.toString())).pipe(
            retry({
              delay: 1_000,
            })
          )
        );
      } finally {
        setIsLoading(false);
      }
    },
    [convertImageToBase64, height, liveImageTemplate, width]
  );

  const [base64, setBase64] = useState<string>();
  const renewInterval$ = useMemo(() => {
    return merge(
      //@ts-ignore
      ...[
        Boolean(!pretendFirstLoad) && of(v4()),
        renewInterval !== Infinity && interval(renewInterval),
        update$.current,
      ].filter(Boolean)
    ).pipe(
      takeUntil(destroy$.current),
      tap((v) => debug && console.log(`${debug}-${v}`)),
      filter(() => !pause && !!liveImageTemplate),
      exhaustMap(() => {
        if (isNil(camera)) {
          return of("");
        }
        return from(getShot(camera));
      }),
      tap((s) => {
        setBase64(s);
      })
    );
  }, [renewInterval, pause, liveImageTemplate, getShot]);

  const update = useMemo(
    () => async () => {
      update$.current.next();
      return convertImageToBase64(
        await firstValueFrom(renewInterval$.pipe(first()))
      );
    },
    [convertImageToBase64, renewInterval$]
  );
  useEffect(() => {
    !pretendFirstLoad && update();
  }, [pretendFirstLoad, update]);
  const cachedValue = getValue(camera?.toString?.())?.value;

  const meta = useImageSize(base64);
  useEffect(() => {
    if (camera === "full_frame_panorama" && meta && !height && !width) {
      setPanoramaCache(meta);
    }
  }, [base64, camera, height, meta, setPanoramaCache, width]);

  useEffect(() => {
    if (!height && !width && Boolean(base64) && !isNil(camera)) {
      setValue(camera.toString(), base64);
    }
  }, [base64, camera, height, setValue, width]);

  useEffect(() => {
    return () => {
      destroy$.current.next();
      destroy$.current.complete();
      pause$.current.complete();
      update$.current.complete();
    };
  }, []);

  return {
    base64: useCached ? cachedValue ?? base64 : base64,
    update,
    setPause,
    meta,
    getShot,
    isLoading,
  };
};
