import { useCallback, useEffect, useRef, useState } from "react";

const useElementIsOverlaped = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);
  const [isOverlaped, setIsOverlaped] = useState<boolean>(false);
  const check = useCallback(() => {
    if (ref.current) {
      const { left, top, right, bottom } = ref.current.getBoundingClientRect();
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const overlappedElement = document.elementFromPoint(centerX, centerY);

      if (overlappedElement === ref.current) {
        setIsOverlaped(false);
      } else {
        setIsOverlaped(true);
      }
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", check);
    window.addEventListener("resize", check);

    return () => {
      window.removeEventListener("scroll", check);
      window.removeEventListener("resize", check);
    };
  }, [check]);

  return { ref, isOverlaped, check };
};

export default useElementIsOverlaped;
