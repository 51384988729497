import { gql } from 'graphql-request';
import { debounce } from 'lodash';
import { QueryKey, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useCameraHandler } from "../../../../hooks/useCameraHandler";

interface IResponse {
  getCalibrationStateList: {
    uuid: string;
    status: "pending" | "resolved" | "rejected" | "not_spawned";
  }[];
}

const graphql = gql`
  query GetCalibrationStateList {
    getCalibrationStateList {
      uuid
      status
    }
  }
`;

interface IUseCalibrationStateList<TData> {
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
}

export const useCalibrationStateListCacheKey = "useCalibrationStateList";

export const useCalibrationStateList = (
  props?: IUseCalibrationStateList<IResponse | undefined>
) => {
  const { options } = props ?? {}; // Извлекаем значения pollingInterval и maxRetries, по умолчанию 1000 миллисекунд и 60 попыток соответственно
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const key = [useCalibrationStateListCacheKey] as QueryKey;
  const request = () => client?.request<IResponse>(graphql);
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };

  const query = useQuery(key, () => request(), {
    staleTime: 5_000,
    refetchInterval: 5_000,
    ...options,
  });

  const fetchData = debounce(async () => {
    return queryClient.fetchQuery(key, request); // Prefetch the query
  }, 100);
  const abort = async () => {
    await queryClient.cancelQueries(useCalibrationStateListCacheKey);
  };

  return {
    ...query,
    resetCache,
    queryKey: key,
    fetchData,
    abort,
  };
};
