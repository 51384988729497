import styled from "@emotion/styled";
import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../../Components/Loader/index";
import Card from "../../../../../../Components/_Layout/Card/index";
import Popup from "../../../../../../Components/_Layout/Popup/index";
import { useWritePanoramaStore } from "../../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/mutations/useWritePanoramaStore";
import { PanoramaStoreConfig } from "../../../../../../Data/NapiCameraHandlerV2/hooks/panorama-store/PanoramaStoreConfig.interface";
import { ThemeVariables } from "../../../../../../hooks/useTheme";
import LOCALIZATION from "../../../../../../Localization/index";
import { useStoreEditorState } from "../useStoreEditorState";
import { ImageFilesStoreSettings } from "./ImageFilesStoreSettings";
import { PanoramaCropStoreRegion } from "./PanoramaCropStoreRegion";
import { PanoramaStoreSettings } from "./PanoramaStoreSettings";
import { VideoFilesStoreSetting } from "./VideoFilesStoreSetting";


const Header = styled.span`
color: var(--C_Dark_Text, #334D5E);
margin-bottom:10px;
/* c_h4 (18) */
font-family: Montserrat;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.27px;
margin-bottom:10px;`


const Input = styled.input`
    color: var(--AdBlack, #000);
    /* c_body_P */
    font-family: var(${ThemeVariables.font});
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 6px;
    border: 1px solid var(--AdGray1, #B5BBC5);
    background: #FFF;
    height: 38px;
    width: 100%;
    padding-left: 12px;
`

const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: '#F5F6F8',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        },
    }
}))

interface Props {
    onClose?: () => void,
    onApply?: () => void,
    uuid: string,

}

interface Props {
    onClose?: () => void,
    onApply?: () => void,
    uuid: string,

}

export const Form = ({ onClose, onApply, uuid }: Props) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const { state: config, patch } = useStoreEditorState();
    const { mutateAsync: saveConfig, isLoading: savingConfig } = useWritePanoramaStore()
    const name = useMemo(() => config && config?.name, [config])
    const setName = useCallback((name: string) => {
        patch({ name })
    }, [patch])
    const onApplyCallback = useCallback(async () => {
        if (!config) {
            return
        }
        await saveConfig(config as PanoramaStoreConfig);
        onApply?.()
        onClose?.()
    }, [config, onApply, onClose, saveConfig])
    return <>
        <LoadingComponent ugly isLoading={savingConfig}>
            <Popup
                closeTitle={`${formatMessage({ id: LOCALIZATION.close_btn })}`}
                isFullScreen
                headerTitle={`${formatMessage({ id: LOCALIZATION.panorama_store_settings })}`}
                // isApplyDisabled={loadingList}
                onApply={onApplyCallback}
                onClose={onClose}
                classes={classes}
                applyTitle={`${formatMessage({ id: LOCALIZATION.save })}`}

            >
                <Grid container >
                    <Grid marginBottom={3} item xs={12}>
                        <Input value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                    <Grid item marginBottom={3} xs={12}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <Grid marginBottom={2}>
                                <Header>{formatMessage({ id: LOCALIZATION.panorama_store_settings }).capitalizeFirstLetter()}</Header>
                            </Grid>
                            <Grid>
                                <PanoramaStoreSettings />
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item marginBottom={3} xs={12}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <Grid marginBottom={2}>
                                <Header>{formatMessage({ id: LOCALIZATION.panorama_store_settings }).capitalizeFirstLetter()}</Header>
                            </Grid>
                            <Grid>
                                <PanoramaCropStoreRegion />
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item marginBottom={3} xs={12}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <Grid marginBottom={2}>
                                <Header>{formatMessage({ id: LOCALIZATION.panorama_store_video_header }).capitalizeFirstLetter()}</Header>
                            </Grid>
                            <Grid>
                                <VideoFilesStoreSetting />
                            </Grid>

                        </Card>
                    </Grid>

                    <Grid item marginBottom={3} xs={12}>
                        <Card contentSx={{
                            padding: '20px !important',
                            paddingBottom: '30px !important',
                        }}>
                            <Grid marginBottom={2}>
                                <Header>{formatMessage({ id: LOCALIZATION.panorama_image_settings_title }).capitalizeFirstLetter()}</Header>
                            </Grid>
                            <Grid>
                                <ImageFilesStoreSettings />
                            </Grid>

                        </Card>
                    </Grid>

                </Grid>
            </Popup>
        </LoadingComponent></>
}