import { cloneDeep } from "lodash"
import { useIntl } from "react-intl"
import LoadingComponent from "../../../../../../Components/Loader/index"
import FieldSet, { FieldSetInputType } from "../../../../../../Components/_BaseUI/FieldSet/index"
import { useCameraCommonSettings } from "../../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"
import LOCALIZATION from "../../../../../../Localization/index"
import { useStoreEditorState } from "../useStoreEditorState"

export const VideoFilesStoreSetting = () => {
    const { formatMessage } = useIntl()
    const { data: commonSettings, isLoading: gettinCommon } = useCameraCommonSettings()
    const { state: storeState, patch, set } = useStoreEditorState()
    return <>
        <LoadingComponent isLoading={gettinCommon}>
            <FieldSet labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.Text,
                value: (storeState.config.dest?.original?.[0].file ?? "/mnt/ssd/panorama/panorama_{timestamp}_part.mp4"),
                onChange: (v) => {
                    const storeStateC = cloneDeep(storeState);
                    if (
                        storeStateC.config.dest?.original?.[0].file
                    ) {
                        storeStateC.config.dest.original[0].file = v
                    }
                    set(storeStateC);
                },
            }} label={formatMessage({ id: LOCALIZATION.panorama_video_file_path })}></FieldSet>
            <FieldSet labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.Text,
                value: (storeState.config.dest?.operator?.[0].file ?? "/mnt/ssd/panorama/panorama_{timestamp}_part.mp4"),
                onChange: (v) => {
                    const storeStateC = cloneDeep(storeState);
                    if (
                        storeStateC.config.dest?.operator?.[0].file
                    ) {
                        storeStateC.config.dest.operator[0].file = v
                    }
                    set(storeStateC);
                },
            }} label={formatMessage({ id: LOCALIZATION.operator_video_file_path })}></FieldSet>

            <FieldSet labelSize={2} inputSize={true} input={{
                type: FieldSetInputType.Number,
                value: storeState.config.dest?.["rename-interval"] ?? 600,
                onChange: (v) => {
                    const storeStateC = cloneDeep(storeState);
                    if (
                        storeStateC.config.dest?.["rename-interval"]
                    ) {
                        storeStateC.config.dest["rename-interval"] = Number(v)
                    }
                    set(storeStateC);
                },
            }} label={formatMessage({ id: LOCALIZATION.video_file_chunk_sec })}></FieldSet>



        </LoadingComponent>
    </>
}