import React, { ReactNode } from "react";
import { isEventEnded } from "../../Data/EPG/EpgSchedule/dates";
import EpgEvent from "../../Data/EPG/EpgSchedule/Event";
import Order from "../../Data/_Networking/ReactQuery/Order";

// Utility function to extract text content from ReactNode
const extractTextFromReactNode = (node: ReactNode): string => {
  if (typeof node === "string" || typeof node === "number") {
    return String(node);
  }

  if (React.isValidElement(node)) {
    const children = node.props.children;
    if (typeof children === "string" || typeof children === "number") {
      return String(children);
    }
    // Recursively extract text from nested nodes
    if (Array.isArray(children)) {
      return children.map(extractTextFromReactNode).join("");
    }
  }

  return "";
};

// Custom comparator for ReactNode, compares based on text content
const reactNodeComparator = (a: ReactNode, b: ReactNode): number => {
  const textA = extractTextFromReactNode(a);
  const textB = extractTextFromReactNode(b);

  if (textA < textB) return -1;
  if (textA > textB) return 1;
  return 0;
};

const descendingComparator = <T>(a: T, b: T, orderBy: keyof T): number => {
  // Handle ReactNode comparison
  if (typeof a[orderBy] === "object" || typeof b[orderBy] === "object") {
    const aVal = a[orderBy] as unknown as ReactNode;
    const bVal = b[orderBy] as unknown as ReactNode;
    return reactNodeComparator(aVal, bVal);
  }

  //if EpgEvent object
  if (
    "startTime" in a &&
    "startTime" in b &&
    "disabled" in a &&
    "disabled" in b &&
    "streamProfileID" in a
  ) {
    const a1 = a as unknown as EpgEvent;
    const b1 = b as unknown as EpgEvent;

    if (orderBy === "startDate") {
      if (a1.disabled && b1.disabled) {
        return 0;
      } else if (a1.disabled) {
        return -1;
      } else if (b1.disabled) {
        return 1;
      }

      const aEnded = isEventEnded(a1);
      const bEnded = isEventEnded(b1);
      if (aEnded && bEnded) {
        return 0;
      } else if (aEnded) {
        return -1;
      } else if (bEnded) {
        return 1;
      }

      const a1DateTime=`${a1.startDate}_${a1.startTime}`;
      const b1DateTime=`${b1.startDate}_${b1.startTime}`;

      if (b1DateTime < a1DateTime) {
        return -1;
      }
      if (b1DateTime > a1DateTime) {
        return 1;
      }
      return 0;
    }
    else if (orderBy === "startTime") {
      if (a1.disabled && b1.disabled) {
        return 0;
      } else if (a1.disabled) {
        return -1;
      } else if (b1.disabled) {
        return 1;
      }

      const aEnded = isEventEnded(a1);
      const bEnded = isEventEnded(b1);
      if (aEnded && bEnded) {
        return 0;
      } else if (aEnded) {
        return -1;
      } else if (bEnded) {
        return 1;
      }

      if (b1.startTime < a1.startTime) {
        return -1;
      }
      if (b1.startTime > a1.startTime) {
        return 1;
      }
      return 0;
    } else if (orderBy === "gameType") {
      const aType = a1.gameType + a1.sportType;
      const bType = b1.gameType + b1.sportType;
      if (bType < aType) {
        return -1;
      }
      if (bType > aType) {
        return 1;
      }
      return 0;
    } else if (orderBy === "repeatDays") {
      const aType = a1.repeatType + a1.repeatDays;
      const bType = b1.repeatType + b1.repeatDays;
      if (bType < aType) {
        return -1;
      }
      if (bType > aType) {
        return 1;
      }
      return 0;
    } else if (orderBy === "homeTeamID") {
      const aType = `${a1?.homeTeamID}:${a1?.guestTeamID}`;
      const bType = `${b1?.homeTeamID}:${b1?.guestTeamID}`;
      if (bType < aType) {
        return -1;
      }
      if (bType > aType) {
        return 1;
      }
      return 0;
    }
  }

  // Default comparison
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const stableSort = <T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el: [T, number]) => el[0]);
};

function getComparator<T>(
  order: Order,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === Order.DESC
    ? (a, b) => descendingComparator<T>(a, b, orderBy)
    : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

export { stableSort, getComparator };
