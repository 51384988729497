// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { compact } from 'lodash';
import { ReactNode } from "react";
import { WrappedComponentProps } from "react-intl";

import { LabelType } from "../../../../Components/_BaseUI/CircledLabel/CircledLabel";
import { CameraSettingsInterface } from "../../../../Data/Camera/HWCameraSettings/CameraSettings";
import { ssdFreeSpaceString } from "../../../../Data/Camera/HWCameraSettings/CameraSettingsMetrics";
import {
  activeEpgEventStatesInfo,
  CameraSystemStateValue,
  steamingStatesInfo,
} from "../../../../Data/Camera/HWCameraSettings/CameraSettingsStreamingState";
import {
  CameraSystemStateField,
  CameraSystemStates,
} from "../../../../Data/Camera/HWCameraSettings/CameraSettingsStuff";
import {
  DCDeviceStatus,
  HeaterTemperatureControllerDataInterface,
  TemperatureControllerDataInterface,
  TemperatureControllerState,
} from "../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature";
import { TemperatureControllerID } from "../../../../Data/Camera/Metrics/Types";
import { fetchActualVersions } from "../../../../Data/Software/useActualSoftware";
import { versionSummaryFunction } from "../../../../hooks/useVersionsSummary";
import LOCALIZATION from "../../../../Localization";
import { AppLocale } from "../../../../Localization/AppContextProvider/helpers";
import { formatBitrate } from "../../../../Tools/b2Mb";
import { InfoField } from "./index";

const getFans = (
  cameraSettings?: CameraSettingsInterface
): TemperatureControllerDataInterface[] => {
  return compact([
    cameraSettings?.temperatureControllerDataJ1,
    cameraSettings?.temperatureControllerDataJ2,
  ]);
};

const getHeaters = (
  cameraSettings?: CameraSettingsInterface
): HeaterTemperatureControllerDataInterface[] => {
  return compact([
    cameraSettings?.temperatureControllerDataHeater,
    cameraSettings?.temperatureControllerDataHeater1,
    cameraSettings?.temperatureControllerDataHeater2,
    cameraSettings?.temperatureControllerDataHeater3,
    cameraSettings?.temperatureControllerDataHeater4,
    cameraSettings?.temperatureControllerDataHeaterCam1,
    cameraSettings?.temperatureControllerDataHeaterCam2,
    cameraSettings?.temperatureControllerDataHeaterCam3,
  ]);
};

const fansHeatersName: { [key in TemperatureControllerID]: string } = {
  [TemperatureControllerID.Jetson1FAN]: "1",
  [TemperatureControllerID.Jetson2FAN]: "2",
  [TemperatureControllerID.Heater]: "M",
  [TemperatureControllerID.Heater1]: "1",
  [TemperatureControllerID.Heater2]: "2",
  [TemperatureControllerID.Heater3]: "3",
  [TemperatureControllerID.Heater4]: "4",
  [TemperatureControllerID.HeaterCAM1]: "C1",
  [TemperatureControllerID.HeaterCAM2]: "C2",
  [TemperatureControllerID.HeaterCAM3]: "C3",
};

// list of systsor we already show in UI
const ignoreCriticalErrors: CameraSystemStateField[] = [
  "DC",
  "videoStreaming",
  "videoStore",
  "ssd",
  "videoSync",
  "outdoor",
  "insideCaseTemperature",
  "insideCaseHumidity",
  "heater",
  "fan",
];

const getLabelFromState = (state?: CameraSystemStateValue): LabelType => {
  switch (state) {
    case CameraSystemStateValue.Unknown:
      return LabelType.Grey;
    case CameraSystemStateValue.Warning:
      return LabelType.Yellow;

    case CameraSystemStateValue.Critial:
      return LabelType.Red;
    case CameraSystemStateValue.Ok:
      return LabelType.Green;
  }

  return LabelType.Grey;
};

const getLabel = (
  fieldName: CameraSystemStateField,
  systemStates?: CameraSystemStates
): LabelType => {
  return getLabelFromState(systemStates?.[fieldName]);
};

const getLabelFromUsedDiskSpacePercent = (
  usedSpacePercent?: number
): LabelType => {
  if (usedSpacePercent) {
    if (usedSpacePercent > 95) return LabelType.Red;
    if (usedSpacePercent > 90) return LabelType.Yellow;
    return LabelType.Green;
  }
  return LabelType.Grey;
};

type StateByKeyProps = Readonly<
  {
    key: keyof typeof InfoField;
    isServer: boolean;
    lastSettingsResponse?: CameraSettingsInterface;
    criticalErrors?: Partial<CameraSystemStates>;
    locale?: AppLocale;
    actualVersions: Awaited<ReturnType<typeof fetchActualVersions>>;
  } & WrappedComponentProps
>;

const stateByKey = (
  props: StateByKeyProps
): { label: LabelType; title: ReactNode } | null => {
  const stateOnName = props.intl.formatMessage({ id: LOCALIZATION.state_on });
  const stateOffName = props.intl.formatMessage({ id: LOCALIZATION.state_off });
  const stateOkName = props.intl.formatMessage({ id: LOCALIZATION.ok });

  const getFanHeaterState = (
    fan?: TemperatureControllerDataInterface
  ): string =>
    fan?.state === TemperatureControllerState.On ? stateOnName : stateOffName;

  switch (props.key) {
    case "Software": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      //@ts-ignore
      const versionSummary = versionSummaryFunction({
        staticData: props.lastSettingsResponse?.staticData,
        formatMessage: props.intl.formatMessage,
        actualVersions: props.actualVersions,
        isServer: props.isServer,
      });

      return {
        title: versionSummary?.node,
        label:
          versionSummary?.isOutdated && props.isServer
            ? LabelType.Red
            : LabelType.Green,
      };
    }
    case "Critical": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      let errorText = undefined;
      if (props.criticalErrors) {
        errorText = compact(
          Object.keys(props.criticalErrors || []).map((key) => {
            if (!props.criticalErrors?.[key as CameraSystemStateField]) {
              return null;
            }
            if (ignoreCriticalErrors.find((error) => error === key)) {
              return null;
            }

            return `${key} - ${
              props.criticalErrors[key as CameraSystemStateField]
            }`;
          })
        ).join(", ");
      }

      return {
        label: errorText ? LabelType.Red : LabelType.Green,
        title: `${props.intl.formatMessage({
          id: errorText ? LOCALIZATION.critical : LOCALIZATION.hardware,
        })}: ${errorText ? errorText : stateOkName}`,
      };
    }
    case "DC": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      const mainDC = props.lastSettingsResponse?.deviceStates?.mainDC;
      const powerTypeName = props.intl.formatMessage({
        id:
          mainDC === DCDeviceStatus.AC
            ? LOCALIZATION.main_dc_ac
            : mainDC === DCDeviceStatus.Battery
            ? LOCALIZATION.main_dc_battery
            : LOCALIZATION.unknown,
      });
      const batteryVolt =
        mainDC !== DCDeviceStatus.AC
          ? props.lastSettingsResponse?.lastMetricsData?.DCVoltage
          : undefined;
      const batteryVoltName = batteryVolt
        ? ` (${batteryVolt.toFixed(1)} ${props.intl.formatMessage({
            id: LOCALIZATION.unit_v,
          })})`
        : "";

      return {
        label: getLabel("DC", props.lastSettingsResponse?.systemStates),
        title: `${props.intl.formatMessage({
          id: LOCALIZATION.power,
        })}: ${powerTypeName}${batteryVoltName}`,
      };
    }
    case "StreamingProfile": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      const [, storeState] = steamingStatesInfo(
        props.lastSettingsResponse?.streamingState,
        "rtmpStream",
        true
      );

      const activeProfileId =
        props.lastSettingsResponse?.streamingState?.activeProfileId;
      let activeProfileName =
        props.lastSettingsResponse?.streamingState?.activeProfileName;
      if (!activeProfileName && activeProfileId === "No-Streaming") {
        activeProfileName = props.intl.formatMessage({
          id: LOCALIZATION.no_stream,
        });
      }

      return {
        label: getLabelFromState(storeState), //getLabel('videoStreaming', props.lastSettingsResponse?.systemStates),
        title: `${props.intl.formatMessage({
          id: LOCALIZATION.stream_profile,
        })}: ${activeProfileName}`,
      };
    }
    case "ActiveEpgEvent": {
      const [activeEventStateString, activeEventState] =
        activeEpgEventStatesInfo(
          props.lastSettingsResponse?.activeEpgEvent,
          props.locale
        );

      return {
        label: getLabelFromState(activeEventState),
        title: `${props.intl.formatMessage({
          id: LOCALIZATION.active_epg_event,
        })}: ${activeEventStateString}`,
      };
    }
    case "Streaming": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      const [streamStateString, storeState] = steamingStatesInfo(
        props.lastSettingsResponse?.streamingState,
        "rtmpStream",
        true
      );

      // const validOutputs = props.lastSettingsResponse?.streamingState?.operatorOutputs?.filter(({ targetType, outputState }) => (targetType === 'rtmpStream' && isStreamingOutputStateOk(outputState) ))

      // const state = props.lastSettingsResponse?.streamingState?.state;
      // const streamingText = `${ (state === 'running' || state === 'runningWithOutputWarnings') ? stateOnName : stateOffName }`;
      // const speed = props.lastSettingsResponse?.streamingState?.operatorOutputs?.find(({ targetType }) => (targetType === 'rtmpStream'))?.outputBitrate;
      // const speedText = (speed !== undefined) ? ` (${ formatBitrate(speed) })` : '';

      return {
        label: getLabelFromState(storeState), //getLabel('videoStreaming', props.lastSettingsResponse?.systemStates),
        title: `${props.intl.formatMessage({
          id: LOCALIZATION.streaming,
        })}: ${streamStateString}`,
      };
    }
    case "SaveToStorage": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      const [streamStateString, storeState] = steamingStatesInfo(
        props.lastSettingsResponse?.streamingState,
        "mp4File",
        true
      );

      // const streamStateLabel = getLabelFromState(streamState)
      //${(streamStateLabel === LabelType.Green) ? stateOnName : stateOffName}
      return {
        label: getLabelFromState(storeState), //getLabel('videoStore', props.lastSettingsResponse?.systemStates),
        title: `${props.intl.formatMessage({
          id: LOCALIZATION.saveToStorage,
        })}: ${streamStateString}`,
      };
    }
    case "StorageLeft": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      const mainSsdDiskUsePercent = ssdFreeSpaceString(
        props.lastSettingsResponse
      );

      return {
        label: getLabel("ssd", props.lastSettingsResponse?.systemStates),
        title: `${props.intl.formatMessage({
          id: LOCALIZATION.storage_left,
        })}: ${mainSsdDiskUsePercent}`,
      };
    }
    case "StorageSync": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      const state =
        props.lastSettingsResponse?.syncConfig?.syncEnabled &&
        props.lastSettingsResponse?.syncState?.serverState === "connected"
          ? stateOnName
          : stateOffName;
      const speed = props.lastSettingsResponse?.syncState?.currentSyncBitrate;
      const speedText = speed !== undefined ? ` (${formatBitrate(speed)})` : "";

      return {
        label: getLabel("videoSync", props.lastSettingsResponse?.systemStates),
        title: `${props.intl.formatMessage({
          id: LOCALIZATION.storage_sync_short,
        })}: ${state}${speedText}`,
      };
    }
    case "Outdoor": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      if (props.lastSettingsResponse?.lastThermalData?.outdoor) {
        const { outdoor } = props.lastSettingsResponse?.lastThermalData;
        if (isNaN(outdoor)) {
          return null;
        }

        return {
          label: getLabel("outdoor", props.lastSettingsResponse?.systemStates),
          title: `${props.intl.formatMessage({
            id: LOCALIZATION.outdoor_temp,
          })}: ${outdoor.toFixed(1)}℃`,
        };
      }

      return null;
    }
    case "Internal": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      if (props.lastSettingsResponse?.lastThermalData?.cameraCase) {
        const {
          cameraCase,
          Humidity: humidity,
          DewPoint: dewPoint,
        } = props.lastSettingsResponse?.lastThermalData;
        const insideCaseTemperature = getLabel(
          "insideCaseTemperature",
          props.lastSettingsResponse?.systemStates
        );
        const insideCaseHumidity = getLabel(
          "insideCaseHumidity",
          props.lastSettingsResponse?.systemStates
        );
        const label =
          insideCaseTemperature === LabelType.Red ||
          insideCaseHumidity === LabelType.Red
            ? LabelType.Red
            : insideCaseTemperature === LabelType.Yellow ||
              insideCaseHumidity === LabelType.Yellow
            ? LabelType.Yellow
            : LabelType.Green;

        return {
          label,
          title: `${props.intl.formatMessage({
            id: LOCALIZATION.internal,
          })}: ${cameraCase?.toFixed(1)}℃ ${humidity?.toFixed(
            2
          )}% (${props.intl.formatMessage({
            id: LOCALIZATION.dew_point_short,
          })} ${dewPoint?.toFixed(1)}℃)`,
        };
      }

      return null;
    }
    case "FAN": {
      if (props.lastSettingsResponse?.activeOptions?.isProxyMode === "true") {
        return null;
      }
      const fans = getFans(props.lastSettingsResponse);
      const heaters = getHeaters(props.lastSettingsResponse);
      const activeFans = fans.filter(
        (heater) => heater.state === TemperatureControllerState.On
      );
      const activeHeaters = heaters.filter(
        (heater) => heater.state === TemperatureControllerState.On
      );
      const isFansOn = activeFans.length !== 0;
      const isHeaterssOn = activeHeaters.length !== 0;
      const outdoor = props.lastSettingsResponse?.lastThermalData?.outdoor;
      const isTempForHeater =
        outdoor !== undefined && !isNaN(outdoor) && outdoor < 10;
      const isHeaterRender = (!isFansOn && isTempForHeater) || isHeaterssOn;

      let text = (isHeaterRender ? activeHeaters : activeFans)
        .map((item) => {
          const stateName = getFanHeaterState(item);
          const powerLoadPercentText =
            item.state === TemperatureControllerState.On
              ? ` (${item.powerLoadPercent}%)`
              : "";

          return `${
            fansHeatersName[item.id]
          }-${stateName}${powerLoadPercentText}`;
        })
        .join(", ");
      if (!text) {
        text = stateOffName;
      }

      return {
        label: getLabel(
          isHeaterRender ? "heater" : "fan",
          props.lastSettingsResponse?.systemStates
        ),
        title: `${props.intl.formatMessage({
          id: isHeaterRender ? LOCALIZATION.heater : LOCALIZATION.fan,
        })}: ${text}`,
      };
    }
    default:
      return null;
  }
};


export {
  stateByKey,
  getLabel,
  getLabelFromState,
  getLabelFromUsedDiskSpacePercent,
};
