// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { NAPICameraHandlerGraphQLClient, napiGraphQLCameraHandlerClient, napiGraphQLExposedConfigurationsClient, napiGraphQLScoerboardClient } from './contexts/NAPIGraphQLClient.context';
import { CalibrationModeProvider } from './hooks/useCalibrationMode';
import { ConfirmDialogProvider } from './hooks/useConfirm';
import { CustomThemeProvider, ThemeVariables } from './hooks/useTheme';
import { TimePickProvider } from './hooks/useTimePicker';
import AppContextProvider from './Localization/AppContextProvider';
import LocalizationProvider from './Localization/LocalizationProvider';

// eslint-disable-next-line global-require
require('whatwg-fetch');


type Props = Readonly<{}>;


export const queryCache = new QueryCache();
export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error'],
      retry: 1,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});
const theme = createTheme({
  spacing: 10,
  typography: {
    fontFamily: `var(${ThemeVariables.font})`
  }

});

export const removeAllQueryCache = () => {
  queryClient.resetQueries()
};


const AppProviders: React.FC<Props> = () => (
  <CustomThemeProvider>

  <BrowserRouter>
    <CalibrationModeProvider>
    <ThemeProvider
      theme={ theme }
    >
      <QueryClientProvider
        client={ queryClient }
      >
        <AppContextProvider>
          <LocalizationProvider>
                <NAPICameraHandlerGraphQLClient.Provider value={{ cameraHandlerGraphQL: napiGraphQLCameraHandlerClient, exposedConfigurationsClient: napiGraphQLExposedConfigurationsClient, napiGraphQLScoerboardClient }} >

                <StyledEngineProvider injectFirst>
                  <ConfirmDialogProvider>
                    <TimePickProvider>


              <AppWrapper />
                      {(process.env.NODE_ENV === 'development') && <ReactQueryDevtools initialIsOpen={false} />}
                    </TimePickProvider>

                  </ConfirmDialogProvider>

              </StyledEngineProvider>
            </NAPICameraHandlerGraphQLClient.Provider>

          </LocalizationProvider>
        </AppContextProvider>
      </QueryClientProvider>
      </ThemeProvider>
    </CalibrationModeProvider>
    </BrowserRouter>
  </CustomThemeProvider>
);


export default AppProviders;
