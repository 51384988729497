// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import renderCellContent from './Cell';
import ButtonsInRowElement, { ButtonsInRowArray } from '../../../Components/_BaseUI/ButtonsInRowElement';
// import { getEventByID } from './helpers';
import Order from '../../../Data/_Networking/ReactQuery/Order';
import Event from '../../../Data/EPG/EpgSchedule/Event';
import SortedTable from '../../../Components/SortedTable';
import { PAGE_LIMIT } from '../../../constants';
import AddEventPopup from '../AddEvent';
import EventWithoutID, { EventPriority, minPriorityForNonAdmin, priorityFilterForNonAdmin } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import usePagedData from '../../../Data/EPG/EpgSchedule/hook/usePagedData';
import { useFetchStreamingProfiles } from '../../../Data/NapiStreamingProfile/hook';
import useMutationEvent, { ActionType } from '../../../Data/EPG/EpgSchedule/fetch/useMutation';
import LOCALIZATION from '../../../Localization';
import { EMPTY_ARRAY } from '../../../constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAppConfig } from '../../../Localization/AppContextProvider/helpers';
import dayjs from 'dayjs';
import { RowClassName } from '../../../Components/SortedTable/SortedTableBodyRow';
import { isCurrentEvent, isEventIntersectEvent } from '../../../Data/EPG/EpgSchedule/dates';
import { EVENT_PRIORITY_ID_ARR, localizedPriorityById, priorityToNumber } from '../AddEvent/helpers';
import FieldSetSelectComponent from '../../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet';
import AuthorisationManager from '../../../Data/Auth/AuthorisationManager';
import { isUserRoleMinimum, UserRole } from '../../../Data/AccountUsers/UserWithoutID';
import ImportEpgPopup from '../ImportEpgPopup';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DownloadIcon from '@mui/icons-material/Download';

type Props = Readonly<{} & WrappedComponentProps>;


const EventsTabList: React.FC<Props> = (props: Props) => {

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))

  const user = AuthorisationManager.shared.user
  const defaultPriorityFilter = (): EventPriority | undefined => {
    if (isUserRoleMinimum(user?.role, UserRole.Admin)) {
      return undefined;
    }
    return priorityFilterForNonAdmin  // >= used to define minimal priority to show for user
  }

  const priorityArrForCurUser = (): EventPriority[] => {
    var priorityArr = [...EVENT_PRIORITY_ID_ARR];
    if (!isUserRoleMinimum(user?.role, UserRole.Admin)) {
      while (priorityArr.length > 0) {
          if (priorityArr[0] !== minPriorityForNonAdmin) {
              priorityArr.shift()
          }
          else {
              break;
          }
      }
    }
    return priorityArr
  }

  const { localization: { locale } } = useAppConfig();
  dayjs.locale(locale);

  // timer to update ui every 10 sec to reflect active event change
  const uiUpdateTimer = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [uiUpdateCount, setUiUpdateCount] = React.useState(0);

  React.useEffect(() => {
    if (uiUpdateTimer.current) {
      clearTimeout(uiUpdateTimer.current);
    }
    uiUpdateTimer.current = setTimeout(() => {
      setUiUpdateCount(uiUpdateCount + 1);
    }, 10000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  // cancel timer on hide
  React.useEffect(() => {
    return (
      () => {
        if (uiUpdateTimer.current) {
          clearTimeout(uiUpdateTimer.current);
        }
      }
    );
  }, []);

  const headCells = [
    {
      id: 'priority' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: "📊",
    },
    {
      id: 'name' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_name }),
    },
    {
      id: 'gameType' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_type }),
    },
    {
      id: 'homeTeamID' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_teams }),
    },
    {
      id: 'startDate' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.date }),
    },
    {
      id: 'startTime' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_time }),
    },
    {
      id: 'repeatDays' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.event_repeat }),
    },
    {
      id: 'streamProfileID' as keyof Event,
      numeric: false,
      disablePadding: true,
      label: props.intl.formatMessage({ id: LOCALIZATION.streaming }),
    },
  ];

  const [isEditPopupOpened, setIsEditPopupOpened] = React.useState<boolean>(false);
  const [isImportEpgPopupOpened, setIsImportEpgPopupOpened] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(0);
  const [showDeleted, setShowDeleted] = React.useState(0);
  const [limit, setLimit] = React.useState(PAGE_LIMIT);
  const [editItem, setEditItem] = React.useState<Event | EventWithoutID | undefined>(undefined);
  const [orderBy, setOrderBy] = React.useState<keyof Event>('startDate');
  const [order, setOrder] = React.useState<Order>(Order.ASC);
  const [filterPriority, setFilterPriority] = React.useState<EventPriority|undefined>(undefined);
  const [selected, setSelected] = React.useState<Event[]>(EMPTY_ARRAY);
  const { mutateAsync: mutateConfig } = useMutationEvent();
  const {
    // status,
    data: events,
    handleFlushData,
  } = usePagedData({
    page,
    limit,
    order,
    orderBy,
    deleted: showDeleted,
    priority: filterPriority || defaultPriorityFilter()
  });
  useFetchStreamingProfiles();


  const handlePost = async (event: Event | EventWithoutID) => {
    try {
      await mutateConfig(
        {
          event,
          type: ActionType.Post,
          // page: props.page,
        }, {
          onSuccess: () => {
            handleFlushData();
            handleCloseEditPopup();
            handleCloseImportEpgPopup();
          },
        },
      );
    } catch (error) {
      alert(error);
      handleFlushData();
      handleCloseEditPopup();
      handleCloseImportEpgPopup();
    }
  };

  const handleImportEpgDone = async () => {
    setFilterPriority(undefined);
    handleFlushData();
    handleCloseEditPopup();
    handleCloseImportEpgPopup();
  };

  const handleEventsAction = async (IDs: number[], action: ActionType) => {
    try {
      await mutateConfig(
        {
          IDs,
          type: action,
        }, {
          onSuccess: () => {
            setSelected([]);
            handleFlushData();
          },
        },
      );
    } catch (error) {
      alert(error);
    }
  };
  const handleAddClicked = () => {
    setIsEditPopupOpened(true);
  };
  const handleImportEpgClicked = () => {
    setIsImportEpgPopupOpened(true)
  };
  // const handleCopyClicked = () => {
  //   if (selected.length > 0) {
  //     const copyEvent = getEventByID(selected[0].id, events);
  //     handleMenuCopy(copyEvent);
  //   }
  // };
  const handleShowDeleted = () => {
    if (showDeleted) {
      setShowDeleted(0);
      handleFlushData();
    }
    else {
      if (window.confirm(props.intl.formatMessage({ id: LOCALIZATION.show_deleted_events }))) {
        setShowDeleted(1); // 1 = show only deleted; -1 = show all deleted and not deleted
        handleFlushData();
      }
    }
  };

  const canDeleteSelectedItems = (selected.length !== 0) && (selected.findIndex((event) => !!event.deleted) < 0);

  const handleRemoveClicked = () => {
    if (selected.length !== 0) {
      if (window.confirm(props.intl.formatMessage({ id: (canDeleteSelectedItems) ? LOCALIZATION.remove_selected_items : LOCALIZATION.recover_selected_items }))) {
        handleEventsAction(selected.map(({ id }) => id), (canDeleteSelectedItems) ? ActionType.Delete : ActionType.RecoverDelete);
      }
    }
  };

  const canDisableSelectedItems = (selected.length !== 0) && (selected.findIndex((event) => event.disabled) < 0);

  const handleDisableClicked = () => {
    if (selected.length !== 0) {
      if (window.confirm(props.intl.formatMessage({ id: (canDisableSelectedItems) ? LOCALIZATION.disable_selected_items : LOCALIZATION.enable_selected_items }))) {
        handleEventsAction(selected.map(({ id }) => id), (canDisableSelectedItems) ? ActionType.Disable : ActionType.Enable);
      }
    }
  };
  const handlePeerPageChange = (newLimit: number) => {
    setSelected([]);
    setLimit(newLimit);
  };
  const handleCloseEditPopup = () => {
    setIsEditPopupOpened(false);
    setEditItem(undefined);
  };
  const handleCloseImportEpgPopup = () => {
    setIsImportEpgPopupOpened(false);
  };
  const handleSelectIDs = (selectedEvents: Event[]) => {
    setSelected(selectedEvents);
  };
  const handleMenuCopy = (copyEvent?: Event) => {
    if (copyEvent) {
      setEditItem({
        ...copyEvent,
        id: undefined,
        name: `${copyEvent.name} <${props.intl.formatMessage({ id: LOCALIZATION.copy_item })}>`,
      } as EventWithoutID);
    }
  };
  const handleMenuDelete = (event?: Event) => {
    if (event && window.confirm(props.intl.formatMessage({ id: LOCALIZATION.remove_selected_items }))) {
      handleEventsAction([event.id], ActionType.Delete);
    }
  };
  const handleRequestSort = (newOrderField: keyof Event) => {
    if (orderBy === newOrderField) {
      setOrder((order === Order.ASC) ? Order.DESC : Order.ASC);
    }

    setOrderBy(newOrderField);
  };
  const handleRowItemClicked = (item: Event): void => {
    setEditItem(item as Event);
  };

  const rowClassName = (item: Event, allItems: Event[]) => {

    // intersect events
    if (item && allItems && allItems.length !== 0) {
      const foundItem = allItems.find((otherItem) => {
        return (otherItem !== item) ? isEventIntersectEvent(item, otherItem) : false;
      })
      if (foundItem) {
        return RowClassName.Red;
      }
    }

    // current event
    const isCurrent = isCurrentEvent(item);
    if (isCurrent) {
      const currentPriorityNumber = priorityToNumber[item.priority];
      // if other item with higer priority not current then highlight
      const foundItem = allItems.find((otherItem) => {
        return (otherItem !== item && priorityToNumber[otherItem.priority] > currentPriorityNumber) ? isCurrentEvent(otherItem) : false;
      })
      if (!foundItem) {
        return RowClassName.Blue;
      }
    }

    return null;
  }

  const filterPriorityOptions = ["none", ...priorityArrForCurUser()].map((priority) => {
    return ({
      id: priority,
      name: (priority === "none") ? props.intl.formatMessage({ id: LOCALIZATION.filter_no_filter}) : localizedPriorityById(priority),
    });
  });

  const handleFilterPriorityChange = (value: string) => {
    setFilterPriority((value === "none") ? undefined : value as EventPriority);
  };

  const buttons: ButtonsInRowArray = [
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.add_event }),
      iconButton: (mobileView) ? <AddBoxIcon /> : undefined,
      hidden: (showDeleted) ? true : false,
      onClick: handleAddClicked,
    },
    {
      space : 10 ,
    }, 
    <FieldSetSelectComponent
      input={ {
        type: FieldSetInputType.Select,
        label: props.intl.formatMessage({ id: LOCALIZATION.event_priority }),
        value: filterPriority || "none",
        options: filterPriorityOptions,
        onChange: handleFilterPriorityChange,
      } }
    />,
    {
      space : 10 ,
      hidden: (showDeleted) ? true : false,
    }, 
    {
      text: `${props.intl.formatMessage({ id: (canDisableSelectedItems) ? LOCALIZATION.disable_btn : LOCALIZATION.enable_btn })} ${ (selected.length) ? '(' + selected.length + ')' : '' }`,
      bordered: true,
      hidden: (selected.length === 0) || (showDeleted) ? true : false,
      disabled: (selected.length === 0),
      onClick: handleDisableClicked,
    },
    {
      space : 10 ,
    }, 
    {
      text: `${props.intl.formatMessage({ id: (canDeleteSelectedItems) ? LOCALIZATION.remove : LOCALIZATION.recover_remove_btn })} ${ (selected.length) ? '(' + selected.length + ')' : '' }`,
      bordered: true,
      hidden: (selected.length === 0),
      disabled: (selected.length === 0),
      onClick: handleRemoveClicked,
    },
    // {
    //   space : 10 ,
    // }, 
    // {
    //   text: props.intl.formatMessage({ id: LOCALIZATION.copy }),
    //   bordered: true,
    //   hidden: (selected.length !== 1),
    //   disabled: (selected.length !== 1),
    //   onClick: handleCopyClicked,
    // },
    {
      space :-1 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.recover_remove_btn }),
      iconButton: (showDeleted) ? <FolderDeleteIcon /> : <RestoreFromTrashIcon /> ,
      // hidden: (selected.length === 0 && !showDeleted),
      onClick: handleShowDeleted,
    },
    {
      space :5 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.import_epg_btn }),
      iconButton: <DownloadIcon /> ,
      hidden: (!!showDeleted),
      onClick: handleImportEpgClicked,
    },
    {
      space :5 ,
    }, 
    {
      text: props.intl.formatMessage({ id: LOCALIZATION.refresh }),
      iconButton: <RefreshIcon /> ,
      onClick: handleFlushData,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => handleFlushData,[]);

  return (
    <>
      <ButtonsInRowElement
        id = "buttonsRow1"
        buttons={ buttons }
      />
      {
        (events && uiUpdateCount >= 0) &&
          <SortedTable<Event>
            page={ page }
            rowsPerPage={ limit }
            // rowsPerPageOptions={ [5, 10, 25, 50, 100] }
            orderBy={ orderBy }
            order={ order }
            headCells={ headCells }
            selected={ selected }
            manyItems={ events }
            renderCellContent={ renderCellContent }
            rowClassName={ rowClassName }
            onPageChange={ setPage }
            onPeerPageChange={ handlePeerPageChange }
            onRowClick={ handleRowItemClicked }
            // onSelect={ (!mobileView) ? handleSelectIDs : undefined } // hide row select checkbox for mobile view
            onSelect={ handleSelectIDs }
            onRequestSort={ handleRequestSort }
            onMenuEdit={ handleRowItemClicked }
            onMenuCopy={ handleMenuCopy }
            onMenuDelete={ handleMenuDelete }
          />
      }
      {
        (isEditPopupOpened || editItem) &&
          <AddEventPopup
            item={ editItem }
            // page={ page }
            onSubmit={ handlePost }
            onClose={ handleCloseEditPopup }
          />
      }
      {
        (isImportEpgPopupOpened) &&
          <ImportEpgPopup
            onSubmit={ handleImportEpgDone }
            onClose={ handleCloseImportEpgPopup }
          />
      }
    </>
  );
};


export default injectIntl(EventsTabList);
