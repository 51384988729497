import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import { useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { CameraIntervalImage } from "../../../../../Components/CameraIntervalImage"
import LoadingComponent from "../../../../../Components/Loader"
import MainButton, { ButtonType } from "../../../../../Components/_BaseUI/MainButton"
import { useCameraCommonSettings } from "../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"
import { AdjustButtonIcon, RefreshIcon2 } from "../../../../../Icons/Icons"
import LOCALIZATION from "../../../../../Localization/index"
import { PerspectiveStitching } from "./PerspectiveStitching"

const MainContainer = styled(Grid)`

`

const ImagesContainer = styled(Grid)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
     padding-top: 120px;
    padding-bottom: 20px;
`

const ZoneGrid = styled(Grid)`
    position: relative;
    display: flex;
    width: min-content;
`

const ZoneVertical = styled.div`
    position: absolute;
      border-right: 3px solid black;
    transform: translate(50%,-8%);
    height: calc(100% + 30px);
    right: 0;
    top: 0;
    border-color:#A0A0A0;
    z-index: 1;
`

const ZoneButton = styled(MainButton)`
    right: 0;

    position: absolute;
    width:auto;
    transform: translate(50%,-200%);
    z-index: 2;
`

const ZoneSettingButton = styled(MainButton)`
    width:auto;
    min-width: fit-content;
    margin-left: auto;
    transform: translate(50%,0%);
    bottom: 0;
    z-index: 2;
`

const AdjustingColor = styled(MainButton)`
    width: auto;
    min-width: fit-content;
    padding-left: 40px;
    padding-right: 40px;
`

const AdjustingColorRefresh = styled(MainButton)`
    width: auto;
    min-width: fit-content;
`



export const CalculateForm = ({ uuid }: { uuid: string }) => {
    const { data: commonSettings } = useCameraCommonSettings()
    const [panoramaModal, setPanoramaModal] = useState<boolean>(false)

    const cameras = useMemo(() => (new Array(commonSettings?.configState.config.camera["num-cameras"])).fill(0).map((_, i) => i), [commonSettings?.configState.config.camera["num-cameras"]])

    const { formatMessage } = useIntl()
    const [camerasToStitch, setCamerasToStitch] = useState<[number, number]>([0, 1]);


    return <>
        <MainContainer container>
            <LoadingComponent ugly >

                <ImagesContainer item container>
                    {cameras.map((v) => <>
                        <ZoneGrid item key={v}>
                            <Grid container>
                                <Grid marginBottom={2} xs={12}>
                                    <div className={css`
                                aspect-ratio: 16/9;
                                height: 320px;
                            `} >
                                        <CameraIntervalImage camera={v} height={320} interval={5_000} />

                                    </div>
                                </Grid>
                                <Grid className={css`
                            align-items: center;
                            justify-content: center;
                        `} container gap={1} xs={12}>
                                    <Grid className={css`
                               margin-left: ${v !== cameras.length - 1 ? "auto" : "initial"};
                            `} item>
                                        <AdjustingColor buttonType={ButtonType.Outline} title={`${formatMessage({ id: LOCALIZATION.adjust_colors })}`} />
                                    </Grid>
                                    <Grid item>

                                        <AdjustingColorRefresh buttonType={ButtonType.Outline} title={<RefreshIcon2 />} />
                                    </Grid>
                                    <Grid item>
                                        <AdjustingColorRefresh buttonType={ButtonType.Outline} title={<AdjustButtonIcon stroke="rgb(24, 160, 251)" />
                                        } />

                                    </Grid>


                                </Grid>
                            </Grid>
                            {v !== cameras.length - 1 && (<><ZoneButton onClicked={() => {
                                setCamerasToStitch([v, v + 1])
                                setPanoramaModal(true);
                            }} buttonType={ButtonType.Outline} title={` ${formatMessage({ id: LOCALIZATION.zone }).capitalizeFirstLetter()} ${formatMessage({ id: LOCALIZATION.stitching }).capitalizeFirstLetter()} ${v + 1}`} />
                                <ZoneVertical /></>)}
                        </ZoneGrid>

                    </>)}


                </ImagesContainer>
                <Grid item className={css`
                display: flex;
                    align-items: center;
                            justify-content: center;
                `} xs={12}>
                    <MainButton className={css`
                        width: auto;
                    `} title={`${formatMessage({ id: LOCALIZATION.calculate_coefficients })}`} />
                </Grid>
            </LoadingComponent>

        </MainContainer>
        {
            panoramaModal && <PerspectiveStitching camerasToStitch={camerasToStitch} onClose={() => setPanoramaModal(false)} onApply={() => setPanoramaModal(false)} uuid={uuid} />
        }

    </>
}