import React, { useCallback, useMemo } from 'react';
import FieldSetSelectComponent from '../../../Components/_BaseUI/FieldSet/FieldSetSelectComponent';
import FieldSetText from '../../../Components/_BaseUI/FieldSet/FieldSetText';
import { FieldSetInputType } from '../../../Components/_BaseUI/FieldSet/index';
import { Action, ActionType, Condition, ConditionOperator, EndAction, FieldName, LogicalOperator, Rule } from './types';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { SportType } from '../../../Data/EPG/EpgSchedule/EventWithoutID';
import { CrossIcon } from '../../../Icons/Icons';
import LOCALIZATION from '../../../Localization/index';

interface RuleProps {
    rule: Rule;
    onChange?: (updatedRule: Rule) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onDelete?: () => void;
    className?: string;
    isLast?: boolean;
    isFirst?: boolean;
    eventTypeOptions?: { id: string; name: string }[];
    streamProfileOptions?: { id: string; name: string }[];
}

const RuleContainer = styled(Grid)`
  background: #F5F6F8;
  border: 1px solid #90A0B5;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const RuleHeader = styled(Grid)`
  background: white;
  font-size: 18px;
  border: 1px solid #90A0B5;
  border-radius: 8px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const IconButton = css`
    &:hover{
        cursor: pointer;
    }
    color: #18A0FB;
`;

const RuleComponent: React.FC<RuleProps> = ({ rule, onChange, onMoveUp, onMoveDown, onDelete, className, isLast, isFirst, eventTypeOptions, streamProfileOptions }) => {
    const { formatMessage } = useIntl()
    const logicalOperatorOptions = useMemo(
        () => Object.values(LogicalOperator).map(v => ({
            id: v,
            //@ts-ignore
            name: formatMessage({ id: LOCALIZATION[`import_epg_rules_${v.toLowerCase().replaceAll(' ', '_')}`] })
        })),
        []
    );

    const fieldNameOptions = useMemo(
        () => Object.values(FieldName).map(v => ({
            id: v,
            //@ts-ignore
            name: formatMessage({ id: LOCALIZATION[`import_epg_rules_${v.toLowerCase().replaceAll(' ', '_')}`] })
        })),
        []
    );

    const conditionOperatorOptions = useMemo(
        () => Object.values(ConditionOperator).map(v => ({
            id: v,
            //@ts-ignore
            name: formatMessage({ id: LOCALIZATION[`import_epg_rules_${v.toLowerCase().replaceAll(' ', '_')}`] })
        })),
        []
    );
    const actionTypeOptions = useMemo(() => Object.values(ActionType).map(v => ({
        id: v,
        //@ts-ignore
        name: formatMessage({ id: LOCALIZATION[`import_epg_rules_${v.toLowerCase().replaceAll(' ', '_')}`] })
    })), []);
    const endActionOptions = useMemo(() => Object.values(EndAction).map(v => ({
        id: v,
        //@ts-ignore
        name: formatMessage({ id: LOCALIZATION[`import_epg_rules_${v.toLowerCase().replaceAll(' ', '_')}`] })
    })), []);
    // Опции для выбора типа спорта
    const sportTypeOptions = useMemo(
        () => Object.values(SportType).map(v => ({
            id: v,
            //@ts-ignore
            name: formatMessage({ id: LOCALIZATION[`${v.toLowerCase().replaceAll(' ', '_')}`] })
        })),
        []
    );

    const handleLogicalOperatorChange = useCallback((index: number, value: LogicalOperator) => {
        const updatedConditions = [...rule.ifBlock.conditions];
        if (index === 0) {
            updatedConditions.push({
                logicalOperator: value,
                field: FieldName.Title,
                operator: ConditionOperator.Contains,
                value: '',
            });
        } else if (index > 0) {
            updatedConditions[index] = ({
                ...updatedConditions[index],
                logicalOperator: value,
            });
        }
        onChange?.({ ...rule, ifBlock: { ...rule.ifBlock, conditions: updatedConditions } });
    }, [rule, onChange]);

    const handleConditionChange = useCallback((index: number, field: keyof Condition, value: any) => {
        const updatedConditions = [...rule.ifBlock.conditions];
        updatedConditions[index] = { ...updatedConditions[index], [field]: value };
        onChange?.({ ...rule, ifBlock: { ...rule.ifBlock, conditions: updatedConditions } });
    }, [rule, onChange]);

    const handleActionChange = useCallback((index: number, field: keyof Action, value: any) => {
        const updatedActions = [...rule.actions];
        updatedActions[index] = { ...updatedActions[index], [field]: value };
        onChange?.({ ...rule, actions: updatedActions });
    }, [rule, onChange]);

    const addAction = useCallback((type: ActionType) => {
        const updatedActions = [...rule.actions, { type, value: '' }];
        onChange?.({ ...rule, actions: updatedActions });
    }, [rule, onChange]);

    const removeCondition = useCallback((index: number) => {
        const updatedConditions = rule.ifBlock.conditions.filter((_, i) => i !== index);
        onChange?.({ ...rule, ifBlock: { ...rule.ifBlock, conditions: updatedConditions } });
    }, [rule, onChange]);

    const removeAction = useCallback((index: number) => {
        const updatedActions = rule.actions.filter((_, i) => i !== index);
        onChange?.({ ...rule, actions: updatedActions });
    }, [rule, onChange]);

    const usesOnlyOr = useMemo(() => rule.ifBlock.conditions.find(v => v.logicalOperator === LogicalOperator.OR), [rule])
    const usesOnlyAnd = useMemo(() => rule.ifBlock.conditions.find(v => v.logicalOperator === LogicalOperator.AND), [rule])

    const renderConditions = useMemo(() => (
        <Grid container gap={2}>
            {rule.ifBlock.conditions.map((condition, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={12}>
                        <Grid gap={2} container>
                            <Grid item xs="auto">
                                <FieldSetSelectComponent
                                    input={{
                                        label: formatMessage({ id: LOCALIZATION.import_epg_rules_logical_operator }),
                                        type: FieldSetInputType.Select,
                                        value: index === 0 ? LogicalOperator.IF : condition.logicalOperator,
                                        options: index === 0 ? logicalOperatorOptions
                                            .filter(v => (usesOnlyAnd && v.id === LogicalOperator.AND) || (usesOnlyOr && v.id === LogicalOperator.OR) || (!usesOnlyAnd && !usesOnlyOr) || v.id === LogicalOperator.IF)

                                            : logicalOperatorOptions.filter(v => v.id !== LogicalOperator.IF),
                                        onChange(value) {
                                            handleLogicalOperatorChange(index, value as LogicalOperator);
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FieldSetSelectComponent
                                    input={{
                                        label: formatMessage({ id: LOCALIZATION.import_epg_rules_field }),
                                        type: FieldSetInputType.Select,
                                        value: condition.field,
                                        options: fieldNameOptions,
                                        onChange(value) {
                                            handleConditionChange(index, 'field', value);
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FieldSetSelectComponent
                                    input={{
                                        label: formatMessage({ id: LOCALIZATION.import_epg_rules_operator }),
                                        type: FieldSetInputType.Select,
                                        value: condition.operator,
                                        options: conditionOperatorOptions,
                                        onChange(value) {
                                            handleConditionChange(index, 'operator', value);
                                        },
                                    }}
                                />
                            </Grid>
                            {condition.operator !== ConditionOperator.Any && <Grid item xs>
                                <FieldSetText
                                    input={{
                                        label: formatMessage({ id: LOCALIZATION.import_epg_rules_value }),
                                        type: FieldSetInputType.Text,
                                        value: condition.value,
                                        onChange(value) {
                                            handleConditionChange(index, 'value', value);
                                        },
                                    }}
                                />
                            </Grid>}
                            {index !== 0 && (
                                <Grid item className={css` display: flex; align-items: center; `} xs="auto">
                                    <CrossIcon className={IconButton} onClick={() => removeCondition(index)} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    ), [usesOnlyAnd, usesOnlyOr, rule.ifBlock.conditions, logicalOperatorOptions, fieldNameOptions, conditionOperatorOptions, handleConditionChange, handleLogicalOperatorChange, removeCondition]);

    const renderActions = useMemo(() => (
        <Grid container gap={2}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs="auto">
                        <FieldSetSelectComponent
                            input={{
                                label: formatMessage({ id: LOCALIZATION.import_epg_rules_add_rule }),
                                type: FieldSetInputType.Select,
                                value: ActionType.ThenSet,
                                options: actionTypeOptions,
                                onChange(value) {
                                    addAction(value as ActionType);
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {rule.actions.map((action, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={4}>
                        <FieldSetSelectComponent
                            input={{
                                label: formatMessage({ id: LOCALIZATION.import_epg_rules_action_type }),
                                type: FieldSetInputType.Select,
                                value: action.type,
                                options: actionTypeOptions.filter(v => v.id !== ActionType.ThenSet),
                                onChange(value) {
                                    handleActionChange(index, 'type', value);
                                },
                            }}
                        />
                    </Grid>
                    {action.type === ActionType.SetSportType && (
                        <Grid item xs={6}>
                            <FieldSetSelectComponent
                                input={{
                                    label: formatMessage({ id: LOCALIZATION.import_epg_rules_sport_type }),
                                    type: FieldSetInputType.Select,
                                    value: action.value,
                                    options: sportTypeOptions,
                                    onChange(value) {
                                        handleActionChange(index, 'value', value);
                                    },
                                }}
                            />
                        </Grid>
                    )}
                    {action.type === ActionType.SetEventType && eventTypeOptions ? (
                        <Grid item xs={6}>
                            <FieldSetSelectComponent
                                input={{
                                    label: formatMessage({ id: LOCALIZATION.import_epg_rules_event_type }),
                                    type: FieldSetInputType.Select,
                                    value: action.value,
                                    options: eventTypeOptions,
                                    onChange(value) {
                                        handleActionChange(index, 'value', value);
                                    },
                                }}
                            />
                        </Grid>
                    ) : action.type === ActionType.SetEventType && (
                        <Grid item xs={6}>
                            <FieldSetText
                                input={{
                                    label: formatMessage({ id: LOCALIZATION.import_epg_rules_event_type }),
                                    type: FieldSetInputType.Text,
                                    value: action.value,
                                    onChange(value) {
                                        handleActionChange(index, 'value', value);
                                    },
                                }}
                            />
                        </Grid>
                    )}
                    {action.type === ActionType.SetStreamProfile && streamProfileOptions ? (
                        <Grid item xs={6}>
                            <FieldSetSelectComponent
                                input={{
                                    label: formatMessage({ id: LOCALIZATION.import_epg_rules_stream_profile }),
                                    type: FieldSetInputType.Select,
                                    value: action.value,
                                    options: streamProfileOptions,
                                    onChange(value) {
                                        handleActionChange(index, 'value', value);
                                    },
                                }}
                            />
                        </Grid>
                    ) : action.type === ActionType.SetStreamProfile && (
                        <Grid item xs={6}>
                            <FieldSetText
                                input={{
                                    label: formatMessage({ id: LOCALIZATION.import_epg_rules_stream_profile }),
                                    type: FieldSetInputType.Text,
                                    value: action.value,
                                    onChange(value) {
                                        handleActionChange(index, 'value', value);
                                    },
                                }}
                            />
                        </Grid>
                    )}
                    {action.type !== ActionType.SetSportType && action.type !== ActionType.SetEventType && action.type !== ActionType.SetStreamProfile && (
                        <Grid item xs={6}>
                            <FieldSetText
                                input={{
                                    label: formatMessage({ id: LOCALIZATION.import_epg_rules_action_value }),
                                    type: FieldSetInputType.Text,
                                    value: action.value,
                                    onChange(value) {
                                        handleActionChange(index, 'value', value);
                                    },
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item className={css` display: flex; align-items: center; `} xs="auto">
                        <CrossIcon className={IconButton} onClick={() => removeAction(index)} />
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    ), [rule.actions, actionTypeOptions, sportTypeOptions, eventTypeOptions, streamProfileOptions, handleActionChange, addAction, removeAction]);

    return (
        <Grid item xs={12}>
            <RuleHeader container>
                <Grid item className={css` display: flex; align-items: center;`} xs>
                    <p className={css`margin:  0 0 0 20px;`}>{rule.id}# {formatMessage({ id: LOCALIZATION.import_epg_rules_rule })}</p>
                </Grid>
                <Grid item xs="auto">
                    <Grid className={css`height: 100%;`} padding={1} container>
                        <Grid item className={css`height: 100%; align-items: center; display:flex;`} xs='auto'>
                            {!isFirst && <ArrowCircleUpIcon className={IconButton} onClick={onMoveUp} />}
                            {!isLast && <ArrowCircleDownIcon className={IconButton} onClick={onMoveDown} />}
                            <DeleteOutlineIcon className={IconButton} onClick={onDelete} />
                        </Grid>
                    </Grid>
                </Grid>
            </RuleHeader>
            <RuleContainer container className={className} padding={2} gap={2}>
                <Grid item xs={12}>{renderConditions}</Grid>
                <Grid item xs={12}>{renderActions}</Grid>
                <Grid item xs={6}>
                    <FieldSetSelectComponent
                        input={{
                            label: formatMessage({ id: LOCALIZATION.import_epg_rules_success_end_action }),
                            type: FieldSetInputType.Select,
                            value: rule.successEndAction,
                            options: endActionOptions,
                            onChange(value) {
                                onChange?.({ ...rule, successEndAction: value as EndAction });
                            },
                        }}
                    />
                </Grid>
            </RuleContainer>
        </Grid>
    );
};

export default RuleComponent;
