import { createContext, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState } from "react";
import { map, merge, Subject } from "rxjs";
import { useAsync } from '../../../../../../hooks/useAsync';
export enum PanelAction {
    update,
    test,
    fit,
    fullSize,

}

type PanoramaState = {
    update$: Subject<PanelAction.update>,
    test$: Subject<PanelAction.test>,
    fit$: Subject<PanelAction.fit>,
    fullSize$: Subject<PanelAction.fullSize>,
};

const initialValue: PanoramaState = {
    update$: new Subject(),
    test$: new Subject(),
    fit$: new Subject(),
    fullSize$: new Subject(),
}


const ParnoramaContext = createContext<{ state: PanoramaState, setState: React.Dispatch<React.SetStateAction<PanoramaState>> }>(
    {
        state: initialValue,
        setState: function(value: SetStateAction<PanoramaState>): void {
            throw new Error("Function not implemented.");
        }
    });


export const PanelStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<PanoramaState>(initialValue);


    return (
        <ParnoramaContext.Provider value={{ state, setState }}>
            {children}


        </ParnoramaContext.Provider>
    );
};




export const usePanelActions = () => {
    const { state } = useContext(ParnoramaContext);
    const { update$, test$, fit$, fullSize$ } = state;
    const update = useCallback(() => {
        update$.next(PanelAction.update)
    }, [update$])

    const test = useCallback(() => {
        test$.next(PanelAction.test)
    }, [test$])

    const fit = useCallback(() => {
        fit$.next(PanelAction.fit)
    }, [fit$])

    const fullSize = useCallback(() => {
        fullSize$.next(PanelAction.fullSize)
    }, [fullSize$])

    const action$ = useMemo(() => merge(update$, test$, fit$, fullSize$).pipe(map(v => ({ action: v, timestamp: (new Date()).getTime() }))), [])
    const lastAction = useAsync({ observable: action$ })

    return { update, test, fit, fullSize, lastAction };
};