import { gql } from "graphql-request";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useCameraHandler } from "../../../../hooks/useCameraHandler";
import { undistordConfigCacheKey } from "../useUndistordConfig";

interface IResponse {
  getCalibrationState: {
    status: "pending" | "resolved" | "rejected" | "not_spawned";
    config?: {
      type: "fisheye" | "regular";
      kdata?: number[];
      ddata?: number[];
      angle?: number;
      balance?: number;
    };
    info?: {
      reported_reprojection_error: number;
      avg_reprojection_error: number;
    };
  };
}

const graphql = gql`
  query GetCalibrationState($uuid: ID!) {
    getCalibrationState(uuid: $uuid) {
      status
      config {
        type
        kdata
        ddata
        angle
        balance
      }
      info {
        reported_reprojection_error
        avg_reprojection_error
      }
    }
  }
`;

interface IUseCalibrationResult<TData> {
  options?: Omit<
    UseQueryOptions<TData, unknown, TData, QueryKey>,
    "queryKey" | "queryFn"
  >;
  uuid: string;
}

export const useCalibrationConfigCacheKey = (uuid: string) =>
  `useCalibrationConfigCacheKey${uuid}`;

export const useCalibrationConfig = (
  props: IUseCalibrationResult<IResponse | undefined>
) => {
  const { options, uuid } = props ?? {};
  const queryClient = useQueryClient();
  const client = useCameraHandler();
  const key = [useCalibrationConfigCacheKey(uuid)] as QueryKey;
  const resetCache = async () => {
    await queryClient.invalidateQueries(key);
  };
  const request = async () => {
    await queryClient.invalidateQueries({
      predicate: (queries) => {
        return queries.queryKey.includes(undistordConfigCacheKey(uuid));
      },
    });

    return client?.request<IResponse>(graphql, { uuid });
  };

  const query = useQuery(key, () => request(), {
    staleTime: 15_000,
    ...options,
  });

  return {
    ...query,
    resetCache,
    queryKey: key,
  };
};
