import { ReactNode, useCallback, useEffect, useState } from "react";
// Import Swiper styles
import { css } from "@emotion/css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/virtual';
import { useSwiperModule } from "../hooks/useSwiper";
// @ts-ignore
import type { Swiper } from "swiper/types";

interface Props {
    children: ReactNode[],
    className?: string,
    onChange?: (active: number) => void,
    activeIndex: number,
    slidesPerView?: number,
    spaceBetween?: `${string}px`
}

const getRealIndex = (limit: number, index: number, last?: number) => {



    const divided = Math.floor(index / limit);
    const res = index - divided * limit;

    return res;
}

export const Carousel = (({ children, className, onChange, activeIndex: remoteActiveIndex, slidesPerView = 1, spaceBetween = '20px' }: Props) => {
    const { Swiper, SwiperSlide, } = useSwiperModule() ?? {};

    const onSlideChange = useCallback((e: { realIndex: number; }) => {
        onChange?.(e.realIndex)
    }, [onChange])

    const [swiper, setSwiper] = useState<Swiper>(null)

    useEffect(() => {
        if (swiper?.destroyed) {
            return;
        }
        const newIndex = getRealIndex(children.length - slidesPerView + 1, remoteActiveIndex,)

        swiper?.slideTo?.(newIndex);
    }, [swiper, remoteActiveIndex, children.length, slidesPerView])


    if (!SwiperSlide || !Swiper) {
        return <></>
    }


    return <Swiper

            onInit={setSwiper}
            realIndex={remoteActiveIndex}
            className={css`
    position: relative;
    ${className}
    `}

            loop
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
            onSlideChange={onSlideChange}

        >
            {children.map((v, i) => <SwiperSlide className={css`max-width: fit-content ;`} key={i}> {v} </SwiperSlide>)}

    </Swiper>
})