import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { createStyles, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import LoadingComponent from "../../../../../../Components/Loader/index";
import Popup from "../../../../../../Components/_Layout/Popup/index";
import { useFirstDefinedValue } from "../../../../../../hooks/useFirstDefined";
import useImageSize from "../../../../../../hooks/useImagesSize";
import { useSortedObject } from "../../../../../../hooks/useSortedObject";
import LOCALIZATION from "../../../../../../Localization/index";
import { Region } from "../../../ImageAdjust/AutoAdjustmentForm/ImagePointer/Region.interface";
import { withRegion } from "../../../ImageAdjust/AutoAdjustmentForm/ImagePointer/withRegion";
import { RegionForm } from "./RegionForm";

type Props = Readonly<{
    onClose: () => void
    handleSubmit?: (value: Region) => Promise<void> | void,
    region: Region,
    image: string,
}>;
const useStyles = makeStyles((theme: Theme) => createStyles({
    contentRoot: {
        background: 'white',
        padding: '0px',
    }
}))

const Canvas = withRegion(styled.canvas())

export const CropModal = (props: Props) => {
    const { onClose, handleSubmit: handleSubmitRemote, region: initialRegion, image } = props;
    const classes = useStyles();
    const intl = useIntl();
    const ref = useRef<HTMLCanvasElement>(null);
    const { width, height } = useImageSize(image) ?? {}

    const imageLoaded = useMemo(() => image && width && height, [image, width, height])

    const [regionToSave, setRegionToSave] = useState<Region>(initialRegion)
    const sortedRegionToSaved = useSortedObject(regionToSave)
    const submitCallback = useCallback(() => {
        handleSubmitRemote?.(regionToSave);
        onClose();
    }, [handleSubmitRemote, onClose, regionToSave])


    const rootGridRef = useRef<HTMLDivElement>(null);
    const currentRootGrid = rootGridRef.current;
    const pixiConf = useMemo(() => {
        if (!currentRootGrid) {
            return;
        }
        return { resizeTo: currentRootGrid }
    }, [currentRootGrid])

    const firstpixiConf = useFirstDefinedValue(pixiConf)


    return <Popup
        closeTitle={`${intl.formatMessage({ id: LOCALIZATION.close_btn })}`}
        applyTitle={`${intl.formatMessage({ id: LOCALIZATION.apply })}`}
        isFullScreen
        headerTitle={`${intl.formatMessage({ id: LOCALIZATION.lens })} ${intl.formatMessage({ id: LOCALIZATION.image_enchantments })}`}
        onApply={submitCallback}
        onClose={onClose}
        classes={classes}

    >
        <LoadingComponent isLoading={!width || !height}>
            {width && height && <Grid ref={rootGridRef} height={"100%"} overflow={"hidden"}>

            <Grid paddingLeft={2} container xs={6} xl={6} sm={12}>
                    <RegionForm height={height} width={width} value={regionToSave} onChange={setRegionToSave}></RegionForm>

            </Grid>

            <Grid container height={"100%"} width={"100%"}>
                    {imageLoaded && rootGridRef && firstpixiConf && <Canvas base64={image} ref={ref} region={sortedRegionToSaved} onRegionChanged={setRegionToSave} config={firstpixiConf} />}
            </Grid>
            </Grid>}
        </LoadingComponent>


    </Popup>
}