import { useEffect, useMemo, useState } from "react";

export const useAsyncMemo = <T>(
  asyncCallback: () => Promise<T>,
  dependencies: any[]
): T | undefined => {
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const result = await asyncCallback();
        if (isMounted) {
          setData(result);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return useMemo(() => data, [data]);
};
