// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FormControl, MenuItem, Select } from '@mui/material';
import { Colors } from '../../../../../../Configuration/Styles/Colors';
import { FontName, useCurrentFont } from '../../../../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../../../../Configuration/Styles/Sizes';
import { TemperatureControllerLastDataInterface } from '../../../../../../Data/Camera/HWCameraSettings/CameraSettingsTemperature';
import { compareSensorPlaces, getSensorPlaceName } from '../../../../../../Data/Camera/Metrics/Tools';
import { SensorPlace } from '../../../../../../Data/Camera/Metrics/Types';
import { formattedMetricValue } from '../../../../../../Tools/Tools';


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    flexGrow: 1
  },
  formControl: {
    minWidth: 90,
    width: '100%',
    minHeight: 40,
    background: Colors.mainBackground,
    fontFamily: ({ fontFamily }) => fontFamily,
  },
  select: {
    height: 38,
    borderRadius: 6
  },
  text: {
    fontFamily: ({ fontFamily }) => fontFamily,
    fontSize: Sizes.subtitle
  },
  correct: {
    color: Colors.mainTitle
  },
  wrong: {
    color: Colors.mainRed
  }
}));

export default function HeaterTemperatureControllerSourceSelector(props: { sources: SensorPlace[], lastData: TemperatureControllerLastDataInterface, currentSource?: SensorPlace, isCorrect?: boolean, onChanged: (source: SensorPlace) => void }) {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })
  const isCorrect = (props.isCorrect !== undefined) ? props.isCorrect : true

  const currentValue = props.currentSource

  const handleCurrentValueChange = (source: SensorPlace) => {
    props.onChanged(source)
  }

  const sources = props.sources
  sources.sort(compareSensorPlaces)

  const getSensorValue = (sensorPlace: SensorPlace) => {
    const value = props.lastData[sensorPlace]
    return formattedMetricValue(value, '˚C')
  }

  function createDropDownItems() {
    return sources.map(source => {
      return (
        <MenuItem
          key={ source }
          value={ source }
          onClick={ () => {
            handleCurrentValueChange(source)
          } }>
          <div
            className={ clsx(classes.text, isCorrect ? classes.correct : classes.wrong) }>{ [getSensorPlaceName(source), getSensorValue(source)].filter(text => !!text).join(', ') }</div>
        </MenuItem>
      )
    })
  }

  return (
    <div className={ classes.root }>
      <FormControl variant="outlined" className={ classes.formControl } size="small">
        <Select
          className={ classes.select }
          displayEmpty
          value={ currentValue }
        >
          { createDropDownItems() }
        </Select>
      </FormControl>
    </div>
  )
}
