import { Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Loader from "../../../../Components/Loader";
import FieldSet, { FieldSetInputType } from "../../../../Components/_BaseUI/FieldSet";
import { useKickCalibrationMode } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/useKickCalibrationMode";
import { usePingCalibrationMode } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/mutation/usePingCalibrationMode";
import { useCurrentUndistordConfigs } from "../../../../Data/NapiCameraHandlerV2/hooks/undistord/useCurrentUndistordConfigs";
import { useCameraCommonSettings } from "../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { NO_STREAM_ID } from "../../../../Data/NapiStreamingProfile/constants";
import { StreamingSettingsKey } from "../../../../Data/NapiStreamingSettings";
import { useNapiStreamingSettingsFetch } from "../../../../Data/NapiStreamingSettings/hook";
import { useCalibrationMode } from "../../../../hooks/useCalibrationMode";
import useConfirm from "../../../../hooks/useConfirm";
import { AdjustButtonIcon } from "../../../../Icons/Icons";
import LOCALIZATION from "../../../../Localization";
import { UndistortionListModal } from "./UndistortionListModal";

const useStyles = makeStyles((theme: Theme) => createStyles({
    editButton: {
        [theme.breakpoints.down(500)]: {
            display: "none !important"
        },
    },
    editIcon: {
        [theme.breakpoints.up(500)]: {
            display: "none  !important"
        },
    },
    buttonSx: {
        [theme.breakpoints.down(500)]: {
            minWidth: "20px !important",
        },
        [theme.breakpoints.up(500)]: {
            minWidth: "120px  !important"
        },
    }
}))

export const UndistordSettingsTable = (() => {
    const { data: commonSettingsData, isLoading: isLoadingCommonSettings } = useCameraCommonSettings()
    const { data: udistordData, isLoading: isLoadingUdistord } = useCurrentUndistordConfigs()
    const intl = useIntl();
    const cameras = useMemo(() => {
        return Array.from(Array(commonSettingsData?.configState.config.camera["num-cameras"]).keys())
    }, [commonSettingsData])
    const mainCamera = useMemo(() => {
        return commonSettingsData?.configState.config.camera["main-camera"]
    }, [commonSettingsData])
    const undistordSettings = useMemo(() => {
        return udistordData?.getCurrentUndistordConfig
    }, [udistordData])
    const fields = useMemo(() => {
        return cameras.map(camera => {
            return {
                camera,
                udistordName: undistordSettings?.find(v => v.camera === camera)?.name ?? intl.formatMessage({ id: LOCALIZATION.undistord_disabled })
            }
        })
    }, [cameras, intl, undistordSettings])
    const { mutateAsync: checkCalibrationMode, isLoading: checkingCalibrationMode } = usePingCalibrationMode();
    const { mutateAsync: disableCalibrationMode, isLoading: disablingCalibration, isError: disablingError } = usePingCalibrationMode();
    const { editButton, editIcon, buttonSx } = useStyles()
    useEffect(() => {
        if (!disablingError) {
            return;
        }
        alert(LOCALIZATION.error_while_changing_camera_handler_conf)
    }, [disablingError])

    const [openLensModal, setOpenLensModal] = useState<boolean>(false);

    const [choosenLens, setChosenlens] = useState<number | null>(null)
    const onChooseLens = useCallback((camera: number) => {
        setChosenlens(camera);
        setOpenLensModal(true);
    }, [setChosenlens])

    const { mutateAsync: kickOperator } = useKickCalibrationMode()
    const { confirm } = useConfirm();
    const { setMode: set, setUndistordDisabled, setRunActiveUndistord } = useCalibrationMode();
    const { data: streamingSettings } = useNapiStreamingSettingsFetch()

    const requestModalOpen = useCallback(async (camera: number) => {
        set(null);
        setRunActiveUndistord(true);
        if (streamingSettings?.data && streamingSettings?.data?.[StreamingSettingsKey.StreamingSettingsSelectedProfileID] !== NO_STREAM_ID && !(await confirm({
            bodyText: intl.formatMessage({ id: LOCALIZATION.confirm_streamoff }),
            timeoutAction: "cancel",
            timeout: 15,
        }))) {
            return;
        }
        checkCalibrationMode().then(async ({ state }) => {
            if (state && (! await confirm({ bodyText: intl.formatMessage({ id: LOCALIZATION.calibration_mode_is_occupy }), timeout: 15 }))) {
                return;
            }
            kickOperator();
            onChooseLens(camera);
        })

    }, [checkCalibrationMode, confirm, intl, kickOperator, onChooseLens, set, setRunActiveUndistord, streamingSettings?.data])

    const closingUndistorfModalListCallback = useCallback(async () => {
        set(false);
        setUndistordDisabled(false)
        await disableCalibrationMode({ set: false, undistordDisabled: false });
        setOpenLensModal(false)
    }, [disableCalibrationMode, set, setUndistordDisabled])

    const loadingMessage = useMemo(() => {
        if (disablingCalibration) {
            return intl.formatMessage({ id: LOCALIZATION.going_out_calibration_mode })
        }
    }, [disablingCalibration, intl])

    return <>
        <Loader label={loadingMessage} ugly isLoading={isLoadingUdistord || isLoadingCommonSettings || checkingCalibrationMode || disablingCalibration}>
            {fields.map(field => (<Fragment key={field.camera}>
                <FieldSet
                    labelSize={4}
                    inputSize={8}
                    buttonSx={buttonSx}
                    button={{ label: <><Grid overflow={"hidden"} className={editButton} >{intl.formatMessage({ id: LOCALIZATION.edit })}</Grid><AdjustButtonIcon className={editIcon} /></>, onClick: () => requestModalOpen(field.camera) }}
                    label={`${intl.formatMessage({ id: LOCALIZATION.lens })}  #${field.camera}${mainCamera === field.camera ? `(${intl.formatMessage({ id: LOCALIZATION.main_lens })})` : ''}`}
                    input={{ type: FieldSetInputType.StaticLabel, value: field.udistordName }}
                    inputSx={{ display: "flex" }}
                ></FieldSet>
            </Fragment>))}
        </Loader>
        {openLensModal && <UndistortionListModal camera={choosenLens ?? 0} onClose={closingUndistorfModalListCallback}  ></UndistortionListModal>}


    </>
})
