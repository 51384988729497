import { useEffect, useState } from "react";

type ImageDimensions = {
  width?: number;
  height?: number;
};

const useImageDimensions = (base64Image?: string): ImageDimensions => {
  const [imageDimensions, setImageDimensions] = useState<
    ImageDimensions
  >({});

  useEffect(() => {
    if (!base64Image) {
      return;
    }
    const image = new Image();
    const handleLoad = () => {
      setImageDimensions({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    };
    image.addEventListener("load", handleLoad);

    image.src = base64Image;

    return () => {
      image.removeEventListener("load", handleLoad);
    };
  }, [base64Image]);

  return imageDimensions ;
};

export default useImageDimensions;
