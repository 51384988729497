import { useMemo } from "react";
import { useStitchingConfig } from "./useStichingConfig";

type Props = {
  indexes: [number, number];
  uuid: string;
};
export const useGetZonePoints = ({ indexes, uuid }: Props) => {
  const { data: config, isLoading } = useStitchingConfig({ uuid });
  const data = useMemo(() => {
    const zoneObj = config?.calibrationInput?.images.filter((v) =>
      indexes.includes(v.index)
    );
    if (zoneObj && zoneObj.length === 2)
      return [
        zoneObj[0].points.map((v) => [v.x, v.y] as [number, number]),
        zoneObj[1].points.map((v) => [v.x, v.y] as [number, number]),
      ] as [[number, number][], [number, number][]];
  }, [config?.calibrationInput?.images, indexes]);

  return {
    data,
    isLoading,
  };
};
