import { useEffect, useRef } from "react";

export function useWhyDidYouUpdate(
  name: string,
  props: { [key in string]: any }
) {
  const previousProps = useRef<{ [key in string]: any }>(props);

  useEffect(() => {
    const allPropKeys = Object.keys({ ...previousProps.current, ...props });
    const propChanges: { [key in string]: any } = {};

    allPropKeys.forEach((key) => {
      if (previousProps.current[key] !== props[key]) {
        propChanges[key] = {
          from: previousProps.current[key],
          to: props[key],
        };
      }
    });

    if (Object.keys(propChanges).length) {
      console.group(`[Почему обновился компонент]: ${name}`);
      if (Object.keys(propChanges).length) {
        console.log("Изменения в пропсах:", propChanges);
      }

      console.groupEnd();
    }

    previousProps.current = props;
  });
}
