import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ImageBroadcastManager from '../../Broadcasts/ImageBroadcastManager';



export const Base64View: React.FC = () => {
    const [base64String, setBase64String] = useState<string | null>(null);
    const broadcastManager = useMemo(() => ImageBroadcastManager.getInstance(), [])

    // Функция для извлечения параметров из URL (в данном случае UUID)
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    const query = useQuery();
    const uuid = query.get('uuid'); // Получаем UUID из query параметра

    useEffect(() => {
        if (!uuid) {
            return;
        }
        // Подписываемся на получение изображения
        new Promise<void>(res => broadcastManager.onMessage((action, data, responseUuid) => {

            if (action === 'saveImage' && responseUuid === uuid) {
                setBase64String(data);
            }
            res()
        }));
        broadcastManager.requestImage(uuid);
        // Отправляем запрос на получение изображения с указанным UUID



    }, [broadcastManager, uuid]);




    if (!base64String) {
        return <div>Waiting for image...</div>; // Ждем получения изображения
    }

    return (
        <div>
            <h1>Base64 Image</h1>
            <img src={base64String} alt="Base64 Image" />
        </div>
    );
};
