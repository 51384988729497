import { css } from "@emotion/css"
import { Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import { cloneDeep } from "lodash"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import LoadingComponent from "../../../../../../Components/Loader/index"
import FieldSet, { FieldSetInputType } from "../../../../../../Components/_BaseUI/FieldSet/index"
import Tabs, { TabItemInterface } from "../../../../../../Components/_Layout/Tabs/index"
import { useTemporaryApplyStitchingConfig } from "../../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useTemporaryApplyStichingConfig"
import { useCameraCommonSettings } from "../../../../../../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings"
import { useCameras } from "../../../../../../Data/NapiCameraHandlerV2/hooks/useCameras"
import { useAsync } from "../../../../../../hooks/useAsync"
import { useFirstDefinedValue } from "../../../../../../hooks/useFirstDefined"
import LOCALIZATION from "../../../../../../Localization/index"
import { trimByPattern } from "../../../../../../Tools/trimByPattern"
import { useSaveRawCoefficent } from "../useSaveCylinderCoefficent"

import CropResolver from "./CropResolver"
import { PanelAction, usePanelActions } from "./usePanelActions"

interface IProps {
    camera: number;
}

const ZoneForm: React.FC<IProps> = memo(({ camera }) => {
    const { formatMessage } = useIntl()
    const { data: commonCameraInfo } = useCameraCommonSettings()
    const meta = useMemo(() => {
        if (!commonCameraInfo) {
            return;
        }
        return ({ width: commonCameraInfo?.configState.config.camera.width, height: commonCameraInfo?.configState.config.camera.height })
    }, [commonCameraInfo])
    const [sourceImage, setSourceImage] = useState<[number, number]>();
    useEffect(() => {
        if (!meta?.width || !meta?.height) {
            return;
        }
        setSourceImage([meta?.width, meta?.height])
    }, [meta])
    const { state, set } = useSaveRawCoefficent()
    const firstState = useFirstDefinedValue(state)
    const [blendingWidth, setBlendingWidth] = useState<number>(state.config?.stitching?.["blend-width"] ?? 0)
    useEffect(() => {
        firstState?.config?.stitching?.["blend-width"] && setBlendingWidth(firstState?.config?.stitching?.["blend-width"])
    }, [firstState])

    const [stretching, setStretching] = useState<string>('')
    const { fit, fullSize, update, test, lastAction } = usePanelActions()
    const { mutateAsync: tempApply, isLoading: applingConfig } = useTemporaryApplyStitchingConfig()
    useEffect(() => {
        if (lastAction?.action === PanelAction.test) {
            tempApply(state.config).then(() => {
                update()

            });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastAction,])

    const setBlendingWidthCallback = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = Number(e.target.value)
        setBlendingWidth(value)
    }, [])
    const setStretchingCallback = useCallback((value: string) => {
        setStretching(value)
    }, [])

    const { data: cameras } = useCameras()

    const cropResolver = useMemo(() => {
        const crop = firstState?.config?.crop
        if (!meta || !firstState) {
            return
        }
        return new CropResolver([meta.width, meta.height], {
            ...{
                "dest-x": -1,
                "dest-y": 0,
                "src-x": 0,
                "src-y": 0,
                width: 0,
                height: 0,
            }, ...crop?.[camera]
        })
    }, [firstState, meta?.width, meta?.height])

    const aclc = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        return Number(v.target.value);
    }, [])

    const setSourceCropImageLeftCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        cropResolver && cropResolver.setCropLeft(aclc(v));
    }, [aclc, cropResolver])

    const setSourceCropTopImageCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        cropResolver && cropResolver.setCropTop(aclc(v));
    }, [aclc, cropResolver])

    const setSourceCropRightImageCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        cropResolver && cropResolver.setCropRight(aclc(v));
    }, [aclc, cropResolver])

    const setSourceCropBottomImageCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        cropResolver && cropResolver.setCropBottom(aclc(v));
    }, [aclc, cropResolver])

    const setPanoramaOutputXCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<"1" | "-1">) => {
        const value = Number(v.target.value);
        cropResolver && cropResolver.setDestX(value);
    }, [cropResolver])

    const setPanoramaOutputYCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = aclc(v);
        cropResolver && cropResolver.setDestY(value);
    }, [aclc, cropResolver])

    const setPanoramaOutputWidthCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = aclc(v);
        if (!meta || !cropResolver) {
            return;
        }
        cropResolver.setCropRight(meta.width - value);
    }, [aclc, meta, cropResolver])

    const setPanoramaOutputHeightCallback = useCallback((v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = aclc(v);
        if (!meta || !cropResolver) {
            return;
        }
        cropResolver.setCropBottom(meta.height - value);
    }, [aclc, meta, cropResolver])

    const sourceCropLeftImage = useAsync({ observable: cropResolver?.cropLeft$ })
    const sourceCropTopImage = useAsync({ observable: cropResolver?.cropTop$ })
    const sourceCropRightImage = useAsync({ observable: cropResolver?.cropRight$ })
    const sourceCropBottomImage = useAsync({ observable: cropResolver?.cropBottom$ })
    const panoramaOutputX = useAsync({ observable: cropResolver?.destX$ })
    const panoramaOutputY = useAsync({ observable: cropResolver?.destY$ })
    const { width: panoramaOutputWidth, height: panoramaOutputHeight } = useAsync({ observable: cropResolver?.outputSize$ }) ?? {}

    useEffect(() => {
        if (!cropResolver) return;
        console.log(sourceCropLeftImage)
        let nstate = cloneDeep(state)
        if (!nstate.config) {
            nstate.config = {};
        }
        nstate.config.crop = cameras.map((_, index) => {
            if (index === camera) {
                return {
                    "src-x": cropResolver.cropLeft,
                    "src-y": cropResolver.cropTop,
                    "width": -cropResolver.cropRight,
                    "height": -cropResolver.cropBottom,
                    "dest-x": cropResolver.destX,
                    "dest-y": cropResolver.destY,
                };
            }
            return state.config?.crop?.[index] || {};
        });
        if (!nstate.config?.stitching?.["blend-width"]) {
            nstate.config.stitching = { "blend-width": 16 };
        }
        nstate.config.stitching["blend-width"] = blendingWidth;
        if ((new RegExp(/^(([-]?\d+([.]\d*)?[,])){2}([-]?\d+([.]\d*)?)$/)).test(stretching)) {
            if (!nstate.config?.cylinder?.[camera]?.stretch) {
                nstate.config.cylinder = (new Array(cameras.length)).fill({
                    stretch: []
                })
            }
            //@ts-ignore
            nstate.config.cylinder[camera].stretch = stretching.split(',').map(Number) as [number, number, number];
        }
        set(nstate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panoramaOutputWidth, panoramaOutputHeight, cropResolver, blendingWidth, sourceCropLeftImage, sourceCropTopImage, sourceCropRightImage, sourceCropBottomImage, panoramaOutputX, panoramaOutputY])


    return <>
        <LoadingComponent ugly pretendChildrenInitializing isLoading={!state || !meta?.height || !meta?.width || !cropResolver || applingConfig}>
            <Grid xs={12} className={css`
            width: 100%;
        `}>

                <FieldSet
                    key={'source_image_size'}
                    label={formatMessage({ id: LOCALIZATION.source_image_size })}
                    // helperText={ 'Short team name' }
                    // tooltipQuestionText={ props.intl.formatMessage({ id: LOCALIZATION.event_game_type_help }) }
                    input={{
                        type: FieldSetInputType.StaticLabel,
                        value: sourceImage ? `${sourceImage?.[0]} x ${sourceImage?.[1]}` : formatMessage({ id: LOCALIZATION.loading_data }),
                    }}
                />

                <FieldSet
                    key={'blendingWidth'}
                    label={formatMessage({ id: LOCALIZATION.blending_width })}
                    tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.blending_tooltip_question })} `}

                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={blendingWidth}
                            onChange={setBlendingWidthCallback}
                            inputProps={{
                                min: -1, className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />

                <FieldSet
                    hideSeparator
                    key={'sourceCropLeftImage'}
                    label={formatMessage({ id: LOCALIZATION.source_crop_left })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={sourceCropLeftImage}
                            onChange={setSourceCropImageLeftCallback}
                            inputProps={{
                                min: 0, className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />




                <FieldSet
                    hideSeparator
                    key={'sourceCropTopImage'}
                    label={formatMessage({ id: LOCALIZATION.source_crop_top })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={sourceCropTopImage}
                            onChange={setSourceCropTopImageCallback}
                            inputProps={{
                                min: 0, className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />
                <FieldSet
                    hideSeparator
                    key={'sourceCropRightImage'}
                    label={formatMessage({ id: LOCALIZATION.source_crop_right })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={sourceCropRightImage}
                            onChange={setSourceCropRightImageCallback}
                            inputProps={{
                                min: 0, className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />
                <FieldSet
                    key={'sourceCropBottomImage'}
                    label={formatMessage({ id: LOCALIZATION.source_crop_bottom })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={sourceCropBottomImage}
                            onChange={setSourceCropBottomImageCallback}
                            inputProps={{
                                min: 0, className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />







                <FieldSet
                    hideSeparator
                    key={'panoramaOutputX'}
                    label={formatMessage({ id: LOCALIZATION.panorama_output_x })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<Grid gap={1} display={"flex"} container>
                            <Grid item xs={5}><TextField
                                fullWidth
                                type={FieldSetInputType.Number}
                                variant="outlined"
                                value={panoramaOutputX}
                                onChange={setPanoramaOutputXCallback}
                                inputProps={{
                                    min: -1, className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                            />
                            </Grid>
                            <Grid xs item>

                                <Select fullWidth value={panoramaOutputX === -1 ? '-1' : '1'}
                                    inputProps={{
                                        className: css`
                                      padding: 8px !important;
                                    width: max-content;
                                ` }}
                                    onChange={setPanoramaOutputXCallback}

                                >
                                    <MenuItem
                                        value={'-1'}
                                    >
                                        {formatMessage({ id: LOCALIZATION.snap_left })}
                                    </MenuItem>
                                    <MenuItem
                                        value={'1'}
                                    >
                                        {formatMessage({ id: LOCALIZATION.absolute })}
                                    </MenuItem>
                                </Select></Grid></Grid>)
                    }}
                />


                <FieldSet
                    hideSeparator
                    key={'panoramaOutputY'}
                    label={formatMessage({ id: LOCALIZATION.panorama_output_y })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={panoramaOutputY}
                            onChange={setPanoramaOutputYCallback}
                            inputProps={{
                                min: -1, className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />


                <FieldSet
                    hideSeparator
                    key={'panoramaOutputWidth'}
                    label={formatMessage({ id: LOCALIZATION.panorama_output_width })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={panoramaOutputWidth}
                            onChange={setPanoramaOutputWidthCallback}
                            inputProps={{
                                max: meta?.width || undefined
                                , className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />

                <FieldSet
                    key={'panoramaOutputHeight'}
                    label={formatMessage({ id: LOCALIZATION.panorama_output_height })}
                    input={{
                        type: FieldSetInputType.Other,
                        children: (<TextField
                            fullWidth
                            type={FieldSetInputType.Number}
                            variant="outlined"
                            value={panoramaOutputHeight}
                            onChange={setPanoramaOutputHeightCallback}
                            inputProps={{
                                max: meta?.height || undefined
                                , className: css`
                                height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 38px;
                        ` }}
                        />)
                    }}
                />

                <FieldSet
                    inputSize
                    labelSize={6}
                    hideSeparator
                    label={`${formatMessage({ id: LOCALIZATION.vertical_stretching })}`}
                    tooltipQuestionText={`${formatMessage({ id: LOCALIZATION.vertical_stretching_desc })} `}
                    onHintTooltipText={`${formatMessage({ id: LOCALIZATION.prop_will_not_saved_with_wrong_value })}. ${formatMessage({ id: LOCALIZATION.format }).capitalizeFirstLetter()} : 0, 0, 0`}
                    input={{
                        placeholder: "0, 0, 0",
                        type: FieldSetInputType.Text, value: stretching, onChange: (v) => setStretchingCallback(trimByPattern(String(v).replace(/[^\d.,-]/g, ''), /^(([-]?\d+([.]\d*)?[,])){2}([-]?\d+([.]\d*)?)/)), hintCheckPattern: (value: string) => {
                            return !!value.length && !(new RegExp(/^(([-]?\d+([.]\d*)?[,])){2}([-]?\d+([.]\d*)?)/)).test(value)
                        },
                    }}
                ></FieldSet>
            </Grid>
        </LoadingComponent>

    </>

})

export const Form = () => {
    const [selectedTabID, setSelectedTabID] = useState<number>(0);
    const { formatMessage } = useIntl();
    const TabsArr: TabItemInterface[] = useMemo(() => {
        return [
            {
                id: 0,
                Content: ZoneForm,
                title: formatMessage({ id: LOCALIZATION.left }),
            },
            {
                id: 1,
                Content: ZoneForm,
                title: formatMessage({ id: LOCALIZATION.right }),
            },
        ]
    }, [])

    const renderContent = () => (TabsArr.map((tab: TabItemInterface) => {
        const hidden = (selectedTabID !== tab.id);

        if (hidden) {
            return null;
        }

        const Content = TabsArr[tab.id].Content;

        return (
            <Typography
                key={tab.id}
                component="div"
                role="tabpanel"
                id={`simple-tab-${tab.id}`}
                aria-labelledby={`simple-tabpanel-${tab.id}`}
            >
                <Content
                    camera={tab.id}
                />
            </Typography>
        )
    }));

    return <>
        <Tabs
            itemClass={css`
            width: 50%;
            max-width: none;
            `}
            selectedTabID={selectedTabID}
            onChangeTab={setSelectedTabID}
            items={TabsArr} />
        {renderContent()}
    </>
}