import { cloneDeep, merge } from "lodash";
import { createContext, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import LoadingComponent from "../../../../../Components/Loader/index";
import { StitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/StitchinConfig.interface";
import { useStitchingConfig } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useStichingConfig";
import { useFirstDefinedValue } from "../../../../../hooks/useFirstDefined";


type PanoramaState = Partial<StitchingConfig>

const defaultInitialValue: PanoramaState = {

}


const RawFormContext = createContext<{ state: PanoramaState, setState: React.Dispatch<React.SetStateAction<PanoramaState>> }>(
    {
        state: defaultInitialValue,
        setState: function(value: SetStateAction<PanoramaState>): void {
            throw new Error("Function not implemented.");
        }
    });


export const RawSaveItemsStateProvider: React.FC<{ children: ReactNode, uuid: string, initialValue?: PanoramaState }> = ({ children, uuid, initialValue = defaultInitialValue }) => {
    const [state, setState] = useState<PanoramaState>(initialValue);
    const { data: remoteConfig } = useStitchingConfig({ uuid })
    const baseConfig = useFirstDefinedValue(remoteConfig)

    useEffect(() => {
        setState(cloneDeep(baseConfig ?? {}))
    }, [baseConfig,]);


    return (<>
        {state.toString()}
        <LoadingComponent ugly pretendChildrenInitializing isLoading={!state?.uuid}>
            <RawFormContext.Provider value={{ state, setState }}>

                {children}

            </RawFormContext.Provider>
        </LoadingComponent>
    </>
    );
};




export const useSaveRawCoefficent = () => {
    const { state, setState } = useContext(RawFormContext);
    const setStateCallback = useCallback((newPatch: Partial<PanoramaState>) => {
        setState(prev => merge(JSON.parse(JSON.stringify(prev)), JSON.parse(JSON.stringify(newPatch))))
    }, [setState]);

    return { state, patch: setStateCallback, set: setState, };
};