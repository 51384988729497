import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
    Area, AreaChart, CartesianGrid, DotProps, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis
} from "recharts";
import { BreakpointEnum, useWidth } from "../../hooks/useWidth";
import LOCALIZATION from "../../Localization/index";
import { GreenLine } from "./GreenLine";
import { RedLine } from "./RedLine";

const ChartContainer = styled.div`
  width: 100%;
  height: 400px;
`;

// Custom Dot component with proper typing
const CustomDot: React.FC<DotProps> = (props) => {
    const { cx, cy } = props;
    return cx && cy ? <circle cx={cx} cy={cy} r={5} fill="none" stroke="none" /> : null;
};

// Custom Tooltip component with proper typing
interface CustomTooltipProps extends TooltipProps<number, string> { }

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload: datakeys, label }) => {
    const { formatMessage, locale } = useIntl();
    if (active && datakeys && datakeys.length) {
        const max = datakeys.find((v) => v?.dataKey === "max")?.value;
        const min = datakeys.find((v) => v?.dataKey === "min")?.value;
        const average = datakeys.find((v) => v?.dataKey === "average")?.value;
        const event = datakeys.find((v) => Boolean(v?.payload.event))?.payload?.event;
        const event_id = datakeys.find((v) => Boolean(v?.payload.event_id))?.payload?.event_id;
        return (
            <div
                style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid #ccc",
                    padding: "10px",
                    color: "#333"
                }}
            >
                <p style={{ margin: 0, fontWeight: "bold" }}>{`${formatMessage({ id: LOCALIZATION.time }).capitalizeFirstLetter()}: ${moment(label).locale(locale).format('LLL')}`}</p>
                <p style={{ margin: 0 }}>{`${formatMessage({ id: LOCALIZATION.average }).capitalizeFirstLetter()}: ${average}`}</p>
                <p style={{ margin: 0 }}>{`${formatMessage({ id: LOCALIZATION.max }).capitalizeFirstLetter()}: ${max}`}</p>
                <p style={{ margin: 0 }}>{`${formatMessage({ id: LOCALIZATION.min }).capitalizeFirstLetter()}:${min}`}</p>
                {event && <p style={{ margin: 0 }}>{`${formatMessage({ id: LOCALIZATION.event }).capitalizeFirstLetter()}: ${event}`}</p>}
                {event_id && event_id !== 'undefined' && <p style={{ margin: 0 }}>{`${formatMessage({ id: LOCALIZATION.identifier }).capitalizeFirstLetter()}: ${event_id}`}</p>}
            </div>
        );
    }

    return null;
};

export const PlaygroundAttendance: React.FC<{
    data: {
        time: string;
        average: number;
        max: number;
        event_id?: string;
        min: number;
    }[],
    /** in minutes */
    periods: number;
}> = ({ data, periods }) => {
    const { formatMessage } = useIntl()
    const formattedData = useMemo(() => {
        let prevEventId: string | undefined; let prevTime: moment.Moment | null = null;
        return data.map((entry) => {
            const currentTime = moment(entry.time);
            let diff = prevTime ? currentTime.diff(prevTime, 'minutes') : 0;

            prevTime = currentTime;

            // Если разрыв больше 30 минут, добавляем null
            if (diff > periods) {
                return [{ time: undefined, average: undefined, max: undefined, min: undefined, event_id: undefined }, entry];
            }

            return entry;
        }).flat().map((entry) => {
            const showEventId = prevEventId !== entry.event_id;
            prevEventId = entry.event_id;
            entry.time = moment(entry.time).toLocaleString();
            return { ...entry, showEventId };
        });
    }, [data, periods]);

    const groupedEventIds = useMemo(() => {
        const grouped: { event_id?: string; startIndex: number; endIndex: number }[] = [];
        let currentGroup: { event_id?: string; startIndex: number; endIndex: number } | null = null;

        formattedData.forEach((entry, index) => {
            if (currentGroup && currentGroup.event_id === entry.event_id) {
                currentGroup.endIndex = index;
            } else {
                if (currentGroup) {
                    grouped.push(currentGroup);
                }
                currentGroup = { event_id: entry.event_id, startIndex: index, endIndex: index };
            }
        });

        if (currentGroup) {
            grouped.push(currentGroup);
        }

        return grouped;
    }, [formattedData]);

    const breakpoint = useWidth();

    const fontSize = useMemo(() => {
        switch (breakpoint) {
            case BreakpointEnum.xl:
                return 14;
            case BreakpointEnum.lg:
                return 12;
            case BreakpointEnum.md:
                return 10;
            case BreakpointEnum.sm:
                return 8;
            case BreakpointEnum.xs:
                return 6;
            default:
                return 4;
        }
    }, [breakpoint]);

    const containerRef = useRef<HTMLDivElement>(null);
    const [tickInterval, setTickInterval] = useState(1);

    useEffect(() => {
        const updateTickInterval = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const maxTicks = Math.floor(containerWidth / 60); // Минимальная ширина для одной метки — 60 пикселей
                setTickInterval(Math.ceil(formattedData.length / maxTicks));
            }
        };

        window.addEventListener('resize', updateTickInterval);
        updateTickInterval();

        return () => window.removeEventListener('resize', updateTickInterval);
    }, [formattedData]);

    return (
        <Grid padding={3} container>
            <ChartContainer ref={containerRef}>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        data={formattedData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
                    >
                        <YAxis label={{ angle: -90, position: "insideLeft" }} />
                        <XAxis
                            dataKey="time"
                            interval={tickInterval}
                            tickMargin={15}
                            height={60}
                            tick={(props) => {
                                const { x, y, payload } = props;
                                const timeLabel = moment(payload.value).format('HH:mm');

                                return (
                                    <g transform={`translate(${x},${y})`}>
                                        <text
                                            x={0}
                                            y={0}
                                            dy={16}
                                            textAnchor="start"
                                            transform="rotate(10)"
                                        >
                                            {`${timeLabel}`}
                                        </text>
                                    </g>
                                );
                            }}
                        />

                        <Tooltip content={<CustomTooltip />} />
                        <Area
                            type="monotone"
                            dataKey="max"
                            stroke="none"
                            fill="rgba(72, 190, 126, 0.3)"
                            activeDot={<CustomDot />}
                            fillOpacity={1}
                        />
                        <Area
                            type="monotone"
                            dataKey="min"
                            stroke="none"
                            fill="rgba(255, 255, 255, 1)"
                            activeDot={<CustomDot />}
                            fillOpacity={1}
                        />
                        <Area
                            type="monotone"
                            dataKey="average"
                            stroke="red"
                            strokeWidth={2}
                            fill="transparent"
                            dot={<CustomDot />}
                        />
                        <CartesianGrid strokeDasharray="3 3" />
                    </AreaChart>
                </ResponsiveContainer>

            </ChartContainer>
            <Grid container gap={2} className={css`display: flex; align-items: center; justify-content: center;`}>
                <Grid item >


                    <span><RedLine className={css`margin-right: 5px;`} ></RedLine>{formatMessage({ id: LOCALIZATION.average_attendance })}</span>
                </Grid>
                <Grid item  >
                    <span > <GreenLine className={css`margin-right: 5px;`}></GreenLine>
                        {formatMessage({ id: LOCALIZATION.minimum_maximum })}</span>

                </Grid>


            </Grid>
        </Grid>
    );
};