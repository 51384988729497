import { createContext, ReactNode, useCallback, useContext, useEffect } from "react";
import { BehaviorSubject } from "rxjs";
import { intl } from "../Localization/LocalizationProvider/index";
import { useAsync } from "./useAsync";


export enum ThemeVariables {
    font = "--font-scheme",
}



type Theme = { [key in ThemeVariables]?: string }

export enum ThemeType {
    COMMON = "common",
    CHINA = "china",
}

interface ThemeContextProps {
    theme: ThemeType;
    toggleTheme: (v: ThemeType) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
    theme: ThemeType.COMMON,
    toggleTheme: function(v: ThemeType): void {
        throw new Error("Function not implemented.");
    }
});

const lightTheme: Theme = {
    [ThemeVariables.font]: "Montserrat",

};

const darkTheme: Theme = {
    [ThemeVariables.font]: "NotoSansSC",

};

const registeredThemes = {
    [ThemeType.CHINA]: darkTheme,
    [ThemeType.COMMON]: lightTheme,
}

export const theme$ = new BehaviorSubject(intl().locale === "cn" ? ThemeType.CHINA : ThemeType.COMMON);


export const CustomThemeProvider = (({ children }: { children: ReactNode }) => {

    const theme = useAsync({ observable: theme$ }) ?? ThemeType.COMMON;
    const setTheme = (v: ThemeType) => theme$.next(v)

    const toggleTheme = useCallback((v: ThemeType) => {
        setTheme(v);
    }, []);

    useEffect(() => {
        const secifiedTheme = registeredThemes[theme];
        for (const key in secifiedTheme) {
            const property = secifiedTheme[key as ThemeVariables];

            if (property) {
                document.documentElement.style.setProperty(key, property);

            }
        }

    }, [theme]);

    useEffect(() => {
        //@ts-ignore
        window!.toggleTheme = toggleTheme
    }, [toggleTheme])

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
});


export const useTheme = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);
    return { theme, toggleTheme }
}