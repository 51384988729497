import { useMemo } from "react";
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { getJavaBackend } from "../../../../Configuration/ENV/index";
import { NODE_JS_BACKEND_URL } from "../../../../constants";
import { getAccessToken } from "../../../../Tools/token";
import { useCameraSettings } from "../../../Camera/HWCameraSettings/hook/Hooks";

type CollectionImagesMeta = { camera: number; src: string }[];

async function fetchCollectionImagesMeta(uuid: string): Promise<string[]> {
  const response = await fetch(
    `${NODE_JS_BACKEND_URL}/stitching-images/${uuid}`,
    {
      headers: {
        Authorization: getAccessToken() ?? "",
        "X-API-VERSION": "1",
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Ошибка: ${response.statusText}`);
  }

  const data = await response.json();
  return data;
}

interface UseCollectionImagesMetaOptions {
  uuid: string;
  options?: Omit<
    UseQueryOptions<CollectionImagesMeta, Error>,
    "queryKey" | "queryFn"
  >;
}

const CACHE_KEY = "useStichingImages";

export function useStichingImages({
  uuid,
  options,
}: UseCollectionImagesMetaOptions) {
  const queryClient = useQueryClient();
  const queryKey: QueryKey = [CACHE_KEY, uuid];
  const { protocol, host, port } = new URL(getJavaBackend());
  const cameraSettings = useCameraSettings(true);

  const eventsImageTemplate = useMemo(
    () => cameraSettings?.urls?.eventsImageTemplate,
    [cameraSettings?.urls?.eventsImageTemplate]
  );

  const query = useQuery<CollectionImagesMeta, Error>(
    queryKey,
    () =>
      fetchCollectionImagesMeta(uuid).then((v) =>
        Promise.all(
          v.map((a) => {
            const src = `${`${protocol}//${host}:${port}`}${eventsImageTemplate?.replace(
              "{events_image_path}",
              `/tmp_${uuid}/${a}`
            )}`;
            const camera = Number(src.match(/shot_(\d+)\.jpeg/)?.[1]);
            return {
              src,
              camera,
            };
          })
        )
      ),
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: true,
      ...options,
    }
  );

  function clearCache() {
    queryClient.invalidateQueries(queryKey);
  }

  return { ...query, clearCache };
}
