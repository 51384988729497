// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Grid } from '@mui/material';
import React, { memo, useMemo } from 'react';

import Camera from '../../Data/Camera/ActiveCamera/Camera';
import { useCamerasHardwareStatusesList } from '../../Data/Camera/RemoteCameraState/hook';
import { DeviceType, useDevice } from '../../hooks/useDevice';
import { BreakpointEnum, useWidth } from '../../hooks/useWidth';
import CameraCardBlock from './CameraCardBlock';


type Props = Readonly<{
  camera: Camera;
}>;


const ActiveCamera: React.FC<Props> = (props: Props) => {
  const camerasStates = useCamerasHardwareStatusesList({
    enableToFetch: true,
    page: 0,
    filterByCameraID: props.camera.cameraId
  });
  const cameraHardware = camerasStates.data?.data.find(({ cameraId }) => (cameraId === props.camera.cameraId)) || props.camera.state;
  const deviceType = useDevice()

  const width = useWidth()

  /**
   * Compact mode for all cards
   */
  const areCompactCards = useMemo(() => {
    return (deviceType === DeviceType.Mobile) || (width <= BreakpointEnum.sm)
  }, [deviceType, width])
  return (
    <Grid
      item
      xs={ 12 }
    >
      <CameraCardBlock
        isServer
        compact={areCompactCards}
        hideActiveCameraBorder={true}
        camera={props.camera}
        activeCamera={ props.camera }
        cameraHardware={ cameraHardware }
      />
    </Grid>
  );
};


export default memo(ActiveCamera);
