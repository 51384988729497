// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { createStyles, makeStyles } from '@mui/styles';

import { Colors } from "../../../Configuration/Styles/Colors";
import { FontName } from "../../../Configuration/Styles/Fonts";
import { Sizes } from "../../../Configuration/Styles/Sizes";

const useStyles = makeStyles(() =>
  createStyles<any, { fontFamily: FontName }>({
    regularText: {
      paddingTop: 1,
      paddingBottom: 1,
      color: Colors.mainTitle,
      fontSize: Sizes.subtitle,
      fontFamily: ({ fontFamily }) => fontFamily,
    },
    icon: {
      display: "flex",
      width: 12,
      height: 12,
      borderRadius: "50%",
    },
    iconContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);


export default useStyles;
