// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Divider, FormControlLabel, ListItem, ListItemSecondaryAction, Radio } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import MenuDropdownButton from '../../../Components/_BaseUI/MenuDropdownButton';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import StreamingProfile from '../../../Data/NapiStreamingProfile';
import { NoStream, NO_STREAM_ID } from '../../../Data/NapiStreamingProfile/constants';


type Props = Readonly<{
  checkboxDisabled: boolean;
  optionsDisabled: boolean;
  selectedProfileID: string;
  item: StreamingProfile | NoStream;
  onSelect: (item: StreamingProfile | NoStream) => void;
  onEdit?: (item: StreamingProfile) => void;
  onCopy?: (item: StreamingProfile) => void;
  onDelete?: (item: StreamingProfile) => void;
}>;


const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  item: {
    minHeight: '56px',
    // borderBottom: 'solid 1px #C2CFE0',

    '&:hover': {
      backgroundColor: '#C2CFE0',
    },
  },
}));

const StreamSettingsProfileItem: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font });

  const handleChangeSelectedProfile = () => {
    props.onSelect(props.item);
  };

  return (
    <>
      <ListItem
        id={ `radio-label-${ props.item.id }` }
        className={ classes.item }
      >
        <FormControlLabel
          disabled={ props.checkboxDisabled }
          aria-labelledby={ `radio-label-${ props.item.id }` }
          value={ props.item.id }
          label={ props.item.name }
          control={ <Radio color="primary" /> }
          onChange={ handleChangeSelectedProfile }
        />
        {
          (props.item.id !== NO_STREAM_ID && !props.checkboxDisabled) &&
            <ListItemSecondaryAction>
              <MenuDropdownButton<StreamingProfile>
                item={ props.item as StreamingProfile }
                onEdit={ props.onEdit }
                onCopy={ props.onCopy }
                onDelete={ (props.selectedProfileID === props.item.id) ? undefined : props.onDelete }
              />
            </ListItemSecondaryAction>
        }
      </ListItem>
      <Divider/>
    </>
  );
};


export default StreamSettingsProfileItem;
