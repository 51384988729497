// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { useEffect } from 'react';
import ServicePopup from './ServicePopup';


type Props = Readonly<{}>;


const CODE = '105501';
declare global {
  interface Window {
    setIsServicePopupVisible: (isVisible: boolean) => void;
  }
}
const ManagerServicePopup: React.FC<Props> = (props: Props) => {
  let timeout = React.useRef<NodeJS.Timeout>(null).current;
  let codePool = React.useRef<string>('').current;
  const [isServicePopupVisible, setIsServicePopupVisible] = React.useState<boolean>(false);
  useEffect(() => {
    window.setIsServicePopupVisible = setIsServicePopupVisible;
  }, [])
  const clearCodePool = (): void => { codePool = ''; };
  const addServiceCode = (event: KeyboardEvent): void => {
    const { charCode = null } = event;

    if (charCode !== null) {
      codePool = (codePool + String.fromCharCode(charCode)).substr(-CODE.length);

      if (codePool === CODE) {
        timeout = setTimeout(clearCodePool, 5000);

        setIsServicePopupVisible(true);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('keypress', addServiceCode);

    return () => {
      window.removeEventListener('keypress', addServiceCode);

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isServicePopupVisible) { return null; }

  const handleServicePopupClosed = () => {
    setIsServicePopupVisible(false);
  };

  return (
    <ServicePopup
      onClose={ handleServicePopupClosed }
    />
  );
};


export default ManagerServicePopup;
