import { useEffect, useState } from "react"

export const usePromise = <T = any>(func: () => Promise<T>, depends: any[]) => {
    const [res, setRes] = useState<T>()

    useEffect(() => {
        func().then((v) => setRes(v))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...depends])
    return res;
}