import { useMemo } from "react";
import { useCameraCommonSettings } from "../useCameraCommonSettings";
import { useGetZonePoints } from "./useGetZonePoints";

const useIteration = <Z, V>(callback: (i: Z) => V, iterationsParams: Z[]) => {
  const res: V[] = [];
  for (const iterator of iterationsParams) {
    res.push(callback(iterator));
  }
  return res;
};

export const useZonePointCounts = ({ uuid }: { uuid: string }) => {
  const { data: commonSettings } = useCameraCommonSettings();
  const zoneCounts =
    Number(commonSettings?.configState.config.camera["num-cameras"]) - 1;
  const zonesInfo = useIteration(
    useGetZonePoints,
    Array(zoneCounts)
      .fill(0)
      .map((v, i) => ({ indexes: [i, i + 1] as [number, number], uuid }))
  );
  const isLoading = useMemo(() => {
    if (zonesInfo) return Boolean(zonesInfo.find((v) => v.isLoading));
  }, [zonesInfo]);
  const data = useMemo(() => {
    if (isLoading) {
      return;
    }
    return zonesInfo.map((v) => v.data?.[0].length as number);
  }, [isLoading, zonesInfo]);
  return { isLoading, data };
};
