import { useCallback, useMemo } from "react";
import { getJavaBackend } from "../Configuration/ENV/index";
import { useCameraSettings } from "../Data/Camera/HWCameraSettings/hook/Hooks";
import { useCameraCommonSettings } from "../Data/NapiCameraHandlerV2/hooks/useCameraCommonSettings";
import { useIsProd } from "./useIsProd";

const convertImageToBlob = async (src: string) => {
  const imageElement = document.createElement("img");
  imageElement.src = src;
  imageElement.crossOrigin = "anonymous";
  // Wait for the image to load
  await new Promise((resolve, reject) => {
    imageElement.onstalled = () => {
      imageElement.remove();
      reject();
    };
    imageElement.onload = resolve;
  });

  // Convert image to base64 data URL
  const canvas = document.createElement("canvas");
  canvas.width = imageElement.width;
  canvas.height = imageElement.height;
  const context = canvas.getContext("2d");
  context?.drawImage(imageElement, 0, 0);
  const base64DataUrl = await new Promise<Blob | null>((res) =>
    canvas.toBlob((blob) => res(blob), "image/jpeg", 1.0)
  );
  imageElement.remove();
  canvas.remove();
  return base64DataUrl;
};

export const useAllCameraShots = (props: {
  width?: number;
  height?: number;
}) => {
  const cameraSettings = useCameraSettings(true);
  const { data: common } = useCameraCommonSettings();
  const liveImageTemplate = useMemo(
    () => cameraSettings?.urls?.imageFileTemplate,
    [cameraSettings?.urls?.imageFileTemplate]
  );
  const isProd = useIsProd();
  const fetch = useCallback(
    () =>
      Promise.all(
        new Array(common?.configState.config.camera["num-cameras"])
          .fill(0)
          .map((_, camera) => {
            const { protocol, host, port } = new URL(getJavaBackend());
            return `${`${protocol}//${host}:${port}`}${liveImageTemplate?.replace(
              "{storage_file_path}",
              `camera${camera.toString()}.jpeg`
            )}`;
          })
          .map(async (src, index) => ({
            camera: index,
            blob: await convertImageToBlob(src),
          }))
      ),
    [common?.configState.config.camera, isProd, liveImageTemplate]
  );

  return { fetch };
};
