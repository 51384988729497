import { css } from "@emotion/css";
import { ReactNode } from "react";
import { Cell } from "./Cell";

const BoxTemplate = (props: { children?: ReactNode, }) => {
    const { children, } = props;

    return <>
        <div className={
            css`
              display: flex;
    max-height: 100%;
    aspect-ratio: 16/9;
    width: fit-content;
    height: 100%;
    position: relative;
    margin: auto;`
        }>        {children}
            <div style={{
                position: "absolute",
            }}>


            </div>
            <Cell left={5} top={5}></Cell>
            <Cell left={5} horizontalCentering ></Cell>
            <Cell left={5} top={75}></Cell>

            <Cell verticalCentering top={5}></Cell>
            <Cell horizontalCentering verticalCentering></Cell>
            <Cell verticalCentering top={75}></Cell>
            <Cell left={50} top={55}></Cell>



            <Cell left={75} top={5}></Cell>
            <Cell left={75} horizontalCentering ></Cell>
            <Cell left={75} top={75}></Cell>
        </div>

    </>
}

export function withBoxTemplate<T>(Component: React.ComponentType<T>,) {
    return (props: T) => <BoxTemplate><Component  {...props} /></BoxTemplate>
};

