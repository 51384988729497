import styled from "@emotion/styled"
import { Grid } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { filter, fromEvent, map } from "rxjs"
import { v4 } from "uuid"
import LoadingComponent from "../../../../../../Components/Loader"
import MainButton from "../../../../../../Components/_BaseUI/MainButton"
import { getJavaBackend } from "../../../../../../Configuration/ENV/index"
import { useCurrentFont } from "../../../../../../Configuration/Styles/Fonts"
import { useCameraSettings } from "../../../../../../Data/Camera/HWCameraSettings/hook/Hooks"
import { useGetUndistordImagesMeta } from "../../../../../../Data/NapiCameraHandlerV2/hooks/undistord/useGetUndistordImagesMeta"
import { withOpenBlank } from "../../../../../../hocs/withOpenBlank"
import { useAsync } from "../../../../../../hooks/useAsync"
import { useFilesSelector } from "../../../../../../hooks/useFilesSelector"
import { useIsProd } from "../../../../../../hooks/useIsProd"
import { useObjectChanged } from "../../../../../../hooks/useObjectChanged"
import { usePrevious } from "../../../../../../hooks/usePrevious"
import { CrossIcon, FIcon } from "../../../../../../Icons/Icons"
import LOCALIZATION from "../../../../../../Localization"
import { AddImageModal } from "./AddImageModal"




const ImageHolder = withOpenBlank(styled.img`
     background-color: #E5E5E5;
    border: solid 1px;
    border-color: #0000004D;
    border-radius: 10px;
    min-height: 60px;
`);

const RemoveButton = styled(CrossIcon)`
    cursor: pointer;
`

const AlignDiv = styled.div`
display: flex;
    align-items: center;
`
const ApplyTitle = () => {
    const { formatMessage } = useIntl();
    return <AlignDiv>{formatMessage({ id: LOCALIZATION.add })}<FIcon width={24} height={24} /></AlignDiv>
}
const UploadTitle = () => {
    const { formatMessage } = useIntl();
    return <AlignDiv>{formatMessage({ id: LOCALIZATION.upload })}</AlignDiv>
}


const ImageLoader = (props: { uuid?: string, filename?: string, onRemove?: () => void, src?: string, tabOpened?: ((v: Window) => void) }) => {
    const { uuid, filename, onRemove, src: overridedSrc, tabOpened } = props;
    const { font } = useCurrentFont()
    const ImageLabel = styled.span`
    color: var(--AdBlack, #000);
    /* c_body_P */
        font-family: ${font};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    `

    const cameraSettings = useCameraSettings(true);
    const eventsImageTemplate = useMemo(() => cameraSettings?.urls?.eventsImageTemplate, [cameraSettings?.urls?.eventsImageTemplate]);
    const isProd = useIsProd();
    const { protocol, host, port } = new URL(getJavaBackend());
    const src = useMemo(() => overridedSrc ?? `${`${protocol}//${host}:${port}`}${eventsImageTemplate?.replace("{events_image_path}", `/tmp_${uuid}/${filename}`)}`, [eventsImageTemplate, filename, isProd, overridedSrc, uuid]);
    const { formatMessage } = useIntl();
    return <LoadingComponent pretendChildrenInitializing isLoading={!src} label={formatMessage({ id: LOCALIZATION.image_loading })}>
        {!!src && <ImageHolder width={220} src={overridedSrc ?? `${src}?w=640&h=480`} overrideSrc={src} tabOpened={tabOpened} />}
        <Grid container gap={1} alignItems={'center'} textAlign={'center'} flexWrap={'nowrap'}>
            <Grid width={200} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} item>
                <ImageLabel>{filename}
                </ImageLabel>
            </Grid>
            <Grid item marginLeft={"auto"} xs="auto">
                <RemoveButton onClick={() => onRemove?.()} width={20} height={20} />
            </Grid>
        </Grid>
    </LoadingComponent>
}

export const UdistortionImageInput = (props: { uuid: string, camera?: number, initialImagesToRemove: { uuid: string, filename: string }[], onChange: (lists: { toSave: { uuid: string, file: string, name: string }[], toRemove: { uuid: string, filename: string }[] }) => void, onRequestingCalibration: () => void, requestSaveFiles: () => void | Promise<void> }) => {
    const { uuid, camera, onChange, onRequestingCalibration, requestSaveFiles, initialImagesToRemove = [] } = props;
    const req = useMemo(() => {
        if (!uuid) {
            return undefined;
        }
        return { uuid }
    }, [uuid])

    const { formatMessage } = useIntl();
    const { data, fetchData, isLoading: gettingImagesMeta } = useGetUndistordImagesMeta({ req });
    useEffect(() => {
        fetchData();
    }, [fetchData, req])
    const filenames = useMemo(() => {
        return data?.getCollectionImagesMeta
    }, [data]);

    const [addImageModalOpen, setAddImageModalOpen,] = useState<boolean>(false);


    const [imagesToRemove, setImagesToRemove] = useState<{ uuid: string, filename: string }[]>(initialImagesToRemove);
    const [imagesToSave, setImagesToSave] = useState<{ uuid: string, file: string, name: string }[]>([]);

    const addImageToSave = useCallback((images: { uuid: string, file: string, name: string }[]) => {
        setImagesToSave([...imagesToSave, ...images]);
    }, [imagesToSave])

    useEffect(() => {
        onChange({ toSave: imagesToSave, toRemove: imagesToRemove });
        if (!imagesToSave.length) {
            return;
        }
        setImagesToSave([])

    }, [imagesToRemove, imagesToSave, onChange, requestSaveFiles])

    const addImageToRemove = useCallback((image: { uuid: string, filename: string }) => {
        setImagesToRemove([...imagesToRemove, image]);
    }, [imagesToRemove])


    const filesCount = useMemo(() => [filenames?.length, imagesToSave?.length].map(Number).reduce((accumulator, currentValue) => accumulator + currentValue, 0) - imagesToRemove.length, [filenames?.length, imagesToRemove.length, imagesToSave?.length])

    const handleSubmitAddImageModal = useCallback((files: string[]) => { addImageToSave(files.map(file => ({ uuid, file, name: `shot` }))) }, [addImageToSave, uuid]);

    const onCloseAddImageModal = useCallback(() => setAddImageModalOpen(false), [setAddImageModalOpen])

    const openImageModalCallback = useCallback(() => {
        setAddImageModalOpen(true);
    }, [])

    const pressedF$ = useMemo(() => fromEvent(document, 'keypress').pipe(filter((e) => (e as KeyboardEvent).key.toLowerCase() === "f"), map(() => v4())), [])

    const pressedF = useAsync({
        observable: pressedF$
    })

    useEffect(() => {
        if (!pressedF) {
            return;
        }
        openImageModalCallback()
    }, [openImageModalCallback, pressedF])

    const { files: uploaded, openModal: openFileInput, isLoading: isLoadingLocalFiles, } = useFilesSelector({})

    const { version: uploadedKey } = useObjectChanged(uploaded)
    const previousUploadedKey = usePrevious(uploadedKey)

    useEffect(() => {
        if (!uploaded.length || uploadedKey === previousUploadedKey) {
            return
        }
        handleSubmitAddImageModal(uploaded)
    }, [handleSubmitAddImageModal, previousUploadedKey, uploaded, uploadedKey])

    const isLoading = useMemo(() => {
        return isLoadingLocalFiles || gettingImagesMeta
    }, [gettingImagesMeta, isLoadingLocalFiles])

    const { font } = useCurrentFont()

    const Header = styled.span`
    color: var(--C_Dark_Text, #334D5E);
    /* c_h4 (18) */
    font-family: ${font};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.27px;
`
    return <><LoadingComponent switchDelay={0} isLoading={isLoading}>
        <Header>
            {`${formatMessage({ id: LOCALIZATION.undistord })}: ${formatMessage({ id: LOCALIZATION.captured }).toLowerCase()} ${filesCount} ${formatMessage({ id: LOCALIZATION.of }).toLowerCase()} 10..30 ${formatMessage({ id: LOCALIZATION.images }).toLocaleLowerCase()}`}
        </Header>
        <Grid container gap={1} marginTop={2}>
            {filenames?.filter(v => !imagesToRemove.find(r => r.filename === v.filename)).map(v => <Grid item xs='auto' key={v.filename}><ImageLoader uuid={uuid} filename={v.filename} onRemove={() => addImageToRemove({ uuid, filename: v.filename })} /></Grid>)}
        </Grid>
        <Grid container marginTop={2} gap={1}>
            <Grid item >

                <MainButton isDisabled={filesCount >= 30} onClicked={openImageModalCallback} fitContent title={<ApplyTitle />} />
            </Grid>
            <Grid item >
                <MainButton isDisabled={filesCount >= 30} onClicked={openFileInput} fitContent title={<UploadTitle />} />

            </Grid>
            <Grid item>
                <MainButton isDisabled={filesCount < 10} fitContent onClicked={() => onRequestingCalibration()} title={formatMessage({ id: LOCALIZATION.calculate_undistord_by_images })} />
            </Grid>
        </Grid>
    </LoadingComponent>
        {addImageModalOpen && <AddImageModal handleSubmit={handleSubmitAddImageModal} camera={camera} onClose={onCloseAddImageModal} />}</>
}