// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import React, { Key } from 'react';

import { Box } from '@mui/material';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import Button from '../Button';
import { SX as MainButtonSX } from '../MainButton';


export type ButtonOption = Readonly<{
  text: string;
  iconButton?: JSX.Element
  hidden?: boolean
  disabled?: boolean
  transparent?: boolean
  bordered?: boolean
  onClick: () => void;
}>;


export type SpaceOption = Readonly<{
  // if -1 then flexGrow=1 else use defined size if px. min 5
  space: number;
  hidden?: boolean
}>;

export type ButtonsInRowArray = Array<ButtonOption | SpaceOption | JSX.Element>

type Props = Readonly<{
  id: Key;
  buttons: ButtonsInRowArray;
}>;


const SX = {
  button: {
    width: 'auto',
    // minWidth: 'initial',
    padding: '0 15px',
  },
  iconButton: {
    width: 'initial',
    minWidth: 'initial',
    padding: 0,
  },
};

const instanceOfButtonOption = (object: ButtonOption | SpaceOption | JSX.Element): object is ButtonOption => 'text' in object;
const instanceOfSpaceOption = (object: ButtonOption | SpaceOption | JSX.Element): object is SpaceOption => 'space' in object;

const ButtonsInRowElement: React.FC<Props> = (props: Props) => {
  const { font } = useCurrentFont()
  // const theme = useTheme();
  // const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const buttonSX = { ...MainButtonSX(font).main, ...MainButtonSX(font).plain, ...SX.button };
  const borderedButtonSX = { ...MainButtonSX(font).main, ...MainButtonSX(font).outline, ...SX.button };
  const iconButtonSX = { ...MainButtonSX(font).icon, ...SX.iconButton };

  const renderItem = (option?: ButtonOption | SpaceOption | JSX.Element, index?:number) => {
    if (!option) {
      return null;
    }

    if (instanceOfButtonOption(option)) {
      const sx = (option.bordered) ? borderedButtonSX : ((option.iconButton) ? iconButtonSX :  buttonSX)
      const sx2 = {
        ...sx,
        visibility: (option.transparent) ? 'hidden' : 'visible'
      }

      return (
        (!option.hidden) &&
          <Button
            key={ index }
            sx={ sx2 }
            variant={ (option.iconButton) ? "outlined" : "contained" }
            // className={ clsx(cls.rootButton, classes.main, classes.plain) }
            disabled={ !!option.disabled }
            onClick={ option.onClick }
          >
            { option.iconButton ?? option.text }
          </Button>
      )
    }
    else if (instanceOfSpaceOption(option)) {
      return (
        (!option.hidden) && (
          (option.space < 0) ?
            <Box
             key={ index }
              sx={{
              minWidth: 5,
                flexGrow: 1
            }}/>
          :
            <Box
             key={ index }
              sx={{
              key: index,
              minWidth: 5,
              width: option.space
            }}/>
        )
      )
    }

    return option
  };

  return (
    <Box
      key= { props.id }
      sx={ {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      } }
    >
      { props.buttons.map(renderItem) }
    </Box>
  );
};


export default ButtonsInRowElement;
