class ImageBroadcastManager {
  private static instance: ImageBroadcastManager | null = null;
  private channel: BroadcastChannel;
  private messageCallback?: (
    action: string,
    data: string | null,
    uuid: string | null
  ) => void;

  private constructor() {
    // Инициализация канала связи
    this.channel = new BroadcastChannel("imageChannel");

    // Установка прослушивателя по умолчанию
    this.channel.onmessage = (event) => {
      const { action, data, uuid } = event.data;
      if (!!this.messageCallback) {
        console.log("Message received", action);

        this.messageCallback(action, data, uuid);
      } else {
        console.log("Message received, but no callback is set.", action);
      }
    };

    // Для удобства дебага
    //@ts-ignore
    window["imageChannel"] = this;
  }

  // Метод для получения экземпляра синглтона
  public static getInstance(): ImageBroadcastManager {
    if (!ImageBroadcastManager.instance) {
      ImageBroadcastManager.instance = new ImageBroadcastManager();
    }
    return ImageBroadcastManager.instance;
  }

  // Сохранение изображения через BroadcastChannel с указанием UUID
  saveImage(base64String: string, uuid: string): void {
    if (!this.isChannelClosed()) {

      this.channel.postMessage({
        action: "saveImage",
        data: base64String,
        uuid,
      });
    }
  }

  // Запрос изображения от отправителя с указанием UUID
  requestImage(uuid: string): void {
    if (!this.isChannelClosed()) {
      this.channel.postMessage({ action: "requestImage", uuid });
    }
  }

  // Установка коллбэка для обработки сообщений
  onMessage(
    callback: (action: string, data: string | null, uuid: string | null) => void
  ): void {
    this.messageCallback = callback;
    console.log(this.messageCallback, !!this.messageCallback);
  }

  // Проверка, закрыт ли канал
  private isChannelClosed(): boolean {
    return !this.channel; // Проверка, существует ли канал
  }

  // Закрытие канала при необходимости
  closeChannel(): void {
    if (!this.isChannelClosed()) {
      this.channel.close();
      this.channel = undefined as unknown as BroadcastChannel; // Устанавливаем undefined для явного указания, что канал закрыт
    }
  }
}

export default ImageBroadcastManager;
