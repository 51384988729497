import { useCallback } from "react";
import { v4 } from "uuid";

export const useDownloadBlob = (
  defualtBlob?: Blob,
  defaultFilename: string = `${v4()}`
) => {
  const download = useCallback(
    (
      blob: Blob | undefined = defualtBlob,
      filename: string = defaultFilename
    ) => {
      if (!blob) {
        return;
      }
      const downloadBlob = () => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      };

      downloadBlob();
    },
    [defaultFilename, defualtBlob]
  );
  return { download };
};
