// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { Button } from '@mui/material';
import React from 'react';

import { FieldSetInputType } from '.';
import { SX as MainButtonSX } from '../MainButton';

import { createStyles, makeStyles } from '@mui/styles';
import { FontName, useCurrentFont } from '../../../Configuration/Styles/Fonts';
import LOCALIZATION from '../../../Localization';
import { intl } from '../../../Localization/LocalizationProvider';
import TeamLogo from '../TeamLogo';

export type ChangeFile = (dataInBase64?: string) => void;

const useStyles = makeStyles(() => createStyles<any, { fontFamily: FontName }>({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
  },
  teamLogoChangeButton: {
    flexGrow: 1,
    float: 'right',
    // marginLeft: '20px',
  },
  container: {
    margin: 20
  }
}));

export type FieldSetFile = Readonly<{
  type: FieldSetInputType.File;
  value?: string;
  fileFormatCheckType?: string[];// default="image/png". can be mime type like "image/png", "text/xml", etc. if start with "image/" assume image format
  invalidFileFormatMsg?: string;
  buttonText: string;
  onChange: ChangeFile;
}>;

type Props = Readonly<{
  input: FieldSetFile;
}>;


const FieldSetFileComponent: React.FC<Props> = (props: Props) => {
  const [base64Image, setBase64Image] = React.useState<string | undefined>(undefined);

  const handleFileValueChanged = (event: React.SyntheticEvent) => {
    try {
      const reader = new FileReader();
      reader.onload = (upload) => {
        const base64 = (upload?.target?.result as any) || undefined;
        const fileTypes = (props.input.fileFormatCheckType) || ["image/png"];

        const fileTypesMatch = fileTypes.filter((fileType) => {
          const fileFormatCheckRegExp = new RegExp(`^data:${fileType};base64,`);
          return (base64 && fileFormatCheckRegExp.test(base64));
        })

        if (fileTypesMatch.length === 0) {
          alert((props.input.invalidFileFormatMsg) || intl().formatMessage({ id: LOCALIZATION.alert_only_png_images }));
          return;
        }

        // check custom file format
        for (const fileType of fileTypesMatch) {
          const fileFormatCheckRegExp = new RegExp(`^data:${fileType};base64,`);

          if (!fileType.startsWith("image/")) { // non image file format
            setBase64Image(undefined);
            const base64Str = (base64 as string).replace(fileFormatCheckRegExp, "");
            (props.input as FieldSetFile).onChange(base64Str);
            return;
          }
          else {// check default image file format
            var image = new Image();
            // eslint-disable-next-line
            image.onload = () => {

              if ((!image) || (image.width > 500) || (image.height > 500)) {
                alert(intl().formatMessage({ id: LOCALIZATION.alert_only_png_images }));
              }
              else {
                setBase64Image(base64);
                (props.input as FieldSetFile).onChange(base64);
              }
            }
            image.src = base64;
            return;
          }
        }
      };
      const file = ((event.target as HTMLInputElement)?.files as FileList)?.[0];

      reader.readAsDataURL(file);
    } catch (ignore) {
      setBase64Image(undefined);
    }
  };

  const renderLogo = (base64Image) ?
    <TeamLogo
      src={ base64Image }
    />
    :
    (props.input.value && props.input.value.length !== 0) ?
      <TeamLogo
        src={ `${props.input.value}?${Math.random()}` }
      />
      :
      null;

  const { font } = useCurrentFont()
  const classes = useStyles({ fontFamily: font })

  return (
    <div className={ classes.root }>
      { renderLogo }
      <div
        className={ classes.teamLogoChangeButton }
      >
        <Button
          sx={{ ...MainButtonSX(font).main, ...MainButtonSX(font).plain, paddingLeft: '3px', paddingRight: '3px' }}
          // className={ clsx(classes.main, classes.plain) }
          // color="primary"
          // variant="contained"
          component="label"
        >
          { props.input.buttonText }
          <input
            hidden
            type="file"
            onChange={ handleFileValueChanged }
          />
        </Button>
      </div>
    </div>
  );
};

export default FieldSetFileComponent;
