// Copyright (C) Cybercamera 2020-2023 - All Rights Reserved
// Author: Vitaliy Alekseev <villy@cybercamera.ru>

import { get as getDeep } from 'lodash';
import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, Rectangle, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { useMediaQuery, useTheme } from '@mui/material';
import { Colors } from '../../../Configuration/Styles/Colors';
import { useCurrentFont } from '../../../Configuration/Styles/Fonts';
import { Sizes } from '../../../Configuration/Styles/Sizes';
import { AnalyticsConvertedDataType } from '../../../Data/Analytics/AnalyticsConverters';
import { AnalyticsStatGranularity } from '../../../Data/Analytics/AnalyticsTypes';
import LOCALIZATION from '../../../Localization';
import AspectRatio from '../../_Layout/AspectRatio';
import PlaceholderStyled, { ChartTooltipItemStyle, ChartTooltipLabelStyle } from '../Styled';
import { AnalyticsHorizBarVisualiseMode, BarLineChartItem } from './helper';

type Props = Readonly<{
    granularity: AnalyticsStatGranularity;
    value1Name: string | undefined;
    value1Color: string | undefined;
    value2Name: string | undefined;
    value2Color: string | undefined;
    data: BarLineChartItem[] | undefined
    visualiseMode?: AnalyticsHorizBarVisualiseMode
    mainDataIndex?: AnalyticsConvertedDataType
    mainDataIndexName?: string
    secondDataIndex?: AnalyticsConvertedDataType
    secondDataIndexName?: string
} & WrappedComponentProps>;

const AnalyticsHorizBarLineChartView: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const verySmallUI = useMediaQuery(theme.breakpoints.down('md'));
  const { font } = useCurrentFont()

  if (!!props.data) {
    const { mainDataIndex = AnalyticsConvertedDataType.score, visualiseMode=AnalyticsHorizBarVisualiseMode.Bar, secondDataIndex } = props
    const mainDataKey = `data.${mainDataIndex}`;
    const secondDataKey = `data.${secondDataIndex}`;

    let chartData: BarLineChartItem[] = props.data;
    if (visualiseMode === AnalyticsHorizBarVisualiseMode.Line) {
      chartData = chartData.map((item: BarLineChartItem) => {
        if (item.child?.length) {
          return {
            ...item,
            data: {},
          }
        }
        return item;
      })
    }

    const dataCount = chartData.length;

    const barOrLineEl = (
      name: string,
      mode: AnalyticsHorizBarVisualiseMode,
      keyName: string,
      legendColor?: string,
      fillColor?: string,
      strokeColor?: string,
      strokeDasharray?: string
      ) => {
      if (mode === AnalyticsHorizBarVisualiseMode.Bar){
        return (
          <Bar
          name={ name }
            dataKey={keyName}
            fill={fillColor}
          color={ legendColor }
          floodColor={ legendColor } // fake not used color to pass it to legend
            barSize={(dataCount < 10) ? 15 : undefined}
          isAnimationActive={false}
          stroke={ Colors.analyticsBarStroke }
          strokeWidth={ Colors.analyticsBarStrokeWidth }
        />
        )
      }

      return <Line
        connectNulls
        name={ name }
        type="linear"
        dataKey={keyName}
        color={ legendColor }
        floodColor={ legendColor } // fake not used color to pass it to legend
        stroke={strokeColor || fillColor}
        isAnimationActive={false}
        strokeWidth={2}
        strokeDasharray={ strokeDasharray }
      />
    }

    const CustomCursor = (props: any) => {
      const { height, points } = props;
      const newX =  points?.[0]?.x
      const newY =  points?.[0]?.y
      // const { x, y, top, left, width, height, stroke, points } = props;
      // const { x, y } = props;
      return (
        <Rectangle
          fill="black"
          fillOpacity={1}
          stroke="black"
          x={newX-1}
          y={newY-1}
          width={1}
          height={height}
          style={{
            zIndex: 10
          }}
        />
      );
    };

    return (
      <AspectRatio
        ratio={ (verySmallUI) ?  1200/600 : 1200/340 }
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
              width={550}
              height={500}
              data={ chartData }
              margin={{
                  top: 10,
                  right: 10,
                  left: -20,
                  bottom: 10
                }}
              >
              <defs>
                  <pattern
                      id="value1ColorDiagonalHatch"
                      width="10"
                      height="10"
                      patternTransform="rotate(45 0 0)"
                      patternUnits="userSpaceOnUse"
                  >
                      <rect x="0" y="0" width="10" height="10" fill={ props.value1Color } />
                      <line x1="0" y1="0" x2="0" y2="10" stroke="black" strokeWidth="4" />
                  </pattern>
                  <pattern
                      id="value2ColorDiagonalHatch"
                      width="10"
                      height="10"
                      patternTransform="rotate(45 0 0)"
                      patternUnits="userSpaceOnUse"
                  >
                      <rect x="0" y="0" width="10" height="10" fill={ props.value2Color } />
                      <line x1="0" y1="0" x2="0" y2="10" stroke="black" strokeWidth="4" />
                  </pattern>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="id"
                scale={ (dataCount > 2) ? "point" : "auto" }
                padding={{ left: 60, right: 60 }}
                tickSize={ 8 }
                tickFormatter={(value: any, index: number): string => {
                  const newIndex = parseInt(value);
                  if (chartData && newIndex < chartData.length) {
                    const newValue = chartData[newIndex].itemName;
                    return newValue;
                  }
                  return value;
                }}
              />
              <YAxis />
            <Tooltip
              cursor={<CustomCursor />}
              labelFormatter={(label: any, payload: any) => {
                const index = parseInt(label);
                return chartData?.[index]?.titleForTooltip || label;
              }}
              formatter={(value: any, name: string, props: any) => {
                if (value === 0 && (props.payload as BarLineChartItem)?.hiddenData) {
                  const valueKey = props.dataKey.replace('data.', 'hiddenData.');
                  const newValue = getDeep(props.payload, valueKey);
                  return [newValue, name];
                }
                return [value, name];
              }}
              labelStyle={ ChartTooltipLabelStyle }
              itemStyle={ ChartTooltipItemStyle }
              wrapperStyle={ { background: Colors.mainBackground, zIndex: 2 } }
              />
            <Legend align="center" verticalAlign='bottom'
                formatter={(value: any, entry, index) => {
                  const color = (entry.payload as any)?.["floodColor"] as string || entry?.color

                  return (
                    <span style={{
                      color: color,
                      fontWeight: Sizes.regularWeight,
                      fontFamily: font,
                      fontSize: Sizes.title,
                     }}>{value}</span>
                  )
                }}
              />

              { barOrLineEl(`${props.value1Name} ${props.mainDataIndexName}`, visualiseMode, mainDataKey + '.value1', Colors.black, props.value1Color) }
              { barOrLineEl(`${props.value2Name} ${props.mainDataIndexName}`, visualiseMode, mainDataKey + '.value2', Colors.black, props.value2Color) }

              {(secondDataIndex !== undefined) && (
                barOrLineEl(`${props.value1Name} ${props.secondDataIndexName}`, visualiseMode, secondDataKey + '.value1', Colors.black, "url(#value1ColorDiagonalHatch)", props.value1Color, "4")
              )}
              { (secondDataIndex !== undefined) && (
                barOrLineEl(`${props.value2Name} ${props.secondDataIndexName}`, visualiseMode, secondDataKey + '.value2', Colors.black, "url(#value2ColorDiagonalHatch)", props.value2Color, "4")
            )}

              {
                chartData.map((item) => {
                  if (item.hiddenData) {
                    return <ReferenceLine
                      stroke="red"
                    ifOverflow = 'extendDomain'
                    x = { item.id }
                    />
                  }
                  return null;
                })
              }

          </ComposedChart>
        </ResponsiveContainer>
      </AspectRatio>
    );
  }

  return (
    <PlaceholderStyled>
      { props.intl.formatMessage({ id: LOCALIZATION.loading_metrics }) }
    </PlaceholderStyled>
  );
};

export default injectIntl(AnalyticsHorizBarLineChartView);
