import { css } from "@emotion/css";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { v4 } from "uuid";
import LoadingComponent from "../../../../../Components/Loader";
import Popup from "../../../../../Components/_Layout/Popup";
import { usePushStitchingImage } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/usePushStitchingImage";
import { useSaveStitchingPoints } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/mutations/useSaveStichingPoints";
import { CalibrationInputImageType } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/StitchinConfig.interface";
import { useGetZonePoints } from "../../../../../Data/NapiCameraHandlerV2/hooks/stiching/useGetZonePoints";
import { useCameraShot } from "../../../../../hooks/useCameraShot";
import { useFirstDefinedValue } from "../../../../../hooks/useFirstDefined";
import useImageSize from "../../../../../hooks/useImagesSize";
import useImageToJpeg from "../../../../../hooks/useImageToJpeg";
import { usePromise } from "../../../../../hooks/usePromise";
import { useResizeObserver } from "../../../../../hooks/useResizeObserver";
import LOCALIZATION from "../../../../../Localization";
import { PanoramaTool } from "../../../../Panorama/PanoramaTool/PanoramaTool";
import { useCameraImageContext } from "../CameraImageProvider";
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        '& .MuiCardContent-root': {
            [theme.breakpoints.down('md')]: {
                padding: '40px 10px',
            },
            padding: '40px',
            paddingTop: '20px',
            paddingBottom: '20px',
        },
        '& .MuiPaper-root': {
            boxShadow: 'none',
            overflowY: 'initial',
        },
    },
    contentRoot: {
        overflowY: "initial",
        background: 'white',
        padding: '0px',
    }
}))

interface Props {
    onClose?: () => any,
    onApply?: () => any,
    camerasToStitch: [number, number],
    uuid: string,

}
export const PanoramaStitching = ({ onClose, onApply, camerasToStitch, uuid }: Props) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const [camera1, camera2] = camerasToStitch;

    const { data: requestedZonePoints, isLoading: loadingPoints } = useGetZonePoints({ indexes: [camera1, camera2], uuid })
    const firstrequestedZonePoints = useFirstDefinedValue(requestedZonePoints)
    const [points, setPoints] = useState<[Array<[number, number]>, Array<[number, number]>]>();
    useEffect(() => {
        if (loadingPoints) {
            return;
        }
        setPoints(firstrequestedZonePoints ?? [[], []])
    }, [firstrequestedZonePoints, loadingPoints])

    const { mutateAsync: pushImage, isLoading: pushingImage } = usePushStitchingImage()
    const [offset, setOffset] = useState(100);
    const { getShot: updateImg1, isLoading: isLoadingupdateImg1 } = useCameraShot({ camera: camera1, debug: 'key-1', })

    const { convertImageToBase64 } = useImageToJpeg();
    const { setImage, getImage, images } = useCameraImageContext()

    const firstLast = usePromise(async () => {
        const src = images?.find(v => v.camera === camera1)?.src
        return src && convertImageToBase64(src)
    }, [camera1])

    const secondLast = usePromise(async () => {
        const src = images?.find(v => v.camera === camera2)?.src
        return src && convertImageToBase64(src)
    }, [camera2])

    const [src1, src2] = useMemo(() => {

        return [firstLast, secondLast,]
    }, [firstLast, secondLast])


    const onRefreshCallback = useCallback(async () => {
        setImage(camera1, await updateImg1(camera1))
        setImage(camera2, await updateImg1(camera2))
    }, [camera1, camera2, setImage, updateImg1])
    const onOkCallback = useCallback(() => { }, [])
    const onCancelCallback = useCallback(() => { }, [])

    const stylePanorama = useMemo(() => ({ width: "100%", height: `100%` }), [])

    const { mutateAsync: saveConfig } = useSaveStitchingPoints()

    const { width: width1, height: height1 } = useImageSize(src1) ?? {};
    const { width: width2, height: height2 } = useImageSize(src2) ?? {};

    const applyCallback = useCallback(async () => {
        const src1 = getImage(camera1)!.src
        const src2 = getImage(camera2)!.src
        await Promise.all([pushImage({
            name: `camera-${camera1}`,
            file: await (await fetch(src1)).blob(), uuid
        }),
        pushImage({
            name: `camera-${camera2}`,
            file: await (await fetch(src2)).blob(), uuid
        })])
        onApply?.();

        if (!points || !width1 || !width2 || !height1 || !height2) {
            return;
        }
        const result = {
            images: [
                {
                    type: CalibrationInputImageType.left,
                    index: camera1,
                    width: width1,
                    height: height1,
                    points: points[0].map(([x, y]) => ({ x: Math.round(x), y: Math.round(y) }))
                },
                {
                    type: CalibrationInputImageType.right,
                    index: camera2,
                    width: width2,
                    height: height2,
                    points: points[1].map(([x, y]) => ({ x: Math.round(x), y: Math.round(y) }))
                }
            ],
            offsets: [{ index: camera1, value: offset }]
        };
        saveConfig({ calibrationInput: result, uuid })
    }, [camera1, camera2, getImage, height1, height2, offset, onApply, points, pushImage, saveConfig, uuid, width1, width2])

    const { ref: resizeRef, width, } = useResizeObserver()
    const [panoramaTool, setPanoramaTool] = useState<string>(v4());

    useEffect(() => {
        setPanoramaTool(v4())
    }, [width])

    return <>
        <LoadingComponent ugly isLoading={!src1 || !src2 || loadingPoints || pushingImage || isLoadingupdateImg1}><Popup
            closeTitle={`${formatMessage({ id: LOCALIZATION.close_btn })}`}
            isFullScreen
            headerTitle={`${formatMessage({ id: LOCALIZATION.zone })}#${camera2}  `}
            // isApplyDisabled={loadingList}
            onApply={applyCallback}
            onClose={onClose}
            classes={classes}
            applyTitle={`${formatMessage({ id: LOCALIZATION.save })}`}

        >   {src1 && src2 && points && <div className={css`
            width: 100%;
    height: 100%;
        `} ref={resizeRef}><PanoramaTool
                key={panoramaTool}
                    style={stylePanorama}
                    points={points}
                    leftImageSource={src1}
                    rightImageSource={src2}
                    offset={offset}
                    setPoints={setPoints}
                    setOffset={setOffset}
                    onOK={onOkCallback}
                    onCancel={onCancelCallback}
                    onRefresh={onRefreshCallback}
                    leftPanelId={camera1.toString()}
                    rightPanelId={camera2.toString()}
                    zoneId={"2"}
                /></div>}</Popup></LoadingComponent></>
}


